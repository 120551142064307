import * as yup from 'yup';

//Regex
const string_regex = /^[A-Za-z][A-Za-z\s.]+$/;

//limits
const string_max = 100;

//Error Messages
const invalid = "Input Format Invalid";
const required = "Input Required"
const max_limit = "Maximum Limit Exceeded"

export const ContactSchema = yup.object().shape({
     ContactPersonFirstName : yup.string().max(string_max, max_limit).required(required).matches(string_regex,invalid),
     ContactPersonLastName :yup.string().max(string_max, max_limit).required(required).matches(string_regex,invalid),
     ContactPersonEmailId : yup.string().max(string_max, max_limit).required(required).email(invalid),
     ContactPersonMessage : yup.string().required(required),
});
