import React from 'react';
import DashboardCustomers from './DashboardCustomers';
import DashboardOrders from './DashboardOrders';
const Dashboard = () => {
    
    return (
        <div>
            <DashboardCustomers/>     
            <DashboardOrders/>       
        </div>
    );
};

export default Dashboard;