import React from 'react';
import { useParams } from 'react-router-dom';
import styles from "./OrderSummary.module.css"
import { Button } from "react-bootstrap"
import { useDispatch} from 'react-redux';
import { orderState } from "src/redux/actions";
import { useSelector } from 'react-redux'
import  Summary from "src/components/Order/Summary"
import CustomButton from "src/components/Button/CustomButton"

const OrderSummary = () => {

    const dispatch = useDispatch()

    const orderData = useSelector(state=> state.orderState) // See Changes

    const getTotalPrice = (price, count) => {
        return price*count
    } 
    
    const selectedTemplateData = [
        {
            item_name : orderData.idCardTemplate.title,
            description  : orderData.idCardTemplate.details,
            quantity : orderData.idCardTemplate.quantity,
            total : getTotalPrice(orderData.idCardTemplate.quantity, orderData.idCardTemplate.price),
            currency : orderData.idCardTemplate.currency,
            item_image : orderData.idCardTemplate.front
        }
    ]

    const selectedAccessoriesData = orderData.accessories? orderData.accessories.map(accessory=> {
        return {
            item_name : accessory.title,
            description  : accessory.details,
            quantity : accessory.quantity,
            total : getTotalPrice(accessory.quantity, accessory.price),
            currency : accessory.currency,
            item_image : accessory.image
        }
    }) : []

    const allOrderData = [...selectedTemplateData , ...selectedAccessoriesData]


    return (
        <div className="container-fluid">
            <div className="container">
                <div className="row mt-3 mb-5 justify-content-center" >
                    <Summary
                        options = {allOrderData}
                    />
                </div>
            </div>

            <div className="row mt-3 mb-5 justify-content-center" >
                <div className="col-sm-2">
                    
                    <CustomButton 
                        label = "Back" 
                        onClick={() => dispatch(orderState(1))}
                        type = "button" 
                    />

                    </div>

                <div className="col-sm-2">

                    <CustomButton 
                        label = "Next" 
                        onClick={() => dispatch(orderState(3))}
                        type = "button" 
                    />

                </div>

            </div>

        </div>
    );
};

export default OrderSummary;