import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import { FaCircle, FaArrowLeft } from 'react-icons/fa'
import styles from "./DashboardOrders.module.css"
import CustomButton from "src/components/Button/CustomButton"
import OrderList from "src/components/CustomTable/List"
import { apiProvider } from 'src/ApiProvider/ApiProvider';
import { getSum } from 'src/Utils/utils'

const DashboardOrders = () => {

    const history = useHistory()
    const [orders , setOrders] = useState([])
    const [searchText, setSearchText] = useState()

    useEffect(()=>{
        const getData = async () => {
            const result = await apiProvider.getAllOrders()
            const orderList = result.map(item=>{
                const total = getSum(item.accessories) + getSum(item.idCardTemplate)
                return {...item , totalPrice : total, currency : item.idCardTemplate[0].currency}
              })
            setOrders(orderList)
        }
        getData()
    },[])
    
    const fieldItems = ["id", "totalPrice", "currency", "purchasedOn", "status"]

    return (
        <div className={`container ${styles.container}`}>

        <div className="row pb-4">
            <div className="col-4 d-flex justify-content-start align-items-center">
                <div className = {`h4 ${styles.order_title}`}>Orders</div>
            </div>
            <div className="col-8 d-flex mt-3 justify-content-end">
                <CustomButton
                    label = "View Details"
                    onClick={() => history.push("/cdprc/orders")}
                    type = "button"
                />
            </div>
        </div>

        <OrderList
            List = {orders && orders.slice(0,5)}
            Fields = {fieldItems}
            scopedValue = "status"
            onRowClick = {(id)=> {history.push(`/cdprc/order/details/${id}`)}}
        />
            
        </div>
    );
};

export default DashboardOrders;