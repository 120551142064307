import * as yup from 'yup';

//Regex
const password_regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/

//limits
const string_max = 200;

//Error Messages
const invalid = "Input Format Invalid";
const required = "Input Required"
const max_limit = "Maximum Limit Exceeded"
const enter_pass =  "Please Enter your password"
const password_error =  "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"

export const LoginSchema = yup.object().shape({
    
    username:  yup.string().max(string_max, max_limit).required(required).email(invalid),

    password: yup
                .string()
                .required(enter_pass)
                .matches( password_regex, password_error),
    })
