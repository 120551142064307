import * as yup from 'yup';

//Error Messages
const required = "Input Required";
const fileError = "Upload File(max size 10MB)"
const zipError = "Upload File(max size 30MB)"

export const UploadContentSchema = yup.object().shape({

    cardType : yup.string().required(required),

    attachedFile : yup.mixed().test("fileSize", fileError, (value) => {
        if (!value.length) return false
        return value[0].size <= 10000000
    }),

    attachedZip : yup.mixed().test("fileSize", zipError, (value) => {
        if (!value.length) return false
        return value[0].size <= 25000000
    }),

}
);