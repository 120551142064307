import React from 'react'
import form from 'src/pages/Form/Form'
import login from 'src/pages/Login/Login'
import register from "src/pages/Register/Register"
import dashboard from "src/pages/User/Dashboard"
import user_state from "src/pages/User/UserState"
import user_register_state from "src/pages/User/UserRegisterState"
import user_review_done from "src/pages/User/UserReviewDone"
import order from "src/pages/Order/Order"
import order_upload_process from "src/pages/Order/UploadProcess/UploadProcess"
import processing from "src/pages/Order/UploadProcess/Processing"
import uploaded_list from "src/pages/Order/UploadedData/List"
import edit_item from "src/pages/Order/UploadedData/EditItem"
import cdprc_dashboard_layout from "src/pages/CDPRC/Dashboard/DashboardLayout"
import cdprc_customers from "src/pages/CDPRC/Customers/Customers"
import cdprc_orders from "src/pages/CDPRC/Orders/Orders"
import cdprc_customer_enlistment from "src/pages/CDPRC/EnlistmentForm/ApprovalPage/EnlistmentForm"
import page401 from 'src/pages/Page401/Page401'
import page404 from 'src/pages/Page404/Page404'
import error_page from 'src/pages/ErrorPage/ErrorPage'
import Contact from 'src/pages/Contact/Contact'
import order_details from 'src/pages/CDPRC/Orders/OrderSummary'
import user_order_details from 'src/pages/User/OrderSummary'
import cdprc_customer_details from 'src/pages/CDPRC/EnlistmentForm/Form'


const homepage =  React.lazy(() => import('src/pages/LandingPage/index'))
const cdprc_login_page = React.lazy(() => import("src/pages/CDPRC/Login/Login"))

const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/cdprc', exact: true, name: 'CDPRC' },
  { path: '/home', name: 'Instructions', component: homepage },
  { path: '/form', name: 'from', component: form },
  { path: '/login', name: 'login', component: login },
  { path: '/register', name: 'register', component: register },
  { path: '/user/dashboard', name: 'user dashboard', component: dashboard },
  { path: '/user/state', name: 'user state', component: user_state },
  { path: '/user/register/state', name: 'user registration state', component: user_register_state},
  { path: '/user/review', name: 'user review done', component: user_review_done },
  { path: '/user/order', name: 'order', component: order },
  { path: '/user/content/upload', name: 'order attachment upload', component: order_upload_process },
  { path: '/user/upload/processing', name: 'order attachment processing', component: processing },
  { path: '/orders/:id/records', name: 'uploaded list', component: uploaded_list },
  { path: '/data/edit/:id', name: 'edit data', component: edit_item },
  { path: '/cdprc/login', name: 'cdprc login', component: cdprc_login_page },
  { path: '/cdprc/dashboard', name: 'cdprc dashboard', component: cdprc_dashboard_layout },
  { path: '/cdprc/customers', name: 'cdprc customers', component: cdprc_customers },
  { path: '/cdprc/enlistment', name: 'cdprc customers', component: cdprc_customer_enlistment },
  { path: '/cdprc/orders', name: 'cdprc customers', component: cdprc_orders },
  { path: '/cdprc/order/details/:id', name: 'order details for cdprc', component:  order_details },
  { path: '/cdprc/customer/details/:id', name: 'cdprc customer details', component:  cdprc_customer_details },
  { path: '/401', name: '404', component: page401 },
  { path: '/404', name: '401', component: page404 },
  { path: '/500', name: '500', component: error_page },
  { path: '/contact', name: 'contact', component: Contact },
  { path: '/order/:id', name: 'order details for cdprc users', component:  user_order_details },
  
];

export default routes;
