import React from 'react';

const Title = (props) => {

  const label = props.label;

  return (
    <div className={"h6 mt-5"} style={{textAlign: 'left', fontFamily: 'poppins', fontWeight:"600"}}>
      <strong style={{color : "#0E4CA9"}}>|</strong> <span style={{color:"#0E4CA9"}}>{label}</span>
      <hr style={{backgroundColor: "#0E4CA9", height:"2px", border: "none", opacity: "1"}} />
    </div>
  );
};

export default Title;