import React, {useState, useEffect} from 'react';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import styles from "./Customers.module.css"
import OrderList from "src/components/CustomTable/List"
import { useHistory } from 'react-router';
import { apiProvider } from 'src/ApiProvider/ApiProvider';
import SearchBar from 'src/components/SearchBar/SearchBar';
import Pagination from 'src/components/Pagination/Pagination';
import {useDispatch, useSelector} from "react-redux";
import { formState } from 'src/redux/actions';
import { searchFunction, status } from 'src/Utils/utils';


const Customers = () => {

    const history = useHistory()

    const dispatch = useDispatch()

    useEffect(()=>{
        dispatch(formState(0))
    },[])

    const [customers , setCustomers] = useState([])
    const [allCustomers, setAllCustomers] = useState([])
    const [searchText, setSearchText] = useState()
    const [key, setKey] = useState('allRecord');
    const [currentPage, setCurrentPage] = useState (1)

    const fieldItems = ["organizationName", "primaryContactPerson", "email", "phoneNo", "status"]


    useEffect(() =>{
        const getData = async () =>{
            const result = await apiProvider.getAllCustomers()
            setAllCustomers(result)
            setCustomers(result)
        }
        getData();
    },[])

    useEffect(() =>{
        setCurrentPage(1)
    },[key])

    const searchClick = async () =>{
        if(searchText && customers .length>0){
            const searchableData = allCustomers.map(item=>{ return {id : item.id, organizationName : item.organizationName , primaryContactPerson : item.primaryContactPerson, email : item.email, phoneNo: item.phoneNo, status : item.status } })
            const result = searchFunction(searchableData, searchText)
            if(result) setAllCustomers(result)
        }
    }

    const refreshClick = () => {
        setSearchText('')
        setAllCustomers(customers)
    }

    const getComponentData = (key) =>{
        switch (key) {
            case "allCustomers" : return allCustomers
            case "pendingCustomers" : return allCustomers.filter(item => item.status === status.PENDING)
            case "approvedCustomers" : return allCustomers.filter(item => item.status === status.APPROVED)
            case "rejectedCustomers" : return allCustomers.filter(item => item.status === status.REJECTED)
            default : return allCustomers
        }
    }

    return (
        <div className={`container ${styles.container}`}>

            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className={styles.tabs}
            >
                <Tab eventKey="allCustomers" active title="All Customer"></Tab>
                <Tab eventKey="pendingCustomers" title="Pending Customers"></Tab>
                <Tab eventKey="approvedCustomers" title="Approved Customers"></Tab>
                <Tab eventKey="rejectedCustomers" title="Rejected Customers"></Tab>
            </Tabs>

    
        <SearchBar
            onChangeValue = {(value) => setSearchText(value)}
            onSearchClick = {searchClick}
            onRefreshClick = {refreshClick}
        />

        <OrderList
            List = {getComponentData(key).slice((currentPage*10)-10,(currentPage*10))}
            Fields = {fieldItems}
            scopedValue = "status"
            onRowClick = {(id)=> {history.push(`/cdprc/customer/details/${id}`)}}
        />
        <Pagination
            dataCount = {getComponentData(key).length}
            itemsPerPage = "10"
            clickedValue = {value => setCurrentPage(value) }
        />

        </div>
    );
};

export default Customers;