import React, {useEffect, useState} from 'react';
import { Form, Button} from 'react-bootstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import styles from "./UploadProcess.module.css"
import Select from 'src/components/Select/Select';
import UploadBox from 'src/components/UploadBox/UploadBox';
import Label from 'src/components/Label/Label';
import { useHistory } from 'react-router-dom';
import { UploadContentSchema } from './UploadContentSchema';
import CustomButton from "src/components/Button/CustomButton"
import { apiProvider } from 'src/ApiProvider/ApiProvider';
import { useSelector } from 'react-redux';
import Processing from './Processing';

//Step Digits
import step_one from 'src/images/step_one.png';
import step_two from 'src/images/step_two.png';
import step_three from 'src/images/step_three.png';
import step_four from 'src/images/step_four.png';
import step_five from 'src/images/step_five.png'
import vertical_line from 'src/images/vertical_line.png';
import { getFormData } from 'src/Utils/utils';

const UploadProcess = () => {

    const history = useHistory()

    const order_id = useSelector(state=> state.orderState.order_id)

    //State
    const [templateList , setTemplateList] = useState([]) //Expecting [{id : "", name : "" , downloadLink : ""}]
    const [cardType, setCardType] = useState();
    const [selectedCardLink, setSelectedCardLink] = useState();
    const [processingState , setProcessingState] = useState(false)
    const [dataSubmittedState, setDataSubmittedState] = useState(false)

    useEffect(() => {
        if(cardType){
            const link = templateList.find(i=>i.name === cardType)
            setSelectedCardLink(link.downloadLink)
        }
    }, [cardType])

    useEffect(()=>{
        if(dataSubmittedState) history.push('/user/dashboard')
    },[dataSubmittedState])

    //useEffects
    useEffect(() => {
        const getList = async () => {
            const result = await apiProvider.getExcelTemplateList()
            setTemplateList(result)
        }
        getList();
    },[])

    //Accepted Document Types
    const acceptDoc = ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
    const acceptZip = ".zip, application/x-zip-compressed"

    //Handle data from event and Submit
    const submit = async (event) => {
        //submit
        setProcessingState(true)
        const data = {
            ...event,
            orderId : order_id,
            attachedFile : event.attachedFile[0],
            attachedZip: event.attachedZip[0]
        }
        const formData = getFormData(data);
        await apiProvider.uploadAttachment(formData)
        setDataSubmittedState(true)
    }
     

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver : yupResolver(UploadContentSchema),
    });
  
    return (
        <div className="container p-0">
            
            {processingState? 

                <Processing/> 
                
                :

                <Form className = "form container" onSubmit={handleSubmit(submit)}>

                    <div className = {styles.upload_title}>
                        Process of Uploading ID Card Contents
                    </div>

                    <div className = {`row`}>

                        <div className = {`col-md-3`}>
                            <div className = {`d-flex ${styles.instruction_row}`}> 
                                <div className = {`col-sm-2`}> <img src={step_one} className = {styles.step_digit} alt="01"/></div>
                                <div className = {`col-sm-9 d-flex align-items-center ${styles.step_text}`}> Choose ID Card Type </div>
                            </div>
                            <div className = {`d-flex ${styles.instruction_row}`}> 
                                <div className = {`col-sm-2`}> <img src={vertical_line} className = {styles.step_digit} alt="01"/></div>
                                <div className = {`col-sm-9 d-flex align-items-center ${styles.step_text}`}></div>
                            </div>
                            <div className = {`d-flex ${styles.instruction_row}`}> 
                                <div className = {`col-sm-2`}> <img src={step_two} className = {styles.step_digit} alt="02"/> </div>
                                <div className = {`col-sm-9 d-flex align-items-center ${styles.step_text}`}> Download the template </div>
                            </div>
                            <div className = {`d-flex ${styles.instruction_row}`}> 
                                <div className = {`col-sm-2`}> <img src={vertical_line} className = {styles.step_digit} alt="01"/></div>
                                <div className = {`col-sm-9 d-flex align-items-center ${styles.step_text}`}></div>
                            </div>
                            <div  className = {`d-flex ${styles.instruction_row}`}> 
                                <div className = {`col-sm-2`}> <img src={step_three} className = {styles.step_digit} alt="03"/>  </div>
                                <div className = {`col-sm-9 d-flex align-items-center  ${styles.step_text}`}> Add your contents with images </div>
                            </div>
                            <div className = {`d-flex ${styles.instruction_row}`}> 
                                <div className = {`col-sm-2`}> <img src={vertical_line} className = {styles.step_digit} alt="01"/></div>
                                <div className = {`col-sm-9 d-flex align-items-center ${styles.step_text}`}></div>
                            </div>
                            <div className = {`d-flex ${styles.instruction_row}`}> 
                                <div className = {`col-sm-2`}> <img src={step_four} className = {styles.step_digit} alt="04"/>  </div>
                                <div className = {`col-sm-9 d-flex align-items-center ${styles.step_text}`}> Or Upload Zipped Image folder </div>
                            </div>
                            <div className = {`d-flex ${styles.instruction_row}`}> 
                                <div className = {`col-sm-2`}> <img src={vertical_line} className = {styles.step_digit} alt="01"/></div>
                                <div className = {`col-sm-9 d-flex align-items-center ${styles.step_text}`}></div>
                            </div>
                            <div className = {`d-flex ${styles.instruction_row}`}> 
                                <div className = {`col-sm-2`}> <img src={step_five} className = {styles.step_digit} alt="04"/>  </div>
                                <div className = {`col-sm-9 d-flex align-items-center ${styles.step_text}`}> Finally Upload the contents </div>
                            </div>
                        </div>

                        <div className = {`col-md-9 ${styles.form_row}`}>
                            
                            <div className = "row">

                                <Select
                                    register = {register}
                                    errors = {errors}
                                    label = "Choose Id Card Type"
                                    label_md = "2"
                                    required = {true}
                                    fieldName = "cardType"
                                    setSelected = {value => setCardType(value)}
                                    items = {templateList}
                                    value = "name"
                                    size = "sm"
                                    lg = "10"
                                />
                
                            </div>

                            <div className = "row">
                                <Label
                                    label = "Template"
                                    label_md = "2"
                                />
                                <div className = "col-lg-3 d-flex align-center-left">
                                    <a target="_blank" href={selectedCardLink}> 
                                    <CustomButton 
                                        label = "Download" 
                                        type = "button" 
                                    />
                                    </a>
                                </div>                    
                            </div>

                            <div className = "row mt-5">

                                <UploadBox
                                    register = {register}
                                    errors = {errors}
                                    label = "Attach File"
                                    required = {true}
                                    fieldName = "attachedFile"
                                    accept = {acceptDoc}
                                    col_width = "12"
                                    label_width = "2"
                                    box_width = "4"
                                />
            
                            </div>

                            
                            <div className = "row mt-5">

                                <UploadBox
                                    register = {register}
                                    errors = {errors}
                                    required = {true}
                                    label = "Upload Zip"
                                    fieldName = "attachedZip"
                                    accept = {acceptZip}
                                    col_width = "12"
                                    label_width = "2"
                                    box_width = "4"
                                />
            
                            </div>

                        </div>

                    </div>

                    <div className = "mt-4 mb-5">
                        <CustomButton 
                            label = "Upload" 
                            type = "submit" 
                        />
                    </div>

                </Form>
            }

        </div>
    );
};

export default UploadProcess;