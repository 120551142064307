import React from 'react';
import { Button } from "react-bootstrap"
import styles from "./CustomButton.module.css"

const CustomButton = (props) => {
    
    const type = props.type
    const onClick = props.onClick
    const label = props.label
    const disabled = props.disabled

    return (
        <div>
            <Button type={type} disabled={disabled} onClick={onClick} className={styles.btn}><span className={styles.button_text}>{label}</span></Button>
        </div>
    );
};

export default CustomButton;