import React from 'react';

const Pagination = (props) => {

    const dataCount = parseInt(props.dataCount)
    const itemsPerPage = parseInt(props.itemsPerPage)
    const onClick = props.clickedValue

    const paginationCount = (dataCount%itemsPerPage) === 0 ? (dataCount / itemsPerPage) : (dataCount / itemsPerPage) + 1

    return (
        <nav aria-label="Page navigation example">
        <ul class="pagination justify-content-center">
        {[...Array(parseInt(paginationCount))].map((e, i) => {
            return <li className="page-item">
                        <button className="page-link" value = {i+1} onClick={(e)=>onClick(e.target.value)}>{i+1}</button>
                    </li>
            })
        }
        </ul>
      </nav>
    );
};

export default Pagination;
