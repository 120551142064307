import {combineReducers} from "redux";
import { applicationStateReducer } from "./ApplicationStateReaducer";
import {customerStateReducer} from "./CustomerStateReducer";
import {orderStateReducer} from "./OrderStateReducer";

const allReducers = combineReducers({
  customerState: customerStateReducer,
  orderState : orderStateReducer,
  applicationState : applicationStateReducer
})
export default allReducers;
