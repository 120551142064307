import * as yup from 'yup'

//Regex
const string_regex = /^[A-Za-z][A-Za-z\s.]+$/
const contact_number_regex = /(^(\+8801|8801|01|008801))[3-9]{1}(\d){8}$/
const designation_regex = /^[A-Za-z][A-Za-z\s./-:,]+$/

//limits
const string_max = 100
const min_limit = 1

//Error Messages

const invalid = "Invalid Format"
const required = "Input Required"
const max_limit = "Maximum Limit Exceeded"
const min_limit_msg = `Minimum ${min_limit} Items required`
const fileError = "Upload File(max size 2MB)"

export const ApprovedBySchema = yup.object().shape({
    comments : yup.string().required(required),

    firstName : yup.string().max(string_max, max_limit).required(required).matches(string_regex,invalid),
    lastName : yup.string().max(string_max, max_limit).required(required).matches(string_regex,invalid),
    designation : yup.string().max(string_max, max_limit).required(required).matches(designation_regex,invalid),

    contactNumber : yup.string().required(required).matches(contact_number_regex,invalid),

    email : yup.string().max(string_max, max_limit).email(invalid).required(required),

    signFile : yup.mixed().test("fileSize", fileError, (value) => {
        if (!value.length) return false
        return value[0].size <= 2000000
    })
}
);