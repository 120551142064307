import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Progress from './components/Progress/Progress';
import { Provider } from 'react-redux'
import store from 'src/redux/store'
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "src/authConfig";

const loading = <Progress />

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
    <Provider store={store}>
        <Suspense fallback={loading}>
            <MsalProvider instance={msalInstance}>
                <App />
            </MsalProvider>
        </Suspense>
    </Provider>,
    document.getElementById('root')
);

reportWebVitals();