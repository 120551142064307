import React, { useEffect, useState } from 'react';
import { Form, Button} from 'react-bootstrap';
import { useDispatch} from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux'
import { formState, insertPage2Information } from 'src/redux/actions';
import Input from 'src/components/Input/Input';
import Title from 'src/components/Title/Title';
import DatePick from 'src/components/DatePicker/DatePick';
import UploadBox from 'src/components/UploadBox/UploadBox';
import { SecondPageSchema } from './SecondPageSchema';
import styles from "./SecondPage.module.css"
import PreviewBox from 'src/components/PreviewBox/PreviewBox';
import CustomButton from "src/components/Button/CustomButton"
import TypeOfCompanies from "./TypeOfCompanies"
import SisterConcernCheck from './SisterConcernCheck';
import IndustryTypes from './IndustryTypes';

import { getExtension } from 'src/Utils/utils';

const SecondPage = (props) => {

    useEffect(()=>{
        window.scrollTo(0,0);
    },[])

    const customerData = props.customerData
    
    const [page2data,setPage2Data] = useState(customerData)

    useEffect(() => {
        setPage2Data(props.customerData)
    }, [])

    //File Accept Strings
    const anyAttachments = "image/jpg, image/jpeg, image/png, .doc, .docx, .txt, .pdf"
    const onlyImage = "image/jpg, image/jpeg, image/png"

    const dispatch = useDispatch();

    //Methods
    const submit = async (event) => {
        dispatch(formState(2))
    };

    const { register, handleSubmit, control, formState: { errors } } = useForm({
    //   resolver : yupResolver(SecondPageSchema),
    });

    const readOnlyPermission = true; 

    return (
        <div className="container-fuild p-0">

            <Form className = "form container-fluid" onSubmit={handleSubmit(submit)}>

                <div className="container">
                <div className = "row">

                    <Title
                        label = "Official Information"
                    />

                </div>


                <div className = "row">
                    <TypeOfCompanies
                        register = {register}
                        errors = {errors}
                        checkBoxFieldName = "typeOfCompany" 
                        inputFieldName = "typeOfCompanyOthersText"
                        label = "Type of Company / Organization"
                        label_md = "2"
                        required = {true}
                        formData = {page2data}
                    />
                </div>
            
                <div className = "row">
                
                    <DatePick
                        control = {control}
                        errors = {errors}
                        formData = {page2data}
                        label = "Year Of Establishment"
                        label_md = "2"
                        fieldName = "yearOfEstablishment"
                        required = {true}
                        md = "4"
                        disabled = {readOnlyPermission}
                        formData = {page2data}
                    />
                
                </div>

                <div className = "row">
                    <SisterConcernCheck
                        register = {register}
                        errors  = {errors}
                        label = "Any Group Company or Sister Concern (s)"
                        label_md = "2"
                        required = {true}
                        checkFieldName = "sisterConcernCheck"
                        inputFieldName = "sisterConcernText"
                        formData = {page2data}
                    />
                </div>

                <div className = "row">

                    <Input
                        register={register}
                        errors = {errors}
                        formData = {page2data}
                        label = "Incorporation Number (In Case of Public & Private Ltd. Company)"
                        fieldName="incorporationNumber"
                        required = {true}
                        label_md = "2"
                        md = "4"
                        size = "sm"
                        readOnly = {readOnlyPermission}
                    /> 

                    
                    <Input
                        register={register}
                        errors = {errors}
                        label = "E-TIN Number"
                        fieldName="eTinNumber"
                        formData = {page2data}
                        required = {true}
                        label_md = "2"
                        md = "4"
                        size = "sm"
                        readOnly = {readOnlyPermission}
                    /> 

                </div>

                
                <div className = "row">

                    <Input
                        register={register}
                        errors = {errors}
                        formData = {page2data}
                        label = "BIN / VAT Registration Number"
                        fieldName="binVatNumber"
                        required = {true}
                        label_md = "2"
                        md = "4"
                        size = "sm"
                        readOnly = {readOnlyPermission}
                    /> 

                    
                    <Input
                        register={register}
                        errors = {errors}
                        formData = {page2data}
                        label = "Trade License Number"
                        fieldName="tradeLicenseNumber"
                        required = {true}
                        label_md = "2"
                        md = "4"
                        size = "sm"
                        readOnly = {readOnlyPermission}
                    /> 

                </div>

                <div className = "row">

                    <PreviewBox 
                        label = "Incorporation Certificate"
                        col_width = "6"
                        label_width = "5"
                        box_width = "6"
                        filePath = {page2data.incorporationFile}
                        fileType = {getExtension(page2data.incorporationFile)}
                    />

                    <PreviewBox 
                        label = "E-TIN Certificate"
                        col_width = "6"
                        label_width = "5"
                        box_width = "6"
                        filePath = {page2data.eTinFile}
                        fileType = {getExtension(page2data.eTinFile)}
                    />

                </div>


                <div className = "row">

                    <PreviewBox 
                        label = "BIN/VAT Registration Number"
                        col_width = "6"
                        label_width = "5"
                        box_width = "6"
                        filePath = {page2data.binVatFile}
                        fileType = {getExtension(page2data.binVatFile)}
                    />

                    <PreviewBox 
                        label = "Trade License"
                        col_width = "6"
                        label_width = "5"
                        box_width = "6"
                        filePath = {page2data.tradeLicenseFile}
                        fileType = {getExtension(page2data.tradeLicenseFile)}
                    />

                </div>

                
                <div className = "row">
                    <IndustryTypes
                        register = {register}
                        errors = {errors}
                        control = {control}
                        label = "Industry Types & Their Business Services"
                        fieldName = "industryTypeAndBusinesses"
                        label_md = "2"
                        required = {true}
                        formData = {page2data}
                    />
                </div>
                </div>

                <div className = "container-fluid">
                <div className="row mt-3 mb-5 justify-content-center" >
                    <div className="col-sm-2">
                        
                        <CustomButton 
                            label = "Back"
                            onClick={() => dispatch(formState(0))}
                            type = "button" 
                        />

                    </div>

                    <div className="col-sm-2">

                        <CustomButton 
                            label = "Next"
                            onClick={() => dispatch(formState(2))}
                            type = "button" 
                        />

                    </div>

                </div>
                </div>
    
    </Form> 
        
    </div>
    );
};

export default SecondPage;