import * as yup from 'yup';

//Error Messages
const required = "Input Required";
const fileError = "Upload File(max size 10MB)"
const zipError = "Upload File(max size 30MB)"

//For Future Use generic schema for records.
export const EditItemSchema = yup.object().shape({

}
);