import * as yup from 'yup';

//Regex
const string_regex = /^[A-Za-z][A-Za-z\s.]+$/;
const contact_number_regex = /(^(\+8801|8801|01|008801))[3-9]{1}(\d){8}$/;

//limits
const string_max = 100;
const min_limit = 1

//Error Messages

const invalid = "Invalid Format";
const required = "Input Required";
const max_limit = "Maximum Limit Exceeded"
const emailErrorMsg = "Enter in this format : name@example.com"
const phoneNumError = "Enter a valid contact number";

export const OrderCheckoutSchema = yup.object().shape({

    billingFirstName : yup.string().max(string_max, max_limit).required(required).matches(string_regex,invalid),
    billingLastName : yup.string().max(string_max, max_limit).required(required).matches(string_regex,invalid),
    billingCompanyName : yup.string().max(string_max, max_limit),
   
    billingCountry : yup.string().required(required),
    billingAddress : yup.string().required(required),
    billingCity : yup.string().required(required),
    billingDistrict : yup.string().required(required),

    billingPhoneNumber : yup.string().required(required).matches(contact_number_regex,phoneNumError),
    billingEmail : yup.string().max(string_max, max_limit).email(emailErrorMsg).required(required),

    billingOrderNote : yup.string().required(required),

    billingShippingSame : yup.boolean(),

    shippingFirstName : yup.string().when("billingShippingSame",{is: false, then : yup.string().max(string_max, max_limit).required(required).matches(string_regex,invalid)}),
    shippingLastName : yup.string().when("billingShippingSame",{is: false, then : yup.string().max(string_max, max_limit).required(required).matches(string_regex,invalid)}),
    shippingCompanyName : yup.string().when("billingShippingSame",{is: false, then : yup.string().max(string_max, max_limit)}),
   
    shippingCountry : yup.string().when("billingShippingSame",{is: false, then : yup.string().required(required)}),
    shippingAddress : yup.string().when("billingShippingSame",{is: false, then : yup.string().required(required)}),
    shippingCity : yup.string().when("billingShippingSame",{is: false, then : yup.string().required(required)}),
    shippingDistrict :  yup.string().when("billingShippingSame",{is: false, then : yup.string().required(required)}),

    shippingPhoneNumber : yup.string().when("billingShippingSame",{is: false, then : yup.string().required(required).matches(contact_number_regex,phoneNumError)}),

    shippingEmail : yup.string().when("billingShippingSame",{is: false, then : yup.string().max(string_max, max_limit).email(emailErrorMsg).required(required)}),
    
    shippingOrderNote : yup.string().when("billingShippingSame",{is: false, then : yup.string().required(required)}),

}
);