import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux'
import { formState , insertPage3Information } from 'src/redux/actions';
import Input from 'src/components/Input/Input';
import Title from 'src/components/Title/Title';
import UploadBox from 'src/components/UploadBox/UploadBox';
import { ThirdPageSchema } from './ThirdPageSchema';
import styles from "./ThirdPage.module.css"
import { apiProvider } from 'src/ApiProvider/ApiProvider';
import CustomButton from "src/components/Button/CustomButton"
import DesignationCheckBox from './DesignationCheckBox';
import { useHistory } from 'react-router-dom';
import { getFormData } from 'src/Utils/utils';
import PreviewBox from 'src/components/PreviewBox/PreviewBox';
import { getExtension } from 'src/Utils/utils';

const ThirdPage = (props) => {

    useEffect(()=>{
        window.scrollTo(0,0);
    },[])

    const customerData = props.customerData

    const [page3data,setPage3Data] = useState(customerData)

    useEffect(() => {
        setPage3Data(props.customerData)
    }, [])

    const [isSame, setIsSame] = useState(false)

    //File Accept Strings
    const anyAttachments = "image/jpg, image/jpeg, image/png, .doc, .docx, .txt, .pdf"
    const onlyImage = "image/jpg, image/jpeg, image/png"

    const dispatch = useDispatch();
    const history = useHistory()

    //Methods
    const submit = async (event) => {
        
    };

    const { register, handleSubmit, control, formState: { errors } } = useForm({
        //resolver: yupResolver(ThirdPageSchema),
    });

    const readOnlyPermission = true

    return (
        <div className="container-fuild p-0">

            <Form className="form container-fluid" onSubmit={handleSubmit(submit)}>

                <div className = "container">
                <div className="row">

                    <Title
                        label="CEO / MD / Director / Managing Partner / Owner Information"
                    />

                </div>


                <div className="row">

                    <Input
                        register={register}
                        errors={errors}
                        label="First Name"
                        fieldName="ownerFirstName"
                        required={true}
                        label_md="2"
                        md="4"
                        size="sm"
                        readOnly = {readOnlyPermission}
                        formData = {page3data}
                    />

                    <Input
                        register={register}
                        errors={errors}
                        label="Last Name"
                        required={true}
                        fieldName="ownerLastName"
                        label_md="2"
                        md="4"
                        size="sm"
                        readOnly = {readOnlyPermission}
                        formData = {page3data}
                    />

                </div>

                <div className="row">
                    <DesignationCheckBox
                        register={register}
                        errors={errors}
                        label="Designation"
                        label_md="2"
                        fieldName="ownerDesignation"
                        required={true}
                        formData = {page3data}
                    />
                </div>

                <div className="row">

                    <Input
                        register={register}
                        errors={errors}
                        label="Contact Number"
                        required={true}
                        fieldName="ownerContactNumber"
                        label_md="2"
                        md="10"
                        size="sm"
                        readOnly = {readOnlyPermission}
                        formData = {page3data}
                    />

                </div>

                <div className="row">

                    <Input
                        register={register}
                        errors={errors}
                        label="E-mail ID"
                        required={true}
                        fieldName="ownerEmail"
                        label_md="2"
                        md="10"
                        size="sm"
                        readOnly = {readOnlyPermission}
                        formData = {page3data}
                    />

                </div>

                <div className="row">
                    
                    <PreviewBox 
                        label = "Photo"
                        col_width = "6"
                        label_width = "5"
                        box_width = "6"
                        filePath = {page3data.ownerPhotoFile}
                        fileType = {getExtension(page3data.ownerPhotoFile)}
                    />

                </div>


                <div className="row">

                    <Title
                        label="National ID No. / Passport No. / Driving License No. ( Write number or Upload Files ) of CEO :"
                    />

                </div>

                <div className="row">

                    <Input
                        register={register}
                        errors={errors}
                        label="National ID No."
                        fieldName="ownerNidNumber"
                        required={true}
                        label_md="2"
                        md="4"
                        size="sm"
                        readOnly = {readOnlyPermission}
                        formData = {page3data}
                    />

                    <Input
                        register={register}
                        errors={errors}
                        label="Passport No."
                        required={true}
                        fieldName="ownerPassportNumber"
                        label_md="2"
                        md="4"
                        size="sm"
                        readOnly = {readOnlyPermission}
                        formData = {page3data}
                    />

                </div>

                <div className="row">

                    <Input
                        register={register}
                        errors={errors}
                        label="Driving License No."
                        fieldName="ownerDrivingLicenseNumber"
                        required={true} s
                        label_md="2"
                        md="4"
                        size="sm"
                        readOnly = {readOnlyPermission}
                        formData = {page3data}
                    />

                </div>


                <div className="row">

                    <PreviewBox 
                        label = "National ID"
                        col_width = "6"
                        label_width = "5"
                        box_width = "6"
                        filePath = {page3data.ownerNidFile}
                        fileType = {getExtension(page3data.ownerNidFile)}
                    />
                    
                    <PreviewBox 
                        label = "Passport"
                        col_width = "6"
                        label_width = "5"
                        box_width = "6"
                        filePath = {page3data.ownerPassportFile}
                        fileType = {getExtension(page3data.ownerPassportFile)}
                    />

                </div>

                <div className="row">

                    <PreviewBox 
                        label = "Driving License"
                        col_width = "6"
                        label_width = "5"
                        box_width = "6"
                        filePath = {page3data.ownerDrivingLicenseFile}
                        fileType = {getExtension(page3data.ownerDrivingLicenseFile)}
                    />

                </div>



                <div className="row">

                    <Title
                        label="Information Provider"
                    />

                </div>

                <div className="row">

                    <div className="col-xl-3">
                        <label className={`from-group  ${styles.checkbox_row}`}>
                            <input className={`${styles.checkbox}`} disabled={true} checked={page3data.providerOwnerSame} type="checkbox" {...register("providerOwnerSame")} onChange={e => setIsSame(e.target.checked)} />
                            <span className={styles.checkbox_text}>Same</span>
                        </label>
                    </div>

                </div>

                {isSame ?
                    null :
                    <>
                        <div className="row">

                            <Input
                                register={register}
                                errors={errors}
                                label="First Name"
                                fieldName="infoProviderFirstName"
                                required={true}
                                label_md="2"
                                md="4"
                                size="sm"
                                readOnly = {readOnlyPermission}
                                formData = {page3data}
                            />

                            <Input
                                register={register}
                                errors={errors}
                                label="Last Name"
                                required={true}
                                fieldName="infoProviderLastName"
                                label_md="2"
                                md="4"
                                size="sm"
                                readOnly = {readOnlyPermission}
                                formData = {page3data}
                            />

                        </div>


                        <div className="row">

                            <Input
                                register={register}
                                errors={errors}
                                label="Designation"
                                required={true}
                                fieldName="infoProviderDesignation"
                                label_md="2"
                                md="10"
                                size="sm"
                                readOnly = {readOnlyPermission}
                                formData = {page3data}
                            />

                        </div>

                        <div className="row">

                            <Input
                                register={register}
                                errors={errors}
                                label="Department"
                                required={true}
                                fieldName="infoProviderDepartment"
                                label_md="2"
                                md="10"
                                size="sm"
                                readOnly = {readOnlyPermission}
                                formData = {page3data}
                            />

                        </div>
                    

                        <div className="row">

                            <Input
                                register={register}
                                errors={errors}
                                label="Contact Number"
                                required={true}
                                fieldName="infoProviderContactNumber"
                                label_md="2"
                                md="10"
                                size="sm"
                                readOnly = {readOnlyPermission}
                                formData = {page3data}
                            />

                        </div>

                        <div className="row">

                            <Input
                                register={register}
                                errors={errors}
                                label="E-mail ID"
                                required={true}
                                fieldName="infoProviderEmail"
                                label_md="2"
                                md="10"
                                size="sm"
                                readOnly = {readOnlyPermission}
                                formData = {page3data}
                            />

                        </div>
                        </>
                    }

                        <div className="row">

                            <PreviewBox 
                                label = "Signature"
                                col_width = "6"
                                label_width = "5"
                                box_width = "6"
                                filePath = {page3data.infoProviderSignFile}
                                fileType = {getExtension(page3data.infoProviderSignFile)}
                                />

                        </div>
                        </div>
                            <div className="container-fluid">
                                <div className="row mt-3 mb-5 justify-content-center" >
                                    <div className="col-sm-2">

                                        <CustomButton
                                            label="Back"
                                            onClick={() => dispatch(formState(1))}
                                            type="button"
                                        />

                                    </div>

                                    <div className="col-sm-2">

                                        <CustomButton
                                            label="Next"
                                            onClick={() => dispatch(formState(3))}
                                            type="button"
                                        />

                                    </div>

                                </div>
                            </div>

                </Form>

        </div>
    );
};

export default ThirdPage;