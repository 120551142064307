import React, { useEffect, useState } from 'react';
import { Form, Button} from 'react-bootstrap';
import { useDispatch} from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux'
import { formState, insertPage2Information } from 'src/redux/actions';
import Input from 'src/components/Input/Input';
import Title from 'src/components/Title/Title';
import DatePick from 'src/components/DatePicker/DatePick';
import UploadBox from 'src/components/UploadBox/UploadBox';
import { SecondPageSchema } from './SecondPageSchema';
import styles from "./SecondPage.module.css"
import PreviewBox from 'src/components/PreviewBox/PreviewBox';

import CustomButton from "src/components/Button/CustomButton"

import TypeOfCompanies from "./TypeOfCompanies"
import SisterConcernCheck from './SisterConcernCheck';
import IndustryTypes from './IndustryTypes';

const SecondPage = () => {

    useEffect(()=>{
        window.scrollTo(0,0);
    },[])

    const page2data = useSelector(state => state.customerState.page2_information)

    //File Accept Strings
    const anyAttachments = "image/jpg, image/jpeg, image/png, .doc, .docx, .txt, .pdf"
    const onlyImage = "image/jpg, image/jpeg, image/png"

    const dispatch = useDispatch();

    //Methods

    const updatedData = (event, fieldName) => {
        if(event && event[fieldName] && event[fieldName].length) return event[fieldName]
        else if (page2data && page2data[fieldName] && page2data[fieldName].length) return page2data[fieldName]
    }

    const submit = async (event) => {
        // Getting the validated data and only one file from the fileList for each attachment
        const data = {
            ...page2data,
            ...event,
            incorporationFile : updatedData(event , "incorporationFile"), 
            eTinFile : updatedData(event , "eTinFile"),
            binVatFile : updatedData(event , "binVatFile"),
            tradeLicenseFile : updatedData(event , "tradeLicenseFile"),
            yearOfEstablishment : new Date(event["yearOfEstablishment"]).toISOString(),
            industryTypeAndBusinesses : JSON.stringify(event['industryTypeAndBusinesses'].map(item=> item.value)),
        }

        dispatch(insertPage2Information(data))
        dispatch(formState(2))
    }

    const getFileData = (fileName)=> page2data && page2data[fileName]? page2data[fileName][0] : undefined

    const { register, trigger, handleSubmit, getValues, control, formState: { errors, isValid } } = useForm({
        resolver : yupResolver(SecondPageSchema)
    });

    return (
        <div className="container-fuild p-0">

            <Form className = "form container-fluid" onSubmit={handleSubmit(submit)}>

                <div className="container">
                <div className = "row">

                    <Title
                        label = "Official Information"
                    />

                </div>


                <div className = "row">
                    <TypeOfCompanies
                        register = {register}
                        errors = {errors}
                        checkBoxFieldName = "typeOfCompany" 
                        inputFieldName = "typeOfCompanyOthersText"
                        label = "Type of Company / Organization"
                        label_md = "2"
                        required = {true}
                        formData = {page2data}
                    />
                </div>
            
                <div className = "row">
                
                    <DatePick
                        control = {control}
                        errors = {errors}
                        formData = {page2data}
                        label = "Year Of Establishment"
                        label_md = "2"
                        fieldName = "yearOfEstablishment"
                        required = {true}
                        md = "4"
                        setMaxToday = {true}
                    />
                
                </div>

                <div className = "row">
                    <SisterConcernCheck
                        register = {register}
                        errors  = {errors}
                        label = "Any Group Company or Sister Concern (s)"
                        label_md = "2"
                        required = {true}
                        checkFieldName = "sisterConcernCheck"
                        inputFieldName = "sisterConcernText"
                        formData = {page2data}
                    />
                </div>

                <div className = "row">

                    <Input
                        register={register}
                        errors = {errors}
                        formData = {page2data}
                        label = "Incorporation Number (In Case of Public & Private Ltd. Company)"
                        fieldName="incorporationNumber"
                        required = {true}
                        label_md = "2"
                        md = "4"
                        size = "sm"
                    /> 

                    
                    <Input
                        register={register}
                        errors = {errors}
                        label = "E-TIN Number"
                        fieldName="eTinNumber"
                        formData = {page2data}
                        required = {true}
                        label_md = "2"
                        md = "4"
                        size = "sm"
                    /> 

                </div>

                
                <div className = "row">

                    <Input
                        register={register}
                        errors = {errors}
                        formData = {page2data}
                        label = "BIN / VAT Registration Number"
                        fieldName="binVatNumber"
                        required = {true}
                        label_md = "2"
                        md = "4"
                        size = "sm"
                    /> 

                    
                    <Input
                        register={register}
                        errors = {errors}
                        formData = {page2data}
                        label = "Trade License Number"
                        fieldName="tradeLicenseNumber"
                        required = {true}
                        label_md = "2"
                        md = "4"
                        size = "sm"
                    /> 

                </div>

                <div className = "row">

                    <UploadBox
                        register = {register}
                        errors = {errors}
                        label = "Incorporation Certificate"
                        required = {true}
                        file = {getFileData("incorporationFile")}
                        accept = {anyAttachments}
                        fieldName = "incorporationFile"
                        col_width = "6"
                        label_width = "5"
                        box_width = "6"
                    />
                    <UploadBox
                        register = {register}
                        errors = {errors}
                        label = "E-TIN Certificate"
                        required = {true}
                        accept = {anyAttachments}
                        fieldName = "eTinFile"
                        file = {getFileData("eTinFile")}
                        col_width = "6"
                        label_width = "5"
                        box_width = "6"
                    />

                </div>


                <div className = "row">

                    <UploadBox
                        register = {register}
                        errors = {errors}
                        label = "BIN/VAT Registration Number"
                        fieldName = "binVatFile"
                        required = {true}
                        accept = {anyAttachments}
                        file = {getFileData("binVatFile")}
                        col_width = "6"
                        label_width = "5"
                        box_width = "6"
                    />
                    <UploadBox
                        register = {register}
                        errors = {errors}
                        label = "Trade License"
                        fieldName = "tradeLicenseFile"
                        required = {true}
                        accept = {anyAttachments}
                        file = {getFileData("tradeLicenseFile")}
                        col_width = "6"
                        label_width = "5"
                        box_width = "6"
                    />

                </div>

                
                <div className = "row">
                    <IndustryTypes
                        register = {register}
                        errors = {errors}
                        control = {control}
                        label = "Industry Types & Their Business Services"
                        fieldName = "industryTypeAndBusinesses"
                        label_md = "2"
                        required = {true}
                        formData = {page2data}
                    />
                </div>
                </div>

                <div className = "container-fluid">
                <div className="row mt-3 mb-5 justify-content-center" >
                    <div className="col-sm-2">
                        
                        <CustomButton 
                            label = "Back"
                            onClick={() => dispatch(formState(0))}
                            type = "button" 
                        />

                    </div>

                    <div className="col-sm-2">
                        <CustomButton 
                            label = "Next" 
                            type = "submit" 
                        />

                    </div>

                </div>
                </div>
    
    </Form> 
        
    </div>
    );
};

export default SecondPage;