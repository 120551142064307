  
import React from 'react';
import { Col } from 'react-bootstrap';
import styles from "./Label.module.css";

const Label = (props) => {

  const label = props.label
  const required = props.required
  const requiredText = props.requiredText
  const md = props.label_md

  return (
    <Col md={md}>
        <div className={styles.level_div}>{label}:
        {required?
          requiredText? <><span style={{color:"#D55D5D"}}>*</span><br></br>{requiredText}</> :
          <><span style={{color:"#D55D5D"}}>*</span><br></br></>
          : null }
          </div>
      </Col>
  );
};

export default Label;