export const industryTypes = [
      {
          label:"Agro based Industry ",
          options:[
              { label:"Agro based firms (Incl. Agro Processing /Seed/GM)",value:"Agro based firms (Incl. Agro Processing /Seed/GM)"},
              { label:"Hatchery",value:"Hatchery"},
              { label:"Reptile Firms",value:"Reptile_Firms"},
              { label:"Shrimp",value:" Shrimp"},
              { label:"Animal/Plant Breeding",value:"Animal/Plant_Breeding"},
              { label:"Farming ",value:"Farming"},
              { label:"Livestock ",value:"Livestock"},
              { label:"Salt ",value:"Salt"},
              { label:"Tea Garden ",value:"Tea_Garden  "},
              { label:"Dairy ",value:"Dairy"},
              { label:"Fisheries ",value:"Fisheries"},
              { label:"Poultry ",value:"Poultry"},
              { label:"Science Laboratory ",value:"Science_Laboratory "},
              { label:"Dry Fish ",value:"Dry_Fish"}
              ]
      },
      {
          label:"Airline/Travel/Tourim",
          options:[
              { label:"Airline",value:"Airline"},
              { label:"Tour Operator ",value:"Tour_Operator "},
              { label:"GSA",value:"GSA"},
              { label:"Transport Service ",value:"Transport_Service "},
              { label:"Immigration/VISA Processing",value:"Immigration/VISA_Processing"},
              { label:"Travel Agent  ",value:"Travel_Agent  "}
          ]  
      },
      {  
          label:"Architecture/ Engineering/ Construction",
          options:[
              { label:"Architecture Firm ",value:"Architecture_Firm "},
              { label:"Engineering Firms  ",value:"Engineering_Firms "},
              { label:"Escalator/ Elevator/ Lift ",value:"Escalator/ Elevator/Lift "},
              { label:"HVAC System    ",value:"HVAC_System  "},
              { label:"Interior Design   ",value:"Interior_Design   "},
              { label:"Consultancy Firm   ",value:"Consultancy_Firm  "},
              { label:"Contractor  ",value:"Contractor "}
  
          ] 
        },
        {
  
            label:"Automobile/Industrial Machine ",
            options:[
                {label:"Automobile",value:"Automobile"},
                {label:"CNG/LPG Conversion",value:"CNG/LPG_Conversion "},
                {label:"Indenting  ",value:"Indenting  "},
                {label:"Industrial Machines (Generator, Diesel Engine etc.) ",value:"Industrial_Machines (Generator, Diesel Engine etc.) "},
                {label:"Motor Vehicle Body Manufacturer  ",value:"Motor_Vehicle_Body_Manufacturer  "},
                {label:"Motor Workshop ",value:"Motor_Workshop "}
            ]
        },
        {
            label:"Bank/Non-Bank Fin. Institution ",
            options:[
              {label:"Banks ",value:"Banks "},
              {label:"Insurance   ",value:"Insurance   "},
              {label:"Share Brokerage / Securities House   ",value:"Share_Brokerage / Securities_House   "},
              {label:"Credit Rating Agency ",value:"Credit_Rating_Agency "},
              {label:"Investment/Merchant Banking   ",value:"Investment/Merchant_Banking "},
              {label:"Venture Capital Firm  ",value:"Venture_Capital_Firm "},
              {label:"Financial Consultants   ",value:"Financial_Consultants "},
              {label:"Leasing ",value:"Leasing "}
  
            ]
        },
        {
            label:"Education ",
            options:[
              {label:"Coaching_Center ",value:"Coaching_Center "},
              {label:"College ",value:"College "},
              {label:"Kindergarten ",value:"Kindergarten "},
              {label:"Madrasa  ",value:"Madrasa "},
              {label:"School ",value:"School "},
              {label:"Training_Institutes   ",value:"Training_Institutes "},
              {label:"University ",value:"University"}
  
            ]
        },
        {
            label:"Electronics/ Consumer Durables ",
            options:[
              {label:"Computer Hardware/Network Companies",value:"Computer Hardware/Network_Companies"},
              {label:"Electronic Equipment/ Home Appliances ",value:"Electronic_Equipment/ Home_Appliances "},
              {label:"Mobile & Accessories ",value:"Mobile & Accessories "}
            ]
        },
        {
            label:"Energy/ Power/ Fuel ",
            options:[
              {label:"CNG/LPG/LNG ",value:"CNG/LPG/LNG "},
              {label:"Fuel/Petroleum   ",value:"Fuel/Petroleum   "},
              {label:"Mining ",value:"Mining "},
              {label:"Coal ",value:"Coal "},
              {label:"Gas  ",value:"Gas  "},
              {label:"Power ",value:"Power "},
              {label:"Filling_Station",value:"Filling_Station"},
              {label:"LPG_Gas / Cylinder_Gas ",value:"LPG_Gas / Cylinder_Gas"}
            ]
        },
        {
            label:"Entertainment/ Recreation ",
            options:[
              {label:"Amusement Park ",value:"Amusement_Park "},
              {label:"Convention Center  ",value:"Convention_Center  "},
              {label:"Golf Club  ",value:"Golf_Club "},
              {label:"Party/Community Center  ",value:"Party/Community_Center "},
              {label:"Sports Complex   ",value:"Sports_Complex   "},
              {label:"Cinema Hall/ Theater ",value:"Cinema_Hall/ Theater "},
              {label:"Cultural Center   ",value:"Cultural Center "},
              {label:"Museum  ",value:"Museum   "},
              {label:"Religious Place   ",value:"Religious_Place   "},
              {label:"Swimming Pool   ",value:"Swimming_Pool   "},
              {label:"Club ",value:"Club  "},
              {label:"Gallery  ",value:"Gallery   "},
              {label:"Park ",value:"Park "},
              {label:"Shopping Mall ",value:"Shopping_Mall "},
              {label:"Sports Academy ",value:"Sports_Academy"}
              
            ]
        },
        {
            label:"Fire, Safety & Protection ",
            options:[
              {label:"Fire_Fighting_and_Safety   ",value:"Fire_Fighting_and_Safety  "},
              {label:"Pest_Control   ",value:"Pest_Control  "}
            ]
        },
        {
            label:"Food & Beverage Industry ",
            options:[
              {label:"Bakery Item (Cake, Biscuit, Bread etc.) ",value:"Bakery_Item (Cake, Biscuit, Bread etc.)"},
              {label:"Ice Cream    ",value:"Ice_Cream  "},
              {label:"Beverage   ",value:"Beverage"},
              {label:"Mineral Water  ",value:"Mineral_Water  "},
              {label:"Drinking Water     ",value:"Drinking_Water   "},
              {label:"Food (Packaged)   ",value:"Food_(Packaged)   "}
              
            ]
        },
        {
            label:"Garments/Textiles",
            options:[
              {label:"Buying House ",value:"Buying House"},
              {label:"Garments & Textile Accessories     ",value:"Garments & Textile_Accessories  "},
              {label:"Textile   ",value:"Textile  "},
              {label:"Dyeing Factory    ",value:"Dyeing_Factory    "},
              {label:"Spinning     ",value:"Spinning    "},
              {label:"Garments   ",value:"Garments  "},
              {label:"Sweater Industry   ",value:"Sweater_Industry  "},
              {label:"Washing Factory  ",value:"Washing_Factory  "},
              {label:"Waving Factory  ",value:"Waving_Factory  "},
              {label:"Knit Composite    ",value:"Knit_Composite   "},
              {label:"Denim Factory    ",value:"Denim_Factory   "},
              {label:"Sizing Factory    ",value:"Sizing_Factory    "},
              {label:"Third Party Auditor (Quality, Health, Environment, Compliance etc.) ",value:"Third_Party_Auditor (Quality, Health, Environment, Compliance etc.) "}
  
            ]
        },
        {
            label:"Govt. / Semi Govt. / Autonomous Body"
        },
        {
            label:" Hospital/Diagnostic_Center",
            options:[
              {label:"Doctor’s Chamber ",value:"Doctor’s_Chamber "},
              {label:"Hospital ",value:"Hospital"},
              {label:"Clinic",value:"Clinic"},
              {label:"Diagnostic Center",value:"Diagnostic_Center"},
              {label:"Physiotherapy Center  ",value:"Physiotherapy_Center  "}
            ]
        },
        {
            label:" Hotel/Restaurant ",
            options:[
                {label:"Bar/Pub",value:"Bar/Pub"},
                {label:"Fast Food Shop ",value:"Fast_Food_Shop"},
                {label:"Resort   ",value:"Resort  "},
                {label:"Catering   ",value:"Catering  "},
                {label:"Hotel   ",value:"Hotel  "},
                {label:"Restaurant ",value:"Restaurant "},
                {label:"Coffee Shop  ",value:"Coffee_Shop  "},
                {label:"Motel  ",value:"Motel "}
              
            ]
        },
        {
            label:"Information Technology (IT) ",
            options:[
              {label:"BPO/ Data Entry Firm ",value:"BPO/ Data_Entry_Firm "},
              {label:"E-commerce  ",value:"E-commerce "},
              {label:"Software Company",value:"Software_Company"},
              {label:"Call Center  ",value:"Call_Center  "},
              {label:"ISP ",value:"ISP"},
              {label:"DTP House",value:"DTP_House"},
              {label:"IT Enabled Service  ",value:"IT_Enabled_Service  "},
              {label:"Research & Development (R&D)  ",value:"Research & Development (R&D) "}
  
            ]
        },
        {
            label:" Logistic/ Transportation  ",
            options: [
              {label:"Clearing & Forwarding (C&F) Companies ",value:"Clearing & Forwarding (C&F) Companies"},
              {label:"Logistic/Courier/Air Express Companies ",value:"Logistic/Courier/Air Express_Companies"},
              {label:"Supply Chain ",value:"Supply_Chain "},
              {label:"Freight Forwarding  ",value:"Freight_Forwarding "},
              {label:"Port Service ",value:"Port_Service"},
              {label:"Transportation ",value:"Transportation "},
              {label:"Inventory/Warehouse",value:"Inventory/Warehouse"},
              {label:"Shipping ",value:"Shipping"},
              {label:"Distributor ",value:"Distributor"},
              {label:"Local Agent   ",value:"Local_Agent  "},
              {label:"Sole Distributor  ",value:"Sole_Distributor  "}
            ]   
        },
        {
            label:"Manufacturing (Heavy Industry) ",
            options:[
              {label:"Battery, Storage Cell ",value:"Battery, Storage_Cell "},
              {label:"Chemical Industries ",value:"Chemical_Industries "},
              {label:"Electric Wire/Cable ",value:"Electric_Wire/Cable "},
              {label:"Lamps",value:"Lamps"},
              {label:"Paint ",value:"Paint "},
              {label:"Sanitary Ware ",value:"Sanitary_Ware "},
              {label:"Cement",value:"Cement"},
              {label:"Corrugated Tin ",value:"Corrugated_Tin "},
              {label:"Food (Packaged)/Beverage  ",value:"Food (Packaged)/Beverage  "},
              {label:"Manufacturing (FMCG",value:"Manufacturing (FMCG"},
              {label:"Paper",value:"Paper"},
              {label:"Shipyard ",value:"Shipyard "},
              {label:"Cement Industry ",value:"Cement_Industry "},
              {label:"Furniture Manufacturer  ",value:"Furniture_Manufacturer "},
              {label:"Packaging Industry ",value:"Packaging_Industry "},
              {label:"Plastic/Polymer Industry ",value:"Plastic/Polymer_Industry "},
              {label:"Steel ",value:"Steel "}
            ]
        },
        {
            label:"Manufacturing (Light Industry)  ",
            options:[
              {label:"Bicycle ",value:"Bicycle"},
              {label:"Crockeries   ",value:"Crockeries  "},
              {label:"Jute Good ",value:"Jute_Good "},
              {label:"Jute Yarn ",value:"Jute_Yarn "},
              {label:"Jute Craft   ",value:"Jute_Craft  "},
              {label:"Tannery  ",value:"Tannery "},
              {label:"Footwear  ",value:"Footwear "},
              {label:"Boutique  ",value:"Boutique "},
              {label:"Fashion ",value:"Fashion"},
              {label:"Furniture ",value:"Furniture"},
              {label:"Medical Equipment  ",value:"Medical_Equipment  "},
              {label:"Brick ",value:"Brick"},
              {label:"Handcraft ",value:"Handcraft "},
              {label:"Pottery   ",value:"Pottery  "},
              {label:"Printing",value:"Printing"}
              
  
            ]
        },
        {
            label:"Media (Satellite/Print/Online)/ Advertising/ Event Mgt. ",
            options:[
              {label:"Advertising Agency  ",value:"Advertising_Agency "},
              {label:"Newspaper/ Magazine ",value:"Newspaper/ Magazine"},
              {label:"Web Media/Blog  ",value:"Web Media/Blog "},
              {label:" Event Management  ",value:" Event_Management "},
              {label:"Online Newspaper/News Portal ",value:"Online_Newspaper/News_Portal"},
              {label:"Radio  ",value:"Radio  "},
              {label:"Film Production  ",value:"Film_Production "},
              {label:"Music Production ",value:"Music_Production"},
              {label:"Professional Photographers",value:"Professional_Photographers"},
              {label:"Wedding Planner   ",value:"Wedding_Planner  "}
            ]
        },
        {
            label:"NGO/Development ",
            options:[
              {label:"Development Agency ",value:"Development_Agency  "},
              {label:"NGO ",value:"NGO "},
              {label:"Micro-Credit ",value:"Micro-Credit"}
            ]
        },
        {
            label:"Pharmaceuticals ",
            options:[
              {label:"Healthcare Product ",value:"Healthcare_Product  "},
              {label:"Lifestyle Product  ",value:"Lifestyle_Product   "},
              {label:"Herbal Medicine  ",value:"Herbal_Medicine  "},
              {label:"Medical Equipment   ",value:"Medical_Equipment    "},
              {label:"Pharmaceuticals/Medicine Companies  ",value:"Pharmaceuticals/Medicine_ompanies "}
              
            ]
        },
        {
            label:"Real Estate/Development ",
            options:[
                {label:"Developer ",value:"Developer "},
                {label:"Real Estate ",value:"Real_Estate "},
                {label:"Contractor ",value:"Contractor "},
                {label:"Consultancy Firm   ",value:"Consultancy_Firm "}
            ]
        },
        {
            label:"Security Service  ",
            options:[
                {label:"Security Service Agency ",value:"Security_Service_Agency "},
                {label:"Security Guards Service   ",value:"Security_Guards_Service   "},
                {label:"Patrol Vehicle Service  ",value:"Patrol_Vehicle_Service  "},
                {label:"Alarm Systems Service  ",value:"Alarm_Systems_Service "},
                {label:"Cyber Security Service   ",value:"Cyber_Security_Service  "},
                {label:"Private Investigation Service ",value:"Private_Investigation_Service "},
                {label:"Risk Analysis Firm  ",value:"Risk_Analysis_Firm "},
                {label:"Security Consultancy Firm   ",value:"Security_Consultancy_Firm "},
                {label:"CCTV Installation & Maintenance Service ",value:"CCTV_Installation & Maintenance_Service "}
  
  
              ]
        },
        {
            label:"Telecommunication ",
            options:[
              {label:"Mobile Phone Operator ",value:"Mobile_Phone_Operator "},
              {label:"Land Phone   ",value:"Land_Phone "},
              {label:"PSTN ",value:"PSTN "},
              {label:"Telecommunication Infrastructure ",value:"Telecommunication_Infrastructure "},
              {label:"Telecommunication ",value:"Telecommunication  "},
              {label:"Technical Infrastructure  ",value:"Technical_Infrastructure  "},
              {label:"Technical Service Provider ",value:"Technical_Service_Provider "}
              
            ]
          
        },
        {
            label:"Wholesale/ Retail/ Export-Import ",
            options:[
              {label:"Chain Shop ",value:"Chain_Shop "},
              {label:"Grocery Shop  ",value:"Grocery_Shop "},
              {label:"Jewelry/Gem_Shop ",value:"Jewelry/Gem_Shop "},
              {label:"Tailor Shop  ",value:"Tailor_Shop  "},
              {label:"Watch ",value:"Watch "},
              {label:"Departmental_Store ",value:"Departmental_Store "},
              {label:"Importer ",value:"Importer"},
              {label:"Exporter   ",value:"Exporter  "},
              {label:"Retail Store   ",value:"Retail_Store "},
              {label:"Toy Shop ",value:"Toy_Shop "},
              {label:"Gift Shop   ",value:"Gift_Shop "},
              {label:"Wholesale   ",value:"Wholesale "},
              {label:"Direct Selling  ",value:"Direct_Selling "},
              {label:"Marketing Service Agency  ",value:"Marketing_Service_Agency "},
              {label:"Indenting Firm   ",value:"Indenting_Firm "},
              {label:"Super_Store  ",value:"Super_Store "},
              {label:"Treading   ",value:"Treading  "},
              {label:"Export-Import    ",value:"Export-Import "},
              {label:"Clothing Shop ",value:"Clothing_Shop "},
              {label:"Footwear Shop ",value:"Footwear_Shop "},
              {label:"Stationary Shop  ",value:"Stationary_Shop "},
              {label:"Pharmacy/Medicine_Shop",value:"Pharmacy/Medicine_Shop"},
              
              ]
            },
            {
                label:"Others",
                options:[
                  {label:"Audit Firms",value:"Audit_Firms"},
                  {label:"Tax & VAT Consultant  ",value:"Tax & VAT_Consultant  "},
                  {label:"Consulting Firms  ",value:"Consulting_Firms  "},
                  {label:"Embassies/ Foreign Consulate  ",value:"Embassies/ Foreign_Consulate  "},
                  {label:"Individual / Personal Recruitment  ",value:"Individual / Personal_Recruitment  "},
                  {label:"Beauty Parlor/Saloon/Spa ",value:"Beauty Parlor/Saloon/Spa "},
                  {label:"Cosmetics/Toiletries/Personal Care ",value:"Cosmetics/Toiletries/Personal_Care "},
                  {label:"Group of Companies ",value:"Group_of_Companies "},
                  {label:"Law Firm",value:"Law_Firm"},
                  {label:"Market Research Firm  ",value:"Market_Research_Firm  "},
                  {label:"Marketing Agency ",value:"Marketing_Agency "},
                  {label:"Overseas Companies  ",value:"Overseas_Companies  "},
                  {label:"Call Center ",value:"Call_Center "},
                  {label:"Design/Printing/Publishing  ",value:"Design/Printing/Publishing  "},
                  {label:"Immigration & Education Consultanc Service ",value:"Immigration & Education_Consultancy_Service "},
                  {label:"Manpower Recruitment Agency  ",value:"Manpower_Recruitmen_Agency  "},
                  {label:"Multinational_Companies  ",value:"Multinational_Companies  "},
                  {label:"Research & Development Organization ",value:"Research & Development_Organization "},
                  {label:"Rental Service (Private car/Ambulance/ Bus/Truck/Cargo/Pick-up etc.) ",value:"Rental Service (Private car/Ambulance/ Bus/Truck/Cargo/Pick-up etc.) "},
                  {label:"Ride Sharing Service ",value:"Ride_Sharing_Service "},
                  {label:"Food Delivery Service  ",value:"Food_Delivery_Service  "},
                  {label:"Others (Please Specify) …………………………… ",value:"Others (Please Specify) …………………………… "},
  
                ]
            }
  
  ];