import { Form, Col } from 'react-bootstrap';
import styles from "./CommentInput.module.css";

const CommentInput = (props) => {

  //Props values
  const register = props.register
  const errors = props.errors
  const fieldName = props.fieldName
  const formData = props.formData
  const placeholder = props.placeholder
  const defaultValue = formData && formData[fieldName]? formData[fieldName] : null
  const size = props.size
  const md = props.md
  const as = props.as

  return (<>
        <Form.Group as={Col} lg={md}>
          <Form.Control className={styles.input_control} as = {as} type="text" size = {size} isInvalid={errors[fieldName]} placeholder={placeholder} defaultValue={defaultValue} {...register(fieldName)} />
          <div style={{ height: "25px", marginTop: "5px", fontSize:"12px", fontFamily:"poppins", textAlign: "left"}}>
            {errors[fieldName]? <p className = "text-danger">{errors[fieldName].message}</p> : null}
          </div>
        </Form.Group>
        </>
  )
};

export default CommentInput;