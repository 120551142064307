import * as yup from 'yup';
import store from 'src/redux/store';


//Regex
const string_regex = /^[A-Za-z][A-Za-z\s.]+$/;
const incorporation_regex = /^[A-Za-z][-](\d{5}|\d{6})[/](\d{2}|\d{4})$/
const e_tin_regex = /^(\d{12})$/
const bin_vat_regex = /^(((\d{9})[-](\d{4}))|\d{11})$/;
const trade_license_regex = /^(\d+)$/

//Error Messages
const incorporationNumError = "Enter in this format : C-000000/0000";
const eTinError = "Should Contain 12 digits"
const binVatError = "Should contain 11 digits or 9 digits (-) 4 digits"
const tradeLicenceError = "Should contain only digits"


const invalid = "Invalid Input Format";
const required = "Input Required";
const fileError = "Upload File(max size 2MB)"

const fileValidation = (value, fileName, size)=>{
    if (!value.length){
        const page2Data =  store.getState().customerState.page2_information
        if(page2Data[fileName] && page2Data[fileName].length!==0) return true
        return false
    }
    return value[0].size <= size
}

export const SecondPageSchema = yup.object().shape({
    typeOfCompany : yup.string().required(required).typeError(required),

    typeOfCompanyOthersText : yup.string().when("typeOfCompany",
                                {is : (value) => value!==null && value ==='other',
                                    then : yup.string().required(required)
                                }),

    yearOfEstablishment : yup.date().required(required).nullable(),
    sisterConcernCheck : yup.string().required(required).typeError(required),
    sisterConcernText :  yup.string().when("sisterConcernCheck",
                            { is: (val) => val === "yes",
                            then :yup.string().required(required).matches(string_regex, invalid )}
                            ),

    incorporationNumber : yup.string().required(required).matches(incorporation_regex,incorporationNumError),
    eTinNumber : yup.string().required(required).matches(e_tin_regex,eTinError),
    binVatNumber : yup.string().required(required).matches(bin_vat_regex,binVatError),
    tradeLicenseNumber : yup.string().required(required).matches(trade_license_regex,tradeLicenceError),

    incorporationFile : yup.mixed().test("fileSize", fileError, (value) => fileValidation(value, "incorporationFile", 2000000)),
    eTinFile : yup.mixed().test("fileSize", fileError, (value) => fileValidation(value, "eTinFile", 2000000)),
    binVatFile : yup.mixed().test("fileSize", fileError, (value) => fileValidation(value, "binVatFile", 2000000)),
    tradeLicenseFile : yup.mixed().test("fileSize", fileError, (value) => fileValidation(value, "tradeLicenseFile", 2000000)),
    
    industryTypeAndBusinesses : yup
        .array()
        .of(
            yup.object().shape({
            label: yup.string().required(required),
            value: yup.string().required(required)
        })).required(required)
}
);