import React, {useState, useEffect} from 'react';
import styles from './NumberInput.module.css';

const NumberInput = (props) => {

    //Props
    const getCount = props.getCount
    const existingCount = props.existingNumber

    const [count, setCount] = useState(0);

    useEffect(() => {
      if(existingCount) setCount(existingCount)
    }, [existingCount])

    const decrease = () => { 
      if(count>0){
        const updatedValue = count - 1
        setCount(updatedValue) ;
        getCount(updatedValue) 
      } 
    };

    const increase = () => {
      const updatedValue = count+1
      setCount(updatedValue); 
      getCount(updatedValue)
    }

    const change = e => {
      const userInput = e.target.value;
      if (userInput === "") {
        setCount("");
        getCount(0)
        return;
      }
      const num = parseInt(userInput);
      if (!isNaN(num)) {
        setCount(num);
        getCount(num);
      }
    };
    
    return (
      <div className="row number">
        <span className={`col-3 ${styles.minus}`} onClick={decrease}>
          -
        </span>
        <input
          className={`col-6 ${styles.num_input}`}
          type="text"
          value={count}
          onChange={change}
        />
        <span className={`col-3 ${styles.plus}`} onClick={increase}>
          +
        </span>
      </div>
    );
};

export default NumberInput;