const customerState = {
  formState: 0,
  page1_information : {},
  page2_information : {},
  page3_information : {}
}

export const customerStateReducer=(state = customerState,action)=>{
    switch (action.type){
        case 'FORM_STATE':
            return {...state, formState: action.payload}

        case 'PAGE1_INFORMATION_INSERT':
            return {...state, page1_information: action.payload}

        case 'PAGE2_INFORMATION_INSERT':
            return {...state, page2_information: action.payload}

        case 'PAGE3_INFORMATION_INSERT':
            return {...state, page3_information: action.payload}

        case 'CLEAR_DATA':
            return {...state, page1_information: { }, page2_information: { }, page3_information: { }}

      default:
          return state;
  }
}
