import * as yup from 'yup';

//Regex
const string_regex = /^[A-Za-z][A-Za-z\s.]+$/;
const contact_number_regex = /(^(\+8801|8801|01|008801))[3-9]{1}(\d){8}$/;
const nid_regex = /^(\d{10}|\d{13}|\d{17})$/;
const passport_regex = /^[A-Za-z0-9]+[0-9\s.]+$/;
const driving_license_regex = /^(\d{9})$/
const incorporation_regex = /^[A-Za-z][-](\d{6})[/](\d{2})$/
const e_tin_regex = /^(\d{12})$/
const bin_vat_regex = /^(((\d{9})[-](\d{4}))|\d{11})$/;
const trade_license_regex = /^[0-9][0-9]+$/;
const upload_or_input = "Please Enter the Number"
const designation_regex = /^[A-Za-z][A-Za-z\s./-:,]+$/;


//limits
const string_max = 100;
const min_limit = 1

//Error Messages

const invalid = "Invalid Format";
const required = "Input Required";
const max_limit = "Maximum Limit Exceeded"
const min_limit_msg = `Minimum ${min_limit} Items required`
const fileError = "Upload File(max size 2MB)"

export const ThirdPageSchema = yup.object().shape({

    ownerFirstName: yup.string().max(string_max, max_limit).required(required).matches(string_regex, invalid),
    ownerLastName: yup.string().max(string_max, max_limit).required(required).matches(string_regex, invalid),


    ownerDesignation: yup.string().max(string_max, max_limit).required(required).typeError(required),

    ownerContactNumber: yup.string().required(required).matches(contact_number_regex, invalid),

    ownerEmail: yup.string().max(string_max, max_limit).email(invalid).required(required),

    ownerPhoto: yup.mixed().test("fileSize", fileError, (value) => {
        if (!value.length) return false
        return value[0].size <= 2000000
    }),

    ownerNidNumber: yup.string().notRequired().test('ownerNidNumber', upload_or_input, (value) => {
        if (!!value) {
            const schema = yup.string().required().matches(nid_regex, invalid);
            return schema.isValidSync(value);
        }
    }),
    ownerPassportNumber: yup.string().notRequired().test('ownerPassportNumber', upload_or_input, function (value) {
        if (!!value) {
            const schema = yup.string().matches(passport_regex, invalid);
            return schema.isValidSync(value);
        }
        return false;
    }),

    ownerDrivingLicenseNumber: yup.string().notRequired().test('ownerDrivingLicenseNumber', upload_or_input, function (value) {
        if (!!value) {
            const schema = yup.string().matches(driving_license_regex, invalid);
            return schema.isValidSync(value);
        }
        return false;
    }),

    ownerNidAttachment: yup.mixed().when("ownerNidNumber",
        {
            is: (val) => val === "",
            then: yup.mixed().test("fileSize", fileError, (value) => {
                if (!value.length) return false
                return value[0].size <= 2000000
            }),
        }),

    ownerPassportAttachment: yup.mixed().when("ownerPassportNumber",
        {
            is: (val) => val === "",
            then: yup.mixed().test("fileSize", fileError, (value) => {
                if (!value.length) return false
                return value[0].size <= 2000000
            }),
        }),

    ownerDrivingLicenseAttachment: yup.mixed().when("ownerDrivingLicenseNumber",
        {
            is: (val) => val === "",
            then: yup.mixed().test("fileSize", fileError, (value) => {
                if (!value.length) return false
                return value[0].size <= 2000000
            }),
        }),

    providerOwnerSame: yup.boolean(),

    infoProviderFirstName: yup.string().when("providerOwnerSame", {
        is: false, then: yup.string().max(string_max, max_limit).required(required).matches(string_regex, invalid)
    }),

    infoProviderLastName: yup.string().when("providerOwnerSame", {
        is: false, then: yup.string().max(string_max, max_limit).required(required).matches(string_regex, invalid)
    }),

    infoProviderDesignation: yup.string().when("providerOwnerSame", {
        is: false, then: yup.string().max(string_max, max_limit).required(required).matches(designation_regex, invalid)
    }),

    infoProviderDepartment: yup.string().when("providerOwnerSame", {
        is: false, then: yup.string().max(string_max, max_limit).required(required).matches(string_regex, invalid)
    }),

    infoProviderContactNumber: yup.string().when("providerOwnerSame", {
        is: false, then: yup.string().required(required).matches(contact_number_regex, invalid)
    }),

    infoProviderEmail: yup.string().when("providerOwnerSame", {
        is: false, then: yup.string().max(string_max, max_limit).email(invalid).required(required),
    }),

    infoProviderSignature: yup.mixed().test("fileSize", fileError, (value) => {
        if (!value.length) return false
        return value[0].size <= 2000000
    }),
}
);