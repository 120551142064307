import styles from "./ListItem.module.css";
import { useHistory } from "react-router";
import { FaPencilAlt, FaTrash } from "react-icons/fa";
import employee_image from 'src/images/employee_image.png'
import { convertIsoToDate, isIsoDate } from "src/Utils/utils";

const ListItem = (props) => {

    //Props
    const name = props.name
    const profession = props.designation
    const id = props.id;
    const email = props.email;
    const phone = props.phone
    const joinDate = isIsoDate(props.joiningDate)? convertIsoToDate(props.joiningDate) : props.joiningDate
    const expireDate = isIsoDate(props.expireDate)? convertIsoToDate(props.expireDate) : props.expireDate
    const imageSrc = props.image
    const deleteItem = props.deleteItem

    const history = useHistory()

    return (
        <div className = {`card ${styles.card_item}`}>
            
            <div className = {`row ${styles.edit_row}`}>
               <div className = {`col-5 ${styles.edit_icon_col}`}
                    onClick={()=> history.push(`/data/edit/${id}`)}>
                        <FaPencilAlt/>
                </div>
                <div className = {`col-5 ${styles.delete_icon_col}`}
                    onClick={()=> deleteItem(id)}>
                        <FaTrash/>
                </div>
            </div>
            
            <img className={`rounded-circle ${styles.card_img}`} src= {imageSrc? imageSrc : employee_image} alt="..."/>
            
            <div className="p-0">
                <h5 className={`mt-4 ${styles.card_item_name}`}>{name}</h5>
                <div className={styles.profession_text}>{profession}</div>
            </div>

            <div className="row">
            
                <div className={`col-lg-6 ${styles.card_info}`}>
                    <div className={`${styles.card_info_title}`}>Front Side Info:</div>
                    <div className={`${styles.card_info_data}`}>
                        Id : {id}
                    </div>
                    <div className={`${styles.card_info_data}`}>
                        Email : {email}
                    </div>
                    <div className={`${styles.card_info_data}`}>
                        Phone : {phone}
                    </div>
                </div>

                <div className={`col-lg-6 ${styles.card_info}`}>
                    <div className={`${styles.card_info_title}`}>Back Side Info:</div>
                    <div className={`${styles.card_info_data}`}>
                        Join Date : {joinDate}
                    </div>
                    <div className={`${styles.card_info_data}`}>
                        Expire Date : {expireDate}
                    </div>
                </div>
            
            </div>

        </div>
    );
};

export default ListItem;