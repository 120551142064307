import React from 'react';
import styles from "./CDPRCOfficials.module.css"
import Label from 'src/components/Label/Label';

const CDPRCOfficials = (props) => {
    const name = props.name
    const email = props.email
    const phone = props.phone
    return (
        <>
            <Label
                label = "Information Of CDPRC Officials"
            />

            <div className = "row border p-3">
                <div className = {`row ${styles.value_text}`}>
                    <div className={`col-5 ${styles.key_text}`}>
                        Name :
                    </div>
                    {name}
                </div>
                <div className = {`row ${styles.value_text}`}>
                    <div className={`col-5 ${styles.key_text}`}>
                        Email :
                    </div>
                    {email}
                </div>
                <div className = {`row ${styles.value_text}`}>
                <div className={`col-5 ${styles.key_text}`}>
                    Phone No. :
                </div>
                {phone}
            </div>
            </div>
        </>
    );
};

export default CDPRCOfficials;