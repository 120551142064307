import React, {useState, useEffect} from 'react';
import styles from "./Summary.module.css"
import { useDispatch} from 'react-redux';
import RowItems from "src/components/TableItems/RowItems"

const Summary = (props) => {

    const dispatch = useDispatch()

    const getOptions = props.options
    
    const [options, setOptions] = useState([])

    useEffect(() => {
        setOptions(getOptions)
    }, [getOptions])

    const currency = options.length>0? options[0].currency : null


    const total =  options
                        .map(item => item.total)
                        .reduce((prev, curr) => prev + curr, 0);
    

    return (
        <>
            <div className="row">
            <div className="col-5 mt-2 d-flex justify-content-end">
                
            </div>
            </div>
            <div className = {`card ${styles.table_card}`}>
                <table className="table">
                <thead className={styles.table_head}>
                    <tr>
                    <th scope="col">Product</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Price</th>
                    </tr>
                </thead>
                <tbody>
                    <RowItems className = {styles.row_items}
                        Items = {options}
                    />
                </tbody>
                </table>
                <hr></hr>
                <div className={`row ${styles.subtotal_section_row}`}>
                    <div className="col">
                        Subtotal Price 
                    </div>
                    <div className="col">
                    </div>
                    <div className="col-3">
                     {total} {currency}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Summary;