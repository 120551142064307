import React from 'react';
import styles from "./RowItems.module.css"
import order_thumbnail from "src/images/order_thumbnail.png"

const RowItems = (props) => {

    const Items = props.Items

    return (
        <>
        {Items && Items.map(Item =>{
            return (
                <tr className = {styles.table_row}>
                    <td className = {`${styles.row_td}`}>
                    <div className = {`row d-flex justify-content-center ${styles.product_row}`}>
                        <img className = {`col ${styles.product_image}`} src = {Item.item_image} alt = ""/>
                        <div className = {`col d-flex align-items-center`}>
                            <div>
                            <div className = {`row ${styles.col_one_item_name}`}>{Item.item_name}</div>
                            <div className = {`row ${styles.col_one_item_details}`}>{Item.description}</div>
                            </div>
                        </div>
                    </div>
                    </td>
                    <td className = {`${styles.row_td}`} >
                        {Item.quantity}
                    </td>
                    <td className = {`${styles.row_td}`}>
                        {Item.total+ " " + Item.currency}
                    </td>
                </tr> 
            )
        })}
        </>
    );
};

export default RowItems;