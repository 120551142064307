import React, { Component } from 'react';

const Map = () => {
    return (
        <div className = "container">
        <div className="mapouter">
            <div className="gmap_canvas">
                <iframe class="gmap_iframe" frameborder="0" scrolling="no" width="100%" src="https://maps.google.com/maps?width=657&amp;height=192&amp;hl=en&amp;q=mirpur dohs&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
            </div>
        </div>
        </div>
    )
}
  
export default Map;
