import React, { Suspense, useState, useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import routes from 'src/routes'
import { useSelector } from 'react-redux'
import styles from "./TheLayout.module.css"
import Progress from 'src/components/Progress/Progress';
import TheHeader from './TheHeader';
import TheFooter from './TheFooter';
import Page401 from 'src/pages/Page401/Page401'

const loading = (<Progress />)

const isCDPRC = (routeName) => routeName.path.indexOf('cdprc') !== -1

const TheLayout = () => {

  const allowedPaths = ["/login", "/401", "/404" , "/500", "/home", "/register", "/", "/cdprc/login", "/contact" , "/user/register/state", "/user/state"] 
  
  const token = useSelector(state=> state.applicationState.token)

  const isAuthenticated = (route) =>{
    if(token) return true
    return allowedPaths.find(item=>item === route.path)
  }

    return (
    <div>
        <main>
          <Suspense fallback={loading}>
            <Switch>
              {routes.map((route, idx) => {
                return route.component && (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={props =>
                      <>
                        <TheHeader />
                        {isAuthenticated(route)? <route.component {...props} /> : <Page401/>}
                        {isCDPRC(route) ? null : <TheFooter />}
                      </>
                    }/>
                )
              })}
              <Redirect from="/" to="/home" />
            </Switch>
          </Suspense>
        </main>
      </div>
  )
}

export default React.memo(TheLayout)
