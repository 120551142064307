import React from 'react';
import styles from './Success.module.css'
import congrats_icon from "src/images/congrats_icon.png"



const Success = (props) => {

    const message = props.message

    return (
        <div className="container">
            <div className="row">
                <img className={styles.confirm_icon} src = {congrats_icon} alt = "..."></img>
            </div>

            <div className={`row ${styles.order_placed_text}`}>
                <span>{message}</span>
            </div>


        </div>
    );
};

export default Success;