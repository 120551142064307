import React from 'react';
import { useHistory } from 'react-router-dom';
import styles from './OrderCompleted.module.css'
import congrats_icon from "src/images/congrats_icon.png"
import CustomButton from "src/components/Button/CustomButton"
import { useDispatch } from 'react-redux';
import { orderStateDecrement } from 'src/redux/actions';


const OrderCompleted = (props) => {

    const order_id = props.order_id

    const history = useHistory()
    const dispatch = useDispatch

    return (
        <div className="container">
            <div className="row">
                <img className={styles.confirm_icon} src = {congrats_icon} alt = "..."></img>
            </div>
            <div className={`row ${styles.congratulations_text}`}>
                <span>Congratulations !</span>
            </div>

            <div className={`row ${styles.order_placed_text}`}>
                <span>Your order has been successfully placed.</span>
            </div>

            <div className={`row ${styles.order_id_text}`}>
                <span>Order ID : {order_id}</span>
            </div>

            <div className="row mt-3 mb-5 justify-content-center" >
            
                <div className="col-sm-4">

                    <CustomButton 
                        label = "Upload Your Id Card Contents" 
                        type = "button" 
                        onClick={() => history.push("/user/content/upload") }
                    />

                </div>

            </div>
        </div>
    );
};

export default OrderCompleted;