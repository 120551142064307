import React, { useEffect, useState } from 'react';
import styles from './TheHeader.module.css'
import header_logo from 'src/images/header_logo.png'
import {Nav, Navbar, NavDropdown} from "react-bootstrap"
import { useDispatch, useSelector } from 'react-redux';
import login_icon from 'src/images/login_icon.png'
import register_icon from 'src/images/register_icon.png'
import { useHistory } from 'react-router'
import {loggedOut} from "src/redux/actions" 
import { FaUser } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import CustomButton from '../Button/CustomButton';
import { apiProvider } from 'src/ApiProvider/ApiProvider';
import { useMsal } from '@azure/msal-react'

const TheHeader = () => {

    const history = useHistory()
    const dispatch = useDispatch()

    const token = useSelector(state=> state.applicationState.token)
    const userName = useSelector(state=> state.applicationState.userName)
    const type = useSelector(state=> state.applicationState.type)

    const { instance } = useMsal();

    const logOutHandler = () =>{
      dispatch(loggedOut())
      if(type === 'user') history.push('/login')
      else if(type === 'admin') logOutAdmin()
    }

    const logOutAdmin=()=>{
      instance.logoutPopup();
      history.push('/cdprc/login')
    }
    
    return (
        <header>
        <div className={styles.header}>
        <Navbar className={`container sticky-top ${styles.navbar_container}`} expand="lg">
            <Navbar.Brand href = {!token && !userName? "/home" : null} className={'font-weight-bold'}>
                <img className="w-100" src={header_logo} alt="CDPRC Logo"></img>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse>
            {!token?
              <>
                <Nav className="m-auto">
                    <NavLink className={styles.my_link}  to="/home" activeClassName={`is-active ${styles.myact}`}> Home </NavLink>
                    <NavLink className={styles.my_link}  to="/contact" activeClassName={`is-active ${styles.myact}`}> Contact Us </NavLink>
                </Nav>
                <Nav className="ml-auto">
                    <NavLink className={styles.my_link}  to="/login" activeClassName={`is-active ${styles.myact}`}>
                      <img style={{marginRight:"20px"}} src={login_icon} alt = "login_icon"/>
                      Login 
                    </NavLink>
                  <NavLink className={styles.my_link}  to="/register" activeClassName={`is-active ${styles.myact}`}> 
                      <img style={{marginRight:"20px"}}  className="pr-5" src={register_icon} alt = "register_icon"/> 
                      Register 
                  </NavLink>
                </Nav>

                <CustomButton 
                    label = "Order Now" 
                    onClick = {()=> history.push("/login")}
                    type = "button" 
                />
                </>
                  :
                  <span className={styles.my_dropdown_photo}>
                    <span className={styles.my_dropdown_icon}><FaUser/></span>
                    <NavDropdown
                      className={styles.my_dropdown_title}
                      title={userName? userName : "User"}
                      menuVariant="dark">
                        <NavDropdown.Item onClick={logOutHandler}> Log Out</NavDropdown.Item>
                      </NavDropdown> 
                  </span>  
            }
            </Navbar.Collapse>
        </Navbar>
        </div>
        </header>
    );
};

export default TheHeader;