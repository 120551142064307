import React from 'react';
import Label from 'src/components/Label/Label';
import styles from "./DesignationCheckBox.module.css"

const DesignationCheckBox = (props) => {
    //Props values
    const register = props.register
    const errors = props.errors
    const fieldName = props.fieldName
    const required = props.required
    const requiredText = props.requiredText
    const label = props.label
    const label_md = props.label_md
    const formData = props.formData

    const check = (text) => formData[fieldName] && formData[fieldName] === text

    return (
        <div className="row">

        <Label label = {label} label_md = {label_md} required = {required} requiredText={requiredText}/>

        <div className="col-xl-10">
        
        <div className="row">
        <div className="col-xl-3">
            <label className={`from-group  ${styles.checkbox_row}`}>
                <input className={`${styles.checkbox}`} disabled={true} checked={check("CEO")} {...register(fieldName)} value = "CEO" type = "radio"/>
                <span className={styles.checkbox_text}>CEO</span>
            </label>
        </div>

        <div className="col-xl-3">
            <label className={`from-group ${styles.checkbox_row}`}>
                <input className={`${styles.checkbox}`} disabled={true} checked={check("Managing Director")} {...register(fieldName)} value = "Managing Director" type = "radio"/>
                <span className={styles.checkbox_text}>Managing Director</span>
            </label>
        </div>


        <div className="col-xl-3">
            <label className={`from-group  ${styles.checkbox_row}`}>
                <input className={`form-check-input ${styles.checkbox}`} disabled={true} checked={check("Managing Partner")} {...register(fieldName)} value = "Managing Partner" type = "radio"/>
                <span className={styles.checkbox_text}>Managing Partner</span>
            </label>
        </div>


        <div className="col-xl-3">
            <label className={`from-group  ${styles.checkbox_row}`}>
                <input className={`form-check-input ${styles.checkbox}`} disabled={true} checked={check("Proprietor")} {...register(fieldName)} value = "Proprietor" type = "radio"/>
                <span className={styles.checkbox_text}>Proprietor</span>
            </label>
        </div>

        </div>

        <div style={{ height: "25px", marginTop: "-20px", fontSize:"12px", fontFamily:"poppins", textAlign: "left"}}>
            {errors[fieldName]? <p className = "text-danger">{errors[fieldName].message}</p> : null}
        </div>

        </div>

        </div>
    );
};

export default DesignationCheckBox;