import React from 'react';
import { Form, Col} from 'react-bootstrap'
import Label from "src/components/Label/Label"
import styles from "./Select.module.css"

const Select = (props) => {

  //Recieved Props
  const register = props.register
  const errors = props.errors
  const label = props.label
  const label_md = props.label_md
  const required = props.required
  const fieldName = props.fieldName
  const setSelected = props.setSelected
  const requiredText = props.requiredText
  const formData = props.formData
  const Items = props.items // Send an array 
  const value = props.value
  const size = props.size
  const lg = props.lg
  const readOnly = props.readOnly
  const disabled = props.disabled

  //Ref
  const fieldRef = register(fieldName)

  //methods

  const getValue = (item) => item[value]

  return (
    <>
    <Label label = {label} label_md = {label_md} required = {required} requiredText={requiredText}/>
    <Form.Group as={Col} lg={lg}>
    <Form.Control disabled={disabled} readOnly={readOnly} className={styles.select_control} as="select" isInvalid={errors[fieldName]} size = {size} name= {fieldName} ref={fieldRef.ref} onChange= {e=>{
      fieldRef.onChange(e);
      setSelected(e.target.value)
    }}>
        {formData && formData[fieldName]?
        <option value={formData[fieldName]} selected>{formData[fieldName]}</option>:
        <option value="" disabled selected hidden>Choose...</option>
        }
          {Items && Items.map(item =>{
            return <option value = {getValue(item)}>{getValue(item)}</option>
          })}
    </Form.Control>
    <div style={{ height: "25px", marginTop: "5px", fontSize:"12px", fontFamily:"poppins", textAlign: "left"}}>
      {errors[fieldName]? <p className= "text-danger">{errors[fieldName].message}</p> : null}
    </div>
    </Form.Group>
    </>
  );
};

export default Select;