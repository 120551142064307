import React, {useState,useEffect} from 'react';
import Label from 'src/components/Label/Label';
import styles from "./UploadBox.module.css"
import arrow_up from "src/images/arrow_up.png"
import excel_icon from "src/images/excel_icon.png"
import zip_icon from "src/images/zip_icon.png"

const UploadBox = (props) => {
    
    const register = props.register;
    const clearErrors = props.clearErrors
    const errors = props.errors
    const fieldName = props.fieldName
    const label = props.label
    const required = props.required
    const requiredText = props.requiredText
    const accept = props.accept
    const col_width = props.col_width
    const label_width = props.label_width
    const box_width = props.box_width
    const existing_file = props.file
    
    //From URL
    const filePath = props.filePath
    const fileType = props.fileType

    //State
    const [file , setFile] = useState()



    //Setting file if any file already passed through props for preview
    useEffect(() =>{
        if(existing_file){
            setFile(existing_file)
        }
        else if (filePath) setFile(filePath)
    },[])

    //MimeTypes
    const imageMimeTypes = ["image/jpg", "image/jpeg", "image/png"]

    const mimeExceptionHandle = {
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" :  excel_icon,
        "application/vnd.ms-excel": excel_icon,
        "application/x-zip-compressed": zip_icon,
        "application/zip" : zip_icon,
    } 

    //Extensions
    const imageExtensions = ["png", "jpeg", "jpg"]

    const extensionExceptionHandle = {
        "csv" : excel_icon,
        "xlsx": excel_icon,
        "zip": zip_icon
    } 

    const getImageSrc = (file) => {
        if(file && file.type){
            if(imageMimeTypes.find(item => item === file.type)){
                return URL.createObjectURL(file);
            }
            else return mimeExceptionHandle[file.type]
        }
        else if(filePath && fileType){
            if(imageExtensions.find(item => item === fileType)){
                console.log("I am Here")
                console.log(fileType)
                return filePath;
            }
            else return extensionExceptionHandle[file.type]
        }
    }
    
    const fileRef = register(fieldName)

    const onFileChange = e =>{
        if(e.target.files.length===0) return
        fileRef.onChange(e)
        setFile(e.target.files[0])
    }

    return (
        <div className={`col-lg-${col_width}`}>
            <div className="row d-flex align-items-center">
                <Label label = {label} label_md = {label_width} required = {required} requiredText={requiredText}/>
                    <div className={`col-lg-${box_width} ${styles.box_col}`}>
                        <div className="row">
                            <span className = {styles.file_name_box}>
                                {file?<span className = {styles.file_name}>{file.name}</span>: null}
                            </span>
                                <label>
                                    <div className={`row d-flex justify-content-center ${styles.preview_box}`}>
                                    {
                                    !file?
                                        <img className = {styles.box_up_arrow} src = {arrow_up} alt="Upload" />
                                        :
                                        getImageSrc(file)?
                                            <img className = {styles.preview_area} src = {getImageSrc(file)} alt="..." />
                                            :
                                            <iframe title="Document" className = {styles.preview_area} src = {filePath? filePath : URL.createObjectURL(file)} type = {fileType? fileType : file.type}/>
                                    }
                                    </div>
                                    <h6 className={styles.custom_input_button}>Upload</h6>
                                    <input type="file" accept= {accept} className={`form-control ${styles.input_file}`} ref={fileRef.ref} name = {fieldName} onChange = {onFileChange} />
                                </label>
                        </div>
                        <div style={{ height: "25px", marginTop: "5px", fontSize:"12px", fontFamily:"poppins", textAlign: "center"}}>
                            {errors[fieldName]? <p className = "text-danger">{errors[fieldName].message}</p> : null}
                        </div>
                    </div>
                </div>    
            </div>
    );
};

export default UploadBox;