import React, {useEffect, useState}  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import {formState} from 'src/redux/actions';
import styles from "./Form.module.css"
import FirstPage from './Page1/FirstPage';
import SecondPage from './Page2/SecondPage';
import ThirdPage from './Page3/ThirdPage'
import { useHistory, useParams } from 'react-router';
import CDPRCOnly from "./ApprovalPage/EnlistmentForm"
import { apiProvider } from 'src/ApiProvider/ApiProvider';
import CustomButton from 'src/components/Button/CustomButton';
import { FaArrowLeft } from 'react-icons/fa';


const useStyles = makeStyles((theme) => ({
  root: {
    marginTop:'2%',
    width: '100%',
    backgroundColor:'',
    alignItems: 'center',
  },
  instructions: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(1),
  },
}));


const getSteps = (step) =>{
  return ['Primary Information', 'Official Information' , 'Particulars', "CDPRC Only"];
}


const Form = () => {

  const history = useHistory()

  const [customerData, setCustomerData] = useState()

  const {id} = useParams()

  useEffect(() => {
    const getData = async () =>{
      const result = await apiProvider.getCustomerData(id)
      setCustomerData(result)
    }
    getData()
  }, [])

  const classes = useStyles();
  const activeStep = useSelector(state=> state.customerState.formState)
  const dispatch = useDispatch()
  const steps = getSteps();

  const handleReset = () => {
    dispatch(formState(0))
  };


  const getStepContent = (stepIndex, data) => {
    switch (stepIndex) {
      case 0:
          return <FirstPage customerData = {data}/>;
      case 1:
          return <SecondPage customerData = {data}/>;
      case 2:
          return <ThirdPage customerData = {data}/>;
      case 3:
          return <CDPRCOnly customerData = {data}/>
      default:
        return 'Unknown stepIndex';
    }
  }

  return (
    <div className = {classes.root}>
    <div className = {`sticky-stepper ${styles.fixedElement }`}>

        <div className="container d-flex mb-3">
          <FaArrowLeft className={styles.back_arrow}
              size = "25px"
            	onClick = {()=> history.goBack()}
          />
        </div>

    <div className = {styles.stepper_title}>Customer Enlistment Form</div>
      <Stepper  activeStep={customerData && customerData.status === 'Approved'? 4 : 3 } alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>
              <div className={styles.step_label}>{label}</div>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      </div>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>All steps completed</Typography>
            <Button onClick={handleReset}>Reset</Button>
          </div>
        ) : (
          <div>
            <Typography className={classes.instructions}>{ customerData? getStepContent(activeStep, customerData) : null }</Typography>
          </div>
        )}
      </div>
    </div>
  );
}

export default Form;