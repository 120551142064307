import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux'
import { clearData, formState , insertPage3Information } from 'src/redux/actions';
import Input from 'src/components/Input/Input';
import Title from 'src/components/Title/Title';
import UploadBox from 'src/components/UploadBox/UploadBox';
import { ThirdPageSchema } from './ThirdPageSchema';
import styles from "./ThirdPage.module.css"
import { apiProvider } from 'src/ApiProvider/ApiProvider';
import CustomButton from "src/components/Button/CustomButton"
import DesignationCheckBox from './DesignationCheckBox';
import { useHistory } from 'react-router-dom';
import { getFormData } from 'src/Utils/utils';

const ThirdPage = () => {

    useEffect(()=>{
        window.scrollTo(0,0);
    },[])

    const [isSame, setIsSame] = useState(false)

    //File Accept Strings
    const anyAttachments = "image/jpg, image/jpeg, image/png, .doc, .docx, .txt, .pdf"
    const onlyImage = "image/jpg, image/jpeg, image/png"

    const dispatch = useDispatch();
    const history = useHistory()

    const page1Data = useSelector(state=> state.customerState.page1_information) // See Changes

    const page2Data = useSelector(state=> state.customerState.page2_information) // See Changes

    //Methods
    const submit = async (event) => {

        const data = {
            ...event, 
            ownerPhotoFile : event["ownerPhotoFile"][0],
            ownerNidFile : event["ownerNidFile"][0],
            ownerPassportFile : event["ownerPassportFile"][0],
            ownerDrivingLicenseFile : event["ownerDrivingLicenseFile"][0],
            infoProviderSignFile : event["infoProviderSignFile"][0]
        }

        const page2Info = {
            ...page2Data,
            incorporationFile : page2Data["incorporationFile"][0],
            eTinFile : page2Data["eTinFile"][0],
            binVatFile : page2Data["binVatFile"][0],
            tradeLicenseFile : page2Data["tradeLicenseFile"][0]
        }

        dispatch(insertPage3Information(data))

        //All Data
        const finalData = {...page1Data, ...page2Info , ...data }
        
        //Form data of all collected data
        const collectedFormData = getFormData(finalData)

        //Post Data
        await apiProvider.postCustomer(collectedFormData)
        dispatch(clearData())
        history.push(`/user/state`)
    };

    const { register, handleSubmit, control, formState: { errors } } = useForm({
        resolver: yupResolver(ThirdPageSchema),
    });

    return (
        <div className="container-fuild p-0">

            <Form className="form container-fluid" onSubmit={handleSubmit(submit)}>

                <div className = "container">
                <div className="row">

                    <Title
                        label="CEO / MD / Director / Managing Partner / Owner Information"
                    />

                </div>


                <div className="row">

                    <Input
                        register={register}
                        errors={errors}
                        label="First Name"
                        fieldName="ownerFirstName"
                        required={true}
                        label_md="2"
                        md="4"
                        size="sm"
                    />

                    <Input
                        register={register}
                        errors={errors}
                        label="Last Name"
                        required={true}
                        fieldName="ownerLastName"
                        label_md="2"
                        md="4"
                        size="sm"
                    />

                </div>

                <div className="row">
                    <DesignationCheckBox
                        register={register}
                        errors={errors}
                        label="Designation"
                        label_md="2"
                        fieldName="ownerDesignation"
                        required={true}
                    />
                </div>

                <div className="row">

                    <Input
                        register={register}
                        errors={errors}
                        label="Contact Number"
                        required={true}
                        fieldName="ownerContactNumber"
                        label_md="2"
                        md="10"
                        size="sm"
                    />

                </div>

                <div className="row">

                    <Input
                        register={register}
                        errors={errors}
                        label="E-mail ID"
                        required={true}
                        fieldName="ownerEmail"
                        label_md="2"
                        md="10"
                        size="sm"
                    />

                </div>

                <div className="row">

                    <UploadBox
                        register={register}
                        errors={errors}
                        label="Photo"
                        required={true}
                        fieldName="ownerPhotoFile"
                        accept={onlyImage}
                        col_width={"6"}
                        label_width={"5"}
                        box_width={"6"}
                    />

                </div>


                <div className="row">

                    <Title
                        label="National ID No. / Passport No. / Driving License No. ( Write number or Upload Files ) of CEO :"
                    />

                </div>

                <div className="row">

                    <Input
                        register={register}
                        errors={errors}
                        label="National ID No."
                        fieldName="ownerNidNumber"
                        required={true}
                        label_md="2"
                        md="4"
                        size="sm"
                    />

                    <Input
                        register={register}
                        errors={errors}
                        label="Passport No."
                        required={true}
                        fieldName="ownerPassportNumber"
                        label_md="2"
                        md="4"
                        size="sm"
                    />

                </div>

                <div className="row">

                    <Input
                        register={register}
                        errors={errors}
                        label="Driving License No."
                        fieldName="ownerDrivingLicenseNumber"
                        required={true} s
                        label_md="2"
                        md="4"
                        size="sm"
                    />

                </div>


                <div className="row">

                    <UploadBox
                        register={register}
                        errors={errors}
                        label="National ID"
                        fieldName="ownerNidFile"
                        accept={anyAttachments}
                        required={true}
                        col_width={"6"}
                        label_width={"5"}
                        box_width={"6"}
                    />
                    <UploadBox
                        register={register}
                        errors={errors}
                        required={true}
                        label="Passport"
                        fieldName="ownerPassportFile"
                        accept={anyAttachments}
                        col_width={"6"}
                        label_width={"5"}
                        box_width={"6"}
                    />

                </div>

                <div className="row">

                    <UploadBox
                        register={register}
                        errors={errors}
                        required={true}
                        label="Driving License"
                        fieldName="ownerDrivingLicenseFile"
                        accept={anyAttachments}
                        col_width={"6"}
                        label_width={"5"}
                        box_width={"6"}
                    />

                </div>



                <div className="row">

                    <Title
                        label="Information Provider"
                    />

                </div>

                <div className="row">

                    <div className="col-xl-3">
                        <label className={`from-group  ${styles.checkbox_row}`}>
                            <input className={`${styles.checkbox}`} type="checkbox" {...register("providerOwnerSame")} onChange={e => setIsSame(e.target.checked)} />
                            <span className={styles.checkbox_text}>Same</span>
                        </label>
                    </div>

                </div>

                {isSame ?
                    null :
                    <>
                        <div className="row">

                            <Input
                                register={register}
                                errors={errors}
                                label="First Name"
                                fieldName="infoProviderFirstName"
                                required={true}
                                label_md="2"
                                md="4"
                                size="sm"
                            />

                            <Input
                                register={register}
                                errors={errors}
                                label="Last Name"
                                required={true}
                                fieldName="infoProviderLastName"
                                label_md="2"
                                md="4"
                                size="sm"
                            />

                        </div>


                        <div className="row">

                            <Input
                                register={register}
                                errors={errors}
                                label="Designation"
                                required={true}
                                fieldName="infoProviderDesignation"
                                label_md="2"
                                md="10"
                                size="sm"
                            />

                        </div>

                        <div className="row">

                            <Input
                                register={register}
                                errors={errors}
                                label="Department"
                                required={true}
                                fieldName="infoProviderDepartment"
                                label_md="2"
                                md="10"
                                size="sm"
                            />

                        </div>
                    

                        <div className="row">

                            <Input
                                register={register}
                                errors={errors}
                                label="Contact Number"
                                required={true}
                                fieldName="infoProviderContactNumber"
                                label_md="2"
                                md="10"
                                size="sm"
                            />

                        </div>

                        <div className="row">

                            <Input
                                register={register}
                                errors={errors}
                                label="E-mail ID"
                                required={true}
                                fieldName="infoProviderEmail"
                                label_md="2"
                                md="10"
                                size="sm"
                            />

                        </div>
                        </>
                    }

                        <div className="row">

                            <UploadBox
                                register={register}
                                errors={errors}
                                label="Signature"
                                required={true}
                                fieldName="infoProviderSignFile"
                                accept={onlyImage}
                                col_width={"6"}
                                label_width={"5"}
                                box_width={"6"}
                            />

                        </div>
                        </div>
                        <div className="container-fluid">
                            <div className="row mt-3 mb-5 justify-content-center" >
                                <div className="col-sm-2">

                                    <CustomButton
                                        label="Back"
                                        onClick={() => dispatch(formState(1))}
                                        type="button"
                                    />

                                </div>

                                <div className="col-sm-2">

                                    <CustomButton
                                        label="Submit"
                                        type="submit"
                                    />

                                </div>

                            </div>
                        </div>

            </Form>

        </div>
    );
};

export default ThirdPage;