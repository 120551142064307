import * as yup from 'yup';

//Regex
const string_regex = /^[A-Za-z][A-Za-z\s.]+$/;
const contact_number_regex = /(^(\+8801|8801|01|008801))[3-9]{1}(\d){8}$/;
const nid_regex = /^(\d{10}|\d{13}|\d{17})$/;
const passport_regex = /^[A-Za-z0-9]+[0-9\s.]+$/;
const driving_license_regex = /^(\d{9})$/
const incorporation_regex = /^[A-Za-z][-](\d{6})[/](\d{2})$/
const e_tin_regex = /^(\d{12})$/
const bin_vat_regex = /^(((\d{9})[-](\d{4}))|\d{11})$/;
const trade_license_regex = /^[0-9][0-9]+$/;
const upload_or_input = "Please Enter the Number"

//limits
const string_max = 100;
const min_limit = 1

//Error Messages

const invalid = "Invalid Format";
const required = "Input Required";
const fileError = "Upload File(max size 2MB)"

export const SecondPageSchema = yup.object().shape({
    typeOfCompany : yup.string().required(required).typeError(required),

    typeOfCompanyOthersText : yup.string().when("typeOfCompany",
                                {is : (value) => value!==null && value ==='other',
                                    then : yup.string().required(required)
                                }),

    yearOfEstablishment : yup.date().required(required).nullable(),
    sisterConcernCheck : yup.string().required(required).typeError(required),
    sisterConcernText :  yup.string().when("sisterConcernCheck",
                            { is: (val) => val === "yes",
                            then :yup.string().required(required).matches(string_regex, invalid )}
                            ),

    incorporationNumber : yup.string().required(required).matches(incorporation_regex,invalid),
    eTinNumber : yup.string().required(required).matches(e_tin_regex,invalid),
    binVatNumber : yup.string().required(required).matches(bin_vat_regex,invalid),
    tradeLicenseNumber : yup.string().required(required).matches(trade_license_regex,invalid),

    incorporationAttachment : yup.mixed().test("fileSize", fileError, (value) => {
        if (!value.length) return false
        return value[0].size <= 2000000}),

    eTinAttachment : yup.mixed().test("fileSize", fileError, (value) => {
        if (!value.length) return false
        return value[0].size <= 2000000}),

    binVatAttachment : yup.mixed().test("fileSize", fileError, (value) => {
        if (!value.length) return false
        return value[0].size <= 2000000}),

    tradeLicenseAttachment : yup.mixed().test("fileSize", fileError, (value) => {
        if (!value.length) return false
        return value[0].size <= 2000000}),
    
    industryTypeAndBusinesses : yup
        .array()
        .of(
            yup.object().shape({
            label: yup.string().required(required),
            value: yup.string().required(required)
        })).required(required)
}
);