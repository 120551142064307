import React, {useState, useEffect} from 'react'
import styles from "./Login.module.css"
import Input from "src/components/InputWithIcon/Input"
import { Form, Button} from 'react-bootstrap';
import { useDispatch , useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoginSchema } from './LoginSchema';
import { clearData, formState, loggedIn } from "src/redux/actions"
import { FaMailBulk, FaKey, FaFacebook, FaTwitter, FaGoogle, FaLinkedin } from 'react-icons/fa';
import { apiProvider } from 'src/ApiProvider/ApiProvider';
import { loggedOut } from 'src/redux/actions';

const Login = () => {
    const history = useHistory()
    const dispatch = useDispatch()

    //states
    const [checked, setChecked] = useState(false)
    const [logError, setLogError] = useState()

    const loggedInUserType = useSelector(state=>state.applicationState.type)

    if(loggedInUserType === 'admin') dispatch(loggedOut())

    const token = useSelector(state=>state.applicationState.token)

    useEffect(() => {
      const getName = async ()=> {
        if(token) {
          const customerInfo = await apiProvider.getCustomerInfoByToken()
          if(customerInfo && customerInfo.isRegistrationCompleted) history.push(`/user/dashboard`)
          else history.push(`/form`)
        }
      }
      getName()
    }, [])

    const submit = async(event) =>{
        const token = await apiProvider.login(event) // Sending username, password
        if(token){
            dispatch(loggedIn({token : token}))
            const customerInfo = await apiProvider.getCustomerInfoByToken()
            dispatch(loggedIn({token : token, userName : customerInfo.fullName, type : 'user'}))
            if(customerInfo && customerInfo.isRegistrationCompleted){
                history.push(`/user/dashboard`)
            }
            else{
                dispatch(clearData())
                dispatch(formState(0))
                history.push(`/form`)
            }
        }
        else{ 
            setLogError("Incorrect Username or Password")
        }
    }

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver : yupResolver(LoginSchema),
      });

    return (
        <div className={`container-fluid ${styles.containerfluid}`}>
            <div className = {styles.register_title}>
                Login to order preferred ID Card
            </div>

            <Form className = "form container" onSubmit={handleSubmit(submit)}>

                <div className="row d-flex justify-content-center">
                    <div className="col-md-5">

                        <div className="row">
                            <Input 
                                register = {register}
                                errors = {errors}
                                fieldName = "username"
                                icon = {<FaMailBulk/>}
                                placeholder = {"Email Id"}
                                type = {"email"}
                            />
                        </div>

                        <div className="row">
                            <Input 
                                register = {register}
                                errors = {errors}
                                fieldName = "password"
                                icon = {<FaKey/>}
                                placeholder = {"Password"}
                                type = {"password"}
                            />
                        </div>        

                        <div className="row mt-3">

                        <div className="col-md-8">
                            <div className={`form-check ${styles.terms_row}`}>
                                <input type="checkbox" className={`form-check-input ${styles.checkbox}`} onClick={()=>setChecked(!checked)} id="ch1"/>
                                <label className={`form-check-label ${styles.term_check}`} for="ch1"> Remember me </label>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <a className = {styles.forgot_pass_text} href="#/login">
                                Forgot Password?
                            </a>
                        </div>

                        </div>

                        <div className="row mt-4">
                            <div className={styles.error_msg}>{logError}</div>
                        </div>


                        <div className="row mt-3 mb-5">

                            <Button type="submit" className={styles.signup_btn}>Login</Button>

                        </div>

                    </div>
                </div>
                
                </Form>

                <div className="mb-5">
                <span className = {styles.already_signed}>
                    Don't have an account?
                </span>

                <a className = {styles.register_link} href="/register">
                    Sign Up
                </a>
                </div>
                {/*
                <div className={`row w-100 p-3 d-flex justify-content-center mt-5 mb-5`}>
                    <hr className={`col-2 mt-2 ${styles.hr_line}`}></hr>
                    <h6 className="col-1 ">or</h6>
                    <hr className={`col-2 mt-2 ${styles.hr_line}`}></hr>
                </div>


                <span className = {styles.already_signed}>
                    Continue With Social Media
                </span>

                <div className={`row d-flex justify-content-center mt-5 mb-5`}>
                    <div className = "col-sm-1 p-1" ><FaFacebook color={"#4267B2"} size={40}/></div>
                    <div className = "col-sm-1 p-1" ><FaTwitter color={"#55ACEE"} size={40}/></div>
                    <div className = "col-sm-1 p-1" ><FaGoogle color={"#F24E1E"} size={40}/></div>
                    <div className = "col-sm-1 p-1" ><FaLinkedin color={"#0077B5"} size={40}/></div>
                </div>
                */}

        </div>
    )
}

export default Login
