import React, {useState,useEffect} from 'react';
import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import axios from 'axios';
import TheLayout from "src/components/TheLayout/TheLayout"
import Progress from 'src/components/Progress/Progress';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom'
import store from './redux/store';
import { useDispatch } from 'react-redux';
import { loggedOut, registerStatus } from './redux/actions';

const App = () => {

    const [isLoading,setLoadingState] = useState(false)
    const dispatch = useDispatch()
    const history = useHistory()

    //Setting Axios Config
    axios.interceptors.request.use(
        config => {
        setLoadingState(true)
        const token = store.getState().applicationState.token
        if (token) {
            config.headers = { Authorization: `Bearer ${token}` } 
        }
        config.baseURL = "/api"
        return config;
        }
    );
    
    axios.interceptors.response.use((response)=>{
        setLoadingState(false)
        return response
    },
    
    (error) => {
        setLoadingState(false) 
        const status = error.response.status
        if(error.response.config.url !== "/users/login" && error.response.config.url !== "/users/register"){
            if(status === 401) { 
                dispatch(loggedOut(0)); 
                window.location.assign('/401') 
            }
            else if(status === 404) window.location.assign('/404')
            else window.location.assign('/500')   
        }
        else if(error.response.config.url === "/users/register"){
            if(status===400) dispatch(registerStatus(true))
        }
        return error
    }
    );

    return (
        <div className="App">
            <BrowserRouter>
                <React.Suspense fallback={(<Progress/>)}>
                    <Switch>
                        <Route path="/" name="Home">
                            {isLoading? <Progress/> :  null}
                            <TheLayout />
                        </Route>
                    </Switch>
                </React.Suspense>
            </BrowserRouter>
        </div>
    );
}

export default App;