import React from 'react'
import styles from "./ErrorPage.module.css"

const ErrorPage = () => {
  return (
    <div className="flex-row align-items-center">
      <div className="container">
        <div className={`row ${styles.centered}`}>
          <div className={`col-md-6 ${styles.centeredText}`}>
            <div className={"row"}>
              <h2 className="float-left mr-4">SOMETHING WENT WRONG !</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ErrorPage