export const getFormData = data => {
    const formData = new FormData();
    Object.keys(data).forEach(key =>{
        if(data[key]) formData.append(key, data[key])
    })
    return formData
}

export const status = {
    APPROVED : "approved",
    VERIFIED : "verified",
    PENDING  : "pending",
    REJECTED : "rejected",
    INPROGRESS : "inProgress",
    DELIVERED : "delivered",
    CANCELLED : "cancelled"
}

export const permissions = {
  APPROVE : "approve",
  VERIFY : "verify",
}

export const getStatusColor = status => {
    switch (status) {
      case 'approved': return '#178E03'
      case 'verified': return '#178E03'
      case 'pending': return '#FF9A62'
      case 'rejected' : return 'red'
      case 'delivered': return '#178E03'
      case 'inProgress': return '#7569BD'
      case 'cancelled' : return 'red'
      default: return 'primary'
    }
  }

  export const searchFunction = (arr, searchKey) => {
    return arr.filter(obj => Object.keys(obj).some(key => obj[key].includes(searchKey)));
  }

  export const isIsoDate = (str) =>{
    if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
    var d = new Date(str); 
    return d.toISOString()===str;
  }


  export const getExtension = (fileName) =>{
      const ext = fileName.split('.').pop()
      return ext
  }

  export const convertIsoToDate =(dateText) =>{
    const date = new Date(dateText);
    const year = date.getFullYear();
    var month = date.getMonth()+1;
    var dt = date.getDate();
    if (dt < 10) dt = '0' + dt;
    if (month < 10) month = '0' + month;
    return dt +'-'+ month + '-'+ year
  }


  // Text -> camelCase to Sentence
  export const camelCaseToNormalCase = (text) =>{
      const result = text.replace(/([A-Z])/g, " $1");
      return result.charAt(0).toUpperCase() + result.slice(1);
  }


  //Calculate Sum
  export const getSum = (options) =>{
    const orderSum = options.map(item => (item.price * item.quantity))
    const total =  orderSum
                      .map(item => item)
                      .reduce((prev, curr) => prev + curr, 0);
    return total
  }