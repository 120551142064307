import React, { useEffect, useState } from 'react';
import { Form, Button} from 'react-bootstrap';
import { useDispatch , useSelector } from 'react-redux';
import { formState, insertPage1Information} from "src/redux/actions";
import { yupResolver } from '@hookform/resolvers/yup'; 
import { useForm } from 'react-hook-form';
import Input from 'src/components/Input/Input';
import Title from 'src/components/Title/Title';
import Select from 'src/components/Select/Select';
import {FirstPageSchema } from './FirstPageSchema';
import CustomButton from "src/components/Button/CustomButton"
import styles from "./FirstPage.module.css"

import { divisions } from 'src/masterdata/divisions';
import { districts } from 'src/masterdata/districts';
import { subDistricts } from 'src/masterdata/subDistricts'
import { unions } from 'src/masterdata/unions'

const FirstPage = (props) => {

    useEffect(()=>{
        window.scrollTo(0,0);
    },[])

    const readOnly = props.readOnly
    const customerData = props.customerData

    const [page1data,setPage1Data] = useState(customerData)

    useEffect(() => {
        setPage1Data(customerData)
    }, [customerData])

    const dispatch = useDispatch()

    //States
    const [divisionList , setDivisionList] = useState(divisions)
    const [districtList, setDistrictList] = useState([])
    const [subDistrictList, setSubDistrictList] = useState([])
    const [unionList, setUnionList] = useState([])
    const [selectedDivision, setSelectedDivision] = useState()
    const [selectedDistrict, setSelectedDistrict] = useState()
    const [selectedSubDistrict, setSelectedSubDistrict] = useState()
    const [selectedThana, setSelectedThana] = useState()
    const [selectedCityCorporation, setSelectedCityCorporation] = useState()
    const [selectedUnion, setSelectedUnion] = useState()
    const [selectedPostOffice, setSelectedPostOffice] = useState()

    //useEffects

    useEffect(()=>{
        if(selectedDivision){
            const code = divisions.find(item=>item.division_name === selectedDivision).division_code
            const filteredDistricts = districts.filter(item => item.division_code === code)
          setDistrictList(filteredDistricts)
        }
    },[divisionList , selectedDivision])

    useEffect(()=>{
        if(selectedDistrict){
            const districtCode = districts.find(item=>item.district_name === selectedDistrict).district_code
            const filteredSubDistricts = subDistricts.filter(item => item.district_code === districtCode)
          setSubDistrictList(filteredSubDistricts)
        }
    },[districtList , selectedDistrict])

      useEffect(()=>{
        if(selectedDistrict){
            const upazilaCode = subDistricts.find(item=>item.upazila_name === selectedSubDistrict).upazila_code
            const filteredUnions = unions.filter(item => item.upazila_code === upazilaCode)
            setUnionList(filteredUnions)
        }
      },[selectedSubDistrict])
    

    //Methods
    const handleNext = async (event) => {
        dispatch(formState(1))
    };

    const { register, handleSubmit, formState: { errors } } = useForm({
      //resolver : yupResolver(FirstPageSchema),
    });


    const readOnlyPermission = true; 


    return (
        <div className="container-fuild p-0">

            <Form className = "form container" onSubmit={handleSubmit(handleNext)}>
            
                <div className = "row">
                
                    <Input
                      register={register}
                      errors = {errors}
                      label = "Company / Organization Name"
                      formData = {page1data}
                      fieldName="organizationName"
                      label_md = "2"
                      md = "10"
                      size = "sm"
                      readOnly = {readOnlyPermission}
                    />
                
                </div>

                <div className = "row">

                    <Title
                        label = "Address Of Company/Organization"
                    />

                </div>
            
                <div className = "row">

                    <Select
                      register = {register}
                      errors = {errors}
                      label = "Division / State"
                      label_md = {"2"}
                      required = {true}
                      fieldName = {"division"}
                      setSelected = {value => setSelectedDivision(value)}
                      formData = {page1data}
                      items = {divisionList}
                      value = "division_name"
                      size = "sm"
                      lg = "4"
                      readOnly = {readOnlyPermission}
                      disabled = {readOnlyPermission}
                    />

                    <Select
                      register = {register}
                      errors = {errors}
                      label = "District"
                      label_md = {"2"}
                      required = {true}
                      fieldName = {"district"}
                      setSelected = {value => setSelectedDistrict(value)}
                      formData = {page1data}
                      items = {districtList}
                      value = "district_name"
                      size = "sm"
                      lg = "4"
                      readOnly = {readOnlyPermission}
                      disabled = {readOnlyPermission}
                    />

              </div>


                      
              <div className = "row">

                  <Select
                    register = {register}
                    errors = {errors}
                    label = "Upazila"
                    label_md = {"2"}
                    required = {true}
                    fieldName = "upazila"
                    setSelected = {value => setSelectedSubDistrict(value)}
                    formData = {page1data}
                    items = {subDistrictList}
                    value = "upazila_name"
                    size = "sm"
                    lg = "4"
                    readOnly = {readOnlyPermission}
                    disabled = {readOnlyPermission}
                  />

                  <Select
                    register = {register}
                    errors = {errors}
                    label = {"Thana"}
                    label_md = {"2"}
                    required = {true}
                    fieldName = {"thana"}
                    setSelected = {value => setSelectedThana(value)}
                    formData = {page1data}
                    items = {subDistrictList}
                    value = "upazila_name"
                    size = "sm"
                    lg = "4"
                    readOnly = {readOnlyPermission}
                    disabled = {readOnlyPermission}
                  />

            </div>


                    
            <div className = "row">

                <Select
                  register = {register}
                  errors = {errors}
                  label = {"City Corporation / Municipality"}
                  label_md = {"2"}
                  required = {true}
                  fieldName = {"municipality"}
                  setSelected = {value => setSelectedCityCorporation(value)}
                  formData = {page1data}
                  items = {subDistrictList}
                  value = "upazila_name"
                  size = "sm"
                  lg = "4"
                  readOnly = {readOnlyPermission}
                  disabled = {readOnlyPermission}
                />

                <Select
                  register = {register}
                  errors = {errors}
                  label = {"Union"}
                  label_md = {"2"}
                  required = {true}
                  fieldName = {"union"}
                  setSelected = {value => setSelectedUnion(value)}
                  formData = {page1data}
                  items = {unionList}
                  value = "union_name"
                  size = "sm"
                  lg = "4"
                  readOnly = {readOnlyPermission}
                  disabled = {readOnlyPermission}
                />

          </div>


                  
          <div className = "row">

              <Select
                  register = {register}
                  errors = {errors}
                  label = {"Post Office"}
                  label_md = {"2"}
                  required = {true}
                  fieldName = {"postOffice"}
                  setSelected = {value => setSelectedPostOffice(value)}
                  formData = {page1data}
                  items = {unionList}
                  value = "union_name"
                  size = "sm"
                  lg = "4"
                  readOnly = {readOnlyPermission}
                  disabled = {readOnlyPermission}
              />

              <Input
                  register={register}
                  errors = {errors}
                  label = "House / Holding"
                  formData = {page1data}
                  fieldName="houseNumber"
                  required = {true}
                  label_md = "2"
                  md = "4"
                  size = "sm"
                  readOnly = {readOnlyPermission}
              /> 

        </div>

        <div className = "row text-left">

            <Input
                register={register}
                errors = {errors}
                label = "Village / Road"
                formData = {page1data}
                fieldName={"roadNumber"}
                label_md = "2"
                md = "4"
                size = "sm"
                readOnly = {readOnlyPermission}
            /> 

            <Input
                register={register}
                errors = {errors}
                label = "Post Code"
                formData = {page1data}
                fieldName="postCode"
                label_md = "2"
                md = "4"
                size = "sm"
                readOnly = {readOnlyPermission}
            /> 

        </div>


        <div className = "row">

            <Title
                label = "Primary Contact Person"
            />

        </div>


        <div className = "row">

            <Input
                register={register}
                errors = {errors}
                label = "First Name"
                formData = {page1data}
                fieldName="primaryPersonFirstName"
                required = {true}
                label_md = "2"
                md = "4"
                size = "sm"
                readOnly = {readOnlyPermission}
            /> 

            <Input
                register={register}
                errors = {errors}
                label = "Last Name"
                required = {true}
                formData = {page1data}
                fieldName="primaryPersonLastName"
                label_md = "2"
                md = "4"
                size = "sm"
                readOnly = {readOnlyPermission}
            /> 

        </div>


        <div className = "row">

            <Input
                register={register}
                errors = {errors}
                label = "Designation"
                required = {true}
                formData = {page1data}
                fieldName="primaryPersonDesignation"
                label_md = "2"
                md = "10"
                size = "sm"
                readOnly = {readOnlyPermission}
            /> 

        </div>

        <div className = "row">

            <Input
                register={register}
                errors = {errors}
                label = "Contact Number"
                required = {true}
                formData = {page1data}
                fieldName="primaryPersonContactNumber"
                label_md = "2"
                md = "10"
                size = "sm"
                readOnly = {readOnlyPermission}
            /> 

        </div>

        <div className = "row">

            <Input
                register={register}
                errors = {errors}
                label = "Official E-mail ID"
                required = {true}
                formData = {page1data}
                fieldName="primaryPersonOfficialEmailId"
                label_md = "2"
                md = "10"
                size = "sm"
                readOnly = {readOnlyPermission}
            /> 

        </div>

        <div className = "mt-4 mb-5">
            <CustomButton 
                label = "Next" 
                type = "submit" 
            />
        </div>

    </Form>
        
    </div>
    );
};

export default FirstPage;