import React from 'react';
import Label from 'src/components/Label/Label';
import styles from "./TypeOfCompanies.module.css"

const TypeOfCompanies = (props) => {
    //Props values
    const register = props.register;
    const errors = props.errors;
    const checkBoxFieldName = props.checkBoxFieldName
    const inputFieldName = props.inputFieldName
    const required = props.required
    const requiredText = props.requiredText
    const label = props.label
    const label_md = props.label_md
    const formData = props.formData

    const check = (text) => formData[checkBoxFieldName] && formData[checkBoxFieldName] === text

    return (
        <div className="row">

        <Label label = {label} label_md = {label_md} required = {required} requiredText={requiredText}/>

        <div className="col-xl-10">

            <div className="row">
                <div className="col-xl-3">
                <label className={`from-group  ${styles.checkbox_row}`}>
                    <input className={`${styles.checkbox}`} checked = {check("Private Ltd.")} type = "radio" {...register(checkBoxFieldName)} value = "Private Ltd."/>
                    <span className={styles.checkbox_text}>Private Ltd.</span>
                </label>
                </div>

                <div className="col-xl-3">
                <label className={`from-group ${styles.checkbox_row}`}>
                    <input className={`${styles.checkbox}`}checked = {check("Public Ltd.")} type = "radio" {...register(checkBoxFieldName)} value = "Public Ltd."/>
                    <span className={styles.checkbox_text}>Public Ltd.</span>
                </label>
                </div>

                <div className="col-xl-3">
                <label className={`from-group  ${styles.checkbox_row}`}>
                    <input className={`${styles.checkbox}`}checked = {check("Partnership")} type = "radio" {...register(checkBoxFieldName)} value = "Partnership"/>
                    <span className={styles.checkbox_text}>Partnership</span>
                </label>
                </div>

                <div className="col-xl-3">
                <label className={`from-group  ${styles.checkbox_row}`}>
                    <input className={`${styles.checkbox}`}checked = {check("Proprietorship")} type = "radio" {...register(checkBoxFieldName)} value = "Proprietorship"/>
                    <span className={styles.checkbox_text}>Proprietorship</span>
                </label>
                </div>
            </div>

            <div className="row">
                <div className="col-xl-3">
                <label className={`from-group  ${styles.checkbox_row}`}>
                    <input className={`${styles.checkbox}`}checked = {check("NGO")} type = "radio" {...register(checkBoxFieldName)} value = "NGO"/>
                    <span className={styles.checkbox_text}>NGO</span>
                </label>
                </div>

                <div className="col-xl-3">
                <label className={`from-group ${styles.checkbox_row}`}>
                    <input className={`${styles.checkbox}`}checked = {check("Govt.")} type = "radio" {...register(checkBoxFieldName)} value = "Govt."/>
                    <span className={styles.checkbox_text}>Govt.</span>
                </label>
                </div>

                <div className="col-xl-3">
                <label className={`from-group  ${styles.checkbox_row}`}>
                    <input className={`${styles.checkbox}`}checked = {check("Semi Govt.")} type = "radio" {...register(checkBoxFieldName)} value="Semi Govt."/>
                    <span className={styles.checkbox_text}>Semi Govt.</span>
                </label>
                </div>

                <div className="col-xl-3">
                <label className={`from-group  ${styles.checkbox_row}`}>
                    <input className={`${styles.checkbox}`}checked = {check("Govt. Agency")} type = "radio" {...register(checkBoxFieldName)} value = "Govt. Agency"/>
                    <span className={styles.checkbox_text}>Govt. Agency</span>
                </label>
                </div>
            </div>

            <div className="row">
                <div className="col-xl-3">
                <label className={`from-group  ${styles.checkbox_row}`}>
                    <input className={`${styles.checkbox}`}checked = {check("Law Enforcement Agency")} type = "radio" {...register(checkBoxFieldName)} value = "Law Enforcement Agency"/>
                    <span className={styles.checkbox_text}>Law Enforcement Agency</span>
                </label>
                </div>

                <div className="col-xl-3">
                    <label className={`from-group  ${styles.checkbox_row}`}>
                    <input className={`${styles.checkbox}`}checked = {check("other")} type = "radio" {...register(checkBoxFieldName)}  value = 'other'/>
                    <span className={styles.checkbox_text}><input className={styles.checkbox_input} defaultValue={formData[inputFieldName]} {...register(inputFieldName)} placeholder={"Others (Please specify)"} type="text"></input></span>
                </label>
                <div style={{height: "25px", marginTop: "-10px",fontSize:"12px", textAlign: "left"}}>
                    {errors[inputFieldName]? <p className= "text-danger">{errors[inputFieldName].message}</p>: null}
                </div>
                </div>
            </div>

        </div>
            <div style={{ height: "25px", marginTop: "-8px",fontFamily: "poppins", fontSize:"12px", textAlign: "left"}}>
                {errors[checkBoxFieldName] ? <p className= "text-danger">{errors[checkBoxFieldName].message}</p> : null}
            </div>
        </div>
    );
};

export default TypeOfCompanies;