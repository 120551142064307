import React from 'react'
import { Modal } from 'react-bootstrap'
import styles from "./ConfirmModal.module.css"
import confirm_modal_icon from "src/images/confirm_modal_icon.png"
import CustomButton from '../Button/CustomButton'

const ConfirmModal = (props) => {

    const selected = props.selected

    return (
        <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
            <div className="container">
                <div className="row mb-4">
                    <img className={styles.user_state_icon} src = {confirm_modal_icon} alt = "..."></img>
                </div>

                <h4 className="text-center p-5">Are You Sure?</h4>
          </div>
        </Modal.Body>
        <Modal.Footer  className="justify-content-center" id="modalFooter">
            <CustomButton 
                onClick={props.onHide} 
                label = "Close"
            />
            
            <CustomButton 
                onClick={props.onHide} 
                label = "Confirm"
                onClick={()=>{
                    selected(true)
                    props.onHide()
                }
                }
            />
        </Modal.Footer>
      </Modal>
    )
}

export default ConfirmModal