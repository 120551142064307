import React , {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { orderState } from "src/redux/actions";
import styles from "./Checkout.module.css"
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { OrderCheckoutSchema } from './OrderCheckoutSchema';
import Input from 'src/components/Input/Input';
import Title from 'src/components/Title/Title';
import Select from 'src/components/Select/Select';
import { Form } from 'react-bootstrap';
import {checkoutInformationInsert, orderId} from "src/redux/actions"
import CustomButton from 'src/components/Button/CustomButton'
import { countries } from 'src/masterdata/countries';
import { districts } from 'src/masterdata/districts';
import { apiProvider } from 'src/ApiProvider/ApiProvider';



const Checkout = () => {

    useEffect(()=>{
        window.scrollTo(0,0);
    },[])

    const orderData = useSelector(state=> state.orderState) // See Changes

    const selectedData = {
        idCardTemplate : [
                            { 
                                id : orderData.idCardTemplate.id, 
                                quantity : orderData.idCardTemplate.quantity,
                                price : orderData.idCardTemplate.price,
                                currency : orderData.idCardTemplate.currency
                            }
                        ],
        accessories : orderData.accessories.map(item=> {
                    return {
                                id : item.id ,
                                quantity : item.quantity,
                                price : item.price,
                                currency : item.currency
                            }})
    }

    const dispatch = useDispatch()

    //States
    const [isSame, setIsSame] = useState(false)
    const [selectedCountryBilling, setSelectedCountryBilling] = useState()
    const [selectedCityBilling, setSelectedCityBilling] = useState()
    const [selectedDistrictBilling, setSelectedDistrictBilling] = useState()
    const [selectedCountryShipping, setSelectedCountryShipping] = useState()
    const [selectedCityShipping, setSelectedCityShipping] = useState()
    const [selectedDistrictShipping, setSelectedDistrictShipping] = useState()

    const handleNext = async (event) => {
        dispatch(checkoutInformationInsert(event))
        const allData = {
            ...event, 
            ...selectedData,
        }
        const result = await apiProvider.postOrder(allData) //Expecting an Order Id
        dispatch(orderId(result))
        dispatch(orderState(4))
    };

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver : yupResolver(OrderCheckoutSchema),
    });
    

    return (
        <div className="container-fuild p-0">

            <Form className = "form" onSubmit={handleSubmit(handleNext)}>
            
            <div className="container">
                <div className = "row">

                    <Title
                        label = "Billing Address"
                    />

                </div>


                <div className = "row">

                    <Input
                        register={register}
                        errors = {errors}
                        label = "First Name"
                        fieldName="billingFirstName"
                        required = {true}
                        label_md = "2"
                        md = "4"
                        size = "sm"
                    /> 

                    <Input
                        register={register}
                        errors = {errors}
                        label = "Last Name"
                        required = {true}
                        fieldName="billingLastName"
                        label_md = "2"
                        md = "4"
                        size = "sm"
                    /> 

                </div>

                <div className = "row">
                
                    <Input
                      register={register}
                      errors = {errors}
                      label = "Company Name"
                      fieldName="billingCompanyName"
                      label_md = "2"
                      md = "10"
                      size = "sm"
                    />
                
                </div>

                <div className = "row">

                    <Select
                        register = {register}
                        errors = {errors}
                        label = {"Country / Region"}
                        label_md = {"2"}
                        required = {true}
                        fieldName = {"billingCountry"}
                        setSelected = {value => setSelectedCountryBilling(value)}
                        items = {countries}
                        value = "name"
                        size = "sm"
                        lg = "10"
                    />
    
                 </div>


                <div className = "row">
                            
                    <Input
                        register={register}
                        errors = {errors}
                        label = "Address"
                        fieldName="billingAddress"
                        required = {true}
                        label_md = "2"
                        md = "10"
                        size = "sm"
                        as = "textarea"
                    />
                
                </div>

                <div className = "row">

                    <Select
                        register = {register}
                        errors = {errors}
                        label = "Town / City"
                        label_md = "2"
                        required = {true}
                        fieldName = "billingCity"
                        setSelected = {value => setSelectedCityBilling(value)}
                        items = {districts}
                        value = "district_name"
                        size = "sm"
                        lg = "4"
                    />

                    <Select
                        register = {register}
                        errors = {errors}
                        label = "District"
                        label_md = "2"
                        required = {true}
                        fieldName = "billingDistrict"
                        setSelected = {value => setSelectedDistrictBilling(value)}
                        items = {districts}
                        value = "district_name"
                        size = "sm"
                        lg = "4"
                    />

                </div>


                <div className = "row">
    
                    <Input
                        register={register}
                        errors = {errors}
                        label = "Phone"
                        fieldName="billingPhoneNumber"
                        required = {true}
                        label_md = "2"
                        md = "4"
                        size = "sm"
                    /> 

                    <Input
                        register={register}
                        errors = {errors}
                        label = "E-Mail address"
                        fieldName="billingEmail"
                        required = {true}
                        label_md = "2"
                        md = "4"
                        size = "sm"
                    /> 

                </div>

                <div className = "row">
                            
                    <Input
                        register={register}
                        errors = {errors}
                        label = "Order Note"
                        fieldName="billingOrderNote"
                        required = {true}
                        label_md = "2"
                        md = "10"
                        size = "sm"
                        as = "textarea"
                    />
                
                </div>

                <div className = "row">

                    <Title
                        label = "Shipping Address"
                    />

                </div>

                <div className = "row">

                    <div className="col-xl-3">
                    <label className={`from-group  ${styles.checkbox_row}`}>
                        <input className={`${styles.checkbox}`} type = "checkbox" {...register("billingShippingSame")} onChange  = { e => setIsSame(e.target.checked)}/>
                        <span className={styles.checkbox_text}>Same as Billing Address</span>
                    </label>
                    </div>

                </div>
                
                {isSame?
                    null : 
                    <>
                    <div className = "row">

                        <Input
                            register={register}
                            errors = {errors}
                            label = "First Name"
                            fieldName="shippingFirstName"
                            required = {true}
                            label_md = "2"
                            md = "4"
                            size = "sm"
                        /> 

                        <Input
                            register={register}
                            errors = {errors}
                            label = "Last Name"
                            required = {true}
                            fieldName="shippingLastName"
                            label_md = "2"
                            md = "4"
                            size = "sm"
                        /> 

                    </div>

                    <div className = "row">
                    
                        <Input
                            register={register}
                            errors = {errors}
                            label = "Company Name"
                            fieldName="shippingCompanyName"
                            label_md = "2"
                            md = "10"
                            size = "sm"
                        />
                    
                    </div>

                    <div className = "row">

                        <Select
                            register = {register}
                            errors = {errors}
                            label = "Country / Region"
                            label_md = "2"
                            required = {true}
                            fieldName = "shippingCountry"
                            setSelected = {value => setSelectedCountryShipping(value)}
                            items = {countries}
                            value = "name"
                            size = "sm"
                            lg = "10"
                        />

                    </div>


                    <div className = "row">
                                
                        <Input
                            register={register}
                            errors = {errors}
                            label = "Address"
                            fieldName="shippingAddress"
                            required = {true}
                            label_md = "2"
                            md = "10"
                            size = "sm"
                            as = "textarea"
                        />
                    
                    </div>


                    <div className = "row">

                        <Select
                            register = {register}
                            errors = {errors}
                            label = "Town / City"
                            label_md = "2"
                            required = {true}
                            fieldName = "shippingCity"
                            setSelected = {value => setSelectedCityShipping(value)}
                            items = {districts}
                            value = "district_name"
                            size = "sm"
                            lg = "4"
                        />

                        <Select
                            register = {register}
                            errors = {errors}
                            label = "District"
                            label_md = "2"
                            required = {true}
                            fieldName = "shippingDistrict"
                            setSelected = {value => setSelectedDistrictShipping(value)}
                            items = {districts}
                            value = "district_name"
                            size = "sm"
                            lg = "4"
                        />

                    </div>



                    <div className = "row">

                        <Input
                            register={register}
                            errors = {errors}
                            label = "Phone"
                            fieldName="shippingPhoneNumber"
                            required = {true}
                            label_md = "2"
                            md = "4"
                            size = "sm"
                        /> 

                        <Input
                            register={register}
                            errors = {errors}
                            label = "E-Mail address"
                            fieldName="shippingEmail"
                            required = {true}
                            label_md = "2"
                            md = "4"
                            size = "sm"
                        /> 

                    </div>

                    <div className = "row">
                                
                        <Input
                            register={register}
                            errors = {errors}
                            label = "Order Note"
                            fieldName="shippingOrderNote"
                            required = {true}
                            label_md = "2"
                            md = "10"
                            size = "sm"
                            as = "textarea"
                        />
                    
                    </div>
                    </>
                }</div>

                <div className="row mt-3 mb-5 justify-content-center w-100" >
                    <div className="col-sm-2">
                        
                        <CustomButton 
                            label = "Back" 
                            onClick = {()=> dispatch(orderState(2))}
                            type = "button" 
                        />

                        </div>

                    <div className="col-sm-2">

                        <CustomButton 
                            label = "Next" 
                            type = "submit" 
                        />

                    </div>

                </div>


             </Form>
        
         </div>
    );
};

export default Checkout;

