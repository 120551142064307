import React, { useState } from 'react'
import { FaSearch } from 'react-icons/fa'
import { FiRefreshCcw } from 'react-icons/fi'
import { BsFilter } from 'react-icons/bs'
import styles from "./SearchBar.module.css"

const SearchBar = (props) => {

    const onChangeValue = props.onChangeValue
    const onSearchClick = props.onSearchClick
    const onRefreshClick = props.onRefreshClick

    const [text, setText] = useState('')

    return (
        <div>
            <div className={`container ${styles.container}`}>
                <div className="row w-100 mt-4">
                    <div className="col-4">
                    
                        <div className={`input-group ${styles.icon_input}`}>
                            <div className={`input-group-prepend`}>
                                <div className={`input-group-text ${styles.icon}`}><span className={styles.icon_file}><FaSearch/></span></div>
                            </div>
                            <input type="text" className={`form-control ${styles.input}`} value = {text} placeholder="Search" onChange={(e) =>{
                                    setText(e.target.value)
                                    onChangeValue(e.target.value)}
                                } />
                        </div>

                    </div>
                    <div className="col-3 d-flex justify-content-start align-items-center">
                        <button className={`${styles.search_btn}`} onClick={onSearchClick}>Search</button>
                        <button className={`${styles.refresh_btn}`} onClick={()=>{
                                setText('')
                                onRefreshClick()
                            }}><FiRefreshCcw/></button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SearchBar;