import React from 'react';
import styles from './UserRegisterState.module.css'
import mail_icon from 'src/images/mail_icon.png'

const UserRegisterState = () => {

    return (
        <div className="container vh-100">
            <div className="row">
                <div className={styles.user_state_icon}>
                    <img className={styles.mail_icon} src = {mail_icon} alt = "..."></img>
                </div>
            </div>
            <div className={`row ${styles.user_status_text}`}>
                <span>
                    Please check your email for account verification.
                </span>
            </div>
        </div>
    );
};

export default UserRegisterState;