import { Form, Col } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { Controller } from 'react-hook-form';
import Label from 'src/components/Label/Label';
import "react-datepicker/dist/react-datepicker.css";
import styles from "./DatePick.module.css"
import { isIsoDate } from 'src/Utils/utils';

const DatePick = (props) => {

  //Props values
  const control = props.control
  const errors = props.errors
  const fieldName = props.fieldName
  const required = props.required
  const formData = props.formData
  const defaultValue = formData && formData[fieldName] && isIsoDate(formData[fieldName])? new Date(formData[fieldName]) : null
  const requiredText = props.requiredText
  const label = props.label
  const label_md = props.label_md
  const md = props.md
  const disabled = props.disabled
  const setMaxToday = props.setMaxToday && new Date()

  return (<>
        <Label label = {label} label_md = {label_md} required = {required} requiredText={requiredText}/>
        <Form.Group as={Col} md={md}>
            <Controller
                control={control}
                name={fieldName}
                type="date"
                defaultValue={defaultValue}
                render={({ field: { onChange, value} }) => (
                    <DatePicker
                        disabled = {disabled}
                        type = "date"
                        onChange={onChange}
                        selected={value}
                        className={`form-control ${styles.date_control}`}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="dd/mm/yyyy"
                        maxDate={setMaxToday}
                    />
                    )}
            />
            <div style={{ height: "25px", marginTop: "5px", fontSize:"12px", fontFamily:"poppins", textAlign: "left"}}>
                {errors[fieldName] ? <p className= "text-danger">{errors[fieldName].message}</p> : null}
            </div>
            </Form.Group>
        </>
  )
};

export default DatePick;