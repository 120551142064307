import React, {useState, useEffect} from 'react';
import Summary from "src/components/Order/Summary"
import {useParams} from "react-router"
import styles from "./OrderSummary.module.css"
import {NavDropdown} from "react-bootstrap"
import { apiProvider } from 'src/ApiProvider/ApiProvider'
import { FaArrowLeft } from 'react-icons/fa';
import { useHistory } from 'react-router';
import { camelCaseToNormalCase, status } from 'src/Utils/utils';

const OrderSummary = () => {

    const {id} = useParams()

    const [orderData , setOrderData] = useState([])
    const [orderStatus , setOrderStatus] = useState("Change Status")

    const history = useHistory()

    const getTotalPrice = (price, count) => {
        return parseInt(price) * parseInt(count)
    } 
    
    useEffect(()=>{
        const getData = async () => {
            const allOrders = await apiProvider.getAllOrders()
            const selectedOrderDetails = allOrders.find(i=>i.id == id)
            setOrderStatus(selectedOrderDetails.status)
            const orderData = await apiProvider.getOrderItemsCdprc(id)
            const allOrderData = orderData? orderData.map(item=>{
                return (
                    {
                    item_name : item.name,
                    description  : item.details,
                    quantity : item.quantity,
                    total : getTotalPrice(item.quantity, item.price),
                    currency : orderData.length>0? orderData[0].currency : null,
                    item_image : item.image
                    }
                )
            }) : []
            setOrderData(allOrderData)
        }
        getData()
    },[])

    const updateOrderStatus = async ( id, status ) =>{
        await apiProvider.updateOrderStatus(id,status)
        setOrderStatus(status)
    }

    return (
        <div className="container">
            <div className={`row mt-4 ${styles.order_title_row}`}>

                    <div className="container d-flex mt-2 mb-5">
                        <FaArrowLeft className={styles.back_arrow}
                            size = "25px"
                                onClick = {()=> history.goBack()}
                        />
                    </div>

                    <div className={`h4 col-6 d-flex justify-content-start align-items-center ${styles.order_summary_title}`}>
                        Order Summary
                    </div>
 
                    <div className="col-5 mt-2 d-flex justify-content-end">
                    <NavDropdown
                      className={styles.my_dropdown_title}
                      title={camelCaseToNormalCase(orderStatus)}
                      menuVariant="light">
                      <NavDropdown.Item onClick = {()=>{updateOrderStatus(id, status.INPROGRESS)}}> In Progress </NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item onClick = {()=>{updateOrderStatus(id,status.DELIVERED)}}> Delivered </NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item onClick = {()=>{updateOrderStatus(id,status.CANCELLED)}}> Cancelled </NavDropdown.Item>
                      </NavDropdown> 
                    </div>
                </div>

            <Summary
                options = {orderData}
            />
        </div>
    );
};

export default OrderSummary;