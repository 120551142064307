import { Form, Col } from 'react-bootstrap';
import Label from "src/components/Label/Label"
import styles from "./Input.module.css";

const Input = (props) => {

  //Props values
  const register = props.register
  const errors = props.errors
  const fieldName = props.fieldName
  const required = props.required
  const requiredText = props.requiredText
  const formData = props.formData
  const defaultValue = formData!==undefined && formData[fieldName]!==undefined ? formData[fieldName] : null
  const label = props.label
  const size = props.size
  const md = props.md
  const label_md = props.label_md
  const as = props.as
  const readOnly  = props.readOnly

  return (<>
        <Label label = {label} label_md = {label_md} required = {required} requiredText={requiredText}/>
        <Form.Group as={Col} lg={md}>
          <Form.Control readOnly={readOnly} className={styles.input_control} as = {as} type="text" size = {size} isInvalid={errors[fieldName]} defaultValue={defaultValue} {...register(fieldName)} />
          <div style={{ height: "25px", marginTop: "5px", fontSize:"12px", fontFamily:"poppins", textAlign: "left"}}>
            {errors[fieldName]? <p className = "text-danger">{errors[fieldName].message}</p> : null}
          </div>
        </Form.Group>
        </>
  )
};

export default Input;