export const unions = [
    {
       "_id":1,
       "upazila_code":8,
       "union_code":1,
       "union_name":"Ward No-01",
       "union_ban_name":"ওয়ার্ড  নং ০১"
    },
    {
       "_id":2,
       "upazila_code":8,
       "union_code":2,
       "union_name":"Ward No-02",
       "union_ban_name":"ওয়ার্ড  নং ০২"
    },
    {
       "_id":3,
       "upazila_code":8,
       "union_code":3,
       "union_name":"Ward No-03",
       "union_ban_name":"ওয়ার্ড  নং ০৩"
    },
    {
       "_id":4,
       "upazila_code":8,
       "union_code":4,
       "union_name":"Ward No-04",
       "union_ban_name":"ওয়ার্ড  নং ০৪"
    },
    {
       "_id":5,
       "upazila_code":8,
       "union_code":5,
       "union_name":"Ward No-05",
       "union_ban_name":"ওয়ার্ড  নং ০৫"
    },
    {
       "_id":6,
       "upazila_code":8,
       "union_code":6,
       "union_name":"Ward No-06",
       "union_ban_name":"ওয়ার্ড  নং ০৬"
    },
    {
       "_id":7,
       "upazila_code":8,
       "union_code":7,
       "union_name":"Ward No-07",
       "union_ban_name":"ওয়ার্ড  নং ০৭"
    },
    {
       "_id":8,
       "upazila_code":8,
       "union_code":8,
       "union_name":"Ward No-08",
       "union_ban_name":"ওয়ার্ড  নং ০৮"
    },
    {
       "_id":9,
       "upazila_code":8,
       "union_code":9,
       "union_name":"Ward No-09",
       "union_ban_name":"ওয়ার্ড  নং ০৯"
    },
    {
       "_id":117,
       "upazila_code":51,
       "union_code":10,
       "union_name":"Ward No-10",
       "union_ban_name":"ওয়ার্ড  নং ১০"
    },
    {
       "_id":118,
       "upazila_code":51,
       "union_code":11,
       "union_name":"Ward No-11",
       "union_ban_name":"ওয়ার্ড  নং -১১"
    },
    {
       "_id":119,
       "upazila_code":51,
       "union_code":12,
       "union_name":"Ward No-12",
       "union_ban_name":"ওয়ার্ড  নং -১২"
    },
    {
       "_id":120,
       "upazila_code":51,
       "union_code":13,
       "union_name":"Ward No-13",
       "union_ban_name":"ওয়ার্ড  নং - ১৩"
    },
    {
       "_id":121,
       "upazila_code":51,
       "union_code":14,
       "union_name":"Ward No-14",
       "union_ban_name":"ওয়ার্ড  নং - ১৪"
    },
    {
       "_id":122,
       "upazila_code":51,
       "union_code":15,
       "union_name":"Ward No-15",
       "union_ban_name":"ওয়ার্ড  নং - ১৫"
    },
    {
       "_id":123,
       "upazila_code":51,
       "union_code":16,
       "union_name":"Ward No-16",
       "union_ban_name":"ওয়ার্ড  নং - ১৬"
    },
    {
       "_id":124,
       "upazila_code":51,
       "union_code":17,
       "union_name":"Ward No-17",
       "union_ban_name":"ওয়ার্ড  নং - ১৭"
    },
    {
       "_id":125,
       "upazila_code":51,
       "union_code":18,
       "union_name":"Ward No-18",
       "union_ban_name":"ওয়ার্ড  নং - ১৮"
    },
    {
       "_id":126,
       "upazila_code":51,
       "union_code":19,
       "union_name":"Ward No-19",
       "union_ban_name":"ওয়ার্ড  নং - ১৯"
    },
    {
       "_id":127,
       "upazila_code":51,
       "union_code":20,
       "union_name":"Ward No-20",
       "union_ban_name":"ওয়ার্ড নং -২০"
    },
    {
       "_id":128,
       "upazila_code":51,
       "union_code":21,
       "union_name":"Ward No-21",
       "union_ban_name":"ওয়ার্ড  নং - ২১"
    },
    {
       "_id":129,
       "upazila_code":51,
       "union_code":22,
       "union_name":"Ward No-22",
       "union_ban_name":"ওয়ার্ড  নং - ২২"
    },
    {
       "_id":130,
       "upazila_code":51,
       "union_code":23,
       "union_name":"Ward No-23",
       "union_ban_name":"ওয়ার্ড  নং -২৩"
    },
    {
       "_id":131,
       "upazila_code":51,
       "union_code":24,
       "union_name":"Ward No-24",
       "union_ban_name":"ওয়ার্ড  নং - ২৪"
    },
    {
       "_id":132,
       "upazila_code":51,
       "union_code":25,
       "union_name":"Ward No-25",
       "union_ban_name":"ওয়ার্ড  নং -২৫"
    },
    {
       "_id":133,
       "upazila_code":51,
       "union_code":26,
       "union_name":"Ward No-26",
       "union_ban_name":"ওয়ার্ড  নং -২৬"
    },
    {
       "_id":134,
       "upazila_code":51,
       "union_code":27,
       "union_name":"Ward No-27",
       "union_ban_name":"ওয়ার্ড  নং -২৭"
    },
    {
       "_id":135,
       "upazila_code":51,
       "union_code":28,
       "union_name":"Ward No-28",
       "union_ban_name":"ওয়ার্ড  নং -২৮"
    },
    {
       "_id":136,
       "upazila_code":51,
       "union_code":29,
       "union_name":"Ward No-29",
       "union_ban_name":"ওয়ার্ড নং -২৯"
    },
    {
       "_id":137,
       "upazila_code":51,
       "union_code":30,
       "union_name":"Ward No-30",
       "union_ban_name":"ওয়ার্ড  নং -৩০"
    },
    {
       "_id":229,
       "upazila_code":20,
       "union_code":21,
       "union_name":"Ward No-21 (Part)",
       "union_ban_name":"ওয়ার্ড  নং - ২১ (অংশ)"
    },
    {
       "_id":285,
       "upazila_code":85,
       "union_code":14,
       "union_name":"Ward No-14 (Part) Bogra Psa",
       "union_ban_name":"ওয়ার্ড  নং - ১৪ (অংশ) বগুড়া পিএসএ"
    },
    {
       "_id":286,
       "upazila_code":85,
       "union_code":21,
       "union_name":"Ward No-21 (Part) Bogra Psa",
       "union_ban_name":"ওয়ার্ড নং -২1 (অংশ) বগুড়া পিএসএ"
    },
    {
       "_id":482,
       "upazila_code":10,
       "union_code":35,
       "union_name":"Ward No-35 (Part)",
       "union_ban_name":"ওয়ার্ড  নং -৩৫(অংশ)"
    },
    {
       "_id":495,
       "upazila_code":20,
       "union_code":37,
       "union_name":"Ward No-37",
       "union_ban_name":"ওয়ার্ড  নং -৩৭"
    },
    {
       "_id":496,
       "upazila_code":20,
       "union_code":38,
       "union_name":"Ward No-38",
       "union_ban_name":"ওয়ার্ড  নং - ৩৮"
    },
    {
       "_id":497,
       "upazila_code":20,
       "union_code":39,
       "union_name":"Ward No-39",
       "union_ban_name":"ওয়ার্ড  নং - ৩৯"
    },
    {
       "_id":499,
       "upazila_code":28,
       "union_code":24,
       "union_name":"Ward No-24 (Part)",
       "union_ban_name":"ওয়ার্ড  নং -২৪ (অংশ)"
    },
    {
       "_id":504,
       "upazila_code":28,
       "union_code":36,
       "union_name":"Ward No-36",
       "union_ban_name":"ওয়ার্ড  নং -৩৬"
    },
    {
       "_id":505,
       "upazila_code":35,
       "union_code":11,
       "union_name":"Ward No-11 (Part)",
       "union_ban_name":"ওয়ার্ড  নং -১১ (অংশ)"
    },
    {
       "_id":506,
       "upazila_code":35,
       "union_code":24,
       "union_name":"Ward No-24(Part)",
       "union_ban_name":"ওয়ার্ড  নং -২৪ (অংশ)"
    },
    {
       "_id":510,
       "upazila_code":41,
       "union_code":16,
       "union_name":"Ward No-16 (Part)",
       "union_ban_name":"ওয়ার্ড  নং - ১৬ (অংশ)"
    },
    {
       "_id":514,
       "upazila_code":41,
       "union_code":31,
       "union_name":"Ward No-31",
       "union_ban_name":"ওয়ার্ড  নং - ১৩"
    },
    {
       "_id":515,
       "upazila_code":41,
       "union_code":32,
       "union_name":"Ward No-32",
       "union_ban_name":"ওয়ার্ড নং -২২"
    },
    {
       "_id":516,
       "upazila_code":41,
       "union_code":33,
       "union_name":"Ward No-33",
       "union_ban_name":"ওয়ার্ড  নং -৩৩"
    },
    {
       "_id":517,
       "upazila_code":41,
       "union_code":34,
       "union_name":"Ward No-34",
       "union_ban_name":"ওয়ার্ড  নং -৩৪"
    },
    {
       "_id":519,
       "upazila_code":43,
       "union_code":8,
       "union_name":"Ward No-08(Part)",
       "union_ban_name":"ওয়ার্ড  নং ০৮  (অংশ)"
    },
    {
       "_id":556,
       "upazila_code":65,
       "union_code":40,
       "union_name":"Ward No-40",
       "union_ban_name":"ওয়ার্ড  নং - ৪০"
    },
    {
       "_id":557,
       "upazila_code":65,
       "union_code":41,
       "union_name":"Ward No-41",
       "union_ban_name":"ওয়ার্ড  নং - ৪১"
    },
    {
       "_id":777,
       "upazila_code":2,
       "union_code":43,
       "union_name":"Ward No-43",
       "union_ban_name":"ওয়ার্ড  নং -৩৩"
    },
    {
       "_id":778,
       "upazila_code":2,
       "union_code":46,
       "union_name":"Ward No-46 (Part)",
       "union_ban_name":"ওয়ার্ড  নং -৪৬ (অংশ)"
    },
    {
       "_id":779,
       "upazila_code":4,
       "union_code":17,
       "union_name":"Ward No-17 (Part)",
       "union_ban_name":"ওয়ার্ড  নং - ১৭ (অংশ)"
    },
    {
       "_id":781,
       "upazila_code":5,
       "union_code":63,
       "union_name":"Ward No-63 (Part)",
       "union_ban_name":"ওয়ার্ড  নং -৬৩ (অংশ)"
    },
    {
       "_id":782,
       "upazila_code":5,
       "union_code":66,
       "union_name":"Ward No-66 (Part)",
       "union_ban_name":"ওয়ার্ড  নং -৬৬ (অংশ)"
    },
    {
       "_id":783,
       "upazila_code":5,
       "union_code":67,
       "union_name":"Ward No-67 (Part)",
       "union_ban_name":"ওয়ার্ড  নং -৬৭ (অংশ)"
    },
    {
       "_id":784,
       "upazila_code":5,
       "union_code":68,
       "union_name":"Ward No-68(Part)",
       "union_ban_name":"ওয়ার্ড  নং -৬৮ (অংশ)"
    },
    {
       "_id":785,
       "upazila_code":5,
       "union_code":69,
       "union_name":"Ward No-69",
       "union_ban_name":"ওয়ার্ড  নং -৬৯"
    },
    {
       "_id":786,
       "upazila_code":5,
       "union_code":70,
       "union_name":"Ward No-70",
       "union_ban_name":"ওয়ার্ড  নং -৭০"
    },
    {
       "_id":787,
       "upazila_code":5,
       "union_code":71,
       "union_name":"Ward No-71(Part)",
       "union_ban_name":"ওয়ার্ড  নং -  ৭১ (অংশ)"
    },
    {
       "_id":788,
       "upazila_code":6,
       "union_code":1,
       "union_name":"Ward No-01(Part)",
       "union_ban_name":"ওয়ার্ড  নং -০১ (অংশ)"
    },
    {
       "_id":789,
       "upazila_code":6,
       "union_code":98,
       "union_name":"Ward No-98(Rest. Area Part)",
       "union_ban_name":"ওয়ার্ড  নং -৯৮ (বিশ্রাম। এরিয়া অংশ)"
    },
    {
       "_id":790,
       "upazila_code":8,
       "union_code":15,
       "union_name":"Ward No-15 (Part)",
       "union_ban_name":"ওয়ার্ড  নং - ১৫ (অংশ)"
    },
    {
       "_id":791,
       "upazila_code":8,
       "union_code":98,
       "union_name":"Ward No-98 (Rest. Area)",
       "union_ban_name":"ওয়ার্ড  নং -৯৮ (বিশ্রাম। এলাকা)"
    },
    {
       "_id":793,
       "upazila_code":9,
       "union_code":64,
       "union_name":"Ward No-64",
       "union_ban_name":"ওয়ার্ড  নং -৬৪"
    },
    {
       "_id":794,
       "upazila_code":9,
       "union_code":65,
       "union_name":"Ward No-65",
       "union_ban_name":"ওয়ার্ড  নং -৬৫"
    },
    {
       "_id":808,
       "upazila_code":16,
       "union_code":47,
       "union_name":"Ward No-47(Part)",
       "union_ban_name":"ওয়ার্ড  নং -৪৭ (অংশ)"
    },
    {
       "_id":809,
       "upazila_code":16,
       "union_code":48,
       "union_name":"Ward No-48(Part)",
       "union_ban_name":"ওয়ার্ড  নং -৪৮ (অংশ)"
    },
    {
       "_id":810,
       "upazila_code":16,
       "union_code":49,
       "union_name":"Ward No-49",
       "union_ban_name":"ওয়ার্ড  নং -৪৯"
    },
    {
       "_id":820,
       "upazila_code":24,
       "union_code":76,
       "union_name":"Ward No-76 (Part)",
       "union_ban_name":"ওয়ার্ড  নং -৭৬ (অংশ)"
    },
    {
       "_id":821,
       "upazila_code":24,
       "union_code":80,
       "union_name":"Ward No-80 (Part)",
       "union_ban_name":"ওয়ার্ড  নং -৮০ (অংশ)"
    },
    {
       "_id":822,
       "upazila_code":24,
       "union_code":81,
       "union_name":"Ward No-81",
       "union_ban_name":"ওয়ার্ড  নং ০৮1"
    },
    {
       "_id":823,
       "upazila_code":24,
       "union_code":82,
       "union_name":"Ward No-82",
       "union_ban_name":"ওয়ার্ড  নং -৮২"
    },
    {
       "_id":825,
       "upazila_code":26,
       "union_code":19,
       "union_name":"Ward No-19 (Part)",
       "union_ban_name":"ওয়ার্ড  নং - ১৯ (অংশ)"
    },
    {
       "_id":826,
       "upazila_code":26,
       "union_code":20,
       "union_name":"Ward No-20 (Part)",
       "union_ban_name":"ওয়ার্ড  নং - ২০ (অংশ)"
    },
    {
       "_id":829,
       "upazila_code":28,
       "union_code":58,
       "union_name":"Ward No-58",
       "union_ban_name":"ওয়ার্ড  নং -  ৫৮"
    },
    {
       "_id":831,
       "upazila_code":29,
       "union_code":84,
       "union_name":"Ward No-84",
       "union_ban_name":"ওয়ার্ড  নং -৮৪"
    },
    {
       "_id":832,
       "upazila_code":29,
       "union_code":85,
       "union_name":"Ward No-85",
       "union_ban_name":"ওয়ার্ড  নং -৮৫"
    },
    {
       "_id":833,
       "upazila_code":29,
       "union_code":86,
       "union_name":"Ward No-86",
       "union_ban_name":"ওয়ার্ড  নং -৮৬"
    },
    {
       "_id":835,
       "upazila_code":30,
       "union_code":14,
       "union_name":"Ward No-14 (Part)",
       "union_ban_name":"ওয়ার্ড  নং - ১৪ (অংশ)"
    },
    {
       "_id":838,
       "upazila_code":32,
       "union_code":88,
       "union_name":"Ward No-88",
       "union_ban_name":"ওয়ার্ড  নং -৮৪"
    },
    {
       "_id":839,
       "upazila_code":32,
       "union_code":89,
       "union_name":"Ward No-89",
       "union_ban_name":"ওয়ার্ড  নং - ৮৯"
    },
    {
       "_id":840,
       "upazila_code":33,
       "union_code":50,
       "union_name":"Ward No-50",
       "union_ban_name":"ওয়ার্ড  নং -৫০"
    },
    {
       "_id":841,
       "upazila_code":33,
       "union_code":51,
       "union_name":"Ward No-51(Part)",
       "union_ban_name":"ওয়ার্ড  নং -৫১ (অংশ)"
    },
    {
       "_id":844,
       "upazila_code":36,
       "union_code":26,
       "union_name":"Ward No-26 (Part)",
       "union_ban_name":"ওয়ার্ড  নং -২৬ (অংশ)"
    },
    {
       "_id":848,
       "upazila_code":40,
       "union_code":72,
       "union_name":"Ward No-72",
       "union_ban_name":"ওয়ার্ড  নং -৭২"
    },
    {
       "_id":849,
       "upazila_code":40,
       "union_code":73,
       "union_name":"Ward No-73",
       "union_ban_name":"ওয়ার্ড  নং -৭৩"
    },
    {
       "_id":850,
       "upazila_code":42,
       "union_code":56,
       "union_name":"Ward No-56(Part)",
       "union_ban_name":"ওয়ার্ড  নং -৬৬ (অংশ)"
    },
    {
       "_id":851,
       "upazila_code":42,
       "union_code":59,
       "union_name":"Ward No-59",
       "union_ban_name":"ওয়ার্ড  নং -৫৯"
    },
    {
       "_id":852,
       "upazila_code":42,
       "union_code":60,
       "union_name":"Ward No-60",
       "union_ban_name":"ওয়ার্ড  নং -৬০"
    },
    {
       "_id":853,
       "upazila_code":42,
       "union_code":61,
       "union_name":"Ward No-61",
       "union_ban_name":"ওয়ার্ড নং -৬১"
    },
    {
       "_id":854,
       "upazila_code":42,
       "union_code":62,
       "union_name":"Ward No-62",
       "union_ban_name":"ওয়ার্ড নং -৬২"
    },
    {
       "_id":855,
       "upazila_code":42,
       "union_code":91,
       "union_name":"Ward No-91",
       "union_ban_name":"ওয়ার্ড নং -\t\n৯১"
    },
    {
       "_id":856,
       "upazila_code":42,
       "union_code":92,
       "union_name":"Ward No-92",
       "union_ban_name":"ওয়ার্ড নং -৯২"
    },
    {
       "_id":857,
       "upazila_code":48,
       "union_code":7,
       "union_name":"Ward No-07 (Part)",
       "union_ban_name":"ওয়ার্ড  নং -০৭(অংশ)"
    },
    {
       "_id":862,
       "upazila_code":50,
       "union_code":42,
       "union_name":"Ward No-42",
       "union_ban_name":"ওয়ার্ড  নং -৪২"
    },
    {
       "_id":863,
       "upazila_code":50,
       "union_code":44,
       "union_name":"Ward No-44",
       "union_ban_name":"ওয়ার্ড  নং -৪৪"
    },
    {
       "_id":864,
       "upazila_code":50,
       "union_code":45,
       "union_name":"Ward No-45",
       "union_ban_name":"ওয়ার্ড  নং \t\n৪৫"
    },
    {
       "_id":866,
       "upazila_code":50,
       "union_code":47,
       "union_name":"Ward No-47 (Part)",
       "union_ban_name":"ওয়ার্ড  নং -৪৭ (অংশ)"
    },
    {
       "_id":867,
       "upazila_code":50,
       "union_code":51,
       "union_name":"Ward No-51 (Part)",
       "union_ban_name":"ওয়ার্ড  নং -৫১ (অংশ)"
    },
    {
       "_id":872,
       "upazila_code":54,
       "union_code":35,
       "union_name":"Ward No-35",
       "union_ban_name":"ওয়ার্ড  নং ৩৫"
    },
    {
       "_id":873,
       "upazila_code":63,
       "union_code":52,
       "union_name":"Ward No-52",
       "union_ban_name":"ওয়ার্ড নং -৫২"
    },
    {
       "_id":879,
       "upazila_code":64,
       "union_code":15,
       "union_name":"Ward No-15(Part)",
       "union_ban_name":"ওয়ার্ড  নং - ১৫ (অংশ)"
    },
    {
       "_id":881,
       "upazila_code":66,
       "union_code":53,
       "union_name":"Ward No-53",
       "union_ban_name":"ওয়ার্ড নং -৫৩"
    },
    {
       "_id":882,
       "upazila_code":66,
       "union_code":54,
       "union_name":"Ward No-54",
       "union_ban_name":"ওয়ার্ড নং -৫৪"
    },
    {
       "_id":883,
       "upazila_code":66,
       "union_code":55,
       "union_name":"Ward No-55",
       "union_ban_name":"ওয়ার্ড নং -৫৫"
    },
    {
       "_id":902,
       "upazila_code":75,
       "union_code":57,
       "union_name":"Ward No-57",
       "union_ban_name":"ওয়ার্ড নং -৫৭"
    },
    {
       "_id":903,
       "upazila_code":76,
       "union_code":83,
       "union_name":"Ward No-83",
       "union_ban_name":"ওয়ার্ড  নং -৮৩"
    },
    {
       "_id":904,
       "upazila_code":76,
       "union_code":87,
       "union_name":"Ward No-87",
       "union_ban_name":"ওয়ার্ড  নং -৮৭"
    },
    {
       "_id":905,
       "upazila_code":76,
       "union_code":90,
       "union_name":"Ward No-90",
       "union_ban_name":"ওয়ার্ড  নং -৯০"
    },
    {
       "_id":906,
       "upazila_code":80,
       "union_code":40,
       "union_name":"Ward No-40 (Part)",
       "union_ban_name":"ওয়ার্ড  নং - ৪০ (অংশ)"
    },
    {
       "_id":908,
       "upazila_code":88,
       "union_code":74,
       "union_name":"Ward No-74",
       "union_ban_name":"ওয়ার্ড নং -৭৪"
    },
    {
       "_id":909,
       "upazila_code":88,
       "union_code":75,
       "union_name":"Ward No-75",
       "union_ban_name":"ওয়ার্ড নং -৭৫"
    },
    {
       "_id":910,
       "upazila_code":88,
       "union_code":77,
       "union_name":"Ward No-77",
       "union_ban_name":"ওয়ার্ড নং -৭৭"
    },
    {
       "_id":911,
       "upazila_code":88,
       "union_code":78,
       "union_name":"Ward No-78",
       "union_ban_name":"ওয়ার্ড নং-৭৮"
    },
    {
       "_id":912,
       "upazila_code":88,
       "union_code":79,
       "union_name":"Ward No-79",
       "union_ban_name":"ওয়ার্ড নং-৭৯"
    },
    {
       "_id":914,
       "upazila_code":90,
       "union_code":38,
       "union_name":"Ward No-38 (Part)",
       "union_ban_name":"ওয়ার্ড  নং - ৩৮ (অংশ)"
    },
    {
       "_id":916,
       "upazila_code":90,
       "union_code":40,
       "union_name":"Ward No-40(Part)",
       "union_ban_name":"ওয়ার্ড  নং - ৪০ (অংশ)"
    },
    {
       "_id":1224,
       "upazila_code":44,
       "union_code":11,
       "union_name":"Ward No-01 (Shayestagang)",
       "union_ban_name":"ওয়ার্ড  নং -০১ (শায়েস্তাগং)"
    },
    {
       "_id":1525,
       "upazila_code":21,
       "union_code":2,
       "union_name":"Ward No-02 (Part)",
       "union_ban_name":"ওয়ার্ড  নং -02 (অংশ)"
    },
    {
       "_id":1889,
       "upazila_code":56,
       "union_code":1,
       "union_name":"Ward No-01 (Mirkadim)",
       "union_ban_name":"ওয়ার্ড  নং -০১ (মিরকাদিম)"
    },
    {
       "_id":2090,
       "upazila_code":58,
       "union_code":11,
       "union_name":"Ward No-01 (Siddirganj)",
       "union_ban_name":"ওয়ার্ড  নং -০১ (সিদ্দরগঞ্জ)"
    },
    {
       "_id":2099,
       "upazila_code":68,
       "union_code":1,
       "union_name":"Ward No-01 (Kanchan)",
       "union_ban_name":"ওয়ার্ড  নং -০১ (কাঞ্চন)"
    },
    {
       "_id":2108,
       "upazila_code":68,
       "union_code":11,
       "union_name":"Ward No-01 (Tarabo)",
       "union_ban_name":"ওয়ার্ড  নং -০১ (তারাবো)"
    },
    {
       "_id":2126,
       "upazila_code":60,
       "union_code":1,
       "union_name":"Ward No-01 (Madhabdi)",
       "union_ban_name":"ওয়ার্ড  নং -০১ (মাধাবদি)"
    },
    {
       "_id":2135,
       "upazila_code":60,
       "union_code":11,
       "union_name":"Ward No-01 (Narsingdi)",
       "union_ban_name":"ওয়ার্ড  নং -০১ (নরসিংদী)"
    },
    {
       "_id":2537,
       "upazila_code":66,
       "union_code":61,
       "union_name":"Ward No-01 (Kuakata)",
       "union_ban_name":"ওয়ার্ড  নং -০১ (কুয়াকাটা)"
    },
    {
       "_id":2618,
       "upazila_code":10,
       "union_code":11,
       "union_name":"Ward No-01 (Arani)",
       "union_ban_name":"ওয়ার্ড  নং -০১ (আরানি)"
    },
    {
       "_id":2627,
       "upazila_code":12,
       "union_code":1,
       "union_name":"Ward No-01 (Bhabanigonj)",
       "union_ban_name":"ওয়ার্ড  নং -০১ (ভবানগঞ্জ)"
    },
    {
       "_id":2636,
       "upazila_code":12,
       "union_code":11,
       "union_name":"Ward No-01 (Tahirpur)",
       "union_ban_name":"ওয়ার্ড  নং -০১ (তাহিরপুর)"
    },
    {
       "_id":2646,
       "upazila_code":22,
       "union_code":10,
       "union_name":"Ward No-10 (Part)",
       "union_ban_name":"ওয়ার্ড  নং ১০ (অংশ)"
    },
    {
       "_id":2653,
       "upazila_code":22,
       "union_code":18,
       "union_name":"Ward No-18 (Part)",
       "union_ban_name":"ওয়ার্ড  নং - ১৮ (অংশ)"
    },
    {
       "_id":2662,
       "upazila_code":22,
       "union_code":27,
       "union_name":"Ward No-27 (Part)",
       "union_ban_name":"ওয়ার্ড  নং -২৭ (অংশ)"
    },
    {
       "_id":2690,
       "upazila_code":34,
       "union_code":11,
       "union_name":"Ward No-01 (Kakanhat)",
       "union_ban_name":"ওয়ার্ড  নং -০১ (কাকানহাট)"
    },
    {
       "_id":2711,
       "upazila_code":72,
       "union_code":1,
       "union_name":"Ward No-01 (Katakhali)",
       "union_ban_name":"ওয়ার্ড  নং -০১ (কাটখালী)"
    },
    {
       "_id":2720,
       "upazila_code":72,
       "union_code":11,
       "union_name":"Ward No-01 (Noahata)",
       "union_ban_name":"ওয়ার্ড  নং -০১ (নোহতা)"
    },
    {
       "_id":2759,
       "upazila_code":94,
       "union_code":11,
       "union_name":"Ward No-01 (Mundumala)",
       "union_ban_name":"ওয়ার্ড  নং -০১ (মুন্ডুমালা)"
    },
    {
       "_id":3196,
       "upazila_code":8,
       "union_code":17,
       "union_name":"Barai Para",
       "union_ban_name":"বারাই পারা"
    },
    {
       "_id":3197,
       "upazila_code":8,
       "union_code":25,
       "union_name":"Bemarta",
       "union_ban_name":"বেমার্তা"
    },
    {
       "_id":3198,
       "upazila_code":8,
       "union_code":34,
       "union_name":"Bishnupur",
       "union_ban_name":"বিষ্ণুপুর"
    },
    {
       "_id":3199,
       "upazila_code":8,
       "union_code":35,
       "union_name":"Dema",
       "union_ban_name":"ডেমা"
    },
    {
       "_id":3200,
       "upazila_code":8,
       "union_code":51,
       "union_name":"Gota Para",
       "union_ban_name":"গোটা প্যারা"
    },
    {
       "_id":3201,
       "upazila_code":8,
       "union_code":60,
       "union_name":"Jatrapur",
       "union_ban_name":"যাত্রাপুর"
    },
    {
       "_id":3202,
       "upazila_code":8,
       "union_code":69,
       "union_name":"Kara Para",
       "union_ban_name":"কারা পারা"
    },
    {
       "_id":3203,
       "upazila_code":8,
       "union_code":77,
       "union_name":"Khanpur",
       "union_ban_name":"খানপুর"
    },
    {
       "_id":3204,
       "upazila_code":8,
       "union_code":86,
       "union_name":"Rakhalgachhi",
       "union_ban_name":"রাখালগছি"
    },
    {
       "_id":3205,
       "upazila_code":8,
       "union_code":94,
       "union_name":"Shat Gambuj",
       "union_ban_name":"শাট গাম্বুজ"
    },
    {
       "_id":3206,
       "upazila_code":14,
       "union_code":15,
       "union_name":"Char Baniari",
       "union_ban_name":"চর ব্যানারী"
    },
    {
       "_id":3207,
       "upazila_code":14,
       "union_code":19,
       "union_name":"Bara Baria",
       "union_ban_name":"বার বারিয়া"
    },
    {
       "_id":3208,
       "upazila_code":14,
       "union_code":31,
       "union_name":"Chitalmari",
       "union_ban_name":"চিতলমারি।"
    },
    {
       "_id":3209,
       "upazila_code":14,
       "union_code":47,
       "union_name":"Hizla",
       "union_ban_name":"হিজলা"
    },
    {
       "_id":3210,
       "upazila_code":14,
       "union_code":63,
       "union_name":"Santoshpur",
       "union_ban_name":"সন্তোষপুর"
    },
    {
       "_id":3211,
       "upazila_code":14,
       "union_code":66,
       "union_name":"Kalatala",
       "union_ban_name":"কালাতালা"
    },
    {
       "_id":3212,
       "upazila_code":14,
       "union_code":79,
       "union_name":"Shibpur",
       "union_ban_name":"শিবপুর"
    },
    {
       "_id":3213,
       "upazila_code":34,
       "union_code":10,
       "union_name":"Bahirdia Mansa",
       "union_ban_name":"বাহিরদিয়া মানসা"
    },
    {
       "_id":3214,
       "upazila_code":34,
       "union_code":21,
       "union_name":"Betaga",
       "union_ban_name":"বেতাগা"
    },
    {
       "_id":3215,
       "upazila_code":34,
       "union_code":31,
       "union_name":"Fakirhat",
       "union_ban_name":"ফকিরহাট"
    },
    {
       "_id":3216,
       "upazila_code":34,
       "union_code":42,
       "union_name":"Lakhpur",
       "union_ban_name":"লাখপুর"
    },
    {
       "_id":3217,
       "upazila_code":34,
       "union_code":52,
       "union_name":"Mulghar",
       "union_ban_name":"মুলঘার"
    },
    {
       "_id":3218,
       "upazila_code":34,
       "union_code":63,
       "union_name":"Naldha Maubhog",
       "union_ban_name":"নলদা মাউহগ"
    },
    {
       "_id":3219,
       "upazila_code":34,
       "union_code":73,
       "union_name":"Piljanga",
       "union_ban_name":"পিলজাঙ্গা"
    },
    {
       "_id":3220,
       "upazila_code":34,
       "union_code":84,
       "union_name":"Subhadia",
       "union_ban_name":"সুহাদিয়া"
    },
    {
       "_id":3221,
       "upazila_code":38,
       "union_code":15,
       "union_name":"Badhal",
       "union_ban_name":"বদল"
    },
    {
       "_id":3222,
       "upazila_code":38,
       "union_code":28,
       "union_name":"Dhopakhali",
       "union_ban_name":"ধোপাখালী"
    },
    {
       "_id":3223,
       "upazila_code":38,
       "union_code":38,
       "union_name":"Gazalia",
       "union_ban_name":"গাজালিয়া"
    },
    {
       "_id":3224,
       "upazila_code":38,
       "union_code":47,
       "union_name":"Gopalpur",
       "union_ban_name":"গোপালপুর"
    },
    {
       "_id":3225,
       "upazila_code":38,
       "union_code":57,
       "union_name":"Kachua",
       "union_ban_name":"কচুয়া"
    },
    {
       "_id":3226,
       "upazila_code":38,
       "union_code":66,
       "union_name":"Maghia",
       "union_ban_name":"মাঘিয়া"
    },
    {
       "_id":3227,
       "upazila_code":38,
       "union_code":76,
       "union_name":"Rari Para",
       "union_ban_name":"রারি প্যারা"
    },
    {
       "_id":3228,
       "upazila_code":56,
       "union_code":17,
       "union_name":"Atjuri",
       "union_ban_name":"আতিঝুরি"
    },
    {
       "_id":3229,
       "upazila_code":56,
       "union_code":28,
       "union_name":"Chunkhola",
       "union_ban_name":"চুনখোলা"
    },
    {
       "_id":3230,
       "upazila_code":56,
       "union_code":38,
       "union_name":"Gangni",
       "union_ban_name":"গঙ্গী"
    },
    {
       "_id":3231,
       "upazila_code":56,
       "union_code":47,
       "union_name":"Gaola",
       "union_ban_name":"গাওলা"
    },
    {
       "_id":3232,
       "upazila_code":56,
       "union_code":76,
       "union_name":"Kodalia",
       "union_ban_name":"কোদালিয়া"
    },
    {
       "_id":3233,
       "upazila_code":56,
       "union_code":85,
       "union_name":"Kulia",
       "union_ban_name":"কুলিয়া"
    },
    {
       "_id":3234,
       "upazila_code":56,
       "union_code":95,
       "union_name":"Udaypur",
       "union_ban_name":"উদয়পুর"
    },
    {
       "_id":3235,
       "upazila_code":58,
       "union_code":23,
       "union_name":"Chandpi",
       "union_ban_name":"চাঁদপী"
    },
    {
       "_id":3236,
       "upazila_code":58,
       "union_code":27,
       "union_name":"Burirdanga",
       "union_ban_name":"বীরদিঙ্গা"
    },
    {
       "_id":3237,
       "upazila_code":58,
       "union_code":29,
       "union_name":"Chila",
       "union_ban_name":"চিলা"
    },
    {
       "_id":3238,
       "upazila_code":58,
       "union_code":59,
       "union_name":"Mithakhali",
       "union_ban_name":"মিঠখালী"
    },
    {
       "_id":3239,
       "upazila_code":58,
       "union_code":83,
       "union_name":"Suniltala",
       "union_ban_name":"সূরালা"
    },
    {
       "_id":3240,
       "upazila_code":58,
       "union_code":89,
       "union_name":"Sundarban",
       "union_ban_name":"সুন্দরবন"
    },
    {
       "_id":3241,
       "upazila_code":60,
       "union_code":10,
       "union_name":"Baharbunia",
       "union_ban_name":"বাহারবুনিয়া"
    },
    {
       "_id":3242,
       "upazila_code":60,
       "union_code":11,
       "union_name":"Balaibunia",
       "union_ban_name":"বালিবুনিয়া"
    },
    {
       "_id":3243,
       "upazila_code":60,
       "union_code":17,
       "union_name":"Banagram",
       "union_ban_name":"বনগ্রাম"
    },
    {
       "_id":3244,
       "upazila_code":60,
       "union_code":23,
       "union_name":"Baraikhali",
       "union_ban_name":"বরাইখালী"
    },
    {
       "_id":3245,
       "upazila_code":60,
       "union_code":29,
       "union_name":"Chingrakhali",
       "union_ban_name":"চিংগ্রখালী"
    },
    {
       "_id":3246,
       "upazila_code":60,
       "union_code":35,
       "union_name":"Daibagnyahati",
       "union_ban_name":"দাইবাগনিয়াহাটি"
    },
    {
       "_id":3247,
       "upazila_code":60,
       "union_code":41,
       "union_name":"Hoglabunia",
       "union_ban_name":"হোগ্লাবুনিয়া"
    },
    {
       "_id":3248,
       "upazila_code":60,
       "union_code":47,
       "union_name":"Hogla Pasha",
       "union_ban_name":"হগলা পাশা"
    },
    {
       "_id":3249,
       "upazila_code":60,
       "union_code":53,
       "union_name":"Jiudhara",
       "union_ban_name":"জিউধারা"
    },
    {
       "_id":3250,
       "upazila_code":60,
       "union_code":59,
       "union_name":"Khuolia",
       "union_ban_name":"খোলিয়া"
    },
    {
       "_id":3251,
       "upazila_code":60,
       "union_code":65,
       "union_name":"Morrelganj",
       "union_ban_name":"মোরেলগঞ্জ"
    },
    {
       "_id":3252,
       "upazila_code":60,
       "union_code":71,
       "union_name":"Nishanbaria",
       "union_ban_name":"নিশানবাড়িয়া"
    },
    {
       "_id":3253,
       "upazila_code":60,
       "union_code":77,
       "union_name":"Panchakaran",
       "union_ban_name":"পঞ্চকরন"
    },
    {
       "_id":3254,
       "upazila_code":60,
       "union_code":83,
       "union_name":"Putikhali",
       "union_ban_name":"পুটিখালি."
    },
    {
       "_id":3255,
       "upazila_code":60,
       "union_code":89,
       "union_name":"Ramchandrapur",
       "union_ban_name":"রামচন্দ্রপুর"
    },
    {
       "_id":3256,
       "upazila_code":60,
       "union_code":95,
       "union_name":"Teligati",
       "union_ban_name":"তেলিগতি"
    },
    {
       "_id":3257,
       "upazila_code":73,
       "union_code":10,
       "union_name":"Baintala",
       "union_ban_name":"বেন্টাল"
    },
    {
       "_id":3258,
       "upazila_code":73,
       "union_code":11,
       "union_name":"Banshtali",
       "union_ban_name":"বাঁশতলী"
    },
    {
       "_id":3259,
       "upazila_code":73,
       "union_code":17,
       "union_name":"Bhojpatia",
       "union_ban_name":"ভোজপতিয়া"
    },
    {
       "_id":3260,
       "upazila_code":73,
       "union_code":41,
       "union_name":"Gaurambha",
       "union_ban_name":"গৌরম্বমা"
    },
    {
       "_id":3261,
       "upazila_code":73,
       "union_code":47,
       "union_name":"Hurka",
       "union_ban_name":"হুরকা"
    },
    {
       "_id":3262,
       "upazila_code":73,
       "union_code":53,
       "union_name":"Malliker Ber",
       "union_ban_name":"ম্যালিকার বার"
    },
    {
       "_id":3263,
       "upazila_code":73,
       "union_code":71,
       "union_name":"Perikhali",
       "union_ban_name":"পেরাইখালী"
    },
    {
       "_id":3264,
       "upazila_code":73,
       "union_code":77,
       "union_name":"Rajnagar",
       "union_ban_name":"রাজনগর"
    },
    {
       "_id":3265,
       "upazila_code":73,
       "union_code":83,
       "union_name":"Rampal",
       "union_ban_name":"রামপাল"
    },
    {
       "_id":3266,
       "upazila_code":73,
       "union_code":94,
       "union_name":"Ujalkur",
       "union_ban_name":"উজাকুর"
    },
    {
       "_id":3267,
       "upazila_code":77,
       "union_code":19,
       "union_name":"Dhansagar",
       "union_ban_name":"ধনগরগর"
    },
    {
       "_id":3268,
       "upazila_code":77,
       "union_code":38,
       "union_name":"Khontakata",
       "union_ban_name":"খন্টাকটা"
    },
    {
       "_id":3269,
       "upazila_code":77,
       "union_code":57,
       "union_name":"Royenda",
       "union_ban_name":"রায়েন্ডা"
    },
    {
       "_id":3270,
       "upazila_code":77,
       "union_code":76,
       "union_name":"Dakhin Khali",
       "union_ban_name":"দখিন খালি"
    },
    {
       "_id":3271,
       "upazila_code":4,
       "union_code":31,
       "union_name":"Alikadam",
       "union_ban_name":"আলিকাদাম"
    },
    {
       "_id":3272,
       "upazila_code":4,
       "union_code":63,
       "union_name":"Chokhyong",
       "union_ban_name":"চোখইয়ং"
    },
    {
       "_id":3273,
       "upazila_code":14,
       "union_code":15,
       "union_name":"Bandarban",
       "union_ban_name":"বন্দরবান"
    },
    {
       "_id":3274,
       "upazila_code":14,
       "union_code":31,
       "union_name":"Kuhalong",
       "union_ban_name":"কুহালোং"
    },
    {
       "_id":3275,
       "upazila_code":14,
       "union_code":47,
       "union_name":"Rajbila",
       "union_ban_name":"রাজবিলা"
    },
    {
       "_id":3276,
       "upazila_code":14,
       "union_code":63,
       "union_name":"Suwalak",
       "union_ban_name":"সুওয়ালাক"
    },
    {
       "_id":3277,
       "upazila_code":14,
       "union_code":79,
       "union_name":"Tankabati",
       "union_ban_name":"তানকাবাটি"
    },
    {
       "_id":3278,
       "upazila_code":51,
       "union_code":15,
       "union_name":"Aziznagar",
       "union_ban_name":"আজিজনগর"
    },
    {
       "_id":3279,
       "upazila_code":51,
       "union_code":27,
       "union_name":"Faitang",
       "union_ban_name":"ফাইতাং"
    },
    {
       "_id":3280,
       "upazila_code":51,
       "union_code":31,
       "union_name":"Fasyakhali",
       "union_ban_name":"ফসখালী"
    },
    {
       "_id":3281,
       "upazila_code":51,
       "union_code":47,
       "union_name":"Gajalia",
       "union_ban_name":"গাজালিয়া"
    },
    {
       "_id":3282,
       "upazila_code":51,
       "union_code":63,
       "union_name":"Lama",
       "union_ban_name":"লামা"
    },
    {
       "_id":3283,
       "upazila_code":51,
       "union_code":68,
       "union_name":"Rupshipara",
       "union_ban_name":"রূপশিপাড়া"
    },
    {
       "_id":3284,
       "upazila_code":51,
       "union_code":79,
       "union_name":"Sarai",
       "union_ban_name":"সারাই"
    },
    {
       "_id":3285,
       "upazila_code":73,
       "union_code":19,
       "union_name":"Baishari",
       "union_ban_name":"বৈশাখি"
    },
    {
       "_id":3286,
       "upazila_code":73,
       "union_code":38,
       "union_name":"Dochhari",
       "union_ban_name":"দোচারি"
    },
    {
       "_id":3287,
       "upazila_code":73,
       "union_code":57,
       "union_name":"Ghumdum",
       "union_ban_name":"ঘুমদুম"
    },
    {
       "_id":3288,
       "upazila_code":73,
       "union_code":76,
       "union_name":"Naikhongchhari",
       "union_ban_name":"নাইখংছছড়ি"
    },
    {
       "_id":3289,
       "upazila_code":89,
       "union_code":19,
       "union_name":"Alikhong",
       "union_ban_name":"আলিখং"
    },
    {
       "_id":3290,
       "upazila_code":89,
       "union_code":38,
       "union_name":"Nowa Patang",
       "union_ban_name":"নোয়া পাটাং"
    },
    {
       "_id":3291,
       "upazila_code":89,
       "union_code":57,
       "union_name":"Rowangchhari",
       "union_ban_name":"রওংছড়ি"
    },
    {
       "_id":3292,
       "upazila_code":89,
       "union_code":76,
       "union_name":"Tarachha",
       "union_ban_name":"নোয়া পাটাং"
    },
    {
       "_id":3293,
       "upazila_code":91,
       "union_code":19,
       "union_name":"Ghalangya",
       "union_ban_name":"ঘালঙ্গিয়া"
    },
    {
       "_id":3294,
       "upazila_code":91,
       "union_code":38,
       "union_name":"Paindu",
       "union_ban_name":"পেইনডু"
    },
    {
       "_id":3295,
       "upazila_code":91,
       "union_code":57,
       "union_name":"Remakri Pransa",
       "union_ban_name":"রেমাকরি প্রাণসা"
    },
    {
       "_id":3296,
       "upazila_code":91,
       "union_code":76,
       "union_name":"Ruma",
       "union_ban_name":"রুমা"
    },
    {
       "_id":3297,
       "upazila_code":95,
       "union_code":19,
       "union_name":"Balipara",
       "union_ban_name":"বালিপাড়া"
    },
    {
       "_id":3298,
       "upazila_code":95,
       "union_code":38,
       "union_name":"Remakry",
       "union_ban_name":"রেমাকরি প্রাণসা"
    },
    {
       "_id":3299,
       "upazila_code":95,
       "union_code":57,
       "union_name":"Thanchi",
       "union_ban_name":"থানচি"
    },
    {
       "_id":3300,
       "upazila_code":95,
       "union_code":76,
       "union_name":"Tindu",
       "union_ban_name":"টিন্ডু"
    },
    {
       "_id":3301,
       "upazila_code":9,
       "union_code":13,
       "union_name":"Amtali",
       "union_ban_name":"আমতলী"
    },
    {
       "_id":3302,
       "upazila_code":9,
       "union_code":15,
       "union_name":"Arpangashia",
       "union_ban_name":"আরপাঙ্গাশিয়া।"
    },
    {
       "_id":3303,
       "upazila_code":9,
       "union_code":23,
       "union_name":"Atharagashia",
       "union_ban_name":"অথরগাশিয়া"
    },
    {
       "_id":3304,
       "upazila_code":9,
       "union_code":39,
       "union_name":"Barabagi",
       "union_ban_name":"বারাবাগি"
    },
    {
       "_id":3305,
       "upazila_code":9,
       "union_code":43,
       "union_name":"Chhota Bagi",
       "union_ban_name":"ছোট বাগি"
    },
    {
       "_id":3306,
       "upazila_code":9,
       "union_code":47,
       "union_name":"Chowra",
       "union_ban_name":"চৌরা"
    },
    {
       "_id":3307,
       "upazila_code":9,
       "union_code":63,
       "union_name":"Gulisakhali",
       "union_ban_name":"গুলিসাখালী"
    },
    {
       "_id":3308,
       "upazila_code":9,
       "union_code":71,
       "union_name":"Haldia",
       "union_ban_name":"হালদিয়া"
    },
    {
       "_id":3309,
       "upazila_code":9,
       "union_code":79,
       "union_name":"Karaibaria",
       "union_ban_name":"কারিবারিয়া"
    },
    {
       "_id":3310,
       "upazila_code":9,
       "union_code":87,
       "union_name":"Kukua",
       "union_ban_name":"কুকুয়া"
    },
    {
       "_id":3312,
       "upazila_code":9,
       "union_code":94,
       "union_name":"Pancha Koralia",
       "union_ban_name":"পঞ্চ কোরালিয়া"
    },
    {
       "_id":3313,
       "upazila_code":9,
       "union_code":95,
       "union_name":"Sarikkhali",
       "union_ban_name":"সারিকখালী"
    },
    {
       "_id":3314,
       "upazila_code":9,
       "union_code":96,
       "union_name":"Sonakata",
       "union_ban_name":"সোনাকটা"
    },
    {
       "_id":3315,
       "upazila_code":19,
       "union_code":23,
       "union_name":"Bamna",
       "union_ban_name":"বাম্না"
    },
    {
       "_id":3316,
       "upazila_code":19,
       "union_code":47,
       "union_name":"Bukabunia",
       "union_ban_name":"বুকাবুনিয়া"
    },
    {
       "_id":3317,
       "upazila_code":19,
       "union_code":71,
       "union_name":"Dauatala",
       "union_ban_name":"দাউতলা"
    },
    {
       "_id":3318,
       "upazila_code":19,
       "union_code":95,
       "union_name":"Ramna",
       "union_ban_name":"রমনা"
    },
    {
       "_id":3319,
       "upazila_code":28,
       "union_code":17,
       "union_name":"Ayla Patakata",
       "union_ban_name":"আলা পটকাটা"
    },
    {
       "_id":3320,
       "upazila_code":28,
       "union_code":19,
       "union_name":"Badarkhali",
       "union_ban_name":"বদরখালী"
    },
    {
       "_id":3321,
       "upazila_code":28,
       "union_code":28,
       "union_name":"Barguna",
       "union_ban_name":"বরগুনা"
    },
    {
       "_id":3322,
       "upazila_code":28,
       "union_code":38,
       "union_name":"Burir Char",
       "union_ban_name":"বুরির চর"
    },
    {
       "_id":3323,
       "upazila_code":28,
       "union_code":47,
       "union_name":"Dhalua",
       "union_ban_name":"ধলুয়া"
    },
    {
       "_id":3324,
       "upazila_code":28,
       "union_code":57,
       "union_name":"Phuljhury",
       "union_ban_name":"ফুলজুরি"
    },
    {
       "_id":3325,
       "upazila_code":28,
       "union_code":66,
       "union_name":"Gaurichanna",
       "union_ban_name":"গৌরিমান্না"
    },
    {
       "_id":3326,
       "upazila_code":28,
       "union_code":76,
       "union_name":"Keorabunia",
       "union_ban_name":"কেওরাবুনিয়া"
    },
    {
       "_id":3327,
       "upazila_code":28,
       "union_code":85,
       "union_name":"M.Baliatali",
       "union_ban_name":"এম.বালিয়াতালি"
    },
    {
       "_id":3328,
       "upazila_code":28,
       "union_code":95,
       "union_name":"Naltona",
       "union_ban_name":"নলটন"
    },
    {
       "_id":3329,
       "upazila_code":47,
       "union_code":11,
       "union_name":"Betagi",
       "union_ban_name":"বেতাগি"
    },
    {
       "_id":3330,
       "upazila_code":47,
       "union_code":23,
       "union_name":"Bibichini",
       "union_ban_name":"বিবিচিনি"
    },
    {
       "_id":3331,
       "upazila_code":47,
       "union_code":35,
       "union_name":"Bura Mazumdar",
       "union_ban_name":"বুড়া মজুমদার"
    },
    {
       "_id":3332,
       "upazila_code":47,
       "union_code":47,
       "union_name":"Hosnabad",
       "union_ban_name":"হোসনাবাদ"
    },
    {
       "_id":3333,
       "upazila_code":47,
       "union_code":59,
       "union_name":"Kazirabad",
       "union_ban_name":"কাজিরাবাদ"
    },
    {
       "_id":3334,
       "upazila_code":47,
       "union_code":71,
       "union_name":"Mokamia",
       "union_ban_name":"মোকামিয়া"
    },
    {
       "_id":3335,
       "upazila_code":47,
       "union_code":83,
       "union_name":"Sarishamuri",
       "union_ban_name":"সারিশামুরি"
    },
    {
       "_id":3336,
       "upazila_code":85,
       "union_code":11,
       "union_name":"Char Duanti",
       "union_ban_name":"বেতাগি"
    },
    {
       "_id":3337,
       "upazila_code":85,
       "union_code":23,
       "union_name":"Kakchira",
       "union_ban_name":"কাকচিরা"
    },
    {
       "_id":3338,
       "upazila_code":85,
       "union_code":35,
       "union_name":"Kalmegha",
       "union_ban_name":"কললঘা"
    },
    {
       "_id":3339,
       "upazila_code":85,
       "union_code":47,
       "union_name":"Kanthaltali",
       "union_ban_name":"কণ্ঠতলী"
    },
    {
       "_id":3340,
       "upazila_code":85,
       "union_code":59,
       "union_name":"Nachna Para",
       "union_ban_name":"ন্যাচনা প্যারা"
    },
    {
       "_id":3341,
       "upazila_code":85,
       "union_code":71,
       "union_name":"Patharghata",
       "union_ban_name":"পাথরঘাট"
    },
    {
       "_id":3342,
       "upazila_code":85,
       "union_code":83,
       "union_name":"Raihanpur",
       "union_ban_name":"রাইহানপুর"
    },
    {
       "_id":3343,
       "upazila_code":2,
       "union_code":13,
       "union_name":"Bagdha",
       "union_ban_name":"বাগধন"
    },
    {
       "_id":3344,
       "upazila_code":2,
       "union_code":15,
       "union_name":"Bakal",
       "union_ban_name":"বাকল"
    },
    {
       "_id":3345,
       "upazila_code":2,
       "union_code":47,
       "union_name":"Gaila",
       "union_ban_name":"গাইলা"
    },
    {
       "_id":3346,
       "upazila_code":2,
       "union_code":79,
       "union_name":"Rajiher",
       "union_ban_name":"রাজিয়ার"
    },
    {
       "_id":3347,
       "upazila_code":2,
       "union_code":87,
       "union_name":"Ratnapur",
       "union_ban_name":"রত্নপুর"
    },
    {
       "_id":3348,
       "upazila_code":3,
       "union_code":13,
       "union_name":"Jahangir Nagar(Agarpur)",
       "union_ban_name":"জাহাঙ্গীর নগর (আগরপুর)"
    },
    {
       "_id":3349,
       "upazila_code":3,
       "union_code":27,
       "union_name":"Chandpasha",
       "union_ban_name":"চাঁদপশা"
    },
    {
       "_id":3350,
       "upazila_code":3,
       "union_code":40,
       "union_name":"Dehergati",
       "union_ban_name":"দেহেরগাতি"
    },
    {
       "_id":3351,
       "upazila_code":3,
       "union_code":54,
       "union_name":"Kedarpur",
       "union_ban_name":"কেদারপুর"
    },
    {
       "_id":3352,
       "upazila_code":3,
       "union_code":67,
       "union_name":"Madhab Pasha",
       "union_ban_name":"মাধাব পাশা"
    },
    {
       "_id":3353,
       "upazila_code":3,
       "union_code":81,
       "union_name":"Rahmatpur",
       "union_ban_name":"রহমতপুর"
    },
    {
       "_id":3354,
       "upazila_code":7,
       "union_code":12,
       "union_name":"Bhar Pasha",
       "union_ban_name":"ভর পাশা"
    },
    {
       "_id":3355,
       "upazila_code":7,
       "union_code":13,
       "union_name":"Charadi",
       "union_ban_name":"চারদী"
    },
    {
       "_id":3356,
       "upazila_code":7,
       "union_code":20,
       "union_name":"Char Amaddi",
       "union_ban_name":"চর আমদ্দী"
    },
    {
       "_id":3357,
       "upazila_code":7,
       "union_code":27,
       "union_name":"Darial",
       "union_ban_name":"দারিয়াল"
    },
    {
       "_id":3358,
       "upazila_code":7,
       "union_code":33,
       "union_name":"Dudhal",
       "union_ban_name":"দুদাল"
    },
    {
       "_id":3359,
       "upazila_code":7,
       "union_code":40,
       "union_name":"Durga Pasha",
       "union_ban_name":"দুর্গা পাশা"
    },
    {
       "_id":3360,
       "upazila_code":7,
       "union_code":47,
       "union_name":"Faridpur",
       "union_ban_name":"ফরিদপুর"
    },
    {
       "_id":3361,
       "upazila_code":7,
       "union_code":54,
       "union_name":"Garuria",
       "union_ban_name":"গারুরিয়া"
    },
    {
       "_id":3362,
       "upazila_code":7,
       "union_code":61,
       "union_name":"Kabai",
       "union_ban_name":"কাবাই"
    },
    {
       "_id":3363,
       "upazila_code":7,
       "union_code":67,
       "union_name":"Kalaskati",
       "union_ban_name":"কালস্কাটি"
    },
    {
       "_id":3364,
       "upazila_code":7,
       "union_code":74,
       "union_name":"Nalua",
       "union_ban_name":"নালুয়া"
    },
    {
       "_id":3365,
       "upazila_code":7,
       "union_code":81,
       "union_name":"Niamati",
       "union_ban_name":"নিমতি"
    },
    {
       "_id":3366,
       "upazila_code":7,
       "union_code":88,
       "union_name":"Padri Shibpur",
       "union_ban_name":"পদে শিবিপুল"
    },
    {
       "_id":3367,
       "upazila_code":7,
       "union_code":94,
       "union_name":"Rangasree",
       "union_ban_name":"রাঙ্গশ্রী"
    },
    {
       "_id":3368,
       "upazila_code":10,
       "union_code":10,
       "union_name":"Banaripara",
       "union_ban_name":"বানারিপাড়া"
    },
    {
       "_id":3369,
       "upazila_code":10,
       "union_code":21,
       "union_name":"Baisari",
       "union_ban_name":"বৈষারী"
    },
    {
       "_id":3370,
       "upazila_code":10,
       "union_code":31,
       "union_name":"Bisarkandi",
       "union_ban_name":"বিস্কান্দি"
    },
    {
       "_id":3371,
       "upazila_code":10,
       "union_code":42,
       "union_name":"Chakhar",
       "union_ban_name":"চাখার"
    },
    {
       "_id":3372,
       "upazila_code":10,
       "union_code":52,
       "union_name":"Iluhar",
       "union_ban_name":"ইলুহরার"
    },
    {
       "_id":3373,
       "upazila_code":10,
       "union_code":63,
       "union_name":"Salia Bakpur",
       "union_ban_name":"সালিয়া বাকপুর"
    },
    {
       "_id":3374,
       "upazila_code":10,
       "union_code":73,
       "union_name":"Saidkati",
       "union_ban_name":"সাইদকাতি"
    },
    {
       "_id":3375,
       "upazila_code":10,
       "union_code":84,
       "union_name":"Udaykati",
       "union_ban_name":"উদয়কতি"
    },
    {
       "_id":3376,
       "upazila_code":32,
       "union_code":23,
       "union_name":"Barthi",
       "union_ban_name":"বার্থী"
    },
    {
       "_id":3377,
       "upazila_code":32,
       "union_code":31,
       "union_name":"Batajore",
       "union_ban_name":"বতজোর"
    },
    {
       "_id":3378,
       "upazila_code":32,
       "union_code":38,
       "union_name":"Chandshi",
       "union_ban_name":"চাঁদশী"
    },
    {
       "_id":3379,
       "upazila_code":32,
       "union_code":55,
       "union_name":"Khanjapur",
       "union_ban_name":"খানজাপুর"
    },
    {
       "_id":3380,
       "upazila_code":32,
       "union_code":63,
       "union_name":"Mahilara",
       "union_ban_name":"মহিলারা"
    },
    {
       "_id":3381,
       "upazila_code":32,
       "union_code":71,
       "union_name":"Nalchira",
       "union_ban_name":"নলচিরা"
    },
    {
       "_id":3382,
       "upazila_code":32,
       "union_code":94,
       "union_name":"Sarikal",
       "union_ban_name":"সারিকাল"
    },
    {
       "_id":3383,
       "upazila_code":36,
       "union_code":13,
       "union_name":"Bara Jalia",
       "union_ban_name":"বার জালিয়া"
    },
    {
       "_id":3384,
       "upazila_code":36,
       "union_code":27,
       "union_name":"Dhulkhola",
       "union_ban_name":"ধুলকোলা"
    },
    {
       "_id":3385,
       "upazila_code":36,
       "union_code":54,
       "union_name":"Guabaria",
       "union_ban_name":"গুয়াবাড়িয়া"
    },
    {
       "_id":3386,
       "upazila_code":36,
       "union_code":67,
       "union_name":"Harinathpur",
       "union_ban_name":"হরিনাথপুর"
    },
    {
       "_id":3387,
       "upazila_code":36,
       "union_code":81,
       "union_name":"Hizla Gaurabdi",
       "union_ban_name":"হিজলা গৌরাবদি"
    },
    {
       "_id":3388,
       "upazila_code":36,
       "union_code":94,
       "union_name":"Memania",
       "union_ban_name":"মেম্যানিয়া"
    },
    {
       "_id":3389,
       "upazila_code":51,
       "union_code":32,
       "union_name":"Chandpura",
       "union_ban_name":"চাঁদপুরা"
    },
    {
       "_id":3390,
       "upazila_code":51,
       "union_code":33,
       "union_name":"Chandra Mohan",
       "union_ban_name":"চন্দ্র মোহন"
    },
    {
       "_id":3391,
       "upazila_code":51,
       "union_code":34,
       "union_name":"Char Baria",
       "union_ban_name":"চার বারিয়া"
    },
    {
       "_id":3392,
       "upazila_code":51,
       "union_code":43,
       "union_name":"Char Kowa",
       "union_ban_name":"চার কোওয়া"
    },
    {
       "_id":3393,
       "upazila_code":51,
       "union_code":51,
       "union_name":"Char Monai",
       "union_ban_name":"চর মনি"
    },
    {
       "_id":3394,
       "upazila_code":51,
       "union_code":60,
       "union_name":"Jagua",
       "union_ban_name":"জাগুয়া"
    },
    {
       "_id":3395,
       "upazila_code":51,
       "union_code":69,
       "union_name":"Kashipur",
       "union_ban_name":"কাশিপুর"
    },
    {
       "_id":3396,
       "upazila_code":51,
       "union_code":77,
       "union_name":"Roy Pasha Karapur",
       "union_ban_name":"রায় পাশা কারপুর"
    },
    {
       "_id":3397,
       "upazila_code":51,
       "union_code":86,
       "union_name":"Shayestabad",
       "union_ban_name":"শায়স্তাবাদ"
    },
    {
       "_id":3398,
       "upazila_code":51,
       "union_code":94,
       "union_name":"Tungibaria",
       "union_ban_name":"তুঙ্গিবারিয়া"
    },
    {
       "_id":3399,
       "upazila_code":62,
       "union_code":13,
       "union_name":"Alimabad",
       "union_ban_name":"আলিমাবাদ"
    },
    {
       "_id":3400,
       "upazila_code":62,
       "union_code":15,
       "union_name":"Andhar Manik",
       "union_ban_name":"অন্ধর মানিক"
    },
    {
       "_id":3401,
       "upazila_code":62,
       "union_code":23,
       "union_name":"Bhasan Char",
       "union_ban_name":"ভাসান চর"
    },
    {
       "_id":3402,
       "upazila_code":62,
       "union_code":31,
       "union_name":"Bidyanandapur",
       "union_ban_name":"বিডানন্দাপুর"
    },
    {
       "_id":3403,
       "upazila_code":62,
       "union_code":39,
       "union_name":"Chandpur",
       "union_ban_name":"চাঁদপুর"
    },
    {
       "_id":3404,
       "upazila_code":62,
       "union_code":40,
       "union_name":"Gobindapur",
       "union_ban_name":"গোবিন্দপুর"
    },
    {
       "_id":3405,
       "upazila_code":62,
       "union_code":47,
       "union_name":"Char Ekkaria",
       "union_ban_name":"চর এককারিয়া"
    },
    {
       "_id":3406,
       "upazila_code":62,
       "union_code":55,
       "union_name":"Char Gopalpur",
       "union_ban_name":"চর গোপালপুর"
    },
    {
       "_id":3407,
       "upazila_code":62,
       "union_code":63,
       "union_name":"Dari Char Khajuria",
       "union_ban_name":"দাড়ী চর খাজুরিয়া"
    },
    {
       "_id":3408,
       "upazila_code":62,
       "union_code":71,
       "union_name":"Jangalia",
       "union_ban_name":"জঙ্গালিয়া"
    },
    {
       "_id":3409,
       "upazila_code":62,
       "union_code":79,
       "union_name":"Lata",
       "union_ban_name":"লতা"
    },
    {
       "_id":3410,
       "upazila_code":62,
       "union_code":87,
       "union_name":"Mehendiganj",
       "union_ban_name":"মেহেন্ডিগঞ্জ"
    },
    {
       "_id":3411,
       "upazila_code":62,
       "union_code":94,
       "union_name":"Ulania",
       "union_ban_name":"উলানিয়া"
    },
    {
       "_id":3412,
       "upazila_code":69,
       "union_code":11,
       "union_name":"Batamara",
       "union_ban_name":"ব্যাটমারা"
    },
    {
       "_id":3413,
       "upazila_code":69,
       "union_code":23,
       "union_name":"Char Kalekhan",
       "union_ban_name":"চর কালখানার"
    },
    {
       "_id":3414,
       "upazila_code":69,
       "union_code":35,
       "union_name":"Gachhua",
       "union_ban_name":"চর এককারিয়া"
    },
    {
       "_id":3415,
       "upazila_code":69,
       "union_code":47,
       "union_name":"Kazir Char",
       "union_ban_name":"কাজীর চর"
    },
    {
       "_id":3416,
       "upazila_code":69,
       "union_code":59,
       "union_name":"Muladi",
       "union_ban_name":"চর এককারিয়া"
    },
    {
       "_id":3417,
       "upazila_code":69,
       "union_code":71,
       "union_name":"Nazirpur",
       "union_ban_name":"নাজিরপুর"
    },
    {
       "_id":3418,
       "upazila_code":69,
       "union_code":83,
       "union_name":"Safipur",
       "union_ban_name":"সাফিপুর"
    },
    {
       "_id":3419,
       "upazila_code":94,
       "union_code":10,
       "union_name":"Bamrail",
       "union_ban_name":"চর এককারিয়া"
    },
    {
       "_id":3420,
       "upazila_code":94,
       "union_code":21,
       "union_name":"Bara Kotha",
       "union_ban_name":"বার কোথা"
    },
    {
       "_id":3421,
       "upazila_code":94,
       "union_code":31,
       "union_name":"Guthia",
       "union_ban_name":"গথিয়া"
    },
    {
       "_id":3422,
       "upazila_code":94,
       "union_code":42,
       "union_name":"Harta",
       "union_ban_name":"হার্টা"
    },
    {
       "_id":3423,
       "upazila_code":94,
       "union_code":52,
       "union_name":"Jalla",
       "union_ban_name":"জালা"
    },
    {
       "_id":3424,
       "upazila_code":94,
       "union_code":63,
       "union_name":"Otra",
       "union_ban_name":"ওত্রা"
    },
    {
       "_id":3425,
       "upazila_code":94,
       "union_code":73,
       "union_name":"Satla",
       "union_ban_name":"সতলা"
    },
    {
       "_id":3426,
       "upazila_code":94,
       "union_code":84,
       "union_name":"Shikarpur",
       "union_ban_name":"শিকপুর"
    },
    {
       "_id":3427,
       "upazila_code":94,
       "union_code":94,
       "union_name":"Sholak",
       "union_ban_name":"শোলাক"
    },
    {
       "_id":3428,
       "upazila_code":18,
       "union_code":12,
       "union_name":"Alinagar",
       "union_ban_name":"আলিনগর"
    },
    {
       "_id":3429,
       "upazila_code":18,
       "union_code":14,
       "union_name":"Bapta",
       "union_ban_name":"ব্যস্থা"
    },
    {
       "_id":3430,
       "upazila_code":18,
       "union_code":21,
       "union_name":"Char Samaia",
       "union_ban_name":"চর সামিয়া"
    },
    {
       "_id":3431,
       "upazila_code":18,
       "union_code":29,
       "union_name":"Char Shibpur",
       "union_ban_name":"চর শিবপুর"
    },
    {
       "_id":3432,
       "upazila_code":18,
       "union_code":36,
       "union_name":"Dhania",
       "union_ban_name":"ধনিয়া"
    },
    {
       "_id":3433,
       "upazila_code":18,
       "union_code":51,
       "union_name":"Illisha",
       "union_ban_name":"ইলিশা"
    },
    {
       "_id":3434,
       "upazila_code":18,
       "union_code":55,
       "union_name":"Paschim Ilisha",
       "union_ban_name":"পশ্চিম ইলিশা"
    },
    {
       "_id":3435,
       "upazila_code":18,
       "union_code":58,
       "union_name":"Kachia",
       "union_ban_name":"কচিয়া"
    },
    {
       "_id":3436,
       "upazila_code":18,
       "union_code":65,
       "union_name":"Uttar Dighaldi",
       "union_ban_name":"উত্তর দিঘালদি"
    },
    {
       "_id":3437,
       "upazila_code":18,
       "union_code":73,
       "union_name":"Rajapur",
       "union_ban_name":"রাজাপুর"
    },
    {
       "_id":3438,
       "upazila_code":18,
       "union_code":80,
       "union_name":"Dakshin Dighaldi",
       "union_ban_name":"দক্ষিণ দিঘালদি"
    },
    {
       "_id":3439,
       "upazila_code":18,
       "union_code":87,
       "union_name":"Veduria",
       "union_ban_name":"ভেদুরিয়া"
    },
    {
       "_id":3440,
       "upazila_code":18,
       "union_code":94,
       "union_name":"Bhelu Miah",
       "union_ban_name":"ভেলু মিয়া"
    },
    {
       "_id":3441,
       "upazila_code":21,
       "union_code":16,
       "union_name":"Bara Manika",
       "union_ban_name":"বার মানিকা"
    },
    {
       "_id":3442,
       "upazila_code":21,
       "union_code":19,
       "union_name":"Deula",
       "union_ban_name":"দেউলা"
    },
    {
       "_id":3443,
       "upazila_code":21,
       "union_code":28,
       "union_name":"Gangapur",
       "union_ban_name":"গঙ্গাপুর"
    },
    {
       "_id":3444,
       "upazila_code":21,
       "union_code":38,
       "union_name":"Hassan Nagar",
       "union_ban_name":"হাসান নগর"
    },
    {
       "_id":3446,
       "upazila_code":21,
       "union_code":57,
       "union_name":"Kutba",
       "union_ban_name":"কুটবা"
    },
    {
       "_id":3447,
       "upazila_code":21,
       "union_code":66,
       "union_name":"Pakshia",
       "union_ban_name":"পাকশিয়া"
    },
    {
       "_id":3448,
       "upazila_code":21,
       "union_code":76,
       "union_name":"Sachra",
       "union_ban_name":"সচরা"
    },
    {
       "_id":3449,
       "upazila_code":21,
       "union_code":95,
       "union_name":"Tabgi",
       "union_ban_name":"তাবগি"
    },
    {
       "_id":3450,
       "upazila_code":25,
       "union_code":11,
       "union_name":"Abu Bakarpur",
       "union_ban_name":"আবু বকরপুর"
    },
    {
       "_id":3451,
       "upazila_code":25,
       "union_code":13,
       "union_name":"Abdullapur",
       "union_ban_name":"আবদুলাপুর"
    },
    {
       "_id":3452,
       "upazila_code":25,
       "union_code":14,
       "union_name":"Adhakha Nazrul Nagar",
       "union_ban_name":"আধকা নজরুল নগর"
    },
    {
       "_id":3453,
       "upazila_code":25,
       "union_code":15,
       "union_name":"Aminabad",
       "union_ban_name":"আমিনাবাদ"
    },
    {
       "_id":3454,
       "upazila_code":25,
       "union_code":19,
       "union_name":"Aslampur",
       "union_ban_name":"আসলামপুর"
    },
    {
       "_id":3455,
       "upazila_code":25,
       "union_code":28,
       "union_name":"Char Kalmi",
       "union_ban_name":"চর কালি"
    },
    {
       "_id":3456,
       "upazila_code":25,
       "union_code":38,
       "union_name":"Char Madras",
       "union_ban_name":"চার মাদ্রাজ"
    },
    {
       "_id":3457,
       "upazila_code":25,
       "union_code":47,
       "union_name":"Char Manika",
       "union_ban_name":"চার মানিকা"
    },
    {
       "_id":3458,
       "upazila_code":25,
       "union_code":51,
       "union_name":"Dhal Char",
       "union_ban_name":"ধলের চর"
    },
    {
       "_id":3459,
       "upazila_code":25,
       "union_code":53,
       "union_name":"Ewajpur",
       "union_ban_name":"ইজাজপুর"
    },
    {
       "_id":3460,
       "upazila_code":25,
       "union_code":57,
       "union_name":"Hazariganj",
       "union_ban_name":"হজরীগঞ্জ"
    },
    {
       "_id":3461,
       "upazila_code":25,
       "union_code":63,
       "union_name":"Jahanpur",
       "union_ban_name":"জাহানপুর"
    },
    {
       "_id":3462,
       "upazila_code":25,
       "union_code":66,
       "union_name":"Jinnaghar",
       "union_ban_name":"জিন্নঘর"
    },
    {
       "_id":3463,
       "upazila_code":25,
       "union_code":70,
       "union_name":"Char Kukri Mukri",
       "union_ban_name":"চর কুকরি মুকরি"
    },
    {
       "_id":3464,
       "upazila_code":25,
       "union_code":73,
       "union_name":"Mujib Nagar",
       "union_ban_name":"মুজিব নগর"
    },
    {
       "_id":3465,
       "upazila_code":25,
       "union_code":76,
       "union_name":"Nilkamal",
       "union_ban_name":"নীলকামাল"
    },
    {
       "_id":3466,
       "upazila_code":25,
       "union_code":85,
       "union_name":"Nurabad",
       "union_ban_name":"নুরাবাদ"
    },
    {
       "_id":3467,
       "upazila_code":25,
       "union_code":88,
       "union_name":"Rasul Pur",
       "union_ban_name":"রসুল পুর"
    },
    {
       "_id":3468,
       "upazila_code":25,
       "union_code":95,
       "union_name":"Osmanganj",
       "union_ban_name":"ওসমানগঞ্জ"
    },
    {
       "_id":3469,
       "upazila_code":29,
       "union_code":16,
       "union_name":"Bhabanipur",
       "union_ban_name":"ভবানীপুর"
    },
    {
       "_id":3470,
       "upazila_code":29,
       "union_code":19,
       "union_name":"Char Khalifa",
       "union_ban_name":"চর খলিফা"
    },
    {
       "_id":3471,
       "upazila_code":29,
       "union_code":28,
       "union_name":"Char Pata",
       "union_ban_name":"চর পটা"
    },
    {
       "_id":3472,
       "upazila_code":29,
       "union_code":38,
       "union_name":"Hajipur",
       "union_ban_name":"হজীপুর"
    },
    {
       "_id":3473,
       "upazila_code":29,
       "union_code":47,
       "union_name":"Madanpur",
       "union_ban_name":"মাদানপুর"
    },
    {
       "_id":3474,
       "upazila_code":29,
       "union_code":57,
       "union_name":"Medua",
       "union_ban_name":"মেদুয়া"
    },
    {
       "_id":3475,
       "upazila_code":29,
       "union_code":76,
       "union_name":"Uttar Joynagar",
       "union_ban_name":"উত্তর জয়নাগর"
    },
    {
       "_id":3476,
       "upazila_code":29,
       "union_code":85,
       "union_name":"Dakshin Joynagar",
       "union_ban_name":"দক্ষিণ জয়নাগর"
    },
    {
       "_id":3477,
       "upazila_code":29,
       "union_code":95,
       "union_name":"Saidpur",
       "union_ban_name":"সাইদপুর"
    },
    {
       "_id":3478,
       "upazila_code":54,
       "union_code":16,
       "union_name":"Badarpur",
       "union_ban_name":"বদরপুর"
    },
    {
       "_id":3479,
       "upazila_code":54,
       "union_code":19,
       "union_name":"Char Bhuta",
       "union_ban_name":"চর ভুটা"
    },
    {
       "_id":3480,
       "upazila_code":54,
       "union_code":28,
       "union_name":"Dhali Gaurnagar",
       "union_ban_name":"ধালী গারনারগর"
    },
    {
       "_id":3481,
       "upazila_code":54,
       "union_code":38,
       "union_name":"Farazganj",
       "union_ban_name":"ফারজগঞ্জ"
    },
    {
       "_id":3482,
       "upazila_code":54,
       "union_code":47,
       "union_name":"Kalma",
       "union_ban_name":"কালমা"
    },
    {
       "_id":3483,
       "upazila_code":54,
       "union_code":57,
       "union_name":"Lalmohan",
       "union_ban_name":"লালমোহন"
    },
    {
       "_id":3484,
       "upazila_code":54,
       "union_code":66,
       "union_name":"Lord Hardinje",
       "union_ban_name":"লর্ড হার্ডিঞ্জে."
    },
    {
       "_id":3485,
       "upazila_code":54,
       "union_code":70,
       "union_name":"Paschim Char Umed",
       "union_ban_name":"পশ্চিম চর উমেদে"
    },
    {
       "_id":3486,
       "upazila_code":54,
       "union_code":76,
       "union_name":"Ramaganj",
       "union_ban_name":"রামগঞ্জ"
    },
    {
       "_id":3487,
       "upazila_code":65,
       "union_code":21,
       "union_name":"Dakshin Sakuchia",
       "union_ban_name":"দক্ষিণ সাকুচিয়া"
    },
    {
       "_id":3488,
       "upazila_code":65,
       "union_code":23,
       "union_name":"Hajirhat",
       "union_ban_name":"হজিরহাট"
    },
    {
       "_id":3489,
       "upazila_code":65,
       "union_code":47,
       "union_name":"Manpura",
       "union_ban_name":"মানপুরা"
    },
    {
       "_id":3490,
       "upazila_code":65,
       "union_code":71,
       "union_name":"Uttar Sakuchia",
       "union_ban_name":"উত্তর সাকুচিয়া"
    },
    {
       "_id":3491,
       "upazila_code":91,
       "union_code":19,
       "union_name":"Bara Malancha",
       "union_ban_name":"বারা মালঞ্চ"
    },
    {
       "_id":3492,
       "upazila_code":91,
       "union_code":38,
       "union_name":"Chanchra",
       "union_ban_name":"চঞ্চড়া"
    },
    {
       "_id":3494,
       "upazila_code":91,
       "union_code":76,
       "union_name":"Sonapur",
       "union_ban_name":"সোনাপুর"
    },
    {
       "_id":3495,
       "upazila_code":91,
       "union_code":85,
       "union_name":"Shambhupur",
       "union_ban_name":"শম্ভুপুর"
    },
    {
       "_id":3496,
       "upazila_code":6,
       "union_code":13,
       "union_name":"Adam Dighi",
       "union_ban_name":"আদম দিঘি"
    },
    {
       "_id":3497,
       "upazila_code":6,
       "union_code":27,
       "union_name":"Champapur",
       "union_ban_name":"চাঁপাপুর"
    },
    {
       "_id":3498,
       "upazila_code":6,
       "union_code":40,
       "union_name":"Chhatiangram",
       "union_ban_name":"ছত্রিয়াগ্রাম"
    },
    {
       "_id":3499,
       "upazila_code":6,
       "union_code":54,
       "union_name":"Kundagram",
       "union_ban_name":"কুন্ডগ্রাম"
    },
    {
       "_id":3500,
       "upazila_code":6,
       "union_code":67,
       "union_name":"Nasratpur",
       "union_ban_name":"নাসরাতপুর"
    },
    {
       "_id":3501,
       "upazila_code":6,
       "union_code":81,
       "union_name":"Shantahar",
       "union_ban_name":"শান্তাহার"
    },
    {
       "_id":3502,
       "upazila_code":20,
       "union_code":23,
       "union_name":"Erulia",
       "union_ban_name":"এরুলিয়া"
    },
    {
       "_id":3503,
       "upazila_code":20,
       "union_code":25,
       "union_name":"Fapore",
       "union_ban_name":"ফাপোর।."
    },
    {
       "_id":3504,
       "upazila_code":20,
       "union_code":34,
       "union_name":"Gokul",
       "union_ban_name":"গোকুল"
    },
    {
       "_id":3505,
       "upazila_code":20,
       "union_code":47,
       "union_name":"Lahiri Para",
       "union_ban_name":"লাহিরি প্যারা"
    },
    {
       "_id":3506,
       "upazila_code":20,
       "union_code":60,
       "union_name":"Namuja",
       "union_ban_name":"নামুজা"
    },
    {
       "_id":3507,
       "upazila_code":20,
       "union_code":64,
       "union_name":"Nishindara",
       "union_ban_name":"নিশিন্দারা"
    },
    {
       "_id":3508,
       "upazila_code":20,
       "union_code":69,
       "union_name":"Noongola",
       "union_ban_name":"নোয়াংলা"
    },
    {
       "_id":3510,
       "upazila_code":20,
       "union_code":77,
       "union_name":"Shabgram",
       "union_ban_name":"শাবগ্রাম"
    },
    {
       "_id":3511,
       "upazila_code":20,
       "union_code":82,
       "union_name":"Shakharia",
       "union_ban_name":"শখরিয়া"
    },
    {
       "_id":3512,
       "upazila_code":20,
       "union_code":86,
       "union_name":"Sekherkola",
       "union_ban_name":"সেখেরকোলা।."
    },
    {
       "_id":3513,
       "upazila_code":27,
       "union_code":15,
       "union_name":"Bhandarbari",
       "union_ban_name":"ভান্দরবাড়ী"
    },
    {
       "_id":3514,
       "upazila_code":27,
       "union_code":19,
       "union_name":"Chaukibari",
       "union_ban_name":"চৌকিবাড়ি"
    },
    {
       "_id":3515,
       "upazila_code":27,
       "union_code":28,
       "union_name":"Chikashi",
       "union_ban_name":"চিকশী"
    },
    {
       "_id":3516,
       "upazila_code":27,
       "union_code":38,
       "union_name":"Dhunat",
       "union_ban_name":"ধুনাট"
    },
    {
       "_id":3517,
       "upazila_code":27,
       "union_code":47,
       "union_name":"Elangi",
       "union_ban_name":"এলঙ্গি"
    },
    {
       "_id":3518,
       "upazila_code":27,
       "union_code":57,
       "union_name":"Gopalnagar",
       "union_ban_name":"গোপালনগর"
    },
    {
       "_id":3519,
       "upazila_code":27,
       "union_code":66,
       "union_name":"Gosainbari",
       "union_ban_name":"গোসাইনবাড়ি"
    },
    {
       "_id":3520,
       "upazila_code":27,
       "union_code":76,
       "union_name":"Kaler Para",
       "union_ban_name":"কালার প্যারা"
    },
    {
       "_id":3521,
       "upazila_code":27,
       "union_code":85,
       "union_name":"Mathurapur",
       "union_ban_name":"মঠুরাপুর"
    },
    {
       "_id":3522,
       "upazila_code":27,
       "union_code":95,
       "union_name":"Nimgachhi",
       "union_ban_name":"নিমগিচি"
    },
    {
       "_id":3523,
       "upazila_code":33,
       "union_code":13,
       "union_name":"Chamrul",
       "union_ban_name":"চামরুল"
    },
    {
       "_id":3524,
       "upazila_code":33,
       "union_code":27,
       "union_name":"Dhupchanchia",
       "union_ban_name":"ধুপচঞ্চিয়া"
    },
    {
       "_id":3526,
       "upazila_code":33,
       "union_code":54,
       "union_name":"Gunahar",
       "union_ban_name":"গুনাহার"
    },
    {
       "_id":3527,
       "upazila_code":33,
       "union_code":67,
       "union_name":"Talora",
       "union_ban_name":"তালোরা"
    },
    {
       "_id":3528,
       "upazila_code":33,
       "union_code":81,
       "union_name":"Zianagar",
       "union_ban_name":"জিয়াগর"
    },
    {
       "_id":3529,
       "upazila_code":40,
       "union_code":11,
       "union_name":"Balia Dighi",
       "union_ban_name":"বালিয়া দিঘি"
    },
    {
       "_id":3530,
       "upazila_code":40,
       "union_code":17,
       "union_name":"Dakshinpara",
       "union_ban_name":"দক্ষিণপাড়া"
    },
    {
       "_id":3531,
       "upazila_code":40,
       "union_code":27,
       "union_name":"Durgahata",
       "union_ban_name":"দুর্গহাট"
    },
    {
       "_id":3532,
       "upazila_code":40,
       "union_code":33,
       "union_name":"Gabtali",
       "union_ban_name":"গাবতলী"
    },
    {
       "_id":3533,
       "upazila_code":40,
       "union_code":47,
       "union_name":"Kagail",
       "union_ban_name":"কাগাইল"
    },
    {
       "_id":3534,
       "upazila_code":40,
       "union_code":54,
       "union_name":"Mahishaban",
       "union_ban_name":"মহিশাবান"
    },
    {
       "_id":3535,
       "upazila_code":40,
       "union_code":61,
       "union_name":"Naruamala",
       "union_ban_name":"নারুমালা"
    },
    {
       "_id":3536,
       "upazila_code":40,
       "union_code":67,
       "union_name":"Nasipur",
       "union_ban_name":"নাসীপুর"
    },
    {
       "_id":3537,
       "upazila_code":40,
       "union_code":74,
       "union_name":"Nepaltali",
       "union_ban_name":"নেপালালি"
    },
    {
       "_id":3538,
       "upazila_code":40,
       "union_code":81,
       "union_name":"Rameshwarpur",
       "union_ban_name":"রামেশ্বরপুর"
    },
    {
       "_id":3539,
       "upazila_code":40,
       "union_code":88,
       "union_name":"Sonarai",
       "union_ban_name":"সোনারাই"
    },
    {
       "_id":3540,
       "upazila_code":54,
       "union_code":13,
       "union_name":"Bir Kedar",
       "union_ban_name":"বীর কেদার"
    },
    {
       "_id":3541,
       "upazila_code":54,
       "union_code":19,
       "union_name":"Durgapur",
       "union_ban_name":"দুর্গাপুর"
    },
    {
       "_id":3542,
       "upazila_code":54,
       "union_code":28,
       "union_name":"Jamgaon",
       "union_ban_name":"জামাকাঁও"
    },
    {
       "_id":3543,
       "upazila_code":54,
       "union_code":38,
       "union_name":"Kahaloo",
       "union_ban_name":"কাহালু"
    },
    {
       "_id":3544,
       "upazila_code":54,
       "union_code":47,
       "union_name":"Kalai Majh Para",
       "union_ban_name":"কালাই মাজ পারা"
    },
    {
       "_id":3545,
       "upazila_code":54,
       "union_code":57,
       "union_name":"Malancha",
       "union_ban_name":"মালঞ্চা"
    },
    {
       "_id":3546,
       "upazila_code":54,
       "union_code":66,
       "union_name":"Murail",
       "union_ban_name":"মুরেল"
    },
    {
       "_id":3547,
       "upazila_code":54,
       "union_code":76,
       "union_name":"Narahatta",
       "union_ban_name":"নারহট্টা"
    },
    {
       "_id":3548,
       "upazila_code":54,
       "union_code":85,
       "union_name":"Paikar",
       "union_ban_name":"পাইকার"
    },
    {
       "_id":3549,
       "upazila_code":67,
       "union_code":10,
       "union_name":"Bhatgram",
       "union_ban_name":"ভাটগ্রাম"
    },
    {
       "_id":3550,
       "upazila_code":67,
       "union_code":21,
       "union_name":"Bhatra",
       "union_ban_name":"ভাত্ররা"
    },
    {
       "_id":3551,
       "upazila_code":67,
       "union_code":31,
       "union_name":"Burail",
       "union_ban_name":"বুরাইল"
    },
    {
       "_id":3552,
       "upazila_code":67,
       "union_code":73,
       "union_name":"Nandigram",
       "union_ban_name":"নন্দীগ্রাম"
    },
    {
       "_id":3553,
       "upazila_code":67,
       "union_code":84,
       "union_name":"Thalta Majhgram",
       "union_ban_name":"থালা মজগ্রাম"
    },
    {
       "_id":3554,
       "upazila_code":81,
       "union_code":11,
       "union_name":"Bhelabari",
       "union_ban_name":"ভেলবাড়ী"
    },
    {
       "_id":3555,
       "upazila_code":81,
       "union_code":12,
       "union_name":"Bohail",
       "union_ban_name":"বোহাইল"
    },
    {
       "_id":3556,
       "upazila_code":81,
       "union_code":19,
       "union_name":"Chaluabari",
       "union_ban_name":"চালাবাড়ী"
    },
    {
       "_id":3557,
       "upazila_code":81,
       "union_code":25,
       "union_name":"Chandan Baisha",
       "union_ban_name":"চন্দন বৈশা"
    },
    {
       "_id":3558,
       "upazila_code":81,
       "union_code":31,
       "union_name":"Fulbari",
       "union_ban_name":"ফুলবাড়ী"
    },
    {
       "_id":3559,
       "upazila_code":81,
       "union_code":37,
       "union_name":"Hat Sherpur",
       "union_ban_name":"টুপি শেরপুর"
    },
    {
       "_id":3560,
       "upazila_code":81,
       "union_code":44,
       "union_name":"Kamalpur",
       "union_ban_name":"কামালপুর"
    },
    {
       "_id":3561,
       "upazila_code":81,
       "union_code":55,
       "union_name":"Kazla",
       "union_ban_name":"কাজলা"
    },
    {
       "_id":3562,
       "upazila_code":81,
       "union_code":56,
       "union_name":"Karnibari",
       "union_ban_name":"কর্ণিবারী"
    },
    {
       "_id":3563,
       "upazila_code":81,
       "union_code":63,
       "union_name":"Kutubpur",
       "union_ban_name":"কুতুবপুর"
    },
    {
       "_id":3564,
       "upazila_code":81,
       "union_code":75,
       "union_name":"Narchi",
       "union_ban_name":"নার্কি"
    },
    {
       "_id":3565,
       "upazila_code":81,
       "union_code":88,
       "union_name":"Sariakandi",
       "union_ban_name":"সরিয়াকান্দি"
    },
    {
       "_id":3566,
       "upazila_code":85,
       "union_code":16,
       "union_name":"Amrool",
       "union_ban_name":"আমরুল"
    },
    {
       "_id":3567,
       "upazila_code":85,
       "union_code":17,
       "union_name":"Aria Bazar",
       "union_ban_name":"আরিয়া বাজার"
    },
    {
       "_id":3568,
       "upazila_code":85,
       "union_code":18,
       "union_name":"Asekpur",
       "union_ban_name":"আশেকপুর"
    },
    {
       "_id":3569,
       "upazila_code":85,
       "union_code":19,
       "union_name":"Chopinagar",
       "union_ban_name":"চোপিনগর"
    },
    {
       "_id":3570,
       "upazila_code":85,
       "union_code":30,
       "union_name":"Gohail",
       "union_ban_name":"গোহাইল"
    },
    {
       "_id":3571,
       "upazila_code":85,
       "union_code":38,
       "union_name":"Kharna",
       "union_ban_name":"খার্না"
    },
    {
       "_id":3572,
       "upazila_code":85,
       "union_code":43,
       "union_name":"Khotta Para",
       "union_ban_name":"খোটা প্যারা"
    },
    {
       "_id":3573,
       "upazila_code":85,
       "union_code":51,
       "union_name":"Madla",
       "union_ban_name":"মাদলা"
    },
    {
       "_id":3574,
       "upazila_code":85,
       "union_code":56,
       "union_name":"Majhira",
       "union_ban_name":"মজীরা"
    },
    {
       "_id":3576,
       "upazila_code":88,
       "union_code":19,
       "union_name":"Bishalpur",
       "union_ban_name":"বিশলপুর"
    },
    {
       "_id":3577,
       "upazila_code":88,
       "union_code":28,
       "union_name":"Garidaha",
       "union_ban_name":"গারিদাহ"
    },
    {
       "_id":3578,
       "upazila_code":88,
       "union_code":38,
       "union_name":"Khamarkandi",
       "union_ban_name":"খামারকান্দি"
    },
    {
       "_id":3580,
       "upazila_code":88,
       "union_code":57,
       "union_name":"Kusumbi",
       "union_ban_name":"কুসুম্বি"
    },
    {
       "_id":3581,
       "upazila_code":88,
       "union_code":66,
       "union_name":"Mirzapur",
       "union_ban_name":"মির্জাপুর"
    },
    {
       "_id":3582,
       "upazila_code":88,
       "union_code":77,
       "union_name":"Shah-Bandegi",
       "union_ban_name":"শাহ-বান্দী"
    },
    {
       "_id":3583,
       "upazila_code":88,
       "union_code":85,
       "union_name":"Shimabari",
       "union_ban_name":"শিমাবাড়ী"
    },
    {
       "_id":3584,
       "upazila_code":88,
       "union_code":95,
       "union_name":"Sughat",
       "union_ban_name":"সুঘাট"
    },
    {
       "_id":3585,
       "upazila_code":94,
       "union_code":11,
       "union_name":"Atmul",
       "union_ban_name":"এটিএমুল"
    },
    {
       "_id":3586,
       "upazila_code":94,
       "union_code":15,
       "union_name":"Bihar",
       "union_ban_name":"বিহার"
    },
    {
       "_id":3587,
       "upazila_code":94,
       "union_code":23,
       "union_name":"Buriganj",
       "union_ban_name":"বুরিগঞ্জ"
    },
    {
       "_id":3588,
       "upazila_code":94,
       "union_code":31,
       "union_name":"Deuli",
       "union_ban_name":"দেউলি"
    },
    {
       "_id":3589,
       "upazila_code":94,
       "union_code":39,
       "union_name":"Kichak",
       "union_ban_name":"কিচাক"
    },
    {
       "_id":3590,
       "upazila_code":94,
       "union_code":47,
       "union_name":"Maidanhata",
       "union_ban_name":"ময়দানটা"
    },
    {
       "_id":3591,
       "upazila_code":94,
       "union_code":55,
       "union_name":"Majhihatta",
       "union_ban_name":"মজিহত্তি"
    },
    {
       "_id":3592,
       "upazila_code":94,
       "union_code":63,
       "union_name":"Mokamtala",
       "union_ban_name":"মকামটালা"
    },
    {
       "_id":3593,
       "upazila_code":94,
       "union_code":71,
       "union_name":"Pirab",
       "union_ban_name":"পিরাব"
    },
    {
       "_id":3594,
       "upazila_code":94,
       "union_code":79,
       "union_name":"Roynagar",
       "union_ban_name":"রায়নগর"
    },
    {
       "_id":3596,
       "upazila_code":94,
       "union_code":94,
       "union_name":"Shibganj",
       "union_ban_name":"শিবগঞ্জ"
    },
    {
       "_id":3597,
       "upazila_code":95,
       "union_code":10,
       "union_name":"Balua",
       "union_ban_name":"বালুয়া."
    },
    {
       "_id":3598,
       "upazila_code":95,
       "union_code":31,
       "union_name":"Digdair",
       "union_ban_name":"ডিগডাইর"
    },
    {
       "_id":3599,
       "upazila_code":95,
       "union_code":42,
       "union_name":"Jorgachha",
       "union_ban_name":"জোর্গাছা"
    },
    {
       "_id":3600,
       "upazila_code":95,
       "union_code":52,
       "union_name":"Madhupur",
       "union_ban_name":"মধুপুর"
    },
    {
       "_id":3601,
       "upazila_code":95,
       "union_code":73,
       "union_name":"Sonatala",
       "union_ban_name":"সোনাতালা"
    },
    {
       "_id":3602,
       "upazila_code":95,
       "union_code":81,
       "union_name":"Pakulla",
       "union_ban_name":"পাকুলা"
    },
    {
       "_id":3603,
       "upazila_code":95,
       "union_code":84,
       "union_name":"Tekani Chukaina",
       "union_ban_name":"টেকানি চুকেনা"
    },
    {
       "_id":3604,
       "upazila_code":2,
       "union_code":19,
       "union_name":"Dakshin Akhaura",
       "union_ban_name":"দক্ষিণ আখাউড়া"
    },
    {
       "_id":3605,
       "upazila_code":2,
       "union_code":57,
       "union_name":"Dharkhar",
       "union_ban_name":"ধারখার"
    },
    {
       "_id":3606,
       "upazila_code":2,
       "union_code":76,
       "union_name":"Maniand",
       "union_ban_name":"মানিয়ানড"
    },
    {
       "_id":3607,
       "upazila_code":2,
       "union_code":85,
       "union_name":"Mogra",
       "union_ban_name":"মোগরা"
    },
    {
       "_id":3608,
       "upazila_code":2,
       "union_code":90,
       "union_name":"Uttar Akhaura",
       "union_ban_name":"উত্তর আখাউড়া"
    },
    {
       "_id":3609,
       "upazila_code":4,
       "union_code":14,
       "union_name":"Ayubpur",
       "union_ban_name":"আইয়ুবপুর"
    },
    {
       "_id":3610,
       "upazila_code":4,
       "union_code":23,
       "union_name":"Dariadaulat",
       "union_ban_name":"দারিয়াদাউলাত"
    },
    {
       "_id":3611,
       "upazila_code":4,
       "union_code":30,
       "union_name":"Pahariakandi",
       "union_ban_name":"পাহাড়িকন্দি"
    },
    {
       "_id":3612,
       "upazila_code":4,
       "union_code":36,
       "union_name":"Saifullakandi",
       "union_ban_name":"সাইফুলাকান্দি"
    },
    {
       "_id":3613,
       "upazila_code":4,
       "union_code":45,
       "union_name":"Rupasdi",
       "union_ban_name":"রূপসদি"
    },
    {
       "_id":3614,
       "upazila_code":4,
       "union_code":54,
       "union_name":"Manikpur",
       "union_ban_name":"মানিকপুর"
    },
    {
       "_id":3615,
       "upazila_code":4,
       "union_code":59,
       "union_name":"Darikandi",
       "union_ban_name":"দারিকান্দি"
    },
    {
       "_id":3616,
       "upazila_code":4,
       "union_code":68,
       "union_name":"Fardabad",
       "union_ban_name":"ফার্ডাবাদ"
    },
    {
       "_id":3617,
       "upazila_code":4,
       "union_code":77,
       "union_name":"Ujan Char",
       "union_ban_name":"উজান চর"
    },
    {
       "_id":3618,
       "upazila_code":4,
       "union_code":81,
       "union_name":"Salimabad",
       "union_ban_name":"সালিমাবাদ"
    },
    {
       "_id":3619,
       "upazila_code":4,
       "union_code":83,
       "union_name":"Sonarampur",
       "union_ban_name":"সোনারামপুর"
    },
    {
       "_id":3620,
       "upazila_code":4,
       "union_code":88,
       "union_name":"Tezkhali",
       "union_ban_name":"তেজখালী"
    },
    {
       "_id":3621,
       "upazila_code":4,
       "union_code":94,
       "union_name":"Banchharampur",
       "union_ban_name":"বঞ্চারামপুর"
    },
    {
       "_id":3622,
       "upazila_code":7,
       "union_code":28,
       "union_name":"Budhanti",
       "union_ban_name":"বুদান্তি"
    },
    {
       "_id":3623,
       "upazila_code":7,
       "union_code":32,
       "union_name":"Chandura",
       "union_ban_name":"চাঁদুড়া"
    },
    {
       "_id":3624,
       "upazila_code":7,
       "union_code":36,
       "union_name":"Char Islmapur",
       "union_ban_name":"চর ইসলামাপুর"
    },
    {
       "_id":3625,
       "upazila_code":7,
       "union_code":43,
       "union_name":"Champaknagar",
       "union_ban_name":"চাঁপকনগর"
    },
    {
       "_id":3626,
       "upazila_code":7,
       "union_code":52,
       "union_name":"Dakshin Singerbil",
       "union_ban_name":"দক্ষিণে সিঙ্গারবিল"
    },
    {
       "_id":3627,
       "upazila_code":7,
       "union_code":56,
       "union_name":"Harashpur",
       "union_ban_name":"হারশপুর"
    },
    {
       "_id":3628,
       "upazila_code":7,
       "union_code":66,
       "union_name":"Paharpur",
       "union_ban_name":"পাহাড়পুর"
    },
    {
       "_id":3629,
       "upazila_code":7,
       "union_code":72,
       "union_name":"Pattan",
       "union_ban_name":"পটান"
    },
    {
       "_id":3630,
       "upazila_code":7,
       "union_code":91,
       "union_name":"Uttar Ichhapur",
       "union_ban_name":"উত্তর ইছাপুর"
    },
    {
       "_id":3632,
       "upazila_code":13,
       "union_code":17,
       "union_name":"Basudeb",
       "union_ban_name":"বসুদেব"
    },
    {
       "_id":3633,
       "upazila_code":13,
       "union_code":48,
       "union_name":"Dakshin Natai",
       "union_ban_name":"দক্ষিণ নাটাই"
    },
    {
       "_id":3634,
       "upazila_code":13,
       "union_code":50,
       "union_name":"Shuhilpur",
       "union_ban_name":"শাহিলপুর"
    },
    {
       "_id":3635,
       "upazila_code":13,
       "union_code":59,
       "union_name":"Machhihata",
       "union_ban_name":"মাচিহাটা."
    },
    {
       "_id":3636,
       "upazila_code":13,
       "union_code":61,
       "union_name":"Majlishpur",
       "union_ban_name":"মজলিশপুর"
    },
    {
       "_id":3637,
       "upazila_code":13,
       "union_code":81,
       "union_name":"Purba Talsahar",
       "union_ban_name":"পূর্বা তসল্সার"
    },
    {
       "_id":3638,
       "upazila_code":13,
       "union_code":83,
       "union_name":"Ramrail",
       "union_ban_name":"রামরাইল"
    },
    {
       "_id":3639,
       "upazila_code":13,
       "union_code":86,
       "union_name":"Sadekpur",
       "union_ban_name":"সাদেকপুর"
    },
    {
       "_id":3640,
       "upazila_code":13,
       "union_code":88,
       "union_name":"Sultanpur",
       "union_ban_name":"সুলতানপুর"
    },
    {
       "_id":3641,
       "upazila_code":13,
       "union_code":93,
       "union_name":"Uttar Natai",
       "union_ban_name":"উত্তর নাতাই"
    },
    {
       "_id":3642,
       "upazila_code":13,
       "union_code":94,
       "union_name":"Budhal",
       "union_ban_name":"বুধাল"
    },
    {
       "_id":3643,
       "upazila_code":33,
       "union_code":12,
       "union_name":"Araisidha",
       "union_ban_name":"আরিসিধা"
    },
    {
       "_id":3644,
       "upazila_code":33,
       "union_code":14,
       "union_name":"Ashugang",
       "union_ban_name":"আশুগাং"
    },
    {
       "_id":3645,
       "upazila_code":33,
       "union_code":26,
       "union_name":"Char Chartala",
       "union_ban_name":"চর চার্টলা"
    },
    {
       "_id":3647,
       "upazila_code":33,
       "union_code":42,
       "union_name":"Lalpur",
       "union_ban_name":"লালপুর"
    },
    {
       "_id":3648,
       "upazila_code":33,
       "union_code":74,
       "union_name":"Paschim Talsahar",
       "union_ban_name":"পশ্চিম তালসাহার"
    },
    {
       "_id":3649,
       "upazila_code":33,
       "union_code":77,
       "union_name":"Sharifpur",
       "union_ban_name":"শরিফপুর"
    },
    {
       "_id":3650,
       "upazila_code":33,
       "union_code":83,
       "union_name":"Tarua",
       "union_ban_name":"পশ্চিম তালসাহার"
    },
    {
       "_id":3651,
       "upazila_code":63,
       "union_code":18,
       "union_name":"Badair",
       "union_ban_name":"বাদাইর"
    },
    {
       "_id":3652,
       "upazila_code":63,
       "union_code":31,
       "union_name":"Bayek",
       "union_ban_name":"বায়েক"
    },
    {
       "_id":3653,
       "upazila_code":63,
       "union_code":37,
       "union_name":"Binauti",
       "union_ban_name":"বিনাউতি."
    },
    {
       "_id":3654,
       "upazila_code":63,
       "union_code":50,
       "union_name":"Gopinathpur",
       "union_ban_name":"গোপীনাথপুর"
    },
    {
       "_id":3655,
       "upazila_code":63,
       "union_code":56,
       "union_name":"Kaimpur",
       "union_ban_name":"কাইমপুর"
    },
    {
       "_id":3656,
       "upazila_code":63,
       "union_code":63,
       "union_name":"Kasba Paschim",
       "union_ban_name":"কাসবা পাশ্চিম"
    },
    {
       "_id":3657,
       "upazila_code":63,
       "union_code":65,
       "union_name":"Kherera",
       "union_ban_name":"খেরেরা"
    },
    {
       "_id":3658,
       "upazila_code":63,
       "union_code":69,
       "union_name":"Kuti",
       "union_ban_name":"কুটি"
    },
    {
       "_id":3659,
       "upazila_code":63,
       "union_code":82,
       "union_name":"Mehari",
       "union_ban_name":"মেহারি"
    },
    {
       "_id":3660,
       "upazila_code":63,
       "union_code":94,
       "union_name":"Mulgram",
       "union_ban_name":"মুলগ্রাম"
    },
    {
       "_id":3661,
       "upazila_code":85,
       "union_code":12,
       "union_name":"Barikandi",
       "union_ban_name":"বারিকান্দি"
    },
    {
       "_id":3662,
       "upazila_code":85,
       "union_code":13,
       "union_name":"Biddyakut",
       "union_ban_name":"বিদিকুট"
    },
    {
       "_id":3663,
       "upazila_code":85,
       "union_code":18,
       "union_name":"Birgaon",
       "union_ban_name":"বীরগাঁও"
    },
    {
       "_id":3664,
       "upazila_code":85,
       "union_code":22,
       "union_name":"Bitghar (Tiara)",
       "union_ban_name":"বিটঘার (তিয়ারা)"
    },
    {
       "_id":3665,
       "upazila_code":85,
       "union_code":27,
       "union_name":"Ibrahimpur",
       "union_ban_name":"ইব্রাহিমপুর"
    },
    {
       "_id":3666,
       "upazila_code":85,
       "union_code":36,
       "union_name":"Junedpur",
       "union_ban_name":"জুনদপুর"
    },
    {
       "_id":3667,
       "upazila_code":85,
       "union_code":40,
       "union_name":"Kaitala Dakshin",
       "union_ban_name":"কিতলা দক্ষিণে"
    },
    {
       "_id":3668,
       "upazila_code":85,
       "union_code":42,
       "union_name":"Kaitala Uttar",
       "union_ban_name":"কৈতলা উত্তর"
    },
    {
       "_id":3669,
       "upazila_code":85,
       "union_code":45,
       "union_name":"Krishnanagar",
       "union_ban_name":"কৃষ্ণনগর"
    },
    {
       "_id":3670,
       "upazila_code":85,
       "union_code":47,
       "union_name":"Laur Fatehpur",
       "union_ban_name":"লৌর ফতেহপুর"
    },
    {
       "_id":3671,
       "upazila_code":85,
       "union_code":58,
       "union_name":"Natghar",
       "union_ban_name":"নাটঘার"
    },
    {
       "_id":3672,
       "upazila_code":85,
       "union_code":60,
       "union_name":"Paschim Nabinagar",
       "union_ban_name":"পাসচিম নবীনগর"
    },
    {
       "_id":3673,
       "upazila_code":85,
       "union_code":62,
       "union_name":"Purba Nabinagar",
       "union_ban_name":"পূর্বা নবীনগর"
    },
    {
       "_id":3674,
       "upazila_code":85,
       "union_code":67,
       "union_name":"Rasullabad",
       "union_ban_name":"রাসুলাবাদ"
    },
    {
       "_id":3675,
       "upazila_code":85,
       "union_code":72,
       "union_name":"Ratanpur",
       "union_ban_name":"রতনপুর"
    },
    {
       "_id":3676,
       "upazila_code":85,
       "union_code":75,
       "union_name":"Salimganj",
       "union_ban_name":"সলিমগঞ্জ"
    },
    {
       "_id":3677,
       "upazila_code":85,
       "union_code":81,
       "union_name":"Barail",
       "union_ban_name":"বারাইল"
    },
    {
       "_id":3678,
       "upazila_code":85,
       "union_code":83,
       "union_name":"Satmura",
       "union_ban_name":"সাতমুরা"
    },
    {
       "_id":3680,
       "upazila_code":85,
       "union_code":88,
       "union_name":"Shyamgram",
       "union_ban_name":"শ্যামগ্রাম"
    },
    {
       "_id":3681,
       "upazila_code":85,
       "union_code":90,
       "union_name":"Sreerampur",
       "union_ban_name":"শ্রীরামপুর"
    },
    {
       "_id":3682,
       "upazila_code":90,
       "union_code":14,
       "union_name":"Burishwar",
       "union_ban_name":"বুরিশ্বর"
    },
    {
       "_id":3683,
       "upazila_code":90,
       "union_code":17,
       "union_name":"Bolakot",
       "union_ban_name":"বোলাকোট"
    },
    {
       "_id":3684,
       "upazila_code":90,
       "union_code":21,
       "union_name":"Chapartala",
       "union_ban_name":"চপার্টালা"
    },
    {
       "_id":3685,
       "upazila_code":90,
       "union_code":29,
       "union_name":"Chatalpar",
       "union_ban_name":"চাতালপার"
    },
    {
       "_id":3686,
       "upazila_code":90,
       "union_code":36,
       "union_name":"Dhar Mandal",
       "union_ban_name":"ধর মণ্ডল"
    },
    {
       "_id":3687,
       "upazila_code":90,
       "union_code":43,
       "union_name":"Fandauk",
       "union_ban_name":"ফান্দাউক"
    },
    {
       "_id":3688,
       "upazila_code":90,
       "union_code":51,
       "union_name":"Goalnagar",
       "union_ban_name":"গোয়ালনগর"
    },
    {
       "_id":3689,
       "upazila_code":90,
       "union_code":58,
       "union_name":"Gokarna",
       "union_ban_name":"গোকারনা"
    },
    {
       "_id":3690,
       "upazila_code":90,
       "union_code":65,
       "union_name":"Guniak",
       "union_ban_name":"গুনিয়াক"
    },
    {
       "_id":3691,
       "upazila_code":90,
       "union_code":73,
       "union_name":"Haripur",
       "union_ban_name":"হরিপুর"
    },
    {
       "_id":3692,
       "upazila_code":90,
       "union_code":80,
       "union_name":"Kunda",
       "union_ban_name":"কুন্ডা"
    },
    {
       "_id":3693,
       "upazila_code":90,
       "union_code":87,
       "union_name":"Nasirnagar",
       "union_ban_name":"নাসিরনগর"
    },
    {
       "_id":3694,
       "upazila_code":90,
       "union_code":94,
       "union_name":"Purbabagh",
       "union_ban_name":"পূর্বাঘঘ"
    },
    {
       "_id":3695,
       "upazila_code":94,
       "union_code":13,
       "union_name":"Aorail",
       "union_ban_name":"আওরেল"
    },
    {
       "_id":3696,
       "upazila_code":94,
       "union_code":19,
       "union_name":"Chunta",
       "union_ban_name":"চুন্টা"
    },
    {
       "_id":3697,
       "upazila_code":94,
       "union_code":28,
       "union_name":"Kalikachchha",
       "union_ban_name":"কালিকছছা"
    },
    {
       "_id":3698,
       "upazila_code":94,
       "union_code":38,
       "union_name":"Noagaon",
       "union_ban_name":"নোগাঁও."
    },
    {
       "_id":3699,
       "upazila_code":94,
       "union_code":47,
       "union_name":"Pak Shimul",
       "union_ban_name":"পাক শিমুল"
    },
    {
       "_id":3700,
       "upazila_code":94,
       "union_code":76,
       "union_name":"Sarail",
       "union_ban_name":"সরাইল"
    },
    {
       "_id":3701,
       "upazila_code":94,
       "union_code":85,
       "union_name":"Shahbazpur",
       "union_ban_name":"শাহবাজপুর"
    },
    {
       "_id":3702,
       "upazila_code":94,
       "union_code":90,
       "union_name":"Shahjadapur",
       "union_ban_name":"শাহজাদাপুর"
    },
    {
       "_id":3703,
       "upazila_code":94,
       "union_code":94,
       "union_name":"Uttar Panisar",
       "union_ban_name":"উত্তর প্যানিসার"
    },
    {
       "_id":3704,
       "upazila_code":22,
       "union_code":18,
       "union_name":"Ashikati",
       "union_ban_name":"আশিকতী"
    },
    {
       "_id":3705,
       "upazila_code":22,
       "union_code":20,
       "union_name":"Kalyanpur",
       "union_ban_name":"কল্যাণপুর"
    },
    {
       "_id":3706,
       "upazila_code":22,
       "union_code":22,
       "union_name":"Baghadi",
       "union_ban_name":"বাগাদি"
    },
    {
       "_id":3707,
       "upazila_code":22,
       "union_code":27,
       "union_name":"Balia",
       "union_ban_name":"বালিয়া"
    },
    {
       "_id":3709,
       "upazila_code":22,
       "union_code":54,
       "union_name":"Chandra",
       "union_ban_name":"চন্দ্র"
    },
    {
       "_id":3710,
       "upazila_code":22,
       "union_code":58,
       "union_name":"Hanar Char",
       "union_ban_name":"হানর চর"
    },
    {
       "_id":3712,
       "upazila_code":22,
       "union_code":67,
       "union_name":"Maishadi",
       "union_ban_name":"মাশাদি"
    },
    {
       "_id":3713,
       "upazila_code":22,
       "union_code":76,
       "union_name":"Rajrajeshwar",
       "union_ban_name":"রাজজেশ্বর"
    },
    {
       "_id":3714,
       "upazila_code":22,
       "union_code":81,
       "union_name":"Shah Mahmudpur",
       "union_ban_name":"শাহ মাহমুদপুর"
    },
    {
       "_id":3715,
       "upazila_code":22,
       "union_code":85,
       "union_name":"Rampur",
       "union_ban_name":"রামপুরে"
    },
    {
       "_id":3716,
       "upazila_code":22,
       "union_code":90,
       "union_name":"Sakhua",
       "union_ban_name":"সাখুয়া।"
    },
    {
       "_id":3717,
       "upazila_code":22,
       "union_code":94,
       "union_name":"Tarpur Chandi",
       "union_ban_name":"তারপুর চাঁদি"
    },
    {
       "_id":3718,
       "upazila_code":45,
       "union_code":11,
       "union_name":"Paschim Baluthupa",
       "union_ban_name":"পশ্চিম বালুথুপা"
    },
    {
       "_id":3719,
       "upazila_code":45,
       "union_code":13,
       "union_name":"Purba Baluthupa",
       "union_ban_name":"পূর্বা বালুথুপা"
    },
    {
       "_id":3720,
       "upazila_code":45,
       "union_code":17,
       "union_name":"Purba Char Dukhia",
       "union_ban_name":"পূর্বা চর দখিয়া"
    },
    {
       "_id":3721,
       "upazila_code":45,
       "union_code":23,
       "union_name":"Paschim Char Dukhia",
       "union_ban_name":"পশ্চিম চর দুখিয়া"
    },
    {
       "_id":3722,
       "upazila_code":45,
       "union_code":35,
       "union_name":"Dakshin Faridganj",
       "union_ban_name":"দক্ষিণ ফরিদগঞ্জ"
    },
    {
       "_id":3723,
       "upazila_code":45,
       "union_code":41,
       "union_name":"Uttar Gobindapur",
       "union_ban_name":"উত্তর গোবিন্দপুর"
    },
    {
       "_id":3724,
       "upazila_code":45,
       "union_code":47,
       "union_name":"Dakshin Gobindapur",
       "union_ban_name":"দক্ষিণ গোবিন্দপুর"
    },
    {
       "_id":3725,
       "upazila_code":45,
       "union_code":53,
       "union_name":"Purba Gupti",
       "union_ban_name":"পূর্বা গুপ্ত"
    },
    {
       "_id":3726,
       "upazila_code":45,
       "union_code":59,
       "union_name":"Paschim Gupti",
       "union_ban_name":"পাশিম গুপ্তী"
    },
    {
       "_id":3727,
       "upazila_code":45,
       "union_code":65,
       "union_name":"Uttar Paikpara",
       "union_ban_name":"উত্তর পাইকপাড়া"
    },
    {
       "_id":3728,
       "upazila_code":45,
       "union_code":71,
       "union_name":"Dakshin Paik Para",
       "union_ban_name":"দক্ষিণ পিক প্যারা"
    },
    {
       "_id":3729,
       "upazila_code":45,
       "union_code":77,
       "union_name":"Uttar Rupsa",
       "union_ban_name":"উত্তর রূপসা"
    },
    {
       "_id":3730,
       "upazila_code":45,
       "union_code":83,
       "union_name":"Dakshin Rupsa",
       "union_ban_name":"দক্ষিণ রূপসা"
    },
    {
       "_id":3731,
       "upazila_code":45,
       "union_code":89,
       "union_name":"Purba Subidpur",
       "union_ban_name":"পূর্বা সুবিদপুর"
    },
    {
       "_id":3732,
       "upazila_code":45,
       "union_code":95,
       "union_name":"Paschim Subidpur",
       "union_ban_name":"পশ্চিম সুবিদপুর"
    },
    {
       "_id":3733,
       "upazila_code":47,
       "union_code":11,
       "union_name":"Uttar Algi Durgapur",
       "union_ban_name":"উত্তর আলজি দুর্গাপুর"
    },
    {
       "_id":3734,
       "upazila_code":47,
       "union_code":23,
       "union_name":"Dakshin Algi Durgapur",
       "union_ban_name":"দক্ষিণে আলজি দুর্গাপুর"
    },
    {
       "_id":3735,
       "upazila_code":47,
       "union_code":35,
       "union_name":"Char Bhairabi",
       "union_ban_name":"চর ভৈরবি"
    },
    {
       "_id":3736,
       "upazila_code":47,
       "union_code":47,
       "union_name":"Haim Char",
       "union_ban_name":"হিম চার"
    },
    {
       "_id":3737,
       "upazila_code":47,
       "union_code":59,
       "union_name":"Gazipur",
       "union_ban_name":"গাজীপুর"
    },
    {
       "_id":3739,
       "upazila_code":49,
       "union_code":18,
       "union_name":"Purba Barkul",
       "union_ban_name":"পূর্বা বরখুল"
    },
    {
       "_id":3740,
       "upazila_code":49,
       "union_code":20,
       "union_name":"Paschim Barkul",
       "union_ban_name":"পশ্চিম বারকুল"
    },
    {
       "_id":3741,
       "upazila_code":49,
       "union_code":25,
       "union_name":"Uttar Gandharabpur",
       "union_ban_name":"উত্তর গান্ধরাবপুর"
    },
    {
       "_id":3742,
       "upazila_code":49,
       "union_code":30,
       "union_name":"Dakshin Gandharbapur",
       "union_ban_name":"দক্ষিণ গান্ধবাপুর"
    },
    {
       "_id":3743,
       "upazila_code":49,
       "union_code":35,
       "union_name":"Hajiganj",
       "union_ban_name":"হাজীগঞ্জ"
    },
    {
       "_id":3744,
       "upazila_code":49,
       "union_code":50,
       "union_name":"Uttar Kalocho",
       "union_ban_name":"উত্তর কালোচো"
    },
    {
       "_id":3745,
       "upazila_code":49,
       "union_code":55,
       "union_name":"Dakshin Kalocho",
       "union_ban_name":"দক্ষিণ কালোচো"
    },
    {
       "_id":3746,
       "upazila_code":49,
       "union_code":60,
       "union_name":"Pachim Hatila",
       "union_ban_name":"পচিম হাতিলা"
    },
    {
       "_id":3747,
       "upazila_code":49,
       "union_code":65,
       "union_name":"Hatila Purba",
       "union_ban_name":"হাতিলা পূর্বা"
    },
    {
       "_id":3748,
       "upazila_code":49,
       "union_code":70,
       "union_name":"Uttar Rajargaon",
       "union_ban_name":"উত্তর রাজগন্যান"
    },
    {
       "_id":3749,
       "upazila_code":49,
       "union_code":75,
       "union_name":"Bakila",
       "union_ban_name":"বাকিলা"
    },
    {
       "_id":3750,
       "upazila_code":58,
       "union_code":13,
       "union_name":"Ashrafpur",
       "union_ban_name":"আশরাফপুর"
    },
    {
       "_id":3751,
       "upazila_code":58,
       "union_code":15,
       "union_name":"Bitara",
       "union_ban_name":"বিটারা"
    },
    {
       "_id":3752,
       "upazila_code":58,
       "union_code":23,
       "union_name":"Uttar Gohat",
       "union_ban_name":"উত্তর গোহাট"
    },
    {
       "_id":3753,
       "upazila_code":58,
       "union_code":31,
       "union_name":"Dakshin Gohat",
       "union_ban_name":"দক্ষিণ গোহাট"
    },
    {
       "_id":3754,
       "upazila_code":58,
       "union_code":39,
       "union_name":"Uttar Kachua",
       "union_ban_name":"উত্তর কচুয়া"
    },
    {
       "_id":3755,
       "upazila_code":58,
       "union_code":47,
       "union_name":"Dakshin Kachua",
       "union_ban_name":"দক্ষিণ কচুয়া"
    },
    {
       "_id":3756,
       "upazila_code":58,
       "union_code":55,
       "union_name":"Kadla",
       "union_ban_name":"কাদলা"
    },
    {
       "_id":3757,
       "upazila_code":58,
       "union_code":63,
       "union_name":"Karaia",
       "union_ban_name":"কারিয়া"
    },
    {
       "_id":3758,
       "upazila_code":58,
       "union_code":71,
       "union_name":"Sachar",
       "union_ban_name":"সাচার"
    },
    {
       "_id":3759,
       "upazila_code":58,
       "union_code":79,
       "union_name":"Pathair",
       "union_ban_name":"প্যাথেয়ার"
    },
    {
       "_id":3760,
       "upazila_code":58,
       "union_code":87,
       "union_name":"Purba Sahadebpur",
       "union_ban_name":"পূর্বা সাহাদবপুর"
    },
    {
       "_id":3761,
       "upazila_code":58,
       "union_code":94,
       "union_name":"Paschim Sahadebpur",
       "union_ban_name":"পশ্চিম সহদেবপুর"
    },
    {
       "_id":3762,
       "upazila_code":76,
       "union_code":34,
       "union_name":"Khadergaon",
       "union_ban_name":"খাদেরগাঁও"
    },
    {
       "_id":3763,
       "upazila_code":76,
       "union_code":43,
       "union_name":"Narayanpur",
       "union_ban_name":"নারায়ণপুর"
    },
    {
       "_id":3764,
       "upazila_code":76,
       "union_code":56,
       "union_name":"Uttar Nayergaon",
       "union_ban_name":"উত্তর নয়েগাঁও"
    },
    {
       "_id":3765,
       "upazila_code":76,
       "union_code":60,
       "union_name":"Uttar Upadi",
       "union_ban_name":"উত্তর উপাদামা"
    },
    {
       "_id":3766,
       "upazila_code":76,
       "union_code":82,
       "union_name":"Dakhsin Nayergaon",
       "union_ban_name":"দাখসিন নয়েগাঁও"
    },
    {
       "_id":3767,
       "upazila_code":76,
       "union_code":86,
       "union_name":"Dakshin Upadi",
       "union_ban_name":"দক্ষিণে উপাদি"
    },
    {
       "_id":3768,
       "upazila_code":79,
       "union_code":11,
       "union_name":"Baganbari",
       "union_ban_name":"বাগানবাড়ী"
    },
    {
       "_id":3769,
       "upazila_code":79,
       "union_code":13,
       "union_name":"Farajikandi",
       "union_ban_name":"ফারজিকান্দি"
    },
    {
       "_id":3771,
       "upazila_code":79,
       "union_code":17,
       "union_name":"Purba Fatehpur",
       "union_ban_name":"পূর্বা ফতেহপুর"
    },
    {
       "_id":3772,
       "upazila_code":79,
       "union_code":21,
       "union_name":"Eklaspur",
       "union_ban_name":"একলাসপুর"
    },
    {
       "_id":3773,
       "upazila_code":79,
       "union_code":23,
       "union_name":"Gajra",
       "union_ban_name":"গজরা"
    },
    {
       "_id":3774,
       "upazila_code":79,
       "union_code":25,
       "union_name":"Islamabad",
       "union_ban_name":"ইসলামাবাদ"
    },
    {
       "_id":3775,
       "upazila_code":79,
       "union_code":30,
       "union_name":"Jahirabad",
       "union_ban_name":"জহিরাবাদ"
    },
    {
       "_id":3776,
       "upazila_code":79,
       "union_code":32,
       "union_name":"Kalakanda",
       "union_ban_name":"কালকান্দা"
    },
    {
       "_id":3777,
       "upazila_code":79,
       "union_code":38,
       "union_name":"Mohanpur",
       "union_ban_name":"মোহনপুর"
    },
    {
       "_id":3778,
       "upazila_code":79,
       "union_code":64,
       "union_name":"Sadullapur",
       "union_ban_name":"সাদুলাপুর"
    },
    {
       "_id":3779,
       "upazila_code":79,
       "union_code":73,
       "union_name":"Satnal",
       "union_ban_name":"সাতনাল"
    },
    {
       "_id":3780,
       "upazila_code":79,
       "union_code":90,
       "union_name":"Sultanabad",
       "union_ban_name":"সুলতানাবাদ"
    },
    {
       "_id":3781,
       "upazila_code":79,
       "union_code":94,
       "union_name":"Paschim Fatehpur",
       "union_ban_name":"পাশ্চিম ফতেহপুর"
    },
    {
       "_id":3782,
       "upazila_code":95,
       "union_code":15,
       "union_name":"East Chitasi",
       "union_ban_name":"পূর্ব চিতাসি"
    },
    {
       "_id":3783,
       "upazila_code":95,
       "union_code":20,
       "union_name":"West Chitasi",
       "union_ban_name":"পশ্চিম চিতাসি"
    },
    {
       "_id":3784,
       "upazila_code":95,
       "union_code":60,
       "union_name":"Uttar Meher",
       "union_ban_name":"উত্তর মেহের"
    },
    {
       "_id":3785,
       "upazila_code":95,
       "union_code":65,
       "union_name":"Dakshin Meher",
       "union_ban_name":"দক্ষিণ মেহের"
    },
    {
       "_id":3786,
       "upazila_code":95,
       "union_code":80,
       "union_name":"Uttar Roysree",
       "union_ban_name":"উত্তর রয়িস্রী"
    },
    {
       "_id":3787,
       "upazila_code":95,
       "union_code":82,
       "union_name":"Dakshin Roysree",
       "union_ban_name":"দক্ষিণ রয়িস্রী"
    },
    {
       "_id":3788,
       "upazila_code":95,
       "union_code":85,
       "union_name":"Uttar Suchi Para",
       "union_ban_name":"উত্তর সুদী পারা"
    },
    {
       "_id":3789,
       "upazila_code":95,
       "union_code":90,
       "union_name":"Dakshin Suchi Para",
       "union_ban_name":"দক্ষিণ সুচি পাড়া"
    },
    {
       "_id":3790,
       "upazila_code":95,
       "union_code":95,
       "union_name":"Tamta Dakshin",
       "union_ban_name":"তমটা দক্ষিণে"
    },
    {
       "_id":3791,
       "upazila_code":18,
       "union_code":18,
       "union_name":"Bholahat",
       "union_ban_name":"ভোলাহাট"
    },
    {
       "_id":3792,
       "upazila_code":18,
       "union_code":37,
       "union_name":"Daldali",
       "union_ban_name":"দোদালি"
    },
    {
       "_id":3793,
       "upazila_code":18,
       "union_code":56,
       "union_name":"Gohalbari",
       "union_ban_name":"গোহালবাড়ি"
    },
    {
       "_id":3794,
       "upazila_code":18,
       "union_code":75,
       "union_name":"Jambaria",
       "union_ban_name":"জাম্বারিয়া"
    },
    {
       "_id":3796,
       "upazila_code":37,
       "union_code":21,
       "union_name":"Bhangabaria",
       "union_ban_name":"ভঙ্গাবরিয়া"
    },
    {
       "_id":3797,
       "upazila_code":37,
       "union_code":31,
       "union_name":"Boalia",
       "union_ban_name":"বোয়ালিয়া"
    },
    {
       "_id":3798,
       "upazila_code":37,
       "union_code":42,
       "union_name":"Chowdala",
       "union_ban_name":"চৌদলা"
    },
    {
       "_id":3799,
       "upazila_code":37,
       "union_code":52,
       "union_name":"Gomastapur",
       "union_ban_name":"গোমস্তাপুর"
    },
    {
       "_id":3800,
       "upazila_code":37,
       "union_code":63,
       "union_name":"Parbatipur",
       "union_ban_name":"পার্বতীপুর"
    },
    {
       "_id":3801,
       "upazila_code":37,
       "union_code":73,
       "union_name":"Radhanagar",
       "union_ban_name":"রাধানগর"
    },
    {
       "_id":3802,
       "upazila_code":37,
       "union_code":84,
       "union_name":"Rohanpur",
       "union_ban_name":"রোহানপুর"
    },
    {
       "_id":3803,
       "upazila_code":56,
       "union_code":19,
       "union_name":"Fatehpur",
       "union_ban_name":"ফতেহপুর"
    },
    {
       "_id":3804,
       "upazila_code":56,
       "union_code":38,
       "union_name":"Kasba",
       "union_ban_name":"কাসবা"
    },
    {
       "_id":3805,
       "upazila_code":56,
       "union_code":57,
       "union_name":"Nachole",
       "union_ban_name":"নাচোলে"
    },
    {
       "_id":3806,
       "upazila_code":56,
       "union_code":76,
       "union_name":"Nizampur",
       "union_ban_name":"নিজামপুর"
    },
    {
       "_id":3807,
       "upazila_code":66,
       "union_code":17,
       "union_name":"Alatuli",
       "union_ban_name":"আলাতুলি"
    },
    {
       "_id":3808,
       "upazila_code":66,
       "union_code":18,
       "union_name":"Balidanga",
       "union_ban_name":"বালিদাঙ্গা"
    },
    {
       "_id":3809,
       "upazila_code":66,
       "union_code":19,
       "union_name":"Baragharia",
       "union_ban_name":"বারঘরিয়া"
    },
    {
       "_id":3810,
       "upazila_code":66,
       "union_code":20,
       "union_name":"Char Anupnagar",
       "union_ban_name":"চর আনুপনগর"
    },
    {
       "_id":3811,
       "upazila_code":66,
       "union_code":22,
       "union_name":"Char Bagdanga",
       "union_ban_name":"চার বাগদঙ্গা"
    },
    {
       "_id":3812,
       "upazila_code":66,
       "union_code":27,
       "union_name":"Debinagar",
       "union_ban_name":"দেবীনগর"
    },
    {
       "_id":3813,
       "upazila_code":66,
       "union_code":33,
       "union_name":"Gobratala",
       "union_ban_name":"গবরাতলা"
    },
    {
       "_id":3814,
       "upazila_code":66,
       "union_code":39,
       "union_name":"Islampur",
       "union_ban_name":"ইসলামপুর"
    },
    {
       "_id":3815,
       "upazila_code":66,
       "union_code":44,
       "union_name":"Jhilim",
       "union_ban_name":"ঝিলিম"
    },
    {
       "_id":3816,
       "upazila_code":66,
       "union_code":55,
       "union_name":"Maharajpur",
       "union_ban_name":"মহারাজপুর"
    },
    {
       "_id":3818,
       "upazila_code":66,
       "union_code":83,
       "union_name":"Ranihati",
       "union_ban_name":"রানীহতি"
    },
    {
       "_id":3819,
       "upazila_code":66,
       "union_code":89,
       "union_name":"Shahjahanpur",
       "union_ban_name":"শাহজাহানপুর"
    },
    {
       "_id":3820,
       "upazila_code":66,
       "union_code":94,
       "union_name":"Sundarpur",
       "union_ban_name":"সুন্দরপুরপুর"
    },
    {
       "_id":3821,
       "upazila_code":88,
       "union_code":10,
       "union_name":"Binodpur",
       "union_ban_name":"বিনোদপুর"
    },
    {
       "_id":3822,
       "upazila_code":88,
       "union_code":11,
       "union_name":"Chak Kirti",
       "union_ban_name":"চক কিরি"
    },
    {
       "_id":3823,
       "upazila_code":88,
       "union_code":17,
       "union_name":"Daipukuria",
       "union_ban_name":"দিপুকুরিয়া"
    },
    {
       "_id":3824,
       "upazila_code":88,
       "union_code":23,
       "union_name":"Dhainagar",
       "union_ban_name":"ধনগর"
    },
    {
       "_id":3825,
       "upazila_code":88,
       "union_code":29,
       "union_name":"Durlabhpur",
       "union_ban_name":"দুরুভপুর"
    },
    {
       "_id":3826,
       "upazila_code":88,
       "union_code":35,
       "union_name":"Ghorapakhia",
       "union_ban_name":"ঘোরাপাখিয়া"
    },
    {
       "_id":3827,
       "upazila_code":88,
       "union_code":41,
       "union_name":"Kansat",
       "union_ban_name":"কানসাত"
    },
    {
       "_id":3828,
       "upazila_code":88,
       "union_code":47,
       "union_name":"Mobarakpur",
       "union_ban_name":"মোবারকপুর"
    },
    {
       "_id":3829,
       "upazila_code":88,
       "union_code":53,
       "union_name":"Manakosa",
       "union_ban_name":"মানাকোসা"
    },
    {
       "_id":3830,
       "upazila_code":88,
       "union_code":59,
       "union_name":"Naya Naobhanga",
       "union_ban_name":"নয়া নওবঙ্গা"
    },
    {
       "_id":3831,
       "upazila_code":88,
       "union_code":65,
       "union_name":"Panka",
       "union_ban_name":"পঙ্কা"
    },
    {
       "_id":3832,
       "upazila_code":88,
       "union_code":71,
       "union_name":"Satrujitpur",
       "union_ban_name":"সট্রুজিৎপুর"
    },
    {
       "_id":3833,
       "upazila_code":88,
       "union_code":77,
       "union_name":"Shahbajpur",
       "union_ban_name":"শাহবাজপুর"
    },
    {
       "_id":3834,
       "upazila_code":88,
       "union_code":89,
       "union_name":"Shyampur",
       "union_ban_name":"শ্যামপুর"
    },
    {
       "_id":3835,
       "upazila_code":88,
       "union_code":95,
       "union_name":"Uzirpur",
       "union_ban_name":"উজিরপুর"
    },
    {
       "_id":3836,
       "upazila_code":4,
       "union_code":15,
       "union_name":"Anowara",
       "union_ban_name":"আনোয়ারা"
    },
    {
       "_id":3837,
       "upazila_code":4,
       "union_code":19,
       "union_name":"Bairag",
       "union_ban_name":"বৈরাগ"
    },
    {
       "_id":3838,
       "upazila_code":4,
       "union_code":28,
       "union_name":"Barakhain",
       "union_ban_name":"বারখাইন"
    },
    {
       "_id":3839,
       "upazila_code":4,
       "union_code":38,
       "union_name":"Barasat",
       "union_ban_name":"বারাসাত"
    },
    {
       "_id":3840,
       "upazila_code":4,
       "union_code":47,
       "union_name":"Burumchhara",
       "union_ban_name":"বুরুঝহার"
    },
    {
       "_id":3841,
       "upazila_code":4,
       "union_code":57,
       "union_name":"Battali",
       "union_ban_name":"বতালি"
    },
    {
       "_id":3842,
       "upazila_code":4,
       "union_code":66,
       "union_name":"Chatari",
       "union_ban_name":"চাতারি"
    },
    {
       "_id":3843,
       "upazila_code":4,
       "union_code":76,
       "union_name":"Haildhar",
       "union_ban_name":"হাইলদার"
    },
    {
       "_id":3844,
       "upazila_code":4,
       "union_code":81,
       "union_name":"Juidandi",
       "union_ban_name":"জুসিডি"
    },
    {
       "_id":3845,
       "upazila_code":4,
       "union_code":85,
       "union_name":"Paraikora",
       "union_ban_name":"পারাইকোরা"
    },
    {
       "_id":3846,
       "upazila_code":4,
       "union_code":95,
       "union_name":"Roypur",
       "union_ban_name":"রায়পুর"
    },
    {
       "_id":3847,
       "upazila_code":8,
       "union_code":11,
       "union_name":"Baharchhara",
       "union_ban_name":"বাহারচরা"
    },
    {
       "_id":3848,
       "upazila_code":8,
       "union_code":12,
       "union_name":"Bailchhari",
       "union_ban_name":"বেইলছড়ি."
    },
    {
       "_id":3849,
       "upazila_code":8,
       "union_code":18,
       "union_name":"Chambal",
       "union_ban_name":"চম্বল"
    },
    {
       "_id":3850,
       "upazila_code":8,
       "union_code":25,
       "union_name":"Chhanua",
       "union_ban_name":"ছানুয়া"
    },
    {
       "_id":3851,
       "upazila_code":8,
       "union_code":31,
       "union_name":"Gandamara",
       "union_ban_name":"গান্ধমারা"
    },
    {
       "_id":3852,
       "upazila_code":8,
       "union_code":44,
       "union_name":"Kalipur",
       "union_ban_name":"কালীপুর"
    },
    {
       "_id":3853,
       "upazila_code":8,
       "union_code":50,
       "union_name":"Katharia",
       "union_ban_name":"ক্যাথরিয়া"
    },
    {
       "_id":3854,
       "upazila_code":8,
       "union_code":56,
       "union_name":"Khankhanabad",
       "union_ban_name":"খানকানাবাদ"
    },
    {
       "_id":3855,
       "upazila_code":8,
       "union_code":63,
       "union_name":"Puichhari",
       "union_ban_name":"পুইছড়ি"
    },
    {
       "_id":3856,
       "upazila_code":8,
       "union_code":69,
       "union_name":"Pukuria",
       "union_ban_name":"পুকুরিয়া"
    },
    {
       "_id":3857,
       "upazila_code":8,
       "union_code":75,
       "union_name":"Sadhanpur",
       "union_ban_name":"সদানপুর"
    },
    {
       "_id":3858,
       "upazila_code":8,
       "union_code":82,
       "union_name":"Saral",
       "union_ban_name":"সরল"
    },
    {
       "_id":3859,
       "upazila_code":8,
       "union_code":88,
       "union_name":"Sekherkhil",
       "union_ban_name":"সেখেরখিল"
    },
    {
       "_id":3860,
       "upazila_code":8,
       "union_code":94,
       "union_name":"Silkup",
       "union_ban_name":"সিল্কুপ"
    },
    {
       "_id":3861,
       "upazila_code":12,
       "union_code":17,
       "union_name":"Ahla Karaldanga",
       "union_ban_name":"আহলা করদঙ্গা"
    },
    {
       "_id":3862,
       "upazila_code":12,
       "union_code":19,
       "union_name":"Amuchia",
       "union_ban_name":"আমুচিয়া."
    },
    {
       "_id":3863,
       "upazila_code":12,
       "union_code":28,
       "union_name":"Charandwip",
       "union_ban_name":"চরণদ্বীপ"
    },
    {
       "_id":3864,
       "upazila_code":12,
       "union_code":38,
       "union_name":"Purba Gomdandi",
       "union_ban_name":"পূর্বা গোমদান্ডি"
    },
    {
       "_id":3865,
       "upazila_code":12,
       "union_code":47,
       "union_name":"Kandhurkhil",
       "union_ban_name":"কান্দুরখিল"
    },
    {
       "_id":3866,
       "upazila_code":12,
       "union_code":50,
       "union_name":"Paschim Gomdandi",
       "union_ban_name":"পশ্চিম গোমদান্দি"
    },
    {
       "_id":3867,
       "upazila_code":12,
       "union_code":57,
       "union_name":"Popadia",
       "union_ban_name":"পোপাদিয়া"
    },
    {
       "_id":3868,
       "upazila_code":12,
       "union_code":66,
       "union_name":"Saroatali",
       "union_ban_name":"সারোতালি"
    },
    {
       "_id":3869,
       "upazila_code":12,
       "union_code":76,
       "union_name":"Sakpura",
       "union_ban_name":"সাকপুরা"
    },
    {
       "_id":3870,
       "upazila_code":12,
       "union_code":85,
       "union_name":"Sreepur Kharandwip",
       "union_ban_name":"শ্রীপুর খাড়ান্দীপ"
    },
    {
       "_id":3871,
       "upazila_code":18,
       "union_code":13,
       "union_name":"Bailtali",
       "union_ban_name":"বৈলতলী"
    },
    {
       "_id":3872,
       "upazila_code":18,
       "union_code":19,
       "union_name":"Barkal",
       "union_ban_name":"বরকল"
    },
    {
       "_id":3873,
       "upazila_code":18,
       "union_code":28,
       "union_name":"Barama",
       "union_ban_name":"বারামা"
    },
    {
       "_id":3874,
       "upazila_code":18,
       "union_code":38,
       "union_name":"Dhopachhari",
       "union_ban_name":"ধোপাছড়ি"
    },
    {
       "_id":3875,
       "upazila_code":18,
       "union_code":47,
       "union_name":"Dohazari",
       "union_ban_name":"দোহাজারি"
    },
    {
       "_id":3876,
       "upazila_code":18,
       "union_code":66,
       "union_name":"Hashimpur",
       "union_ban_name":"হাশিমপুর"
    },
    {
       "_id":3877,
       "upazila_code":18,
       "union_code":76,
       "union_name":"Joara",
       "union_ban_name":"জোয়ারা"
    },
    {
       "_id":3878,
       "upazila_code":18,
       "union_code":85,
       "union_name":"Kanchanabad",
       "union_ban_name":"কাঞ্চনাবাদ"
    },
    {
       "_id":3879,
       "upazila_code":18,
       "union_code":95,
       "union_name":"Satbaria",
       "union_ban_name":"সটবাড়িয়া."
    },
    {
       "_id":3881,
       "upazila_code":33,
       "union_code":13,
       "union_name":"Bagan Bazar",
       "union_ban_name":"বাগান বাজার"
    },
    {
       "_id":3882,
       "upazila_code":33,
       "union_code":14,
       "union_name":"Baktapur",
       "union_ban_name":"বক্তপুর"
    },
    {
       "_id":3883,
       "upazila_code":33,
       "union_code":19,
       "union_name":"Bhujpur",
       "union_ban_name":"ভূজপুর"
    },
    {
       "_id":3884,
       "upazila_code":33,
       "union_code":23,
       "union_name":"Dantmara",
       "union_ban_name":"দান্তমারা"
    },
    {
       "_id":3885,
       "upazila_code":33,
       "union_code":28,
       "union_name":"Dharmapur",
       "union_ban_name":"ধর্মপুর"
    },
    {
       "_id":3886,
       "upazila_code":33,
       "union_code":33,
       "union_name":"Dhurung",
       "union_ban_name":"ধুরুং"
    },
    {
       "_id":3887,
       "upazila_code":33,
       "union_code":38,
       "union_name":"Daulatpur",
       "union_ban_name":"দৌলতপুর"
    },
    {
       "_id":3888,
       "upazila_code":33,
       "union_code":42,
       "union_name":"Harwalchhari",
       "union_ban_name":"হারুয়ালছড়ি"
    },
    {
       "_id":3889,
       "upazila_code":33,
       "union_code":47,
       "union_name":"Jafarnagar",
       "union_ban_name":"জাফরগর"
    },
    {
       "_id":3890,
       "upazila_code":33,
       "union_code":52,
       "union_name":"Kanchan Nagar",
       "union_ban_name":"কাঞ্চন নগর"
    },
    {
       "_id":3891,
       "upazila_code":33,
       "union_code":55,
       "union_name":"Khiram",
       "union_ban_name":"খিরাম"
    },
    {
       "_id":3892,
       "upazila_code":33,
       "union_code":57,
       "union_name":"Lelang",
       "union_ban_name":"লেলাং"
    },
    {
       "_id":3893,
       "upazila_code":33,
       "union_code":61,
       "union_name":"Nanupur",
       "union_ban_name":"নানুপুর"
    },
    {
       "_id":3894,
       "upazila_code":33,
       "union_code":66,
       "union_name":"Narayanhat",
       "union_ban_name":"নারায়ণহাট"
    },
    {
       "_id":3895,
       "upazila_code":33,
       "union_code":71,
       "union_name":"Paindanga",
       "union_ban_name":"পেইনডাঙ্গা"
    },
    {
       "_id":3896,
       "upazila_code":33,
       "union_code":76,
       "union_name":"Rangamatia",
       "union_ban_name":"রাঙ্গামাটিয়া"
    },
    {
       "_id":3897,
       "upazila_code":33,
       "union_code":80,
       "union_name":"Roushangiri",
       "union_ban_name":"রোসাংগিরি"
    },
    {
       "_id":3898,
       "upazila_code":33,
       "union_code":85,
       "union_name":"Suabil",
       "union_ban_name":"সবেল"
    },
    {
       "_id":3899,
       "upazila_code":33,
       "union_code":90,
       "union_name":"Samitirhat",
       "union_ban_name":"সমিতিরহাট"
    },
    {
       "_id":3901,
       "upazila_code":37,
       "union_code":10,
       "union_name":"Burish Char",
       "union_ban_name":"বুরিশ চার"
    },
    {
       "_id":3902,
       "upazila_code":37,
       "union_code":11,
       "union_name":"Chhibatali",
       "union_ban_name":"ছিবতলী"
    },
    {
       "_id":3903,
       "upazila_code":37,
       "union_code":17,
       "union_name":"Chikandandi",
       "union_ban_name":"চিকনদন্ডী"
    },
    {
       "_id":3904,
       "upazila_code":37,
       "union_code":23,
       "union_name":"Dakshin Madarsha",
       "union_ban_name":"দক্ষিণ মাদ্রাসা"
    },
    {
       "_id":3905,
       "upazila_code":37,
       "union_code":29,
       "union_name":"Dhalai",
       "union_ban_name":"ধলাই"
    },
    {
       "_id":3907,
       "upazila_code":37,
       "union_code":41,
       "union_name":"Forhadabad",
       "union_ban_name":"ফরহাদাবাদ"
    },
    {
       "_id":3908,
       "upazila_code":37,
       "union_code":47,
       "union_name":"Garduara",
       "union_ban_name":"গার্ডুয়ার"
    },
    {
       "_id":3909,
       "upazila_code":37,
       "union_code":53,
       "union_name":"Guman Mardan",
       "union_ban_name":"গুমান মর্দান"
    },
    {
       "_id":3910,
       "upazila_code":37,
       "union_code":59,
       "union_name":"Hathazari",
       "union_ban_name":"হাটহাজারী"
    },
    {
       "_id":3911,
       "upazila_code":37,
       "union_code":65,
       "union_name":"Mekhal",
       "union_ban_name":"মেখল"
    },
    {
       "_id":3913,
       "upazila_code":37,
       "union_code":77,
       "union_name":"Nangalmora",
       "union_ban_name":"নাঙ্গলমোরা"
    },
    {
       "_id":3915,
       "upazila_code":37,
       "union_code":95,
       "union_name":"Uttar Madarsa",
       "union_ban_name":"উত্তর মাদ্রাসা"
    },
    {
       "_id":3916,
       "upazila_code":37,
       "union_code":98,
       "union_name":"Chittagang Cnt",
       "union_ban_name":"চট্টগ্রাম সিএনটি"
    },
    {
       "_id":3917,
       "upazila_code":47,
       "union_code":10,
       "union_name":"Amirabad",
       "union_ban_name":"আমিরাবাদ"
    },
    {
       "_id":3918,
       "upazila_code":47,
       "union_code":13,
       "union_name":"Adhunagar",
       "union_ban_name":"আধুনগর"
    },
    {
       "_id":3919,
       "upazila_code":47,
       "union_code":18,
       "union_name":"Barahatia",
       "union_ban_name":"বারহাতিয়া"
    },
    {
       "_id":3920,
       "upazila_code":47,
       "union_code":25,
       "union_name":"Charamba",
       "union_ban_name":"চারাম্বা"
    },
    {
       "_id":3921,
       "upazila_code":47,
       "union_code":32,
       "union_name":"Chunati",
       "union_ban_name":"চুনাতি"
    },
    {
       "_id":3922,
       "upazila_code":47,
       "union_code":43,
       "union_name":"Kalauzan",
       "union_ban_name":"কালৌজান"
    },
    {
       "_id":3923,
       "upazila_code":47,
       "union_code":62,
       "union_name":"Lohagara",
       "union_ban_name":"লোহাগড়া"
    },
    {
       "_id":3924,
       "upazila_code":47,
       "union_code":73,
       "union_name":"Padua",
       "union_ban_name":"পাদুয়া"
    },
    {
       "_id":3925,
       "upazila_code":47,
       "union_code":80,
       "union_name":"Putibila",
       "union_ban_name":"পুটিবিলা"
    },
    {
       "_id":3926,
       "upazila_code":53,
       "union_code":22,
       "union_name":"Dhum",
       "union_ban_name":"ধুম"
    },
    {
       "_id":3928,
       "upazila_code":53,
       "union_code":27,
       "union_name":"Haitkandi",
       "union_ban_name":"হাইকান্দি"
    },
    {
       "_id":3929,
       "upazila_code":53,
       "union_code":28,
       "union_name":"Hinguli",
       "union_ban_name":"হিনগুলি."
    },
    {
       "_id":3930,
       "upazila_code":53,
       "union_code":29,
       "union_name":"Ichhakhali",
       "union_ban_name":"ইছখালী"
    },
    {
       "_id":3931,
       "upazila_code":53,
       "union_code":35,
       "union_name":"Karerhat",
       "union_ban_name":"করেরহাট"
    },
    {
       "_id":3932,
       "upazila_code":53,
       "union_code":41,
       "union_name":"Katachhara",
       "union_ban_name":"কাতচারা"
    },
    {
       "_id":3933,
       "upazila_code":53,
       "union_code":47,
       "union_name":"Khaiyachhara",
       "union_ban_name":"খাইয়ছড়া"
    },
    {
       "_id":3934,
       "upazila_code":53,
       "union_code":53,
       "union_name":"Mayani",
       "union_ban_name":"মায়ানি"
    },
    {
       "_id":3935,
       "upazila_code":53,
       "union_code":59,
       "union_name":"Mirsharai",
       "union_ban_name":"মিরসরাই"
    },
    {
       "_id":3936,
       "upazila_code":53,
       "union_code":65,
       "union_name":"Mithanala",
       "union_ban_name":"মিঠানালা"
    },
    {
       "_id":3937,
       "upazila_code":53,
       "union_code":71,
       "union_name":"Maghadia",
       "union_ban_name":"মাঘাসিয়া"
    },
    {
       "_id":3938,
       "upazila_code":53,
       "union_code":77,
       "union_name":"Osmanpur",
       "union_ban_name":"ওসমানপুর"
    },
    {
       "_id":3939,
       "upazila_code":53,
       "union_code":83,
       "union_name":"Saherkhali",
       "union_ban_name":"সাহেরখালি"
    },
    {
       "_id":3940,
       "upazila_code":53,
       "union_code":89,
       "union_name":"Wahedpur",
       "union_ban_name":"ওয়াহেদপুর"
    },
    {
       "_id":3941,
       "upazila_code":53,
       "union_code":95,
       "union_name":"Zorwarganj",
       "union_ban_name":"জোরওয়ারগঞ্জ."
    },
    {
       "_id":3942,
       "upazila_code":61,
       "union_code":12,
       "union_name":"Asia",
       "union_ban_name":"এশিয়া"
    },
    {
       "_id":3943,
       "upazila_code":61,
       "union_code":15,
       "union_name":"Baralia",
       "union_ban_name":"বারালিয়া"
    },
    {
       "_id":3944,
       "upazila_code":61,
       "union_code":18,
       "union_name":"Bara Uthan",
       "union_ban_name":"বার উথান"
    },
    {
       "_id":3945,
       "upazila_code":61,
       "union_code":19,
       "union_name":"Bhatikhain",
       "union_ban_name":"ভাটিখেন"
    },
    {
       "_id":3946,
       "upazila_code":61,
       "union_code":21,
       "union_name":"Chanhara",
       "union_ban_name":"চাঁখা"
    },
    {
       "_id":3947,
       "upazila_code":61,
       "union_code":24,
       "union_name":"Char Lakshya",
       "union_ban_name":"চর লক্ষ্মিয়া"
    },
    {
       "_id":3948,
       "upazila_code":61,
       "union_code":27,
       "union_name":"Char Patharghata",
       "union_ban_name":"চর পাথরঘাট"
    },
    {
       "_id":3949,
       "upazila_code":61,
       "union_code":30,
       "union_name":"Dhalghat",
       "union_ban_name":"ধলঘাট"
    },
    {
       "_id":3950,
       "upazila_code":61,
       "union_code":39,
       "union_name":"Habilas Dwip",
       "union_ban_name":"হাবিলাস ড"
    },
    {
       "_id":3951,
       "upazila_code":61,
       "union_code":42,
       "union_name":"Haidgaon",
       "union_ban_name":"হাইডগাঁও"
    },
    {
       "_id":3952,
       "upazila_code":61,
       "union_code":52,
       "union_name":"Janglukhain",
       "union_ban_name":"জাংলুখাইন"
    },
    {
       "_id":3953,
       "upazila_code":61,
       "union_code":55,
       "union_name":"Juldha",
       "union_ban_name":"জুল্ডা"
    },
    {
       "_id":3954,
       "upazila_code":61,
       "union_code":58,
       "union_name":"Kachuai",
       "union_ban_name":"কচুয়াই"
    },
    {
       "_id":3955,
       "upazila_code":61,
       "union_code":64,
       "union_name":"Kasiais",
       "union_ban_name":"ক্যাসিয়া"
    },
    {
       "_id":3956,
       "upazila_code":61,
       "union_code":67,
       "union_name":"Kelishahar",
       "union_ban_name":"কেলিশহর"
    },
    {
       "_id":3957,
       "upazila_code":61,
       "union_code":70,
       "union_name":"Kharana",
       "union_ban_name":"খারানা"
    },
    {
       "_id":3958,
       "upazila_code":61,
       "union_code":73,
       "union_name":"Kolagaon",
       "union_ban_name":"কোলগাঁও"
    },
    {
       "_id":3959,
       "upazila_code":61,
       "union_code":76,
       "union_name":"Kusumpura",
       "union_ban_name":"কুশুমুরা"
    },
    {
       "_id":3960,
       "upazila_code":61,
       "union_code":85,
       "union_name":"Sikalbaha",
       "union_ban_name":"সিকালবায়া"
    },
    {
       "_id":3961,
       "upazila_code":61,
       "union_code":88,
       "union_name":"Sobhandandi",
       "union_ban_name":"সোবন্দান্দি"
    },
    {
       "_id":3962,
       "upazila_code":61,
       "union_code":91,
       "union_name":"Dakhin D.Bhurshi",
       "union_ban_name":"দখিন ডি.বেহুরশি"
    },
    {
       "_id":3963,
       "upazila_code":61,
       "union_code":94,
       "union_name":"Jiri",
       "union_ban_name":"জিরি"
    },
    {
       "_id":3965,
       "upazila_code":70,
       "union_code":17,
       "union_name":"Chandraghona Kadamtali",
       "union_ban_name":"চন্দ্রঘোনা কাদামতলী"
    },
    {
       "_id":3966,
       "upazila_code":70,
       "union_code":21,
       "union_name":"Rajanagar",
       "union_ban_name":"রাজগরগর"
    },
    {
       "_id":3969,
       "upazila_code":70,
       "union_code":30,
       "union_name":"Kodala",
       "union_ban_name":"কোদালা"
    },
    {
       "_id":3970,
       "upazila_code":70,
       "union_code":32,
       "union_name":"Lalanagar",
       "union_ban_name":"লালনগর"
    },
    {
       "_id":3971,
       "upazila_code":70,
       "union_code":34,
       "union_name":"Mariamnagar",
       "union_ban_name":"মারিয়ামনগর"
    },
    {
       "_id":3973,
       "upazila_code":70,
       "union_code":51,
       "union_name":"Parua",
       "union_ban_name":"পারুয়া"
    },
    {
       "_id":3974,
       "upazila_code":70,
       "union_code":60,
       "union_name":"Pomara",
       "union_ban_name":"পোমারা"
    },
    {
       "_id":3975,
       "upazila_code":70,
       "union_code":69,
       "union_name":"Dakshin Rajanagar",
       "union_ban_name":"দক্ষিণ রাজগর"
    },
    {
       "_id":3976,
       "upazila_code":70,
       "union_code":77,
       "union_name":"Rangunia",
       "union_ban_name":"রাঙ্গুনিয়া"
    },
    {
       "_id":3977,
       "upazila_code":70,
       "union_code":86,
       "union_name":"Sarapbhata",
       "union_ban_name":"সরাপভাটা"
    },
    {
       "_id":3978,
       "upazila_code":70,
       "union_code":94,
       "union_name":"Silok",
       "union_ban_name":"সিলোক"
    },
    {
       "_id":3979,
       "upazila_code":74,
       "union_code":11,
       "union_name":"Bagoan",
       "union_ban_name":"বাগোন"
    },
    {
       "_id":3980,
       "upazila_code":74,
       "union_code":12,
       "union_name":"Binajuri",
       "union_ban_name":"বিন্যাজুরি"
    },
    {
       "_id":3981,
       "upazila_code":74,
       "union_code":18,
       "union_name":"Chikdair",
       "union_ban_name":"চিকডার"
    },
    {
       "_id":3982,
       "upazila_code":74,
       "union_code":25,
       "union_name":"Dabua",
       "union_ban_name":"ডাবুয়া"
    },
    {
       "_id":3983,
       "upazila_code":74,
       "union_code":31,
       "union_name":"Purba Guzara",
       "union_ban_name":"পূর্বা গুজরা"
    },
    {
       "_id":3984,
       "upazila_code":74,
       "union_code":37,
       "union_name":"Gahira",
       "union_ban_name":"গহিরা"
    },
    {
       "_id":3985,
       "upazila_code":74,
       "union_code":44,
       "union_name":"Haladia",
       "union_ban_name":"হালাদিয়া"
    },
    {
       "_id":3986,
       "upazila_code":74,
       "union_code":50,
       "union_name":"Kadalpur",
       "union_ban_name":"কাদালপুর"
    },
    {
       "_id":3987,
       "upazila_code":74,
       "union_code":56,
       "union_name":"Noajispur",
       "union_ban_name":"নাজিসপুর"
    },
    {
       "_id":3988,
       "upazila_code":74,
       "union_code":63,
       "union_name":"Noa Para",
       "union_ban_name":"নোয়া প্যারা"
    },
    {
       "_id":3989,
       "upazila_code":74,
       "union_code":69,
       "union_name":"Pahartali",
       "union_ban_name":"পাহাড়তলী"
    },
    {
       "_id":3990,
       "upazila_code":74,
       "union_code":75,
       "union_name":"Raozan",
       "union_ban_name":"রাওজান।"
    },
    {
       "_id":3991,
       "upazila_code":74,
       "union_code":88,
       "union_name":"Urkirchar",
       "union_ban_name":"উরকিরচর"
    },
    {
       "_id":3992,
       "upazila_code":74,
       "union_code":94,
       "union_name":"Paschim Guzara",
       "union_ban_name":"পশ্চিম গুজারা"
    },
    {
       "_id":3993,
       "upazila_code":78,
       "union_code":11,
       "union_name":"Amanullah",
       "union_ban_name":"আমানউল্লাহ"
    },
    {
       "_id":3994,
       "upazila_code":78,
       "union_code":13,
       "union_name":"Azimpur",
       "union_ban_name":"আজিমপুর"
    },
    {
       "_id":3995,
       "upazila_code":78,
       "union_code":20,
       "union_name":"Bauria",
       "union_ban_name":"বাউরিয়া"
    },
    {
       "_id":3996,
       "upazila_code":78,
       "union_code":25,
       "union_name":"Digghapar",
       "union_ban_name":"দিঘাপার"
    },
    {
       "_id":3998,
       "upazila_code":78,
       "union_code":35,
       "union_name":"Haramia",
       "union_ban_name":"হারামিয়া"
    },
    {
       "_id":3999,
       "upazila_code":78,
       "union_code":40,
       "union_name":"Harispur",
       "union_ban_name":"হরিসপুর"
    },
    {
       "_id":4000,
       "upazila_code":78,
       "union_code":55,
       "union_name":"Kalapania",
       "union_ban_name":"কালাপানিয়া"
    },
    {
       "_id":4001,
       "upazila_code":78,
       "union_code":65,
       "union_name":"Magdhara",
       "union_ban_name":"মগধারা"
    },
    {
       "_id":4002,
       "upazila_code":78,
       "union_code":70,
       "union_name":"Maitbhanga",
       "union_ban_name":"মৈতভাঙ্গা"
    },
    {
       "_id":4003,
       "upazila_code":78,
       "union_code":75,
       "union_name":"Musapur",
       "union_ban_name":"মুসাপুর"
    },
    {
       "_id":4006,
       "upazila_code":78,
       "union_code":92,
       "union_name":"Sarikait",
       "union_ban_name":"সারিকাইট"
    },
    {
       "_id":4007,
       "upazila_code":78,
       "union_code":96,
       "union_name":"Urirchar",
       "union_ban_name":"উরিরচর"
    },
    {
       "_id":4008,
       "upazila_code":82,
       "union_code":12,
       "union_name":"Amilais",
       "union_ban_name":"আমিলা"
    },
    {
       "_id":4009,
       "upazila_code":82,
       "union_code":14,
       "union_name":"Eochia",
       "union_ban_name":"ইওচিয়া"
    },
    {
       "_id":4010,
       "upazila_code":82,
       "union_code":21,
       "union_name":"Bazalia",
       "union_ban_name":"বাজালিয়া"
    },
    {
       "_id":4011,
       "upazila_code":82,
       "union_code":29,
       "union_name":"Charati",
       "union_ban_name":"চরতি"
    },
    {
       "_id":4012,
       "upazila_code":82,
       "union_code":36,
       "union_name":"Dhemsa",
       "union_ban_name":"ধেমসা"
    },
    {
       "_id":4014,
       "upazila_code":82,
       "union_code":47,
       "union_name":"Kaliais",
       "union_ban_name":"কালিয়াইশ"
    },
    {
       "_id":4015,
       "upazila_code":82,
       "union_code":51,
       "union_name":"Kanchana",
       "union_ban_name":"কাঞ্চানা"
    },
    {
       "_id":4016,
       "upazila_code":82,
       "union_code":54,
       "union_name":"Keochia",
       "union_ban_name":"কেঁওচিয়া"
    },
    {
       "_id":4017,
       "upazila_code":82,
       "union_code":58,
       "union_name":"Khagaria",
       "union_ban_name":"খাগরিয়া"
    },
    {
       "_id":4018,
       "upazila_code":82,
       "union_code":65,
       "union_name":"Madarsa",
       "union_ban_name":"মাদারস"
    },
    {
       "_id":4020,
       "upazila_code":82,
       "union_code":76,
       "union_name":"Puranagar",
       "union_ban_name":"পুরানগর"
    },
    {
       "_id":4021,
       "upazila_code":82,
       "union_code":83,
       "union_name":"Sadaha",
       "union_ban_name":"সাদাহা"
    },
    {
       "_id":4022,
       "upazila_code":82,
       "union_code":87,
       "union_name":"Satkania",
       "union_ban_name":"সাতকানিয়া"
    },
    {
       "_id":4023,
       "upazila_code":82,
       "union_code":91,
       "union_name":"Sonakania",
       "union_ban_name":"সোনাকানিয়া"
    },
    {
       "_id":4024,
       "upazila_code":82,
       "union_code":94,
       "union_name":"Paschim Dhemsa",
       "union_ban_name":"পশ্চিম ধেমসা"
    },
    {
       "_id":4025,
       "upazila_code":86,
       "union_code":16,
       "union_name":"Banshbaria",
       "union_ban_name":"বাঁশবাড়িয়া"
    },
    {
       "_id":4026,
       "upazila_code":86,
       "union_code":19,
       "union_name":"Barabkunda",
       "union_ban_name":"বারবকুন্ডা"
    },
    {
       "_id":4027,
       "upazila_code":86,
       "union_code":28,
       "union_name":"Bariadyala",
       "union_ban_name":"বারিয়াদিয়ালা"
    },
    {
       "_id":4028,
       "upazila_code":86,
       "union_code":38,
       "union_name":"Bhatiari",
       "union_ban_name":"ভাটিয়ারি"
    },
    {
       "_id":4029,
       "upazila_code":86,
       "union_code":47,
       "union_name":"Kumira",
       "union_ban_name":"কুমিরা"
    },
    {
       "_id":4030,
       "upazila_code":86,
       "union_code":57,
       "union_name":"Muradpur",
       "union_ban_name":"মুরাদপুর"
    },
    {
       "_id":4031,
       "upazila_code":86,
       "union_code":66,
       "union_name":"Salimpur",
       "union_ban_name":"সালিমপুর"
    },
    {
       "_id":4032,
       "upazila_code":86,
       "union_code":85,
       "union_name":"Sonaichhari",
       "union_ban_name":"সোনাইছড়ি"
    },
    {
       "_id":4034,
       "upazila_code":7,
       "union_code":13,
       "union_name":"Belgachhi",
       "union_ban_name":"বেলগাছি"
    },
    {
       "_id":4035,
       "upazila_code":7,
       "union_code":15,
       "union_name":"Baradi",
       "union_ban_name":"বারাদি"
    },
    {
       "_id":4037,
       "upazila_code":7,
       "union_code":27,
       "union_name":"Chithla",
       "union_ban_name":"চিতলা"
    },
    {
       "_id":4038,
       "upazila_code":7,
       "union_code":31,
       "union_name":"Dauki",
       "union_ban_name":"দাউকি"
    },
    {
       "_id":4040,
       "upazila_code":7,
       "union_code":47,
       "union_name":"Hardi",
       "union_ban_name":"হার্ডি"
    },
    {
       "_id":4041,
       "upazila_code":7,
       "union_code":51,
       "union_name":"Kalidashpur",
       "union_ban_name":"কালিদাসপুর"
    },
    {
       "_id":4042,
       "upazila_code":7,
       "union_code":55,
       "union_name":"Jamjami",
       "union_ban_name":"জামজামি"
    },
    {
       "_id":4043,
       "upazila_code":7,
       "union_code":63,
       "union_name":"Jehala",
       "union_ban_name":"জেহালা."
    },
    {
       "_id":4044,
       "upazila_code":7,
       "union_code":71,
       "union_name":"Khadimpur",
       "union_ban_name":"খাদিমপুর"
    },
    {
       "_id":4045,
       "upazila_code":7,
       "union_code":79,
       "union_name":"Khaskara",
       "union_ban_name":"খাসকাটা"
    },
    {
       "_id":4046,
       "upazila_code":7,
       "union_code":87,
       "union_name":"Kumari",
       "union_ban_name":"কুমারী"
    },
    {
       "_id":4047,
       "upazila_code":7,
       "union_code":94,
       "union_name":"Nagdaha",
       "union_ban_name":"নাগদাহা"
    },
    {
       "_id":4048,
       "upazila_code":23,
       "union_code":11,
       "union_name":"Alokdia",
       "union_ban_name":"আলকদিয়া"
    },
    {
       "_id":4049,
       "upazila_code":23,
       "union_code":23,
       "union_name":"Begampur",
       "union_ban_name":"বেগমপুর"
    },
    {
       "_id":4051,
       "upazila_code":23,
       "union_code":59,
       "union_name":"Mominpur",
       "union_ban_name":"মমিনপুর"
    },
    {
       "_id":4052,
       "upazila_code":23,
       "union_code":62,
       "union_name":"Padmabila",
       "union_ban_name":"পদ্মবিলা"
    },
    {
       "_id":4053,
       "upazila_code":23,
       "union_code":71,
       "union_name":"Shankar Chandra",
       "union_ban_name":"শংকর চন্দ্র"
    },
    {
       "_id":4054,
       "upazila_code":23,
       "union_code":83,
       "union_name":"Titudaha",
       "union_ban_name":"তিতুদহ"
    },
    {
       "_id":4055,
       "upazila_code":31,
       "union_code":11,
       "union_name":"Damurhuda",
       "union_ban_name":"দামুরহুদা"
    },
    {
       "_id":4056,
       "upazila_code":31,
       "union_code":23,
       "union_name":"Perkrishnapur Madna",
       "union_ban_name":"পারকৃষ্ণপুর মাদনা"
    },
    {
       "_id":4057,
       "upazila_code":31,
       "union_code":35,
       "union_name":"Howli",
       "union_ban_name":"হাওলি"
    },
    {
       "_id":4058,
       "upazila_code":31,
       "union_code":47,
       "union_name":"Juranpur",
       "union_ban_name":"জুরানপুর"
    },
    {
       "_id":4059,
       "upazila_code":31,
       "union_code":59,
       "union_name":"Kapasadanga",
       "union_ban_name":"কাপাসদঙ্গা"
    },
    {
       "_id":4060,
       "upazila_code":31,
       "union_code":71,
       "union_name":"Kuralgachhi",
       "union_ban_name":"কুড়ালগাছি"
    },
    {
       "_id":4061,
       "upazila_code":31,
       "union_code":83,
       "union_name":"Natipota",
       "union_ban_name":"নাটিপোতা"
    },
    {
       "_id":4062,
       "upazila_code":55,
       "union_code":19,
       "union_name":"Andulbaria",
       "union_ban_name":"আন্দুলবাড়িয়া"
    },
    {
       "_id":4063,
       "upazila_code":55,
       "union_code":38,
       "union_name":"Banka",
       "union_ban_name":"ব্যাংকা"
    },
    {
       "_id":4064,
       "upazila_code":55,
       "union_code":57,
       "union_name":"Simanta",
       "union_ban_name":"সিমান্তা"
    },
    {
       "_id":4065,
       "upazila_code":55,
       "union_code":76,
       "union_name":"Uthali",
       "union_ban_name":"উথালি"
    },
    {
       "_id":4066,
       "upazila_code":9,
       "union_code":11,
       "union_name":"Adda",
       "union_ban_name":"আড্ডা"
    },
    {
       "_id":4067,
       "upazila_code":9,
       "union_code":12,
       "union_name":"Adra",
       "union_ban_name":"আদ্রা"
    },
    {
       "_id":4068,
       "upazila_code":9,
       "union_code":18,
       "union_name":"Aganagar",
       "union_ban_name":"আগানগর"
    },
    {
       "_id":4070,
       "upazila_code":9,
       "union_code":37,
       "union_name":"Deora",
       "union_ban_name":"দেওরা"
    },
    {
       "_id":4071,
       "upazila_code":9,
       "union_code":44,
       "union_name":"Galimpur",
       "union_ban_name":"গালিমপুর"
    },
    {
       "_id":4072,
       "upazila_code":9,
       "union_code":50,
       "union_name":"Chitadda",
       "union_ban_name":"চিতাড্ডা"
    },
    {
       "_id":4073,
       "upazila_code":9,
       "union_code":56,
       "union_name":"Jalam",
       "union_ban_name":"জালাম"
    },
    {
       "_id":4074,
       "upazila_code":9,
       "union_code":63,
       "union_name":"Uttar Khosbas",
       "union_ban_name":"উত্তর খোসবাস"
    },
    {
       "_id":4075,
       "upazila_code":9,
       "union_code":69,
       "union_name":"Dakshin Khosbas",
       "union_ban_name":"দক্ষিণ খোসবাস."
    },
    {
       "_id":4076,
       "upazila_code":9,
       "union_code":75,
       "union_name":"Uttar Payalgachha",
       "union_ban_name":"উত্তর পায়েলগাছা"
    },
    {
       "_id":4077,
       "upazila_code":9,
       "union_code":82,
       "union_name":"Lakshmipur",
       "union_ban_name":"লক্ষ্মীপুর"
    },
    {
       "_id":4078,
       "upazila_code":9,
       "union_code":88,
       "union_name":"Uttar Shilmuri",
       "union_ban_name":"উত্তর শিলমুরি"
    },
    {
       "_id":4079,
       "upazila_code":9,
       "union_code":94,
       "union_name":"Dakshin Shilmuri",
       "union_ban_name":"দক্ষিণ শীলমুরি"
    },
    {
       "_id":4080,
       "upazila_code":15,
       "union_code":18,
       "union_name":"Brahman Para",
       "union_ban_name":"ব্রাহ্মণ প্যারা"
    },
    {
       "_id":4081,
       "upazila_code":15,
       "union_code":30,
       "union_name":"Dulalpur",
       "union_ban_name":"দুলালপুর"
    },
    {
       "_id":4082,
       "upazila_code":15,
       "union_code":44,
       "union_name":"Chandla",
       "union_ban_name":"চাঁদলা"
    },
    {
       "_id":4083,
       "upazila_code":15,
       "union_code":50,
       "union_name":"Sidlai",
       "union_ban_name":"সিডলি"
    },
    {
       "_id":4084,
       "upazila_code":15,
       "union_code":56,
       "union_name":"Madhabpur",
       "union_ban_name":"মাধবপুর"
    },
    {
       "_id":4085,
       "upazila_code":15,
       "union_code":62,
       "union_name":"Malapara",
       "union_ban_name":"মালপাড়া"
    },
    {
       "_id":4086,
       "upazila_code":15,
       "union_code":82,
       "union_name":"Sahebabad",
       "union_ban_name":"সাহেবাবাদ"
    },
    {
       "_id":4087,
       "upazila_code":15,
       "union_code":88,
       "union_name":"Shashidal",
       "union_ban_name":"শাসিদাল"
    },
    {
       "_id":4088,
       "upazila_code":18,
       "union_code":11,
       "union_name":"Baksimail",
       "union_ban_name":"বাকসিমাইল"
    },
    {
       "_id":4089,
       "upazila_code":18,
       "union_code":12,
       "union_name":"Bharella",
       "union_ban_name":"ভেরেলা"
    },
    {
       "_id":4090,
       "upazila_code":18,
       "union_code":31,
       "union_name":"Burichang",
       "union_ban_name":"বুরিচ্যাং"
    },
    {
       "_id":4091,
       "upazila_code":18,
       "union_code":63,
       "union_name":"Mainamati",
       "union_ban_name":"ময়নামতী"
    },
    {
       "_id":4092,
       "upazila_code":18,
       "union_code":69,
       "union_name":"Mokam",
       "union_ban_name":"মোকাম"
    },
    {
       "_id":4093,
       "upazila_code":18,
       "union_code":72,
       "union_name":"Pir Jatrapur",
       "union_ban_name":"পীর যাত্রাপুর"
    },
    {
       "_id":4095,
       "upazila_code":18,
       "union_code":94,
       "union_name":"Sholanal",
       "union_ban_name":"শোলানাল"
    },
    {
       "_id":4096,
       "upazila_code":27,
       "union_code":13,
       "union_name":"Keran Khal",
       "union_ban_name":"কেরান খাল"
    },
    {
       "_id":4097,
       "upazila_code":27,
       "union_code":15,
       "union_name":"Barera",
       "union_ban_name":"বারেরা"
    },
    {
       "_id":4098,
       "upazila_code":27,
       "union_code":20,
       "union_name":"Mahichal",
       "union_ban_name":"মহিচাল"
    },
    {
       "_id":4099,
       "upazila_code":27,
       "union_code":23,
       "union_name":"Bara Karai",
       "union_ban_name":"বার কারাই"
    },
    {
       "_id":4100,
       "upazila_code":27,
       "union_code":31,
       "union_name":"Joyag",
       "union_ban_name":"জয়গ"
    },
    {
       "_id":4101,
       "upazila_code":27,
       "union_code":39,
       "union_name":"Barkait",
       "union_ban_name":"বরকত"
    },
    {
       "_id":4102,
       "upazila_code":27,
       "union_code":47,
       "union_name":"Atbar Pur",
       "union_ban_name":"আটবার পুর"
    },
    {
       "_id":4103,
       "upazila_code":27,
       "union_code":55,
       "union_name":"Gallai",
       "union_ban_name":"গাল্লাই।"
    },
    {
       "_id":4104,
       "upazila_code":27,
       "union_code":63,
       "union_name":"Dollai Nowabab Pur",
       "union_ban_name":"ডলাইইবব পূজা"
    },
    {
       "_id":4105,
       "upazila_code":27,
       "union_code":71,
       "union_name":"Madhya",
       "union_ban_name":"মধিয়া"
    },
    {
       "_id":4106,
       "upazila_code":27,
       "union_code":79,
       "union_name":"Maijkhar",
       "union_ban_name":"মেজখর"
    },
    {
       "_id":4107,
       "upazila_code":27,
       "union_code":87,
       "union_name":"Batakash",
       "union_ban_name":"বাটাকাশ"
    },
    {
       "_id":4108,
       "upazila_code":27,
       "union_code":94,
       "union_name":"Suhilpur",
       "union_ban_name":"সুহিলপুর"
    },
    {
       "_id":4109,
       "upazila_code":31,
       "union_code":10,
       "union_name":"Alkara",
       "union_ban_name":"আলকারা"
    },
    {
       "_id":4110,
       "upazila_code":31,
       "union_code":15,
       "union_name":"Batisha",
       "union_ban_name":"বাতিশা"
    },
    {
       "_id":4111,
       "upazila_code":31,
       "union_code":19,
       "union_name":"Cheora",
       "union_ban_name":"চিওরা"
    },
    {
       "_id":4112,
       "upazila_code":31,
       "union_code":38,
       "union_name":"Gholpasha",
       "union_ban_name":"ঘোলাশশা"
    },
    {
       "_id":4113,
       "upazila_code":31,
       "union_code":42,
       "union_name":"Gunabati",
       "union_ban_name":"গুনবতী"
    },
    {
       "_id":4114,
       "upazila_code":31,
       "union_code":47,
       "union_name":"Jagannath Dighi",
       "union_ban_name":"জগন্নাথ দিঘি"
    },
    {
       "_id":4115,
       "upazila_code":31,
       "union_code":52,
       "union_name":"Kalikapur",
       "union_ban_name":"কালিকাপুর"
    },
    {
       "_id":4116,
       "upazila_code":31,
       "union_code":57,
       "union_name":"Kankapait",
       "union_ban_name":"কঙ্কাপাইট"
    },
    {
       "_id":4117,
       "upazila_code":31,
       "union_code":61,
       "union_name":"Kashinagar",
       "union_ban_name":"কাশিনগর"
    },
    {
       "_id":4118,
       "upazila_code":31,
       "union_code":71,
       "union_name":"Munshirhat",
       "union_ban_name":"মুন্সিরহাট"
    },
    {
       "_id":4119,
       "upazila_code":31,
       "union_code":85,
       "union_name":"Shubhapur",
       "union_ban_name":"শুভাপুর"
    },
    {
       "_id":4120,
       "upazila_code":31,
       "union_code":90,
       "union_name":"Sreepur",
       "union_ban_name":"শ্রীপুর"
    },
    {
       "_id":4121,
       "upazila_code":31,
       "union_code":95,
       "union_name":"Ujirpur",
       "union_ban_name":"উজিরপুর"
    },
    {
       "_id":4122,
       "upazila_code":33,
       "union_code":12,
       "union_name":"Baghmara",
       "union_ban_name":"বাগমারা"
    },
    {
       "_id":4123,
       "upazila_code":33,
       "union_code":24,
       "union_name":"Bara Para",
       "union_ban_name":"বার প্যারা"
    },
    {
       "_id":4124,
       "upazila_code":33,
       "union_code":28,
       "union_name":"Belghar",
       "union_ban_name":"বেলঘার"
    },
    {
       "_id":4125,
       "upazila_code":33,
       "union_code":31,
       "union_name":"Bholain (Dakshin)",
       "union_ban_name":"ভোলাইন (দক্ষিণ)"
    },
    {
       "_id":4126,
       "upazila_code":33,
       "union_code":33,
       "union_name":"Bholain (Uttar)",
       "union_ban_name":"ভোলাইন (উত্তর)"
    },
    {
       "_id":4127,
       "upazila_code":33,
       "union_code":35,
       "union_name":"Bijoypur",
       "union_ban_name":"বিজয়পুর"
    },
    {
       "_id":4128,
       "upazila_code":33,
       "union_code":39,
       "union_name":"Chouara",
       "union_ban_name":"চৌরা"
    },
    {
       "_id":4129,
       "upazila_code":33,
       "union_code":58,
       "union_name":"Galiara",
       "union_ban_name":"গালিয়া"
    },
    {
       "_id":4130,
       "upazila_code":33,
       "union_code":73,
       "union_name":"Purba Jorekaran",
       "union_ban_name":"পূর্বা জোরকারন"
    },
    {
       "_id":4131,
       "upazila_code":33,
       "union_code":80,
       "union_name":"Paschim Jorekaran",
       "union_ban_name":"পশ্চিম জোরেকরণ"
    },
    {
       "_id":4132,
       "upazila_code":33,
       "union_code":83,
       "union_name":"Perul (Dakshin)",
       "union_ban_name":"পেরুল (দক্ষিণ)"
    },
    {
       "_id":4133,
       "upazila_code":33,
       "union_code":87,
       "union_name":"Perul (Uttar)",
       "union_ban_name":"পেরুল (উত্তর)"
    },
    {
       "_id":4134,
       "upazila_code":36,
       "union_code":21,
       "union_name":"Daudkandi Uttar",
       "union_ban_name":"দাউদকান্দি উত্তর"
    },
    {
       "_id":4135,
       "upazila_code":36,
       "union_code":25,
       "union_name":"Biteshwar",
       "union_ban_name":"বিটেশ্বর"
    },
    {
       "_id":4136,
       "upazila_code":36,
       "union_code":30,
       "union_name":"Elliotganj Uttar",
       "union_ban_name":"ইলিয়টগঞ্জ উত্তর"
    },
    {
       "_id":4137,
       "upazila_code":36,
       "union_code":34,
       "union_name":"Elliotganj Dakshin",
       "union_ban_name":"এলিয়টগঞ্জ দক্ষিণ"
    },
    {
       "_id":4138,
       "upazila_code":36,
       "union_code":38,
       "union_name":"Goalmari",
       "union_ban_name":"গৌমারী"
    },
    {
       "_id":4139,
       "upazila_code":36,
       "union_code":47,
       "union_name":"Jinglatali",
       "union_ban_name":"জিংলাতালি"
    },
    {
       "_id":4140,
       "upazila_code":36,
       "union_code":51,
       "union_name":"Gauripur",
       "union_ban_name":"গৌরীপুর"
    },
    {
       "_id":4141,
       "upazila_code":36,
       "union_code":64,
       "union_name":"Maruka",
       "union_ban_name":"মারুকা"
    },
    {
       "_id":4142,
       "upazila_code":36,
       "union_code":73,
       "union_name":"Mohammadpur Paschim",
       "union_ban_name":"মোহাম্মদপুর পাসচিম"
    },
    {
       "_id":4143,
       "upazila_code":36,
       "union_code":84,
       "union_name":"Mohammadpur Purba",
       "union_ban_name":"মোহাম্মদপুর পূর্বা"
    },
    {
       "_id":4145,
       "upazila_code":36,
       "union_code":86,
       "union_name":"Panchgachhia Paschim",
       "union_ban_name":"পঞ্চগাছিয়া পাসচিম"
    },
    {
       "_id":4146,
       "upazila_code":36,
       "union_code":90,
       "union_name":"Barapara",
       "union_ban_name":"বারাপাড়া"
    },
    {
       "_id":4147,
       "upazila_code":36,
       "union_code":94,
       "union_name":"Sundalpur",
       "union_ban_name":"সুন্দরলপুর"
    },
    {
       "_id":4148,
       "upazila_code":40,
       "union_code":10,
       "union_name":"Bara Shalghar",
       "union_ban_name":"বার শালঘর"
    },
    {
       "_id":4149,
       "upazila_code":40,
       "union_code":11,
       "union_name":"Isab Pur",
       "union_ban_name":"ইসাব পুর"
    },
    {
       "_id":4150,
       "upazila_code":40,
       "union_code":17,
       "union_name":"Mohan Pur",
       "union_ban_name":"মোহন পুর"
    },
    {
       "_id":4151,
       "upazila_code":40,
       "union_code":23,
       "union_name":"Barkamta",
       "union_ban_name":"বরকতমটা"
    },
    {
       "_id":4152,
       "upazila_code":40,
       "union_code":35,
       "union_name":"Dhamti",
       "union_ban_name":"ধমতি"
    },
    {
       "_id":4154,
       "upazila_code":40,
       "union_code":47,
       "union_name":"Fatehabad",
       "union_ban_name":"ফাতেহাবাদ"
    },
    {
       "_id":4155,
       "upazila_code":40,
       "union_code":53,
       "union_name":"Uttar Gunaighar",
       "union_ban_name":"উত্তর গুনঘর"
    },
    {
       "_id":4156,
       "upazila_code":40,
       "union_code":59,
       "union_name":"Dakshin Gunaighar",
       "union_ban_name":"দক্ষিণ গুনঘর"
    },
    {
       "_id":4157,
       "upazila_code":40,
       "union_code":65,
       "union_name":"Jafarganj",
       "union_ban_name":"জাফরগঞ্জ"
    },
    {
       "_id":4158,
       "upazila_code":40,
       "union_code":71,
       "union_name":"Elahabad",
       "union_ban_name":"এলাহাবাদ"
    },
    {
       "_id":4159,
       "upazila_code":40,
       "union_code":77,
       "union_name":"Rajamehar",
       "union_ban_name":"রাজমহর"
    },
    {
       "_id":4160,
       "upazila_code":40,
       "union_code":83,
       "union_name":"Bhani",
       "union_ban_name":"ভনি"
    },
    {
       "_id":4161,
       "upazila_code":40,
       "union_code":89,
       "union_name":"Rasulpur",
       "union_ban_name":"রসুলপুর"
    },
    {
       "_id":4162,
       "upazila_code":40,
       "union_code":95,
       "union_name":"Subil",
       "union_ban_name":"সুবিল"
    },
    {
       "_id":4163,
       "upazila_code":54,
       "union_code":15,
       "union_name":"Asadpur",
       "union_ban_name":"আসাদপুর"
    },
    {
       "_id":4164,
       "upazila_code":54,
       "union_code":20,
       "union_name":"Bhasania",
       "union_ban_name":"ভাসানিয়া"
    },
    {
       "_id":4165,
       "upazila_code":54,
       "union_code":22,
       "union_name":"Dulal Pur",
       "union_ban_name":"দুলাল পুর"
    },
    {
       "_id":4166,
       "upazila_code":54,
       "union_code":25,
       "union_name":"Joypur",
       "union_ban_name":"জয়পুর"
    },
    {
       "_id":4167,
       "upazila_code":54,
       "union_code":28,
       "union_name":"Chander Char",
       "union_ban_name":"চন্দ্র চর"
    },
    {
       "_id":4168,
       "upazila_code":54,
       "union_code":47,
       "union_name":"Ghagutia",
       "union_ban_name":"ঘগুতিয়া"
    },
    {
       "_id":4169,
       "upazila_code":54,
       "union_code":50,
       "union_name":"Garmora",
       "union_ban_name":"গারমোরা"
    },
    {
       "_id":4170,
       "upazila_code":54,
       "union_code":76,
       "union_name":"Mathabanga",
       "union_ban_name":"মাথাবঙ্গা"
    },
    {
       "_id":4171,
       "upazila_code":54,
       "union_code":85,
       "union_name":"Nilakhi",
       "union_ban_name":"নিলখী"
    },
    {
       "_id":4172,
       "upazila_code":67,
       "union_code":22,
       "union_name":"Amratali",
       "union_ban_name":"অমৃতালী"
    },
    {
       "_id":4173,
       "upazila_code":67,
       "union_code":51,
       "union_name":"Dakshin Durgapur",
       "union_ban_name":"দক্ষিণ দুর্গাপুর"
    },
    {
       "_id":4174,
       "upazila_code":67,
       "union_code":65,
       "union_name":"Jagannathpur",
       "union_ban_name":"জগন্নাথপুর"
    },
    {
       "_id":4175,
       "upazila_code":67,
       "union_code":68,
       "union_name":"Kalir Bazar",
       "union_ban_name":"কালির বাজার"
    },
    {
       "_id":4176,
       "upazila_code":67,
       "union_code":90,
       "union_name":"Panchthubi",
       "union_ban_name":"পঞ্চ্থুবি"
    },
    {
       "_id":4177,
       "upazila_code":67,
       "union_code":94,
       "union_name":"Uttar Durgapur",
       "union_ban_name":"উত্তর দুর্গাপুর"
    },
    {
       "_id":4178,
       "upazila_code":72,
       "union_code":17,
       "union_name":"Bakai",
       "union_ban_name":"বাকাই"
    },
    {
       "_id":4180,
       "upazila_code":72,
       "union_code":52,
       "union_name":"Laksam",
       "union_ban_name":"লাকসাম"
    },
    {
       "_id":4181,
       "upazila_code":72,
       "union_code":63,
       "union_name":"Mudafarganj",
       "union_ban_name":"মুদাফারগঞ্জ"
    },
    {
       "_id":4182,
       "upazila_code":72,
       "union_code":77,
       "union_name":"Kandirpar",
       "union_ban_name":"কন্দরপাড়া"
    },
    {
       "_id":4183,
       "upazila_code":72,
       "union_code":84,
       "union_name":"Ajgara",
       "union_ban_name":"আজগারা"
    },
    {
       "_id":4184,
       "upazila_code":72,
       "union_code":87,
       "union_name":"Uttardah",
       "union_ban_name":"উত্তরাহার"
    },
    {
       "_id":4185,
       "upazila_code":74,
       "union_code":13,
       "union_name":"Baishgaon",
       "union_ban_name":"বৈশগাঁও"
    },
    {
       "_id":4186,
       "upazila_code":74,
       "union_code":17,
       "union_name":"Bipulasar",
       "union_ban_name":"বিপুলাসার"
    },
    {
       "_id":4187,
       "upazila_code":74,
       "union_code":31,
       "union_name":"Hasnabad",
       "union_ban_name":"হাসানাবাদ"
    },
    {
       "_id":4188,
       "upazila_code":74,
       "union_code":42,
       "union_name":"Jhalam (Uttar)",
       "union_ban_name":"ঝালম (উত্তর)"
    },
    {
       "_id":4189,
       "upazila_code":74,
       "union_code":45,
       "union_name":"Jhalam (Dakshin)",
       "union_ban_name":"ঝালাম (দক্ষিণে)"
    },
    {
       "_id":4190,
       "upazila_code":74,
       "union_code":53,
       "union_name":"Khila",
       "union_ban_name":"খাদা"
    },
    {
       "_id":4191,
       "upazila_code":74,
       "union_code":59,
       "union_name":"Lakshmanpur",
       "union_ban_name":"লক্ষ্মণপুর"
    },
    {
       "_id":4192,
       "upazila_code":74,
       "union_code":63,
       "union_name":"Maisatua",
       "union_ban_name":"মাইসাতুয়া"
    },
    {
       "_id":4193,
       "upazila_code":74,
       "union_code":70,
       "union_name":"Nather Petua",
       "union_ban_name":"পূর্বের পেটুয়া"
    },
    {
       "_id":4194,
       "upazila_code":74,
       "union_code":82,
       "union_name":"Sarashpur",
       "union_ban_name":"সারশপুর"
    },
    {
       "_id":4195,
       "upazila_code":74,
       "union_code":94,
       "union_name":"Uttar Hawla",
       "union_ban_name":"উত্তর হাওলা"
    },
    {
       "_id":4196,
       "upazila_code":75,
       "union_code":12,
       "union_name":"Barakanda",
       "union_ban_name":"বারকান্দা"
    },
    {
       "_id":4197,
       "upazila_code":75,
       "union_code":19,
       "union_name":"Chandanpur",
       "union_ban_name":"চন্দনপুর"
    },
    {
       "_id":4198,
       "upazila_code":75,
       "union_code":21,
       "union_name":"Chalibhanga",
       "union_ban_name":"চালিভাঙ্গা"
    },
    {
       "_id":4200,
       "upazila_code":75,
       "union_code":53,
       "union_name":"Luter Char",
       "union_ban_name":"লুটার চর"
    },
    {
       "_id":4201,
       "upazila_code":75,
       "union_code":71,
       "union_name":"Maniker Char",
       "union_ban_name":"মানিকার চর"
    },
    {
       "_id":4203,
       "upazila_code":81,
       "union_code":10,
       "union_name":"Akubpur",
       "union_ban_name":"আকুবপুর"
    },
    {
       "_id":4204,
       "upazila_code":81,
       "union_code":11,
       "union_name":"Andikot",
       "union_ban_name":"অ্যান্ডিকট"
    },
    {
       "_id":4205,
       "upazila_code":81,
       "union_code":13,
       "union_name":"Purba Bangara",
       "union_ban_name":"পূর্বা বঙ্গারা"
    },
    {
       "_id":4206,
       "upazila_code":81,
       "union_code":18,
       "union_name":"Paschim Bangara",
       "union_ban_name":"পশ্চিম বাঙ্গারা"
    },
    {
       "_id":4207,
       "upazila_code":81,
       "union_code":22,
       "union_name":"Chapitala",
       "union_ban_name":"চাপিতলা"
    },
    {
       "_id":4208,
       "upazila_code":81,
       "union_code":27,
       "union_name":"Darora",
       "union_ban_name":"ডারোরা"
    },
    {
       "_id":4209,
       "upazila_code":81,
       "union_code":31,
       "union_name":"Chhaliakandi",
       "union_ban_name":"ছালিয়াকান্দি"
    },
    {
       "_id":4210,
       "upazila_code":81,
       "union_code":36,
       "union_name":"Dhamghar",
       "union_ban_name":"ধামঘর"
    },
    {
       "_id":4211,
       "upazila_code":81,
       "union_code":40,
       "union_name":"Jahapur",
       "union_ban_name":"জাহাপুর"
    },
    {
       "_id":4213,
       "upazila_code":81,
       "union_code":49,
       "union_name":"Kamalla",
       "union_ban_name":"কামাল্লা"
    },
    {
       "_id":4214,
       "upazila_code":81,
       "union_code":54,
       "union_name":"Muradnagar",
       "union_ban_name":"মুরাদানগর"
    },
    {
       "_id":4215,
       "upazila_code":81,
       "union_code":58,
       "union_name":"Purba Nabipur",
       "union_ban_name":"পূর্বা নবীপুর"
    },
    {
       "_id":4216,
       "upazila_code":81,
       "union_code":63,
       "union_name":"Paschim Nabipur",
       "union_ban_name":"পশ্চিম নবীপুর"
    },
    {
       "_id":4218,
       "upazila_code":81,
       "union_code":72,
       "union_name":"Babuti Para",
       "union_ban_name":"বাবুতী পারা"
    },
    {
       "_id":4219,
       "upazila_code":81,
       "union_code":76,
       "union_name":"Purba Purbadhair",
       "union_ban_name":"পূর্বা পূর্বাধের"
    },
    {
       "_id":4220,
       "upazila_code":81,
       "union_code":81,
       "union_name":"Paschim Purbadhair",
       "union_ban_name":"পশ্চিম পূর্বাধাইর"
    },
    {
       "_id":4221,
       "upazila_code":81,
       "union_code":85,
       "union_name":"Uttar Ramchandrapur",
       "union_ban_name":"উত্তর রামচন্দ্রপুর"
    },
    {
       "_id":4222,
       "upazila_code":81,
       "union_code":90,
       "union_name":"Dakshin Ramchandrapur",
       "union_ban_name":"দক্ষিণ রামচন্দ্রপুর"
    },
    {
       "_id":4223,
       "upazila_code":81,
       "union_code":91,
       "union_name":"Tanki",
       "union_ban_name":"ট্যাঙ্কি"
    },
    {
       "_id":4224,
       "upazila_code":81,
       "union_code":94,
       "union_name":"Sreekail",
       "union_ban_name":"শ্রীকাইল"
    },
    {
       "_id":4226,
       "upazila_code":87,
       "union_code":17,
       "union_name":"Bangodda",
       "union_ban_name":"বাঙ্গোদা"
    },
    {
       "_id":4227,
       "upazila_code":87,
       "union_code":34,
       "union_name":"Bakshaganj",
       "union_ban_name":"বাকশগঞ্জ"
    },
    {
       "_id":4229,
       "upazila_code":87,
       "union_code":51,
       "union_name":"Daulkhar",
       "union_ban_name":"দৌলখর"
    },
    {
       "_id":4230,
       "upazila_code":87,
       "union_code":57,
       "union_name":"Hesakhal",
       "union_ban_name":"হেসখাল"
    },
    {
       "_id":4231,
       "upazila_code":87,
       "union_code":60,
       "union_name":"Jodda",
       "union_ban_name":"জোডদা"
    },
    {
       "_id":4232,
       "upazila_code":87,
       "union_code":69,
       "union_name":"Mokara",
       "union_ban_name":"মোকারা"
    },
    {
       "_id":4233,
       "upazila_code":87,
       "union_code":73,
       "union_name":"Mokrabpur",
       "union_ban_name":"মোক্রাবপুর"
    },
    {
       "_id":4234,
       "upazila_code":87,
       "union_code":80,
       "union_name":"Peria",
       "union_ban_name":"পেরিয়া"
    },
    {
       "_id":4235,
       "upazila_code":87,
       "union_code":86,
       "union_name":"Roykot",
       "union_ban_name":"রয়কোট"
    },
    {
       "_id":4237,
       "upazila_code":94,
       "union_code":10,
       "union_name":"Balarampur",
       "union_ban_name":"বালারামপুর"
    },
    {
       "_id":4238,
       "upazila_code":94,
       "union_code":17,
       "union_name":"Bitikandi",
       "union_ban_name":"বিটিকান্দি"
    },
    {
       "_id":4239,
       "upazila_code":94,
       "union_code":35,
       "union_name":"Jagatpur",
       "union_ban_name":"জগৎপুর"
    },
    {
       "_id":4240,
       "upazila_code":94,
       "union_code":45,
       "union_name":"Jiarkandi",
       "union_ban_name":"জিয়ারকান্দি"
    },
    {
       "_id":4241,
       "upazila_code":94,
       "union_code":62,
       "union_name":"Kala Kandi",
       "union_ban_name":"কাল কান্দি"
    },
    {
       "_id":4242,
       "upazila_code":94,
       "union_code":67,
       "union_name":"Karikandi",
       "union_ban_name":"কারিকান্দি"
    },
    {
       "_id":4243,
       "upazila_code":94,
       "union_code":69,
       "union_name":"Majidpur",
       "union_ban_name":"মজিদপুর"
    },
    {
       "_id":4244,
       "upazila_code":94,
       "union_code":77,
       "union_name":"Narayandia",
       "union_ban_name":"নারায়ণীয়"
    },
    {
       "_id":4245,
       "upazila_code":94,
       "union_code":85,
       "union_name":"Satani",
       "union_ban_name":"সাততী"
    },
    {
       "_id":4247,
       "upazila_code":16,
       "union_code":12,
       "union_name":"Bamo Bilchari",
       "union_ban_name":"বামো বিলছড়ি"
    },
    {
       "_id":4248,
       "upazila_code":16,
       "union_code":16,
       "union_name":"Baraitali",
       "union_ban_name":"বরৈতালী"
    },
    {
       "_id":4249,
       "upazila_code":16,
       "union_code":22,
       "union_name":"Bheola Manik Char",
       "union_ban_name":"ভীলা মানিক চর"
    },
    {
       "_id":4250,
       "upazila_code":16,
       "union_code":27,
       "union_name":"Chiringa",
       "union_ban_name":"চিরেসা"
    },
    {
       "_id":4251,
       "upazila_code":16,
       "union_code":31,
       "union_name":"Demusia",
       "union_ban_name":"ডেমুসিয়া"
    },
    {
       "_id":4252,
       "upazila_code":16,
       "union_code":33,
       "union_name":"Dulahazara",
       "union_ban_name":"দুলাহাজারা"
    },
    {
       "_id":4253,
       "upazila_code":16,
       "union_code":39,
       "union_name":"Purba Barabheola",
       "union_ban_name":"পূর্ব বরাবেওলা"
    },
    {
       "_id":4254,
       "upazila_code":16,
       "union_code":44,
       "union_name":"Fasiakhali",
       "union_ban_name":"ফাসাইখালী"
    },
    {
       "_id":4255,
       "upazila_code":16,
       "union_code":50,
       "union_name":"Harbang",
       "union_ban_name":"হারবং"
    },
    {
       "_id":4256,
       "upazila_code":16,
       "union_code":55,
       "union_name":"Kakhara",
       "union_ban_name":"কখারা"
    },
    {
       "_id":4257,
       "upazila_code":16,
       "union_code":61,
       "union_name":"Kaiarbil",
       "union_ban_name":"কাইয়ারবিল"
    },
    {
       "_id":4258,
       "upazila_code":16,
       "union_code":67,
       "union_name":"Khuntakhali",
       "union_ban_name":"খন্দখলি"
    },
    {
       "_id":4259,
       "upazila_code":16,
       "union_code":69,
       "union_name":"Konakhali",
       "union_ban_name":"কনখনালি"
    },
    {
       "_id":4260,
       "upazila_code":16,
       "union_code":72,
       "union_name":"Lakhyarchar",
       "union_ban_name":"লক্ষায়চারার"
    },
    {
       "_id":4261,
       "upazila_code":16,
       "union_code":80,
       "union_name":"Saharbil",
       "union_ban_name":"সাহারবিল"
    },
    {
       "_id":4262,
       "upazila_code":16,
       "union_code":87,
       "union_name":"Surajpur Manikpur",
       "union_ban_name":"সুরজপুর মানিকপুর"
    },
    {
       "_id":4263,
       "upazila_code":16,
       "union_code":94,
       "union_name":"Paschim Bara Bheola",
       "union_ban_name":"পশ্চিম বারা ব্রাওলা"
    },
    {
       "_id":4264,
       "upazila_code":24,
       "union_code":20,
       "union_name":"Bharuakhali",
       "union_ban_name":"ভরুখালী"
    },
    {
       "_id":4265,
       "upazila_code":24,
       "union_code":22,
       "union_name":"Chaufaldandi",
       "union_ban_name":"চৌফলদণ্ডী।"
    },
    {
       "_id":4266,
       "upazila_code":24,
       "union_code":35,
       "union_name":"Idgaon",
       "union_ban_name":"ঈদগাঁও"
    },
    {
       "_id":4269,
       "upazila_code":24,
       "union_code":45,
       "union_name":"Jalalabad",
       "union_ban_name":"জালালাবাদ"
    },
    {
       "_id":4270,
       "upazila_code":24,
       "union_code":47,
       "union_name":"Jhilwanja",
       "union_ban_name":"ঝিলওয়ানজা"
    },
    {
       "_id":4271,
       "upazila_code":24,
       "union_code":59,
       "union_name":"Khurushkul",
       "union_ban_name":"খুরূশকুল"
    },
    {
       "_id":4272,
       "upazila_code":24,
       "union_code":71,
       "union_name":"Patali Machhuakhali",
       "union_ban_name":"পাটালি মাছুয়াখালী"
    },
    {
       "_id":4273,
       "upazila_code":24,
       "union_code":83,
       "union_name":"Pokkhali",
       "union_ban_name":"পোকখালী"
    },
    {
       "_id":4274,
       "upazila_code":45,
       "union_code":13,
       "union_name":"Ali Akbar Deil",
       "union_ban_name":"আলী আকবর দেইল"
    },
    {
       "_id":4275,
       "upazila_code":45,
       "union_code":27,
       "union_name":"Baraghop",
       "union_ban_name":"বড়ঘোপ"
    },
    {
       "_id":4276,
       "upazila_code":45,
       "union_code":40,
       "union_name":"Dakshin Dhurung",
       "union_ban_name":"দক্ষিণ ধুরুং"
    },
    {
       "_id":4277,
       "upazila_code":45,
       "union_code":54,
       "union_name":"Kaiyarbil",
       "union_ban_name":"কাইয়ারবিল"
    },
    {
       "_id":4278,
       "upazila_code":45,
       "union_code":67,
       "union_name":"Lemsikhali",
       "union_ban_name":"লেমসিখালি"
    },
    {
       "_id":4279,
       "upazila_code":45,
       "union_code":81,
       "union_name":"Uttar Dhurung",
       "union_ban_name":"উত্তর ধুরুং"
    },
    {
       "_id":4280,
       "upazila_code":49,
       "union_code":11,
       "union_name":"Bara Maheskhali",
       "union_ban_name":"বারা মহেশখালী"
    },
    {
       "_id":4281,
       "upazila_code":49,
       "union_code":18,
       "union_name":"Chhotamohes Khali",
       "union_ban_name":"ছত্তমোহস খালি"
    },
    {
       "_id":4282,
       "upazila_code":49,
       "union_code":23,
       "union_name":"Dhalghata",
       "union_ban_name":"ধলঘাট"
    },
    {
       "_id":4283,
       "upazila_code":49,
       "union_code":47,
       "union_name":"Hoanak",
       "union_ban_name":"হোয়ানাক"
    },
    {
       "_id":4284,
       "upazila_code":49,
       "union_code":59,
       "union_name":"Kalarmarchhara",
       "union_ban_name":"কালার্মারচরা"
    },
    {
       "_id":4285,
       "upazila_code":49,
       "union_code":62,
       "union_name":"Kutubjom",
       "union_ban_name":"কুতুবজম"
    },
    {
       "_id":4286,
       "upazila_code":49,
       "union_code":71,
       "union_name":"Matarbari",
       "union_ban_name":"মাতারবাড়ী"
    },
    {
       "_id":4287,
       "upazila_code":49,
       "union_code":83,
       "union_name":"Saflapur",
       "union_ban_name":"সাফলাপুর"
    },
    {
       "_id":4288,
       "upazila_code":56,
       "union_code":11,
       "union_name":"Bara Bakia",
       "union_ban_name":"বার বাকিয়া"
    },
    {
       "_id":4289,
       "upazila_code":56,
       "union_code":55,
       "union_name":"Ujantia",
       "union_ban_name":"উজান্তিয়া"
    },
    {
       "_id":4290,
       "upazila_code":56,
       "union_code":78,
       "union_name":"Magnama",
       "union_ban_name":"ম্যাগনামা"
    },
    {
       "_id":4291,
       "upazila_code":56,
       "union_code":83,
       "union_name":"Pekua",
       "union_ban_name":"পেকুয়া"
    },
    {
       "_id":4292,
       "upazila_code":56,
       "union_code":89,
       "union_name":"Rajakhali",
       "union_ban_name":"রাজাকালী"
    },
    {
       "_id":4293,
       "upazila_code":56,
       "union_code":93,
       "union_name":"Shilkhali",
       "union_ban_name":"শীলখালী"
    },
    {
       "_id":4294,
       "upazila_code":56,
       "union_code":96,
       "union_name":"Taitong",
       "union_ban_name":"টেইটং"
    },
    {
       "_id":4295,
       "upazila_code":66,
       "union_code":13,
       "union_name":"Chakmarkul",
       "union_ban_name":"চক্করকুল"
    },
    {
       "_id":4296,
       "upazila_code":66,
       "union_code":15,
       "union_name":"Fatekharkul",
       "union_ban_name":"ফতেখারকুল"
    },
    {
       "_id":4297,
       "upazila_code":66,
       "union_code":19,
       "union_name":"Garjania",
       "union_ban_name":"গজানিয়া"
    },
    {
       "_id":4298,
       "upazila_code":66,
       "union_code":28,
       "union_name":"Idgar",
       "union_ban_name":"আইডিগার"
    },
    {
       "_id":4299,
       "upazila_code":66,
       "union_code":38,
       "union_name":"Joarianala",
       "union_ban_name":"জোআরিয়ানালা"
    },
    {
       "_id":4300,
       "upazila_code":66,
       "union_code":47,
       "union_name":"Kachhapia",
       "union_ban_name":"কাচাপিয়া"
    },
    {
       "_id":4301,
       "upazila_code":66,
       "union_code":57,
       "union_name":"Khuniapalong",
       "union_ban_name":"খুনিয়াপালং"
    },
    {
       "_id":4302,
       "upazila_code":66,
       "union_code":66,
       "union_name":"Kauarkhop",
       "union_ban_name":"কাউয়ারখোপ"
    },
    {
       "_id":4303,
       "upazila_code":66,
       "union_code":70,
       "union_name":"Rashid Nagar",
       "union_ban_name":"রশিদ নগর"
    },
    {
       "_id":4304,
       "upazila_code":66,
       "union_code":76,
       "union_name":"Rajarkul",
       "union_ban_name":"রাজকুলুল"
    },
    {
       "_id":4305,
       "upazila_code":66,
       "union_code":85,
       "union_name":"Dakshin Mithachhari",
       "union_ban_name":"দক্ষিণ মিতছড়িড়ি"
    },
    {
       "_id":4307,
       "upazila_code":90,
       "union_code":31,
       "union_name":"Nhilla",
       "union_ban_name":"নিহিলা"
    },
    {
       "_id":4308,
       "upazila_code":90,
       "union_code":39,
       "union_name":"St.Martin Dwip",
       "union_ban_name":"সেন্টমার্টিন দ্বীপ."
    },
    {
       "_id":4309,
       "upazila_code":90,
       "union_code":47,
       "union_name":"Sabrang",
       "union_ban_name":"সাবরাং"
    },
    {
       "_id":4310,
       "upazila_code":90,
       "union_code":63,
       "union_name":"Teknaf",
       "union_ban_name":"টেকনাফ"
    },
    {
       "_id":4311,
       "upazila_code":90,
       "union_code":79,
       "union_name":"Whykong",
       "union_ban_name":"হয়াইকং"
    },
    {
       "_id":4312,
       "upazila_code":94,
       "union_code":15,
       "union_name":"Haldia Palong",
       "union_ban_name":"হালদিয়া পলং"
    },
    {
       "_id":4313,
       "upazila_code":94,
       "union_code":31,
       "union_name":"Jalia Palong",
       "union_ban_name":"জালিয়া পলাং"
    },
    {
       "_id":4314,
       "upazila_code":94,
       "union_code":47,
       "union_name":"Raja Palong",
       "union_ban_name":"রাজা পলং"
    },
    {
       "_id":4315,
       "upazila_code":94,
       "union_code":63,
       "union_name":"Ratna Palong",
       "union_ban_name":"রত্ন প্যালং"
    },
    {
       "_id":4316,
       "upazila_code":94,
       "union_code":79,
       "union_name":"Palong Khali",
       "union_ban_name":"পলং খালি"
    },
    {
       "_id":4317,
       "upazila_code":4,
       "union_code":13,
       "union_name":"Badda",
       "union_ban_name":"বাড্ডা"
    },
    {
       "_id":4318,
       "upazila_code":4,
       "union_code":19,
       "union_name":"Beraid",
       "union_ban_name":"বেদিদ"
    },
    {
       "_id":4319,
       "upazila_code":4,
       "union_code":57,
       "union_name":"Bhatara",
       "union_ban_name":"ভাতারা"
    },
    {
       "_id":4320,
       "upazila_code":4,
       "union_code":82,
       "union_name":"Satarkul",
       "union_ban_name":"সাট্রোকুল"
    },
    {
       "_id":4321,
       "upazila_code":6,
       "union_code":38,
       "union_name":"Dakshinkhan(Part)",
       "union_ban_name":"দক্ষিণখান (অংশ)"
    },
    {
       "_id":4323,
       "upazila_code":12,
       "union_code":65,
       "union_name":"Demra",
       "union_ban_name":"ডেমরা"
    },
    {
       "_id":4324,
       "upazila_code":12,
       "union_code":80,
       "union_name":"Matuail (Part)",
       "union_ban_name":"মাতুয়েল (অংশ)"
    },
    {
       "_id":4325,
       "upazila_code":12,
       "union_code":93,
       "union_name":"Saralia",
       "union_ban_name":"সারালিয়া"
    },
    {
       "_id":4326,
       "upazila_code":14,
       "union_code":10,
       "union_name":"Amta",
       "union_ban_name":"আমতা"
    },
    {
       "_id":4327,
       "upazila_code":14,
       "union_code":11,
       "union_name":"Baisakanda",
       "union_ban_name":"বাইসাকান্দা"
    },
    {
       "_id":4329,
       "upazila_code":14,
       "union_code":23,
       "union_name":"Bhararia",
       "union_ban_name":"ভারতিয়া"
    },
    {
       "_id":4330,
       "upazila_code":14,
       "union_code":29,
       "union_name":"Chauhat",
       "union_ban_name":"চৌহাট"
    },
    {
       "_id":4331,
       "upazila_code":14,
       "union_code":35,
       "union_name":"Dhamrai",
       "union_ban_name":"ধামরাই"
    },
    {
       "_id":4332,
       "upazila_code":14,
       "union_code":41,
       "union_name":"Gangutia",
       "union_ban_name":"গাঙ্গুটিয়া"
    },
    {
       "_id":4333,
       "upazila_code":14,
       "union_code":47,
       "union_name":"Jadabpur",
       "union_ban_name":"জাদবপুর"
    },
    {
       "_id":4334,
       "upazila_code":14,
       "union_code":53,
       "union_name":"Kulla",
       "union_ban_name":"কুলা"
    },
    {
       "_id":4335,
       "upazila_code":14,
       "union_code":59,
       "union_name":"Kushura",
       "union_ban_name":"কুশুরা"
    },
    {
       "_id":4336,
       "upazila_code":14,
       "union_code":65,
       "union_name":"Nannar",
       "union_ban_name":"নান্নার"
    },
    {
       "_id":4337,
       "upazila_code":14,
       "union_code":71,
       "union_name":"Rowail",
       "union_ban_name":"রোলাইল"
    },
    {
       "_id":4338,
       "upazila_code":14,
       "union_code":77,
       "union_name":"Sanora",
       "union_ban_name":"সানোরা"
    },
    {
       "_id":4339,
       "upazila_code":14,
       "union_code":83,
       "union_name":"Sombhag",
       "union_ban_name":"সোমভাগ"
    },
    {
       "_id":4340,
       "upazila_code":14,
       "union_code":88,
       "union_name":"Suapur",
       "union_ban_name":"সুাপুর"
    },
    {
       "_id":4341,
       "upazila_code":14,
       "union_code":94,
       "union_name":"Suti Para",
       "union_ban_name":"সুতি পারা"
    },
    {
       "_id":4342,
       "upazila_code":18,
       "union_code":13,
       "union_name":"Bilaspur",
       "union_ban_name":"বিলাসপুর"
    },
    {
       "_id":4343,
       "upazila_code":18,
       "union_code":21,
       "union_name":"Kushumhati",
       "union_ban_name":"কুণ্ডলহতি"
    },
    {
       "_id":4344,
       "upazila_code":18,
       "union_code":31,
       "union_name":"Mahmudpur",
       "union_ban_name":"মাহমুদপুর"
    },
    {
       "_id":4345,
       "upazila_code":18,
       "union_code":42,
       "union_name":"Muksudpur",
       "union_ban_name":"মুকসুদপুর"
    },
    {
       "_id":4346,
       "upazila_code":18,
       "union_code":52,
       "union_name":"Narisha",
       "union_ban_name":"নিশিশা"
    },
    {
       "_id":4347,
       "upazila_code":18,
       "union_code":63,
       "union_name":"Nayabari",
       "union_ban_name":"নয়বাড়ী"
    },
    {
       "_id":4348,
       "upazila_code":18,
       "union_code":73,
       "union_name":"Roypara",
       "union_ban_name":"রায়পাড়া"
    },
    {
       "_id":4349,
       "upazila_code":18,
       "union_code":84,
       "union_name":"Sutar Para",
       "union_ban_name":"সুটার প্যারা"
    },
    {
       "_id":4350,
       "upazila_code":29,
       "union_code":35,
       "union_name":"Dhania (Part)",
       "union_ban_name":"দনিয়া (অংশ)"
    },
    {
       "_id":4353,
       "upazila_code":32,
       "union_code":80,
       "union_name":"Matuail(Part)",
       "union_ban_name":"মাতুয়াইল (অংশ)"
    },
    {
       "_id":4355,
       "upazila_code":34,
       "union_code":51,
       "union_name":"Sultanganj",
       "union_ban_name":"সুলতানগঞ্জ"
    },
    {
       "_id":4356,
       "upazila_code":36,
       "union_code":60,
       "union_name":"Dakshingaon (Part)",
       "union_ban_name":"দক্ষিণগাঁও (অংশ)"
    },
    {
       "_id":4357,
       "upazila_code":36,
       "union_code":85,
       "union_name":"Nasirabad",
       "union_ban_name":"নাসিরাবাদ"
    },
    {
       "_id":4358,
       "upazila_code":37,
       "union_code":38,
       "union_name":"Dakshinkhan (Part)",
       "union_ban_name":"দক্ষিণখান (অংশ)"
    },
    {
       "_id":4359,
       "upazila_code":37,
       "union_code":49,
       "union_name":"Dumni",
       "union_ban_name":"দুমনি"
    },
    {
       "_id":4361,
       "upazila_code":38,
       "union_code":13,
       "union_name":"Basta",
       "union_ban_name":"বাস্তা"
    },
    {
       "_id":4362,
       "upazila_code":38,
       "union_code":17,
       "union_name":"Hazratpur",
       "union_ban_name":"হযরতপুর"
    },
    {
       "_id":4363,
       "upazila_code":38,
       "union_code":25,
       "union_name":"Kalatia",
       "union_ban_name":"কালাটিয়া"
    },
    {
       "_id":4364,
       "upazila_code":38,
       "union_code":34,
       "union_name":"Kalindi",
       "union_ban_name":"কালিন্দী"
    },
    {
       "_id":4365,
       "upazila_code":38,
       "union_code":43,
       "union_name":"Konda",
       "union_ban_name":"কোন্ডা"
    },
    {
       "_id":4366,
       "upazila_code":38,
       "union_code":51,
       "union_name":"Ruhitpur",
       "union_ban_name":"রুহিতপুর"
    },
    {
       "_id":4367,
       "upazila_code":38,
       "union_code":60,
       "union_name":"Sakta",
       "union_ban_name":"সাকতা"
    },
    {
       "_id":4368,
       "upazila_code":38,
       "union_code":69,
       "union_name":"Subhadya",
       "union_ban_name":"সুভাদিয়া"
    },
    {
       "_id":4369,
       "upazila_code":38,
       "union_code":77,
       "union_name":"Taranagar",
       "union_ban_name":"তারানগর"
    },
    {
       "_id":4370,
       "upazila_code":38,
       "union_code":86,
       "union_name":"Tegharia",
       "union_ban_name":"তেঘরিয়া"
    },
    {
       "_id":4371,
       "upazila_code":38,
       "union_code":94,
       "union_name":"Zinjira",
       "union_ban_name":"জিনজিরা"
    },
    {
       "_id":4372,
       "upazila_code":62,
       "union_code":11,
       "union_name":"Agla",
       "union_ban_name":"আগলা"
    },
    {
       "_id":4373,
       "upazila_code":62,
       "union_code":13,
       "union_name":"Bakshanagar",
       "union_ban_name":"বাকশনগর"
    },
    {
       "_id":4374,
       "upazila_code":62,
       "union_code":20,
       "union_name":"Bandura",
       "union_ban_name":"বান্দুরা"
    },
    {
       "_id":4375,
       "upazila_code":62,
       "union_code":27,
       "union_name":"Barrah",
       "union_ban_name":"বারারা"
    },
    {
       "_id":4376,
       "upazila_code":62,
       "union_code":33,
       "union_name":"Baruakhali",
       "union_ban_name":"বারুখালী"
    },
    {
       "_id":4377,
       "upazila_code":62,
       "union_code":40,
       "union_name":"Churain",
       "union_ban_name":"চুরাইন"
    },
    {
       "_id":4379,
       "upazila_code":62,
       "union_code":54,
       "union_name":"Jantrail",
       "union_ban_name":"জনট্রল"
    },
    {
       "_id":4380,
       "upazila_code":62,
       "union_code":61,
       "union_name":"Joykrishnapur",
       "union_ban_name":"জয়কর্ণপুর"
    },
    {
       "_id":4381,
       "upazila_code":62,
       "union_code":67,
       "union_name":"Kailail",
       "union_ban_name":"কৈলাইল"
    },
    {
       "_id":4382,
       "upazila_code":62,
       "union_code":74,
       "union_name":"Kalakopa",
       "union_ban_name":"কালকোপা"
    },
    {
       "_id":4383,
       "upazila_code":62,
       "union_code":81,
       "union_name":"Nayansree",
       "union_ban_name":"নয়নসে"
    },
    {
       "_id":4384,
       "upazila_code":62,
       "union_code":88,
       "union_name":"Shikari Para",
       "union_ban_name":"শিকারী প্যারা"
    },
    {
       "_id":4385,
       "upazila_code":62,
       "union_code":94,
       "union_name":"Sholla",
       "union_ban_name":"শোল্লা"
    },
    {
       "_id":4387,
       "upazila_code":68,
       "union_code":82,
       "union_name":"Manda",
       "union_ban_name":"মান্ডা."
    },
    {
       "_id":4388,
       "upazila_code":72,
       "union_code":15,
       "union_name":"Amin Bazar",
       "union_ban_name":"আমিন বাজার"
    },
    {
       "_id":4389,
       "upazila_code":72,
       "union_code":18,
       "union_name":"Ashulia",
       "union_ban_name":"আশুলিয়া"
    },
    {
       "_id":4391,
       "upazila_code":72,
       "union_code":27,
       "union_name":"Bhakurta",
       "union_ban_name":"ভক্ততা"
    },
    {
       "_id":4392,
       "upazila_code":72,
       "union_code":33,
       "union_name":"Biralia",
       "union_ban_name":"বিরালিয়া"
    },
    {
       "_id":4393,
       "upazila_code":72,
       "union_code":39,
       "union_name":"Dhamsana",
       "union_ban_name":"ধামসানা"
    },
    {
       "_id":4394,
       "upazila_code":72,
       "union_code":50,
       "union_name":"Kaundia",
       "union_ban_name":"কুন্ডিয়া"
    },
    {
       "_id":4395,
       "upazila_code":72,
       "union_code":72,
       "union_name":"Pathalia",
       "union_ban_name":"পাথালিয়া"
    },
    {
       "_id":4396,
       "upazila_code":72,
       "union_code":78,
       "union_name":"Savar",
       "union_ban_name":"সাভার"
    },
    {
       "_id":4397,
       "upazila_code":72,
       "union_code":83,
       "union_name":"Shimulia",
       "union_ban_name":"শিমুলিয়া"
    },
    {
       "_id":4398,
       "upazila_code":72,
       "union_code":89,
       "union_name":"Tetuljhora",
       "union_ban_name":"তেতুলঝোরা"
    },
    {
       "_id":4399,
       "upazila_code":72,
       "union_code":94,
       "union_name":"Yearpur",
       "union_ban_name":"বছর আগে"
    },
    {
       "_id":4400,
       "upazila_code":93,
       "union_code":51,
       "union_name":"Harirampur",
       "union_ban_name":"হরিরামপুর"
    },
    {
       "_id":4401,
       "upazila_code":96,
       "union_code":76,
       "union_name":"Uttar Khan",
       "union_ban_name":"উত্তর খান"
    },
    {
       "_id":4402,
       "upazila_code":10,
       "union_code":11,
       "union_name":"Benail",
       "union_ban_name":"বেনাইল"
    },
    {
       "_id":4403,
       "upazila_code":10,
       "union_code":23,
       "union_name":"Mukundapur",
       "union_ban_name":"মুকুন্দাপুর"
    },
    {
       "_id":4404,
       "upazila_code":10,
       "union_code":35,
       "union_name":"Deor",
       "union_ban_name":"ডওর"
    },
    {
       "_id":4405,
       "upazila_code":10,
       "union_code":47,
       "union_name":"Jotbani",
       "union_ban_name":"জোটবানি"
    },
    {
       "_id":4406,
       "upazila_code":10,
       "union_code":59,
       "union_name":"Katla",
       "union_ban_name":"কাতলা"
    },
    {
       "_id":4408,
       "upazila_code":10,
       "union_code":83,
       "union_name":"Pali Prayagpur",
       "union_ban_name":"পালি প্রিয়াগপুর"
    },
    {
       "_id":4409,
       "upazila_code":12,
       "union_code":13,
       "union_name":"Bhognagar",
       "union_ban_name":"ভোগনগর"
    },
    {
       "_id":4410,
       "upazila_code":12,
       "union_code":17,
       "union_name":"Maricha",
       "union_ban_name":"মারিচা"
    },
    {
       "_id":4411,
       "upazila_code":12,
       "union_code":25,
       "union_name":"Mohammadpur",
       "union_ban_name":"মোহাম্মদপুর"
    },
    {
       "_id":4413,
       "upazila_code":12,
       "union_code":43,
       "union_name":"Nijpara",
       "union_ban_name":"নিজপাড়া"
    },
    {
       "_id":4414,
       "upazila_code":12,
       "union_code":51,
       "union_name":"Palashbari",
       "union_ban_name":"পলাশবাড়ী"
    },
    {
       "_id":4415,
       "upazila_code":12,
       "union_code":60,
       "union_name":"Paltapur",
       "union_ban_name":"পল্টাপুর"
    },
    {
       "_id":4416,
       "upazila_code":12,
       "union_code":69,
       "union_name":"Shatagram",
       "union_ban_name":"শাটগ্রাম"
    },
    {
       "_id":4417,
       "upazila_code":12,
       "union_code":77,
       "union_name":"Satair",
       "union_ban_name":"স্যাটায়ার"
    },
    {
       "_id":4418,
       "upazila_code":12,
       "union_code":86,
       "union_name":"Shibrampur",
       "union_ban_name":"শিব্রম্পপুর"
    },
    {
       "_id":4419,
       "upazila_code":12,
       "union_code":94,
       "union_name":"Sujalpur",
       "union_ban_name":"সুজালপুর"
    },
    {
       "_id":4421,
       "upazila_code":17,
       "union_code":19,
       "union_name":"Bhandara",
       "union_ban_name":"ভান্ডার"
    },
    {
       "_id":4422,
       "upazila_code":17,
       "union_code":28,
       "union_name":"Biral",
       "union_ban_name":"বিরাল"
    },
    {
       "_id":4423,
       "upazila_code":17,
       "union_code":38,
       "union_name":"Bijora",
       "union_ban_name":"বিজরা"
    },
    {
       "_id":4424,
       "upazila_code":17,
       "union_code":47,
       "union_name":"Dhamair",
       "union_ban_name":"ধামের"
    },
    {
       "_id":4426,
       "upazila_code":17,
       "union_code":66,
       "union_name":"Farakkabad",
       "union_ban_name":"ফারাকাবাদ"
    },
    {
       "_id":4427,
       "upazila_code":17,
       "union_code":76,
       "union_name":"Mangalpur",
       "union_ban_name":"মঙ্গলপুর"
    },
    {
       "_id":4428,
       "upazila_code":17,
       "union_code":85,
       "union_name":"Rani Pukur",
       "union_ban_name":"রানী পুকুর"
    },
    {
       "_id":4429,
       "upazila_code":17,
       "union_code":95,
       "union_name":"Sahargram",
       "union_ban_name":"সাহারগ্রাম"
    },
    {
       "_id":4430,
       "upazila_code":21,
       "union_code":13,
       "union_name":"Atgaon",
       "union_ban_name":"আতগাঁও"
    },
    {
       "_id":4431,
       "upazila_code":21,
       "union_code":27,
       "union_name":"Chhatail",
       "union_ban_name":"ছাতেল"
    },
    {
       "_id":4432,
       "upazila_code":21,
       "union_code":40,
       "union_name":"Ishania",
       "union_ban_name":"ইশানিয়া"
    },
    {
       "_id":4433,
       "upazila_code":21,
       "union_code":54,
       "union_name":"Mushidhat",
       "union_ban_name":"মুশাহাত"
    },
    {
       "_id":4434,
       "upazila_code":21,
       "union_code":67,
       "union_name":"Nafanagar",
       "union_ban_name":"নফনগর"
    },
    {
       "_id":4435,
       "upazila_code":21,
       "union_code":81,
       "union_name":"Rangaon",
       "union_ban_name":"রাঙ্গন"
    },
    {
       "_id":4436,
       "upazila_code":30,
       "union_code":13,
       "union_name":"Abdulpur",
       "union_ban_name":"আব্দুলপুর"
    },
    {
       "_id":4437,
       "upazila_code":30,
       "union_code":15,
       "union_name":"Alokdihi",
       "union_ban_name":"আলকদিহি"
    },
    {
       "_id":4438,
       "upazila_code":30,
       "union_code":23,
       "union_name":"Amarpur",
       "union_ban_name":"অমরপুর"
    },
    {
       "_id":4439,
       "upazila_code":30,
       "union_code":31,
       "union_name":"Aulia Pukur",
       "union_ban_name":"আউলিয়া পুকুর"
    },
    {
       "_id":4440,
       "upazila_code":30,
       "union_code":39,
       "union_name":"Bhiail",
       "union_ban_name":"ভিয়াঈল"
    },
    {
       "_id":4441,
       "upazila_code":30,
       "union_code":47,
       "union_name":"Fatehjanapur",
       "union_ban_name":"ফতেহানপুর"
    },
    {
       "_id":4442,
       "upazila_code":30,
       "union_code":55,
       "union_name":"Isabpur",
       "union_ban_name":"ইসাবপুর"
    },
    {
       "_id":4444,
       "upazila_code":30,
       "union_code":71,
       "union_name":"Punatti",
       "union_ban_name":"পুনাত্তি"
    },
    {
       "_id":4445,
       "upazila_code":30,
       "union_code":79,
       "union_name":"Saintara",
       "union_ban_name":"সেন্টার"
    },
    {
       "_id":4446,
       "upazila_code":30,
       "union_code":87,
       "union_name":"Satnala",
       "union_ban_name":"সাতনালা"
    },
    {
       "_id":4447,
       "upazila_code":30,
       "union_code":94,
       "union_name":"Tentulia",
       "union_ban_name":"তেন্টুলিয়া"
    },
    {
       "_id":4448,
       "upazila_code":38,
       "union_code":25,
       "union_name":"Aladipur",
       "union_ban_name":"আলাদিপুর"
    },
    {
       "_id":4449,
       "upazila_code":38,
       "union_code":28,
       "union_name":"Betdighi",
       "union_ban_name":"বেতদিঘি"
    },
    {
       "_id":4451,
       "upazila_code":38,
       "union_code":47,
       "union_name":"Eluary",
       "union_ban_name":"এলুয়"
    },
    {
       "_id":4452,
       "upazila_code":38,
       "union_code":66,
       "union_name":"Kazihal",
       "union_ban_name":"কাজীহল"
    },
    {
       "_id":4453,
       "upazila_code":38,
       "union_code":76,
       "union_name":"Khayerbari",
       "union_ban_name":"খায়রবাড়ী"
    },
    {
       "_id":4454,
       "upazila_code":38,
       "union_code":84,
       "union_name":"Shibnagar",
       "union_ban_name":"শিবনগর"
    },
    {
       "_id":4455,
       "upazila_code":43,
       "union_code":19,
       "union_name":"Bulakipur",
       "union_ban_name":"বুলাকীপুর"
    },
    {
       "_id":4456,
       "upazila_code":43,
       "union_code":38,
       "union_name":"Ghoraghat",
       "union_ban_name":"ঘোরাঘাট"
    },
    {
       "_id":4457,
       "upazila_code":43,
       "union_code":57,
       "union_name":"Palsa",
       "union_ban_name":"পলসা"
    },
    {
       "_id":4458,
       "upazila_code":43,
       "union_code":76,
       "union_name":"Singra",
       "union_ban_name":"সিংড়া"
    },
    {
       "_id":4459,
       "upazila_code":47,
       "union_code":13,
       "union_name":"Alihat",
       "union_ban_name":"আলিহাট"
    },
    {
       "_id":4460,
       "upazila_code":47,
       "union_code":40,
       "union_name":"Boaldar",
       "union_ban_name":"বাউলদার"
    },
    {
       "_id":4461,
       "upazila_code":47,
       "union_code":81,
       "union_name":"Khatta Madhab Para",
       "union_ban_name":"খট্টা মাধব পারা"
    },
    {
       "_id":4462,
       "upazila_code":56,
       "union_code":15,
       "union_name":"Dabar",
       "union_ban_name":"দাবর"
    },
    {
       "_id":4467,
       "upazila_code":56,
       "union_code":94,
       "union_name":"Targaon",
       "union_ban_name":"তারগাঁও"
    },
    {
       "_id":4468,
       "upazila_code":60,
       "union_code":15,
       "union_name":"Alokjhari",
       "union_ban_name":"আলখঝাড়ি"
    },
    {
       "_id":4469,
       "upazila_code":60,
       "union_code":31,
       "union_name":"Angar Para",
       "union_ban_name":"আঙ্গার প্যারা"
    },
    {
       "_id":4470,
       "upazila_code":60,
       "union_code":47,
       "union_name":"Bhabki",
       "union_ban_name":"ভবকি"
    },
    {
       "_id":4471,
       "upazila_code":60,
       "union_code":63,
       "union_name":"Bherbheri",
       "union_ban_name":"খেরবেরি"
    },
    {
       "_id":4472,
       "upazila_code":60,
       "union_code":79,
       "union_name":"Goaldihi",
       "union_ban_name":"গোয়ালদিহি"
    },
    {
       "_id":4473,
       "upazila_code":60,
       "union_code":94,
       "union_name":"Khamar Para",
       "union_ban_name":"খামারের পারা"
    },
    {
       "_id":4474,
       "upazila_code":64,
       "union_code":16,
       "union_name":"Askarpur",
       "union_ban_name":"আসকরপুর"
    },
    {
       "_id":4475,
       "upazila_code":64,
       "union_code":17,
       "union_name":"Auliapur",
       "union_ban_name":"আউলিয়াপুর"
    },
    {
       "_id":4476,
       "upazila_code":64,
       "union_code":25,
       "union_name":"Chehelgazi",
       "union_ban_name":"চেহ্লগাজি"
    },
    {
       "_id":4477,
       "upazila_code":64,
       "union_code":34,
       "union_name":"Fazilpur",
       "union_ban_name":"ফজিলপুর"
    },
    {
       "_id":4479,
       "upazila_code":64,
       "union_code":60,
       "union_name":"Shankarpur",
       "union_ban_name":"শঙ্করপুর"
    },
    {
       "_id":4480,
       "upazila_code":64,
       "union_code":69,
       "union_name":"Shashara",
       "union_ban_name":"শাশারা"
    },
    {
       "_id":4481,
       "upazila_code":64,
       "union_code":77,
       "union_name":"Sekhpura",
       "union_ban_name":"সেখপুরা"
    },
    {
       "_id":4483,
       "upazila_code":64,
       "union_code":94,
       "union_name":"Uthrail",
       "union_ban_name":"উথ্রাইল"
    },
    {
       "_id":4484,
       "upazila_code":69,
       "union_code":13,
       "union_name":"Bhaduria",
       "union_ban_name":"ভাদুরিয়া"
    },
    {
       "_id":4485,
       "upazila_code":69,
       "union_code":17,
       "union_name":"Binodnagar",
       "union_ban_name":"বিনোধনগর"
    },
    {
       "_id":4486,
       "upazila_code":69,
       "union_code":25,
       "union_name":"Daudpur",
       "union_ban_name":"দাউদপুর"
    },
    {
       "_id":4487,
       "upazila_code":69,
       "union_code":43,
       "union_name":"Golapganj",
       "union_ban_name":"গোলাপগঞ্জ"
    },
    {
       "_id":4489,
       "upazila_code":69,
       "union_code":69,
       "union_name":"Kushdaha",
       "union_ban_name":"কুশাদা"
    },
    {
       "_id":4491,
       "upazila_code":69,
       "union_code":86,
       "union_name":"Putimara",
       "union_ban_name":"পুটিমারা"
    },
    {
       "_id":4492,
       "upazila_code":69,
       "union_code":94,
       "union_name":"Shalkhuria",
       "union_ban_name":"শালখুরিয়া"
    },
    {
       "_id":4493,
       "upazila_code":77,
       "union_code":16,
       "union_name":"Belaichandi",
       "union_ban_name":"বেলাইচান্ডি"
    },
    {
       "_id":4494,
       "upazila_code":77,
       "union_code":17,
       "union_name":"Chandipur",
       "union_ban_name":"চাঁদিপুর"
    },
    {
       "_id":4495,
       "upazila_code":77,
       "union_code":25,
       "union_name":"Habra",
       "union_ban_name":"হাবরা"
    },
    {
       "_id":4496,
       "upazila_code":77,
       "union_code":34,
       "union_name":"Hamidpur",
       "union_ban_name":"হামিদপুর"
    },
    {
       "_id":4498,
       "upazila_code":77,
       "union_code":51,
       "union_name":"Manmathapur",
       "union_ban_name":"মানমথাপুর"
    },
    {
       "_id":4500,
       "upazila_code":77,
       "union_code":69,
       "union_name":"Mostafapur",
       "union_ban_name":"মোস্তফাপুর"
    },
    {
       "_id":4503,
       "upazila_code":3,
       "union_code":10,
       "union_name":"Alfadanga",
       "union_ban_name":"আলফাদাঙ্গা"
    },
    {
       "_id":4504,
       "upazila_code":3,
       "union_code":21,
       "union_name":"Bana",
       "union_ban_name":"বানা"
    },
    {
       "_id":4505,
       "upazila_code":3,
       "union_code":31,
       "union_name":"Buraich",
       "union_ban_name":"বুরাইচ"
    },
    {
       "_id":4507,
       "upazila_code":3,
       "union_code":52,
       "union_name":"Panchuria",
       "union_ban_name":"পঞ্চুরিয়া"
    },
    {
       "_id":4508,
       "upazila_code":3,
       "union_code":84,
       "union_name":"Tagarbanda",
       "union_ban_name":"ট্যাগারবান্দা"
    },
    {
       "_id":4509,
       "upazila_code":10,
       "union_code":13,
       "union_name":"Algi",
       "union_ban_name":"আলজি"
    },
    {
       "_id":4510,
       "upazila_code":10,
       "union_code":15,
       "union_name":"Azimnagar",
       "union_ban_name":"আজিমনগর"
    },
    {
       "_id":4511,
       "upazila_code":10,
       "union_code":25,
       "union_name":"Chumordi",
       "union_ban_name":"চুমোর্দি"
    },
    {
       "_id":4513,
       "upazila_code":10,
       "union_code":39,
       "union_name":"Gharua",
       "union_ban_name":"ঘারুয়া"
    },
    {
       "_id":4514,
       "upazila_code":10,
       "union_code":47,
       "union_name":"Hamirdi",
       "union_ban_name":"হামিদী"
    },
    {
       "_id":4515,
       "upazila_code":10,
       "union_code":55,
       "union_name":"Kalamridha",
       "union_ban_name":"কালাম্রিধা"
    },
    {
       "_id":4516,
       "upazila_code":10,
       "union_code":63,
       "union_name":"Kaolibera",
       "union_ban_name":"কাওলিবেরা"
    },
    {
       "_id":4517,
       "upazila_code":10,
       "union_code":71,
       "union_name":"Manikdaha",
       "union_ban_name":"মানিকদহ"
    },
    {
       "_id":4519,
       "upazila_code":10,
       "union_code":87,
       "union_name":"Nurullaganj",
       "union_ban_name":"নুরুলগঞ্জ"
    },
    {
       "_id":4520,
       "upazila_code":10,
       "union_code":94,
       "union_name":"Tuzarpur",
       "union_ban_name":"তুজরপুর"
    },
    {
       "_id":4521,
       "upazila_code":18,
       "union_code":15,
       "union_name":"Boalmari",
       "union_ban_name":"বোয়ালমারি"
    },
    {
       "_id":4523,
       "upazila_code":18,
       "union_code":25,
       "union_name":"Chatul",
       "union_ban_name":"চাতুল"
    },
    {
       "_id":4524,
       "upazila_code":18,
       "union_code":30,
       "union_name":"Dadpur",
       "union_ban_name":"দাদপুর"
    },
    {
       "_id":4525,
       "upazila_code":18,
       "union_code":35,
       "union_name":"Ghoshpur",
       "union_ban_name":"ঘোষপুর"
    },
    {
       "_id":4526,
       "upazila_code":18,
       "union_code":40,
       "union_name":"Gunbaha",
       "union_ban_name":"গুনবাহা"
    },
    {
       "_id":4527,
       "upazila_code":18,
       "union_code":60,
       "union_name":"Moyna",
       "union_ban_name":"মোয়না"
    },
    {
       "_id":4528,
       "upazila_code":18,
       "union_code":75,
       "union_name":"Parameshwardi",
       "union_ban_name":"পরমেশ্বরী"
    },
    {
       "_id":4529,
       "upazila_code":18,
       "union_code":85,
       "union_name":"Rupapat",
       "union_ban_name":"রূপাপাট"
    },
    {
       "_id":4531,
       "upazila_code":18,
       "union_code":95,
       "union_name":"Shekhar",
       "union_ban_name":"শেখর"
    },
    {
       "_id":4532,
       "upazila_code":21,
       "union_code":19,
       "union_name":"Char Bhadrasan",
       "union_ban_name":"চর ভদ্রসান"
    },
    {
       "_id":4533,
       "upazila_code":21,
       "union_code":38,
       "union_name":"Char Harirampur",
       "union_ban_name":"চর হরিরামপুর"
    },
    {
       "_id":4534,
       "upazila_code":21,
       "union_code":57,
       "union_name":"Char Jhaukanda",
       "union_ban_name":"চর ঝাউকান্দা"
    },
    {
       "_id":4535,
       "upazila_code":21,
       "union_code":76,
       "union_name":"Gazirtek",
       "union_ban_name":"গাজিরটেক"
    },
    {
       "_id":4536,
       "upazila_code":47,
       "union_code":13,
       "union_name":"Aliabad",
       "union_ban_name":"আলিয়াবাদ"
    },
    {
       "_id":4537,
       "upazila_code":47,
       "union_code":15,
       "union_name":"Ambikapur",
       "union_ban_name":"অম্বিকাপুর"
    },
    {
       "_id":4538,
       "upazila_code":47,
       "union_code":23,
       "union_name":"Char Madhabdia",
       "union_ban_name":"চর মাধাবদিয়া"
    },
    {
       "_id":4539,
       "upazila_code":47,
       "union_code":31,
       "union_name":"Decreerchar",
       "union_ban_name":"ডিক্রিচার"
    },
    {
       "_id":4540,
       "upazila_code":47,
       "union_code":39,
       "union_name":"Greda",
       "union_ban_name":"গ্রেডা"
    },
    {
       "_id":4541,
       "upazila_code":47,
       "union_code":47,
       "union_name":"Ishan Gopalpur",
       "union_ban_name":"ঈশান গোপালপুর"
    },
    {
       "_id":4542,
       "upazila_code":47,
       "union_code":55,
       "union_name":"Kaijuri",
       "union_ban_name":"কাইজুরি"
    },
    {
       "_id":4543,
       "upazila_code":47,
       "union_code":63,
       "union_name":"Kanaipur",
       "union_ban_name":"কানাইপুর"
    },
    {
       "_id":4545,
       "upazila_code":47,
       "union_code":79,
       "union_name":"Majchar",
       "union_ban_name":"মাজচর"
    },
    {
       "_id":4546,
       "upazila_code":47,
       "union_code":87,
       "union_name":"Uttar Channel",
       "union_ban_name":"উত্তর চ্যানেল"
    },
    {
       "_id":4547,
       "upazila_code":56,
       "union_code":10,
       "union_name":"Bagat",
       "union_ban_name":"বাঘাত"
    },
    {
       "_id":4548,
       "upazila_code":56,
       "union_code":21,
       "union_name":"Dumain",
       "union_ban_name":"ডুমাইন"
    },
    {
       "_id":4549,
       "upazila_code":56,
       "union_code":31,
       "union_name":"Gajna",
       "union_ban_name":"গজনা"
    },
    {
       "_id":4551,
       "upazila_code":56,
       "union_code":42,
       "union_name":"Kamarkhali",
       "union_ban_name":"কামারখালী"
    },
    {
       "_id":4552,
       "upazila_code":56,
       "union_code":52,
       "union_name":"Madhukhali",
       "union_ban_name":"মধুখালী"
    },
    {
       "_id":4553,
       "upazila_code":56,
       "union_code":63,
       "union_name":"Megchami",
       "union_ban_name":"মেগচামি"
    },
    {
       "_id":4554,
       "upazila_code":56,
       "union_code":73,
       "union_name":"Noapara",
       "union_ban_name":"নোয়াপাড়া"
    },
    {
       "_id":4555,
       "upazila_code":56,
       "union_code":84,
       "union_name":"Raipur",
       "union_ban_name":"রায়পুর"
    },
    {
       "_id":4556,
       "upazila_code":62,
       "union_code":22,
       "union_name":"Char Jasordi",
       "union_ban_name":"চর জাসর্ডি"
    },
    {
       "_id":4557,
       "upazila_code":62,
       "union_code":27,
       "union_name":"Dangi",
       "union_ban_name":"ডাঙ্গি"
    },
    {
       "_id":4558,
       "upazila_code":62,
       "union_code":33,
       "union_name":"Phulsuti",
       "union_ban_name":"ফাবলসী"
    },
    {
       "_id":4559,
       "upazila_code":62,
       "union_code":50,
       "union_name":"Kaichail",
       "union_ban_name":"কাইচাইল"
    },
    {
       "_id":4560,
       "upazila_code":62,
       "union_code":55,
       "union_name":"Laskardia",
       "union_ban_name":"লস্কার্ডিয়া"
    },
    {
       "_id":4561,
       "upazila_code":62,
       "union_code":67,
       "union_name":"Kodalia Shohidnagar",
       "union_ban_name":"কোদালিয়া শোহিদনগর"
    },
    {
       "_id":4562,
       "upazila_code":62,
       "union_code":72,
       "union_name":"Pura Para",
       "union_ban_name":"পুর পাড়া"
    },
    {
       "_id":4563,
       "upazila_code":62,
       "union_code":83,
       "union_name":"Ramnagar",
       "union_ban_name":"রামনগর"
    },
    {
       "_id":4564,
       "upazila_code":62,
       "union_code":94,
       "union_name":"Talma",
       "union_ban_name":"তালমা"
    },
    {
       "_id":4565,
       "upazila_code":84,
       "union_code":15,
       "union_name":"Akter Char",
       "union_ban_name":"আক্তার চর"
    },
    {
       "_id":4566,
       "upazila_code":84,
       "union_code":19,
       "union_name":"Bhashanchar",
       "union_ban_name":"ভাসানচর"
    },
    {
       "_id":4567,
       "upazila_code":84,
       "union_code":28,
       "union_name":"Char Bishnupur",
       "union_ban_name":"চর বিষ্ণুপুর"
    },
    {
       "_id":4568,
       "upazila_code":84,
       "union_code":38,
       "union_name":"Char Manair",
       "union_ban_name":"চর মনায়ার"
    },
    {
       "_id":4569,
       "upazila_code":84,
       "union_code":47,
       "union_name":"Char Nasirpur",
       "union_ban_name":"চর নাসিরপুর"
    },
    {
       "_id":4570,
       "upazila_code":84,
       "union_code":57,
       "union_name":"Dheukhali",
       "union_ban_name":"ধেউখালি"
    },
    {
       "_id":4571,
       "upazila_code":84,
       "union_code":66,
       "union_name":"Krishnapur",
       "union_ban_name":"কৃষ্ণপুর"
    },
    {
       "_id":4572,
       "upazila_code":84,
       "union_code":76,
       "union_name":"Narikelbaria",
       "union_ban_name":"নারিকেলবাড়িয়া"
    },
    {
       "_id":4573,
       "upazila_code":84,
       "union_code":85,
       "union_name":"Sadarpur",
       "union_ban_name":"সদরপুর"
    },
    {
       "_id":4574,
       "upazila_code":90,
       "union_code":10,
       "union_name":"Atghar",
       "union_ban_name":"আটঘর"
    },
    {
       "_id":4575,
       "upazila_code":90,
       "union_code":11,
       "union_name":"Ballabhdi",
       "union_ban_name":"বল্লভদী"
    },
    {
       "_id":4576,
       "upazila_code":90,
       "union_code":16,
       "union_name":"Bhawal",
       "union_ban_name":"ভাওয়াল"
    },
    {
       "_id":4577,
       "upazila_code":90,
       "union_code":39,
       "union_name":"Gatti",
       "union_ban_name":"গাট্টি"
    },
    {
       "_id":4578,
       "upazila_code":90,
       "union_code":44,
       "union_name":"Jadunandi",
       "union_ban_name":"যাদুনান্দি"
    },
    {
       "_id":4579,
       "upazila_code":90,
       "union_code":61,
       "union_name":"Majhardia",
       "union_ban_name":"মাজারদিয়া"
    },
    {
       "_id":4580,
       "upazila_code":90,
       "union_code":78,
       "union_name":"Ramkantapur",
       "union_ban_name":"রামকান্তপুর"
    },
    {
       "_id":4582,
       "upazila_code":14,
       "union_code":47,
       "union_name":"Gopal",
       "union_ban_name":"গোপাল"
    },
    {
       "_id":4583,
       "upazila_code":14,
       "union_code":57,
       "union_name":"Mohamaya",
       "union_ban_name":"মহামায়া"
    },
    {
       "_id":4584,
       "upazila_code":14,
       "union_code":76,
       "union_name":"Pathannagar",
       "union_ban_name":"পাঠানগর"
    },
    {
       "_id":4586,
       "upazila_code":14,
       "union_code":95,
       "union_name":"Subhapur",
       "union_ban_name":"সুভাপুর"
    },
    {
       "_id":4587,
       "upazila_code":25,
       "union_code":12,
       "union_name":"Daganbhuiyan",
       "union_ban_name":"দাগনভূঁইয়া"
    },
    {
       "_id":4588,
       "upazila_code":25,
       "union_code":38,
       "union_name":"Jailashkara",
       "union_ban_name":"জৈলশকারা"
    },
    {
       "_id":4589,
       "upazila_code":25,
       "union_code":56,
       "union_name":"Mathu Bhuiyan",
       "union_ban_name":"মাথু ভূঁইয়া"
    },
    {
       "_id":4590,
       "upazila_code":25,
       "union_code":69,
       "union_name":"Purba Chandrapur",
       "union_ban_name":"পূর্ব চন্দ্রপুর"
    },
    {
       "_id":4593,
       "upazila_code":25,
       "union_code":90,
       "union_name":"Sindurpur",
       "union_ban_name":"সিন্দুরপুর"
    },
    {
       "_id":4594,
       "upazila_code":25,
       "union_code":94,
       "union_name":"Yakubpur",
       "union_ban_name":"ইয়াকুবপুর"
    },
    {
       "_id":4595,
       "upazila_code":29,
       "union_code":20,
       "union_name":"Baligaon",
       "union_ban_name":"বালিগাঁও"
    },
    {
       "_id":4596,
       "upazila_code":29,
       "union_code":22,
       "union_name":"Dhalia",
       "union_ban_name":"ধালিয়া"
    },
    {
       "_id":4598,
       "upazila_code":29,
       "union_code":25,
       "union_name":"Farhadnagar",
       "union_ban_name":"ফরহাদনগর"
    },
    {
       "_id":4600,
       "upazila_code":29,
       "union_code":43,
       "union_name":"Kalidah",
       "union_ban_name":"কালিদাহ"
    },
    {
       "_id":4601,
       "upazila_code":29,
       "union_code":47,
       "union_name":"Kazirbag",
       "union_ban_name":"কাজিরবাগ"
    },
    {
       "_id":4602,
       "upazila_code":29,
       "union_code":51,
       "union_name":"Lemua",
       "union_ban_name":"লেমুয়া"
    },
    {
       "_id":4603,
       "upazila_code":29,
       "union_code":60,
       "union_name":"Matabi",
       "union_ban_name":"মাতবি"
    },
    {
       "_id":4604,
       "upazila_code":29,
       "union_code":64,
       "union_name":"Panchgachhiya",
       "union_ban_name":"পাঁচগাছিয়া"
    },
    {
       "_id":4605,
       "upazila_code":29,
       "union_code":82,
       "union_name":"Sanua",
       "union_ban_name":"সানুয়া"
    },
    {
       "_id":4606,
       "upazila_code":29,
       "union_code":86,
       "union_name":"Sharshadi",
       "union_ban_name":"শার্শাদি"
    },
    {
       "_id":4607,
       "upazila_code":41,
       "union_code":13,
       "union_name":"Amjadhat",
       "union_ban_name":"আমজাদহাট"
    },
    {
       "_id":4608,
       "upazila_code":41,
       "union_code":27,
       "union_name":"Anandapur",
       "union_ban_name":"আনন্দপুর"
    },
    {
       "_id":4609,
       "upazila_code":41,
       "union_code":54,
       "union_name":"Darbarpur",
       "union_ban_name":"দরবারপুর"
    },
    {
       "_id":4610,
       "upazila_code":41,
       "union_code":67,
       "union_name":"Fulgazi",
       "union_ban_name":"ফুলগাজী"
    },
    {
       "_id":4611,
       "upazila_code":41,
       "union_code":74,
       "union_name":"G.M.Hat",
       "union_ban_name":"জি.এম.হ্যাট"
    },
    {
       "_id":4613,
       "upazila_code":51,
       "union_code":23,
       "union_name":"Chithalia",
       "union_ban_name":"চিথালিয়া"
    },
    {
       "_id":4614,
       "upazila_code":51,
       "union_code":40,
       "union_name":"Baksh Mohammad",
       "union_ban_name":"বকশ মো"
    },
    {
       "_id":4615,
       "upazila_code":51,
       "union_code":47,
       "union_name":"Mirzanagar",
       "union_ban_name":"মির্জানগর"
    },
    {
       "_id":4617,
       "upazila_code":94,
       "union_code":19,
       "union_name":"Bagadana",
       "union_ban_name":"বাগডানা"
    },
    {
       "_id":4618,
       "upazila_code":94,
       "union_code":28,
       "union_name":"Char Chandia",
       "union_ban_name":"চর চান্দিয়া"
    },
    {
       "_id":4619,
       "upazila_code":94,
       "union_code":38,
       "union_name":"Char Darbesh",
       "union_ban_name":"চর দরবেশ"
    },
    {
       "_id":4620,
       "upazila_code":94,
       "union_code":47,
       "union_name":"Char Majlishpur",
       "union_ban_name":"চর মজলিশপুর"
    },
    {
       "_id":4621,
       "upazila_code":94,
       "union_code":57,
       "union_name":"Mangalkandi",
       "union_ban_name":"মঙ্গলকান্দি"
    },
    {
       "_id":4622,
       "upazila_code":94,
       "union_code":66,
       "union_name":"Matiganj",
       "union_ban_name":"মতিগঞ্জ"
    },
    {
       "_id":4623,
       "upazila_code":94,
       "union_code":76,
       "union_name":"Nawabpur",
       "union_ban_name":"নবাবপুর"
    },
    {
       "_id":4624,
       "upazila_code":94,
       "union_code":85,
       "union_name":"Sonagazi",
       "union_ban_name":"সোনাগাজী"
    },
    {
       "_id":4625,
       "upazila_code":21,
       "union_code":11,
       "union_name":"Erendabari",
       "union_ban_name":"এরেনদাবাড়ি"
    },
    {
       "_id":4626,
       "upazila_code":21,
       "union_code":23,
       "union_name":"Fazlupur",
       "union_ban_name":"ফজলুপুর"
    },
    {
       "_id":4627,
       "upazila_code":21,
       "union_code":35,
       "union_name":"Fulchhari",
       "union_ban_name":"ফুলছড়ি"
    },
    {
       "_id":4628,
       "upazila_code":21,
       "union_code":47,
       "union_name":"Gazaria",
       "union_ban_name":"গজারিয়া"
    },
    {
       "_id":4629,
       "upazila_code":21,
       "union_code":59,
       "union_name":"Kanchi Para",
       "union_ban_name":"কাঁচি পাড়া"
    },
    {
       "_id":4630,
       "upazila_code":21,
       "union_code":71,
       "union_name":"Udakhali",
       "union_ban_name":"উদাখালী"
    },
    {
       "_id":4631,
       "upazila_code":21,
       "union_code":83,
       "union_name":"Uria",
       "union_ban_name":"ইউরিয়া"
    },
    {
       "_id":4632,
       "upazila_code":24,
       "union_code":12,
       "union_name":"Badiakhali",
       "union_ban_name":"বদিয়াখালী"
    },
    {
       "_id":4633,
       "upazila_code":24,
       "union_code":14,
       "union_name":"Ballamjhar",
       "union_ban_name":"বল্লামঝার"
    },
    {
       "_id":4634,
       "upazila_code":24,
       "union_code":21,
       "union_name":"Boali",
       "union_ban_name":"বোয়ালি"
    },
    {
       "_id":4635,
       "upazila_code":24,
       "union_code":36,
       "union_name":"Ghagoa",
       "union_ban_name":"ঘাগোয়া"
    },
    {
       "_id":4636,
       "upazila_code":24,
       "union_code":43,
       "union_name":"Gidari",
       "union_ban_name":"গিদারী"
    },
    {
       "_id":4637,
       "upazila_code":24,
       "union_code":51,
       "union_name":"Kamarjani",
       "union_ban_name":"কামারজানি"
    },
    {
       "_id":4638,
       "upazila_code":24,
       "union_code":58,
       "union_name":"Kholahati",
       "union_ban_name":"খোলাহাটি"
    },
    {
       "_id":4639,
       "upazila_code":24,
       "union_code":65,
       "union_name":"Kuptala",
       "union_ban_name":"কুপতলা"
    },
    {
       "_id":4641,
       "upazila_code":24,
       "union_code":80,
       "union_name":"Malibari",
       "union_ban_name":"মালিবাড়ি"
    },
    {
       "_id":4642,
       "upazila_code":24,
       "union_code":85,
       "union_name":"Mollar Char",
       "union_ban_name":"মোলার চর"
    },
    {
       "_id":4644,
       "upazila_code":24,
       "union_code":94,
       "union_name":"Saha Para",
       "union_ban_name":"সাহা পাড়া"
    },
    {
       "_id":4645,
       "upazila_code":30,
       "union_code":10,
       "union_name":"Darbasta",
       "union_ban_name":"দরবাস্তা"
    },
    {
       "_id":4647,
       "upazila_code":30,
       "union_code":16,
       "union_name":"Gumaniganj",
       "union_ban_name":"গুমানীগঞ্জ"
    },
    {
       "_id":4649,
       "upazila_code":30,
       "union_code":27,
       "union_name":"Kamardaha",
       "union_ban_name":"কামারদহ"
    },
    {
       "_id":4650,
       "upazila_code":30,
       "union_code":33,
       "union_name":"Kamdia",
       "union_ban_name":"কামদিয়া"
    },
    {
       "_id":4651,
       "upazila_code":30,
       "union_code":39,
       "union_name":"Katabari",
       "union_ban_name":"কাটাবাড়ি"
    },
    {
       "_id":4652,
       "upazila_code":30,
       "union_code":44,
       "union_name":"Kochasahar",
       "union_ban_name":"কোচসাহার"
    },
    {
       "_id":4653,
       "upazila_code":30,
       "union_code":50,
       "union_name":"Mahimaganj",
       "union_ban_name":"মহিমাগঞ্জ"
    },
    {
       "_id":4654,
       "upazila_code":30,
       "union_code":55,
       "union_name":"Nakai",
       "union_ban_name":"নাকাই"
    },
    {
       "_id":4655,
       "upazila_code":30,
       "union_code":61,
       "union_name":"Rajahar",
       "union_ban_name":"রাজাহার"
    },
    {
       "_id":4656,
       "upazila_code":30,
       "union_code":67,
       "union_name":"Rakhal Buruz",
       "union_ban_name":"রাখাল বুরুজ"
    },
    {
       "_id":4657,
       "upazila_code":30,
       "union_code":72,
       "union_name":"Shalmara",
       "union_ban_name":"শালমারা"
    },
    {
       "_id":4658,
       "upazila_code":30,
       "union_code":78,
       "union_name":"Sapmara",
       "union_ban_name":"সাপমারা"
    },
    {
       "_id":4659,
       "upazila_code":30,
       "union_code":83,
       "union_name":"Shakhahar",
       "union_ban_name":"শখাহার"
    },
    {
       "_id":4661,
       "upazila_code":30,
       "union_code":94,
       "union_name":"Taluk Kanupur",
       "union_ban_name":"তালুক কানুপুর"
    },
    {
       "_id":4662,
       "upazila_code":67,
       "union_code":13,
       "union_name":"Barisal",
       "union_ban_name":"বরিশাল"
    },
    {
       "_id":4663,
       "upazila_code":67,
       "union_code":19,
       "union_name":"Betkapa",
       "union_ban_name":"বেতকাপা"
    },
    {
       "_id":4665,
       "upazila_code":67,
       "union_code":38,
       "union_name":"Hossainpur",
       "union_ban_name":"হোসেনপুর"
    },
    {
       "_id":4666,
       "upazila_code":67,
       "union_code":47,
       "union_name":"Kishoregari",
       "union_ban_name":"কিশোরগরী"
    },
    {
       "_id":4667,
       "upazila_code":67,
       "union_code":57,
       "union_name":"Mohadipur",
       "union_ban_name":"মহাদিপুর"
    },
    {
       "_id":4668,
       "upazila_code":67,
       "union_code":66,
       "union_name":"Manoharpur",
       "union_ban_name":"মনোহরপুর"
    },
    {
       "_id":4669,
       "upazila_code":67,
       "union_code":76,
       "union_name":"Pabnapur",
       "union_ban_name":"পাবনাপুর"
    },
    {
       "_id":4673,
       "upazila_code":82,
       "union_code":25,
       "union_name":"Damodarpur",
       "union_ban_name":"দামোদরপুর"
    },
    {
       "_id":4674,
       "upazila_code":82,
       "union_code":34,
       "union_name":"Dhaperhat",
       "union_ban_name":"ধাপেরহাট"
    },
    {
       "_id":4676,
       "upazila_code":82,
       "union_code":51,
       "union_name":"Idilpur",
       "union_ban_name":"আইডিলপুর"
    },
    {
       "_id":4677,
       "upazila_code":82,
       "union_code":60,
       "union_name":"Jamalpur",
       "union_ban_name":"জামালপুর"
    },
    {
       "_id":4678,
       "upazila_code":82,
       "union_code":69,
       "union_name":"Khurda Kamarpur",
       "union_ban_name":"খুরদা কামরপুর"
    },
    {
       "_id":4679,
       "upazila_code":82,
       "union_code":77,
       "union_name":"Kamar Para'",
       "union_ban_name":"কামার পারা '"
    },
    {
       "_id":4680,
       "upazila_code":82,
       "union_code":86,
       "union_name":"Naldanga'",
       "union_ban_name":"নলডাঙ্গা '"
    },
    {
       "_id":4682,
       "upazila_code":88,
       "union_code":13,
       "union_name":"Bhartkhali",
       "union_ban_name":"ভরতখালী"
    },
    {
       "_id":4683,
       "upazila_code":88,
       "union_code":19,
       "union_name":"Bonar Para",
       "union_ban_name":"বোনার প্যারা"
    },
    {
       "_id":4684,
       "upazila_code":88,
       "union_code":28,
       "union_name":"Ghuridaha",
       "union_ban_name":"ঘুরিদাহা"
    },
    {
       "_id":4686,
       "upazila_code":88,
       "union_code":47,
       "union_name":"Jummerbari",
       "union_ban_name":"জুমমারবাড়ি"
    },
    {
       "_id":4688,
       "upazila_code":88,
       "union_code":66,
       "union_name":"Kamaler Para",
       "union_ban_name":"কামালের পারা"
    },
    {
       "_id":4689,
       "upazila_code":88,
       "union_code":76,
       "union_name":"Padumsahar",
       "union_ban_name":"পদুমসাহার"
    },
    {
       "_id":4690,
       "upazila_code":88,
       "union_code":85,
       "union_name":"Saghata",
       "union_ban_name":"সাঘাটা"
    },
    {
       "_id":4691,
       "upazila_code":88,
       "union_code":95,
       "union_name":"Muktanagar",
       "union_ban_name":"মুক্তনগর"
    },
    {
       "_id":4692,
       "upazila_code":91,
       "union_code":11,
       "union_name":"Bamandanga",
       "union_ban_name":"বামনডাঙ্গা"
    },
    {
       "_id":4693,
       "upazila_code":91,
       "union_code":12,
       "union_name":"Belka",
       "union_ban_name":"বেলকা"
    },
    {
       "_id":4695,
       "upazila_code":91,
       "union_code":25,
       "union_name":"Chhaparhati",
       "union_ban_name":"ছফারহাটি"
    },
    {
       "_id":4696,
       "upazila_code":91,
       "union_code":31,
       "union_name":"Dahabanda",
       "union_ban_name":"দহবান্দা"
    },
    {
       "_id":4697,
       "upazila_code":91,
       "union_code":37,
       "union_name":"Dhopadanga",
       "union_ban_name":"ধোপাডাঙ্গা"
    },
    {
       "_id":4699,
       "upazila_code":91,
       "union_code":50,
       "union_name":"Kanchibari",
       "union_ban_name":"কাঁচিবাড়ি"
    },
    {
       "_id":4700,
       "upazila_code":91,
       "union_code":56,
       "union_name":"Kapasia",
       "union_ban_name":"কাপাসিয়া"
    },
    {
       "_id":4701,
       "upazila_code":91,
       "union_code":63,
       "union_name":"Ramjiban",
       "union_ban_name":"রামজীবন"
    },
    {
       "_id":4702,
       "upazila_code":91,
       "union_code":69,
       "union_name":"Shantiram",
       "union_ban_name":"শান্তিরাম"
    },
    {
       "_id":4703,
       "upazila_code":91,
       "union_code":75,
       "union_name":"Sarbananda",
       "union_ban_name":"সর্বানন্দ"
    },
    {
       "_id":4704,
       "upazila_code":91,
       "union_code":82,
       "union_name":"Sonaroy",
       "union_ban_name":"সোনারয়"
    },
    {
       "_id":4706,
       "upazila_code":91,
       "union_code":94,
       "union_name":"Tarapur",
       "union_ban_name":"তারাপুর"
    },
    {
       "_id":4707,
       "upazila_code":30,
       "union_code":23,
       "union_name":"Basan",
       "union_ban_name":"বসান"
    },
    {
       "_id":4708,
       "upazila_code":30,
       "union_code":25,
       "union_name":"Baria",
       "union_ban_name":"বারিয়া"
    },
    {
       "_id":4709,
       "upazila_code":30,
       "union_code":31,
       "union_name":"Gachha",
       "union_ban_name":"গছা"
    },
    {
       "_id":4710,
       "upazila_code":30,
       "union_code":47,
       "union_name":"Kashimpur",
       "union_ban_name":"কাশিমপুর"
    },
    {
       "_id":4711,
       "upazila_code":30,
       "union_code":54,
       "union_name":"Kayaltia",
       "union_ban_name":"কায়ালটিয়া"
    },
    {
       "_id":4712,
       "upazila_code":30,
       "union_code":60,
       "union_name":"Konabari",
       "union_ban_name":"কোনাবাড়ি"
    },
    {
       "_id":4714,
       "upazila_code":30,
       "union_code":81,
       "union_name":"Pubail",
       "union_ban_name":"প্রকাশ করা"
    },
    {
       "_id":4715,
       "upazila_code":32,
       "union_code":13,
       "union_name":"Atabaha",
       "union_ban_name":"আটাবহ"
    },
    {
       "_id":4717,
       "upazila_code":32,
       "union_code":28,
       "union_name":"Chapair",
       "union_ban_name":"চ্যাপেইয়ার"
    },
    {
       "_id":4718,
       "upazila_code":32,
       "union_code":38,
       "union_name":"Dhaljora",
       "union_ban_name":"ধলজোড়া"
    },
    {
       "_id":4719,
       "upazila_code":32,
       "union_code":47,
       "union_name":"Fulbaria",
       "union_ban_name":"ফুলবাড়িয়া"
    },
    {
       "_id":4720,
       "upazila_code":32,
       "union_code":57,
       "union_name":"Madhyapara",
       "union_ban_name":"মধ্যপাড়া"
    },
    {
       "_id":4721,
       "upazila_code":32,
       "union_code":66,
       "union_name":"Mouchak",
       "union_ban_name":"মৌচাক"
    },
    {
       "_id":4722,
       "upazila_code":32,
       "union_code":76,
       "union_name":"Sreefaltali",
       "union_ban_name":"শ্রীফালতলী"
    },
    {
       "_id":4723,
       "upazila_code":32,
       "union_code":85,
       "union_name":"Sutrapur",
       "union_ban_name":"সূত্রাপুর"
    },
    {
       "_id":4724,
       "upazila_code":34,
       "union_code":15,
       "union_name":"Bahadursadi",
       "union_ban_name":"বাহাদুরসাদি"
    },
    {
       "_id":4725,
       "upazila_code":34,
       "union_code":17,
       "union_name":"Baktarpur",
       "union_ban_name":"বক্তারপুর"
    },
    {
       "_id":4728,
       "upazila_code":34,
       "union_code":94,
       "union_name":"Moktarpur",
       "union_ban_name":"মকতারপুর"
    },
    {
       "_id":4729,
       "upazila_code":34,
       "union_code":96,
       "union_name":"Nagari",
       "union_ban_name":"নগরী"
    },
    {
       "_id":4730,
       "upazila_code":34,
       "union_code":97,
       "union_name":"Tumulia",
       "union_ban_name":"টুমুলিয়া"
    },
    {
       "_id":4731,
       "upazila_code":36,
       "union_code":13,
       "union_name":"Barishaba",
       "union_ban_name":"বরিশাবা"
    },
    {
       "_id":4734,
       "upazila_code":36,
       "union_code":34,
       "union_name":"Ghagotia",
       "union_ban_name":"ঘাগোটিয়া"
    },
    {
       "_id":4736,
       "upazila_code":36,
       "union_code":51,
       "union_name":"Karihata",
       "union_ban_name":"করিহাতা"
    },
    {
       "_id":4737,
       "upazila_code":36,
       "union_code":60,
       "union_name":"Rayed",
       "union_ban_name":"রেয়েড"
    },
    {
       "_id":4738,
       "upazila_code":36,
       "union_code":69,
       "union_name":"Sanmania",
       "union_ban_name":"সানমানিয়া"
    },
    {
       "_id":4739,
       "upazila_code":36,
       "union_code":77,
       "union_name":"Singasree",
       "union_ban_name":"সিঙ্গাস্রী"
    },
    {
       "_id":4741,
       "upazila_code":36,
       "union_code":94,
       "union_name":"Toke",
       "union_ban_name":"টোক"
    },
    {
       "_id":4742,
       "upazila_code":86,
       "union_code":21,
       "union_name":"Barmi",
       "union_ban_name":"বার্মি"
    },
    {
       "_id":4744,
       "upazila_code":86,
       "union_code":28,
       "union_name":"Gosinga",
       "union_ban_name":"গোসিংগা"
    },
    {
       "_id":4745,
       "upazila_code":86,
       "union_code":38,
       "union_name":"Kaoraid",
       "union_ban_name":"কওরাইদ"
    },
    {
       "_id":4746,
       "upazila_code":86,
       "union_code":47,
       "union_name":"Maona",
       "union_ban_name":"মাওনা"
    },
    {
       "_id":4747,
       "upazila_code":86,
       "union_code":57,
       "union_name":"Prahladpur",
       "union_ban_name":"প্রহলাদপুর"
    },
    {
       "_id":4748,
       "upazila_code":86,
       "union_code":66,
       "union_name":"Rajabari",
       "union_ban_name":"রাজাবাড়ী"
    },
    {
       "_id":4749,
       "upazila_code":86,
       "union_code":85,
       "union_name":"Telihati",
       "union_ban_name":"তেলিহাটি"
    },
    {
       "_id":4750,
       "upazila_code":32,
       "union_code":11,
       "union_name":"Borasi",
       "union_ban_name":"বোরসি"
    },
    {
       "_id":4751,
       "upazila_code":32,
       "union_code":13,
       "union_name":"Baultali",
       "union_ban_name":"বাউলতালি"
    },
    {
       "_id":4752,
       "upazila_code":32,
       "union_code":15,
       "union_name":"Chandra Dighalia",
       "union_ban_name":"চন্দ্র দিঘালিয়া"
    },
    {
       "_id":4754,
       "upazila_code":32,
       "union_code":21,
       "union_name":"Gobra",
       "union_ban_name":"গোব্রা"
    },
    {
       "_id":4756,
       "upazila_code":32,
       "union_code":34,
       "union_name":"Haridaspur",
       "union_ban_name":"হরিদাসপুর"
    },
    {
       "_id":4758,
       "upazila_code":32,
       "union_code":43,
       "union_name":"Kajulia",
       "union_ban_name":"কজুলিয়া"
    },
    {
       "_id":4759,
       "upazila_code":32,
       "union_code":47,
       "union_name":"Karpara",
       "union_ban_name":"করপাড়া"
    },
    {
       "_id":4760,
       "upazila_code":32,
       "union_code":51,
       "union_name":"Kati",
       "union_ban_name":"কাতি"
    },
    {
       "_id":4761,
       "upazila_code":32,
       "union_code":56,
       "union_name":"Latifpur",
       "union_ban_name":"লতিফপুর"
    },
    {
       "_id":4762,
       "upazila_code":32,
       "union_code":60,
       "union_name":"Majhigati",
       "union_ban_name":"মজহীটি"
    },
    {
       "_id":4763,
       "upazila_code":32,
       "union_code":64,
       "union_name":"Nizra",
       "union_ban_name":"নিজরা"
    },
    {
       "_id":4764,
       "upazila_code":32,
       "union_code":69,
       "union_name":"Paikkandi",
       "union_ban_name":"পাইক্কান্দি"
    },
    {
       "_id":4765,
       "upazila_code":32,
       "union_code":73,
       "union_name":"Raghunathpur",
       "union_ban_name":"রঘুনাথপুর"
    },
    {
       "_id":4766,
       "upazila_code":32,
       "union_code":77,
       "union_name":"Sahapur",
       "union_ban_name":"সাহাপুর"
    },
    {
       "_id":4767,
       "upazila_code":32,
       "union_code":82,
       "union_name":"Satpar",
       "union_ban_name":"সাতপাড়"
    },
    {
       "_id":4768,
       "upazila_code":32,
       "union_code":86,
       "union_name":"Suktail",
       "union_ban_name":"সুকতাইল"
    },
    {
       "_id":4769,
       "upazila_code":32,
       "union_code":90,
       "union_name":"Ulpur",
       "union_ban_name":"উলপুর"
    },
    {
       "_id":4770,
       "upazila_code":32,
       "union_code":94,
       "union_name":"Urafi",
       "union_ban_name":"উরাফি"
    },
    {
       "_id":4771,
       "upazila_code":43,
       "union_code":11,
       "union_name":"Bethuri",
       "union_ban_name":"বেথুরি"
    },
    {
       "_id":4772,
       "upazila_code":43,
       "union_code":13,
       "union_name":"Fukura",
       "union_ban_name":"ফুকুরা"
    },
    {
       "_id":4773,
       "upazila_code":43,
       "union_code":20,
       "union_name":"Hatiara",
       "union_ban_name":"হাতিয়ারা"
    },
    {
       "_id":4774,
       "upazila_code":43,
       "union_code":27,
       "union_name":"Kashiani",
       "union_ban_name":"কাশিয়ানি"
    },
    {
       "_id":4775,
       "upazila_code":43,
       "union_code":33,
       "union_name":"Mamudpur",
       "union_ban_name":"মামুদপুর"
    },
    {
       "_id":4776,
       "upazila_code":43,
       "union_code":40,
       "union_name":"Maheshpur",
       "union_ban_name":"মহেশপুর"
    },
    {
       "_id":4777,
       "upazila_code":43,
       "union_code":47,
       "union_name":"Nijamkandi",
       "union_ban_name":"নিযমকান্দি"
    },
    {
       "_id":4778,
       "upazila_code":43,
       "union_code":54,
       "union_name":"Orakandi",
       "union_ban_name":"ওরাকান্দি"
    },
    {
       "_id":4779,
       "upazila_code":43,
       "union_code":61,
       "union_name":"Parulia",
       "union_ban_name":"পারুলিয়া"
    },
    {
       "_id":4780,
       "upazila_code":43,
       "union_code":67,
       "union_name":"Puisur",
       "union_ban_name":"পুইসুর"
    },
    {
       "_id":4781,
       "upazila_code":43,
       "union_code":74,
       "union_name":"Rajpat",
       "union_ban_name":"রাজপট"
    },
    {
       "_id":4782,
       "upazila_code":43,
       "union_code":81,
       "union_name":"Ratail",
       "union_ban_name":"রতাইল"
    },
    {
       "_id":4783,
       "upazila_code":43,
       "union_code":88,
       "union_name":"Sajail",
       "union_ban_name":"সাজেল"
    },
    {
       "_id":4784,
       "upazila_code":43,
       "union_code":94,
       "union_name":"Singa",
       "union_ban_name":"সিঙ্গা"
    },
    {
       "_id":4786,
       "upazila_code":51,
       "union_code":15,
       "union_name":"Bandhabari",
       "union_ban_name":"বান্ধবাড়ী"
    },
    {
       "_id":4787,
       "upazila_code":51,
       "union_code":23,
       "union_name":"Ghagar",
       "union_ban_name":"ঘগর"
    },
    {
       "_id":4788,
       "upazila_code":51,
       "union_code":31,
       "union_name":"Hiran",
       "union_ban_name":"হিরান"
    },
    {
       "_id":4789,
       "upazila_code":51,
       "union_code":39,
       "union_name":"Kalabari",
       "union_ban_name":"কালবাড়ি"
    },
    {
       "_id":4790,
       "upazila_code":51,
       "union_code":47,
       "union_name":"Kandi",
       "union_ban_name":"কান্দি"
    },
    {
       "_id":4791,
       "upazila_code":51,
       "union_code":55,
       "union_name":"Kushla",
       "union_ban_name":"কুশলা"
    },
    {
       "_id":4792,
       "upazila_code":51,
       "union_code":63,
       "union_name":"Pinjuri",
       "union_ban_name":"পিনজুরি"
    },
    {
       "_id":4793,
       "upazila_code":51,
       "union_code":71,
       "union_name":"Radhaganj",
       "union_ban_name":"রাধাগঞ্জ"
    },
    {
       "_id":4794,
       "upazila_code":51,
       "union_code":79,
       "union_name":"Ramshil",
       "union_ban_name":"রামশিল"
    },
    {
       "_id":4796,
       "upazila_code":51,
       "union_code":94,
       "union_name":"Suagram",
       "union_ban_name":"সুগ্রাম"
    },
    {
       "_id":4797,
       "upazila_code":58,
       "union_code":10,
       "union_name":"Bhabrasur",
       "union_ban_name":"ভব্রাসুর"
    },
    {
       "_id":4798,
       "upazila_code":58,
       "union_code":11,
       "union_name":"Bahugram",
       "union_ban_name":"বহুগ্রাম"
    },
    {
       "_id":4800,
       "upazila_code":58,
       "union_code":22,
       "union_name":"Batikamari",
       "union_ban_name":"বাটিকামারী"
    },
    {
       "_id":4801,
       "upazila_code":58,
       "union_code":27,
       "union_name":"Dignagar",
       "union_ban_name":"দীনগর"
    },
    {
       "_id":4803,
       "upazila_code":58,
       "union_code":39,
       "union_name":"Gohala",
       "union_ban_name":"গোহালা"
    },
    {
       "_id":4804,
       "upazila_code":58,
       "union_code":44,
       "union_name":"Jalirpar",
       "union_ban_name":"জালিরপাড়া"
    },
    {
       "_id":4805,
       "upazila_code":58,
       "union_code":50,
       "union_name":"Kasalia",
       "union_ban_name":"কাসালিয়া"
    },
    {
       "_id":4806,
       "upazila_code":58,
       "union_code":55,
       "union_name":"Khandarpar",
       "union_ban_name":"খন্দরপাড়া"
    },
    {
       "_id":4808,
       "upazila_code":58,
       "union_code":67,
       "union_name":"Mochna",
       "union_ban_name":"মোচনা"
    },
    {
       "_id":4809,
       "upazila_code":58,
       "union_code":72,
       "union_name":"Nanikshir",
       "union_ban_name":"নানিকশির"
    },
    {
       "_id":4810,
       "upazila_code":58,
       "union_code":78,
       "union_name":"Pasargati",
       "union_ban_name":"পাসারগাতি"
    },
    {
       "_id":4811,
       "upazila_code":58,
       "union_code":83,
       "union_name":"Raghdi",
       "union_ban_name":"রাঘদী"
    },
    {
       "_id":4812,
       "upazila_code":58,
       "union_code":94,
       "union_name":"Ujani",
       "union_ban_name":"উজানি"
    },
    {
       "_id":4813,
       "upazila_code":91,
       "union_code":19,
       "union_name":"Barni",
       "union_ban_name":"বার্নি"
    },
    {
       "_id":4814,
       "upazila_code":91,
       "union_code":28,
       "union_name":"Dumuria",
       "union_ban_name":"ডুমুরিয়া"
    },
    {
       "_id":4816,
       "upazila_code":91,
       "union_code":66,
       "union_name":"Kushli",
       "union_ban_name":"কুশলি"
    },
    {
       "_id":4817,
       "upazila_code":91,
       "union_code":76,
       "union_name":"Patgati Display Memory",
       "union_ban_name":"পটগতি প্রদর্শন মেমরি"
    },
    {
       "_id":4818,
       "upazila_code":2,
       "union_code":13,
       "union_name":"Ajmiriganj",
       "union_ban_name":"আজমিরিগঞ্জ"
    },
    {
       "_id":4819,
       "upazila_code":2,
       "union_code":27,
       "union_name":"Badalpur",
       "union_ban_name":"বাদলপুর"
    },
    {
       "_id":4820,
       "upazila_code":2,
       "union_code":54,
       "union_name":"Jalsuka",
       "union_ban_name":"জালুকা"
    },
    {
       "_id":4821,
       "upazila_code":2,
       "union_code":67,
       "union_name":"Kakailseo",
       "union_ban_name":"কাকাইলসিও"
    },
    {
       "_id":4822,
       "upazila_code":2,
       "union_code":81,
       "union_name":"Shibpasha",
       "union_ban_name":"শিবপাশা"
    },
    {
       "_id":4823,
       "upazila_code":5,
       "union_code":11,
       "union_name":"Bahubal",
       "union_ban_name":"বাহুবল"
    },
    {
       "_id":4824,
       "upazila_code":5,
       "union_code":23,
       "union_name":"Bhadeshwar",
       "union_ban_name":"ভাদেশ্বর"
    },
    {
       "_id":4825,
       "upazila_code":5,
       "union_code":35,
       "union_name":"Lamatashi",
       "union_ban_name":"লামতাশি"
    },
    {
       "_id":4826,
       "upazila_code":5,
       "union_code":47,
       "union_name":"Mirpur",
       "union_ban_name":"মিরপুর"
    },
    {
       "_id":4827,
       "upazila_code":5,
       "union_code":59,
       "union_name":"Putijuri",
       "union_ban_name":"পুটিজুরি"
    },
    {
       "_id":4828,
       "upazila_code":5,
       "union_code":71,
       "union_name":"Satkapan",
       "union_ban_name":"সাতকাপান"
    },
    {
       "_id":4829,
       "upazila_code":5,
       "union_code":83,
       "union_name":"Snanghat",
       "union_ban_name":"স্নানঘাট"
    },
    {
       "_id":4830,
       "upazila_code":11,
       "union_code":11,
       "union_name":"Uttar Purba Baniachang",
       "union_ban_name":"উত্তর পূর্ব বানিয়াচং"
    },
    {
       "_id":4831,
       "upazila_code":11,
       "union_code":18,
       "union_name":"Dakshin Purba Baniyachang",
       "union_ban_name":"দক্ষিণ পূর্ব বানিয়াচং"
    },
    {
       "_id":4832,
       "upazila_code":11,
       "union_code":25,
       "union_name":"Dakshin Paschim Baniyachang",
       "union_ban_name":"দক্ষিণ পশিম বানিয়াচং"
    },
    {
       "_id":4833,
       "upazila_code":11,
       "union_code":31,
       "union_name":"Baraiuri",
       "union_ban_name":"বড়ইউড়ি"
    },
    {
       "_id":4835,
       "upazila_code":11,
       "union_code":44,
       "union_name":"Kagapasha",
       "union_ban_name":"কাগপাশা"
    },
    {
       "_id":4836,
       "upazila_code":11,
       "union_code":50,
       "union_name":"Khagaura",
       "union_ban_name":"খগৌড়া"
    },
    {
       "_id":4837,
       "upazila_code":11,
       "union_code":56,
       "union_name":"Makrampur",
       "union_ban_name":"মাকরামপুর"
    },
    {
       "_id":4838,
       "upazila_code":11,
       "union_code":63,
       "union_name":"Mandari",
       "union_ban_name":"মন্দারী"
    },
    {
       "_id":4840,
       "upazila_code":11,
       "union_code":75,
       "union_name":"Pailarkandi",
       "union_ban_name":"পাইলারকান্দি"
    },
    {
       "_id":4841,
       "upazila_code":11,
       "union_code":82,
       "union_name":"Pukhra",
       "union_ban_name":"পুখরা"
    },
    {
       "_id":4842,
       "upazila_code":11,
       "union_code":88,
       "union_name":"Sujatpur",
       "union_ban_name":"সুজাতপুর"
    },
    {
       "_id":4843,
       "upazila_code":11,
       "union_code":94,
       "union_name":"Subidpur",
       "union_ban_name":"সুবিদপুর"
    },
    {
       "_id":4844,
       "upazila_code":26,
       "union_code":13,
       "union_name":"Ahmadabad",
       "union_ban_name":"আহমদাবাদ"
    },
    {
       "_id":4845,
       "upazila_code":26,
       "union_code":19,
       "union_name":"Chunarughat",
       "union_ban_name":"চুনারুঘাট"
    },
    {
       "_id":4846,
       "upazila_code":26,
       "union_code":28,
       "union_name":"Deorgachh",
       "union_ban_name":"দেওরগাছ"
    },
    {
       "_id":4848,
       "upazila_code":26,
       "union_code":47,
       "union_name":"Mirahi",
       "union_ban_name":"মিরাহি"
    },
    {
       "_id":4849,
       "upazila_code":26,
       "union_code":57,
       "union_name":"Paik Para",
       "union_ban_name":"পিক প্যারা"
    },
    {
       "_id":4850,
       "upazila_code":26,
       "union_code":66,
       "union_name":"Ranigaon",
       "union_ban_name":"রণগাঁও"
    },
    {
       "_id":4851,
       "upazila_code":26,
       "union_code":76,
       "union_name":"Sankhola",
       "union_ban_name":"শঙ্কল"
    },
    {
       "_id":4852,
       "upazila_code":26,
       "union_code":85,
       "union_name":"Shatiajuri",
       "union_ban_name":"শাতিয়াজুরি"
    },
    {
       "_id":4853,
       "upazila_code":26,
       "union_code":95,
       "union_name":"Ubahata",
       "union_ban_name":"উবাহাতা"
    },
    {
       "_id":4854,
       "upazila_code":44,
       "union_code":22,
       "union_name":"Gopaya",
       "union_ban_name":"গোপায়"
    },
    {
       "_id":4855,
       "upazila_code":44,
       "union_code":24,
       "union_name":"Laskarpur",
       "union_ban_name":"লস্করপুর"
    },
    {
       "_id":4856,
       "upazila_code":44,
       "union_code":28,
       "union_name":"Lukhra",
       "union_ban_name":"লুখরা"
    },
    {
       "_id":4858,
       "upazila_code":44,
       "union_code":47,
       "union_name":"Nurpur",
       "union_ban_name":"নুরপুর"
    },
    {
       "_id":4859,
       "upazila_code":44,
       "union_code":57,
       "union_name":"Poil",
       "union_ban_name":"পোয়েল"
    },
    {
       "_id":4860,
       "upazila_code":44,
       "union_code":66,
       "union_name":"Raziura",
       "union_ban_name":"রাজিউরা"
    },
    {
       "_id":4861,
       "upazila_code":44,
       "union_code":76,
       "union_name":"Richi",
       "union_ban_name":"রিচি"
    },
    {
       "_id":4862,
       "upazila_code":44,
       "union_code":85,
       "union_name":"Saistaganj",
       "union_ban_name":"সায়স্তাগঞ্জ"
    },
    {
       "_id":4864,
       "upazila_code":68,
       "union_code":13,
       "union_name":"Bamai",
       "union_ban_name":"বামাই"
    },
    {
       "_id":4865,
       "upazila_code":68,
       "union_code":27,
       "union_name":"Bulla",
       "union_ban_name":"গোলমাল"
    },
    {
       "_id":4866,
       "upazila_code":68,
       "union_code":40,
       "union_name":"Karab",
       "union_ban_name":"করব"
    },
    {
       "_id":4867,
       "upazila_code":68,
       "union_code":54,
       "union_name":"Lakhai",
       "union_ban_name":"লাখাই"
    },
    {
       "_id":4868,
       "upazila_code":68,
       "union_code":67,
       "union_name":"Murakari",
       "union_ban_name":"মুরাকারি"
    },
    {
       "_id":4869,
       "upazila_code":68,
       "union_code":81,
       "union_name":"Muriauk",
       "union_ban_name":"মুরিয়াক"
    },
    {
       "_id":4870,
       "upazila_code":71,
       "union_code":16,
       "union_name":"Adair",
       "union_ban_name":"আদায়র"
    },
    {
       "_id":4871,
       "upazila_code":71,
       "union_code":17,
       "union_name":"Bagasura",
       "union_ban_name":"বাগাসুর"
    },
    {
       "_id":4872,
       "upazila_code":71,
       "union_code":25,
       "union_name":"Bahara",
       "union_ban_name":"বাহারা"
    },
    {
       "_id":4874,
       "upazila_code":71,
       "union_code":43,
       "union_name":"Chhatiain",
       "union_ban_name":"ছাতিয়াঈন"
    },
    {
       "_id":4875,
       "upazila_code":71,
       "union_code":51,
       "union_name":"Chowmohani",
       "union_ban_name":"চৌমোহনী"
    },
    {
       "_id":4876,
       "upazila_code":71,
       "union_code":60,
       "union_name":"Dharmaghar",
       "union_ban_name":"ধর্মঘর"
    },
    {
       "_id":4877,
       "upazila_code":71,
       "union_code":69,
       "union_name":"Jagadishpur",
       "union_ban_name":"জগদীশপুর"
    },
    {
       "_id":4878,
       "upazila_code":71,
       "union_code":77,
       "union_name":"Andiurauk",
       "union_ban_name":"ওউরউউক"
    },
    {
       "_id":4881,
       "upazila_code":77,
       "union_code":13,
       "union_name":"Auskandi",
       "union_ban_name":"আউসকান্দি"
    },
    {
       "_id":4882,
       "upazila_code":77,
       "union_code":14,
       "union_name":"Bausha",
       "union_ban_name":"বাউশা"
    },
    {
       "_id":4883,
       "upazila_code":77,
       "union_code":21,
       "union_name":"Debpara",
       "union_ban_name":"দেবপাড়া"
    },
    {
       "_id":4884,
       "upazila_code":77,
       "union_code":29,
       "union_name":"Dighalbak",
       "union_ban_name":"দিঘালবাক"
    },
    {
       "_id":4885,
       "upazila_code":77,
       "union_code":43,
       "union_name":"Gaznapur",
       "union_ban_name":"গাজ্নাপুর"
    },
    {
       "_id":4886,
       "upazila_code":77,
       "union_code":51,
       "union_name":"Inathganj",
       "union_ban_name":"ইনথগঞ্জ"
    },
    {
       "_id":4887,
       "upazila_code":77,
       "union_code":58,
       "union_name":"Kalair Banga",
       "union_ban_name":"কালেয়ার বঙ্গা"
    },
    {
       "_id":4888,
       "upazila_code":77,
       "union_code":65,
       "union_name":"Kargaon",
       "union_ban_name":"কারগাঁও"
    },
    {
       "_id":4889,
       "upazila_code":77,
       "union_code":73,
       "union_name":"Kurshi",
       "union_ban_name":"কুর্শি"
    },
    {
       "_id":4890,
       "upazila_code":77,
       "union_code":80,
       "union_name":"Nabiganj",
       "union_ban_name":"নাবিগঞ্জ"
    },
    {
       "_id":4891,
       "upazila_code":77,
       "union_code":87,
       "union_name":"Paniunda",
       "union_ban_name":"প্যানুন্ডা"
    },
    {
       "_id":4892,
       "upazila_code":77,
       "union_code":90,
       "union_name":"Purba Bara Bakhair",
       "union_ban_name":"পূর্বা বার বখর"
    },
    {
       "_id":4893,
       "upazila_code":77,
       "union_code":94,
       "union_name":"Paschim Bara Bhakhair",
       "union_ban_name":"পাসচিম বড় ভখায়ের"
    },
    {
       "_id":4894,
       "upazila_code":7,
       "union_code":11,
       "union_name":"Bagar Char",
       "union_ban_name":"বাগর চর"
    },
    {
       "_id":4895,
       "upazila_code":7,
       "union_code":23,
       "union_name":"Bakshiganj",
       "union_ban_name":"বকশীগঞ্জ"
    },
    {
       "_id":4896,
       "upazila_code":7,
       "union_code":35,
       "union_name":"Battajore",
       "union_ban_name":"বাট্টাজোর"
    },
    {
       "_id":4897,
       "upazila_code":7,
       "union_code":47,
       "union_name":"Dhanua",
       "union_ban_name":"ধনুয়া"
    },
    {
       "_id":4898,
       "upazila_code":7,
       "union_code":59,
       "union_name":"Merur Char",
       "union_ban_name":"মেরুর চর"
    },
    {
       "_id":4899,
       "upazila_code":7,
       "union_code":71,
       "union_name":"Nilakshmia",
       "union_ban_name":"নীলক্ষ্মিয়া"
    },
    {
       "_id":4900,
       "upazila_code":7,
       "union_code":83,
       "union_name":"Sadhur Para",
       "union_ban_name":"সাধুর তো"
    },
    {
       "_id":4901,
       "upazila_code":15,
       "union_code":17,
       "union_name":"Bahadurabad",
       "union_ban_name":"বাহাদুরাবাদ"
    },
    {
       "_id":4902,
       "upazila_code":15,
       "union_code":29,
       "union_name":"Char Aomkhaoa",
       "union_ban_name":"চর আমখোয়া"
    },
    {
       "_id":4903,
       "upazila_code":15,
       "union_code":36,
       "union_name":"Chikajani",
       "union_ban_name":"চিকজনি"
    },
    {
       "_id":4904,
       "upazila_code":15,
       "union_code":43,
       "union_name":"Chukaibari",
       "union_ban_name":"চুকাইবারি"
    },
    {
       "_id":4905,
       "upazila_code":15,
       "union_code":51,
       "union_name":"Dangdhara",
       "union_ban_name":"ডাঙধারা"
    },
    {
       "_id":4906,
       "upazila_code":15,
       "union_code":58,
       "union_name":"Dewanganj",
       "union_ban_name":"দেওয়ানগঞ্জ"
    },
    {
       "_id":4907,
       "upazila_code":15,
       "union_code":65,
       "union_name":"Hatebhanga",
       "union_ban_name":"হাটভাঙ্গা"
    },
    {
       "_id":4908,
       "upazila_code":15,
       "union_code":87,
       "union_name":"Par Ramrampur",
       "union_ban_name":"পার রামরামপুর"
    },
    {
       "_id":4909,
       "upazila_code":29,
       "union_code":13,
       "union_name":"Belgachha",
       "union_ban_name":"বেলগাছা"
    },
    {
       "_id":4910,
       "upazila_code":29,
       "union_code":15,
       "union_name":"Char Goalini",
       "union_ban_name":"চর গোয়ালিনী"
    },
    {
       "_id":4911,
       "upazila_code":29,
       "union_code":23,
       "union_name":"Char Putimari",
       "union_ban_name":"চর পুটিমারি"
    },
    {
       "_id":4912,
       "upazila_code":29,
       "union_code":31,
       "union_name":"Chinadulli",
       "union_ban_name":"চায়নাদুল্লি"
    },
    {
       "_id":4913,
       "upazila_code":29,
       "union_code":39,
       "union_name":"Gaibandha",
       "union_ban_name":"গাইবান্ধা"
    },
    {
       "_id":4914,
       "upazila_code":29,
       "union_code":47,
       "union_name":"Goaler Char",
       "union_ban_name":"গোলরক্ষক গৃহস্থালি"
    },
    {
       "_id":4916,
       "upazila_code":29,
       "union_code":63,
       "union_name":"Kulkandi",
       "union_ban_name":"কুলকান্দি"
    },
    {
       "_id":4917,
       "upazila_code":29,
       "union_code":71,
       "union_name":"Noarpara",
       "union_ban_name":"নুরপাড়া"
    },
    {
       "_id":4918,
       "upazila_code":29,
       "union_code":79,
       "union_name":"Palbandha",
       "union_ban_name":"পালবন্ধাঃ"
    },
    {
       "_id":4919,
       "upazila_code":29,
       "union_code":87,
       "union_name":"Patharsi",
       "union_ban_name":"পাথরসী"
    },
    {
       "_id":4920,
       "upazila_code":29,
       "union_code":94,
       "union_name":"Sapdhari",
       "union_ban_name":"সাপদারী"
    },
    {
       "_id":4921,
       "upazila_code":36,
       "union_code":13,
       "union_name":"Banshchara",
       "union_ban_name":"বাঁশচারা"
    },
    {
       "_id":4922,
       "upazila_code":36,
       "union_code":17,
       "union_name":"Ghoradhap",
       "union_ban_name":"ঘোরধাপ"
    },
    {
       "_id":4923,
       "upazila_code":36,
       "union_code":19,
       "union_name":"Digpaith",
       "union_ban_name":"ডিগপাইথ"
    },
    {
       "_id":4924,
       "upazila_code":36,
       "union_code":23,
       "union_name":"Itail",
       "union_ban_name":"ইটাইল"
    },
    {
       "_id":4925,
       "upazila_code":36,
       "union_code":35,
       "union_name":"Kendua",
       "union_ban_name":"কেন্দুয়া"
    },
    {
       "_id":4926,
       "upazila_code":36,
       "union_code":41,
       "union_name":"Lakshmir Char",
       "union_ban_name":"লক্ষ্মীর চর"
    },
    {
       "_id":4927,
       "upazila_code":36,
       "union_code":47,
       "union_name":"Meshta",
       "union_ban_name":"মেশতা"
    },
    {
       "_id":4928,
       "upazila_code":36,
       "union_code":53,
       "union_name":"Narundi",
       "union_ban_name":"নুরুন্ডি"
    },
    {
       "_id":4929,
       "upazila_code":36,
       "union_code":59,
       "union_name":"Ranagachha",
       "union_ban_name":"রানাগাছা"
    },
    {
       "_id":4930,
       "upazila_code":36,
       "union_code":65,
       "union_name":"Rashidpur",
       "union_ban_name":"রশিদপুর"
    },
    {
       "_id":4931,
       "upazila_code":36,
       "union_code":71,
       "union_name":"Sahabajpur",
       "union_ban_name":"সাহাবাজপুর"
    },
    {
       "_id":4934,
       "upazila_code":36,
       "union_code":89,
       "union_name":"Titpalla",
       "union_ban_name":"তিতপাল্লা"
    },
    {
       "_id":4935,
       "upazila_code":36,
       "union_code":95,
       "union_name":"Tulsir Char",
       "union_ban_name":"তুলসীর চর"
    },
    {
       "_id":4936,
       "upazila_code":58,
       "union_code":11,
       "union_name":"Adarbhita",
       "union_ban_name":"আদরভিটা"
    },
    {
       "_id":4937,
       "upazila_code":58,
       "union_code":23,
       "union_name":"Balijuri",
       "union_ban_name":"বালিজুরি"
    },
    {
       "_id":4938,
       "upazila_code":58,
       "union_code":35,
       "union_name":"Char Pakerdaha",
       "union_ban_name":"চর পাকেরদহ"
    },
    {
       "_id":4939,
       "upazila_code":58,
       "union_code":47,
       "union_name":"Gunaritala",
       "union_ban_name":"গুনারিটলা"
    },
    {
       "_id":4940,
       "upazila_code":58,
       "union_code":59,
       "union_name":"Jorekhali",
       "union_ban_name":"জোড়খালী"
    },
    {
       "_id":4941,
       "upazila_code":58,
       "union_code":71,
       "union_name":"Karaichara",
       "union_ban_name":"করাইছড়া"
    },
    {
       "_id":4942,
       "upazila_code":58,
       "union_code":83,
       "union_name":"Sidhuli",
       "union_ban_name":"সিধুলি"
    },
    {
       "_id":4944,
       "upazila_code":61,
       "union_code":19,
       "union_name":"Char Banipakuri",
       "union_ban_name":"চর বানীপাকুরি"
    },
    {
       "_id":4945,
       "upazila_code":61,
       "union_code":28,
       "union_name":"Durmut",
       "union_ban_name":"দুরমুত"
    },
    {
       "_id":4946,
       "upazila_code":61,
       "union_code":38,
       "union_name":"Fulkocha",
       "union_ban_name":"ফুলকোচা"
    },
    {
       "_id":4947,
       "upazila_code":61,
       "union_code":47,
       "union_name":"Ghosher Para",
       "union_ban_name":"ঘোষের পাড়া"
    },
    {
       "_id":4948,
       "upazila_code":61,
       "union_code":57,
       "union_name":"Jhaugara",
       "union_ban_name":"ঝুঙ্গারা"
    },
    {
       "_id":4951,
       "upazila_code":61,
       "union_code":85,
       "union_name":"Nangla",
       "union_ban_name":"নংলা"
    },
    {
       "_id":4952,
       "upazila_code":61,
       "union_code":95,
       "union_name":"Nayanagar",
       "union_ban_name":"নয়নগর"
    },
    {
       "_id":4953,
       "upazila_code":61,
       "union_code":97,
       "union_name":"Shaympur",
       "union_ban_name":"শায়মপুর"
    },
    {
       "_id":4954,
       "upazila_code":85,
       "union_code":10,
       "union_name":"Aona",
       "union_ban_name":"আওনা"
    },
    {
       "_id":4956,
       "upazila_code":85,
       "union_code":31,
       "union_name":"Doail",
       "union_ban_name":"দোয়েল"
    },
    {
       "_id":4957,
       "upazila_code":85,
       "union_code":42,
       "union_name":"Kamrabad",
       "union_ban_name":"কামরাবাদ"
    },
    {
       "_id":4958,
       "upazila_code":85,
       "union_code":52,
       "union_name":"Mahadan",
       "union_ban_name":"মহাদান"
    },
    {
       "_id":4959,
       "upazila_code":85,
       "union_code":63,
       "union_name":"Pingna",
       "union_ban_name":"পিংনা"
    },
    {
       "_id":4960,
       "upazila_code":85,
       "union_code":73,
       "union_name":"Pogaldigha",
       "union_ban_name":"পোগলদিঘা"
    },
    {
       "_id":4961,
       "upazila_code":85,
       "union_code":84,
       "union_name":"Satpoa",
       "union_ban_name":"সাতপোয়া"
    },
    {
       "_id":4962,
       "upazila_code":4,
       "union_code":10,
       "union_name":"Baghutia",
       "union_ban_name":"বাঘুটিয়া"
    },
    {
       "_id":4963,
       "upazila_code":4,
       "union_code":21,
       "union_name":"Prambag",
       "union_ban_name":"প্রামবাগ"
    },
    {
       "_id":4964,
       "upazila_code":4,
       "union_code":27,
       "union_name":"Sundoli",
       "union_ban_name":"সুন্দলি"
    },
    {
       "_id":4965,
       "upazila_code":4,
       "union_code":42,
       "union_name":"Payra",
       "union_ban_name":"পাইরা"
    },
    {
       "_id":4966,
       "upazila_code":4,
       "union_code":52,
       "union_name":"Chalishia",
       "union_ban_name":"চালিশিয়া"
    },
    {
       "_id":4967,
       "upazila_code":4,
       "union_code":63,
       "union_name":"Siddhipasha",
       "union_ban_name":"সিদ্দীপাশা"
    },
    {
       "_id":4968,
       "upazila_code":4,
       "union_code":73,
       "union_name":"Sreedharpur",
       "union_ban_name":"শ্রীদহরপুর"
    },
    {
       "_id":4969,
       "upazila_code":4,
       "union_code":84,
       "union_name":"Subha Para",
       "union_ban_name":"সুভা পারা"
    },
    {
       "_id":4970,
       "upazila_code":9,
       "union_code":15,
       "union_name":"Basuari",
       "union_ban_name":"বসুয়ারি"
    },
    {
       "_id":4971,
       "upazila_code":9,
       "union_code":19,
       "union_name":"Bandabilla",
       "union_ban_name":"ব্যান্ডবিলা"
    },
    {
       "_id":4972,
       "upazila_code":9,
       "union_code":28,
       "union_name":"Darajhat",
       "union_ban_name":"দারঝাট"
    },
    {
       "_id":4973,
       "upazila_code":9,
       "union_code":38,
       "union_name":"Dhalgram",
       "union_ban_name":"ধলগ্রাম"
    },
    {
       "_id":4974,
       "upazila_code":9,
       "union_code":47,
       "union_name":"Dohakula",
       "union_ban_name":"দোহকুলা"
    },
    {
       "_id":4975,
       "upazila_code":9,
       "union_code":57,
       "union_name":"Jamdia",
       "union_ban_name":"জামদিয়া"
    },
    {
       "_id":4976,
       "upazila_code":9,
       "union_code":66,
       "union_name":"Jaharpur",
       "union_ban_name":"জহরপুর"
    },
    {
       "_id":4979,
       "upazila_code":11,
       "union_code":14,
       "union_name":"Chaugachha",
       "union_ban_name":"চৌগাছা"
    },
    {
       "_id":4980,
       "upazila_code":11,
       "union_code":17,
       "union_name":"Dhuliani",
       "union_ban_name":"ধুলিয়ানী"
    },
    {
       "_id":4981,
       "upazila_code":11,
       "union_code":25,
       "union_name":"Hakimpur",
       "union_ban_name":"হাকিমপুর"
    },
    {
       "_id":4983,
       "upazila_code":11,
       "union_code":43,
       "union_name":"Swarupdaha",
       "union_ban_name":"স্বরূপদাহা"
    },
    {
       "_id":4985,
       "upazila_code":11,
       "union_code":60,
       "union_name":"Pashapole",
       "union_ban_name":"পশাপোল"
    },
    {
       "_id":4986,
       "upazila_code":11,
       "union_code":69,
       "union_name":"Patibila",
       "union_ban_name":"পতিবিলা"
    },
    {
       "_id":4987,
       "upazila_code":11,
       "union_code":77,
       "union_name":"Phulsara",
       "union_ban_name":"ফসরা"
    },
    {
       "_id":4988,
       "upazila_code":11,
       "union_code":86,
       "union_name":"Singhajhuli",
       "union_ban_name":"সিংহঝুলি"
    },
    {
       "_id":4989,
       "upazila_code":11,
       "union_code":94,
       "union_name":"Sukpukhuria",
       "union_ban_name":"সুক্পুখুরিয়া"
    },
    {
       "_id":4990,
       "upazila_code":23,
       "union_code":25,
       "union_name":"Bankra",
       "union_ban_name":"ব্যাংক্রা"
    },
    {
       "_id":4991,
       "upazila_code":23,
       "union_code":29,
       "union_name":"Ganganandapur",
       "union_ban_name":"গঙ্গানন্দাপুর"
    },
    {
       "_id":4992,
       "upazila_code":23,
       "union_code":35,
       "union_name":"Gadkhali",
       "union_ban_name":"গাদখালী"
    },
    {
       "_id":4993,
       "upazila_code":23,
       "union_code":41,
       "union_name":"Hajirbagh",
       "union_ban_name":"হজিরবাগ"
    },
    {
       "_id":4994,
       "upazila_code":23,
       "union_code":47,
       "union_name":"Jhikargachha",
       "union_ban_name":"ঝিকারগাছা"
    },
    {
       "_id":4995,
       "upazila_code":23,
       "union_code":53,
       "union_name":"Magura",
       "union_ban_name":"মাগুরা"
    },
    {
       "_id":4996,
       "upazila_code":23,
       "union_code":59,
       "union_name":"Nabharan",
       "union_ban_name":"নাবরন"
    },
    {
       "_id":4997,
       "upazila_code":23,
       "union_code":65,
       "union_name":"Nibaskhola",
       "union_ban_name":"নিবাসখোলা"
    },
    {
       "_id":4998,
       "upazila_code":23,
       "union_code":71,
       "union_name":"Panisara",
       "union_ban_name":"পানিসারা"
    },
    {
       "_id":5001,
       "upazila_code":38,
       "union_code":17,
       "union_name":"Bidyanandakati",
       "union_ban_name":"বিদ্যানন্দকাটি"
    },
    {
       "_id":5002,
       "upazila_code":38,
       "union_code":19,
       "union_name":"Gaurighona",
       "union_ban_name":"গৌরিঘোনা"
    },
    {
       "_id":5003,
       "upazila_code":38,
       "union_code":28,
       "union_name":"Keshabpur",
       "union_ban_name":"কেশবপুর"
    },
    {
       "_id":5005,
       "upazila_code":38,
       "union_code":47,
       "union_name":"Mangalkot",
       "union_ban_name":"মঙ্গলকোট"
    },
    {
       "_id":5006,
       "upazila_code":38,
       "union_code":57,
       "union_name":"Panjia",
       "union_ban_name":"পাঞ্জিয়া"
    },
    {
       "_id":5007,
       "upazila_code":38,
       "union_code":66,
       "union_name":"Sagardari",
       "union_ban_name":"সাগরদারি"
    },
    {
       "_id":5008,
       "upazila_code":38,
       "union_code":76,
       "union_name":"Sufalakati",
       "union_ban_name":"সুফলকটি"
    },
    {
       "_id":5009,
       "upazila_code":38,
       "union_code":85,
       "union_name":"Trimohini",
       "union_ban_name":"ত্রিমোহিনী"
    },
    {
       "_id":5010,
       "upazila_code":47,
       "union_code":10,
       "union_name":"Arabpur",
       "union_ban_name":"আরবপুর"
    },
    {
       "_id":5011,
       "upazila_code":47,
       "union_code":11,
       "union_name":"Basundia",
       "union_ban_name":"বসুন্দিয়া"
    },
    {
       "_id":5013,
       "upazila_code":47,
       "union_code":29,
       "union_name":"Churamankati",
       "union_ban_name":"চুরমনকাটি"
    },
    {
       "_id":5014,
       "upazila_code":47,
       "union_code":35,
       "union_name":"Diara",
       "union_ban_name":"ডায়ারা"
    },
    {
       "_id":5015,
       "upazila_code":47,
       "union_code":41,
       "union_name":"Fathehpur",
       "union_ban_name":"ফতেহপুর"
    },
    {
       "_id":5016,
       "upazila_code":47,
       "union_code":47,
       "union_name":"Haibatpur",
       "union_ban_name":"হায়বতপুর"
    },
    {
       "_id":5017,
       "upazila_code":47,
       "union_code":53,
       "union_name":"Ichhali",
       "union_ban_name":"ইছালি"
    },
    {
       "_id":5020,
       "upazila_code":47,
       "union_code":77,
       "union_name":"Lebutala",
       "union_ban_name":"লেবুতালা"
    },
    {
       "_id":5021,
       "upazila_code":47,
       "union_code":83,
       "union_name":"Narendrapur",
       "union_ban_name":"নরেন্দ্রপুর"
    },
    {
       "_id":5024,
       "upazila_code":61,
       "union_code":10,
       "union_name":"Bhojgati",
       "union_ban_name":"ভোজগতি"
    },
    {
       "_id":5025,
       "upazila_code":61,
       "union_code":11,
       "union_name":"Chaluahati",
       "union_ban_name":"চালুহাতি"
    },
    {
       "_id":5026,
       "upazila_code":61,
       "union_code":16,
       "union_name":"Dhakuria",
       "union_ban_name":"ধাকুরিয়া"
    },
    {
       "_id":5027,
       "upazila_code":61,
       "union_code":22,
       "union_name":"Durbadanga",
       "union_ban_name":"দুরাবাদাঙ্গা"
    },
    {
       "_id":5028,
       "upazila_code":61,
       "union_code":27,
       "union_name":"Haridaskati",
       "union_ban_name":"হরিদাসকাতি"
    },
    {
       "_id":5029,
       "upazila_code":61,
       "union_code":33,
       "union_name":"Hariharnagar",
       "union_ban_name":"হরিহরনগর"
    },
    {
       "_id":5030,
       "upazila_code":61,
       "union_code":39,
       "union_name":"Jhanpa",
       "union_ban_name":"ঝনপা"
    },
    {
       "_id":5031,
       "upazila_code":61,
       "union_code":44,
       "union_name":"Kashimnagar",
       "union_ban_name":"কাশিমনগর"
    },
    {
       "_id":5033,
       "upazila_code":61,
       "union_code":55,
       "union_name":"Kheda Para",
       "union_ban_name":"খেদা পারা"
    },
    {
       "_id":5034,
       "upazila_code":61,
       "union_code":61,
       "union_name":"Kultia",
       "union_ban_name":"কুলিয়া"
    },
    {
       "_id":5035,
       "upazila_code":61,
       "union_code":67,
       "union_name":"Manirampur",
       "union_ban_name":"মনিরামপুর"
    },
    {
       "_id":5037,
       "upazila_code":61,
       "union_code":78,
       "union_name":"Maswimnagar",
       "union_ban_name":"মাসভিমনগর"
    },
    {
       "_id":5038,
       "upazila_code":61,
       "union_code":83,
       "union_name":"Nehalpur",
       "union_ban_name":"নেহালপুর"
    },
    {
       "_id":5039,
       "upazila_code":61,
       "union_code":89,
       "union_name":"Rohita",
       "union_ban_name":"রোহিটা"
    },
    {
       "_id":5040,
       "upazila_code":61,
       "union_code":94,
       "union_name":"Shyamkur",
       "union_ban_name":"শ্যামকর"
    },
    {
       "_id":5041,
       "upazila_code":90,
       "union_code":13,
       "union_name":"Bagachra",
       "union_ban_name":"বাগছড়া"
    },
    {
       "_id":5042,
       "upazila_code":90,
       "union_code":17,
       "union_name":"Bahadurpur",
       "union_ban_name":"বাহাদুরপুর"
    },
    {
       "_id":5043,
       "upazila_code":90,
       "union_code":25,
       "union_name":"Benapole",
       "union_ban_name":"বেনাপোল"
    },
    {
       "_id":5044,
       "upazila_code":90,
       "union_code":34,
       "union_name":"Dihi",
       "union_ban_name":"দিহি"
    },
    {
       "_id":5045,
       "upazila_code":90,
       "union_code":43,
       "union_name":"Goga",
       "union_ban_name":"গোগা"
    },
    {
       "_id":5046,
       "upazila_code":90,
       "union_code":51,
       "union_name":"Kayba",
       "union_ban_name":"কায়বা"
    },
    {
       "_id":5049,
       "upazila_code":90,
       "union_code":77,
       "union_name":"Putkhali",
       "union_ban_name":"পুটখালী"
    },
    {
       "_id":5050,
       "upazila_code":90,
       "union_code":86,
       "union_name":"Sharsha",
       "union_ban_name":"শার্শা"
    },
    {
       "_id":5051,
       "upazila_code":90,
       "union_code":94,
       "union_name":"Ulashi",
       "union_ban_name":"উলাশি"
    },
    {
       "_id":5052,
       "upazila_code":40,
       "union_code":17,
       "union_name":"Basanda",
       "union_ban_name":"বসন্দা"
    },
    {
       "_id":5053,
       "upazila_code":40,
       "union_code":19,
       "union_name":"Binoykati",
       "union_ban_name":"বিনয়কতি"
    },
    {
       "_id":5054,
       "upazila_code":40,
       "union_code":28,
       "union_name":"Gabkhan Dhansiri",
       "union_ban_name":"গাবখান ধনসিরি"
    },
    {
       "_id":5055,
       "upazila_code":40,
       "union_code":38,
       "union_name":"Gabha Ramchandrapur",
       "union_ban_name":"গাহা রামচন্দ্রপুর"
    },
    {
       "_id":5056,
       "upazila_code":40,
       "union_code":57,
       "union_name":"Keora",
       "union_ban_name":"কেওরা"
    },
    {
       "_id":5057,
       "upazila_code":40,
       "union_code":63,
       "union_name":"Nathullabad",
       "union_ban_name":"নাথুলাবাদ"
    },
    {
       "_id":5058,
       "upazila_code":40,
       "union_code":66,
       "union_name":"Kirtipasha",
       "union_ban_name":"কীর্তিপাশা"
    },
    {
       "_id":5059,
       "upazila_code":40,
       "union_code":76,
       "union_name":"Nabagram",
       "union_ban_name":"নবগ্রাম"
    },
    {
       "_id":5060,
       "upazila_code":40,
       "union_code":85,
       "union_name":"Ponabalia",
       "union_ban_name":"পোনাবলিয়া"
    },
    {
       "_id":5061,
       "upazila_code":40,
       "union_code":95,
       "union_name":"Sekherhat",
       "union_ban_name":"শেখেরহাট"
    },
    {
       "_id":5062,
       "upazila_code":43,
       "union_code":15,
       "union_name":"Amua",
       "union_ban_name":"আমুয়া"
    },
    {
       "_id":5063,
       "upazila_code":43,
       "union_code":31,
       "union_name":"Awrabunia",
       "union_ban_name":"আড়াবুনিয়া"
    },
    {
       "_id":5064,
       "upazila_code":43,
       "union_code":47,
       "union_name":"Chenchri Rampur",
       "union_ban_name":"চঞ্চরী রামপুর"
    },
    {
       "_id":5065,
       "upazila_code":43,
       "union_code":63,
       "union_name":"Kanthalia",
       "union_ban_name":"কাঁথালিয়া"
    },
    {
       "_id":5066,
       "upazila_code":43,
       "union_code":79,
       "union_name":"Patkhalghata",
       "union_ban_name":"পাটখালঘাটা"
    },
    {
       "_id":5067,
       "upazila_code":43,
       "union_code":94,
       "union_name":"Saulajalia",
       "union_ban_name":"সোলজালিয়া"
    },
    {
       "_id":5068,
       "upazila_code":73,
       "union_code":13,
       "union_name":"Bharabpasha",
       "union_ban_name":"ভরবপাশা"
    },
    {
       "_id":5069,
       "upazila_code":73,
       "union_code":15,
       "union_name":"Dapdapia",
       "union_ban_name":"ডাপডাপিয়া"
    },
    {
       "_id":5070,
       "upazila_code":73,
       "union_code":21,
       "union_name":"Kulkati",
       "union_ban_name":"কুলকতি"
    },
    {
       "_id":5071,
       "upazila_code":73,
       "union_code":31,
       "union_name":"Kusanghal",
       "union_ban_name":"কুসংঘল"
    },
    {
       "_id":5072,
       "upazila_code":73,
       "union_code":36,
       "union_name":"Mollahat",
       "union_ban_name":"মোল্লাহাট"
    },
    {
       "_id":5073,
       "upazila_code":73,
       "union_code":42,
       "union_name":"Magar",
       "union_ban_name":"মাগার"
    },
    {
       "_id":5074,
       "upazila_code":73,
       "union_code":52,
       "union_name":"Nachan Mohal",
       "union_ban_name":"নাচন মহল"
    },
    {
       "_id":5075,
       "upazila_code":73,
       "union_code":73,
       "union_name":"Ranapasha",
       "union_ban_name":"রণপাশা"
    },
    {
       "_id":5076,
       "upazila_code":73,
       "union_code":84,
       "union_name":"Siddhakati",
       "union_ban_name":"সিদ্ধকাঠি"
    },
    {
       "_id":5078,
       "upazila_code":84,
       "union_code":13,
       "union_name":"Baramaia",
       "union_ban_name":"বড়মাইয়া"
    },
    {
       "_id":5079,
       "upazila_code":84,
       "union_code":27,
       "union_name":"Galua",
       "union_ban_name":"গালুয়া"
    },
    {
       "_id":5080,
       "upazila_code":84,
       "union_code":40,
       "union_name":"Mathbari",
       "union_ban_name":"মঠবাড়ি"
    },
    {
       "_id":5082,
       "upazila_code":84,
       "union_code":67,
       "union_name":"Saturia",
       "union_ban_name":"সাটুরিয়া"
    },
    {
       "_id":5083,
       "upazila_code":84,
       "union_code":81,
       "union_name":"Suktagarh",
       "union_ban_name":"সুকাতগড়"
    },
    {
       "_id":5084,
       "upazila_code":14,
       "union_code":10,
       "union_name":"Bhayna",
       "union_ban_name":"ভায়না"
    },
    {
       "_id":5087,
       "upazila_code":14,
       "union_code":42,
       "union_name":"Phalsi",
       "union_ban_name":"ফালসি"
    },
    {
       "_id":5088,
       "upazila_code":14,
       "union_code":52,
       "union_name":"Joradaha",
       "union_ban_name":"জোড়দহ"
    },
    {
       "_id":5089,
       "upazila_code":14,
       "union_code":63,
       "union_name":"Kapashati",
       "union_ban_name":"কাপশাটি"
    },
    {
       "_id":5091,
       "upazila_code":14,
       "union_code":84,
       "union_name":"Taherhuda",
       "union_ban_name":"তাহেরহুদা"
    },
    {
       "_id":5092,
       "upazila_code":19,
       "union_code":11,
       "union_name":"Dogachhi",
       "union_ban_name":"দোগাছি"
    },
    {
       "_id":5093,
       "upazila_code":19,
       "union_code":13,
       "union_name":"Fursandi",
       "union_ban_name":"ফুরসান্দি"
    },
    {
       "_id":5094,
       "upazila_code":19,
       "union_code":15,
       "union_name":"Ganna",
       "union_ban_name":"গান্না"
    },
    {
       "_id":5095,
       "upazila_code":19,
       "union_code":21,
       "union_name":"Ghorshal",
       "union_ban_name":"ঘর্শাল"
    },
    {
       "_id":5096,
       "upazila_code":19,
       "union_code":26,
       "union_name":"Halidhani",
       "union_ban_name":"হালিদহানি"
    },
    {
       "_id":5097,
       "upazila_code":19,
       "union_code":31,
       "union_name":"Harishankarpur",
       "union_ban_name":"হরিশঙ্করপুর"
    },
    {
       "_id":5098,
       "upazila_code":19,
       "union_code":42,
       "union_name":"Kalicharanpur",
       "union_ban_name":"কালীচরণপুর"
    },
    {
       "_id":5099,
       "upazila_code":19,
       "union_code":47,
       "union_name":"Kumrabaria",
       "union_ban_name":"কুমারবারিয়া"
    },
    {
       "_id":5100,
       "upazila_code":19,
       "union_code":52,
       "union_name":"Madhuhati",
       "union_ban_name":"মধুতি"
    },
    {
       "_id":5101,
       "upazila_code":19,
       "union_code":58,
       "union_name":"Moharajpur",
       "union_ban_name":"মহারাজপুর"
    },
    {
       "_id":5102,
       "upazila_code":19,
       "union_code":63,
       "union_name":"Naldanga",
       "union_ban_name":"নলডাঙ্গা"
    },
    {
       "_id":5103,
       "upazila_code":19,
       "union_code":68,
       "union_name":"Padmakar",
       "union_ban_name":"পদ্মকার"
    },
    {
       "_id":5104,
       "upazila_code":19,
       "union_code":73,
       "union_name":"Paglakanai",
       "union_ban_name":"পাগলাকানাই"
    },
    {
       "_id":5105,
       "upazila_code":19,
       "union_code":79,
       "union_name":"Porahati",
       "union_ban_name":"পোড়াহাটি"
    },
    {
       "_id":5106,
       "upazila_code":19,
       "union_code":84,
       "union_name":"Saganna",
       "union_ban_name":"সাগন্না"
    },
    {
       "_id":5107,
       "upazila_code":19,
       "union_code":89,
       "union_name":"Sadhuhati",
       "union_ban_name":"সদুহতি"
    },
    {
       "_id":5108,
       "upazila_code":19,
       "union_code":94,
       "union_name":"Surat",
       "union_ban_name":"সুরত"
    },
    {
       "_id":5109,
       "upazila_code":33,
       "union_code":10,
       "union_name":"Bara Bazar",
       "union_ban_name":"বার বাজার"
    },
    {
       "_id":5110,
       "upazila_code":33,
       "union_code":20,
       "union_name":"Jamal",
       "union_ban_name":"জামাল"
    },
    {
       "_id":5111,
       "upazila_code":33,
       "union_code":33,
       "union_name":"Sundarpur Durgapur",
       "union_ban_name":"সুন্দরপুর দুর্গাপুর"
    },
    {
       "_id":5112,
       "upazila_code":33,
       "union_code":40,
       "union_name":"Kashtabhanga",
       "union_ban_name":"কাশতভঙ্গা"
    },
    {
       "_id":5113,
       "upazila_code":33,
       "union_code":47,
       "union_name":"Kola",
       "union_ban_name":"কোলা"
    },
    {
       "_id":5114,
       "upazila_code":33,
       "union_code":54,
       "union_name":"Maliat",
       "union_ban_name":"মালিয়াত"
    },
    {
       "_id":5115,
       "upazila_code":33,
       "union_code":61,
       "union_name":"Niamatpur",
       "union_ban_name":"নিয়ামতপুর"
    },
    {
       "_id":5116,
       "upazila_code":33,
       "union_code":74,
       "union_name":"Roygram",
       "union_ban_name":"রায়গ্রাম"
    },
    {
       "_id":5118,
       "upazila_code":33,
       "union_code":88,
       "union_name":"Simla Rokonpur",
       "union_ban_name":"সিমলা রোকনপুর"
    },
    {
       "_id":5119,
       "upazila_code":33,
       "union_code":94,
       "union_name":"Trilochanpur",
       "union_ban_name":"ত্রিলোচনপুর"
    },
    {
       "_id":5120,
       "upazila_code":42,
       "union_code":13,
       "union_name":"Baluhar",
       "union_ban_name":"বালুহার"
    },
    {
       "_id":5121,
       "upazila_code":42,
       "union_code":27,
       "union_name":"Dora",
       "union_ban_name":"ডোরা"
    },
    {
       "_id":5123,
       "upazila_code":42,
       "union_code":67,
       "union_name":"Kushna",
       "union_ban_name":"কুশনা"
    },
    {
       "_id":5124,
       "upazila_code":42,
       "union_code":81,
       "union_name":"Sabdalpur",
       "union_ban_name":"সাবদালপুর"
    },
    {
       "_id":5125,
       "upazila_code":71,
       "union_code":10,
       "union_name":"Azampur",
       "union_ban_name":"আজমপুর"
    },
    {
       "_id":5129,
       "upazila_code":71,
       "union_code":29,
       "union_name":"Kazirber",
       "union_ban_name":"কাজিরবার"
    },
    {
       "_id":5130,
       "upazila_code":71,
       "union_code":35,
       "union_name":"Manderbari",
       "union_ban_name":"মান্দারবাড়ি"
    },
    {
       "_id":5131,
       "upazila_code":71,
       "union_code":53,
       "union_name":"Natima",
       "union_ban_name":"নাতিমা"
    },
    {
       "_id":5132,
       "upazila_code":71,
       "union_code":59,
       "union_name":"Nepa",
       "union_ban_name":"নেপা"
    },
    {
       "_id":5133,
       "upazila_code":71,
       "union_code":65,
       "union_name":"Pantha Para",
       "union_ban_name":"পান্থ পাড়া"
    },
    {
       "_id":5135,
       "upazila_code":71,
       "union_code":83,
       "union_name":"S.B.K",
       "union_ban_name":"এস.বি.কে"
    },
    {
       "_id":5136,
       "upazila_code":71,
       "union_code":89,
       "union_name":"Swaruppur",
       "union_ban_name":"স্বরূপপুর"
    },
    {
       "_id":5137,
       "upazila_code":80,
       "union_code":10,
       "union_name":"Abaipur",
       "union_ban_name":"আবাইপুর"
    },
    {
       "_id":5138,
       "upazila_code":80,
       "union_code":12,
       "union_name":"Bagura",
       "union_ban_name":"বাগুরা"
    },
    {
       "_id":5139,
       "upazila_code":80,
       "union_code":18,
       "union_name":"Dhalhara Chandra",
       "union_ban_name":"ধলড়াচন্দ্র"
    },
    {
       "_id":5141,
       "upazila_code":80,
       "union_code":31,
       "union_name":"Dudhsar",
       "union_ban_name":"দুধসার"
    },
    {
       "_id":5143,
       "upazila_code":80,
       "union_code":44,
       "union_name":"Fulhari",
       "union_ban_name":"ফুলহরি"
    },
    {
       "_id":5145,
       "upazila_code":80,
       "union_code":56,
       "union_name":"Kancherkol",
       "union_ban_name":"কাঞ্চেরকোল"
    },
    {
       "_id":5147,
       "upazila_code":80,
       "union_code":69,
       "union_name":"Nityanandapur",
       "union_ban_name":"নিত্যানন্দপুর"
    },
    {
       "_id":5148,
       "upazila_code":80,
       "union_code":75,
       "union_name":"Sarutia",
       "union_ban_name":"সরুটিয়া"
    },
    {
       "_id":5149,
       "upazila_code":80,
       "union_code":88,
       "union_name":"Tribeni",
       "union_ban_name":"ত্রিবেণী"
    },
    {
       "_id":5150,
       "upazila_code":80,
       "union_code":94,
       "union_name":"Umedpur",
       "union_ban_name":"উমদপুর"
    },
    {
       "_id":5152,
       "upazila_code":13,
       "union_code":31,
       "union_name":"Raikali",
       "union_ban_name":"রিকালী"
    },
    {
       "_id":5153,
       "upazila_code":13,
       "union_code":47,
       "union_name":"Rukindipur",
       "union_ban_name":"রুকিনপুর"
    },
    {
       "_id":5154,
       "upazila_code":13,
       "union_code":63,
       "union_name":"Sonamukhi",
       "union_ban_name":"সোনামুখী"
    },
    {
       "_id":5155,
       "upazila_code":13,
       "union_code":79,
       "union_name":"Tilakpur",
       "union_ban_name":"তিলকপুর"
    },
    {
       "_id":5156,
       "upazila_code":47,
       "union_code":17,
       "union_name":"Amdai",
       "union_ban_name":"আমদাই"
    },
    {
       "_id":5157,
       "upazila_code":47,
       "union_code":19,
       "union_name":"Bambu",
       "union_ban_name":"বাম্বু"
    },
    {
       "_id":5158,
       "upazila_code":47,
       "union_code":28,
       "union_name":"Bhadsa",
       "union_ban_name":"ভাদসা"
    },
    {
       "_id":5159,
       "upazila_code":47,
       "union_code":32,
       "union_name":"Chak Barkat",
       "union_ban_name":"চক বরকত"
    },
    {
       "_id":5160,
       "upazila_code":47,
       "union_code":38,
       "union_name":"Dhalahar",
       "union_ban_name":"ধলাহার"
    },
    {
       "_id":5163,
       "upazila_code":47,
       "union_code":76,
       "union_name":"Mohammadabad",
       "union_ban_name":"মোহাম্মাদাবাদ"
    },
    {
       "_id":5164,
       "upazila_code":47,
       "union_code":85,
       "union_name":"Puranapail",
       "union_ban_name":"পুরাণপেল"
    },
    {
       "_id":5165,
       "upazila_code":58,
       "union_code":38,
       "union_name":"Ahmmedabad",
       "union_ban_name":"আহম্মদাবাদ"
    },
    {
       "_id":5166,
       "upazila_code":58,
       "union_code":66,
       "union_name":"Matrai",
       "union_ban_name":"ম্যাট্রাই"
    },
    {
       "_id":5167,
       "upazila_code":58,
       "union_code":76,
       "union_name":"Punat",
       "union_ban_name":"পুনট"
    },
    {
       "_id":5169,
       "upazila_code":58,
       "union_code":95,
       "union_name":"Zindarpur",
       "union_ban_name":"জিন্দারপুর"
    },
    {
       "_id":5170,
       "upazila_code":61,
       "union_code":15,
       "union_name":"Alampur",
       "union_ban_name":"আলমপুর"
    },
    {
       "_id":5171,
       "upazila_code":61,
       "union_code":19,
       "union_name":"Baratara",
       "union_ban_name":"বড়তারা"
    },
    {
       "_id":5173,
       "upazila_code":61,
       "union_code":47,
       "union_name":"Khetlal",
       "union_ban_name":"ক্ষেতলাল"
    },
    {
       "_id":5175,
       "upazila_code":74,
       "union_code":10,
       "union_name":"Aolai",
       "union_ban_name":"আওলাই"
    },
    {
       "_id":5176,
       "upazila_code":74,
       "union_code":21,
       "union_name":"Atapur",
       "union_ban_name":"আতাপুর"
    },
    {
       "_id":5177,
       "upazila_code":74,
       "union_code":31,
       "union_name":"Aymarasulpur",
       "union_ban_name":"আয়মারাসুলপুর"
    },
    {
       "_id":5178,
       "upazila_code":74,
       "union_code":42,
       "union_name":"Bagjana",
       "union_ban_name":"বাগজানা"
    },
    {
       "_id":5179,
       "upazila_code":74,
       "union_code":52,
       "union_name":"Balighata",
       "union_ban_name":"বালঘাট"
    },
    {
       "_id":5180,
       "upazila_code":74,
       "union_code":63,
       "union_name":"Dharanji",
       "union_ban_name":"ধরানজি"
    },
    {
       "_id":5181,
       "upazila_code":74,
       "union_code":73,
       "union_name":"Kusumba",
       "union_ban_name":"কুসুম্বা"
    },
    {
       "_id":5182,
       "upazila_code":74,
       "union_code":84,
       "union_name":"Mohamadpur",
       "union_ban_name":"মোহামদপুর"
    },
    {
       "_id":5183,
       "upazila_code":43,
       "union_code":15,
       "union_name":"Babuchhara",
       "union_ban_name":"বাবুচারা"
    },
    {
       "_id":5184,
       "upazila_code":43,
       "union_code":31,
       "union_name":"Boalkhali",
       "union_ban_name":"বোয়ালখালী"
    },
    {
       "_id":5185,
       "upazila_code":43,
       "union_code":47,
       "union_name":"Dighinala",
       "union_ban_name":"দিঘিনালা"
    },
    {
       "_id":5186,
       "upazila_code":43,
       "union_code":63,
       "union_name":"Kabakhali",
       "union_ban_name":"কাবাকালী"
    },
    {
       "_id":5187,
       "upazila_code":43,
       "union_code":79,
       "union_name":"Merung",
       "union_ban_name":"মেরুং"
    },
    {
       "_id":5188,
       "upazila_code":49,
       "union_code":15,
       "union_name":"Bhaibonchhara",
       "union_ban_name":"ভিববনছড়া"
    },
    {
       "_id":5189,
       "upazila_code":49,
       "union_code":25,
       "union_name":"Golabari",
       "union_ban_name":"গোলবাড়ী"
    },
    {
       "_id":5190,
       "upazila_code":49,
       "union_code":47,
       "union_name":"Kamalchhari",
       "union_ban_name":"কামালছড়ি"
    },
    {
       "_id":5191,
       "upazila_code":49,
       "union_code":63,
       "union_name":"Khagrachhari",
       "union_ban_name":"খাগড়াছড়ি"
    },
    {
       "_id":5192,
       "upazila_code":49,
       "union_code":75,
       "union_name":"Perachhara",
       "union_ban_name":"পেরছারা"
    },
    {
       "_id":5193,
       "upazila_code":61,
       "union_code":23,
       "union_name":"Barmachhari",
       "union_ban_name":"বারমছড়ি"
    },
    {
       "_id":5194,
       "upazila_code":61,
       "union_code":47,
       "union_name":"Dulyatali",
       "union_ban_name":"ডুলিতাালি"
    },
    {
       "_id":5195,
       "upazila_code":61,
       "union_code":71,
       "union_name":"Lakshmichhari",
       "union_ban_name":"লক্ষ্মিছড়িহারি"
    },
    {
       "_id":5196,
       "upazila_code":65,
       "union_code":15,
       "union_name":"Kayangghat",
       "union_ban_name":"কায়ংঘাট"
    },
    {
       "_id":5197,
       "upazila_code":65,
       "union_code":31,
       "union_name":"Mahalchhari",
       "union_ban_name":"মহলছড়ি"
    },
    {
       "_id":5198,
       "upazila_code":65,
       "union_code":47,
       "union_name":"Maschhari",
       "union_ban_name":"মাসচহারি"
    },
    {
       "_id":5199,
       "upazila_code":65,
       "union_code":63,
       "union_name":"Mubachhari",
       "union_ban_name":"মুবাইছড়ি"
    },
    {
       "_id":5200,
       "upazila_code":65,
       "union_code":79,
       "union_name":"Sindukchari",
       "union_ban_name":"সিন্ধুছড়ি"
    },
    {
       "_id":5201,
       "upazila_code":67,
       "union_code":19,
       "union_name":"Batnatali",
       "union_ban_name":"বাতনাটালি"
    },
    {
       "_id":5202,
       "upazila_code":67,
       "union_code":42,
       "union_name":"Juggachhala",
       "union_ban_name":"জুগাছোলা"
    },
    {
       "_id":5203,
       "upazila_code":67,
       "union_code":63,
       "union_name":"Manikchhari",
       "union_ban_name":"মানিকছড়ি"
    },
    {
       "_id":5204,
       "upazila_code":67,
       "union_code":87,
       "union_name":"Tintahari",
       "union_ban_name":"টিনতাহারি"
    },
    {
       "_id":5206,
       "upazila_code":70,
       "union_code":17,
       "union_name":"Baranal",
       "union_ban_name":"বরানাল"
    },
    {
       "_id":5207,
       "upazila_code":70,
       "union_code":23,
       "union_name":"Belchhari",
       "union_ban_name":"বেলচেরি"
    },
    {
       "_id":5208,
       "upazila_code":70,
       "union_code":35,
       "union_name":"Guimara",
       "union_ban_name":"গুইমারা"
    },
    {
       "_id":5209,
       "upazila_code":70,
       "union_code":47,
       "union_name":"Gumti",
       "union_ban_name":"গুমতি"
    },
    {
       "_id":5210,
       "upazila_code":70,
       "union_code":59,
       "union_name":"Matiranga",
       "union_ban_name":"মতিরাঙ্গা"
    },
    {
       "_id":5211,
       "upazila_code":70,
       "union_code":76,
       "union_name":"Tubalchhari",
       "union_ban_name":"তুবালছড়ি"
    },
    {
       "_id":5212,
       "upazila_code":70,
       "union_code":83,
       "union_name":"Taindang",
       "union_ban_name":"তাইণ্ডাং"
    },
    {
       "_id":5213,
       "upazila_code":77,
       "union_code":19,
       "union_name":"Chengi",
       "union_ban_name":"চেঙ্গি"
    },
    {
       "_id":5214,
       "upazila_code":77,
       "union_code":38,
       "union_name":"Latiban",
       "union_ban_name":"লতিবান"
    },
    {
       "_id":5215,
       "upazila_code":77,
       "union_code":57,
       "union_name":"Logang",
       "union_ban_name":"লোগাং"
    },
    {
       "_id":5216,
       "upazila_code":77,
       "union_code":76,
       "union_name":"Panchhari",
       "union_ban_name":"পাঁচহরী"
    },
    {
       "_id":5217,
       "upazila_code":77,
       "union_code":89,
       "union_name":"Ulta Chari",
       "union_ban_name":"উল্টা চরি"
    },
    {
       "_id":5218,
       "upazila_code":80,
       "union_code":38,
       "union_name":"Hapchhari",
       "union_ban_name":"হাপচারারী"
    },
    {
       "_id":5219,
       "upazila_code":80,
       "union_code":57,
       "union_name":"Pathachhara",
       "union_ban_name":"পথচারা"
    },
    {
       "_id":5220,
       "upazila_code":80,
       "union_code":76,
       "union_name":"Ramgarh",
       "union_ban_name":"রামগড়"
    },
    {
       "_id":5221,
       "upazila_code":12,
       "union_code":11,
       "union_name":"Amirpur",
       "union_ban_name":"আমিরপুর"
    },
    {
       "_id":5222,
       "upazila_code":12,
       "union_code":23,
       "union_name":"Baliadanga",
       "union_ban_name":"বালিয়াডাঙ্গা"
    },
    {
       "_id":5223,
       "upazila_code":12,
       "union_code":35,
       "union_name":"Batiaghata",
       "union_ban_name":"বটিঘাটা"
    },
    {
       "_id":5224,
       "upazila_code":12,
       "union_code":47,
       "union_name":"Bhanderkote",
       "union_ban_name":"ভান্ডারকোট"
    },
    {
       "_id":5225,
       "upazila_code":12,
       "union_code":59,
       "union_name":"Gangarampur",
       "union_ban_name":"গঙ্গারামপুর"
    },
    {
       "_id":5226,
       "upazila_code":12,
       "union_code":71,
       "union_name":"Jalma",
       "union_ban_name":"জালমা"
    },
    {
       "_id":5227,
       "upazila_code":12,
       "union_code":83,
       "union_name":"Surkhali",
       "union_ban_name":"সুরখালী"
    },
    {
       "_id":5228,
       "upazila_code":17,
       "union_code":10,
       "union_name":"Bajua",
       "union_ban_name":"বাজুয়া"
    },
    {
       "_id":5229,
       "upazila_code":17,
       "union_code":13,
       "union_name":"Banishanta",
       "union_ban_name":"বানিশান্ত"
    },
    {
       "_id":5230,
       "upazila_code":17,
       "union_code":21,
       "union_name":"Laudubi",
       "union_ban_name":"লাউডুবি"
    },
    {
       "_id":5231,
       "upazila_code":17,
       "union_code":42,
       "union_name":"Dacope",
       "union_ban_name":"দাকোপ"
    },
    {
       "_id":5232,
       "upazila_code":17,
       "union_code":52,
       "union_name":"Kailasganj",
       "union_ban_name":"কৈলাসগঞ্জ"
    },
    {
       "_id":5233,
       "upazila_code":17,
       "union_code":63,
       "union_name":"Kamarkhola",
       "union_ban_name":"কামারখোলা"
    },
    {
       "_id":5234,
       "upazila_code":17,
       "union_code":69,
       "union_name":"Pankhali",
       "union_ban_name":"পানখালী"
    },
    {
       "_id":5235,
       "upazila_code":17,
       "union_code":73,
       "union_name":"Sutarkhali",
       "union_ban_name":"সুতারখালী"
    },
    {
       "_id":5236,
       "upazila_code":17,
       "union_code":84,
       "union_name":"Tildanga",
       "union_ban_name":"তিলডাঙা"
    },
    {
       "_id":5237,
       "upazila_code":17,
       "union_code":97,
       "union_name":"Khulna Range",
       "union_ban_name":"খুলনা রেঞ্জ"
    },
    {
       "_id":5238,
       "upazila_code":21,
       "union_code":10,
       "union_name":"Aranghata",
       "union_ban_name":"আরংঘাটা"
    },
    {
       "_id":5239,
       "upazila_code":30,
       "union_code":11,
       "union_name":"Atlia",
       "union_ban_name":"অ্যাটলিয়া"
    },
    {
       "_id":5240,
       "upazila_code":30,
       "union_code":13,
       "union_name":"Bhandar Para",
       "union_ban_name":"ভান্ডার প্যারা"
    },
    {
       "_id":5241,
       "upazila_code":30,
       "union_code":20,
       "union_name":"Dhamalia",
       "union_ban_name":"ধামালিয়া"
    },
    {
       "_id":5243,
       "upazila_code":30,
       "union_code":33,
       "union_name":"Gutudia",
       "union_ban_name":"গুটুদিয়া"
    },
    {
       "_id":5244,
       "upazila_code":30,
       "union_code":40,
       "union_name":"Kharnia",
       "union_ban_name":"খারনিয়া"
    },
    {
       "_id":5245,
       "upazila_code":30,
       "union_code":47,
       "union_name":"Magurkhali",
       "union_ban_name":"ম্যাগুরখালী"
    },
    {
       "_id":5246,
       "upazila_code":30,
       "union_code":54,
       "union_name":"Maguraghona",
       "union_ban_name":"মাগুরাঘোনা"
    },
    {
       "_id":5248,
       "upazila_code":30,
       "union_code":67,
       "union_name":"Rangpur",
       "union_ban_name":"রংপুর"
    },
    {
       "_id":5249,
       "upazila_code":30,
       "union_code":74,
       "union_name":"Rudaghara",
       "union_ban_name":"রুদ্রঘর"
    },
    {
       "_id":5250,
       "upazila_code":30,
       "union_code":81,
       "union_name":"Sahas",
       "union_ban_name":"সাহস"
    },
    {
       "_id":5251,
       "upazila_code":30,
       "union_code":88,
       "union_name":"Sarappur",
       "union_ban_name":"সরপপুর"
    },
    {
       "_id":5252,
       "upazila_code":30,
       "union_code":94,
       "union_name":"Sovna",
       "union_ban_name":"সোভনা"
    },
    {
       "_id":5253,
       "upazila_code":40,
       "union_code":17,
       "union_name":"Barakpur",
       "union_ban_name":"বরাকপুর"
    },
    {
       "_id":5254,
       "upazila_code":40,
       "union_code":57,
       "union_name":"Dighalia",
       "union_ban_name":"দিঘালিয়া"
    },
    {
       "_id":5255,
       "upazila_code":40,
       "union_code":66,
       "union_name":"Gazir Hat",
       "union_ban_name":"গাজির হাট"
    },
    {
       "_id":5256,
       "upazila_code":40,
       "union_code":85,
       "union_name":"Senhati",
       "union_ban_name":"সেনহাটি"
    },
    {
       "_id":5257,
       "upazila_code":48,
       "union_code":19,
       "union_name":"Atra Gilatala",
       "union_ban_name":"আত্রা গিলতালা"
    },
    {
       "_id":5258,
       "upazila_code":48,
       "union_code":75,
       "union_name":"Jugipole",
       "union_ban_name":"জুগিপোল"
    },
    {
       "_id":5259,
       "upazila_code":53,
       "union_code":10,
       "union_name":"Amadi",
       "union_ban_name":"আমাদী"
    },
    {
       "_id":5260,
       "upazila_code":53,
       "union_code":11,
       "union_name":"Bagali",
       "union_ban_name":"বাগালী"
    },
    {
       "_id":5261,
       "upazila_code":53,
       "union_code":22,
       "union_name":"Dakshin Bedkashi",
       "union_ban_name":"দক্ষিণ শযকাশী"
    },
    {
       "_id":5262,
       "upazila_code":53,
       "union_code":55,
       "union_name":"Koyra",
       "union_ban_name":"কয়রা"
    },
    {
       "_id":5264,
       "upazila_code":53,
       "union_code":78,
       "union_name":"Maheshwaripur",
       "union_ban_name":"মহেশ্বরীপুর"
    },
    {
       "_id":5265,
       "upazila_code":53,
       "union_code":94,
       "union_name":"Uttar Bedkashi",
       "union_ban_name":"উত্তর শযকাশী"
    },
    {
       "_id":5266,
       "upazila_code":64,
       "union_code":16,
       "union_name":"Chandkhali",
       "union_ban_name":"চাঁদখালী"
    },
    {
       "_id":5267,
       "upazila_code":64,
       "union_code":27,
       "union_name":"Deluti",
       "union_ban_name":"ডেলুটি"
    },
    {
       "_id":5268,
       "upazila_code":64,
       "union_code":33,
       "union_name":"Gadaipur",
       "union_ban_name":"গাদিপুর"
    },
    {
       "_id":5269,
       "upazila_code":64,
       "union_code":39,
       "union_name":"Garuikhali",
       "union_ban_name":"গুরুখালী"
    },
    {
       "_id":5270,
       "upazila_code":64,
       "union_code":44,
       "union_name":"Haridhali",
       "union_ban_name":"হরিঘালী"
    },
    {
       "_id":5271,
       "upazila_code":64,
       "union_code":50,
       "union_name":"Kapilmuni",
       "union_ban_name":"কাপিলমুনি"
    },
    {
       "_id":5272,
       "upazila_code":64,
       "union_code":61,
       "union_name":"Laskar",
       "union_ban_name":"লস্কর"
    },
    {
       "_id":5274,
       "upazila_code":64,
       "union_code":83,
       "union_name":"Raruli",
       "union_ban_name":"রাকুলি"
    },
    {
       "_id":5275,
       "upazila_code":64,
       "union_code":89,
       "union_name":"Sholadana",
       "union_ban_name":"শোলাদানা"
    },
    {
       "_id":5276,
       "upazila_code":69,
       "union_code":38,
       "union_name":"Damodar",
       "union_ban_name":"দামোদর"
    },
    {
       "_id":5277,
       "upazila_code":69,
       "union_code":57,
       "union_name":"Jamira",
       "union_ban_name":"জামিরা"
    },
    {
       "_id":5278,
       "upazila_code":69,
       "union_code":76,
       "union_name":"Phultala",
       "union_ban_name":"ফুলতলা"
    },
    {
       "_id":5279,
       "upazila_code":75,
       "union_code":13,
       "union_name":"Aijganti",
       "union_ban_name":"এজিজান্তি"
    },
    {
       "_id":5280,
       "upazila_code":75,
       "union_code":27,
       "union_name":"Ghatbhogh",
       "union_ban_name":"ঘাটভোগ"
    },
    {
       "_id":5281,
       "upazila_code":75,
       "union_code":54,
       "union_name":"Naihati",
       "union_ban_name":"নাইহতি"
    },
    {
       "_id":5282,
       "upazila_code":75,
       "union_code":67,
       "union_name":"Sreefaltala",
       "union_ban_name":"শ্রীফতলালা"
    },
    {
       "_id":5283,
       "upazila_code":75,
       "union_code":81,
       "union_name":"T. S. Bahirdia",
       "union_ban_name":"টি. এস. বাহীদিয়া"
    },
    {
       "_id":5284,
       "upazila_code":94,
       "union_code":13,
       "union_name":"Ajugara",
       "union_ban_name":"আজগারা"
    },
    {
       "_id":5287,
       "upazila_code":94,
       "union_code":54,
       "union_name":"Sachiadah",
       "union_ban_name":"সাচিয়াডাহ"
    },
    {
       "_id":5288,
       "upazila_code":94,
       "union_code":67,
       "union_name":"Sagladah",
       "union_ban_name":"সাগলাদহ"
    },
    {
       "_id":5289,
       "upazila_code":94,
       "union_code":81,
       "union_name":"Terokhada",
       "union_ban_name":"তেরখাদা"
    },
    {
       "_id":5290,
       "upazila_code":2,
       "union_code":11,
       "union_name":"Adampur",
       "union_ban_name":"আদমপুর"
    },
    {
       "_id":5291,
       "upazila_code":2,
       "union_code":23,
       "union_name":"Austagram",
       "union_ban_name":"অস্টগ্রাম"
    },
    {
       "_id":5292,
       "upazila_code":2,
       "union_code":35,
       "union_name":"Bangal Para",
       "union_ban_name":"বাঙ্গাল প্যারা"
    },
    {
       "_id":5293,
       "upazila_code":2,
       "union_code":47,
       "union_name":"Deoghar",
       "union_ban_name":"দেগহার"
    },
    {
       "_id":5295,
       "upazila_code":2,
       "union_code":71,
       "union_name":"Kastail",
       "union_ban_name":"কাস্তেইল"
    },
    {
       "_id":5296,
       "upazila_code":2,
       "union_code":83,
       "union_name":"Khayerpur Abdullahpur",
       "union_ban_name":"খায়রপুর আব্দুল্লাহপুর"
    },
    {
       "_id":5297,
       "upazila_code":2,
       "union_code":91,
       "union_name":"Purba Austagram",
       "union_ban_name":"পূর্বা অস্টগ্রাম"
    },
    {
       "_id":5298,
       "upazila_code":6,
       "union_code":17,
       "union_name":"Baliardi",
       "union_ban_name":"বালিয়ার্দি."
    },
    {
       "_id":5299,
       "upazila_code":6,
       "union_code":25,
       "union_name":"Dighirpar",
       "union_ban_name":"দিঘিরপাড়া"
    },
    {
       "_id":5300,
       "upazila_code":6,
       "union_code":34,
       "union_name":"Dilalpur",
       "union_ban_name":"দিলালপুর"
    },
    {
       "_id":5301,
       "upazila_code":6,
       "union_code":43,
       "union_name":"Gazir Char",
       "union_ban_name":"গাজির চর"
    },
    {
       "_id":5302,
       "upazila_code":6,
       "union_code":51,
       "union_name":"Halimpur",
       "union_ban_name":"হালিমপুর"
    },
    {
       "_id":5303,
       "upazila_code":6,
       "union_code":60,
       "union_name":"Hilachia",
       "union_ban_name":"হিলচিয়া"
    },
    {
       "_id":5304,
       "upazila_code":6,
       "union_code":69,
       "union_name":"Humaipur",
       "union_ban_name":"হুমায়পুর"
    },
    {
       "_id":5305,
       "upazila_code":6,
       "union_code":72,
       "union_name":"Kailag",
       "union_ban_name":"কাইল্যাগ"
    },
    {
       "_id":5306,
       "upazila_code":6,
       "union_code":77,
       "union_name":"Maij Char",
       "union_ban_name":"মিজ চর"
    },
    {
       "_id":5307,
       "upazila_code":6,
       "union_code":86,
       "union_name":"Pirijpur",
       "union_ban_name":"পিরিজপুর"
    },
    {
       "_id":5308,
       "upazila_code":6,
       "union_code":94,
       "union_name":"Sarar Char",
       "union_ban_name":"সরার চর"
    },
    {
       "_id":5311,
       "upazila_code":11,
       "union_code":47,
       "union_name":"Kalika Prasad",
       "union_ban_name":"কালিকা প্রসাদ"
    },
    {
       "_id":5312,
       "upazila_code":11,
       "union_code":59,
       "union_name":"Sadakpur",
       "union_ban_name":"সাদাকপুর"
    },
    {
       "_id":5314,
       "upazila_code":11,
       "union_code":83,
       "union_name":"Shimulkandi",
       "union_ban_name":"শিমুলকান্দি"
    },
    {
       "_id":5315,
       "upazila_code":11,
       "union_code":85,
       "union_name":"Sreenagar",
       "union_ban_name":"শ্রীনগর"
    },
    {
       "_id":5316,
       "upazila_code":27,
       "union_code":13,
       "union_name":"Araibaria",
       "union_ban_name":"আড়াইবাড়িয়া"
    },
    {
       "_id":5318,
       "upazila_code":27,
       "union_code":40,
       "union_name":"Jinari",
       "union_ban_name":"জিনারি"
    },
    {
       "_id":5319,
       "upazila_code":27,
       "union_code":54,
       "union_name":"Pumdi",
       "union_ban_name":"পুমদি"
    },
    {
       "_id":5320,
       "upazila_code":27,
       "union_code":67,
       "union_name":"Sahedal",
       "union_ban_name":"সাহেদাল"
    },
    {
       "_id":5321,
       "upazila_code":27,
       "union_code":81,
       "union_name":"Sidhla",
       "union_ban_name":"সিদ্ধলা"
    },
    {
       "_id":5322,
       "upazila_code":33,
       "union_code":17,
       "union_name":"Badla",
       "union_ban_name":"বদলা"
    },
    {
       "_id":5323,
       "upazila_code":33,
       "union_code":25,
       "union_name":"Baribari",
       "union_ban_name":"বারিবাড়ি"
    },
    {
       "_id":5324,
       "upazila_code":33,
       "union_code":26,
       "union_name":"Chauganga",
       "union_ban_name":"চৌগঙ্গা"
    },
    {
       "_id":5325,
       "upazila_code":33,
       "union_code":43,
       "union_name":"Dhanpur",
       "union_ban_name":"ধানপুর"
    },
    {
       "_id":5326,
       "upazila_code":33,
       "union_code":47,
       "union_name":"Elongjuri",
       "union_ban_name":"এলংজুরি"
    },
    {
       "_id":5327,
       "upazila_code":33,
       "union_code":51,
       "union_name":"Itna",
       "union_ban_name":"ইটনা"
    },
    {
       "_id":5328,
       "upazila_code":33,
       "union_code":60,
       "union_name":"Joy Siddhi",
       "union_ban_name":"জয় সিদ্ধি"
    },
    {
       "_id":5329,
       "upazila_code":33,
       "union_code":86,
       "union_name":"Mriga",
       "union_ban_name":"মিজা"
    },
    {
       "_id":5330,
       "upazila_code":33,
       "union_code":94,
       "union_name":"Raituti",
       "union_ban_name":"রাইতুটি"
    },
    {
       "_id":5332,
       "upazila_code":42,
       "union_code":17,
       "union_name":"Dehunda",
       "union_ban_name":"দেহুন্ডা"
    },
    {
       "_id":5333,
       "upazila_code":42,
       "union_code":25,
       "union_name":"Gundhar",
       "union_ban_name":"গুন্ধর"
    },
    {
       "_id":5334,
       "upazila_code":42,
       "union_code":34,
       "union_name":"Gujadia",
       "union_ban_name":"গুজাদিয়া"
    },
    {
       "_id":5335,
       "upazila_code":42,
       "union_code":43,
       "union_name":"Jafarabad",
       "union_ban_name":"জাফরাবাদ"
    },
    {
       "_id":5336,
       "upazila_code":42,
       "union_code":51,
       "union_name":"Joyka",
       "union_ban_name":"জয়কা"
    },
    {
       "_id":5337,
       "upazila_code":42,
       "union_code":60,
       "union_name":"Kadir Jangal",
       "union_ban_name":"কাদির জঙ্গাল"
    },
    {
       "_id":5338,
       "upazila_code":42,
       "union_code":71,
       "union_name":"Kiratan",
       "union_ban_name":"কিরাতন"
    },
    {
       "_id":5340,
       "upazila_code":42,
       "union_code":86,
       "union_name":"Noabad",
       "union_ban_name":"নোয়াবাদ"
    },
    {
       "_id":5342,
       "upazila_code":45,
       "union_code":13,
       "union_name":"Achmita",
       "union_ban_name":"অচমিতা"
    },
    {
       "_id":5345,
       "upazila_code":45,
       "union_code":38,
       "union_name":"Jalalpur",
       "union_ban_name":"জালালপুর"
    },
    {
       "_id":5347,
       "upazila_code":45,
       "union_code":66,
       "union_name":"Lohajuri",
       "union_ban_name":"লোহাজুরি"
    },
    {
       "_id":5348,
       "upazila_code":45,
       "union_code":76,
       "union_name":"Masua",
       "union_ban_name":"মাসুয়া"
    },
    {
       "_id":5349,
       "upazila_code":45,
       "union_code":85,
       "union_name":"Mumurdia",
       "union_ban_name":"মুমুরদিয়া"
    },
    {
       "_id":5350,
       "upazila_code":45,
       "union_code":95,
       "union_name":"Shahasram Dhuldia",
       "union_ban_name":"শাহস্রম ধুলদিয়া"
    },
    {
       "_id":5351,
       "upazila_code":49,
       "union_code":16,
       "union_name":"Binnati",
       "union_ban_name":"বিন্নাতি"
    },
    {
       "_id":5352,
       "upazila_code":49,
       "union_code":17,
       "union_name":"Baulai",
       "union_ban_name":"বাউলাই"
    },
    {
       "_id":5353,
       "upazila_code":49,
       "union_code":25,
       "union_name":"Chauddasata",
       "union_ban_name":"চৌদ্দাসটা"
    },
    {
       "_id":5354,
       "upazila_code":49,
       "union_code":34,
       "union_name":"Dana Patali",
       "union_ban_name":"ডানা পাটিলি"
    },
    {
       "_id":5355,
       "upazila_code":49,
       "union_code":43,
       "union_name":"Jasodal",
       "union_ban_name":"জাসোডাল"
    },
    {
       "_id":5356,
       "upazila_code":49,
       "union_code":51,
       "union_name":"Korsha Kariail",
       "union_ban_name":"কোরশা করাইয়েল"
    },
    {
       "_id":5357,
       "upazila_code":49,
       "union_code":60,
       "union_name":"Latibabad",
       "union_ban_name":"লাতিবাবাদ"
    },
    {
       "_id":5358,
       "upazila_code":49,
       "union_code":69,
       "union_name":"Mahinanda",
       "union_ban_name":"মহিনন্দ"
    },
    {
       "_id":5359,
       "upazila_code":49,
       "union_code":77,
       "union_name":"Maij Khapan",
       "union_ban_name":"মিজ খাপন"
    },
    {
       "_id":5360,
       "upazila_code":49,
       "union_code":86,
       "union_name":"Maria",
       "union_ban_name":"মারিয়া"
    },
    {
       "_id":5361,
       "upazila_code":49,
       "union_code":94,
       "union_name":"Rashidabad",
       "union_ban_name":"রশিদাবাদ"
    },
    {
       "_id":5362,
       "upazila_code":54,
       "union_code":11,
       "union_name":"Chhaysuti",
       "union_ban_name":"ছায়াউটি"
    },
    {
       "_id":5364,
       "upazila_code":54,
       "union_code":35,
       "union_name":"Gobaria Abdullahpur",
       "union_ban_name":"গোবরিয়া আব্দুল্লাহপুর"
    },
    {
       "_id":5366,
       "upazila_code":54,
       "union_code":71,
       "union_name":"Ramdi",
       "union_ban_name":"রামদি"
    },
    {
       "_id":5367,
       "upazila_code":54,
       "union_code":83,
       "union_name":"Salua",
       "union_ban_name":"সালুয়া"
    },
    {
       "_id":5368,
       "upazila_code":59,
       "union_code":11,
       "union_name":"Bairati",
       "union_ban_name":"বৈরাতি"
    },
    {
       "_id":5369,
       "upazila_code":59,
       "union_code":13,
       "union_name":"Dhaki",
       "union_ban_name":"ধাকি"
    },
    {
       "_id":5370,
       "upazila_code":59,
       "union_code":27,
       "union_name":"Ghagra",
       "union_ban_name":"ঘাগড়া"
    },
    {
       "_id":5371,
       "upazila_code":59,
       "union_code":40,
       "union_name":"Gopedighi",
       "union_ban_name":"গোপেদিঘি"
    },
    {
       "_id":5372,
       "upazila_code":59,
       "union_code":54,
       "union_name":"Khatkhal",
       "union_ban_name":"খাতখাল"
    },
    {
       "_id":5373,
       "upazila_code":59,
       "union_code":67,
       "union_name":"Keorjori",
       "union_ban_name":"কেওরজুরি"
    },
    {
       "_id":5374,
       "upazila_code":59,
       "union_code":81,
       "union_name":"Mithamain",
       "union_ban_name":"মিঠামইন"
    },
    {
       "_id":5375,
       "upazila_code":76,
       "union_code":23,
       "union_name":"Chhatir Char",
       "union_ban_name":"ছাতির চর"
    },
    {
       "_id":5376,
       "upazila_code":76,
       "union_code":27,
       "union_name":"Dampara",
       "union_ban_name":"দামপাড়া"
    },
    {
       "_id":5377,
       "upazila_code":76,
       "union_code":38,
       "union_name":"Gurai",
       "union_ban_name":"গুরাই"
    },
    {
       "_id":5378,
       "upazila_code":76,
       "union_code":47,
       "union_name":"Jaraitala",
       "union_ban_name":"জড়াইতলা"
    },
    {
       "_id":5379,
       "upazila_code":76,
       "union_code":57,
       "union_name":"Karpasha",
       "union_ban_name":"কারপাশা"
    },
    {
       "_id":5380,
       "upazila_code":76,
       "union_code":76,
       "union_name":"Nikli",
       "union_ban_name":"নিকলি"
    },
    {
       "_id":5381,
       "upazila_code":76,
       "union_code":85,
       "union_name":"Singpur",
       "union_ban_name":"সিংপুর"
    },
    {
       "_id":5382,
       "upazila_code":79,
       "union_code":15,
       "union_name":"Barudia",
       "union_ban_name":"বারুদিয়া"
    },
    {
       "_id":5383,
       "upazila_code":79,
       "union_code":19,
       "union_name":"Chandi Pasha",
       "union_ban_name":"চন্দী পাশা"
    },
    {
       "_id":5384,
       "upazila_code":79,
       "union_code":28,
       "union_name":"Char Faradi",
       "union_ban_name":"চর ফারদী"
    },
    {
       "_id":5385,
       "upazila_code":79,
       "union_code":38,
       "union_name":"Egarasindur",
       "union_ban_name":"ইগারাসিন্ডুর"
    },
    {
       "_id":5386,
       "upazila_code":79,
       "union_code":47,
       "union_name":"Hosendi",
       "union_ban_name":"হোসেনডি"
    },
    {
       "_id":5388,
       "upazila_code":79,
       "union_code":66,
       "union_name":"Narandi",
       "union_ban_name":"নারন্দি"
    },
    {
       "_id":5389,
       "upazila_code":79,
       "union_code":85,
       "union_name":"Patuabhanga",
       "union_ban_name":"পটুয়াভঙ্গা"
    },
    {
       "_id":5390,
       "upazila_code":79,
       "union_code":95,
       "union_name":"Sukhia",
       "union_ban_name":"সুখিয়া"
    },
    {
       "_id":5391,
       "upazila_code":92,
       "union_code":13,
       "union_name":"Damiha",
       "union_ban_name":"দামিহা"
    },
    {
       "_id":5392,
       "upazila_code":92,
       "union_code":27,
       "union_name":"Dhala",
       "union_ban_name":"ধোলা"
    },
    {
       "_id":5394,
       "upazila_code":92,
       "union_code":54,
       "union_name":"Jawar",
       "union_ban_name":"জাওয়ার"
    },
    {
       "_id":5395,
       "upazila_code":92,
       "union_code":67,
       "union_name":"Rauti",
       "union_ban_name":"রাউটি"
    },
    {
       "_id":5396,
       "upazila_code":92,
       "union_code":81,
       "union_name":"Talganga",
       "union_ban_name":"তালগঙ্গা"
    },
    {
       "_id":5397,
       "upazila_code":92,
       "union_code":94,
       "union_name":"Tarail Sachail",
       "union_ban_name":"তারাইল সাচাইল"
    },
    {
       "_id":5398,
       "upazila_code":6,
       "union_code":13,
       "union_name":"Andhari Jhar",
       "union_ban_name":"অন্ধারি ঝাড়"
    },
    {
       "_id":5399,
       "upazila_code":6,
       "union_code":19,
       "union_name":"Bhurungamari",
       "union_ban_name":"ভুরুঙ্গামারী"
    },
    {
       "_id":5400,
       "upazila_code":6,
       "union_code":28,
       "union_name":"Boldia",
       "union_ban_name":"বোলডিয়া"
    },
    {
       "_id":5401,
       "upazila_code":6,
       "union_code":38,
       "union_name":"Bangasonahat",
       "union_ban_name":"বঙ্গসোনাহাট"
    },
    {
       "_id":5402,
       "upazila_code":6,
       "union_code":57,
       "union_name":"Joymanirhat",
       "union_ban_name":"জয়মনিরহাট"
    },
    {
       "_id":5403,
       "upazila_code":6,
       "union_code":66,
       "union_name":"Paiker Chhara",
       "union_ban_name":"পাইকার ছড়া"
    },
    {
       "_id":5404,
       "upazila_code":6,
       "union_code":76,
       "union_name":"Pathardubi",
       "union_ban_name":"পাথারদুবি"
    },
    {
       "_id":5405,
       "upazila_code":6,
       "union_code":85,
       "union_name":"Shilkhuri",
       "union_ban_name":"শিলখুরি"
    },
    {
       "_id":5406,
       "upazila_code":6,
       "union_code":95,
       "union_name":"Tilai",
       "union_ban_name":"তিলাই"
    },
    {
       "_id":5407,
       "upazila_code":8,
       "union_code":19,
       "union_name":"Char Rajibpur",
       "union_ban_name":"চর রাজিবপুর"
    },
    {
       "_id":5408,
       "upazila_code":8,
       "union_code":57,
       "union_name":"Kodailkati",
       "union_ban_name":"কোডাইলকাটি"
    },
    {
       "_id":5409,
       "upazila_code":8,
       "union_code":76,
       "union_name":"Mohanganj",
       "union_ban_name":"মোহনগঞ্জ"
    },
    {
       "_id":5410,
       "upazila_code":9,
       "union_code":11,
       "union_name":"Ashtamir Char",
       "union_ban_name":"অষ্টমীর চর"
    },
    {
       "_id":5411,
       "upazila_code":9,
       "union_code":23,
       "union_name":"Chilmari",
       "union_ban_name":"চিলমারী"
    },
    {
       "_id":5412,
       "upazila_code":9,
       "union_code":47,
       "union_name":"Nayerhat",
       "union_ban_name":"নয়ারহাট"
    },
    {
       "_id":5414,
       "upazila_code":9,
       "union_code":71,
       "union_name":"Raniganj",
       "union_ban_name":"রানীগঞ্জ"
    },
    {
       "_id":5415,
       "upazila_code":9,
       "union_code":83,
       "union_name":"Thanahat",
       "union_ban_name":"থানহাট"
    },
    {
       "_id":5416,
       "upazila_code":18,
       "union_code":13,
       "union_name":"Bara Bhita",
       "union_ban_name":"বার ভীতা"
    },
    {
       "_id":5417,
       "upazila_code":18,
       "union_code":27,
       "union_name":"Bhanagmore",
       "union_ban_name":"ভানগমোর"
    },
    {
       "_id":5418,
       "upazila_code":18,
       "union_code":40,
       "union_name":"Phulbari",
       "union_ban_name":"ফুলবাড়ী"
    },
    {
       "_id":5420,
       "upazila_code":18,
       "union_code":67,
       "union_name":"Naodanga",
       "union_ban_name":"নোডাঙ্গা"
    },
    {
       "_id":5421,
       "upazila_code":18,
       "union_code":81,
       "union_name":"Shimulbari",
       "union_ban_name":"শিমুলবাড়ী"
    },
    {
       "_id":5423,
       "upazila_code":52,
       "union_code":19,
       "union_name":"Bhogdanga",
       "union_ban_name":"ভোগদঙ্গা"
    },
    {
       "_id":5424,
       "upazila_code":52,
       "union_code":28,
       "union_name":"Ghogadaha",
       "union_ban_name":"ঘোগাদহা"
    },
    {
       "_id":5425,
       "upazila_code":52,
       "union_code":38,
       "union_name":"Holokhana",
       "union_ban_name":"হোলখানা"
    },
    {
       "_id":5427,
       "upazila_code":52,
       "union_code":57,
       "union_name":"Kanthalbari'",
       "union_ban_name":"কান্থালবাড়ী '"
    },
    {
       "_id":5428,
       "upazila_code":52,
       "union_code":76,
       "union_name":"Mogalbachha",
       "union_ban_name":"মোগলবাছা"
    },
    {
       "_id":5429,
       "upazila_code":52,
       "union_code":85,
       "union_name":"Punchgachhi",
       "union_ban_name":"পাঞ্চগাছি"
    },
    {
       "_id":5430,
       "upazila_code":61,
       "union_code":11,
       "union_name":"Ballabher Khas",
       "union_ban_name":"বল্লভের খাস"
    },
    {
       "_id":5432,
       "upazila_code":61,
       "union_code":18,
       "union_name":"Berubari",
       "union_ban_name":"বেরুবাড়ি"
    },
    {
       "_id":5433,
       "upazila_code":61,
       "union_code":25,
       "union_name":"Bhitarband",
       "union_ban_name":"ভিতারবান্দ"
    },
    {
       "_id":5435,
       "upazila_code":61,
       "union_code":37,
       "union_name":"Kachakata",
       "union_ban_name":"কাচাকাতা"
    },
    {
       "_id":5436,
       "upazila_code":61,
       "union_code":44,
       "union_name":"Kaliganj",
       "union_ban_name":"কালিগঞ্জ"
    },
    {
       "_id":5437,
       "upazila_code":61,
       "union_code":50,
       "union_name":"Kedar",
       "union_ban_name":"কেদর"
    },
    {
       "_id":5439,
       "upazila_code":61,
       "union_code":69,
       "union_name":"Newashi",
       "union_ban_name":"নিওয়াশি"
    },
    {
       "_id":5440,
       "upazila_code":61,
       "union_code":75,
       "union_name":"Noonkhawa",
       "union_ban_name":"নুনখওয়া"
    },
    {
       "_id":5441,
       "upazila_code":61,
       "union_code":82,
       "union_name":"Royganj",
       "union_ban_name":"রায়গঞ্জ"
    },
    {
       "_id":5442,
       "upazila_code":61,
       "union_code":88,
       "union_name":"Ramkhana",
       "union_ban_name":"রামখানা"
    },
    {
       "_id":5444,
       "upazila_code":77,
       "union_code":10,
       "union_name":"Bidyananda",
       "union_ban_name":"বিদেনন্দ"
    },
    {
       "_id":5445,
       "upazila_code":77,
       "union_code":21,
       "union_name":"Chakirpashar",
       "union_ban_name":"চকিরপশার"
    },
    {
       "_id":5446,
       "upazila_code":77,
       "union_code":31,
       "union_name":"Chhinai",
       "union_ban_name":"ছিন্নী"
    },
    {
       "_id":5447,
       "upazila_code":77,
       "union_code":42,
       "union_name":"Gharialdanga",
       "union_ban_name":"ঘরিয়ালডাঙ্গা"
    },
    {
       "_id":5448,
       "upazila_code":77,
       "union_code":52,
       "union_name":"Nazimkhan",
       "union_ban_name":"নাজিমখান"
    },
    {
       "_id":5449,
       "upazila_code":77,
       "union_code":73,
       "union_name":"Rajarhat",
       "union_ban_name":"রাজরহাট"
    },
    {
       "_id":5450,
       "upazila_code":77,
       "union_code":84,
       "union_name":"Omar Majid",
       "union_ban_name":"ওমর মজিদ"
    },
    {
       "_id":5451,
       "upazila_code":79,
       "union_code":11,
       "union_name":"Bandaber",
       "union_ban_name":"বানর"
    },
    {
       "_id":5452,
       "upazila_code":79,
       "union_code":23,
       "union_name":"Dantbhanga",
       "union_ban_name":"দানবঙ্গা"
    },
    {
       "_id":5453,
       "upazila_code":79,
       "union_code":35,
       "union_name":"Jadur Char",
       "union_ban_name":"জাদুর চর"
    },
    {
       "_id":5454,
       "upazila_code":79,
       "union_code":71,
       "union_name":"Raumari",
       "union_ban_name":"রাউমারী"
    },
    {
       "_id":5455,
       "upazila_code":79,
       "union_code":83,
       "union_name":"Saulmari",
       "union_ban_name":"সৌরমারী"
    },
    {
       "_id":5456,
       "upazila_code":94,
       "union_code":14,
       "union_name":"Begumganj",
       "union_ban_name":"বেগমগঞ্জ"
    },
    {
       "_id":5457,
       "upazila_code":94,
       "union_code":16,
       "union_name":"Bazra",
       "union_ban_name":"বাজার"
    },
    {
       "_id":5458,
       "upazila_code":94,
       "union_code":22,
       "union_name":"Buraburi",
       "union_ban_name":"বুরাবুরি"
    },
    {
       "_id":5459,
       "upazila_code":94,
       "union_code":33,
       "union_name":"Daldalia",
       "union_ban_name":"ডালডালিয়া"
    },
    {
       "_id":5460,
       "upazila_code":94,
       "union_code":39,
       "union_name":"Dhamserni",
       "union_ban_name":"ধামসার্নি"
    },
    {
       "_id":5461,
       "upazila_code":94,
       "union_code":44,
       "union_name":"Dharanibari",
       "union_ban_name":"ধরনিবাড়ি"
    },
    {
       "_id":5463,
       "upazila_code":94,
       "union_code":55,
       "union_name":"Gunaigachh",
       "union_ban_name":"গুনাইগাছ"
    },
    {
       "_id":5464,
       "upazila_code":94,
       "union_code":61,
       "union_name":"Hatia",
       "union_ban_name":"হাতিয়া"
    },
    {
       "_id":5465,
       "upazila_code":94,
       "union_code":72,
       "union_name":"Pandul",
       "union_ban_name":"পান্ডুল"
    },
    {
       "_id":5466,
       "upazila_code":94,
       "union_code":75,
       "union_name":"Saheber Alga",
       "union_ban_name":"সাহেব আলগা"
    },
    {
       "_id":5467,
       "upazila_code":94,
       "union_code":78,
       "union_name":"Tabakpur",
       "union_ban_name":"তাবকপুর"
    },
    {
       "_id":5468,
       "upazila_code":94,
       "union_code":83,
       "union_name":"Thetroy",
       "union_ban_name":"থেট্রয়"
    },
    {
       "_id":5470,
       "upazila_code":15,
       "union_code":27,
       "union_name":"Bahir Char",
       "union_ban_name":"বাহির চর"
    },
    {
       "_id":5471,
       "upazila_code":15,
       "union_code":40,
       "union_name":"Chandgram",
       "union_ban_name":"চাঁদগ্রাম"
    },
    {
       "_id":5472,
       "upazila_code":15,
       "union_code":54,
       "union_name":"Dharampur",
       "union_ban_name":"ধরমপুর"
    },
    {
       "_id":5473,
       "upazila_code":15,
       "union_code":67,
       "union_name":"Juniadaha",
       "union_ban_name":"জুনিয়াদহ"
    },
    {
       "_id":5474,
       "upazila_code":15,
       "union_code":81,
       "union_name":"Mokarimpur",
       "union_ban_name":"মোকারিমপুর"
    },
    {
       "_id":5475,
       "upazila_code":39,
       "union_code":11,
       "union_name":"Adabaria",
       "union_ban_name":"আদাবরিয়া"
    },
    {
       "_id":5476,
       "upazila_code":39,
       "union_code":13,
       "union_name":"Aria",
       "union_ban_name":"আরিয়া"
    },
    {
       "_id":5480,
       "upazila_code":39,
       "union_code":40,
       "union_name":"Hogalbaria",
       "union_ban_name":"হোগালবারিয়া"
    },
    {
       "_id":5481,
       "upazila_code":39,
       "union_code":47,
       "union_name":"Khalishakundi",
       "union_ban_name":"খালিশকুন্দি"
    },
    {
       "_id":5484,
       "upazila_code":39,
       "union_code":67,
       "union_name":"Pearpur",
       "union_ban_name":"পেয়ারপুর"
    },
    {
       "_id":5485,
       "upazila_code":39,
       "union_code":74,
       "union_name":"Philipnagar",
       "union_ban_name":"ফিলিপনগর"
    },
    {
       "_id":5486,
       "upazila_code":39,
       "union_code":81,
       "union_name":"Prayagpur",
       "union_ban_name":"প্রয়াগপুর"
    },
    {
       "_id":5487,
       "upazila_code":39,
       "union_code":88,
       "union_name":"Ramkrishnapur",
       "union_ban_name":"রামকৃষ্ণপুর"
    },
    {
       "_id":5488,
       "upazila_code":39,
       "union_code":94,
       "union_name":"Refayetpur",
       "union_ban_name":"রেফায়িতপুর"
    },
    {
       "_id":5489,
       "upazila_code":63,
       "union_code":17,
       "union_name":"Ambaria",
       "union_ban_name":"আম্বরিয়া"
    },
    {
       "_id":5490,
       "upazila_code":63,
       "union_code":25,
       "union_name":"Betbaria",
       "union_ban_name":"বেতবাড়িয়া"
    },
    {
       "_id":5491,
       "upazila_code":63,
       "union_code":31,
       "union_name":"Gopagram",
       "union_ban_name":"গোপাগাম"
    },
    {
       "_id":5492,
       "upazila_code":63,
       "union_code":37,
       "union_name":"Janipur",
       "union_ban_name":"জনিপুর"
    },
    {
       "_id":5493,
       "upazila_code":63,
       "union_code":43,
       "union_name":"Jayanti Hajra",
       "union_ban_name":"জয়ন্ত হজরা"
    },
    {
       "_id":5494,
       "upazila_code":63,
       "union_code":47,
       "union_name":"Khoksa",
       "union_ban_name":"খোকসা"
    },
    {
       "_id":5496,
       "upazila_code":63,
       "union_code":71,
       "union_name":"Samaspur",
       "union_ban_name":"সামাসপুর"
    },
    {
       "_id":5498,
       "upazila_code":71,
       "union_code":16,
       "union_name":"Bagulat",
       "union_ban_name":"বাগুলত"
    },
    {
       "_id":5500,
       "upazila_code":71,
       "union_code":25,
       "union_name":"Chapra",
       "union_ban_name":"চ্যাপড়া"
    },
    {
       "_id":5501,
       "upazila_code":71,
       "union_code":34,
       "union_name":"Jadu Boyra",
       "union_ban_name":"জাদু বয়রা"
    },
    {
       "_id":5503,
       "upazila_code":71,
       "union_code":51,
       "union_name":"Kaya",
       "union_ban_name":"কায়া"
    },
    {
       "_id":5504,
       "upazila_code":71,
       "union_code":69,
       "union_name":"Nandalalpur",
       "union_ban_name":"নন্দলালপুর"
    },
    {
       "_id":5505,
       "upazila_code":71,
       "union_code":77,
       "union_name":"Panti",
       "union_ban_name":"প্যান্টি"
    },
    {
       "_id":5506,
       "upazila_code":71,
       "union_code":86,
       "union_name":"Sadaki",
       "union_ban_name":"সাদাকি"
    },
    {
       "_id":5507,
       "upazila_code":71,
       "union_code":90,
       "union_name":"Sadipur",
       "union_ban_name":"সাদিপুর"
    },
    {
       "_id":5508,
       "upazila_code":71,
       "union_code":94,
       "union_name":"Shelaidaha",
       "union_ban_name":"শেলাইদাহা"
    },
    {
       "_id":5510,
       "upazila_code":79,
       "union_code":17,
       "union_name":"Ailchara",
       "union_ban_name":"আইলচারা"
    },
    {
       "_id":5512,
       "upazila_code":79,
       "union_code":25,
       "union_name":"Barakhada",
       "union_ban_name":"বারখাদা"
    },
    {
       "_id":5513,
       "upazila_code":79,
       "union_code":31,
       "union_name":"Gosind Durgapur",
       "union_ban_name":"গোসিন দুর্গাপুর"
    },
    {
       "_id":5514,
       "upazila_code":79,
       "union_code":37,
       "union_name":"Harinarayanpur",
       "union_ban_name":"হারীনাইনণপুর"
    },
    {
       "_id":5515,
       "upazila_code":79,
       "union_code":44,
       "union_name":"Hatas Haripur",
       "union_ban_name":"হাটস হরিপুর"
    },
    {
       "_id":5516,
       "upazila_code":79,
       "union_code":50,
       "union_name":"Battail",
       "union_ban_name":"ব্যাটেল"
    },
    {
       "_id":5517,
       "upazila_code":79,
       "union_code":56,
       "union_name":"Jiarakhi",
       "union_ban_name":"জিয়াখি"
    },
    {
       "_id":5518,
       "upazila_code":79,
       "union_code":63,
       "union_name":"Jhaudia",
       "union_ban_name":"ঝাউটিয়া"
    },
    {
       "_id":5519,
       "upazila_code":79,
       "union_code":75,
       "union_name":"Mazampur",
       "union_ban_name":"মাজামপুর"
    },
    {
       "_id":5520,
       "upazila_code":79,
       "union_code":82,
       "union_name":"Manohardia",
       "union_ban_name":"মনোহরদিয়া"
    },
    {
       "_id":5521,
       "upazila_code":79,
       "union_code":88,
       "union_name":"Paitkabari",
       "union_ban_name":"পাইতকাবাড়ি"
    },
    {
       "_id":5522,
       "upazila_code":79,
       "union_code":94,
       "union_name":"Ujangram",
       "union_ban_name":"উজানগ্রাম"
    },
    {
       "_id":5524,
       "upazila_code":94,
       "union_code":14,
       "union_name":"Amla",
       "union_ban_name":"আমলা"
    },
    {
       "_id":5525,
       "upazila_code":94,
       "union_code":21,
       "union_name":"Bahalbaria",
       "union_ban_name":"বাহালবাড়িয়া"
    },
    {
       "_id":5526,
       "upazila_code":94,
       "union_code":29,
       "union_name":"Barui Para",
       "union_ban_name":"বারুই পারা"
    },
    {
       "_id":5527,
       "upazila_code":94,
       "union_code":36,
       "union_name":"Chhatian",
       "union_ban_name":"ছাতিয়ান"
    },
    {
       "_id":5528,
       "upazila_code":94,
       "union_code":43,
       "union_name":"Chithulia",
       "union_ban_name":"চিতুলিয়া"
    },
    {
       "_id":5529,
       "upazila_code":94,
       "union_code":58,
       "union_name":"Kursha",
       "union_ban_name":"কুরশা"
    },
    {
       "_id":5530,
       "upazila_code":94,
       "union_code":65,
       "union_name":"Malihad",
       "union_ban_name":"মালিহাদ"
    },
    {
       "_id":5532,
       "upazila_code":94,
       "union_code":80,
       "union_name":"Poradaha",
       "union_ban_name":"পোড়াদহ"
    },
    {
       "_id":5533,
       "upazila_code":94,
       "union_code":87,
       "union_name":"Sardarpur",
       "union_ban_name":"সরদারপুর"
    },
    {
       "_id":5534,
       "upazila_code":94,
       "union_code":94,
       "union_name":"Talbaria",
       "union_ban_name":"তালবাড়িয়া"
    },
    {
       "_id":5535,
       "upazila_code":33,
       "union_code":47,
       "union_name":"Char Falcon",
       "union_ban_name":"চর ফ্যালকন"
    },
    {
       "_id":5536,
       "upazila_code":33,
       "union_code":63,
       "union_name":"Char Kadira",
       "union_ban_name":"চর কাদীরা"
    },
    {
       "_id":5537,
       "upazila_code":33,
       "union_code":71,
       "union_name":"Char Kalkini",
       "union_ban_name":"চর কালকিনি"
    },
    {
       "_id":5538,
       "upazila_code":33,
       "union_code":79,
       "union_name":"Char Lawrence",
       "union_ban_name":"গৃহস্থালি লরেন্স"
    },
    {
       "_id":5539,
       "upazila_code":33,
       "union_code":81,
       "union_name":"Char Martin",
       "union_ban_name":"চার মার্টিন"
    },
    {
       "_id":5541,
       "upazila_code":33,
       "union_code":87,
       "union_name":"Patarir Hat",
       "union_ban_name":"পাতারির হাট"
    },
    {
       "_id":5542,
       "upazila_code":33,
       "union_code":91,
       "union_name":"Torabgang",
       "union_ban_name":"তোরাবগাং"
    },
    {
       "_id":5543,
       "upazila_code":33,
       "union_code":95,
       "union_name":"Saheberhat",
       "union_ban_name":"সাহেবেরহাট"
    },
    {
       "_id":5544,
       "upazila_code":43,
       "union_code":13,
       "union_name":"Bangakha",
       "union_ban_name":"বঙ্গখা"
    },
    {
       "_id":5545,
       "upazila_code":43,
       "union_code":14,
       "union_name":"Basikpur",
       "union_ban_name":"বাসিকপুর"
    },
    {
       "_id":5546,
       "upazila_code":43,
       "union_code":15,
       "union_name":"Bhabaniganj",
       "union_ban_name":"ভবানীগঞ্জ"
    },
    {
       "_id":5547,
       "upazila_code":43,
       "union_code":20,
       "union_name":"Chandraganj",
       "union_ban_name":"চন্দ্রগঞ্জ"
    },
    {
       "_id":5548,
       "upazila_code":43,
       "union_code":23,
       "union_name":"Charramani Mohan",
       "union_ban_name":"চররমণি মোহন"
    },
    {
       "_id":5549,
       "upazila_code":43,
       "union_code":25,
       "union_name":"Charsai",
       "union_ban_name":"চারসাই"
    },
    {
       "_id":5550,
       "upazila_code":43,
       "union_code":30,
       "union_name":"Char Ruhita",
       "union_ban_name":"চর রুহিতা"
    },
    {
       "_id":5551,
       "upazila_code":43,
       "union_code":35,
       "union_name":"Dalal Bazar",
       "union_ban_name":"দালাল বাজার"
    },
    {
       "_id":5552,
       "upazila_code":43,
       "union_code":40,
       "union_name":"Datta Para",
       "union_ban_name":"দত্ত প্যারা"
    },
    {
       "_id":5553,
       "upazila_code":43,
       "union_code":45,
       "union_name":"Dighali",
       "union_ban_name":"দিঘালি"
    },
    {
       "_id":5554,
       "upazila_code":43,
       "union_code":50,
       "union_name":"Hajir Para",
       "union_ban_name":"হজির পারা"
    },
    {
       "_id":5555,
       "upazila_code":43,
       "union_code":55,
       "union_name":"Kushakhali",
       "union_ban_name":"কুশখালী"
    },
    {
       "_id":5556,
       "upazila_code":43,
       "union_code":60,
       "union_name":"Laharkandi",
       "union_ban_name":"লাহকরান্দি"
    },
    {
       "_id":5558,
       "upazila_code":43,
       "union_code":75,
       "union_name":"Uttar Hamchadi",
       "union_ban_name":"উত্তর হামচাদি"
    },
    {
       "_id":5559,
       "upazila_code":43,
       "union_code":80,
       "union_name":"Parbatinagar",
       "union_ban_name":"পার্বতিনগর"
    },
    {
       "_id":5560,
       "upazila_code":43,
       "union_code":85,
       "union_name":"Shak Char",
       "union_ban_name":"শাক চর"
    },
    {
       "_id":5561,
       "upazila_code":43,
       "union_code":87,
       "union_name":"Tiariganj",
       "union_ban_name":"তিয়রিগঞ্জ"
    },
    {
       "_id":5562,
       "upazila_code":43,
       "union_code":89,
       "union_name":"Tum Char",
       "union_ban_name":"তুম চর"
    },
    {
       "_id":5563,
       "upazila_code":43,
       "union_code":90,
       "union_name":"Dakshin Hamchadi",
       "union_ban_name":"দক্ষিণ হামচাদি"
    },
    {
       "_id":5564,
       "upazila_code":43,
       "union_code":95,
       "union_name":"Uttar Joypur",
       "union_ban_name":"উত্তর জয়পুর"
    },
    {
       "_id":5565,
       "upazila_code":58,
       "union_code":11,
       "union_name":"Bamni",
       "union_ban_name":"বামনি"
    },
    {
       "_id":5566,
       "upazila_code":58,
       "union_code":23,
       "union_name":"North Char Ababil",
       "union_ban_name":"উত্তর চর আবাবিল"
    },
    {
       "_id":5567,
       "upazila_code":58,
       "union_code":28,
       "union_name":"South Char Ababil",
       "union_ban_name":"দক্ষিণ চর আবাবিল"
    },
    {
       "_id":5568,
       "upazila_code":58,
       "union_code":35,
       "union_name":"North Char Bangshi",
       "union_ban_name":"উত্তর চর বঙ্গশী"
    },
    {
       "_id":5569,
       "upazila_code":58,
       "union_code":38,
       "union_name":"South Char Bangshi",
       "union_ban_name":"দক্ষিণ চর বঙ্গশী"
    },
    {
       "_id":5570,
       "upazila_code":58,
       "union_code":47,
       "union_name":"Char Mohana",
       "union_ban_name":"চর মোহনানা"
    },
    {
       "_id":5572,
       "upazila_code":58,
       "union_code":59,
       "union_name":"Keroa",
       "union_ban_name":"কেরো"
    },
    {
       "_id":5575,
       "upazila_code":65,
       "union_code":13,
       "union_name":"Bhadur",
       "union_ban_name":"ভাদুর"
    },
    {
       "_id":5577,
       "upazila_code":65,
       "union_code":19,
       "union_name":"Bholakot",
       "union_ban_name":"ভোলাকট"
    },
    {
       "_id":5579,
       "upazila_code":65,
       "union_code":33,
       "union_name":"Darbeshpur",
       "union_ban_name":"দারবেশপুর"
    },
    {
       "_id":5580,
       "upazila_code":65,
       "union_code":38,
       "union_name":"Ichhapur",
       "union_ban_name":"ইছাপুর"
    },
    {
       "_id":5581,
       "upazila_code":65,
       "union_code":42,
       "union_name":"Kanchanpur",
       "union_ban_name":"কাঞ্চনপুর"
    },
    {
       "_id":5583,
       "upazila_code":65,
       "union_code":57,
       "union_name":"Lamchar",
       "union_ban_name":"লামচর"
    },
    {
       "_id":5585,
       "upazila_code":73,
       "union_code":13,
       "union_name":"Bara Kheri",
       "union_ban_name":"বার খেরী"
    },
    {
       "_id":5586,
       "upazila_code":73,
       "union_code":15,
       "union_name":"Char Abdullah",
       "union_ban_name":"চর আবদুল্লাহ"
    },
    {
       "_id":5587,
       "upazila_code":73,
       "union_code":23,
       "union_name":"Char Alexandar",
       "union_ban_name":"গৃহস্থালি আলেকজান্ডার"
    },
    {
       "_id":5588,
       "upazila_code":73,
       "union_code":31,
       "union_name":"Char Algi",
       "union_ban_name":"চর আলজি"
    },
    {
       "_id":5589,
       "upazila_code":73,
       "union_code":39,
       "union_name":"Char Badam",
       "union_ban_name":"চর বাডাম"
    },
    {
       "_id":5590,
       "upazila_code":73,
       "union_code":55,
       "union_name":"Char Gazi",
       "union_ban_name":"চার গাজী"
    },
    {
       "_id":5591,
       "upazila_code":73,
       "union_code":65,
       "union_name":"Char Poragachha",
       "union_ban_name":"চর পোরাগাছা"
    },
    {
       "_id":5592,
       "upazila_code":73,
       "union_code":87,
       "union_name":"Char Ramiz",
       "union_ban_name":"চর রামিজ"
    },
    {
       "_id":5593,
       "upazila_code":2,
       "union_code":10,
       "union_name":"Bhadai",
       "union_ban_name":"ভাদাই"
    },
    {
       "_id":5595,
       "upazila_code":2,
       "union_code":41,
       "union_name":"Durgapur'",
       "union_ban_name":"দুর্গাপুর '"
    },
    {
       "_id":5596,
       "upazila_code":2,
       "union_code":59,
       "union_name":"Kamalabari",
       "union_ban_name":"কামালবাড়ী"
    },
    {
       "_id":5597,
       "upazila_code":2,
       "union_code":71,
       "union_name":"Mahishkhocha",
       "union_ban_name":"মহিশখোচ"
    },
    {
       "_id":5598,
       "upazila_code":2,
       "union_code":77,
       "union_name":"Palashi",
       "union_ban_name":"পলাশী"
    },
    {
       "_id":5599,
       "upazila_code":2,
       "union_code":83,
       "union_name":"Saptibari",
       "union_ban_name":"সপ্তদরি"
    },
    {
       "_id":5600,
       "upazila_code":2,
       "union_code":89,
       "union_name":"Sarpukur",
       "union_ban_name":"সারপুকুর"
    },
    {
       "_id":5601,
       "upazila_code":33,
       "union_code":15,
       "union_name":"Barakhata",
       "union_ban_name":"বারাকটা"
    },
    {
       "_id":5602,
       "upazila_code":33,
       "union_code":19,
       "union_name":"Bhalaguri",
       "union_ban_name":"ভলগুরি"
    },
    {
       "_id":5603,
       "upazila_code":33,
       "union_code":28,
       "union_name":"Daoabari",
       "union_ban_name":"দোবাওয়ারি"
    },
    {
       "_id":5604,
       "upazila_code":33,
       "union_code":31,
       "union_name":"Fakir Para'",
       "union_ban_name":"ফকির প্যারা '"
    },
    {
       "_id":5605,
       "upazila_code":33,
       "union_code":38,
       "union_name":"Goddimari",
       "union_ban_name":"দেবদেবারী"
    },
    {
       "_id":5606,
       "upazila_code":33,
       "union_code":47,
       "union_name":"Gotamari",
       "union_ban_name":"গোতামারি"
    },
    {
       "_id":5607,
       "upazila_code":33,
       "union_code":57,
       "union_name":"Nowdabash",
       "union_ban_name":"নইদাশ"
    },
    {
       "_id":5608,
       "upazila_code":33,
       "union_code":66,
       "union_name":"Patika Para",
       "union_ban_name":"পটিকা প্যারা"
    },
    {
       "_id":5609,
       "upazila_code":33,
       "union_code":74,
       "union_name":"Saniajan",
       "union_ban_name":"সানিয়াজন"
    },
    {
       "_id":5610,
       "upazila_code":33,
       "union_code":76,
       "union_name":"Shingimari",
       "union_ban_name":"শিংমারী"
    },
    {
       "_id":5611,
       "upazila_code":33,
       "union_code":85,
       "union_name":"Sindurna",
       "union_ban_name":"সিন্ধুতা"
    },
    {
       "_id":5612,
       "upazila_code":33,
       "union_code":95,
       "union_name":"Tangbhanga",
       "union_ban_name":"তাংভাঙ্গা"
    },
    {
       "_id":5613,
       "upazila_code":39,
       "union_code":17,
       "union_name":"Bhotemari",
       "union_ban_name":"ভোতমারী"
    },
    {
       "_id":5614,
       "upazila_code":39,
       "union_code":23,
       "union_name":"Chalbala",
       "union_ban_name":"চালবালা"
    },
    {
       "_id":5615,
       "upazila_code":39,
       "union_code":29,
       "union_name":"Chandrapur",
       "union_ban_name":"চন্দ্রপুর"
    },
    {
       "_id":5616,
       "upazila_code":39,
       "union_code":35,
       "union_name":"Dalagram",
       "union_ban_name":"ডালগ্রাম"
    },
    {
       "_id":5617,
       "upazila_code":39,
       "union_code":47,
       "union_name":"Goral",
       "union_ban_name":"গোলাল"
    },
    {
       "_id":5618,
       "upazila_code":39,
       "union_code":53,
       "union_name":"Kakina",
       "union_ban_name":"কাকিনা"
    },
    {
       "_id":5619,
       "upazila_code":39,
       "union_code":65,
       "union_name":"Madati",
       "union_ban_name":"মাদাতী"
    },
    {
       "_id":5620,
       "upazila_code":39,
       "union_code":95,
       "union_name":"Tushbhandar",
       "union_ban_name":"তুষভান্ডার"
    },
    {
       "_id":5621,
       "upazila_code":55,
       "union_code":20,
       "union_name":"Barabari'",
       "union_ban_name":"বড়বাড়ি '"
    },
    {
       "_id":5622,
       "upazila_code":55,
       "union_code":29,
       "union_name":"Gokunda",
       "union_ban_name":"গোকুন্ডা"
    },
    {
       "_id":5623,
       "upazila_code":55,
       "union_code":36,
       "union_name":"Harati",
       "union_ban_name":"হারতি"
    },
    {
       "_id":5624,
       "upazila_code":55,
       "union_code":43,
       "union_name":"Khuniagachh",
       "union_ban_name":"খুনিয়াগাছ"
    },
    {
       "_id":5625,
       "upazila_code":55,
       "union_code":51,
       "union_name":"Kulaghat",
       "union_ban_name":"কুলঘাট"
    },
    {
       "_id":5626,
       "upazila_code":55,
       "union_code":65,
       "union_name":"Mogalhat",
       "union_ban_name":"মোগলহাট"
    },
    {
       "_id":5627,
       "upazila_code":55,
       "union_code":73,
       "union_name":"Mahendranagar",
       "union_ban_name":"মহেন্দ্রনগর"
    },
    {
       "_id":5628,
       "upazila_code":55,
       "union_code":83,
       "union_name":"Panchagram",
       "union_ban_name":"পঞ্চগ্রাম"
    },
    {
       "_id":5629,
       "upazila_code":55,
       "union_code":94,
       "union_name":"Rajpur",
       "union_ban_name":"রাজপুর"
    },
    {
       "_id":5630,
       "upazila_code":70,
       "union_code":13,
       "union_name":"Baura",
       "union_ban_name":"বৌড়া"
    },
    {
       "_id":5631,
       "upazila_code":70,
       "union_code":15,
       "union_name":"Burimari",
       "union_ban_name":"বুরিমারি"
    },
    {
       "_id":5632,
       "upazila_code":70,
       "union_code":19,
       "union_name":"Dahagram",
       "union_ban_name":"দাহগ্রাম"
    },
    {
       "_id":5633,
       "upazila_code":70,
       "union_code":27,
       "union_name":"Jagatber",
       "union_ban_name":"জগৎবার"
    },
    {
       "_id":5634,
       "upazila_code":70,
       "union_code":40,
       "union_name":"Jongra",
       "union_ban_name":"জংরা"
    },
    {
       "_id":5635,
       "upazila_code":70,
       "union_code":54,
       "union_name":"Kuchlibari",
       "union_ban_name":"কুচলিবাড়ি"
    },
    {
       "_id":5636,
       "upazila_code":70,
       "union_code":67,
       "union_name":"Patgram",
       "union_ban_name":"প্যাটগ্রাম"
    },
    {
       "_id":5639,
       "upazila_code":40,
       "union_code":12,
       "union_name":"Baligram",
       "union_ban_name":"বালিগ্রাম"
    },
    {
       "_id":5640,
       "upazila_code":40,
       "union_code":18,
       "union_name":"Banshgari",
       "union_ban_name":"বাঁশগড়ী"
    },
    {
       "_id":5641,
       "upazila_code":40,
       "union_code":25,
       "union_name":"Char Daulat Khan",
       "union_ban_name":"চর দৌলত খান"
    },
    {
       "_id":5642,
       "upazila_code":40,
       "union_code":31,
       "union_name":"Dasar",
       "union_ban_name":"দাশার"
    },
    {
       "_id":5643,
       "upazila_code":40,
       "union_code":37,
       "union_name":"Enayetnagar",
       "union_ban_name":"এনায়েতনগর."
    },
    {
       "_id":5645,
       "upazila_code":40,
       "union_code":56,
       "union_name":"Kayaria",
       "union_ban_name":"কায়ারিয়া"
    },
    {
       "_id":5646,
       "upazila_code":40,
       "union_code":63,
       "union_name":"Kazibakai",
       "union_ban_name":"কাজিবাকাই"
    },
    {
       "_id":5649,
       "upazila_code":40,
       "union_code":82,
       "union_name":"Ramjanpur",
       "union_ban_name":"রামজনপুর"
    },
    {
       "_id":5650,
       "upazila_code":40,
       "union_code":88,
       "union_name":"Sahebrampur",
       "union_ban_name":"সাহেবামপুর"
    },
    {
       "_id":5651,
       "upazila_code":40,
       "union_code":94,
       "union_name":"Shikar Mangal",
       "union_ban_name":"শিকার মংল"
    },
    {
       "_id":5653,
       "upazila_code":54,
       "union_code":11,
       "union_name":"Chilar Char",
       "union_ban_name":"চের চর"
    },
    {
       "_id":5654,
       "upazila_code":54,
       "union_code":17,
       "union_name":"Dhurail",
       "union_ban_name":"ধুরাইল"
    },
    {
       "_id":5655,
       "upazila_code":54,
       "union_code":23,
       "union_name":"Dudkhali",
       "union_ban_name":"দুদখালী"
    },
    {
       "_id":5656,
       "upazila_code":54,
       "union_code":29,
       "union_name":"Ghatmajhi",
       "union_ban_name":"ঘাটমঝি"
    },
    {
       "_id":5657,
       "upazila_code":54,
       "union_code":35,
       "union_name":"Jhaudi",
       "union_ban_name":"ঝুদি"
    },
    {
       "_id":5660,
       "upazila_code":54,
       "union_code":53,
       "union_name":"Khoajpur",
       "union_ban_name":"খোজপুর"
    },
    {
       "_id":5661,
       "upazila_code":54,
       "union_code":59,
       "union_name":"Kunia",
       "union_ban_name":"কুনিয়া"
    },
    {
       "_id":5662,
       "upazila_code":54,
       "union_code":71,
       "union_name":"Mustafapur",
       "union_ban_name":"মুস্তাফাপুর"
    },
    {
       "_id":5663,
       "upazila_code":54,
       "union_code":77,
       "union_name":"Panchkhola",
       "union_ban_name":"পাঁচখোলা"
    },
    {
       "_id":5665,
       "upazila_code":54,
       "union_code":89,
       "union_name":"Rasti",
       "union_ban_name":"রাষ্টী"
    },
    {
       "_id":5666,
       "upazila_code":54,
       "union_code":94,
       "union_name":"Sirkhara",
       "union_ban_name":"সিরাকারা"
    },
    {
       "_id":5667,
       "upazila_code":80,
       "union_code":13,
       "union_name":"Amgram",
       "union_ban_name":"আমগ্রাম"
    },
    {
       "_id":5668,
       "upazila_code":80,
       "union_code":19,
       "union_name":"Badar Pasha",
       "union_ban_name":"বদর পাশা"
    },
    {
       "_id":5669,
       "upazila_code":80,
       "union_code":28,
       "union_name":"Bajitpur",
       "union_ban_name":"বাজিতপুর"
    },
    {
       "_id":5670,
       "upazila_code":80,
       "union_code":31,
       "union_name":"Haridasdi Mahendradi",
       "union_ban_name":"হরিদাসদি মহেন্দ্রদী"
    },
    {
       "_id":5672,
       "upazila_code":80,
       "union_code":47,
       "union_name":"Isibpur",
       "union_ban_name":"ইসিবপুর"
    },
    {
       "_id":5673,
       "upazila_code":80,
       "union_code":57,
       "union_name":"Kabirajpur",
       "union_ban_name":"কবিরাজপুর"
    },
    {
       "_id":5674,
       "upazila_code":80,
       "union_code":66,
       "union_name":"Kadambari",
       "union_ban_name":"কাদম্বারী"
    },
    {
       "_id":5675,
       "upazila_code":80,
       "union_code":76,
       "union_name":"Khalia",
       "union_ban_name":"খালিয়া"
    },
    {
       "_id":5677,
       "upazila_code":80,
       "union_code":95,
       "union_name":"Rajoir",
       "union_ban_name":"রাজোইর."
    },
    {
       "_id":5678,
       "upazila_code":87,
       "union_code":11,
       "union_name":"Bandarkhola",
       "union_ban_name":"বন্দরখোলা"
    },
    {
       "_id":5679,
       "upazila_code":87,
       "union_code":13,
       "union_name":"Banshkandi",
       "union_ban_name":"বাঁশকান্দি"
    },
    {
       "_id":5680,
       "upazila_code":87,
       "union_code":15,
       "union_name":"Bayratala Dakshin",
       "union_ban_name":"বায়রাতলা দক্ষিণ"
    },
    {
       "_id":5681,
       "upazila_code":87,
       "union_code":18,
       "union_name":"Bayratala Uttar",
       "union_ban_name":"বায়রাতলা উত্তর"
    },
    {
       "_id":5682,
       "upazila_code":87,
       "union_code":21,
       "union_name":"Bhadrasan",
       "union_ban_name":"ভদ্রাসন"
    },
    {
       "_id":5683,
       "upazila_code":87,
       "union_code":26,
       "union_name":"Bhandarikandi",
       "union_ban_name":"ভান্ডারিকান্দি"
    },
    {
       "_id":5684,
       "upazila_code":87,
       "union_code":31,
       "union_name":"Char Janajat",
       "union_ban_name":"চর জনজাত"
    },
    {
       "_id":5686,
       "upazila_code":87,
       "union_code":42,
       "union_name":"Ditiyakhanda",
       "union_ban_name":"দিতিয়াখণ্ড"
    },
    {
       "_id":5687,
       "upazila_code":87,
       "union_code":47,
       "union_name":"Kadirpur",
       "union_ban_name":"কাদিরপুর"
    },
    {
       "_id":5688,
       "upazila_code":87,
       "union_code":52,
       "union_name":"Kanthalbari",
       "union_ban_name":"কান্থালবাড়ি"
    },
    {
       "_id":5690,
       "upazila_code":87,
       "union_code":63,
       "union_name":"Matbarer Char",
       "union_ban_name":"মাতবেরার চর"
    },
    {
       "_id":5692,
       "upazila_code":87,
       "union_code":73,
       "union_name":"Panch Char",
       "union_ban_name":"পঞ্চ চর"
    },
    {
       "_id":5693,
       "upazila_code":87,
       "union_code":79,
       "union_name":"Sannyasir Char",
       "union_ban_name":"সন্ন্যাসীর চর"
    },
    {
       "_id":5694,
       "upazila_code":87,
       "union_code":84,
       "union_name":"Shibchar",
       "union_ban_name":"শিবচর"
    },
    {
       "_id":5695,
       "upazila_code":87,
       "union_code":89,
       "union_name":"Siruail",
       "union_ban_name":"সিরিয়াইল"
    },
    {
       "_id":5697,
       "upazila_code":57,
       "union_code":21,
       "union_name":"Atharakhada",
       "union_ban_name":"অথরখদা"
    },
    {
       "_id":5698,
       "upazila_code":57,
       "union_code":23,
       "union_name":"Birail Palita",
       "union_ban_name":"বিরল পলিতা"
    },
    {
       "_id":5699,
       "upazila_code":57,
       "union_code":25,
       "union_name":"Bagia",
       "union_ban_name":"বাগিয়া"
    },
    {
       "_id":5700,
       "upazila_code":57,
       "union_code":27,
       "union_name":"Chaulia",
       "union_ban_name":"চৌলিয়া"
    },
    {
       "_id":5701,
       "upazila_code":57,
       "union_code":33,
       "union_name":"Gopalgram",
       "union_ban_name":"গোপালগ্রাম"
    },
    {
       "_id":5702,
       "upazila_code":57,
       "union_code":40,
       "union_name":"Hazipur",
       "union_ban_name":"হাজীপুর"
    },
    {
       "_id":5703,
       "upazila_code":57,
       "union_code":47,
       "union_name":"Hazrapur",
       "union_ban_name":"হাজরাপুর"
    },
    {
       "_id":5704,
       "upazila_code":57,
       "union_code":54,
       "union_name":"Jagdal",
       "union_ban_name":"জগদল"
    },
    {
       "_id":5705,
       "upazila_code":57,
       "union_code":61,
       "union_name":"Kasundi",
       "union_ban_name":"কাসুন্দি"
    },
    {
       "_id":5706,
       "upazila_code":57,
       "union_code":67,
       "union_name":"Kuchiamora",
       "union_ban_name":"কুচিয়ামোড়া"
    },
    {
       "_id":5707,
       "upazila_code":57,
       "union_code":74,
       "union_name":"Maghi",
       "union_ban_name":"মাগি"
    },
    {
       "_id":5708,
       "upazila_code":57,
       "union_code":88,
       "union_name":"Raghab Dair",
       "union_ban_name":"রাঘব দায়ের"
    },
    {
       "_id":5710,
       "upazila_code":66,
       "union_code":10,
       "union_name":"Babukhali",
       "union_ban_name":"বাবুখালী"
    },
    {
       "_id":5711,
       "upazila_code":66,
       "union_code":21,
       "union_name":"Balidia",
       "union_ban_name":"বালিদিয়া"
    },
    {
       "_id":5712,
       "upazila_code":66,
       "union_code":31,
       "union_name":"Binodepur",
       "union_ban_name":"বিন্দীপুর"
    },
    {
       "_id":5713,
       "upazila_code":66,
       "union_code":42,
       "union_name":"Digha",
       "union_ban_name":"দিঘা"
    },
    {
       "_id":5715,
       "upazila_code":66,
       "union_code":63,
       "union_name":"Nahata",
       "union_ban_name":"নাহটা"
    },
    {
       "_id":5716,
       "upazila_code":66,
       "union_code":73,
       "union_name":"Palashbaria",
       "union_ban_name":"পলাশবাড়িয়া"
    },
    {
       "_id":5718,
       "upazila_code":85,
       "union_code":11,
       "union_name":"Arpara",
       "union_ban_name":"আরপাড়া"
    },
    {
       "_id":5719,
       "upazila_code":85,
       "union_code":23,
       "union_name":"Bunagati",
       "union_ban_name":"বুনগতি"
    },
    {
       "_id":5720,
       "upazila_code":85,
       "union_code":35,
       "union_name":"Dhaneshwargati",
       "union_ban_name":"ধনেশ্বরগতি"
    },
    {
       "_id":5722,
       "upazila_code":85,
       "union_code":59,
       "union_name":"Shalikha",
       "union_ban_name":"শালিখা"
    },
    {
       "_id":5723,
       "upazila_code":85,
       "union_code":71,
       "union_name":"Shatakhali",
       "union_ban_name":"শাতখালী"
    },
    {
       "_id":5724,
       "upazila_code":85,
       "union_code":83,
       "union_name":"Talkhari",
       "union_ban_name":"তখারি"
    },
    {
       "_id":5725,
       "upazila_code":95,
       "union_code":10,
       "union_name":"Amalsar",
       "union_ban_name":"আমালসর"
    },
    {
       "_id":5726,
       "upazila_code":95,
       "union_code":21,
       "union_name":"Dariapur",
       "union_ban_name":"দারিয়াপুর"
    },
    {
       "_id":5727,
       "upazila_code":95,
       "union_code":31,
       "union_name":"Gayeshpur",
       "union_ban_name":"গায়েশপুর"
    },
    {
       "_id":5728,
       "upazila_code":95,
       "union_code":42,
       "union_name":"Kadir Para",
       "union_ban_name":"কাদির প্যারা"
    },
    {
       "_id":5729,
       "upazila_code":95,
       "union_code":52,
       "union_name":"Nakol",
       "union_ban_name":"নকল"
    },
    {
       "_id":5731,
       "upazila_code":95,
       "union_code":73,
       "union_name":"Sreekol",
       "union_ban_name":"শ্রীকোল"
    },
    {
       "_id":5733,
       "upazila_code":10,
       "union_code":17,
       "union_name":"Bachamara",
       "union_ban_name":"বচ্চমারা"
    },
    {
       "_id":5735,
       "upazila_code":10,
       "union_code":28,
       "union_name":"Chak Mirpur",
       "union_ban_name":"চক মিরপুর"
    },
    {
       "_id":5736,
       "upazila_code":10,
       "union_code":38,
       "union_name":"Charkatari",
       "union_ban_name":"চরকতরী"
    },
    {
       "_id":5737,
       "upazila_code":10,
       "union_code":57,
       "union_name":"Dhamsar",
       "union_ban_name":"ধামসার"
    },
    {
       "_id":5738,
       "upazila_code":10,
       "union_code":66,
       "union_name":"Jiyanpur",
       "union_ban_name":"জিয়ানপুর"
    },
    {
       "_id":5739,
       "upazila_code":10,
       "union_code":76,
       "union_name":"Kalia",
       "union_ban_name":"কালিয়া"
    },
    {
       "_id":5740,
       "upazila_code":10,
       "union_code":85,
       "union_name":"Khalsi",
       "union_ban_name":"খালসি"
    },
    {
       "_id":5741,
       "upazila_code":22,
       "union_code":11,
       "union_name":"Baliakhora",
       "union_ban_name":"বালিয়াখোড়া"
    },
    {
       "_id":5742,
       "upazila_code":22,
       "union_code":23,
       "union_name":"Baniajuri",
       "union_ban_name":"বানিয়াজুরী"
    },
    {
       "_id":5743,
       "upazila_code":22,
       "union_code":35,
       "union_name":"Baratia",
       "union_ban_name":"বড়টিয়া"
    },
    {
       "_id":5744,
       "upazila_code":22,
       "union_code":47,
       "union_name":"Ghior",
       "union_ban_name":"ঘিওর"
    },
    {
       "_id":5745,
       "upazila_code":22,
       "union_code":59,
       "union_name":"Nali",
       "union_ban_name":"নালি"
    },
    {
       "_id":5746,
       "upazila_code":22,
       "union_code":71,
       "union_name":"Paila",
       "union_ban_name":"পাইলা"
    },
    {
       "_id":5747,
       "upazila_code":22,
       "union_code":83,
       "union_name":"Singjuri",
       "union_ban_name":"সিংজুরি"
    },
    {
       "_id":5749,
       "upazila_code":28,
       "union_code":14,
       "union_name":"Balla",
       "union_ban_name":"বল্লা"
    },
    {
       "_id":5750,
       "upazila_code":28,
       "union_code":21,
       "union_name":"Balara",
       "union_ban_name":"বলারা"
    },
    {
       "_id":5751,
       "upazila_code":28,
       "union_code":29,
       "union_name":"Boyra",
       "union_ban_name":"বয়রা"
    },
    {
       "_id":5752,
       "upazila_code":28,
       "union_code":36,
       "union_name":"Chala",
       "union_ban_name":"চালা"
    },
    {
       "_id":5753,
       "upazila_code":28,
       "union_code":43,
       "union_name":"Dhulsunra",
       "union_ban_name":"ধুলসুনরা"
    },
    {
       "_id":5754,
       "upazila_code":28,
       "union_code":51,
       "union_name":"Gala",
       "union_ban_name":"গালা"
    },
    {
       "_id":5756,
       "upazila_code":28,
       "union_code":65,
       "union_name":"Harukandi",
       "union_ban_name":"হারুকান্দি"
    },
    {
       "_id":5758,
       "upazila_code":28,
       "union_code":80,
       "union_name":"Lesraganj",
       "union_ban_name":"লেসরগঞ্জ"
    },
    {
       "_id":5760,
       "upazila_code":28,
       "union_code":94,
       "union_name":"Sutalari",
       "union_ban_name":"সুতালারি"
    },
    {
       "_id":5761,
       "upazila_code":46,
       "union_code":11,
       "union_name":"Betila Mitara",
       "union_ban_name":"বেটিলা মিতারা"
    },
    {
       "_id":5762,
       "upazila_code":46,
       "union_code":13,
       "union_name":"Atigram",
       "union_ban_name":"অতিগ্রাম"
    },
    {
       "_id":5764,
       "upazila_code":46,
       "union_code":23,
       "union_name":"Dighi",
       "union_ban_name":"দিঘি"
    },
    {
       "_id":5765,
       "upazila_code":46,
       "union_code":31,
       "union_name":"Garpara",
       "union_ban_name":"গারপাড়া"
    },
    {
       "_id":5766,
       "upazila_code":46,
       "union_code":39,
       "union_name":"Hati Para",
       "union_ban_name":"হাতি পাড়া"
    },
    {
       "_id":5767,
       "upazila_code":46,
       "union_code":55,
       "union_name":"Jaigir",
       "union_ban_name":"জয়গীর"
    },
    {
       "_id":5770,
       "upazila_code":46,
       "union_code":94,
       "union_name":"Putail",
       "union_ban_name":"পুতাইল"
    },
    {
       "_id":5771,
       "upazila_code":70,
       "union_code":19,
       "union_name":"Baliati",
       "union_ban_name":"বালিয়াটি"
    },
    {
       "_id":5772,
       "upazila_code":70,
       "union_code":28,
       "union_name":"Baraid",
       "union_ban_name":"বড়াইদ"
    },
    {
       "_id":5773,
       "upazila_code":70,
       "union_code":38,
       "union_name":"Daragram",
       "union_ban_name":"দারাগ্রাম"
    },
    {
       "_id":5775,
       "upazila_code":70,
       "union_code":57,
       "union_name":"Dhankora",
       "union_ban_name":"ধানকোড়া"
    },
    {
       "_id":5776,
       "upazila_code":70,
       "union_code":66,
       "union_name":"Fukurhati",
       "union_ban_name":"ফুকুরহাটি"
    },
    {
       "_id":5777,
       "upazila_code":70,
       "union_code":76,
       "union_name":"Hargaz",
       "union_ban_name":"হরগাজ"
    },
    {
       "_id":5779,
       "upazila_code":70,
       "union_code":95,
       "union_name":"Tilli",
       "union_ban_name":"টিলি"
    },
    {
       "_id":5780,
       "upazila_code":78,
       "union_code":11,
       "union_name":"Arua",
       "union_ban_name":"অরুয়া"
    },
    {
       "_id":5781,
       "upazila_code":78,
       "union_code":23,
       "union_name":"Mohadebpur",
       "union_ban_name":"মহাদেবপুর"
    },
    {
       "_id":5783,
       "upazila_code":78,
       "union_code":47,
       "union_name":"Shibalaya",
       "union_ban_name":"শিবালয়"
    },
    {
       "_id":5784,
       "upazila_code":78,
       "union_code":59,
       "union_name":"Teota",
       "union_ban_name":"তেওতা"
    },
    {
       "_id":5785,
       "upazila_code":78,
       "union_code":71,
       "union_name":"Ulail",
       "union_ban_name":"উলাইল"
    },
    {
       "_id":5787,
       "upazila_code":82,
       "union_code":17,
       "union_name":"Baldhara",
       "union_ban_name":"বলধারা"
    },
    {
       "_id":5789,
       "upazila_code":82,
       "union_code":25,
       "union_name":"Chandhar",
       "union_ban_name":"চাঁধর"
    },
    {
       "_id":5790,
       "upazila_code":82,
       "union_code":34,
       "union_name":"Charigram",
       "union_ban_name":"চারিগ্রাম"
    },
    {
       "_id":5791,
       "upazila_code":82,
       "union_code":43,
       "union_name":"Dhalla",
       "union_ban_name":"ধল্লা"
    },
    {
       "_id":5792,
       "upazila_code":82,
       "union_code":51,
       "union_name":"Joy Mantap",
       "union_ban_name":"জয় মনতাপ"
    },
    {
       "_id":5793,
       "upazila_code":82,
       "union_code":60,
       "union_name":"Jamirta",
       "union_ban_name":"জামির্তা"
    },
    {
       "_id":5794,
       "upazila_code":82,
       "union_code":69,
       "union_name":"Jamsha",
       "union_ban_name":"জামশা"
    },
    {
       "_id":5795,
       "upazila_code":82,
       "union_code":77,
       "union_name":"Saista",
       "union_ban_name":"সাইস্তা"
    },
    {
       "_id":5796,
       "upazila_code":82,
       "union_code":86,
       "union_name":"Singair",
       "union_ban_name":"সিঙ্গাইর"
    },
    {
       "_id":5797,
       "upazila_code":82,
       "union_code":94,
       "union_name":"Talibpur",
       "union_ban_name":"তালিবপুর"
    },
    {
       "_id":5798,
       "upazila_code":14,
       "union_code":13,
       "union_name":"Barlekha",
       "union_ban_name":"বারলেখা"
    },
    {
       "_id":5800,
       "upazila_code":14,
       "union_code":29,
       "union_name":"Dakshin Shahabajpur",
       "union_ban_name":"দক্ষিণ শাহাবাজপুর"
    },
    {
       "_id":5801,
       "upazila_code":14,
       "union_code":31,
       "union_name":"Dakshin Dakshinbhagh",
       "union_ban_name":"দক্ষিণ দক্ষিণবাগ"
    },
    {
       "_id":5802,
       "upazila_code":14,
       "union_code":39,
       "union_name":"Dasher Bazar",
       "union_ban_name":"দশের বাজার"
    },
    {
       "_id":5803,
       "upazila_code":14,
       "union_code":63,
       "union_name":"Nij Bahadurpur",
       "union_ban_name":"নিজ বাহাদুরপুর"
    },
    {
       "_id":5804,
       "upazila_code":14,
       "union_code":77,
       "union_name":"Sujanagar",
       "union_ban_name":"সুজানগর"
    },
    {
       "_id":5805,
       "upazila_code":14,
       "union_code":80,
       "union_name":"Uttar Dakshinbhag",
       "union_ban_name":"উত্তর দক্ষিণভাগ"
    },
    {
       "_id":5806,
       "upazila_code":14,
       "union_code":85,
       "union_name":"Uttar Shahabajpur",
       "union_ban_name":"উত্তর শাহাবাজপুর"
    },
    {
       "_id":5807,
       "upazila_code":14,
       "union_code":94,
       "union_name":"Talimpur",
       "union_ban_name":"তালিমপুর"
    },
    {
       "_id":5808,
       "upazila_code":35,
       "union_code":23,
       "union_name":"Fultala",
       "union_ban_name":"ফুলতলা"
    },
    {
       "_id":5809,
       "upazila_code":35,
       "union_code":29,
       "union_name":"Goalbari",
       "union_ban_name":"গোলবাড়ি"
    },
    {
       "_id":5810,
       "upazila_code":35,
       "union_code":47,
       "union_name":"Jaifarnagar",
       "union_ban_name":"জয়ফরনগর"
    },
    {
       "_id":5811,
       "upazila_code":35,
       "union_code":55,
       "union_name":"Paschim Juri",
       "union_ban_name":"পাসচিম জুড়ি"
    },
    {
       "_id":5812,
       "upazila_code":35,
       "union_code":67,
       "union_name":"Purba Juri",
       "union_ban_name":"পূর্ব জুড়ি"
    },
    {
       "_id":5813,
       "upazila_code":35,
       "union_code":83,
       "union_name":"Sagarnal",
       "union_ban_name":"সাগরনাল"
    },
    {
       "_id":5817,
       "upazila_code":56,
       "union_code":38,
       "union_name":"Kamalganj",
       "union_ban_name":"কমলগঞ্জ"
    },
    {
       "_id":5819,
       "upazila_code":56,
       "union_code":57,
       "union_name":"Munshi Bazar",
       "union_ban_name":"মুন্সি বাজার"
    },
    {
       "_id":5820,
       "upazila_code":56,
       "union_code":66,
       "union_name":"Patanushar",
       "union_ban_name":"পাতানুশার"
    },
    {
       "_id":5821,
       "upazila_code":56,
       "union_code":76,
       "union_name":"Rahimpur",
       "union_ban_name":"রহিমপুর"
    },
    {
       "_id":5822,
       "upazila_code":56,
       "union_code":85,
       "union_name":"Shamshernagar",
       "union_ban_name":"শমশেরনগর"
    },
    {
       "_id":5823,
       "upazila_code":65,
       "union_code":10,
       "union_name":"Bhukshimail",
       "union_ban_name":"ভুকশিমেল"
    },
    {
       "_id":5824,
       "upazila_code":65,
       "union_code":11,
       "union_name":"Baramchal",
       "union_ban_name":"বড়মচাল"
    },
    {
       "_id":5825,
       "upazila_code":65,
       "union_code":13,
       "union_name":"Bhatera",
       "union_ban_name":"ভাটেরা"
    },
    {
       "_id":5826,
       "upazila_code":65,
       "union_code":17,
       "union_name":"Brahman Bazar",
       "union_ban_name":"ব্রাহ্মণ বাজার"
    },
    {
       "_id":5828,
       "upazila_code":65,
       "union_code":41,
       "union_name":"Joychandi",
       "union_ban_name":"জয়চণ্ডী"
    },
    {
       "_id":5830,
       "upazila_code":65,
       "union_code":59,
       "union_name":"Karmadha",
       "union_ban_name":"কর্মধা"
    },
    {
       "_id":5831,
       "upazila_code":65,
       "union_code":65,
       "union_name":"Kulaura",
       "union_ban_name":"কুলাউড়া"
    },
    {
       "_id":5832,
       "upazila_code":65,
       "union_code":71,
       "union_name":"Prithim Pasha",
       "union_ban_name":"পৃথিম পাশা"
    },
    {
       "_id":5833,
       "upazila_code":65,
       "union_code":77,
       "union_name":"Routhgaon",
       "union_ban_name":"রুথগাঁও"
    },
    {
       "_id":5835,
       "upazila_code":65,
       "union_code":95,
       "union_name":"Tilagaon",
       "union_ban_name":"টিলাগাঁও"
    },
    {
       "_id":5836,
       "upazila_code":74,
       "union_code":13,
       "union_name":"Akhailkura",
       "union_ban_name":"আখাইলকুড়া"
    },
    {
       "_id":5837,
       "upazila_code":74,
       "union_code":14,
       "union_name":"Amtail",
       "union_ban_name":"আমটাইল"
    },
    {
       "_id":5838,
       "upazila_code":74,
       "union_code":21,
       "union_name":"Chandighat",
       "union_ban_name":"চান্দিঘাট"
    },
    {
       "_id":5839,
       "upazila_code":74,
       "union_code":29,
       "union_name":"Ekatuna",
       "union_ban_name":"একাতুনা"
    },
    {
       "_id":5840,
       "upazila_code":74,
       "union_code":36,
       "union_name":"Giasnagar",
       "union_ban_name":"গিয়াসনগর"
    },
    {
       "_id":5842,
       "upazila_code":74,
       "union_code":51,
       "union_name":"Kanakpur",
       "union_ban_name":"কনকপুর"
    },
    {
       "_id":5843,
       "upazila_code":74,
       "union_code":58,
       "union_name":"Khalilpur",
       "union_ban_name":"খলিলপুর"
    },
    {
       "_id":5844,
       "upazila_code":74,
       "union_code":65,
       "union_name":"Manumukh",
       "union_ban_name":"মনুমুখ"
    },
    {
       "_id":5846,
       "upazila_code":74,
       "union_code":87,
       "union_name":"Nazirabad",
       "union_ban_name":"নাজিরাবাদ"
    },
    {
       "_id":5847,
       "upazila_code":74,
       "union_code":94,
       "union_name":"Upper Kagabala",
       "union_ban_name":"আপার কাগবালা"
    },
    {
       "_id":5849,
       "upazila_code":80,
       "union_code":21,
       "union_name":"Kamar Chak",
       "union_ban_name":"কামার চাক"
    },
    {
       "_id":5850,
       "upazila_code":80,
       "union_code":31,
       "union_name":"Mansurnagar",
       "union_ban_name":"মনসুরনগর"
    },
    {
       "_id":5852,
       "upazila_code":80,
       "union_code":52,
       "union_name":"Panchgaon",
       "union_ban_name":"পাঁচগাঁও"
    },
    {
       "_id":5854,
       "upazila_code":80,
       "union_code":73,
       "union_name":"Tengra",
       "union_ban_name":"টেংরা"
    },
    {
       "_id":5855,
       "upazila_code":80,
       "union_code":84,
       "union_name":"Uttarbhag",
       "union_ban_name":"উত্তরভাগ"
    },
    {
       "_id":5856,
       "upazila_code":83,
       "union_code":15,
       "union_name":"Ashidron",
       "union_ban_name":"আশিড্রন"
    },
    {
       "_id":5857,
       "upazila_code":83,
       "union_code":19,
       "union_name":"Bhunabir",
       "union_ban_name":"ভুনাবির"
    },
    {
       "_id":5858,
       "upazila_code":83,
       "union_code":28,
       "union_name":"Kalapur",
       "union_ban_name":"কালাপুর"
    },
    {
       "_id":5859,
       "upazila_code":83,
       "union_code":38,
       "union_name":"Kalighat",
       "union_ban_name":"কালীঘাট"
    },
    {
       "_id":5861,
       "upazila_code":83,
       "union_code":57,
       "union_name":"Rajghat",
       "union_ban_name":"রাজঘাট"
    },
    {
       "_id":5862,
       "upazila_code":83,
       "union_code":66,
       "union_name":"Satgoan",
       "union_ban_name":"সাতগাঁও"
    },
    {
       "_id":5863,
       "upazila_code":83,
       "union_code":76,
       "union_name":"Sindurkhan",
       "union_ban_name":"সিন্দুরখান"
    },
    {
       "_id":5864,
       "upazila_code":83,
       "union_code":85,
       "union_name":"Sreemangal",
       "union_ban_name":"শ্রীমঙ্গল"
    },
    {
       "_id":5865,
       "upazila_code":47,
       "union_code":10,
       "union_name":"Bamandi",
       "union_ban_name":"বামনদী"
    },
    {
       "_id":5866,
       "upazila_code":47,
       "union_code":21,
       "union_name":"Dhankhola",
       "union_ban_name":"ধানখোলা"
    },
    {
       "_id":5867,
       "upazila_code":47,
       "union_code":42,
       "union_name":"Kathuli",
       "union_ban_name":"কাঠুলি"
    },
    {
       "_id":5868,
       "upazila_code":47,
       "union_code":52,
       "union_name":"Kazipur",
       "union_ban_name":"কাজীপুর"
    },
    {
       "_id":5869,
       "upazila_code":47,
       "union_code":63,
       "union_name":"Matmura",
       "union_ban_name":"মাতমুরা"
    },
    {
       "_id":5871,
       "upazila_code":47,
       "union_code":73,
       "union_name":"Shaharbati",
       "union_ban_name":"শাহরবাতি"
    },
    {
       "_id":5872,
       "upazila_code":47,
       "union_code":84,
       "union_name":"Shola Taka",
       "union_ban_name":"শোলা টাকা"
    },
    {
       "_id":5873,
       "upazila_code":47,
       "union_code":94,
       "union_name":"Tentulbaria",
       "union_ban_name":"তেঁতুলবাড়িয়া"
    },
    {
       "_id":5876,
       "upazila_code":60,
       "union_code":76,
       "union_name":"Mahajanpur",
       "union_ban_name":"মহাজনপুর"
    },
    {
       "_id":5877,
       "upazila_code":60,
       "union_code":85,
       "union_name":"Monakhali",
       "union_ban_name":"মোনাখালী"
    },
    {
       "_id":5878,
       "upazila_code":87,
       "union_code":14,
       "union_name":"Amda",
       "union_ban_name":"আমদা"
    },
    {
       "_id":5879,
       "upazila_code":87,
       "union_code":19,
       "union_name":"Amjhupi",
       "union_ban_name":"আমঝুপি"
    },
    {
       "_id":5880,
       "upazila_code":87,
       "union_code":38,
       "union_name":"Buripota",
       "union_ban_name":"বুড়িপোটা"
    },
    {
       "_id":5882,
       "upazila_code":87,
       "union_code":95,
       "union_name":"Pirojpur",
       "union_ban_name":"পিরোজপুর"
    },
    {
       "_id":5883,
       "upazila_code":24,
       "union_code":10,
       "union_name":"Baluakandi",
       "union_ban_name":"বালুয়াকান্দি"
    },
    {
       "_id":5884,
       "upazila_code":24,
       "union_code":21,
       "union_name":"Bausia",
       "union_ban_name":"বাউসিয়া"
    },
    {
       "_id":5885,
       "upazila_code":24,
       "union_code":31,
       "union_name":"Bhaber Char",
       "union_ban_name":"ভবর চর"
    },
    {
       "_id":5887,
       "upazila_code":24,
       "union_code":52,
       "union_name":"Guagachhia",
       "union_ban_name":"গুয়াগাছিয়া"
    },
    {
       "_id":5888,
       "upazila_code":24,
       "union_code":63,
       "union_name":"Hossaindi",
       "union_ban_name":"হোসাইন্দি"
    },
    {
       "_id":5889,
       "upazila_code":24,
       "union_code":73,
       "union_name":"Imampur",
       "union_ban_name":"ইমামপুর"
    },
    {
       "_id":5890,
       "upazila_code":24,
       "union_code":84,
       "union_name":"Tenger Char",
       "union_ban_name":"টেনজার চর"
    },
    {
       "_id":5891,
       "upazila_code":44,
       "union_code":13,
       "union_name":"Bejgaon",
       "union_ban_name":"বেজগাঁও"
    },
    {
       "_id":5893,
       "upazila_code":44,
       "union_code":31,
       "union_name":"Gaodia",
       "union_ban_name":"গাওদিয়া"
    },
    {
       "_id":5896,
       "upazila_code":44,
       "union_code":55,
       "union_name":"Kanaksar",
       "union_ban_name":"কনকসার"
    },
    {
       "_id":5897,
       "upazila_code":44,
       "union_code":63,
       "union_name":"Khidir Para",
       "union_ban_name":"খিদির পাড়া"
    },
    {
       "_id":5898,
       "upazila_code":44,
       "union_code":71,
       "union_name":"Kumarbhog",
       "union_ban_name":"কুমারভোগ"
    },
    {
       "_id":5899,
       "upazila_code":44,
       "union_code":87,
       "union_name":"Medini Mandal",
       "union_ban_name":"মেদিনী মণ্ডল"
    },
    {
       "_id":5900,
       "upazila_code":44,
       "union_code":94,
       "union_name":"Lohajang Teotia",
       "union_ban_name":"লোহাজং তেওতিয়া"
    },
    {
       "_id":5901,
       "upazila_code":56,
       "union_code":20,
       "union_name":"Adhara",
       "union_ban_name":"অধরা"
    },
    {
       "_id":5902,
       "upazila_code":56,
       "union_code":22,
       "union_name":"Bajra Jogini",
       "union_ban_name":"বাজরা জোগিনী"
    },
    {
       "_id":5903,
       "upazila_code":56,
       "union_code":23,
       "union_name":"Bangla Bazar",
       "union_ban_name":"বাংলাবাজার"
    },
    {
       "_id":5904,
       "upazila_code":56,
       "union_code":28,
       "union_name":"Char Kewar",
       "union_ban_name":"চর কেওয়ার"
    },
    {
       "_id":5905,
       "upazila_code":56,
       "union_code":38,
       "union_name":"Silai",
       "union_ban_name":"সিলাই"
    },
    {
       "_id":5906,
       "upazila_code":56,
       "union_code":47,
       "union_name":"Mahakali",
       "union_ban_name":"মহাকালী"
    },
    {
       "_id":5907,
       "upazila_code":56,
       "union_code":57,
       "union_name":"Mollahkandi",
       "union_ban_name":"মোল্লাহকান্দি"
    },
    {
       "_id":5908,
       "upazila_code":56,
       "union_code":76,
       "union_name":"Panchasar",
       "union_ban_name":"পঞ্চসার"
    },
    {
       "_id":5910,
       "upazila_code":74,
       "union_code":11,
       "union_name":"Balur Char",
       "union_ban_name":"বালুর চর"
    },
    {
       "_id":5911,
       "upazila_code":74,
       "union_code":13,
       "union_name":"Basail",
       "union_ban_name":"বাসাইল"
    },
    {
       "_id":5912,
       "upazila_code":74,
       "union_code":20,
       "union_name":"Bayaragadi",
       "union_ban_name":"বায়রাগাদি"
    },
    {
       "_id":5913,
       "upazila_code":74,
       "union_code":27,
       "union_name":"Chitrakot",
       "union_ban_name":"চিত্রকোট"
    },
    {
       "_id":5915,
       "upazila_code":74,
       "union_code":40,
       "union_name":"Jainsar",
       "union_ban_name":"জৈনসার"
    },
    {
       "_id":5916,
       "upazila_code":74,
       "union_code":47,
       "union_name":"Kayain",
       "union_ban_name":"কায়াইন"
    },
    {
       "_id":5918,
       "upazila_code":74,
       "union_code":61,
       "union_name":"Latabdi",
       "union_ban_name":"লতাবদী"
    },
    {
       "_id":5920,
       "upazila_code":74,
       "union_code":74,
       "union_name":"Malkhanagar",
       "union_ban_name":"মালখানগর"
    },
    {
       "_id":5922,
       "upazila_code":74,
       "union_code":88,
       "union_name":"Rasunia",
       "union_ban_name":"রসুনিয়া"
    },
    {
       "_id":5923,
       "upazila_code":74,
       "union_code":94,
       "union_name":"Sekharnagar",
       "union_ban_name":"শেখরনগর"
    },
    {
       "_id":5924,
       "upazila_code":84,
       "union_code":11,
       "union_name":"Atpara",
       "union_ban_name":"আটপাড়া"
    },
    {
       "_id":5925,
       "upazila_code":84,
       "union_code":13,
       "union_name":"Baghra",
       "union_ban_name":"বাঘরা"
    },
    {
       "_id":5927,
       "upazila_code":84,
       "union_code":27,
       "union_name":"Bhagyakul",
       "union_ban_name":"ভাগ্যকুল"
    },
    {
       "_id":5928,
       "upazila_code":84,
       "union_code":33,
       "union_name":"Birtara",
       "union_ban_name":"বিরতারা"
    },
    {
       "_id":5929,
       "upazila_code":84,
       "union_code":40,
       "union_name":"Hasara",
       "union_ban_name":"হাসারা"
    },
    {
       "_id":5930,
       "upazila_code":84,
       "union_code":47,
       "union_name":"Kola Para",
       "union_ban_name":"কোলা পাড়া"
    },
    {
       "_id":5931,
       "upazila_code":84,
       "union_code":54,
       "union_name":"Kukutia",
       "union_ban_name":"কুকুটিয়া"
    },
    {
       "_id":5932,
       "upazila_code":84,
       "union_code":61,
       "union_name":"Patabhog",
       "union_ban_name":"পটভগগ"
    },
    {
       "_id":5933,
       "upazila_code":84,
       "union_code":67,
       "union_name":"Rarikhal",
       "union_ban_name":"ররিখাল"
    },
    {
       "_id":5935,
       "upazila_code":84,
       "union_code":81,
       "union_name":"Sholaghar",
       "union_ban_name":"শোলঘর"
    },
    {
       "_id":5936,
       "upazila_code":84,
       "union_code":88,
       "union_name":"Shyamsiddhi",
       "union_ban_name":"শ্যামসিডি"
    },
    {
       "_id":5937,
       "upazila_code":84,
       "union_code":94,
       "union_name":"Tantar",
       "union_ban_name":"তান্তার"
    },
    {
       "_id":5938,
       "upazila_code":94,
       "union_code":11,
       "union_name":"Abdullahpur",
       "union_ban_name":"আবদুল্লাহপুর"
    },
    {
       "_id":5939,
       "upazila_code":94,
       "union_code":15,
       "union_name":"Arial",
       "union_ban_name":"আড়িয়াল"
    },
    {
       "_id":5940,
       "upazila_code":94,
       "union_code":23,
       "union_name":"Autshahi",
       "union_ban_name":"আউটশাহী"
    },
    {
       "_id":5941,
       "upazila_code":94,
       "union_code":31,
       "union_name":"Betka",
       "union_ban_name":"বেটকা"
    },
    {
       "_id":5942,
       "upazila_code":94,
       "union_code":39,
       "union_name":"Dhipur",
       "union_ban_name":"ধিপুর"
    },
    {
       "_id":5943,
       "upazila_code":94,
       "union_code":47,
       "union_name":"Dighir Para",
       "union_ban_name":"দিঘির পাড়া"
    },
    {
       "_id":5944,
       "upazila_code":94,
       "union_code":55,
       "union_name":"Hasail Banari",
       "union_ban_name":"হাসাইল বানারী"
    },
    {
       "_id":5945,
       "upazila_code":94,
       "union_code":63,
       "union_name":"Jashalong",
       "union_ban_name":"জশালং"
    },
    {
       "_id":5946,
       "upazila_code":94,
       "union_code":71,
       "union_name":"Kamarkhara",
       "union_ban_name":"কামারখড়া"
    },
    {
       "_id":5947,
       "upazila_code":94,
       "union_code":79,
       "union_name":"Kathadia Shimulia",
       "union_ban_name":"কথাদিয়া শিমুলিয়া"
    },
    {
       "_id":5949,
       "upazila_code":94,
       "union_code":94,
       "union_name":"Sonarang Tongibari",
       "union_ban_name":"সোনারং টঙ্গিবাড়ি"
    },
    {
       "_id":5950,
       "upazila_code":13,
       "union_code":14,
       "union_name":"Bhaluka",
       "union_ban_name":"ভালুকা"
    },
    {
       "_id":5951,
       "upazila_code":13,
       "union_code":17,
       "union_name":"Bharadoba",
       "union_ban_name":"ভরদোবা"
    },
    {
       "_id":5952,
       "upazila_code":13,
       "union_code":25,
       "union_name":"Birunia",
       "union_ban_name":"বিরুনিয়া"
    },
    {
       "_id":5953,
       "upazila_code":13,
       "union_code":34,
       "union_name":"Dakatia",
       "union_ban_name":"ডাকাতিয়া"
    },
    {
       "_id":5954,
       "upazila_code":13,
       "union_code":43,
       "union_name":"Dhitpur",
       "union_ban_name":"ধিতপুর"
    },
    {
       "_id":5955,
       "upazila_code":13,
       "union_code":51,
       "union_name":"Habirbari",
       "union_ban_name":"হাবিরবাড়ী"
    },
    {
       "_id":5956,
       "upazila_code":13,
       "union_code":60,
       "union_name":"Kachina",
       "union_ban_name":"কচিনা"
    },
    {
       "_id":5957,
       "upazila_code":13,
       "union_code":69,
       "union_name":"Mallikbari",
       "union_ban_name":"মল্লিকবাড়ী"
    },
    {
       "_id":5958,
       "upazila_code":13,
       "union_code":77,
       "union_name":"Meduary",
       "union_ban_name":"মেদুয়ারি"
    },
    {
       "_id":5959,
       "upazila_code":13,
       "union_code":86,
       "union_name":"Rajai",
       "union_ban_name":"রাজাই"
    },
    {
       "_id":5960,
       "upazila_code":13,
       "union_code":94,
       "union_name":"Uthura",
       "union_ban_name":"উথুরা"
    },
    {
       "_id":5961,
       "upazila_code":16,
       "union_code":10,
       "union_name":"Baghber",
       "union_ban_name":"বাঘবার"
    },
    {
       "_id":5962,
       "upazila_code":16,
       "union_code":31,
       "union_name":"Dakshin Maij Para",
       "union_ban_name":"দক্ষিণ মাইজ পাড়া"
    },
    {
       "_id":5963,
       "upazila_code":16,
       "union_code":36,
       "union_name":"Dhobaura",
       "union_ban_name":"ধোবাউড়া"
    },
    {
       "_id":5964,
       "upazila_code":16,
       "union_code":42,
       "union_name":"Gamaritala",
       "union_ban_name":"গামরতলা"
    },
    {
       "_id":5965,
       "upazila_code":16,
       "union_code":47,
       "union_name":"Guatala",
       "union_ban_name":"গুয়াতলা"
    },
    {
       "_id":5966,
       "upazila_code":16,
       "union_code":52,
       "union_name":"Ghoshgaon",
       "union_ban_name":"ঘোষগাঁও"
    },
    {
       "_id":5967,
       "upazila_code":16,
       "union_code":73,
       "union_name":"Pora Kandulia",
       "union_ban_name":"পোড়া কান্দুলিয়া"
    },
    {
       "_id":5968,
       "upazila_code":20,
       "union_code":11,
       "union_name":"Achim Patuli",
       "union_ban_name":"আছিম পাটুলি"
    },
    {
       "_id":5969,
       "upazila_code":20,
       "union_code":17,
       "union_name":"Bakta",
       "union_ban_name":"বকতা"
    },
    {
       "_id":5970,
       "upazila_code":20,
       "union_code":23,
       "union_name":"Balian",
       "union_ban_name":"বালিয়ান"
    },
    {
       "_id":5972,
       "upazila_code":20,
       "union_code":35,
       "union_name":"Deokhola",
       "union_ban_name":"দেওখোলা"
    },
    {
       "_id":5973,
       "upazila_code":20,
       "union_code":41,
       "union_name":"Enayetpur",
       "union_ban_name":"এনায়েতপুর"
    },
    {
       "_id":5975,
       "upazila_code":20,
       "union_code":53,
       "union_name":"Kaladaha",
       "union_ban_name":"কালদহ"
    },
    {
       "_id":5976,
       "upazila_code":20,
       "union_code":59,
       "union_name":"Kushmail",
       "union_ban_name":"কুশমেল"
    },
    {
       "_id":5977,
       "upazila_code":20,
       "union_code":77,
       "union_name":"Naogaon",
       "union_ban_name":"নওগাঁ"
    },
    {
       "_id":5978,
       "upazila_code":20,
       "union_code":83,
       "union_name":"Putijana",
       "union_ban_name":"পুটিজানা"
    },
    {
       "_id":5979,
       "upazila_code":20,
       "union_code":89,
       "union_name":"Radhakanai",
       "union_ban_name":"রাধাকনই"
    },
    {
       "_id":5981,
       "upazila_code":22,
       "union_code":11,
       "union_name":"Barabaria",
       "union_ban_name":"বড়বাড়িয়া"
    },
    {
       "_id":5983,
       "upazila_code":22,
       "union_code":18,
       "union_name":"Datter Bazar",
       "union_ban_name":"ডিটার বাজার"
    },
    {
       "_id":5984,
       "upazila_code":22,
       "union_code":25,
       "union_name":"Gaffargaon",
       "union_ban_name":"গফরগাঁও"
    },
    {
       "_id":5985,
       "upazila_code":22,
       "union_code":31,
       "union_name":"Jessora",
       "union_ban_name":"জেসোসারা"
    },
    {
       "_id":5986,
       "upazila_code":22,
       "union_code":37,
       "union_name":"Langair",
       "union_ban_name":"ল্যাংগেইর"
    },
    {
       "_id":5987,
       "upazila_code":22,
       "union_code":44,
       "union_name":"Mashakhali",
       "union_ban_name":"মাশখালালী"
    },
    {
       "_id":5988,
       "upazila_code":22,
       "union_code":50,
       "union_name":"Nigair",
       "union_ban_name":"নিগায়ের"
    },
    {
       "_id":5989,
       "upazila_code":22,
       "union_code":56,
       "union_name":"Paithal",
       "union_ban_name":"পাইথল"
    },
    {
       "_id":5990,
       "upazila_code":22,
       "union_code":63,
       "union_name":"Panchbhag",
       "union_ban_name":"পঞ্চভাগ"
    },
    {
       "_id":5992,
       "upazila_code":22,
       "union_code":75,
       "union_name":"Raona",
       "union_ban_name":"রাওনা"
    },
    {
       "_id":5993,
       "upazila_code":22,
       "union_code":82,
       "union_name":"Saltia",
       "union_ban_name":"সালটিয়া"
    },
    {
       "_id":5994,
       "upazila_code":22,
       "union_code":88,
       "union_name":"Tengaba",
       "union_ban_name":"টেঙাবা"
    },
    {
       "_id":5995,
       "upazila_code":22,
       "union_code":94,
       "union_name":"Usthi",
       "union_ban_name":"উস্তি"
    },
    {
       "_id":5996,
       "upazila_code":23,
       "union_code":15,
       "union_name":"Achintapur",
       "union_ban_name":"অচিন্তপুর"
    },
    {
       "_id":5997,
       "upazila_code":23,
       "union_code":18,
       "union_name":"Bhangnamari",
       "union_ban_name":"ভাঙ্গনামারী"
    },
    {
       "_id":5998,
       "upazila_code":23,
       "union_code":22,
       "union_name":"Bokainagar",
       "union_ban_name":"বোকাইনগর"
    },
    {
       "_id":5999,
       "upazila_code":23,
       "union_code":27,
       "union_name":"Dowhakhala",
       "union_ban_name":"দাওখালা"
    },
    {
       "_id":6001,
       "upazila_code":23,
       "union_code":49,
       "union_name":"Mailakanda",
       "union_ban_name":"মাইলাকান্দা"
    },
    {
       "_id":6002,
       "upazila_code":23,
       "union_code":58,
       "union_name":"Maoha",
       "union_ban_name":"মাওহা"
    },
    {
       "_id":6003,
       "upazila_code":23,
       "union_code":72,
       "union_name":"Ramgopalpur",
       "union_ban_name":"রামগোপালপুর"
    },
    {
       "_id":6004,
       "upazila_code":23,
       "union_code":81,
       "union_name":"Sahanati",
       "union_ban_name":"সাহানাতি"
    },
    {
       "_id":6007,
       "upazila_code":24,
       "union_code":20,
       "union_name":"Bhubankura",
       "union_ban_name":"ভুবনকুড়া"
    },
    {
       "_id":6008,
       "upazila_code":24,
       "union_code":21,
       "union_name":"Bildora",
       "union_ban_name":"বিল্ডোরা"
    },
    {
       "_id":6009,
       "upazila_code":24,
       "union_code":33,
       "union_name":"Dhara",
       "union_ban_name":"ধারা"
    },
    {
       "_id":6011,
       "upazila_code":24,
       "union_code":47,
       "union_name":"Gazir Bhita",
       "union_ban_name":"গাজীর ভিটা"
    },
    {
       "_id":6012,
       "upazila_code":24,
       "union_code":61,
       "union_name":"Haluaghat",
       "union_ban_name":"হালুয়াঘাট"
    },
    {
       "_id":6013,
       "upazila_code":24,
       "union_code":67,
       "union_name":"Jugli",
       "union_ban_name":"জুগলি"
    },
    {
       "_id":6014,
       "upazila_code":24,
       "union_code":74,
       "union_name":"Kaichapur",
       "union_ban_name":"কাইচাপুর"
    },
    {
       "_id":6015,
       "upazila_code":24,
       "union_code":81,
       "union_name":"Narail",
       "union_ban_name":"নড়াইল"
    },
    {
       "_id":6016,
       "upazila_code":24,
       "union_code":84,
       "union_name":"Sakuai",
       "union_ban_name":"সাকুই"
    },
    {
       "_id":6017,
       "upazila_code":24,
       "union_code":94,
       "union_name":"Swadeshi",
       "union_ban_name":"স্বদেশী"
    },
    {
       "_id":6018,
       "upazila_code":31,
       "union_code":11,
       "union_name":"Atharabari",
       "union_ban_name":"অথরবাড়ি"
    },
    {
       "_id":6019,
       "upazila_code":31,
       "union_code":13,
       "union_name":"Barahit",
       "union_ban_name":"বড়হিত"
    },
    {
       "_id":6020,
       "upazila_code":31,
       "union_code":40,
       "union_name":"Ishwarganj",
       "union_ban_name":".শ্বরগঞ্জ"
    },
    {
       "_id":6021,
       "upazila_code":31,
       "union_code":45,
       "union_name":"Jatia",
       "union_ban_name":"যেতিয়া"
    },
    {
       "_id":6022,
       "upazila_code":31,
       "union_code":54,
       "union_name":"Maijbagh",
       "union_ban_name":"মাইজবাগ"
    },
    {
       "_id":6023,
       "upazila_code":31,
       "union_code":63,
       "union_name":"Mogtola",
       "union_ban_name":"মোগটোলা"
    },
    {
       "_id":6024,
       "upazila_code":31,
       "union_code":67,
       "union_name":"Rajibpur",
       "union_ban_name":"রাজীবপুর"
    },
    {
       "_id":6025,
       "upazila_code":31,
       "union_code":76,
       "union_name":"Sarisha",
       "union_ban_name":"সরিষা"
    },
    {
       "_id":6026,
       "upazila_code":31,
       "union_code":85,
       "union_name":"Sohagi",
       "union_ban_name":"সোহাগী"
    },
    {
       "_id":6027,
       "upazila_code":31,
       "union_code":90,
       "union_name":"Tarundia",
       "union_ban_name":"তারুন্দিয়া"
    },
    {
       "_id":6028,
       "upazila_code":31,
       "union_code":94,
       "union_name":"Uchakhila",
       "union_ban_name":"উছাখিলা"
    },
    {
       "_id":6029,
       "upazila_code":52,
       "union_code":23,
       "union_name":"Akua",
       "union_ban_name":"আকুয়া"
    },
    {
       "_id":6030,
       "upazila_code":52,
       "union_code":25,
       "union_name":"Ashtadhar",
       "union_ban_name":"অষ্টধর"
    },
    {
       "_id":6031,
       "upazila_code":52,
       "union_code":27,
       "union_name":"Baira (Kewatkhali)",
       "union_ban_name":"বায়রা (কেওয়াতখালী)"
    },
    {
       "_id":6032,
       "upazila_code":52,
       "union_code":29,
       "union_name":"Bhabkhali",
       "union_ban_name":"ভবখালী"
    },
    {
       "_id":6033,
       "upazila_code":52,
       "union_code":31,
       "union_name":"Borar Char",
       "union_ban_name":"বোরার চর"
    },
    {
       "_id":6034,
       "upazila_code":52,
       "union_code":33,
       "union_name":"Char Ishwardia",
       "union_ban_name":"চর wardশ্বরদীয়া"
    },
    {
       "_id":6035,
       "upazila_code":52,
       "union_code":40,
       "union_name":"Char Nilakshmia",
       "union_ban_name":"চর নিলক্ষ্মিয়া"
    },
    {
       "_id":6036,
       "upazila_code":52,
       "union_code":47,
       "union_name":"Dapunia",
       "union_ban_name":"দাপুনিয়া"
    },
    {
       "_id":6038,
       "upazila_code":52,
       "union_code":67,
       "union_name":"Khagdahar",
       "union_ban_name":"খগদহর"
    },
    {
       "_id":6039,
       "upazila_code":52,
       "union_code":74,
       "union_name":"Kushtia",
       "union_ban_name":"কুষ্টিয়া"
    },
    {
       "_id":6040,
       "upazila_code":52,
       "union_code":88,
       "union_name":"Paranganj",
       "union_ban_name":"পরানগঞ্জ"
    },
    {
       "_id":6041,
       "upazila_code":52,
       "union_code":94,
       "union_name":"Sirta",
       "union_ban_name":"সিরতা"
    },
    {
       "_id":6042,
       "upazila_code":65,
       "union_code":16,
       "union_name":"Baragram",
       "union_ban_name":"বড়গ্রাম"
    },
    {
       "_id":6043,
       "upazila_code":65,
       "union_code":17,
       "union_name":"Basati",
       "union_ban_name":"বাসতি"
    },
    {
       "_id":6044,
       "upazila_code":65,
       "union_code":25,
       "union_name":"Daogaon",
       "union_ban_name":"দাওগাঁও"
    },
    {
       "_id":6045,
       "upazila_code":65,
       "union_code":34,
       "union_name":"Dulla",
       "union_ban_name":"দুল্লা"
    },
    {
       "_id":6046,
       "upazila_code":65,
       "union_code":43,
       "union_name":"Ghoga",
       "union_ban_name":"ঘোগা"
    },
    {
       "_id":6048,
       "upazila_code":65,
       "union_code":60,
       "union_name":"Kheruajani",
       "union_ban_name":"খেরুয়াজনী"
    },
    {
       "_id":6049,
       "upazila_code":65,
       "union_code":69,
       "union_name":"Kumarghata",
       "union_ban_name":"কুমারঘাটা"
    },
    {
       "_id":6050,
       "upazila_code":65,
       "union_code":77,
       "union_name":"Mankon",
       "union_ban_name":"মানকন"
    },
    {
       "_id":6051,
       "upazila_code":65,
       "union_code":94,
       "union_name":"Tarati",
       "union_ban_name":"তারাতি"
    },
    {
       "_id":6052,
       "upazila_code":72,
       "union_code":13,
       "union_name":"Achargaon",
       "union_ban_name":"আছারগাঁও"
    },
    {
       "_id":6053,
       "upazila_code":72,
       "union_code":15,
       "union_name":"Betagair",
       "union_ban_name":"বিটাগেইর"
    },
    {
       "_id":6054,
       "upazila_code":72,
       "union_code":23,
       "union_name":"Chandipasha",
       "union_ban_name":"চণ্ডীপাশা"
    },
    {
       "_id":6055,
       "upazila_code":72,
       "union_code":31,
       "union_name":"Gangail",
       "union_ban_name":"গাঙ্গাইল"
    },
    {
       "_id":6056,
       "upazila_code":72,
       "union_code":39,
       "union_name":"Jahangirpur",
       "union_ban_name":"জাহাঙ্গীরপুর"
    },
    {
       "_id":6057,
       "upazila_code":72,
       "union_code":47,
       "union_name":"Kharua",
       "union_ban_name":"খারুয়া"
    },
    {
       "_id":6058,
       "upazila_code":72,
       "union_code":55,
       "union_name":"Moazzempur",
       "union_ban_name":"মোয়াজ্জেমপুর"
    },
    {
       "_id":6059,
       "upazila_code":72,
       "union_code":63,
       "union_name":"Musuli",
       "union_ban_name":"মুসুলি"
    },
    {
       "_id":6060,
       "upazila_code":72,
       "union_code":71,
       "union_name":"Nandail",
       "union_ban_name":"নান্দাইল"
    },
    {
       "_id":6061,
       "upazila_code":72,
       "union_code":79,
       "union_name":"Rajgati",
       "union_ban_name":"রাজগতি"
    },
    {
       "_id":6062,
       "upazila_code":72,
       "union_code":87,
       "union_name":"Sherpur",
       "union_ban_name":"শেরপুর"
    },
    {
       "_id":6063,
       "upazila_code":72,
       "union_code":94,
       "union_name":"Singrail",
       "union_ban_name":"সিঙ্গরাইল"
    },
    {
       "_id":6065,
       "upazila_code":81,
       "union_code":12,
       "union_name":"Balikhan",
       "union_ban_name":"বলিখন"
    },
    {
       "_id":6066,
       "upazila_code":81,
       "union_code":13,
       "union_name":"Banihala",
       "union_ban_name":"বনহালা"
    },
    {
       "_id":6067,
       "upazila_code":81,
       "union_code":18,
       "union_name":"Bhaitkandi",
       "union_ban_name":"ভাইটকান্দি"
    },
    {
       "_id":6068,
       "upazila_code":81,
       "union_code":22,
       "union_name":"Bishka",
       "union_ban_name":"বিশ্কা"
    },
    {
       "_id":6069,
       "upazila_code":81,
       "union_code":27,
       "union_name":"Baola",
       "union_ban_name":"বাওলা"
    },
    {
       "_id":6070,
       "upazila_code":81,
       "union_code":31,
       "union_name":"Dhakua",
       "union_ban_name":"Kাকুয়া"
    },
    {
       "_id":6071,
       "upazila_code":81,
       "union_code":36,
       "union_name":"Galagaon",
       "union_ban_name":"গালগাঁও"
    },
    {
       "_id":6072,
       "upazila_code":81,
       "union_code":40,
       "union_name":"Kakni",
       "union_ban_name":"কাকনি"
    },
    {
       "_id":6073,
       "upazila_code":81,
       "union_code":45,
       "union_name":"Kamargaon",
       "union_ban_name":"কামারগাঁও"
    },
    {
       "_id":6074,
       "upazila_code":81,
       "union_code":49,
       "union_name":"Kamaria",
       "union_ban_name":"কামারিয়া"
    },
    {
       "_id":6075,
       "upazila_code":81,
       "union_code":54,
       "union_name":"Payari",
       "union_ban_name":"পেয়ারি"
    },
    {
       "_id":6076,
       "upazila_code":81,
       "union_code":58,
       "union_name":"Phulpur",
       "union_ban_name":"ফুলপুর"
    },
    {
       "_id":6077,
       "upazila_code":81,
       "union_code":63,
       "union_name":"Rahimganj",
       "union_ban_name":"রহিমগঞ্জ"
    },
    {
       "_id":6078,
       "upazila_code":81,
       "union_code":67,
       "union_name":"Rambhadrapur",
       "union_ban_name":"রামভদ্রপুর"
    },
    {
       "_id":6080,
       "upazila_code":81,
       "union_code":76,
       "union_name":"Rupasi",
       "union_ban_name":"রূপসী"
    },
    {
       "_id":6081,
       "upazila_code":81,
       "union_code":81,
       "union_name":"Sandhara",
       "union_ban_name":"সন্ধরা"
    },
    {
       "_id":6082,
       "upazila_code":81,
       "union_code":90,
       "union_name":"Singheshwar",
       "union_ban_name":"সিংহেশ্বর"
    },
    {
       "_id":6083,
       "upazila_code":81,
       "union_code":94,
       "union_name":"Tarakanda",
       "union_ban_name":"তারাকান্দা"
    },
    {
       "_id":6084,
       "upazila_code":94,
       "union_code":13,
       "union_name":"Amirabari",
       "union_ban_name":"আমিরবাড়ি"
    },
    {
       "_id":6085,
       "upazila_code":94,
       "union_code":15,
       "union_name":"Bailar",
       "union_ban_name":"বাইলার"
    },
    {
       "_id":6086,
       "upazila_code":94,
       "union_code":19,
       "union_name":"Bali Para",
       "union_ban_name":"বালি পাড়া"
    },
    {
       "_id":6087,
       "upazila_code":94,
       "union_code":28,
       "union_name":"Dhanikhola",
       "union_ban_name":"ধনীখোলা"
    },
    {
       "_id":6089,
       "upazila_code":94,
       "union_code":47,
       "union_name":"Kanihari",
       "union_ban_name":"কণিহারি"
    },
    {
       "_id":6090,
       "upazila_code":94,
       "union_code":57,
       "union_name":"Kanthal",
       "union_ban_name":"কাঁথাল"
    },
    {
       "_id":6092,
       "upazila_code":94,
       "union_code":63,
       "union_name":"Mokshapur",
       "union_ban_name":"মোক্ষপুর"
    },
    {
       "_id":6095,
       "upazila_code":94,
       "union_code":85,
       "union_name":"Trishal",
       "union_ban_name":"ত্রিশাল"
    },
    {
       "_id":6096,
       "upazila_code":3,
       "union_code":10,
       "union_name":"Ahsanganj",
       "union_ban_name":"আহসানগঞ্জ"
    },
    {
       "_id":6097,
       "upazila_code":3,
       "union_code":21,
       "union_name":"Bhopara",
       "union_ban_name":"ভোপাড়া"
    },
    {
       "_id":6098,
       "upazila_code":3,
       "union_code":31,
       "union_name":"Bisha",
       "union_ban_name":"বিশা"
    },
    {
       "_id":6099,
       "upazila_code":3,
       "union_code":42,
       "union_name":"Hatkalu Para",
       "union_ban_name":"হাটকালু পাড়া"
    },
    {
       "_id":6101,
       "upazila_code":3,
       "union_code":63,
       "union_name":"Maniari",
       "union_ban_name":"ম্যানারি"
    },
    {
       "_id":6102,
       "upazila_code":3,
       "union_code":73,
       "union_name":"Panchupur",
       "union_ban_name":"পাঁচুপুর"
    },
    {
       "_id":6103,
       "upazila_code":3,
       "union_code":84,
       "union_name":"Sahagola",
       "union_ban_name":"সাহোগোলা"
    },
    {
       "_id":6104,
       "upazila_code":6,
       "union_code":10,
       "union_name":"Adhaipur",
       "union_ban_name":"আধাইপুর"
    },
    {
       "_id":6105,
       "upazila_code":6,
       "union_code":21,
       "union_name":"Badalgachhi",
       "union_ban_name":"বাদলগাছি"
    },
    {
       "_id":6106,
       "upazila_code":6,
       "union_code":31,
       "union_name":"Balubhara",
       "union_ban_name":"বালুভরা"
    },
    {
       "_id":6107,
       "upazila_code":6,
       "union_code":42,
       "union_name":"Bilasbari",
       "union_ban_name":"বিলাসবাড়ি"
    },
    {
       "_id":6108,
       "upazila_code":6,
       "union_code":52,
       "union_name":"Pahar Pur",
       "union_ban_name":"পাহাড় পুর"
    },
    {
       "_id":6111,
       "upazila_code":6,
       "union_code":84,
       "union_name":"Mithapur",
       "union_ban_name":"মিঠাপুর"
    },
    {
       "_id":6112,
       "upazila_code":28,
       "union_code":10,
       "union_name":"Agra Digun",
       "union_ban_name":"আগ্রা দিগুন"
    },
    {
       "_id":6114,
       "upazila_code":28,
       "union_code":31,
       "union_name":"Aranagar",
       "union_ban_name":"অরণগর"
    },
    {
       "_id":6115,
       "upazila_code":28,
       "union_code":42,
       "union_name":"Dhamoirhat",
       "union_ban_name":"ধামইরহাট"
    },
    {
       "_id":6118,
       "upazila_code":28,
       "union_code":77,
       "union_name":"Khelna",
       "union_ban_name":"খেলনা"
    },
    {
       "_id":6119,
       "upazila_code":28,
       "union_code":84,
       "union_name":"Omar",
       "union_ban_name":"ওমর রা"
    },
    {
       "_id":6120,
       "upazila_code":47,
       "union_code":12,
       "union_name":"Bhalain",
       "union_ban_name":"ভালাইন"
    },
    {
       "_id":6121,
       "upazila_code":47,
       "union_code":13,
       "union_name":"Bharso",
       "union_ban_name":"ভরসো"
    },
    {
       "_id":6123,
       "upazila_code":47,
       "union_code":27,
       "union_name":"Ganeshpur",
       "union_ban_name":"গণেশপুর"
    },
    {
       "_id":6125,
       "upazila_code":47,
       "union_code":40,
       "union_name":"Kanso Para",
       "union_ban_name":"কানসো পাড়া"
    },
    {
       "_id":6126,
       "upazila_code":47,
       "union_code":47,
       "union_name":"Kashab",
       "union_ban_name":"কাশব"
    },
    {
       "_id":6128,
       "upazila_code":47,
       "union_code":61,
       "union_name":"Mainam",
       "union_ban_name":"ময়নাম"
    },
    {
       "_id":6130,
       "upazila_code":47,
       "union_code":74,
       "union_name":"Nurullabad",
       "union_ban_name":"নুরুল্লাবাদ"
    },
    {
       "_id":6131,
       "upazila_code":47,
       "union_code":81,
       "union_name":"Paranpur",
       "union_ban_name":"পরানপুর"
    },
    {
       "_id":6132,
       "upazila_code":47,
       "union_code":88,
       "union_name":"Prasadpur",
       "union_ban_name":"প্রসাদপুর"
    },
    {
       "_id":6134,
       "upazila_code":50,
       "union_code":15,
       "union_name":"Bhimpur",
       "union_ban_name":"ভীমপুর"
    },
    {
       "_id":6135,
       "upazila_code":50,
       "union_code":19,
       "union_name":"Chandas",
       "union_ban_name":"চান্দাস"
    },
    {
       "_id":6136,
       "upazila_code":50,
       "union_code":28,
       "union_name":"Cheragpur",
       "union_ban_name":"চেরাগপুর"
    },
    {
       "_id":6138,
       "upazila_code":50,
       "union_code":47,
       "union_name":"Hatur",
       "union_ban_name":"হাটুর"
    },
    {
       "_id":6139,
       "upazila_code":50,
       "union_code":57,
       "union_name":"Khajur",
       "union_ban_name":"খাজুর"
    },
    {
       "_id":6140,
       "upazila_code":50,
       "union_code":66,
       "union_name":"Mahadebpur",
       "union_ban_name":"মহাদেবপুর"
    },
    {
       "_id":6141,
       "upazila_code":50,
       "union_code":76,
       "union_name":"Roygaon",
       "union_ban_name":"রায়গাঁও"
    },
    {
       "_id":6142,
       "upazila_code":50,
       "union_code":85,
       "union_name":"Safapur",
       "union_ban_name":"সাফাপুর"
    },
    {
       "_id":6143,
       "upazila_code":50,
       "union_code":95,
       "union_name":"Uttargram",
       "union_ban_name":"উত্তরগ্রাম"
    },
    {
       "_id":6144,
       "upazila_code":60,
       "union_code":13,
       "union_name":"Baktiarpur",
       "union_ban_name":"বকতারপুর"
    },
    {
       "_id":6145,
       "upazila_code":60,
       "union_code":14,
       "union_name":"Balihar",
       "union_ban_name":"বলিহার"
    },
    {
       "_id":6146,
       "upazila_code":60,
       "union_code":21,
       "union_name":"Barshail",
       "union_ban_name":"বারশাইল"
    },
    {
       "_id":6148,
       "upazila_code":60,
       "union_code":36,
       "union_name":"Sailgachhi",
       "union_ban_name":"সাইলগাছি"
    },
    {
       "_id":6150,
       "upazila_code":60,
       "union_code":51,
       "union_name":"Dubalhati",
       "union_ban_name":"দুবলহাটি"
    },
    {
       "_id":6151,
       "upazila_code":60,
       "union_code":58,
       "union_name":"Hapania",
       "union_ban_name":"হাপানিয়া"
    },
    {
       "_id":6152,
       "upazila_code":60,
       "union_code":65,
       "union_name":"Hashaighari",
       "union_ban_name":"হাশাইঘড়ি"
    },
    {
       "_id":6153,
       "upazila_code":60,
       "union_code":73,
       "union_name":"Kirtipur",
       "union_ban_name":"কীর্তিপুর"
    },
    {
       "_id":6154,
       "upazila_code":60,
       "union_code":87,
       "union_name":"Sekherpur",
       "union_ban_name":"শেখেরপুর"
    },
    {
       "_id":6157,
       "upazila_code":69,
       "union_code":21,
       "union_name":"Bhabicha",
       "union_ban_name":"ভাবিচা"
    },
    {
       "_id":6158,
       "upazila_code":69,
       "union_code":31,
       "union_name":"Chandan Nagar",
       "union_ban_name":"চন্দন নগর"
    },
    {
       "_id":6159,
       "upazila_code":69,
       "union_code":42,
       "union_name":"Hajinagar",
       "union_ban_name":"হাজিনগর"
    },
    {
       "_id":6161,
       "upazila_code":69,
       "union_code":63,
       "union_name":"Parail",
       "union_ban_name":"প্যারায়েল"
    },
    {
       "_id":6163,
       "upazila_code":69,
       "union_code":84,
       "union_name":"Sreemantapur",
       "union_ban_name":"শ্রীমন্তপুর"
    },
    {
       "_id":6164,
       "upazila_code":75,
       "union_code":12,
       "union_name":"Akbarpur",
       "union_ban_name":"আকবরপুর"
    },
    {
       "_id":6165,
       "upazila_code":75,
       "union_code":17,
       "union_name":"Amair",
       "union_ban_name":"আমায়ার"
    },
    {
       "_id":6166,
       "upazila_code":75,
       "union_code":25,
       "union_name":"Dibar",
       "union_ban_name":"ডিবার"
    },
    {
       "_id":6167,
       "upazila_code":75,
       "union_code":34,
       "union_name":"Ghoshnagar",
       "union_ban_name":"ঘোষনগর"
    },
    {
       "_id":6169,
       "upazila_code":75,
       "union_code":51,
       "union_name":"Matindhar",
       "union_ban_name":"মাতিন্দহার"
    },
    {
       "_id":6170,
       "upazila_code":75,
       "union_code":60,
       "union_name":"Nazipur",
       "union_ban_name":"নাজিপুর"
    },
    {
       "_id":6171,
       "upazila_code":75,
       "union_code":69,
       "union_name":"Nirmail",
       "union_ban_name":"নির্মল"
    },
    {
       "_id":6172,
       "upazila_code":75,
       "union_code":77,
       "union_name":"Patichara",
       "union_ban_name":"পাটিচারা"
    },
    {
       "_id":6173,
       "upazila_code":75,
       "union_code":86,
       "union_name":"Patnitala",
       "union_ban_name":"পত্নীতলা."
    },
    {
       "_id":6174,
       "upazila_code":75,
       "union_code":94,
       "union_name":"Shihara",
       "union_ban_name":"শিহারা"
    },
    {
       "_id":6175,
       "upazila_code":79,
       "union_code":15,
       "union_name":"Chhaor",
       "union_ban_name":"শোর"
    },
    {
       "_id":6176,
       "upazila_code":79,
       "union_code":23,
       "union_name":"Ganguria",
       "union_ban_name":"গঙ্গুরিয়া"
    },
    {
       "_id":6177,
       "upazila_code":79,
       "union_code":31,
       "union_name":"Ghatnagar",
       "union_ban_name":"ঘাটনগর"
    },
    {
       "_id":6178,
       "upazila_code":79,
       "union_code":47,
       "union_name":"Masidpur",
       "union_ban_name":"মাসিদপুর"
    },
    {
       "_id":6179,
       "upazila_code":79,
       "union_code":55,
       "union_name":"Nithpur",
       "union_ban_name":"নিথপুর"
    },
    {
       "_id":6181,
       "upazila_code":85,
       "union_code":10,
       "union_name":"Bargachha",
       "union_ban_name":"বরগছা"
    },
    {
       "_id":6182,
       "upazila_code":85,
       "union_code":21,
       "union_name":"Ekdala",
       "union_ban_name":"একদল"
    },
    {
       "_id":6183,
       "upazila_code":85,
       "union_code":31,
       "union_name":"Gona",
       "union_ban_name":"গোনা"
    },
    {
       "_id":6184,
       "upazila_code":85,
       "union_code":42,
       "union_name":"Kaligram",
       "union_ban_name":"কালীগ্রাম"
    },
    {
       "_id":6186,
       "upazila_code":85,
       "union_code":63,
       "union_name":"Mirat",
       "union_ban_name":"মিরাট"
    },
    {
       "_id":6188,
       "upazila_code":85,
       "union_code":84,
       "union_name":"Raninagar",
       "union_ban_name":"রনিনগর"
    },
    {
       "_id":6189,
       "upazila_code":86,
       "union_code":17,
       "union_name":"Aihai",
       "union_ban_name":"এহাই"
    },
    {
       "_id":6190,
       "upazila_code":86,
       "union_code":39,
       "union_name":"Goala",
       "union_ban_name":"গোলা"
    },
    {
       "_id":6191,
       "upazila_code":86,
       "union_code":63,
       "union_name":"Pathari",
       "union_ban_name":"পাঠরী"
    },
    {
       "_id":6192,
       "upazila_code":86,
       "union_code":71,
       "union_name":"Sapahar",
       "union_ban_name":"সাপহর"
    },
    {
       "_id":6193,
       "upazila_code":86,
       "union_code":79,
       "union_name":"Shiranti",
       "union_ban_name":"শেরান্টি"
    },
    {
       "_id":6194,
       "upazila_code":86,
       "union_code":94,
       "union_name":"Tilna",
       "union_ban_name":"তিলনা"
    },
    {
       "_id":6195,
       "upazila_code":28,
       "union_code":13,
       "union_name":"Babra Hachla",
       "union_ban_name":"বাবারা হাচলা"
    },
    {
       "_id":6196,
       "upazila_code":28,
       "union_code":14,
       "union_name":"Boranal Eliasabad",
       "union_ban_name":"বোরানাল এলিয়াসাবাদ"
    },
    {
       "_id":6197,
       "upazila_code":28,
       "union_code":15,
       "union_name":"Bauisena",
       "union_ban_name":"বাউইসেনা."
    },
    {
       "_id":6199,
       "upazila_code":28,
       "union_code":31,
       "union_name":"Chanchari",
       "union_ban_name":"চঞ্চরী"
    },
    {
       "_id":6200,
       "upazila_code":28,
       "union_code":39,
       "union_name":"Joynagar",
       "union_ban_name":"জয়নাগর"
    },
    {
       "_id":6201,
       "upazila_code":28,
       "union_code":47,
       "union_name":"Kalabaria",
       "union_ban_name":"কালাবরিয়া"
    },
    {
       "_id":6202,
       "upazila_code":28,
       "union_code":63,
       "union_name":"Khasial",
       "union_ban_name":"খাসিয়াল"
    },
    {
       "_id":6203,
       "upazila_code":28,
       "union_code":71,
       "union_name":"Mauli",
       "union_ban_name":"মুলি"
    },
    {
       "_id":6204,
       "upazila_code":28,
       "union_code":79,
       "union_name":"Pahardanga",
       "union_ban_name":"পাহাড়িঙ্গা"
    },
    {
       "_id":6205,
       "upazila_code":28,
       "union_code":87,
       "union_name":"Peruli",
       "union_ban_name":"পেরুলি"
    },
    {
       "_id":6206,
       "upazila_code":28,
       "union_code":94,
       "union_name":"Purulia",
       "union_ban_name":"পুরুলিয়া"
    },
    {
       "_id":6207,
       "upazila_code":28,
       "union_code":95,
       "union_name":"Salamabad",
       "union_ban_name":"সালামাবাদ"
    },
    {
       "_id":6212,
       "upazila_code":52,
       "union_code":39,
       "union_name":"Kotakul",
       "union_ban_name":"কোটাকুল"
    },
    {
       "_id":6213,
       "upazila_code":52,
       "union_code":47,
       "union_name":"Lahuria",
       "union_ban_name":"লাহুরিয়া"
    },
    {
       "_id":6214,
       "upazila_code":52,
       "union_code":55,
       "union_name":"Lakshmipasha",
       "union_ban_name":"লক্ষ্মীপশা"
    },
    {
       "_id":6216,
       "upazila_code":52,
       "union_code":71,
       "union_name":"Mallikpur",
       "union_ban_name":"মল্লিকপুর"
    },
    {
       "_id":6217,
       "upazila_code":52,
       "union_code":79,
       "union_name":"Naldi",
       "union_ban_name":"নলদি"
    },
    {
       "_id":6218,
       "upazila_code":52,
       "union_code":87,
       "union_name":"Noagram",
       "union_ban_name":"নোয়াগ্রাম"
    },
    {
       "_id":6219,
       "upazila_code":52,
       "union_code":94,
       "union_name":"Shalnagar",
       "union_ban_name":"শালনগর"
    },
    {
       "_id":6220,
       "upazila_code":76,
       "union_code":11,
       "union_name":"Auria",
       "union_ban_name":"আরিয়া"
    },
    {
       "_id":6221,
       "upazila_code":76,
       "union_code":13,
       "union_name":"Banshgram",
       "union_ban_name":"বাঁশগ্রাম"
    },
    {
       "_id":6222,
       "upazila_code":76,
       "union_code":20,
       "union_name":"Bhadrabila",
       "union_ban_name":"ভদ্রবিলা"
    },
    {
       "_id":6223,
       "upazila_code":76,
       "union_code":27,
       "union_name":"Bichhali",
       "union_ban_name":"বিচালি"
    },
    {
       "_id":6224,
       "upazila_code":76,
       "union_code":33,
       "union_name":"Chandibarpur",
       "union_ban_name":"চন্দিবারপুরপুর"
    },
    {
       "_id":6225,
       "upazila_code":76,
       "union_code":40,
       "union_name":"Habakhali",
       "union_ban_name":"হাবখালী"
    },
    {
       "_id":6226,
       "upazila_code":76,
       "union_code":47,
       "union_name":"Kalora",
       "union_ban_name":"কলোরা"
    },
    {
       "_id":6227,
       "upazila_code":76,
       "union_code":54,
       "union_name":"Maij Para",
       "union_ban_name":"মিজ প্যারা"
    },
    {
       "_id":6228,
       "upazila_code":76,
       "union_code":61,
       "union_name":"Mulia",
       "union_ban_name":"মুলিয়া"
    },
    {
       "_id":6229,
       "upazila_code":76,
       "union_code":74,
       "union_name":"Sahabad",
       "union_ban_name":"সাহাবাদ"
    },
    {
       "_id":6230,
       "upazila_code":76,
       "union_code":81,
       "union_name":"Shaikhati",
       "union_ban_name":"শাইখতি"
    },
    {
       "_id":6231,
       "upazila_code":76,
       "union_code":88,
       "union_name":"Singasolpur",
       "union_ban_name":"সিঙ্গাসোলপুর"
    },
    {
       "_id":6232,
       "upazila_code":76,
       "union_code":94,
       "union_name":"Tularampur",
       "union_ban_name":"তুলুলামপুর"
    },
    {
       "_id":6233,
       "upazila_code":2,
       "union_code":23,
       "union_name":"Araihazar",
       "union_ban_name":"আরিহাজার"
    },
    {
       "_id":6234,
       "upazila_code":2,
       "union_code":26,
       "union_name":"Bishnandi",
       "union_ban_name":"বিষ্ণান্দি"
    },
    {
       "_id":6235,
       "upazila_code":2,
       "union_code":29,
       "union_name":"Brahmandi",
       "union_ban_name":"ব্রাহ্মান্দি"
    },
    {
       "_id":6236,
       "upazila_code":2,
       "union_code":31,
       "union_name":"Duptara",
       "union_ban_name":"দুপতারা।"
    },
    {
       "_id":6238,
       "upazila_code":2,
       "union_code":47,
       "union_name":"Haizadi",
       "union_ban_name":"হাঝাদি"
    },
    {
       "_id":6239,
       "upazila_code":2,
       "union_code":55,
       "union_name":"Kala Paharia",
       "union_ban_name":"কাল পাহাড়িয়া"
    },
    {
       "_id":6240,
       "upazila_code":2,
       "union_code":63,
       "union_name":"Khagakanda",
       "union_ban_name":"খাগকন্দ"
    },
    {
       "_id":6242,
       "upazila_code":2,
       "union_code":79,
       "union_name":"Sadasardi",
       "union_ban_name":"সদাশ্ডি"
    },
    {
       "_id":6243,
       "upazila_code":2,
       "union_code":87,
       "union_name":"Satgram",
       "union_ban_name":"সটগ্রাম"
    },
    {
       "_id":6244,
       "upazila_code":2,
       "union_code":94,
       "union_name":"Uchitpur",
       "union_ban_name":"উচিতপুর"
    },
    {
       "_id":6245,
       "upazila_code":4,
       "union_code":24,
       "union_name":"Baidyer Bazar",
       "union_ban_name":"বৈদ্যারী বাজার"
    },
    {
       "_id":6247,
       "upazila_code":4,
       "union_code":34,
       "union_name":"Jampur",
       "union_ban_name":"জ্যামপুর"
    },
    {
       "_id":6248,
       "upazila_code":4,
       "union_code":43,
       "union_name":"Kachpur",
       "union_ban_name":"কচপুর"
    },
    {
       "_id":6249,
       "upazila_code":4,
       "union_code":51,
       "union_name":"Mugra Para",
       "union_ban_name":"মুগরা পারা"
    },
    {
       "_id":6253,
       "upazila_code":4,
       "union_code":86,
       "union_name":"Shambhupura",
       "union_ban_name":"শম্ভুপুরা"
    },
    {
       "_id":6254,
       "upazila_code":4,
       "union_code":94,
       "union_name":"Sanmandi",
       "union_ban_name":"সানমান্দি"
    },
    {
       "_id":6255,
       "upazila_code":6,
       "union_code":15,
       "union_name":"Bandar",
       "union_ban_name":"বানর"
    },
    {
       "_id":6256,
       "upazila_code":6,
       "union_code":31,
       "union_name":"Dhamgar",
       "union_ban_name":"ধামগার"
    },
    {
       "_id":6257,
       "upazila_code":6,
       "union_code":47,
       "union_name":"Kalagachhia",
       "union_ban_name":"কালাগাচিয়া"
    },
    {
       "_id":6260,
       "upazila_code":58,
       "union_code":20,
       "union_name":"Alir Tek",
       "union_ban_name":"আলির টেক"
    },
    {
       "_id":6261,
       "upazila_code":58,
       "union_code":25,
       "union_name":"Baktaballi",
       "union_ban_name":"বাকতবালি"
    },
    {
       "_id":6263,
       "upazila_code":58,
       "union_code":37,
       "union_name":"Fatullah",
       "union_ban_name":"ফতুল্লাহ"
    },
    {
       "_id":6264,
       "upazila_code":58,
       "union_code":47,
       "union_name":"Gognagar",
       "union_ban_name":"গগনগর"
    },
    {
       "_id":6267,
       "upazila_code":68,
       "union_code":22,
       "union_name":"Bholaba",
       "union_ban_name":"ভোলাবা"
    },
    {
       "_id":6268,
       "upazila_code":68,
       "union_code":25,
       "union_name":"Bulta",
       "union_ban_name":"বুলতা"
    },
    {
       "_id":6270,
       "upazila_code":68,
       "union_code":39,
       "union_name":"Golakandail",
       "union_ban_name":"গোলকান্দাইল"
    },
    {
       "_id":6271,
       "upazila_code":68,
       "union_code":55,
       "union_name":"Kayet Para",
       "union_ban_name":"কায়েত পাড়া"
    },
    {
       "_id":6272,
       "upazila_code":68,
       "union_code":63,
       "union_name":"Mura Para",
       "union_ban_name":"মুর পাড়া"
    },
    {
       "_id":6273,
       "upazila_code":68,
       "union_code":79,
       "union_name":"Rupganj",
       "union_ban_name":"রূপগঞ্জ"
    },
    {
       "_id":6274,
       "upazila_code":7,
       "union_code":11,
       "union_name":"Amlaba",
       "union_ban_name":"আমলাবা"
    },
    {
       "_id":6275,
       "upazila_code":7,
       "union_code":13,
       "union_name":"Bajnaba",
       "union_ban_name":"বাজনবা"
    },
    {
       "_id":6276,
       "upazila_code":7,
       "union_code":20,
       "union_name":"Belabo",
       "union_ban_name":"বেলাবো"
    },
    {
       "_id":6277,
       "upazila_code":7,
       "union_code":25,
       "union_name":"Binyabaid",
       "union_ban_name":"বিনিয়াবায়েদ"
    },
    {
       "_id":6278,
       "upazila_code":7,
       "union_code":31,
       "union_name":"Char Ujilaba",
       "union_ban_name":"চর উজিলাবা"
    },
    {
       "_id":6280,
       "upazila_code":7,
       "union_code":71,
       "union_name":"Patuli",
       "union_ban_name":"পটুলি"
    },
    {
       "_id":6281,
       "upazila_code":7,
       "union_code":83,
       "union_name":"Sallabad",
       "union_ban_name":"সালাবাদ"
    },
    {
       "_id":6282,
       "upazila_code":52,
       "union_code":21,
       "union_name":"Bara Chapa",
       "union_ban_name":"বার চ্যাপা"
    },
    {
       "_id":6283,
       "upazila_code":52,
       "union_code":27,
       "union_name":"Chalak Char",
       "union_ban_name":"চালক চর"
    },
    {
       "_id":6284,
       "upazila_code":52,
       "union_code":33,
       "union_name":"Chandanbari",
       "union_ban_name":"চাঁদনবাড়ী"
    },
    {
       "_id":6285,
       "upazila_code":52,
       "union_code":40,
       "union_name":"Char Mandalia",
       "union_ban_name":"চর ম্যান্ডালিয়া"
    },
    {
       "_id":6287,
       "upazila_code":52,
       "union_code":54,
       "union_name":"Ekduaria",
       "union_ban_name":"একদুয়ারিয়া"
    },
    {
       "_id":6288,
       "upazila_code":52,
       "union_code":61,
       "union_name":"Gotashia",
       "union_ban_name":"গোটাশিয়া"
    },
    {
       "_id":6289,
       "upazila_code":52,
       "union_code":67,
       "union_name":"Kanchikata",
       "union_ban_name":"কাঞ্চিকটা"
    },
    {
       "_id":6290,
       "upazila_code":52,
       "union_code":74,
       "union_name":"Khidirpur",
       "union_ban_name":"খিদিরপুর"
    },
    {
       "_id":6292,
       "upazila_code":52,
       "union_code":94,
       "union_name":"Shukundi",
       "union_ban_name":"শুকুন্দি"
    },
    {
       "_id":6293,
       "upazila_code":60,
       "union_code":20,
       "union_name":"Alokbali",
       "union_ban_name":"অলোকবালি"
    },
    {
       "_id":6294,
       "upazila_code":60,
       "union_code":21,
       "union_name":"Amdia",
       "union_ban_name":"আমদিয়া"
    },
    {
       "_id":6295,
       "upazila_code":60,
       "union_code":23,
       "union_name":"Char Dighaldi",
       "union_ban_name":"চর দিঘলদী"
    },
    {
       "_id":6296,
       "upazila_code":60,
       "union_code":25,
       "union_name":"Chinishpur",
       "union_ban_name":"চিনিশপুর"
    },
    {
       "_id":6298,
       "upazila_code":60,
       "union_code":41,
       "union_name":"Karimpur",
       "union_ban_name":"করিমপুর"
    },
    {
       "_id":6300,
       "upazila_code":60,
       "union_code":53,
       "union_name":"Nuralla Pur U/C",
       "union_ban_name":"নুরল্লা পুর ইউ/সি"
    },
    {
       "_id":6301,
       "upazila_code":60,
       "union_code":59,
       "union_name":"Mahishasura",
       "union_ban_name":"মহিশাসুর"
    },
    {
       "_id":6302,
       "upazila_code":60,
       "union_code":65,
       "union_name":"Meher Para",
       "union_ban_name":"মেহের পারা"
    },
    {
       "_id":6303,
       "upazila_code":60,
       "union_code":77,
       "union_name":"Nazarpur",
       "union_ban_name":"নাসরপুর"
    },
    {
       "_id":6304,
       "upazila_code":60,
       "union_code":83,
       "union_name":"Paikar Char",
       "union_ban_name":"পাইকার চর"
    },
    {
       "_id":6305,
       "upazila_code":60,
       "union_code":89,
       "union_name":"Panchdona",
       "union_ban_name":"পঞ্চদোনা"
    },
    {
       "_id":6306,
       "upazila_code":60,
       "union_code":95,
       "union_name":"Silmandi",
       "union_ban_name":"সিল্মান্দি"
    },
    {
       "_id":6307,
       "upazila_code":63,
       "union_code":15,
       "union_name":"Char Sindur",
       "union_ban_name":"চর সিন্ধুর"
    },
    {
       "_id":6308,
       "upazila_code":63,
       "union_code":31,
       "union_name":"Danga",
       "union_ban_name":"ডাঙ্গা"
    },
    {
       "_id":6310,
       "upazila_code":63,
       "union_code":79,
       "union_name":"Jinardi",
       "union_ban_name":"জিনিদী"
    },
    {
       "_id":6311,
       "upazila_code":64,
       "union_code":10,
       "union_name":"Amirganj",
       "union_ban_name":"আমিরগঞ্জ"
    },
    {
       "_id":6312,
       "upazila_code":64,
       "union_code":12,
       "union_name":"Adiabad",
       "union_ban_name":"আদিবাদ"
    },
    {
       "_id":6313,
       "upazila_code":64,
       "union_code":14,
       "union_name":"Alipura",
       "union_ban_name":"আলিপুরা"
    },
    {
       "_id":6315,
       "upazila_code":64,
       "union_code":23,
       "union_name":"Chanderkandi",
       "union_ban_name":"চাঁদকন্দি"
    },
    {
       "_id":6317,
       "upazila_code":64,
       "union_code":30,
       "union_name":"Char Aralia",
       "union_ban_name":"চর আরালিয়া"
    },
    {
       "_id":6318,
       "upazila_code":64,
       "union_code":33,
       "union_name":"Char Madhua",
       "union_ban_name":"চর মধুয়া"
    },
    {
       "_id":6319,
       "upazila_code":64,
       "union_code":37,
       "union_name":"Char Subuddi",
       "union_ban_name":"চর সুবুদ্দি"
    },
    {
       "_id":6320,
       "upazila_code":64,
       "union_code":40,
       "union_name":"Daukar Char",
       "union_ban_name":"দাউকার চর"
    },
    {
       "_id":6321,
       "upazila_code":64,
       "union_code":44,
       "union_name":"Hairmara",
       "union_ban_name":"হেয়ারমারা"
    },
    {
       "_id":6323,
       "upazila_code":64,
       "union_code":50,
       "union_name":"Marjal",
       "union_ban_name":"মারজাল"
    },
    {
       "_id":6326,
       "upazila_code":64,
       "union_code":61,
       "union_name":"Mirzar Char",
       "union_ban_name":"মির্জার চর"
    },
    {
       "_id":6328,
       "upazila_code":64,
       "union_code":71,
       "union_name":"Nilakhya",
       "union_ban_name":"নীলখিয়া"
    },
    {
       "_id":6329,
       "upazila_code":64,
       "union_code":74,
       "union_name":"Palashtali",
       "union_ban_name":"পলাশতলী"
    },
    {
       "_id":6330,
       "upazila_code":64,
       "union_code":78,
       "union_name":"Paratali",
       "union_ban_name":"পারাতালি"
    },
    {
       "_id":6332,
       "upazila_code":64,
       "union_code":84,
       "union_name":"Roypura",
       "union_ban_name":"রায়পুরা"
    },
    {
       "_id":6334,
       "upazila_code":64,
       "union_code":94,
       "union_name":"Uttar Bakharnagar",
       "union_ban_name":"উত্তর বখরগগর"
    },
    {
       "_id":6336,
       "upazila_code":76,
       "union_code":21,
       "union_name":"Baghaba",
       "union_ban_name":"বাগবা"
    },
    {
       "_id":6337,
       "upazila_code":76,
       "union_code":31,
       "union_name":"Chak Radha",
       "union_ban_name":"চক রাধা"
    },
    {
       "_id":6339,
       "upazila_code":76,
       "union_code":52,
       "union_name":"Josar",
       "union_ban_name":"জোসার"
    },
    {
       "_id":6341,
       "upazila_code":76,
       "union_code":73,
       "union_name":"Masimpur",
       "union_ban_name":"মাসিমপুর"
    },
    {
       "_id":6342,
       "upazila_code":76,
       "union_code":84,
       "union_name":"Putia",
       "union_ban_name":"পুটিয়া"
    },
    {
       "_id":6343,
       "upazila_code":76,
       "union_code":94,
       "union_name":"Sadhar Char",
       "union_ban_name":"সদর চর"
    },
    {
       "_id":6344,
       "upazila_code":9,
       "union_code":19,
       "union_name":"Bagatipara",
       "union_ban_name":"বাগতিপাড়া"
    },
    {
       "_id":6345,
       "upazila_code":9,
       "union_code":38,
       "union_name":"Dayarampur",
       "union_ban_name":"দিনারামপুর"
    },
    {
       "_id":6346,
       "upazila_code":9,
       "union_code":42,
       "union_name":"Fhaguradiar",
       "union_ban_name":"ফাগুরাডিয়ার"
    },
    {
       "_id":6348,
       "upazila_code":9,
       "union_code":76,
       "union_name":"Jamnagar",
       "union_ban_name":"জামনগর"
    },
    {
       "_id":6349,
       "upazila_code":15,
       "union_code":21,
       "union_name":"Baraigram",
       "union_ban_name":"বড়াইগ্রাম"
    },
    {
       "_id":6350,
       "upazila_code":15,
       "union_code":23,
       "union_name":"Chandi",
       "union_ban_name":"চণ্ডী"
    },
    {
       "_id":6352,
       "upazila_code":15,
       "union_code":47,
       "union_name":"Joari",
       "union_ban_name":"জোয়ারি"
    },
    {
       "_id":6353,
       "upazila_code":15,
       "union_code":59,
       "union_name":"Jonail",
       "union_ban_name":"জোনাইল"
    },
    {
       "_id":6354,
       "upazila_code":15,
       "union_code":71,
       "union_name":"Majgaon",
       "union_ban_name":"মজগাঁও"
    },
    {
       "_id":6355,
       "upazila_code":15,
       "union_code":83,
       "union_name":"Nagar",
       "union_ban_name":"নগর"
    },
    {
       "_id":6356,
       "upazila_code":41,
       "union_code":13,
       "union_name":"Biaghat",
       "union_ban_name":"বিয়াঘাট"
    },
    {
       "_id":6357,
       "upazila_code":41,
       "union_code":27,
       "union_name":"Chapila",
       "union_ban_name":"চপিলা"
    },
    {
       "_id":6358,
       "upazila_code":41,
       "union_code":40,
       "union_name":"Dharabarisha",
       "union_ban_name":"ধরবারিশা"
    },
    {
       "_id":6359,
       "upazila_code":41,
       "union_code":60,
       "union_name":"Khubjipur",
       "union_ban_name":"খুবজীপুর"
    },
    {
       "_id":6360,
       "upazila_code":41,
       "union_code":67,
       "union_name":"Moshinda",
       "union_ban_name":"মোশিন্ডা"
    },
    {
       "_id":6362,
       "upazila_code":44,
       "union_code":17,
       "union_name":"Arbab",
       "union_ban_name":"আরবব"
    },
    {
       "_id":6363,
       "upazila_code":44,
       "union_code":19,
       "union_name":"Bilmaria",
       "union_ban_name":"বিলম্যারিয়া"
    },
    {
       "_id":6364,
       "upazila_code":44,
       "union_code":28,
       "union_name":"Changdhupail",
       "union_ban_name":"চাংধুপাইল"
    },
    {
       "_id":6365,
       "upazila_code":44,
       "union_code":38,
       "union_name":"Duaria",
       "union_ban_name":"ডুয়ারিয়া"
    },
    {
       "_id":6366,
       "upazila_code":44,
       "union_code":47,
       "union_name":"Durduria",
       "union_ban_name":"দুর্দুরিয়া"
    },
    {
       "_id":6367,
       "upazila_code":44,
       "union_code":57,
       "union_name":"Arjunpur Boromhati",
       "union_ban_name":"অর্জুনপুর বোরোহাটি"
    },
    {
       "_id":6368,
       "upazila_code":44,
       "union_code":66,
       "union_name":"Ishwardi",
       "union_ban_name":"Wardশ্বরদী"
    },
    {
       "_id":6369,
       "upazila_code":44,
       "union_code":76,
       "union_name":"Kadam Chilan",
       "union_ban_name":"কদম চিলান"
    },
    {
       "_id":6371,
       "upazila_code":44,
       "union_code":95,
       "union_name":"Walia",
       "union_ban_name":"ওয়ালিয়া"
    },
    {
       "_id":6372,
       "upazila_code":63,
       "union_code":20,
       "union_name":"Bara Harishpur",
       "union_ban_name":"বড় হরিশপুর"
    },
    {
       "_id":6373,
       "upazila_code":63,
       "union_code":23,
       "union_name":"Bipra Belgharia",
       "union_ban_name":"বিপ্র বেলঘরিয়া"
    },
    {
       "_id":6374,
       "upazila_code":63,
       "union_code":26,
       "union_name":"Brahmapur",
       "union_ban_name":"ব্রহ্মপুর"
    },
    {
       "_id":6375,
       "upazila_code":63,
       "union_code":29,
       "union_name":"Chhatni",
       "union_ban_name":"ছাতনী"
    },
    {
       "_id":6376,
       "upazila_code":63,
       "union_code":36,
       "union_name":"Dighapatia",
       "union_ban_name":"দিঘাপতিয়া"
    },
    {
       "_id":6377,
       "upazila_code":63,
       "union_code":43,
       "union_name":"Halsa",
       "union_ban_name":"হালসা"
    },
    {
       "_id":6378,
       "upazila_code":63,
       "union_code":51,
       "union_name":"Kafuria",
       "union_ban_name":"কাফুরিয়া"
    },
    {
       "_id":6379,
       "upazila_code":63,
       "union_code":58,
       "union_name":"Khajuria",
       "union_ban_name":"খাজুরিয়া"
    },
    {
       "_id":6380,
       "upazila_code":63,
       "union_code":65,
       "union_name":"Lakshmipur Kholaba",
       "union_ban_name":"লক্ষ্মীপুর খোলাবা"
    },
    {
       "_id":6381,
       "upazila_code":63,
       "union_code":73,
       "union_name":"Madhnagar",
       "union_ban_name":"মাধনগর"
    },
    {
       "_id":6382,
       "upazila_code":63,
       "union_code":87,
       "union_name":"Piprul",
       "union_ban_name":"পিপ্রুল"
    },
    {
       "_id":6383,
       "upazila_code":63,
       "union_code":94,
       "union_name":"Tebaria",
       "union_ban_name":"তেবাড়িয়া"
    },
    {
       "_id":6384,
       "upazila_code":91,
       "union_code":12,
       "union_name":"Chamari",
       "union_ban_name":"চামারী"
    },
    {
       "_id":6385,
       "upazila_code":91,
       "union_code":15,
       "union_name":"Chhatar Dighi",
       "union_ban_name":"ছাতর দিঘি"
    },
    {
       "_id":6386,
       "upazila_code":91,
       "union_code":23,
       "union_name":"Chaugram",
       "union_ban_name":"চৌগ্রাম"
    },
    {
       "_id":6387,
       "upazila_code":91,
       "union_code":31,
       "union_name":"Dahia",
       "union_ban_name":"ডাহিয়া"
    },
    {
       "_id":6388,
       "upazila_code":91,
       "union_code":39,
       "union_name":"Hatiandaha",
       "union_ban_name":"হাতিয়ান্ডা"
    },
    {
       "_id":6389,
       "upazila_code":91,
       "union_code":47,
       "union_name":"Italy",
       "union_ban_name":"ইতালি"
    },
    {
       "_id":6390,
       "upazila_code":91,
       "union_code":55,
       "union_name":"Kalam",
       "union_ban_name":"কালাম"
    },
    {
       "_id":6391,
       "upazila_code":91,
       "union_code":63,
       "union_name":"Lalore",
       "union_ban_name":"লালোর"
    },
    {
       "_id":6392,
       "upazila_code":91,
       "union_code":71,
       "union_name":"Ramananda Khajura",
       "union_ban_name":"রামানন্দ খাজুরা"
    },
    {
       "_id":6393,
       "upazila_code":91,
       "union_code":79,
       "union_name":"Sherkul",
       "union_ban_name":"শেরকুল"
    },
    {
       "_id":6394,
       "upazila_code":91,
       "union_code":87,
       "union_name":"Tajpur",
       "union_ban_name":"তাজপুর"
    },
    {
       "_id":6395,
       "upazila_code":91,
       "union_code":94,
       "union_name":"Sukash",
       "union_ban_name":"সুকাশ"
    },
    {
       "_id":6396,
       "upazila_code":4,
       "union_code":11,
       "union_name":"Baniajan",
       "union_ban_name":"বানিয়াজন"
    },
    {
       "_id":6397,
       "upazila_code":4,
       "union_code":23,
       "union_name":"Duaz",
       "union_ban_name":"দুয়াজ"
    },
    {
       "_id":6398,
       "upazila_code":4,
       "union_code":35,
       "union_name":"Loneshwar",
       "union_ban_name":"লোনেশ্বর"
    },
    {
       "_id":6399,
       "upazila_code":4,
       "union_code":47,
       "union_name":"Sarmaisa",
       "union_ban_name":"সরমাইসা"
    },
    {
       "_id":6400,
       "upazila_code":4,
       "union_code":59,
       "union_name":"Sukhari",
       "union_ban_name":"সুখারি"
    },
    {
       "_id":6401,
       "upazila_code":4,
       "union_code":71,
       "union_name":"Sonai",
       "union_ban_name":"সোনাই"
    },
    {
       "_id":6403,
       "upazila_code":9,
       "union_code":11,
       "union_name":"Asma",
       "union_ban_name":"আসমা"
    },
    {
       "_id":6404,
       "upazila_code":9,
       "union_code":23,
       "union_name":"Barhatta",
       "union_ban_name":"বারহাট্টা"
    },
    {
       "_id":6405,
       "upazila_code":9,
       "union_code":35,
       "union_name":"Baushi",
       "union_ban_name":"বাউশি"
    },
    {
       "_id":6406,
       "upazila_code":9,
       "union_code":47,
       "union_name":"Chhiram",
       "union_ban_name":"ছিরাম"
    },
    {
       "_id":6408,
       "upazila_code":9,
       "union_code":71,
       "union_name":"Sahata",
       "union_ban_name":"সাহাটা"
    },
    {
       "_id":6409,
       "upazila_code":9,
       "union_code":83,
       "union_name":"Singdha",
       "union_ban_name":"সিংধা"
    },
    {
       "_id":6410,
       "upazila_code":18,
       "union_code":12,
       "union_name":"Bakaljora",
       "union_ban_name":"বাকলজোড়া"
    },
    {
       "_id":6411,
       "upazila_code":18,
       "union_code":17,
       "union_name":"Birisiri",
       "union_ban_name":"বিরিসিরি"
    },
    {
       "_id":6412,
       "upazila_code":18,
       "union_code":25,
       "union_name":"Chandigarh",
       "union_ban_name":"চণ্ডীগড়"
    },
    {
       "_id":6414,
       "upazila_code":18,
       "union_code":69,
       "union_name":"Gaokandia",
       "union_ban_name":"গাওকান্দিয়া"
    },
    {
       "_id":6415,
       "upazila_code":18,
       "union_code":77,
       "union_name":"Kakairgara",
       "union_ban_name":"কাকাইরগড়া"
    },
    {
       "_id":6416,
       "upazila_code":18,
       "union_code":86,
       "union_name":"Kullagora",
       "union_ban_name":"কুল্লাগোড়া"
    },
    {
       "_id":6417,
       "upazila_code":38,
       "union_code":13,
       "union_name":"Chakua",
       "union_ban_name":"চাকুয়া"
    },
    {
       "_id":6419,
       "upazila_code":38,
       "union_code":40,
       "union_name":"Khaliajuri",
       "union_ban_name":"খালিয়াজুরী"
    },
    {
       "_id":6421,
       "upazila_code":38,
       "union_code":67,
       "union_name":"Mendipur",
       "union_ban_name":"মেন্ডিপুর"
    },
    {
       "_id":6423,
       "upazila_code":40,
       "union_code":11,
       "union_name":"Bara Kharpan",
       "union_ban_name":"বড় খর্পণ"
    },
    {
       "_id":6424,
       "upazila_code":40,
       "union_code":23,
       "union_name":"Kailati",
       "union_ban_name":"কৈলাটি"
    },
    {
       "_id":6425,
       "upazila_code":40,
       "union_code":35,
       "union_name":"Kalmakanda",
       "union_ban_name":"কলমাকান্দা"
    },
    {
       "_id":6426,
       "upazila_code":40,
       "union_code":47,
       "union_name":"Kharnai",
       "union_ban_name":"খারনাই"
    },
    {
       "_id":6427,
       "upazila_code":40,
       "union_code":59,
       "union_name":"Lengura",
       "union_ban_name":"লেঙ্গুরা"
    },
    {
       "_id":6429,
       "upazila_code":40,
       "union_code":83,
       "union_name":"Pogla",
       "union_ban_name":"পোগলা"
    },
    {
       "_id":6430,
       "upazila_code":40,
       "union_code":95,
       "union_name":"Rangchhati",
       "union_ban_name":"রাঙ্গছটি"
    },
    {
       "_id":6431,
       "upazila_code":47,
       "union_code":11,
       "union_name":"Asujia",
       "union_ban_name":"আসুজিয়া"
    },
    {
       "_id":6432,
       "upazila_code":47,
       "union_code":13,
       "union_name":"Balaishimul",
       "union_ban_name":"বালাইশিমুল"
    },
    {
       "_id":6433,
       "upazila_code":47,
       "union_code":20,
       "union_name":"Chirang",
       "union_ban_name":"চিরং"
    },
    {
       "_id":6434,
       "upazila_code":47,
       "union_code":27,
       "union_name":"Dalpa",
       "union_ban_name":"ডালপা"
    },
    {
       "_id":6435,
       "upazila_code":47,
       "union_code":33,
       "union_name":"Garadoba",
       "union_ban_name":"গারাদোবা"
    },
    {
       "_id":6436,
       "upazila_code":47,
       "union_code":40,
       "union_name":"Ganda",
       "union_ban_name":"গন্ডা"
    },
    {
       "_id":6437,
       "upazila_code":47,
       "union_code":47,
       "union_name":"Kandiura",
       "union_ban_name":"কান্দিউড়া"
    },
    {
       "_id":6438,
       "upazila_code":47,
       "union_code":61,
       "union_name":"Mashka",
       "union_ban_name":"মাশকা"
    },
    {
       "_id":6439,
       "upazila_code":47,
       "union_code":67,
       "union_name":"Muzaffarpur",
       "union_ban_name":"মুজাফফরপুর"
    },
    {
       "_id":6441,
       "upazila_code":47,
       "union_code":81,
       "union_name":"Paikura",
       "union_ban_name":"পাইকুরা"
    },
    {
       "_id":6442,
       "upazila_code":47,
       "union_code":88,
       "union_name":"Roailbari",
       "union_ban_name":"রওলবাড়ি"
    },
    {
       "_id":6443,
       "upazila_code":47,
       "union_code":94,
       "union_name":"Sandikona",
       "union_ban_name":"সানডিকোনা"
    },
    {
       "_id":6445,
       "upazila_code":56,
       "union_code":21,
       "union_name":"Gobindasree",
       "union_ban_name":"গোবিন্দশ্রী"
    },
    {
       "_id":6446,
       "upazila_code":56,
       "union_code":31,
       "union_name":"Chandgaon",
       "union_ban_name":"চাঁদগাঁও"
    },
    {
       "_id":6447,
       "upazila_code":56,
       "union_code":42,
       "union_name":"Kaitail",
       "union_ban_name":"কাইতাইল"
    },
    {
       "_id":6448,
       "upazila_code":56,
       "union_code":52,
       "union_name":"Madan",
       "union_ban_name":"মদন"
    },
    {
       "_id":6449,
       "upazila_code":56,
       "union_code":63,
       "union_name":"Maghan",
       "union_ban_name":"মাঘান"
    },
    {
       "_id":6450,
       "upazila_code":56,
       "union_code":73,
       "union_name":"Nayekpur",
       "union_ban_name":"নায়কপুর"
    },
    {
       "_id":6451,
       "upazila_code":56,
       "union_code":84,
       "union_name":"Tiasree",
       "union_ban_name":"তিয়াশ্রী"
    },
    {
       "_id":6452,
       "upazila_code":63,
       "union_code":10,
       "union_name":"Barkashia Biramp",
       "union_ban_name":"বরকশিয়া বিরাম্প"
    },
    {
       "_id":6453,
       "upazila_code":63,
       "union_code":21,
       "union_name":"Baratali Banihari",
       "union_ban_name":"বড়তলী বনহরি"
    },
    {
       "_id":6454,
       "upazila_code":63,
       "union_code":31,
       "union_name":"Gaglajur",
       "union_ban_name":"গাগলাজুর"
    },
    {
       "_id":6455,
       "upazila_code":63,
       "union_code":42,
       "union_name":"Maghan Siadhar",
       "union_ban_name":"মাঘান সিয়াধর"
    },
    {
       "_id":6456,
       "upazila_code":63,
       "union_code":63,
       "union_name":"Samaj Sahildeo",
       "union_ban_name":"সমাজ সহিলিও"
    },
    {
       "_id":6457,
       "upazila_code":63,
       "union_code":73,
       "union_name":"Suair",
       "union_ban_name":"সুয়ার"
    },
    {
       "_id":6459,
       "upazila_code":74,
       "union_code":13,
       "union_name":"Amtala",
       "union_ban_name":"আমতলা"
    },
    {
       "_id":6460,
       "upazila_code":74,
       "union_code":15,
       "union_name":"Challisha",
       "union_ban_name":"ছলিশা"
    },
    {
       "_id":6461,
       "upazila_code":74,
       "union_code":23,
       "union_name":"Dakshin Bishiura",
       "union_ban_name":"দক্ষিণ বিশিউরা"
    },
    {
       "_id":6463,
       "upazila_code":74,
       "union_code":39,
       "union_name":"Kaliara Gabragat",
       "union_ban_name":"কালিয়ারা গাবরাগাত"
    },
    {
       "_id":6464,
       "upazila_code":74,
       "union_code":47,
       "union_name":"Lakshmiganj",
       "union_ban_name":"লক্ষ্মীগঞ্জ"
    },
    {
       "_id":6466,
       "upazila_code":74,
       "union_code":55,
       "union_name":"Medni",
       "union_ban_name":"মেদনি"
    },
    {
       "_id":6467,
       "upazila_code":74,
       "union_code":63,
       "union_name":"Maugati",
       "union_ban_name":"মৌগতি"
    },
    {
       "_id":6468,
       "upazila_code":74,
       "union_code":79,
       "union_name":"Rauha",
       "union_ban_name":"রৌহা"
    },
    {
       "_id":6469,
       "upazila_code":74,
       "union_code":87,
       "union_name":"Singhar Bangla",
       "union_ban_name":"সিংহর বাংলা"
    },
    {
       "_id":6470,
       "upazila_code":74,
       "union_code":94,
       "union_name":"Thakurakona",
       "union_ban_name":"ঠাকুরাকোনা"
    },
    {
       "_id":6471,
       "upazila_code":83,
       "union_code":13,
       "union_name":"Agia",
       "union_ban_name":"আগিয়া"
    },
    {
       "_id":6473,
       "upazila_code":83,
       "union_code":23,
       "union_name":"Bishkakuni",
       "union_ban_name":"বিশকাকুনি"
    },
    {
       "_id":6474,
       "upazila_code":83,
       "union_code":31,
       "union_name":"Dhala Mulgaon",
       "union_ban_name":"ধলা মুলগাঁও"
    },
    {
       "_id":6476,
       "upazila_code":83,
       "union_code":55,
       "union_name":"Gohalakanda",
       "union_ban_name":"গোহালকান্দা"
    },
    {
       "_id":6477,
       "upazila_code":83,
       "union_code":63,
       "union_name":"Hogla",
       "union_ban_name":"হোগলা"
    },
    {
       "_id":6478,
       "upazila_code":83,
       "union_code":71,
       "union_name":"Jaria",
       "union_ban_name":"জারিয়া"
    },
    {
       "_id":6479,
       "upazila_code":83,
       "union_code":79,
       "union_name":"Khalishaur",
       "union_ban_name":"খালিশৌর"
    },
    {
       "_id":6480,
       "upazila_code":83,
       "union_code":87,
       "union_name":"Narandia",
       "union_ban_name":"নারান্দিয়া"
    },
    {
       "_id":6481,
       "upazila_code":83,
       "union_code":94,
       "union_name":"Purbadhala",
       "union_ban_name":"পূর্বধলা"
    },
    {
       "_id":6482,
       "upazila_code":12,
       "union_code":13,
       "union_name":"Bala Para'",
       "union_ban_name":"বাল পাড়া '"
    },
    {
       "_id":6483,
       "upazila_code":12,
       "union_code":19,
       "union_name":"Dimla",
       "union_ban_name":"ডিমলা"
    },
    {
       "_id":6484,
       "upazila_code":12,
       "union_code":28,
       "union_name":"Gayabari",
       "union_ban_name":"গায়াবাড়ি"
    },
    {
       "_id":6485,
       "upazila_code":12,
       "union_code":38,
       "union_name":"Jhunagachh Chapani",
       "union_ban_name":"ঝুনাগাছ চাপানী"
    },
    {
       "_id":6486,
       "upazila_code":12,
       "union_code":47,
       "union_name":"Khalisa Chapani",
       "union_ban_name":"খলিসা চাপানী"
    },
    {
       "_id":6487,
       "upazila_code":12,
       "union_code":57,
       "union_name":"Khoga Kharibari",
       "union_ban_name":"খোগা খারিবাড়ি"
    },
    {
       "_id":6488,
       "upazila_code":12,
       "union_code":66,
       "union_name":"Naotara",
       "union_ban_name":"নওতারা"
    },
    {
       "_id":6489,
       "upazila_code":12,
       "union_code":76,
       "union_name":"Paschim Chhatnai",
       "union_ban_name":"পাসচিম ছাতনাই"
    },
    {
       "_id":6490,
       "upazila_code":12,
       "union_code":85,
       "union_name":"Purba Chhatnai",
       "union_ban_name":"পূর্ব ছাতনাই"
    },
    {
       "_id":6491,
       "upazila_code":12,
       "union_code":95,
       "union_name":"Tepa Kharibari",
       "union_ban_name":"টেপা খারিবাড়ি"
    },
    {
       "_id":6492,
       "upazila_code":15,
       "union_code":17,
       "union_name":"Bamunia",
       "union_ban_name":"বামুনিয়া"
    },
    {
       "_id":6493,
       "upazila_code":15,
       "union_code":19,
       "union_name":"Bhogdabari",
       "union_ban_name":"ভোগদাবাড়ি"
    },
    {
       "_id":6494,
       "upazila_code":15,
       "union_code":28,
       "union_name":"Boragari",
       "union_ban_name":"বোরগারি"
    },
    {
       "_id":6495,
       "upazila_code":15,
       "union_code":38,
       "union_name":"Domar",
       "union_ban_name":"ডোমার"
    },
    {
       "_id":6496,
       "upazila_code":15,
       "union_code":47,
       "union_name":"Gomnati",
       "union_ban_name":"গোমনতি"
    },
    {
       "_id":6497,
       "upazila_code":15,
       "union_code":57,
       "union_name":"Harinchara",
       "union_ban_name":"হরিণচারা"
    },
    {
       "_id":6498,
       "upazila_code":15,
       "union_code":66,
       "union_name":"Jorabari",
       "union_ban_name":"জোড়বাড়ি"
    },
    {
       "_id":6499,
       "upazila_code":15,
       "union_code":76,
       "union_name":"Ketkibari",
       "union_ban_name":"কেতকিবাড়ি"
    },
    {
       "_id":6500,
       "upazila_code":15,
       "union_code":85,
       "union_name":"Panga Matukpur",
       "union_ban_name":"পাঙ্গা মাতুকপুর"
    },
    {
       "_id":6502,
       "upazila_code":36,
       "union_code":13,
       "union_name":"Balagram",
       "union_ban_name":"বালাগ্রাম"
    },
    {
       "_id":6504,
       "upazila_code":36,
       "union_code":21,
       "union_name":"Dharmapal",
       "union_ban_name":"ধর্মপাল"
    },
    {
       "_id":6505,
       "upazila_code":36,
       "union_code":29,
       "union_name":"Golmunda",
       "union_ban_name":"গোলমুন্ডা"
    },
    {
       "_id":6506,
       "upazila_code":36,
       "union_code":36,
       "union_name":"Golna",
       "union_ban_name":"গোলনা"
    },
    {
       "_id":6507,
       "upazila_code":36,
       "union_code":51,
       "union_name":"Kaimari'",
       "union_ban_name":"কাইমারী '"
    },
    {
       "_id":6508,
       "upazila_code":36,
       "union_code":58,
       "union_name":"Kanthali",
       "union_ban_name":"কাঁথালি"
    },
    {
       "_id":6509,
       "upazila_code":36,
       "union_code":65,
       "union_name":"Khutamara",
       "union_ban_name":"খুটামারা"
    },
    {
       "_id":6510,
       "upazila_code":36,
       "union_code":80,
       "union_name":"Mirganj",
       "union_ban_name":"মিরগঞ্জ"
    },
    {
       "_id":6512,
       "upazila_code":36,
       "union_code":94,
       "union_name":"Saulmari'",
       "union_ban_name":"শৌলমারী '"
    },
    {
       "_id":6513,
       "upazila_code":45,
       "union_code":17,
       "union_name":"Bahagili",
       "union_ban_name":"বাহাগিলি"
    },
    {
       "_id":6514,
       "upazila_code":45,
       "union_code":25,
       "union_name":"Barabhita",
       "union_ban_name":"বড়ভিতা"
    },
    {
       "_id":6515,
       "upazila_code":45,
       "union_code":34,
       "union_name":"Chandkhana",
       "union_ban_name":"চাঁদখানা"
    },
    {
       "_id":6516,
       "upazila_code":45,
       "union_code":43,
       "union_name":"Garagram",
       "union_ban_name":"গড়াগ্রাম"
    },
    {
       "_id":6517,
       "upazila_code":45,
       "union_code":51,
       "union_name":"Kishoreganj",
       "union_ban_name":"কিশোরগঞ্জ"
    },
    {
       "_id":6519,
       "upazila_code":45,
       "union_code":69,
       "union_name":"Nitai",
       "union_ban_name":"নিতাই"
    },
    {
       "_id":6520,
       "upazila_code":45,
       "union_code":86,
       "union_name":"Putimari'",
       "union_ban_name":"পুতিমারী '"
    },
    {
       "_id":6521,
       "upazila_code":45,
       "union_code":94,
       "union_name":"Ranachandi",
       "union_ban_name":"রানাচণ্ডী"
    },
    {
       "_id":6522,
       "upazila_code":64,
       "union_code":11,
       "union_name":"Chapra Saramjani",
       "union_ban_name":"চাপড়া সরমজনি"
    },
    {
       "_id":6523,
       "upazila_code":64,
       "union_code":12,
       "union_name":"Charaikhola",
       "union_ban_name":"চরাইখোলা"
    },
    {
       "_id":6524,
       "upazila_code":64,
       "union_code":18,
       "union_name":"Chaora Bargachha",
       "union_ban_name":"চাওড়া বরগাছা"
    },
    {
       "_id":6525,
       "upazila_code":64,
       "union_code":25,
       "union_name":"Gorgram",
       "union_ban_name":"গর্গ্রাম"
    },
    {
       "_id":6526,
       "upazila_code":64,
       "union_code":31,
       "union_name":"Itakhola",
       "union_ban_name":"ইটাখোলা"
    },
    {
       "_id":6527,
       "upazila_code":64,
       "union_code":37,
       "union_name":"Kachukata",
       "union_ban_name":"কচুকাতা"
    },
    {
       "_id":6528,
       "upazila_code":64,
       "union_code":44,
       "union_name":"Khokshabari",
       "union_ban_name":"খোকসাবাড়ি"
    },
    {
       "_id":6529,
       "upazila_code":64,
       "union_code":50,
       "union_name":"Kunda Pukur",
       "union_ban_name":"কুন্ডা পুকুর"
    },
    {
       "_id":6530,
       "upazila_code":64,
       "union_code":63,
       "union_name":"Panch Pukur",
       "union_ban_name":"পঞ্চ পুকুর"
    },
    {
       "_id":6531,
       "upazila_code":64,
       "union_code":73,
       "union_name":"Lakshmi Chap",
       "union_ban_name":"লক্ষ্মী চ্যাপ"
    },
    {
       "_id":6533,
       "upazila_code":64,
       "union_code":82,
       "union_name":"Sangalshi",
       "union_ban_name":"সাঙ্গালশি"
    },
    {
       "_id":6535,
       "upazila_code":64,
       "union_code":94,
       "union_name":"Tupamari",
       "union_ban_name":"টুপমারি"
    },
    {
       "_id":6536,
       "upazila_code":85,
       "union_code":23,
       "union_name":"Bangalipur",
       "union_ban_name":"বাঙালিপুর"
    },
    {
       "_id":6537,
       "upazila_code":85,
       "union_code":27,
       "union_name":"Bothlagari",
       "union_ban_name":"বোথলগরী"
    },
    {
       "_id":6538,
       "upazila_code":85,
       "union_code":40,
       "union_name":"Kamar Pukur",
       "union_ban_name":"কামার পুকুর"
    },
    {
       "_id":6539,
       "upazila_code":85,
       "union_code":54,
       "union_name":"Khata Madhupur",
       "union_ban_name":"খাতা মধুপুর"
    },
    {
       "_id":6540,
       "upazila_code":85,
       "union_code":67,
       "union_name":"Kushiram Belpukur",
       "union_ban_name":"কুশিরাম বেলপুকুর"
    },
    {
       "_id":6541,
       "upazila_code":7,
       "union_code":10,
       "union_name":"Alyerapur",
       "union_ban_name":"আলিরপুর"
    },
    {
       "_id":6542,
       "upazila_code":7,
       "union_code":12,
       "union_name":"Amanullapur",
       "union_ban_name":"আমানুল্লাপুর"
    },
    {
       "_id":6544,
       "upazila_code":7,
       "union_code":28,
       "union_name":"Chhayani",
       "union_ban_name":"ছায়ানী"
    },
    {
       "_id":6546,
       "upazila_code":7,
       "union_code":42,
       "union_name":"Eklashpur",
       "union_ban_name":"একলাশপুর"
    },
    {
       "_id":6548,
       "upazila_code":7,
       "union_code":49,
       "union_name":"Narottampur",
       "union_ban_name":"নরোত্তমপুর"
    },
    {
       "_id":6550,
       "upazila_code":7,
       "union_code":56,
       "union_name":"Jirtali",
       "union_ban_name":"জির্তালি"
    },
    {
       "_id":6553,
       "upazila_code":7,
       "union_code":70,
       "union_name":"Mir Warishpur",
       "union_ban_name":"মীর ওয়ারিশপুর"
    },
    {
       "_id":6554,
       "upazila_code":7,
       "union_code":80,
       "union_name":"Rajganj",
       "union_ban_name":"রাজগঞ্জ"
    },
    {
       "_id":6557,
       "upazila_code":10,
       "union_code":17,
       "union_name":"Badalkut",
       "union_ban_name":"বাদলকুট"
    },
    {
       "_id":6558,
       "upazila_code":10,
       "union_code":19,
       "union_name":"Hatpukuria Ghatlabag",
       "union_ban_name":"হাটপুকুরিয়া ঘাটলাবাগ"
    },
    {
       "_id":6559,
       "upazila_code":10,
       "union_code":28,
       "union_name":"Khil Para",
       "union_ban_name":"খিল পাড়া"
    },
    {
       "_id":6561,
       "upazila_code":10,
       "union_code":47,
       "union_name":"Nayakhola",
       "union_ban_name":"নয়াখোলা"
    },
    {
       "_id":6563,
       "upazila_code":10,
       "union_code":66,
       "union_name":"Parkote",
       "union_ban_name":"পার্কোট"
    },
    {
       "_id":6564,
       "upazila_code":10,
       "union_code":76,
       "union_name":"Ramnarayanpur",
       "union_ban_name":"রামনারায়ণপুর"
    },
    {
       "_id":6566,
       "upazila_code":21,
       "union_code":10,
       "union_name":"Char Elahi",
       "union_ban_name":"চর এলাহী"
    },
    {
       "_id":6567,
       "upazila_code":21,
       "union_code":11,
       "union_name":"Char Fakira",
       "union_ban_name":"চর ফকিরা"
    },
    {
       "_id":6568,
       "upazila_code":21,
       "union_code":23,
       "union_name":"Char Hazari",
       "union_ban_name":"চর হাজারী"
    },
    {
       "_id":6569,
       "upazila_code":21,
       "union_code":35,
       "union_name":"Char Kakra",
       "union_ban_name":"চর কাকড়া"
    },
    {
       "_id":6570,
       "upazila_code":21,
       "union_code":47,
       "union_name":"Char Parbati",
       "union_ban_name":"চর পার্বতী"
    },
    {
       "_id":6573,
       "upazila_code":21,
       "union_code":83,
       "union_name":"Sirajpur",
       "union_ban_name":"সিরাজপুর"
    },
    {
       "_id":6575,
       "upazila_code":36,
       "union_code":19,
       "union_name":"Chandnandi",
       "union_ban_name":"চন্দননদী"
    },
    {
       "_id":6576,
       "upazila_code":36,
       "union_code":28,
       "union_name":"Char Ishwar",
       "union_ban_name":"চর warশ্বর"
    },
    {
       "_id":6577,
       "upazila_code":36,
       "union_code":38,
       "union_name":"Char King",
       "union_ban_name":"চর কিং"
    },
    {
       "_id":6578,
       "upazila_code":36,
       "union_code":47,
       "union_name":"Harni",
       "union_ban_name":"হার্নি"
    },
    {
       "_id":6579,
       "upazila_code":36,
       "union_code":57,
       "union_name":"Jahajmara",
       "union_ban_name":"জাহাজমারা"
    },
    {
       "_id":6581,
       "upazila_code":36,
       "union_code":69,
       "union_name":"Nijum Dip",
       "union_ban_name":"নিজুম ডিপ"
    },
    {
       "_id":6582,
       "upazila_code":36,
       "union_code":76,
       "union_name":"Sonadia",
       "union_ban_name":"সোনাদিয়া"
    },
    {
       "_id":6583,
       "upazila_code":36,
       "union_code":85,
       "union_name":"Sukh Char",
       "union_ban_name":"সুখ চর"
    },
    {
       "_id":6584,
       "upazila_code":36,
       "union_code":95,
       "union_name":"Tamaruddin",
       "union_ban_name":"তামারউদ্দিন"
    },
    {
       "_id":6585,
       "upazila_code":47,
       "union_code":23,
       "union_name":"Bataiya",
       "union_ban_name":"বাতাইয়া"
    },
    {
       "_id":6586,
       "upazila_code":47,
       "union_code":27,
       "union_name":"Chap Rashir Hat",
       "union_ban_name":"চ্যাপ রাশির হাট"
    },
    {
       "_id":6587,
       "upazila_code":47,
       "union_code":35,
       "union_name":"Dhan Shalik",
       "union_ban_name":"ধন শালিক"
    },
    {
       "_id":6588,
       "upazila_code":47,
       "union_code":45,
       "union_name":"Dhan Siri",
       "union_ban_name":"ধন সিরি"
    },
    {
       "_id":6589,
       "upazila_code":47,
       "union_code":55,
       "union_name":"Ghoshbagh",
       "union_ban_name":"ঘোষবাগ"
    },
    {
       "_id":6592,
       "upazila_code":80,
       "union_code":13,
       "union_name":"Arjuntala",
       "union_ban_name":"অর্জুনতলা"
    },
    {
       "_id":6593,
       "upazila_code":80,
       "union_code":19,
       "union_name":"Bejoybagh",
       "union_ban_name":"বেজয়বাগ"
    },
    {
       "_id":6594,
       "upazila_code":80,
       "union_code":28,
       "union_name":"Chhatarpaia",
       "union_ban_name":"ছাতরপইয়া"
    },
    {
       "_id":6596,
       "upazila_code":80,
       "union_code":47,
       "union_name":"Kabilpur",
       "union_ban_name":"কাবিলপুর"
    },
    {
       "_id":6597,
       "upazila_code":80,
       "union_code":57,
       "union_name":"Kadra",
       "union_ban_name":"কদরা"
    },
    {
       "_id":6598,
       "upazila_code":80,
       "union_code":66,
       "union_name":"Kesharpar",
       "union_ban_name":"কেশরপাড়"
    },
    {
       "_id":6600,
       "upazila_code":80,
       "union_code":85,
       "union_name":"Nabipur",
       "union_ban_name":"নবীপুর"
    },
    {
       "_id":6601,
       "upazila_code":83,
       "union_code":13,
       "union_name":"Ambarnagar",
       "union_ban_name":"আম্বরনগর"
    },
    {
       "_id":6602,
       "upazila_code":83,
       "union_code":15,
       "union_name":"Amisha Para",
       "union_ban_name":"আমিশা পাড়া"
    },
    {
       "_id":6603,
       "upazila_code":83,
       "union_code":17,
       "union_name":"Baragaon",
       "union_ban_name":"বড়গাঁও"
    },
    {
       "_id":6605,
       "upazila_code":83,
       "union_code":31,
       "union_name":"Chashirhat",
       "union_ban_name":"চশিরহাট"
    },
    {
       "_id":6606,
       "upazila_code":83,
       "union_code":35,
       "union_name":"Deoti",
       "union_ban_name":"দেউতি"
    },
    {
       "_id":6607,
       "upazila_code":83,
       "union_code":59,
       "union_name":"Jayag",
       "union_ban_name":"জয়গ"
    },
    {
       "_id":6608,
       "upazila_code":83,
       "union_code":73,
       "union_name":"Nadana",
       "union_ban_name":"নাদনা"
    },
    {
       "_id":6609,
       "upazila_code":83,
       "union_code":77,
       "union_name":"Nateshwar",
       "union_ban_name":"নাটেশ্বর"
    },
    {
       "_id":6611,
       "upazila_code":85,
       "union_code":29,
       "union_name":"Char Amanullah",
       "union_ban_name":"চর আমানউল্লাহ"
    },
    {
       "_id":6612,
       "upazila_code":85,
       "union_code":31,
       "union_name":"Char Bata",
       "union_ban_name":"চর বাটা"
    },
    {
       "_id":6613,
       "upazila_code":85,
       "union_code":33,
       "union_name":"Char Clerk",
       "union_ban_name":"চর কেরানি"
    },
    {
       "_id":6614,
       "upazila_code":85,
       "union_code":35,
       "union_name":"Char Jabbar",
       "union_ban_name":"চর জব্বার"
    },
    {
       "_id":6615,
       "upazila_code":85,
       "union_code":36,
       "union_name":"Char Jubille",
       "union_ban_name":"চর জুবিলি"
    },
    {
       "_id":6616,
       "upazila_code":85,
       "union_code":37,
       "union_name":"Char Wapda",
       "union_ban_name":"চর ওয়াপদা"
    },
    {
       "_id":6618,
       "upazila_code":85,
       "union_code":47,
       "union_name":"Purba Char Bata",
       "union_ban_name":"পূর্ব চর বাটা"
    },
    {
       "_id":6619,
       "upazila_code":87,
       "union_code":21,
       "union_name":"Anderchar",
       "union_ban_name":"আন্ডারচর"
    },
    {
       "_id":6620,
       "upazila_code":87,
       "union_code":23,
       "union_name":"Ashwadia",
       "union_ban_name":"আশওয়াদিয়া"
    },
    {
       "_id":6622,
       "upazila_code":87,
       "union_code":40,
       "union_name":"Char Matua",
       "union_ban_name":"চর মতুয়া"
    },
    {
       "_id":6625,
       "upazila_code":87,
       "union_code":50,
       "union_name":"Ewazbalia",
       "union_ban_name":"ইওয়াজবলিয়া"
    },
    {
       "_id":6626,
       "upazila_code":87,
       "union_code":60,
       "union_name":"Kadir Hanif",
       "union_ban_name":"কাদির হানিফ"
    },
    {
       "_id":6627,
       "upazila_code":87,
       "union_code":65,
       "union_name":"Kaladaraf",
       "union_ban_name":"কালাদারাফ"
    },
    {
       "_id":6628,
       "upazila_code":87,
       "union_code":75,
       "union_name":"Niazpur",
       "union_ban_name":"নিয়াজপুর"
    },
    {
       "_id":6629,
       "upazila_code":87,
       "union_code":80,
       "union_name":"Noakhali",
       "union_ban_name":"নোয়াখালী"
    },
    {
       "_id":6630,
       "upazila_code":87,
       "union_code":85,
       "union_name":"Noannai",
       "union_ban_name":"নোন্নাই"
    },
    {
       "_id":6631,
       "upazila_code":87,
       "union_code":90,
       "union_name":"Purba Char Matua",
       "union_ban_name":"পূর্ব চর মতুয়া"
    },
    {
       "_id":6632,
       "upazila_code":5,
       "union_code":15,
       "union_name":"Chandba",
       "union_ban_name":"চাঁদবা"
    },
    {
       "_id":6633,
       "upazila_code":5,
       "union_code":31,
       "union_name":"Debottar",
       "union_ban_name":"দেবোত্তর"
    },
    {
       "_id":6634,
       "upazila_code":5,
       "union_code":47,
       "union_name":"Ekdanta",
       "union_ban_name":"Dকদন্ত"
    },
    {
       "_id":6636,
       "upazila_code":5,
       "union_code":79,
       "union_name":"Majh Para",
       "union_ban_name":"মাজ পাড়া"
    },
    {
       "_id":6637,
       "upazila_code":16,
       "union_code":15,
       "union_name":"Chakla",
       "union_ban_name":"চাকলা"
    },
    {
       "_id":6638,
       "upazila_code":16,
       "union_code":21,
       "union_name":"Dhalar Char",
       "union_ban_name":"ধলর চর"
    },
    {
       "_id":6639,
       "upazila_code":16,
       "union_code":31,
       "union_name":"Haturia Nakalia",
       "union_ban_name":"হাটুরিয়া নকলিয়া"
    },
    {
       "_id":6640,
       "upazila_code":16,
       "union_code":42,
       "union_name":"Jatsakhni",
       "union_ban_name":"জাতসখনি"
    },
    {
       "_id":6641,
       "upazila_code":16,
       "union_code":47,
       "union_name":"Kytola",
       "union_ban_name":"কিটোলা"
    },
    {
       "_id":6642,
       "upazila_code":16,
       "union_code":52,
       "union_name":"Masundia",
       "union_ban_name":"মাসুন্দিয়া"
    },
    {
       "_id":6643,
       "upazila_code":16,
       "union_code":63,
       "union_name":"Nutan Bharenga",
       "union_ban_name":"নূতন ভরঙ্গা"
    },
    {
       "_id":6644,
       "upazila_code":16,
       "union_code":73,
       "union_name":"Puran Bharenga",
       "union_ban_name":"পুরান ভারেঙ্গা"
    },
    {
       "_id":6645,
       "upazila_code":16,
       "union_code":84,
       "union_name":"Ruppur",
       "union_ban_name":"রুপপুর"
    },
    {
       "_id":6646,
       "upazila_code":19,
       "union_code":15,
       "union_name":"Ashta Manisha",
       "union_ban_name":"অষ্টা মনীষা"
    },
    {
       "_id":6647,
       "upazila_code":19,
       "union_code":31,
       "union_name":"Bhangura",
       "union_ban_name":"ভাঙ্গুরা"
    },
    {
       "_id":6648,
       "upazila_code":19,
       "union_code":47,
       "union_name":"Dil Pasar",
       "union_ban_name":"দিল পাশার"
    },
    {
       "_id":6649,
       "upazila_code":19,
       "union_code":63,
       "union_name":"Khan Marich",
       "union_ban_name":"খান মেরিচ"
    },
    {
       "_id":6650,
       "upazila_code":19,
       "union_code":79,
       "union_name":"Parbhanguria",
       "union_ban_name":"পারভঙ্গুরিয়া"
    },
    {
       "_id":6651,
       "upazila_code":22,
       "union_code":11,
       "union_name":"Chhaikhola",
       "union_ban_name":"ছাইখোলা"
    },
    {
       "_id":6652,
       "upazila_code":22,
       "union_code":13,
       "union_name":"Bilchalan",
       "union_ban_name":"বিলচালান"
    },
    {
       "_id":6653,
       "upazila_code":22,
       "union_code":25,
       "union_name":"Danthia Bamangram",
       "union_ban_name":"দানথিয়া বামনগ্রাম"
    },
    {
       "_id":6654,
       "upazila_code":22,
       "union_code":34,
       "union_name":"Failjana",
       "union_ban_name":"ফেইলজানা"
    },
    {
       "_id":6655,
       "upazila_code":22,
       "union_code":43,
       "union_name":"Gunaigachha",
       "union_ban_name":"গুনাইগাছা"
    },
    {
       "_id":6656,
       "upazila_code":22,
       "union_code":51,
       "union_name":"Handial",
       "union_ban_name":"হ্যান্ডিয়াল"
    },
    {
       "_id":6658,
       "upazila_code":22,
       "union_code":70,
       "union_name":"Mothurapur",
       "union_ban_name":"মথুরাপুর"
    },
    {
       "_id":6660,
       "upazila_code":22,
       "union_code":86,
       "union_name":"Nimaichara",
       "union_ban_name":"নিমাইচড়া"
    },
    {
       "_id":6661,
       "upazila_code":22,
       "union_code":94,
       "union_name":"Parshadanga",
       "union_ban_name":"পারশাদঙ্গ"
    },
    {
       "_id":6662,
       "upazila_code":33,
       "union_code":10,
       "union_name":"Banwarinagar",
       "union_ban_name":"বানওয়ারিনগর"
    },
    {
       "_id":6663,
       "upazila_code":33,
       "union_code":31,
       "union_name":"Bri-Lahiribari",
       "union_ban_name":"ব্রি-লাহিড়ীবাড়ি"
    },
    {
       "_id":6666,
       "upazila_code":33,
       "union_code":63,
       "union_name":"Hadal",
       "union_ban_name":"হাদাল"
    },
    {
       "_id":6667,
       "upazila_code":33,
       "union_code":84,
       "union_name":"Pungali",
       "union_ban_name":"পুঙ্গালি"
    },
    {
       "_id":6668,
       "upazila_code":39,
       "union_code":21,
       "union_name":"Dashuria",
       "union_ban_name":"দাশুরিয়া"
    },
    {
       "_id":6669,
       "upazila_code":39,
       "union_code":31,
       "union_name":"Lakshmikundi",
       "union_ban_name":"লক্ষ্মীকুণ্ডি"
    },
    {
       "_id":6670,
       "upazila_code":39,
       "union_code":42,
       "union_name":"Muladuli",
       "union_ban_name":"মুলাদুলি"
    },
    {
       "_id":6671,
       "upazila_code":39,
       "union_code":52,
       "union_name":"Pakshi",
       "union_ban_name":"পাকশি"
    },
    {
       "_id":6673,
       "upazila_code":39,
       "union_code":73,
       "union_name":"Silimpur",
       "union_ban_name":"শিলিমপুর"
    },
    {
       "_id":6674,
       "upazila_code":39,
       "union_code":84,
       "union_name":"Sara",
       "union_ban_name":"সারা"
    },
    {
       "_id":6675,
       "upazila_code":55,
       "union_code":16,
       "union_name":"Ataikola",
       "union_ban_name":"আতাইকোলা"
    },
    {
       "_id":6676,
       "upazila_code":55,
       "union_code":17,
       "union_name":"Bharara",
       "union_ban_name":"ভররা"
    },
    {
       "_id":6677,
       "upazila_code":55,
       "union_code":25,
       "union_name":"Char Tarapur",
       "union_ban_name":"চর তারাপুর"
    },
    {
       "_id":6681,
       "upazila_code":55,
       "union_code":60,
       "union_name":"Hemayetpur",
       "union_ban_name":"হেমায়েতপুর"
    },
    {
       "_id":6682,
       "upazila_code":55,
       "union_code":69,
       "union_name":"Malanchi",
       "union_ban_name":"মালঞ্চি"
    },
    {
       "_id":6683,
       "upazila_code":55,
       "union_code":77,
       "union_name":"Maligachha",
       "union_ban_name":"মালিগাছা"
    },
    {
       "_id":6684,
       "upazila_code":55,
       "union_code":94,
       "union_name":"Sadullahpur",
       "union_ban_name":"সাদুল্লাহপুর"
    },
    {
       "_id":6686,
       "upazila_code":72,
       "union_code":17,
       "union_name":"Bhulbaria",
       "union_ban_name":"ভুলবাড়িয়া"
    },
    {
       "_id":6687,
       "upazila_code":72,
       "union_code":25,
       "union_name":"Dhopadaha",
       "union_ban_name":"ধোপদহ"
    },
    {
       "_id":6688,
       "upazila_code":72,
       "union_code":34,
       "union_name":"Dhulauri",
       "union_ban_name":"ধুলাউড়ি"
    },
    {
       "_id":6689,
       "upazila_code":72,
       "union_code":43,
       "union_name":"Gaurigram",
       "union_ban_name":"গৌরিগ্রাম"
    },
    {
       "_id":6690,
       "upazila_code":72,
       "union_code":51,
       "union_name":"Karanja",
       "union_ban_name":"করঞ্জা"
    },
    {
       "_id":6691,
       "upazila_code":72,
       "union_code":60,
       "union_name":"Kashinathpur",
       "union_ban_name":"কাশিনাথপুর"
    },
    {
       "_id":6692,
       "upazila_code":72,
       "union_code":69,
       "union_name":"Khatu Para",
       "union_ban_name":"খাতু পাড়া"
    },
    {
       "_id":6693,
       "upazila_code":72,
       "union_code":77,
       "union_name":"Nagdemra",
       "union_ban_name":"নাগডেমেরা"
    },
    {
       "_id":6694,
       "upazila_code":72,
       "union_code":86,
       "union_name":"Nandanpur",
       "union_ban_name":"নন্দনপুর"
    },
    {
       "_id":6695,
       "upazila_code":83,
       "union_code":17,
       "union_name":"Ahammedpur",
       "union_ban_name":"আহমদপুর"
    },
    {
       "_id":6696,
       "upazila_code":83,
       "union_code":19,
       "union_name":"Dulai",
       "union_ban_name":"দুলাই"
    },
    {
       "_id":6697,
       "upazila_code":83,
       "union_code":28,
       "union_name":"Hatkhali",
       "union_ban_name":"হাটখালী"
    },
    {
       "_id":6698,
       "upazila_code":83,
       "union_code":38,
       "union_name":"Manikhat",
       "union_ban_name":"মানিখাত"
    },
    {
       "_id":6699,
       "upazila_code":83,
       "union_code":47,
       "union_name":"Nazirganj",
       "union_ban_name":"নাজিরগঞ্জ"
    },
    {
       "_id":6701,
       "upazila_code":83,
       "union_code":66,
       "union_name":"Sagarkandi",
       "union_ban_name":"সাগরকান্দি"
    },
    {
       "_id":6703,
       "upazila_code":83,
       "union_code":85,
       "union_name":"Bhayna (Sujanagar)",
       "union_ban_name":"ভায়না (সুজানগর)"
    },
    {
       "_id":6704,
       "upazila_code":83,
       "union_code":95,
       "union_name":"Tantibanda",
       "union_ban_name":"তান্তিবান্দা"
    },
    {
       "_id":6705,
       "upazila_code":4,
       "union_code":13,
       "union_name":"Alowa Khowa",
       "union_ban_name":"আওলা খোওয়া"
    },
    {
       "_id":6707,
       "upazila_code":4,
       "union_code":40,
       "union_name":"Dhamor",
       "union_ban_name":"ধামোর"
    },
    {
       "_id":6710,
       "upazila_code":4,
       "union_code":81,
       "union_name":"Taria",
       "union_ban_name":"তারিয়া"
    },
    {
       "_id":6711,
       "upazila_code":25,
       "union_code":12,
       "union_name":"Bara Shashi",
       "union_ban_name":"বড় শশী"
    },
    {
       "_id":6712,
       "upazila_code":25,
       "union_code":14,
       "union_name":"Benghari Banagram",
       "union_ban_name":"বেনঘরি বনগ্রাম"
    },
    {
       "_id":6713,
       "upazila_code":25,
       "union_code":21,
       "union_name":"Boda",
       "union_ban_name":"বোদা"
    },
    {
       "_id":6715,
       "upazila_code":25,
       "union_code":51,
       "union_name":"Jhalaishalsiri",
       "union_ban_name":"Halaালাইশালসিরি"
    },
    {
       "_id":6716,
       "upazila_code":25,
       "union_code":58,
       "union_name":"Kajal Dighi Kaliganj",
       "union_ban_name":"কাজল দিঘি কালীগঞ্জ"
    },
    {
       "_id":6717,
       "upazila_code":25,
       "union_code":73,
       "union_name":"Maidan Dighi",
       "union_ban_name":"ময়দান দিঘি"
    },
    {
       "_id":6718,
       "upazila_code":25,
       "union_code":80,
       "union_name":"Marea Bamanhat",
       "union_ban_name":"মারে বামনহাট"
    },
    {
       "_id":6719,
       "upazila_code":25,
       "union_code":87,
       "union_name":"Panchpir",
       "union_ban_name":"পাঁচপীর"
    },
    {
       "_id":6720,
       "upazila_code":25,
       "union_code":94,
       "union_name":"Sakoa",
       "union_ban_name":"সাকোয়া"
    },
    {
       "_id":6721,
       "upazila_code":34,
       "union_code":19,
       "union_name":"Chilahati",
       "union_ban_name":"চিলাহাটি"
    },
    {
       "_id":6722,
       "upazila_code":34,
       "union_code":28,
       "union_name":"Dandapal",
       "union_ban_name":"দন্ডপাল"
    },
    {
       "_id":6723,
       "upazila_code":34,
       "union_code":38,
       "union_name":"Debidoba",
       "union_ban_name":"দেবিদোবা"
    },
    {
       "_id":6724,
       "upazila_code":34,
       "union_code":47,
       "union_name":"Debiganj",
       "union_ban_name":"দেবিগঞ্জ"
    },
    {
       "_id":6725,
       "upazila_code":34,
       "union_code":53,
       "union_name":"Hazradanga",
       "union_ban_name":"হাজরাডাঙ্গা"
    },
    {
       "_id":6726,
       "upazila_code":34,
       "union_code":57,
       "union_name":"Pamuli",
       "union_ban_name":"পামুলি"
    },
    {
       "_id":6727,
       "upazila_code":34,
       "union_code":66,
       "union_name":"Saldanga",
       "union_ban_name":"সালডাঙ্গা"
    },
    {
       "_id":6728,
       "upazila_code":34,
       "union_code":76,
       "union_name":"Mallikadaha",
       "union_ban_name":"মল্লিকদহ"
    },
    {
       "_id":6729,
       "upazila_code":34,
       "union_code":85,
       "union_name":"Sundar Dighi",
       "union_ban_name":"সুন্দর দিঘি"
    },
    {
       "_id":6730,
       "upazila_code":34,
       "union_code":95,
       "union_name":"Tepriganj",
       "union_ban_name":"টেপরিগঞ্জ"
    },
    {
       "_id":6731,
       "upazila_code":73,
       "union_code":11,
       "union_name":"Amarkhana",
       "union_ban_name":"অমরখানা"
    },
    {
       "_id":6732,
       "upazila_code":73,
       "union_code":23,
       "union_name":"Chaklarhat",
       "union_ban_name":"চক্লারহাট"
    },
    {
       "_id":6733,
       "upazila_code":73,
       "union_code":35,
       "union_name":"Hafizabad",
       "union_ban_name":"হাফিজাবাদ"
    },
    {
       "_id":6734,
       "upazila_code":73,
       "union_code":36,
       "union_name":"Dhakkamara",
       "union_ban_name":"Kাককমর"
    },
    {
       "_id":6735,
       "upazila_code":73,
       "union_code":43,
       "union_name":"Garinabari",
       "union_ban_name":"গড়িনাবাড়ি"
    },
    {
       "_id":6736,
       "upazila_code":73,
       "union_code":47,
       "union_name":"Haribhasa",
       "union_ban_name":"হরিভাশা"
    },
    {
       "_id":6737,
       "upazila_code":73,
       "union_code":59,
       "union_name":"Kamat Kajal Dighi",
       "union_ban_name":"কামত কাজল দিঘি"
    },
    {
       "_id":6739,
       "upazila_code":73,
       "union_code":71,
       "union_name":"Panchagarh",
       "union_ban_name":"পঞ্চগড়"
    },
    {
       "_id":6740,
       "upazila_code":73,
       "union_code":83,
       "union_name":"Satmara",
       "union_ban_name":"সাতমারা"
    },
    {
       "_id":6741,
       "upazila_code":90,
       "union_code":13,
       "union_name":"Banglabandha",
       "union_ban_name":"বাংলাবান্ধা"
    },
    {
       "_id":6742,
       "upazila_code":90,
       "union_code":27,
       "union_name":"Bhojanpur",
       "union_ban_name":"ভোজনপুর"
    },
    {
       "_id":6743,
       "upazila_code":90,
       "union_code":40,
       "union_name":"Debnagar",
       "union_ban_name":"দেবনগর"
    },
    {
       "_id":6745,
       "upazila_code":90,
       "union_code":67,
       "union_name":"Salbahan",
       "union_ban_name":"সালবাহান"
    },
    {
       "_id":6747,
       "upazila_code":90,
       "union_code":94,
       "union_name":"Tirnaihat",
       "union_ban_name":"তিরনাইহাট"
    },
    {
       "_id":6749,
       "upazila_code":38,
       "union_code":11,
       "union_name":"Baga",
       "union_ban_name":"বগা"
    },
    {
       "_id":6750,
       "upazila_code":38,
       "union_code":29,
       "union_name":"Bauphal",
       "union_ban_name":"বাউফল"
    },
    {
       "_id":6751,
       "upazila_code":38,
       "union_code":35,
       "union_name":"Daspara",
       "union_ban_name":"দাসপাড়া"
    },
    {
       "_id":6752,
       "upazila_code":38,
       "union_code":41,
       "union_name":"Dhulia",
       "union_ban_name":"ধুলিয়া"
    },
    {
       "_id":6754,
       "upazila_code":38,
       "union_code":53,
       "union_name":"Kalaiya",
       "union_ban_name":"কালাইয়া"
    },
    {
       "_id":6755,
       "upazila_code":38,
       "union_code":59,
       "union_name":"Kalisuri",
       "union_ban_name":"কালীসুরি"
    },
    {
       "_id":6756,
       "upazila_code":38,
       "union_code":65,
       "union_name":"Kanakdia",
       "union_ban_name":"কনকদিয়া"
    },
    {
       "_id":6758,
       "upazila_code":38,
       "union_code":77,
       "union_name":"Madanpura",
       "union_ban_name":"মদনপুর"
    },
    {
       "_id":6759,
       "upazila_code":38,
       "union_code":83,
       "union_name":"Noamala",
       "union_ban_name":"নোমালা"
    },
    {
       "_id":6761,
       "upazila_code":38,
       "union_code":95,
       "union_name":"Surjyamani",
       "union_ban_name":"সুরজ্যমণি"
    },
    {
       "_id":6762,
       "upazila_code":52,
       "union_code":10,
       "union_name":"Alipur",
       "union_ban_name":"আলিপুর"
    },
    {
       "_id":6763,
       "upazila_code":52,
       "union_code":21,
       "union_name":"Bahrampur",
       "union_ban_name":"বহরমপুর"
    },
    {
       "_id":6765,
       "upazila_code":52,
       "union_code":42,
       "union_name":"Betagi Sankipura",
       "union_ban_name":"বেতাগি সানকিপুর"
    },
    {
       "_id":6766,
       "upazila_code":52,
       "union_code":52,
       "union_name":"Dashmina",
       "union_ban_name":"দশমিনা"
    },
    {
       "_id":6767,
       "upazila_code":52,
       "union_code":84,
       "union_name":"Rangopaldi",
       "union_ban_name":"রাঙ্গোপলদী"
    },
    {
       "_id":6768,
       "upazila_code":55,
       "union_code":19,
       "union_name":"Angaria",
       "union_ban_name":"আঙ্গারিয়া"
    },
    {
       "_id":6769,
       "upazila_code":55,
       "union_code":47,
       "union_name":"Lebukhali",
       "union_ban_name":"লেবুখালী"
    },
    {
       "_id":6770,
       "upazila_code":55,
       "union_code":81,
       "union_name":"Muradia",
       "union_ban_name":"মুরাদিয়া"
    },
    {
       "_id":6771,
       "upazila_code":55,
       "union_code":88,
       "union_name":"Pangashia",
       "union_ban_name":"পাঙ্গাশিয়া"
    },
    {
       "_id":6773,
       "upazila_code":57,
       "union_code":11,
       "union_name":"Amkhola",
       "union_ban_name":"আমখোলা"
    },
    {
       "_id":6774,
       "upazila_code":57,
       "union_code":22,
       "union_name":"Bakulbaria",
       "union_ban_name":"বকুলবাড়িয়া"
    },
    {
       "_id":6775,
       "upazila_code":57,
       "union_code":27,
       "union_name":"Bara Baisdia",
       "union_ban_name":"বড় বৈসদিয়া"
    },
    {
       "_id":6776,
       "upazila_code":57,
       "union_code":32,
       "union_name":"Chalitabunia",
       "union_ban_name":"চলতাবুনিয়া"
    },
    {
       "_id":6777,
       "upazila_code":57,
       "union_code":34,
       "union_name":"Char Montaz",
       "union_ban_name":"চর মন্টাজ"
    },
    {
       "_id":6778,
       "upazila_code":57,
       "union_code":37,
       "union_name":"Char Biswas",
       "union_ban_name":"চর বিশ্বাস"
    },
    {
       "_id":6779,
       "upazila_code":57,
       "union_code":39,
       "union_name":"Char Kajal",
       "union_ban_name":"চর কাজল"
    },
    {
       "_id":6780,
       "upazila_code":57,
       "union_code":44,
       "union_name":"Chhota Baisdia",
       "union_ban_name":"ছোট বাইসদিয়া"
    },
    {
       "_id":6781,
       "upazila_code":57,
       "union_code":50,
       "union_name":"Chiknikandi",
       "union_ban_name":"চিকনিকান্দি"
    },
    {
       "_id":6782,
       "upazila_code":57,
       "union_code":55,
       "union_name":"Dakua",
       "union_ban_name":"ডাকুয়া"
    },
    {
       "_id":6783,
       "upazila_code":57,
       "union_code":67,
       "union_name":"Galachipa",
       "union_ban_name":"গলাচিপা"
    },
    {
       "_id":6785,
       "upazila_code":57,
       "union_code":72,
       "union_name":"Golkhali",
       "union_ban_name":"গোলখালী"
    },
    {
       "_id":6787,
       "upazila_code":57,
       "union_code":78,
       "union_name":"Panpatty",
       "union_ban_name":"পানপট্টি"
    },
    {
       "_id":6788,
       "upazila_code":57,
       "union_code":83,
       "union_name":"Rangabali",
       "union_ban_name":"রাঙ্গাবালী"
    },
    {
       "_id":6789,
       "upazila_code":57,
       "union_code":94,
       "union_name":"Ratandi Taltali",
       "union_ban_name":"রতান্দি তালতলী"
    },
    {
       "_id":6790,
       "upazila_code":66,
       "union_code":10,
       "union_name":"Baliatali",
       "union_ban_name":"বালিয়াতালি"
    },
    {
       "_id":6791,
       "upazila_code":66,
       "union_code":11,
       "union_name":"Chakamaiya",
       "union_ban_name":"চাকমাইয়া"
    },
    {
       "_id":6792,
       "upazila_code":66,
       "union_code":15,
       "union_name":"Dalbuganj",
       "union_ban_name":"ডালবুগঞ্জ"
    },
    {
       "_id":6793,
       "upazila_code":66,
       "union_code":23,
       "union_name":"Mohipur",
       "union_ban_name":"মহিপুর"
    },
    {
       "_id":6794,
       "upazila_code":66,
       "union_code":29,
       "union_name":"Dhulasar",
       "union_ban_name":"ধুলসার"
    },
    {
       "_id":6795,
       "upazila_code":66,
       "union_code":35,
       "union_name":"Lalua",
       "union_ban_name":"লালুয়া"
    },
    {
       "_id":6796,
       "upazila_code":66,
       "union_code":47,
       "union_name":"Lata Chapli",
       "union_ban_name":"লতা চাপলি"
    },
    {
       "_id":6797,
       "upazila_code":66,
       "union_code":55,
       "union_name":"Dhankhali",
       "union_ban_name":"ধানখালী"
    },
    {
       "_id":6798,
       "upazila_code":66,
       "union_code":59,
       "union_name":"Mithaganj",
       "union_ban_name":"মিঠাগঞ্জ"
    },
    {
       "_id":6799,
       "upazila_code":66,
       "union_code":71,
       "union_name":"Nilganj",
       "union_ban_name":"নীলগঞ্জ"
    },
    {
       "_id":6800,
       "upazila_code":66,
       "union_code":83,
       "union_name":"Tiakhali",
       "union_ban_name":"টিয়াখালী"
    },
    {
       "_id":6801,
       "upazila_code":76,
       "union_code":13,
       "union_name":"Amragachhia",
       "union_ban_name":"আমড়াগাছিয়া"
    },
    {
       "_id":6802,
       "upazila_code":76,
       "union_code":27,
       "union_name":"Deuli Subidkhali",
       "union_ban_name":"দেউলি সুবিদখালী"
    },
    {
       "_id":6803,
       "upazila_code":76,
       "union_code":40,
       "union_name":"Kakrabunia",
       "union_ban_name":"কাকরবুনিয়া"
    },
    {
       "_id":6804,
       "upazila_code":76,
       "union_code":54,
       "union_name":"Madhabkhali",
       "union_ban_name":"মাধবখালী"
    },
    {
       "_id":6805,
       "upazila_code":76,
       "union_code":67,
       "union_name":"Majidbari",
       "union_ban_name":"মজিদবাড়ি"
    },
    {
       "_id":6806,
       "upazila_code":76,
       "union_code":81,
       "union_name":"Mirzaganj",
       "union_ban_name":"মির্জাগঞ্জ"
    },
    {
       "_id":6809,
       "upazila_code":95,
       "union_code":13,
       "union_name":"Chhota Bighai",
       "union_ban_name":"ছোট বিঘাই"
    },
    {
       "_id":6810,
       "upazila_code":95,
       "union_code":20,
       "union_name":"Itabaria",
       "union_ban_name":"ইটাবারিয়া"
    },
    {
       "_id":6811,
       "upazila_code":95,
       "union_code":27,
       "union_name":"Jainkati",
       "union_ban_name":"জৈনকাটি"
    },
    {
       "_id":6812,
       "upazila_code":95,
       "union_code":31,
       "union_name":"Bara Bighai",
       "union_ban_name":"বড় বিঘাই"
    },
    {
       "_id":6814,
       "upazila_code":95,
       "union_code":40,
       "union_name":"Kamalapur",
       "union_ban_name":"কমলাপুর"
    },
    {
       "_id":6815,
       "upazila_code":95,
       "union_code":54,
       "union_name":"Lohalia",
       "union_ban_name":"লোহালিয়া"
    },
    {
       "_id":6816,
       "upazila_code":95,
       "union_code":61,
       "union_name":"Laukati",
       "union_ban_name":"লাউকাতি"
    },
    {
       "_id":6817,
       "upazila_code":95,
       "union_code":67,
       "union_name":"Madarbunia",
       "union_ban_name":"মাদারবুনিয়া"
    },
    {
       "_id":6818,
       "upazila_code":95,
       "union_code":74,
       "union_name":"Marichbunia",
       "union_ban_name":"মেরিচবুনিয়া"
    },
    {
       "_id":6819,
       "upazila_code":14,
       "union_code":11,
       "union_name":"Bhandaria",
       "union_ban_name":"ভান্ডারিয়া"
    },
    {
       "_id":6820,
       "upazila_code":14,
       "union_code":23,
       "union_name":"Bhitabaria",
       "union_ban_name":"ভিটাবারিয়া"
    },
    {
       "_id":6821,
       "upazila_code":14,
       "union_code":35,
       "union_name":"Dhaoa",
       "union_ban_name":"ধোয়া"
    },
    {
       "_id":6823,
       "upazila_code":14,
       "union_code":59,
       "union_name":"Ikri",
       "union_ban_name":"ইকরি"
    },
    {
       "_id":6824,
       "upazila_code":14,
       "union_code":71,
       "union_name":"Nudmulla",
       "union_ban_name":"নুদমুল্লা"
    },
    {
       "_id":6825,
       "upazila_code":14,
       "union_code":83,
       "union_name":"Telikhali",
       "union_ban_name":"তেলিখালী"
    },
    {
       "_id":6826,
       "upazila_code":47,
       "union_code":15,
       "union_name":"Amrajuri",
       "union_ban_name":"আমরাজুরী"
    },
    {
       "_id":6827,
       "upazila_code":47,
       "union_code":31,
       "union_name":"Chira Para Parsaturia",
       "union_ban_name":"চিরা পাড়া পার্সাতুরিয়া"
    },
    {
       "_id":6828,
       "upazila_code":47,
       "union_code":47,
       "union_name":"Kawkhali",
       "union_ban_name":"কাওখালী"
    },
    {
       "_id":6829,
       "upazila_code":47,
       "union_code":63,
       "union_name":"Sayna Raghunathpur",
       "union_ban_name":"সায়না রঘুনাথপুর"
    },
    {
       "_id":6830,
       "upazila_code":47,
       "union_code":79,
       "union_name":"Shialkati",
       "union_ban_name":"শিয়ালকাটি"
    },
    {
       "_id":6832,
       "upazila_code":58,
       "union_code":17,
       "union_name":"Bara Machhua",
       "union_ban_name":"বড় মাছুয়া"
    },
    {
       "_id":6833,
       "upazila_code":58,
       "union_code":25,
       "union_name":"Betmore Rajpara",
       "union_ban_name":"বেতমোর রাজপাড়া"
    },
    {
       "_id":6834,
       "upazila_code":58,
       "union_code":34,
       "union_name":"Daudkhali",
       "union_ban_name":"দাউদখালী"
    },
    {
       "_id":6835,
       "upazila_code":58,
       "union_code":43,
       "union_name":"Dhanisafa",
       "union_ban_name":"ধনীসাফা"
    },
    {
       "_id":6836,
       "upazila_code":58,
       "union_code":51,
       "union_name":"Gulishakhali",
       "union_ban_name":"গুলিশাখালী"
    },
    {
       "_id":6837,
       "upazila_code":58,
       "union_code":60,
       "union_name":"Mathbaria",
       "union_ban_name":"মঠবারিয়া"
    },
    {
       "_id":6838,
       "upazila_code":58,
       "union_code":69,
       "union_name":"Mirukhali",
       "union_ban_name":"মিরখালী"
    },
    {
       "_id":6839,
       "upazila_code":58,
       "union_code":77,
       "union_name":"Sapleza",
       "union_ban_name":"স্যাপলেজা"
    },
    {
       "_id":6840,
       "upazila_code":58,
       "union_code":86,
       "union_name":"Tikikata",
       "union_ban_name":"টিকিকাটা"
    },
    {
       "_id":6841,
       "upazila_code":58,
       "union_code":94,
       "union_name":"Tushkhali",
       "union_ban_name":"তুষখালী"
    },
    {
       "_id":6842,
       "upazila_code":76,
       "union_code":10,
       "union_name":"Dirgha",
       "union_ban_name":"দিরঘা"
    },
    {
       "_id":6843,
       "upazila_code":76,
       "union_code":21,
       "union_name":"Purba Deulbaridobra",
       "union_ban_name":"পূর্ব দেউলবাড়িদোব্রা"
    },
    {
       "_id":6844,
       "upazila_code":76,
       "union_code":31,
       "union_name":"Malikhali",
       "union_ban_name":"মালখালি"
    },
    {
       "_id":6845,
       "upazila_code":76,
       "union_code":42,
       "union_name":"Matibhanga",
       "union_ban_name":"মাটিভাঙ্গা"
    },
    {
       "_id":6847,
       "upazila_code":76,
       "union_code":63,
       "union_name":"Sekhmatia",
       "union_ban_name":"সেখমতিয়া"
    },
    {
       "_id":6848,
       "upazila_code":76,
       "union_code":73,
       "union_name":"Shankharikati",
       "union_ban_name":"শঙ্খরিকাটি"
    },
    {
       "_id":6849,
       "upazila_code":76,
       "union_code":84,
       "union_name":"Sreeramkati",
       "union_ban_name":"শ্রীরামকাটি"
    },
    {
       "_id":6851,
       "upazila_code":80,
       "union_code":25,
       "union_name":"Kadamtala",
       "union_ban_name":"কদমতলা"
    },
    {
       "_id":6852,
       "upazila_code":80,
       "union_code":34,
       "union_name":"Kalakhali",
       "union_ban_name":"কালাখালী"
    },
    {
       "_id":6853,
       "upazila_code":80,
       "union_code":69,
       "union_name":"Shankarpasha",
       "union_ban_name":"শঙ্করপাশা"
    },
    {
       "_id":6854,
       "upazila_code":80,
       "union_code":77,
       "union_name":"Sariktala Dumritala",
       "union_ban_name":"সারিকতলা ডুমরিতলা"
    },
    {
       "_id":6855,
       "upazila_code":80,
       "union_code":86,
       "union_name":"Sikdar Mallik",
       "union_ban_name":"সিকদার মল্লিক"
    },
    {
       "_id":6856,
       "upazila_code":80,
       "union_code":94,
       "union_name":"Tona",
       "union_ban_name":"টোনা"
    },
    {
       "_id":6857,
       "upazila_code":87,
       "union_code":17,
       "union_name":"Atghar Kuriana",
       "union_ban_name":"আটঘর কুড়িয়ানা"
    },
    {
       "_id":6858,
       "upazila_code":87,
       "union_code":19,
       "union_name":"Baldia",
       "union_ban_name":"বালদিয়া"
    },
    {
       "_id":6859,
       "upazila_code":87,
       "union_code":28,
       "union_name":"Daihari",
       "union_ban_name":"দাইহারি"
    },
    {
       "_id":6860,
       "upazila_code":87,
       "union_code":38,
       "union_name":"Guarekha",
       "union_ban_name":"গুয়ারেখা"
    },
    {
       "_id":6861,
       "upazila_code":87,
       "union_code":47,
       "union_name":"Jalabari",
       "union_ban_name":"জালবাড়ি"
    },
    {
       "_id":6862,
       "upazila_code":87,
       "union_code":57,
       "union_name":"Samudaykati",
       "union_ban_name":"সমুদয়কাটি"
    },
    {
       "_id":6863,
       "upazila_code":87,
       "union_code":66,
       "union_name":"Sarengkati",
       "union_ban_name":"সারেংকাটি"
    },
    {
       "_id":6864,
       "upazila_code":87,
       "union_code":76,
       "union_name":"Sohagdal",
       "union_ban_name":"সোহাগডাল"
    },
    {
       "_id":6865,
       "upazila_code":87,
       "union_code":85,
       "union_name":"Sutiakati",
       "union_ban_name":"সুতিয়কটি"
    },
    {
       "_id":6866,
       "upazila_code":87,
       "union_code":95,
       "union_name":"Nesarabad (Swarupkati)",
       "union_ban_name":"নেছারাবাদ (স্বরূপকাঠি)"
    },
    {
       "_id":6868,
       "upazila_code":90,
       "union_code":43,
       "union_name":"Parerhat",
       "union_ban_name":"পারেরহাট"
    },
    {
       "_id":6869,
       "upazila_code":90,
       "union_code":51,
       "union_name":"Pattashi",
       "union_ban_name":"পট্টশী"
    },
    {
       "_id":6870,
       "upazila_code":7,
       "union_code":17,
       "union_name":"Baharpur",
       "union_ban_name":"বাহারপুর"
    },
    {
       "_id":6871,
       "upazila_code":7,
       "union_code":19,
       "union_name":"Baliakandi",
       "union_ban_name":"বালিয়াকান্দি"
    },
    {
       "_id":6874,
       "upazila_code":7,
       "union_code":66,
       "union_name":"Jangal",
       "union_ban_name":"জঙ্গল"
    },
    {
       "_id":6875,
       "upazila_code":7,
       "union_code":85,
       "union_name":"Narua",
       "union_ban_name":"নারুয়া"
    },
    {
       "_id":6877,
       "upazila_code":29,
       "union_code":19,
       "union_name":"Chhota Bhakla",
       "union_ban_name":"ছোট ভকলা"
    },
    {
       "_id":6878,
       "upazila_code":29,
       "union_code":38,
       "union_name":"Daulatdia",
       "union_ban_name":"দৌলতদিয়া"
    },
    {
       "_id":6879,
       "upazila_code":29,
       "union_code":57,
       "union_name":"Debagram",
       "union_ban_name":"দেবগ্রাম"
    },
    {
       "_id":6883,
       "upazila_code":47,
       "union_code":55,
       "union_name":"Majhbari",
       "union_ban_name":"মাজবাড়ি"
    },
    {
       "_id":6884,
       "upazila_code":47,
       "union_code":60,
       "union_name":"Madapur",
       "union_ban_name":"মাদাপুর"
    },
    {
       "_id":6885,
       "upazila_code":47,
       "union_code":70,
       "union_name":"Mrigi",
       "union_ban_name":"মৃগি"
    },
    {
       "_id":6886,
       "upazila_code":47,
       "union_code":85,
       "union_name":"Ratandia",
       "union_ban_name":"রতানদিয়া"
    },
    {
       "_id":6887,
       "upazila_code":47,
       "union_code":95,
       "union_name":"Saorail",
       "union_ban_name":"সাওরাইল"
    },
    {
       "_id":6889,
       "upazila_code":73,
       "union_code":20,
       "union_name":"Habaspur",
       "union_ban_name":"হাবসপুর"
    },
    {
       "_id":6890,
       "upazila_code":73,
       "union_code":25,
       "union_name":"Jashai",
       "union_ban_name":"জশাই"
    },
    {
       "_id":6891,
       "upazila_code":73,
       "union_code":35,
       "union_name":"Kalimahar",
       "union_ban_name":"কালীমহর"
    },
    {
       "_id":6892,
       "upazila_code":73,
       "union_code":40,
       "union_name":"Kasba Majhail",
       "union_ban_name":"কসবা মাঝাইল"
    },
    {
       "_id":6893,
       "upazila_code":73,
       "union_code":50,
       "union_name":"Machh Para",
       "union_ban_name":"মাছ পাড়া"
    },
    {
       "_id":6894,
       "upazila_code":73,
       "union_code":65,
       "union_name":"Maurat",
       "union_ban_name":"মওরাত"
    },
    {
       "_id":6895,
       "upazila_code":73,
       "union_code":75,
       "union_name":"Babupara",
       "union_ban_name":"বাবুপাড়া"
    },
    {
       "_id":6896,
       "upazila_code":73,
       "union_code":80,
       "union_name":"Patta",
       "union_ban_name":"পট্টা"
    },
    {
       "_id":6899,
       "upazila_code":76,
       "union_code":14,
       "union_name":"Banibaha",
       "union_ban_name":"বনিবাহা"
    },
    {
       "_id":6900,
       "upazila_code":76,
       "union_code":21,
       "union_name":"Barat",
       "union_ban_name":"বারাট"
    },
    {
       "_id":6901,
       "upazila_code":76,
       "union_code":29,
       "union_name":"Basantapur",
       "union_ban_name":"বসন্তপুর"
    },
    {
       "_id":6902,
       "upazila_code":76,
       "union_code":32,
       "union_name":"Chandani",
       "union_ban_name":"চন্দনি"
    },
    {
       "_id":6903,
       "upazila_code":76,
       "union_code":36,
       "union_name":"Dadshi",
       "union_ban_name":"দাদশি"
    },
    {
       "_id":6904,
       "upazila_code":76,
       "union_code":43,
       "union_name":"Khankhanapur",
       "union_ban_name":"খানখানাপুর"
    },
    {
       "_id":6905,
       "upazila_code":76,
       "union_code":45,
       "union_name":"Khanganj",
       "union_ban_name":"খানগঞ্জ"
    },
    {
       "_id":6906,
       "upazila_code":76,
       "union_code":51,
       "union_name":"Mizanpur",
       "union_ban_name":"মিজানপুর"
    },
    {
       "_id":6910,
       "upazila_code":76,
       "union_code":87,
       "union_name":"Shahid Wahabpur",
       "union_ban_name":"শহীদ ওয়াহাবপুর"
    },
    {
       "_id":6912,
       "upazila_code":10,
       "union_code":21,
       "union_name":"Arani",
       "union_ban_name":"অরণি"
    },
    {
       "_id":6913,
       "upazila_code":10,
       "union_code":22,
       "union_name":"Bajubagha",
       "union_ban_name":"বাজুবাঘা"
    },
    {
       "_id":6914,
       "upazila_code":10,
       "union_code":23,
       "union_name":"Bausa",
       "union_ban_name":"বাউসা"
    },
    {
       "_id":6915,
       "upazila_code":10,
       "union_code":55,
       "union_name":"Gargari",
       "union_ban_name":"গারগারি"
    },
    {
       "_id":6916,
       "upazila_code":10,
       "union_code":63,
       "union_name":"Manigram",
       "union_ban_name":"মণিগ্রাম"
    },
    {
       "_id":6917,
       "upazila_code":10,
       "union_code":79,
       "union_name":"Pakuria",
       "union_ban_name":"পাকুরিয়া"
    },
    {
       "_id":6918,
       "upazila_code":12,
       "union_code":20,
       "union_name":"Auch Para",
       "union_ban_name":"আছ পাড়া"
    },
    {
       "_id":6919,
       "upazila_code":12,
       "union_code":22,
       "union_name":"Bara Bihanali",
       "union_ban_name":"বড় বিহানালী"
    },
    {
       "_id":6920,
       "upazila_code":12,
       "union_code":24,
       "union_name":"Basu Para",
       "union_ban_name":"বসু পাড়া"
    },
    {
       "_id":6921,
       "upazila_code":12,
       "union_code":31,
       "union_name":"Dwippur",
       "union_ban_name":"দ্বিপুর"
    },
    {
       "_id":6922,
       "upazila_code":12,
       "union_code":37,
       "union_name":"Goalkandi",
       "union_ban_name":"গোলকান্দি"
    },
    {
       "_id":6923,
       "upazila_code":12,
       "union_code":44,
       "union_name":"Gobinda Para",
       "union_ban_name":"গোবিন্দ পাড়া"
    },
    {
       "_id":6924,
       "upazila_code":12,
       "union_code":50,
       "union_name":"Ganipur",
       "union_ban_name":"গণিপুর"
    },
    {
       "_id":6925,
       "upazila_code":12,
       "union_code":56,
       "union_name":"Hamir Kutsha",
       "union_ban_name":"হামির কুটশা"
    },
    {
       "_id":6926,
       "upazila_code":12,
       "union_code":63,
       "union_name":"Jhikra",
       "union_ban_name":"ঝিকরা"
    },
    {
       "_id":6927,
       "upazila_code":12,
       "union_code":69,
       "union_name":"Jogi Para",
       "union_ban_name":"জোগি পাড়া"
    },
    {
       "_id":6928,
       "upazila_code":12,
       "union_code":72,
       "union_name":"Kachhari Kayali Para",
       "union_ban_name":"কছড়ি কয়ালি পাড়া"
    },
    {
       "_id":6930,
       "upazila_code":12,
       "union_code":82,
       "union_name":"Nardas",
       "union_ban_name":"নারদাস"
    },
    {
       "_id":6931,
       "upazila_code":12,
       "union_code":85,
       "union_name":"Sonadanga",
       "union_ban_name":"সোনাডাঙ্গা"
    },
    {
       "_id":6933,
       "upazila_code":12,
       "union_code":94,
       "union_name":"Subhadanga",
       "union_ban_name":"সুভাদঙ্গা"
    },
    {
       "_id":6934,
       "upazila_code":25,
       "union_code":31,
       "union_name":"Bhaya Lakshmipur",
       "union_ban_name":"ভয়া লক্ষ্মীপুর"
    },
    {
       "_id":6935,
       "upazila_code":25,
       "union_code":39,
       "union_name":"Charghat",
       "union_ban_name":"চারঘাট"
    },
    {
       "_id":6936,
       "upazila_code":25,
       "union_code":47,
       "union_name":"Yusufpur",
       "union_ban_name":"ইউসুফপুর"
    },
    {
       "_id":6937,
       "upazila_code":25,
       "union_code":71,
       "union_name":"Nimpara",
       "union_ban_name":"নিমপারা"
    },
    {
       "_id":6939,
       "upazila_code":25,
       "union_code":94,
       "union_name":"Sardah",
       "union_ban_name":"সারদাহ"
    },
    {
       "_id":6940,
       "upazila_code":31,
       "union_code":11,
       "union_name":"Deluabari",
       "union_ban_name":"দেলুয়াবাড়ি"
    },
    {
       "_id":6941,
       "upazila_code":31,
       "union_code":23,
       "union_name":"Dharmapur (Pananagar)",
       "union_ban_name":"ধর্মপুর (পানানগর)"
    },
    {
       "_id":6942,
       "upazila_code":31,
       "union_code":35,
       "union_name":"Jhaluka",
       "union_ban_name":"ঝালুকা"
    },
    {
       "_id":6944,
       "upazila_code":31,
       "union_code":59,
       "union_name":"Kismat Gankair",
       "union_ban_name":"কিসমত গানকাইর"
    },
    {
       "_id":6947,
       "upazila_code":34,
       "union_code":22,
       "union_name":"Basudebpur",
       "union_ban_name":"বাসুদেবপুর"
    },
    {
       "_id":6948,
       "upazila_code":34,
       "union_code":25,
       "union_name":"Char Ashariadaha",
       "union_ban_name":"চর আশরিয়াদহ"
    },
    {
       "_id":6949,
       "upazila_code":34,
       "union_code":28,
       "union_name":"Deopara",
       "union_ban_name":"দেওপাড়া"
    },
    {
       "_id":6950,
       "upazila_code":34,
       "union_code":38,
       "union_name":"Godagari",
       "union_ban_name":"গোদাগাড়ী"
    },
    {
       "_id":6951,
       "upazila_code":34,
       "union_code":47,
       "union_name":"Gogram",
       "union_ban_name":"গোগ্রাম"
    },
    {
       "_id":6952,
       "upazila_code":34,
       "union_code":57,
       "union_name":"Matikata",
       "union_ban_name":"মাটিকাতা"
    },
    {
       "_id":6954,
       "upazila_code":34,
       "union_code":76,
       "union_name":"Pakri",
       "union_ban_name":"পাকরি"
    },
    {
       "_id":6955,
       "upazila_code":34,
       "union_code":85,
       "union_name":"Rishikul",
       "union_ban_name":".ষিকুল"
    },
    {
       "_id":6956,
       "upazila_code":53,
       "union_code":13,
       "union_name":"Bakshimail",
       "union_ban_name":"বকশিমেল"
    },
    {
       "_id":6958,
       "upazila_code":53,
       "union_code":40,
       "union_name":"Ghasigram",
       "union_ban_name":"ঘাসিগ্রাম"
    },
    {
       "_id":6959,
       "upazila_code":53,
       "union_code":54,
       "union_name":"Jahanabad",
       "union_ban_name":"জাহানাবাদ"
    },
    {
       "_id":6960,
       "upazila_code":53,
       "union_code":67,
       "union_name":"Maugachhi",
       "union_ban_name":"মওগাছি"
    },
    {
       "_id":6961,
       "upazila_code":53,
       "union_code":81,
       "union_name":"Royghati",
       "union_ban_name":"রায়ঘাটি"
    },
    {
       "_id":6962,
       "upazila_code":72,
       "union_code":27,
       "union_name":"Baragachhi",
       "union_ban_name":"বড়গাছি"
    },
    {
       "_id":6963,
       "upazila_code":72,
       "union_code":35,
       "union_name":"Damkur",
       "union_ban_name":"দামকুর"
    },
    {
       "_id":6964,
       "upazila_code":72,
       "union_code":43,
       "union_name":"Darshan Para",
       "union_ban_name":"দর্শন পাড়া"
    },
    {
       "_id":6965,
       "upazila_code":72,
       "union_code":51,
       "union_name":"Haragram",
       "union_ban_name":"হারাগ্রাম"
    },
    {
       "_id":6966,
       "upazila_code":72,
       "union_code":54,
       "union_name":"Harian",
       "union_ban_name":"হারিয়ান"
    },
    {
       "_id":6968,
       "upazila_code":72,
       "union_code":65,
       "union_name":"Hujuri Para",
       "union_ban_name":"হুজুরি পাড়া"
    },
    {
       "_id":6969,
       "upazila_code":72,
       "union_code":87,
       "union_name":"Parila",
       "union_ban_name":"পারিলা"
    },
    {
       "_id":6970,
       "upazila_code":82,
       "union_code":13,
       "union_name":"Baneshwar",
       "union_ban_name":"বানেশ্বর"
    },
    {
       "_id":6971,
       "upazila_code":82,
       "union_code":27,
       "union_name":"Belpukuria",
       "union_ban_name":"বেলপুকুরিয়া"
    },
    {
       "_id":6972,
       "upazila_code":82,
       "union_code":40,
       "union_name":"Bhalukgachhi",
       "union_ban_name":"ভালুকগাছি"
    },
    {
       "_id":6973,
       "upazila_code":82,
       "union_code":54,
       "union_name":"Jeopara",
       "union_ban_name":"জিওপাড়া"
    },
    {
       "_id":6974,
       "upazila_code":82,
       "union_code":67,
       "union_name":"Puthia",
       "union_ban_name":"পুঠিয়া"
    },
    {
       "_id":6975,
       "upazila_code":82,
       "union_code":81,
       "union_name":"Silmaria",
       "union_ban_name":"সিলমারিয়া"
    },
    {
       "_id":6976,
       "upazila_code":94,
       "union_code":27,
       "union_name":"Badhair",
       "union_ban_name":"বাধার"
    },
    {
       "_id":6977,
       "upazila_code":94,
       "union_code":40,
       "union_name":"Chanduria",
       "union_ban_name":"চান্দুরিয়া"
    },
    {
       "_id":6980,
       "upazila_code":94,
       "union_code":70,
       "union_name":"Pachandar",
       "union_ban_name":"পাচানদার"
    },
    {
       "_id":6981,
       "upazila_code":94,
       "union_code":77,
       "union_name":"Saranjai",
       "union_ban_name":"সারঞ্জাই"
    },
    {
       "_id":6982,
       "upazila_code":94,
       "union_code":81,
       "union_name":"Talanda",
       "union_ban_name":"তালান্দা"
    },
    {
       "_id":6984,
       "upazila_code":7,
       "union_code":11,
       "union_name":"Baghai Chhari",
       "union_ban_name":"বাঘাই ছরি"
    },
    {
       "_id":6985,
       "upazila_code":7,
       "union_code":23,
       "union_name":"Bangaltali",
       "union_ban_name":"বাঙালতলী"
    },
    {
       "_id":6986,
       "upazila_code":7,
       "union_code":35,
       "union_name":"Sarboatali",
       "union_ban_name":"সরবোটালি"
    },
    {
       "_id":6987,
       "upazila_code":7,
       "union_code":47,
       "union_name":"Kedarmara",
       "union_ban_name":"কেদারমারা"
    },
    {
       "_id":6988,
       "upazila_code":7,
       "union_code":59,
       "union_name":"Marishya",
       "union_ban_name":"মারিশ্যা"
    },
    {
       "_id":6989,
       "upazila_code":7,
       "union_code":71,
       "union_name":"Rupakari",
       "union_ban_name":"রূপকরী"
    },
    {
       "_id":6990,
       "upazila_code":7,
       "union_code":83,
       "union_name":"Sajek",
       "union_ban_name":"সাজেক"
    },
    {
       "_id":6991,
       "upazila_code":21,
       "union_code":15,
       "union_name":"Aima Chhara",
       "union_ban_name":"আইমা ছড়া"
    },
    {
       "_id":6992,
       "upazila_code":21,
       "union_code":31,
       "union_name":"Bara Harina",
       "union_ban_name":"বড় হরিনা"
    },
    {
       "_id":6994,
       "upazila_code":21,
       "union_code":63,
       "union_name":"Bhushan Chhara",
       "union_ban_name":"ভূষণ ছড়া"
    },
    {
       "_id":6995,
       "upazila_code":21,
       "union_code":79,
       "union_name":"Shublong",
       "union_ban_name":"শুবলং"
    },
    {
       "_id":6996,
       "upazila_code":25,
       "union_code":19,
       "union_name":"Betbunia",
       "union_ban_name":"বেতবুনিয়া"
    },
    {
       "_id":6997,
       "upazila_code":25,
       "union_code":38,
       "union_name":"Fatik Chhari",
       "union_ban_name":"ফটিক ছড়ি"
    },
    {
       "_id":6999,
       "upazila_code":25,
       "union_code":76,
       "union_name":"Kalampati",
       "union_ban_name":"কালামপতি"
    },
    {
       "_id":7000,
       "upazila_code":29,
       "union_code":23,
       "union_name":"Belai Chhari",
       "union_ban_name":"বেলাই ছরি"
    },
    {
       "_id":7001,
       "upazila_code":29,
       "union_code":47,
       "union_name":"Farua",
       "union_ban_name":"ফারুয়া"
    },
    {
       "_id":7002,
       "upazila_code":29,
       "union_code":71,
       "union_name":"Kangara Chhari",
       "union_ban_name":"কঙ্গারা ছড়ি"
    },
    {
       "_id":7003,
       "upazila_code":36,
       "union_code":19,
       "union_name":"Chandraghona",
       "union_ban_name":"চন্দ্রঘোনা"
    },
    {
       "_id":7004,
       "upazila_code":36,
       "union_code":38,
       "union_name":"Chitmaram",
       "union_ban_name":"চিতমারাম"
    },
    {
       "_id":7005,
       "upazila_code":36,
       "union_code":57,
       "union_name":"Kaptai",
       "union_ban_name":"কাপ্তাই"
    },
    {
       "_id":7006,
       "upazila_code":36,
       "union_code":76,
       "union_name":"Raikhali",
       "union_ban_name":"রায়খালী"
    },
    {
       "_id":7007,
       "upazila_code":36,
       "union_code":86,
       "union_name":"Wagga",
       "union_ban_name":"ওয়াগা"
    },
    {
       "_id":7008,
       "upazila_code":47,
       "union_code":19,
       "union_name":"Banjugi Chhara",
       "union_ban_name":"বনজুগি ছড়া"
    },
    {
       "_id":7009,
       "upazila_code":47,
       "union_code":38,
       "union_name":"Dumdumya",
       "union_ban_name":"দমদূম্যা"
    },
    {
       "_id":7010,
       "upazila_code":47,
       "union_code":57,
       "union_name":"Jurai Chhari",
       "union_ban_name":"জুরাই ছড়ি"
    },
    {
       "_id":7011,
       "upazila_code":47,
       "union_code":76,
       "union_name":"Maidang",
       "union_ban_name":"মাইদাং"
    },
    {
       "_id":7012,
       "upazila_code":58,
       "union_code":13,
       "union_name":"Atarak Chara",
       "union_ban_name":"আতরক চরা"
    },
    {
       "_id":7013,
       "upazila_code":58,
       "union_code":27,
       "union_name":"Bhasanya Adam",
       "union_ban_name":"ভাসন্যা আদম"
    },
    {
       "_id":7014,
       "upazila_code":58,
       "union_code":40,
       "union_name":"Bagachatar",
       "union_ban_name":"বাগাচাটার"
    },
    {
       "_id":7015,
       "upazila_code":58,
       "union_code":54,
       "union_name":"Gulshakhali",
       "union_ban_name":"গুলশখালী"
    },
    {
       "_id":7016,
       "upazila_code":58,
       "union_code":60,
       "union_name":"Kalapakurjya",
       "union_ban_name":"কলাপাকুরজ্যা"
    },
    {
       "_id":7017,
       "upazila_code":58,
       "union_code":67,
       "union_name":"Langadu",
       "union_ban_name":"লাঙ্গাদু"
    },
    {
       "_id":7018,
       "upazila_code":58,
       "union_code":81,
       "union_name":"Mayanimukh",
       "union_ban_name":"মায়ানিমুখ"
    },
    {
       "_id":7019,
       "upazila_code":75,
       "union_code":19,
       "union_name":"Burighat",
       "union_ban_name":"বুড়িঘাট"
    },
    {
       "_id":7020,
       "upazila_code":75,
       "union_code":38,
       "union_name":"Ghila Chhari",
       "union_ban_name":"ঘিলা ছড়ি"
    },
    {
       "_id":7021,
       "upazila_code":75,
       "union_code":57,
       "union_name":"Naniarchar",
       "union_ban_name":"নানিয়ারচর"
    },
    {
       "_id":7022,
       "upazila_code":75,
       "union_code":76,
       "union_name":"Sabekhyong",
       "union_ban_name":"সাবেখিয়াং"
    },
    {
       "_id":7023,
       "upazila_code":78,
       "union_code":23,
       "union_name":"Bangalhalia",
       "union_ban_name":"বাঙালহালিয়া"
    },
    {
       "_id":7025,
       "upazila_code":78,
       "union_code":71,
       "union_name":"Gainda",
       "union_ban_name":"গাইন্ডা"
    },
    {
       "_id":7026,
       "upazila_code":87,
       "union_code":13,
       "union_name":"Banduk Bhanga",
       "union_ban_name":"বান্দুক ভাঙ্গা"
    },
    {
       "_id":7027,
       "upazila_code":87,
       "union_code":27,
       "union_name":"Balukhali",
       "union_ban_name":"বালুখালী"
    },
    {
       "_id":7028,
       "upazila_code":87,
       "union_code":40,
       "union_name":"Jibtali",
       "union_ban_name":"জিবতালি"
    },
    {
       "_id":7029,
       "upazila_code":87,
       "union_code":54,
       "union_name":"Kutuk Chhari",
       "union_ban_name":"কুতুক ছরি"
    },
    {
       "_id":7030,
       "upazila_code":87,
       "union_code":67,
       "union_name":"Magban",
       "union_ban_name":"মাগবান"
    },
    {
       "_id":7031,
       "upazila_code":87,
       "union_code":81,
       "union_name":"Sapchhari",
       "union_ban_name":"সাপছড়ি"
    },
    {
       "_id":7032,
       "upazila_code":3,
       "union_code":16,
       "union_name":"Kalu Para",
       "union_ban_name":"কালু পাড়া"
    },
    {
       "_id":7035,
       "upazila_code":3,
       "union_code":31,
       "union_name":"Gopalpur'",
       "union_ban_name":"গোপালপুর '"
    },
    {
       "_id":7038,
       "upazila_code":3,
       "union_code":69,
       "union_name":"Lohani Para",
       "union_ban_name":"লোহানী পাড়া"
    },
    {
       "_id":7041,
       "upazila_code":3,
       "union_code":88,
       "union_name":"Ramnathpur",
       "union_ban_name":"রামনাথপুর"
    },
    {
       "_id":7042,
       "upazila_code":27,
       "union_code":16,
       "union_name":"Alam Biditar",
       "union_ban_name":"আলম বিদিতর"
    },
    {
       "_id":7043,
       "upazila_code":27,
       "union_code":19,
       "union_name":"Barabil",
       "union_ban_name":"বড়বিল"
    },
    {
       "_id":7044,
       "upazila_code":27,
       "union_code":21,
       "union_name":"Betgari",
       "union_ban_name":"বেতগারি"
    },
    {
       "_id":7045,
       "upazila_code":27,
       "union_code":31,
       "union_name":"Gangachara",
       "union_ban_name":"গঙ্গাচড়া"
    },
    {
       "_id":7046,
       "upazila_code":27,
       "union_code":42,
       "union_name":"Gajaghanta",
       "union_ban_name":"গাজাঘাঁটা"
    },
    {
       "_id":7047,
       "upazila_code":27,
       "union_code":52,
       "union_name":"Khaleya",
       "union_ban_name":"খালিয়া"
    },
    {
       "_id":7048,
       "upazila_code":27,
       "union_code":63,
       "union_name":"Kolkanda",
       "union_ban_name":"কোলকান্দা"
    },
    {
       "_id":7049,
       "upazila_code":27,
       "union_code":73,
       "union_name":"Lakshmitari",
       "union_ban_name":"লক্ষ্মিতরী"
    },
    {
       "_id":7050,
       "upazila_code":27,
       "union_code":77,
       "union_name":"Nohali",
       "union_ban_name":"নোহালি"
    },
    {
       "_id":7051,
       "upazila_code":27,
       "union_code":84,
       "union_name":"Marania",
       "union_ban_name":"মারানিয়া"
    },
    {
       "_id":7052,
       "upazila_code":42,
       "union_code":23,
       "union_name":"Haragachh",
       "union_ban_name":"হারাগাছ"
    },
    {
       "_id":7053,
       "upazila_code":42,
       "union_code":27,
       "union_name":"Kaunia ' Bala Para'",
       "union_ban_name":"কাউনিয়া 'বালা পাড়া'"
    },
    {
       "_id":7056,
       "upazila_code":42,
       "union_code":67,
       "union_name":"Shahidbagh",
       "union_ban_name":"শহীদবাগ"
    },
    {
       "_id":7057,
       "upazila_code":42,
       "union_code":81,
       "union_name":"Tepa Madhupur",
       "union_ban_name":"টেপা মধুপুর"
    },
    {
       "_id":7058,
       "upazila_code":49,
       "union_code":17,
       "union_name":"Chandanpat",
       "union_ban_name":"চন্দনপাট"
    },
    {
       "_id":7059,
       "upazila_code":49,
       "union_code":19,
       "union_name":"Darshana",
       "union_ban_name":"দর্শনা"
    },
    {
       "_id":7060,
       "upazila_code":49,
       "union_code":23,
       "union_name":"Haridebpur",
       "union_ban_name":"হরিদেবপুর"
    },
    {
       "_id":7062,
       "upazila_code":49,
       "union_code":39,
       "union_name":"Pashuram",
       "union_ban_name":"পশুরাম"
    },
    {
       "_id":7063,
       "upazila_code":49,
       "union_code":47,
       "union_name":"Rajendrapur",
       "union_ban_name":"রাজেন্দ্রপুর"
    },
    {
       "_id":7064,
       "upazila_code":49,
       "union_code":63,
       "union_name":"Sadya Pushkarni",
       "union_ban_name":"সাদ্য পুষ্করণী"
    },
    {
       "_id":7065,
       "upazila_code":49,
       "union_code":71,
       "union_name":"Satgara",
       "union_ban_name":"সাতগড়া"
    },
    {
       "_id":7066,
       "upazila_code":49,
       "union_code":79,
       "union_name":"Tamphat",
       "union_ban_name":"টাম্পাট"
    },
    {
       "_id":7067,
       "upazila_code":49,
       "union_code":87,
       "union_name":"Tapodhan",
       "union_ban_name":"তপোধন"
    },
    {
       "_id":7068,
       "upazila_code":49,
       "union_code":94,
       "union_name":"Uttam",
       "union_ban_name":"উত্তম"
    },
    {
       "_id":7069,
       "upazila_code":58,
       "union_code":17,
       "union_name":"Balua Masimpur",
       "union_ban_name":"বালুয়া মাসিমপুর"
    },
    {
       "_id":7070,
       "upazila_code":58,
       "union_code":21,
       "union_name":"Barabala",
       "union_ban_name":"বারবাল"
    },
    {
       "_id":7071,
       "upazila_code":58,
       "union_code":26,
       "union_name":"Bara Hazratpur",
       "union_ban_name":"বার হযরতপুর"
    },
    {
       "_id":7072,
       "upazila_code":58,
       "union_code":32,
       "union_name":"Balarhat",
       "union_ban_name":"বালারহাট"
    },
    {
       "_id":7073,
       "upazila_code":58,
       "union_code":37,
       "union_name":"Bhangni",
       "union_ban_name":"ভঙ্গনি"
    },
    {
       "_id":7074,
       "upazila_code":58,
       "union_code":43,
       "union_name":"Chengmari",
       "union_ban_name":"চেংমারী"
    },
    {
       "_id":7076,
       "upazila_code":58,
       "union_code":54,
       "union_name":"Emadpur",
       "union_ban_name":"এমাদপুর"
    },
    {
       "_id":7078,
       "upazila_code":58,
       "union_code":62,
       "union_name":"Kafrikhal",
       "union_ban_name":"কাফরিখাল"
    },
    {
       "_id":7079,
       "upazila_code":58,
       "union_code":65,
       "union_name":"Khoragachh",
       "union_ban_name":"খোরগচ"
    },
    {
       "_id":7081,
       "upazila_code":58,
       "union_code":72,
       "union_name":"Milanpur",
       "union_ban_name":"মিলানপুর"
    },
    {
       "_id":7083,
       "upazila_code":58,
       "union_code":83,
       "union_name":"Mayenpur",
       "union_ban_name":"মায়েনপুর"
    },
    {
       "_id":7084,
       "upazila_code":58,
       "union_code":89,
       "union_name":"Pairaband",
       "union_ban_name":"পেয়ারব্যান্ড"
    },
    {
       "_id":7086,
       "upazila_code":73,
       "union_code":17,
       "union_name":"Annadanagar",
       "union_ban_name":"অন্নদানগর"
    },
    {
       "_id":7087,
       "upazila_code":73,
       "union_code":19,
       "union_name":"Chhaola",
       "union_ban_name":"ছাওলা"
    },
    {
       "_id":7088,
       "upazila_code":73,
       "union_code":28,
       "union_name":"Itakumari",
       "union_ban_name":"ইটাকুমারী"
    },
    {
       "_id":7089,
       "upazila_code":73,
       "union_code":38,
       "union_name":"Kaikuri",
       "union_ban_name":"কাইকুরি।"
    },
    {
       "_id":7090,
       "upazila_code":73,
       "union_code":47,
       "union_name":"Kalyani",
       "union_ban_name":"কল্যাণি"
    },
    {
       "_id":7092,
       "upazila_code":73,
       "union_code":66,
       "union_name":"Parul'",
       "union_ban_name":"পারুল '"
    },
    {
       "_id":7093,
       "upazila_code":73,
       "union_code":76,
       "union_name":"Pirgachha",
       "union_ban_name":"পিরগাছা"
    },
    {
       "_id":7094,
       "upazila_code":73,
       "union_code":85,
       "union_name":"Tambulpur",
       "union_ban_name":"ত্বমুলপুর"
    },
    {
       "_id":7095,
       "upazila_code":76,
       "union_code":16,
       "union_name":"Bara Alampur",
       "union_ban_name":"বার আলমপুর"
    },
    {
       "_id":7096,
       "upazila_code":76,
       "union_code":22,
       "union_name":"Bara Dargah'",
       "union_ban_name":"বার দরগাহ '"
    },
    {
       "_id":7097,
       "upazila_code":76,
       "union_code":28,
       "union_name":"Bhendabari",
       "union_ban_name":"ভেন্ডবাড়ী"
    },
    {
       "_id":7098,
       "upazila_code":76,
       "union_code":35,
       "union_name":"Chaitrakul",
       "union_ban_name":"চৈতকুল"
    },
    {
       "_id":7099,
       "upazila_code":76,
       "union_code":41,
       "union_name":"Chatra",
       "union_ban_name":"চাত্রা"
    },
    {
       "_id":7101,
       "upazila_code":76,
       "union_code":54,
       "union_name":"Kumedpur",
       "union_ban_name":"কুমেদপুর"
    },
    {
       "_id":7102,
       "upazila_code":76,
       "union_code":56,
       "union_name":"Madankhali",
       "union_ban_name":"মদনখালী"
    },
    {
       "_id":7104,
       "upazila_code":76,
       "union_code":63,
       "union_name":"Panchgachha",
       "union_ban_name":"পাঁচগাছা"
    },
    {
       "_id":7105,
       "upazila_code":76,
       "union_code":69,
       "union_name":"Pirganj",
       "union_ban_name":"পীরগঞ্জ"
    },
    {
       "_id":7108,
       "upazila_code":76,
       "union_code":88,
       "union_name":"Shanerhat",
       "union_ban_name":"শ্যানেরহাট"
    },
    {
       "_id":7109,
       "upazila_code":76,
       "union_code":95,
       "union_name":"Tukuria",
       "union_ban_name":"টুকুরিয়া"
    },
    {
       "_id":7111,
       "upazila_code":92,
       "union_code":47,
       "union_name":"Ekarchali",
       "union_ban_name":"একরচালি"
    },
    {
       "_id":7112,
       "upazila_code":92,
       "union_code":63,
       "union_name":"Hariarkuti",
       "union_ban_name":"হরিয়ারকুটি"
    },
    {
       "_id":7114,
       "upazila_code":92,
       "union_code":79,
       "union_name":"Sayar",
       "union_ban_name":"সায়ার"
    },
    {
       "_id":7115,
       "upazila_code":4,
       "union_code":15,
       "union_name":"Anulia",
       "union_ban_name":"আনুলিয়া"
    },
    {
       "_id":7116,
       "upazila_code":4,
       "union_code":17,
       "union_name":"Assasuni",
       "union_ban_name":"আসাসুনি"
    },
    {
       "_id":7117,
       "upazila_code":4,
       "union_code":25,
       "union_name":"Baradal",
       "union_ban_name":"বড়দল"
    },
    {
       "_id":7118,
       "upazila_code":4,
       "union_code":34,
       "union_name":"Budhhata",
       "union_ban_name":"বুধহাতা"
    },
    {
       "_id":7120,
       "upazila_code":4,
       "union_code":56,
       "union_name":"Kadakati",
       "union_ban_name":"কাদাকাটি"
    },
    {
       "_id":7121,
       "upazila_code":4,
       "union_code":60,
       "union_name":"Khajra",
       "union_ban_name":"খজরা"
    },
    {
       "_id":7123,
       "upazila_code":4,
       "union_code":77,
       "union_name":"Pratap Nagar",
       "union_ban_name":"প্রতাপ নগর"
    },
    {
       "_id":7124,
       "upazila_code":4,
       "union_code":86,
       "union_name":"Sobhnali",
       "union_ban_name":"শোভনালী"
    },
    {
       "_id":7125,
       "upazila_code":4,
       "union_code":94,
       "union_name":"Sreeula",
       "union_ban_name":"শ্রীলা"
    },
    {
       "_id":7126,
       "upazila_code":25,
       "union_code":15,
       "union_name":"Debhata",
       "union_ban_name":"দেবহাটা"
    },
    {
       "_id":7130,
       "upazila_code":25,
       "union_code":79,
       "union_name":"Sakhipur",
       "union_ban_name":"সখিপুর"
    },
    {
       "_id":7133,
       "upazila_code":43,
       "union_code":23,
       "union_name":"Langaljhara",
       "union_ban_name":"লাঙ্গলঝাড়া"
    },
    {
       "_id":7134,
       "upazila_code":43,
       "union_code":31,
       "union_name":"Helatala",
       "union_ban_name":"হেলাতলা"
    },
    {
       "_id":7135,
       "upazila_code":43,
       "union_code":39,
       "union_name":"Jallabad",
       "union_ban_name":"জল্লাবাদ"
    },
    {
       "_id":7137,
       "upazila_code":43,
       "union_code":55,
       "union_name":"Jogikhali",
       "union_ban_name":"জোগিখালী"
    },
    {
       "_id":7138,
       "upazila_code":43,
       "union_code":63,
       "union_name":"Keragachhi",
       "union_ban_name":"কেরাগাছি"
    },
    {
       "_id":7139,
       "upazila_code":43,
       "union_code":71,
       "union_name":"Keralkata",
       "union_ban_name":"কেরালকাটা"
    },
    {
       "_id":7140,
       "upazila_code":43,
       "union_code":79,
       "union_name":"Kushadanga",
       "union_ban_name":"কুশডাঙ্গা"
    },
    {
       "_id":7141,
       "upazila_code":43,
       "union_code":87,
       "union_name":"Kaila",
       "union_ban_name":"কৈলা"
    },
    {
       "_id":7142,
       "upazila_code":43,
       "union_code":94,
       "union_name":"Sonabaria",
       "union_ban_name":"সোনাবাড়িয়া"
    },
    {
       "_id":7143,
       "upazila_code":47,
       "union_code":13,
       "union_name":"Bhara Simla",
       "union_ban_name":"ভর্তা সিমলা"
    },
    {
       "_id":7145,
       "upazila_code":47,
       "union_code":23,
       "union_name":"Champaphul",
       "union_ban_name":"চম্পাফুল"
    },
    {
       "_id":7146,
       "upazila_code":47,
       "union_code":31,
       "union_name":"Dakshin Sreepur",
       "union_ban_name":"দক্ষিণ শ্রীপুর"
    },
    {
       "_id":7147,
       "upazila_code":47,
       "union_code":39,
       "union_name":"Dhalbaria",
       "union_ban_name":"ধলবাড়িয়া"
    },
    {
       "_id":7149,
       "upazila_code":47,
       "union_code":55,
       "union_name":"Kushlia",
       "union_ban_name":"কুশলিয়া"
    },
    {
       "_id":7150,
       "upazila_code":47,
       "union_code":63,
       "union_name":"Mathureshpur",
       "union_ban_name":"মথুরেশপুর"
    },
    {
       "_id":7151,
       "upazila_code":47,
       "union_code":71,
       "union_name":"Mautala",
       "union_ban_name":"মৌতলা"
    },
    {
       "_id":7152,
       "upazila_code":47,
       "union_code":79,
       "union_name":"Nalta",
       "union_ban_name":"নলতা"
    },
    {
       "_id":7154,
       "upazila_code":47,
       "union_code":94,
       "union_name":"Tarali",
       "union_ban_name":"তারালী"
    },
    {
       "_id":7155,
       "upazila_code":82,
       "union_code":13,
       "union_name":"Agardari",
       "union_ban_name":"আগরদারি"
    },
    {
       "_id":7157,
       "upazila_code":82,
       "union_code":20,
       "union_name":"Baikari",
       "union_ban_name":"বৈকরি"
    },
    {
       "_id":7158,
       "upazila_code":82,
       "union_code":27,
       "union_name":"Balli",
       "union_ban_name":"বলি"
    },
    {
       "_id":7159,
       "upazila_code":82,
       "union_code":33,
       "union_name":"Banshdaha",
       "union_ban_name":"বাঁশদহ"
    },
    {
       "_id":7160,
       "upazila_code":82,
       "union_code":40,
       "union_name":"Bhomra",
       "union_ban_name":"ভোমরা"
    },
    {
       "_id":7161,
       "upazila_code":82,
       "union_code":47,
       "union_name":"Brahma Rajpur",
       "union_ban_name":"ব্রহ্মা রাজপুর"
    },
    {
       "_id":7162,
       "upazila_code":82,
       "union_code":51,
       "union_name":"Fingri",
       "union_ban_name":"ফিঙ্গরি"
    },
    {
       "_id":7163,
       "upazila_code":82,
       "union_code":54,
       "union_name":"Dhulihar",
       "union_ban_name":"ধুলিহার"
    },
    {
       "_id":7164,
       "upazila_code":82,
       "union_code":61,
       "union_name":"Ghona",
       "union_ban_name":"ঘোনা"
    },
    {
       "_id":7165,
       "upazila_code":82,
       "union_code":67,
       "union_name":"Jhaudanga",
       "union_ban_name":"ঝাউডাঙ্গা"
    },
    {
       "_id":7166,
       "upazila_code":82,
       "union_code":74,
       "union_name":"Kuskhali",
       "union_ban_name":"কুশখালী"
    },
    {
       "_id":7167,
       "upazila_code":82,
       "union_code":81,
       "union_name":"Labsa",
       "union_ban_name":"লাবসা"
    },
    {
       "_id":7169,
       "upazila_code":86,
       "union_code":11,
       "union_name":"Atulia",
       "union_ban_name":"অতুলিয়া"
    },
    {
       "_id":7170,
       "upazila_code":86,
       "union_code":15,
       "union_name":"Bhurulia",
       "union_ban_name":"ভুরুলিয়া"
    },
    {
       "_id":7171,
       "upazila_code":86,
       "union_code":23,
       "union_name":"Buri Goalini",
       "union_ban_name":"বুড়ি গোলিনী"
    },
    {
       "_id":7172,
       "upazila_code":86,
       "union_code":31,
       "union_name":"Gabura",
       "union_ban_name":"গাবুরা"
    },
    {
       "_id":7173,
       "upazila_code":86,
       "union_code":39,
       "union_name":"Ishwaripur",
       "union_ban_name":".শ্বরীপুর"
    },
    {
       "_id":7174,
       "upazila_code":86,
       "union_code":47,
       "union_name":"Kaikhali",
       "union_ban_name":"কৈখালি"
    },
    {
       "_id":7175,
       "upazila_code":86,
       "union_code":55,
       "union_name":"Kashimari",
       "union_ban_name":"কাশিমারি"
    },
    {
       "_id":7176,
       "upazila_code":86,
       "union_code":63,
       "union_name":"Munshiganj",
       "union_ban_name":"মুন্সিগঞ্জ"
    },
    {
       "_id":7177,
       "upazila_code":86,
       "union_code":71,
       "union_name":"Nurnagar",
       "union_ban_name":"নুরনগর"
    },
    {
       "_id":7178,
       "upazila_code":86,
       "union_code":79,
       "union_name":"Padma Pukur",
       "union_ban_name":"পদ্ম পুকুর"
    },
    {
       "_id":7179,
       "upazila_code":86,
       "union_code":87,
       "union_name":"Ramjan Nagar",
       "union_ban_name":"রমজান নগর"
    },
    {
       "_id":7180,
       "upazila_code":86,
       "union_code":94,
       "union_name":"Shyamnagar",
       "union_ban_name":"শ্যামনগর"
    },
    {
       "_id":7181,
       "upazila_code":90,
       "union_code":11,
       "union_name":"Dhandia",
       "union_ban_name":"Ndান্ডিয়া"
    },
    {
       "_id":7182,
       "upazila_code":90,
       "union_code":15,
       "union_name":"Islamkati",
       "union_ban_name":"ইসলামকাটি"
    },
    {
       "_id":7184,
       "upazila_code":90,
       "union_code":31,
       "union_name":"Khalilnagar",
       "union_ban_name":"খলিলনগর"
    },
    {
       "_id":7185,
       "upazila_code":90,
       "union_code":39,
       "union_name":"Khalishkhali",
       "union_ban_name":"খালিশখালী"
    },
    {
       "_id":7186,
       "upazila_code":90,
       "union_code":47,
       "union_name":"Khesra",
       "union_ban_name":"খেসরা"
    },
    {
       "_id":7189,
       "upazila_code":90,
       "union_code":71,
       "union_name":"Nagarghata",
       "union_ban_name":"নগরঘাটা"
    },
    {
       "_id":7190,
       "upazila_code":90,
       "union_code":79,
       "union_name":"Sarulia",
       "union_ban_name":"সরুলিয়া"
    },
    {
       "_id":7191,
       "upazila_code":90,
       "union_code":87,
       "union_name":"Tala",
       "union_ban_name":"তালা"
    },
    {
       "_id":7193,
       "upazila_code":14,
       "union_code":12,
       "union_name":"Arshi Nagar",
       "union_ban_name":"আরশি নগর"
    },
    {
       "_id":7194,
       "upazila_code":14,
       "union_code":15,
       "union_name":"Char Bhaga",
       "union_ban_name":"চর ভাগা"
    },
    {
       "_id":7195,
       "upazila_code":14,
       "union_code":17,
       "union_name":"Char Kumaria",
       "union_ban_name":"চর কুমারিয়া"
    },
    {
       "_id":7196,
       "upazila_code":14,
       "union_code":25,
       "union_name":"Chhaygaon",
       "union_ban_name":"ছায়গাঁও"
    },
    {
       "_id":7197,
       "upazila_code":14,
       "union_code":28,
       "union_name":"Char Census",
       "union_ban_name":"চর শুমারি"
    },
    {
       "_id":7198,
       "upazila_code":14,
       "union_code":32,
       "union_name":"Dhakhin Tarabunia",
       "union_ban_name":"Khাখিন তারাবুনিয়া"
    },
    {
       "_id":7199,
       "upazila_code":14,
       "union_code":43,
       "union_name":"Digar Mahishkhali",
       "union_ban_name":"দিগর মহিষখালী"
    },
    {
       "_id":7200,
       "upazila_code":14,
       "union_code":51,
       "union_name":"Kachikata",
       "union_ban_name":"কচিকাটা"
    },
    {
       "_id":7201,
       "upazila_code":14,
       "union_code":60,
       "union_name":"Mahisar",
       "union_ban_name":"মহিষার"
    },
    {
       "_id":7205,
       "upazila_code":14,
       "union_code":94,
       "union_name":"Tarabunia",
       "union_ban_name":"তারাবুনিয়া"
    },
    {
       "_id":7206,
       "upazila_code":25,
       "union_code":23,
       "union_name":"Darul Aman",
       "union_ban_name":"দারুল আমান"
    },
    {
       "_id":7207,
       "upazila_code":25,
       "union_code":35,
       "union_name":"Dhankati",
       "union_ban_name":"ধনকাটি"
    },
    {
       "_id":7208,
       "upazila_code":25,
       "union_code":40,
       "union_name":"Islam Pur",
       "union_ban_name":"ইসলাম পুর"
    },
    {
       "_id":7209,
       "upazila_code":25,
       "union_code":47,
       "union_name":"Kaneshwar",
       "union_ban_name":"কানেশ্বর"
    },
    {
       "_id":7210,
       "upazila_code":25,
       "union_code":59,
       "union_name":"Purba Damudya",
       "union_ban_name":"পূর্বা ডামুড্যা"
    },
    {
       "_id":7211,
       "upazila_code":25,
       "union_code":71,
       "union_name":"Sidulkura",
       "union_ban_name":"সিডুলকুড়া"
    },
    {
       "_id":7212,
       "upazila_code":25,
       "union_code":83,
       "union_name":"Sidya",
       "union_ban_name":"সিড্যা"
    },
    {
       "_id":7213,
       "upazila_code":36,
       "union_code":11,
       "union_name":"Alawalpur",
       "union_ban_name":"আলাওয়ালপুর"
    },
    {
       "_id":7214,
       "upazila_code":36,
       "union_code":23,
       "union_name":"Gosairhat",
       "union_ban_name":"গোসাইরহাট"
    },
    {
       "_id":7216,
       "upazila_code":36,
       "union_code":47,
       "union_name":"Kodalpur",
       "union_ban_name":"কোডালপুর"
    },
    {
       "_id":7217,
       "upazila_code":36,
       "union_code":51,
       "union_name":"Kuchaipatti",
       "union_ban_name":"কুচাইপট্টি"
    },
    {
       "_id":7218,
       "upazila_code":36,
       "union_code":59,
       "union_name":"Nager Para",
       "union_ban_name":"নাগার পাড়া"
    },
    {
       "_id":7219,
       "upazila_code":36,
       "union_code":71,
       "union_name":"Nalmuri",
       "union_ban_name":"নলমুড়ি"
    },
    {
       "_id":7220,
       "upazila_code":36,
       "union_code":83,
       "union_name":"Samantasar",
       "union_ban_name":"সামন্তসার"
    },
    {
       "_id":7221,
       "upazila_code":65,
       "union_code":11,
       "union_name":"Bhojeshwar",
       "union_ban_name":"ভোজেশ্বর"
    },
    {
       "_id":7222,
       "upazila_code":65,
       "union_code":12,
       "union_name":"Bhumkhara",
       "union_ban_name":"ভুমখড়া"
    },
    {
       "_id":7223,
       "upazila_code":65,
       "union_code":18,
       "union_name":"Bijhari",
       "union_ban_name":"বিজারি"
    },
    {
       "_id":7224,
       "upazila_code":65,
       "union_code":25,
       "union_name":"Chamta",
       "union_ban_name":"চামতা"
    },
    {
       "_id":7225,
       "upazila_code":65,
       "union_code":31,
       "union_name":"Char Atra",
       "union_ban_name":"চর আতরা"
    },
    {
       "_id":7226,
       "upazila_code":65,
       "union_code":37,
       "union_name":"Dinga Manik",
       "union_ban_name":"ডিঙ্গা মানিক"
    },
    {
       "_id":7227,
       "upazila_code":65,
       "union_code":44,
       "union_name":"Fateh Jangapur",
       "union_ban_name":"ফতেহ জঙ্গাপুর"
    },
    {
       "_id":7228,
       "upazila_code":65,
       "union_code":50,
       "union_name":"Gharisar",
       "union_ban_name":"ঘড়িসর"
    },
    {
       "_id":7229,
       "upazila_code":65,
       "union_code":56,
       "union_name":"Japsa",
       "union_ban_name":"জাপসা"
    },
    {
       "_id":7231,
       "upazila_code":65,
       "union_code":69,
       "union_name":"Muktarer Char",
       "union_ban_name":"মুক্তার চর"
    },
    {
       "_id":7232,
       "upazila_code":65,
       "union_code":82,
       "union_name":"Nasasan",
       "union_ban_name":"নাসাসন"
    },
    {
       "_id":7238,
       "upazila_code":69,
       "union_code":38,
       "union_name":"Chikandi",
       "union_ban_name":"চিকান্দি"
    },
    {
       "_id":7239,
       "upazila_code":69,
       "union_code":47,
       "union_name":"Chitalia",
       "union_ban_name":"চিতালিয়া"
    },
    {
       "_id":7240,
       "upazila_code":69,
       "union_code":57,
       "union_name":"Domsar",
       "union_ban_name":"ডোমসার"
    },
    {
       "_id":7242,
       "upazila_code":69,
       "union_code":66,
       "union_name":"Palong",
       "union_ban_name":"পালং"
    },
    {
       "_id":7243,
       "upazila_code":69,
       "union_code":76,
       "union_name":"Rudrakar",
       "union_ban_name":"রুদ্রকর"
    },
    {
       "_id":7244,
       "upazila_code":69,
       "union_code":85,
       "union_name":"Shaul Para",
       "union_ban_name":"শৈল পাড়া"
    },
    {
       "_id":7245,
       "upazila_code":69,
       "union_code":95,
       "union_name":"Tulasar",
       "union_ban_name":"তুলসার"
    },
    {
       "_id":7246,
       "upazila_code":94,
       "union_code":12,
       "union_name":"Bara Gopalpur",
       "union_ban_name":"বড় গোপালপুর"
    },
    {
       "_id":7247,
       "upazila_code":94,
       "union_code":14,
       "union_name":"Barakandi",
       "union_ban_name":"বারাকান্দি"
    },
    {
       "_id":7248,
       "upazila_code":94,
       "union_code":21,
       "union_name":"Bara Krishnagar",
       "union_ban_name":"বড় কৃষ্ণগর"
    },
    {
       "_id":7251,
       "upazila_code":94,
       "union_code":51,
       "union_name":"Kunder Char",
       "union_ban_name":"কুন্ডার চর"
    },
    {
       "_id":7252,
       "upazila_code":94,
       "union_code":58,
       "union_name":"Mulna",
       "union_ban_name":"মুলনা"
    },
    {
       "_id":7253,
       "upazila_code":94,
       "union_code":65,
       "union_name":"Naodoba",
       "union_ban_name":"নওডোবা"
    },
    {
       "_id":7254,
       "upazila_code":94,
       "union_code":73,
       "union_name":"Paler Char",
       "union_ban_name":"পালের চর"
    },
    {
       "_id":7255,
       "upazila_code":94,
       "union_code":80,
       "union_name":"Purba Naodoba",
       "union_ban_name":"পূর্ব নাওডোবা"
    },
    {
       "_id":7256,
       "upazila_code":94,
       "union_code":87,
       "union_name":"Sener Char",
       "union_ban_name":"সেনার চর"
    },
    {
       "_id":7257,
       "upazila_code":94,
       "union_code":94,
       "union_name":"Zanjira",
       "union_ban_name":"জাঞ্জিরা"
    },
    {
       "_id":7258,
       "upazila_code":37,
       "union_code":12,
       "union_name":"Dhanshail",
       "union_ban_name":"ধানশাইল"
    },
    {
       "_id":7260,
       "upazila_code":37,
       "union_code":17,
       "union_name":"Hatibandha Malijhikanda",
       "union_ban_name":"হাতীবান্ধা মালিঝিকান্দা"
    },
    {
       "_id":7261,
       "upazila_code":37,
       "union_code":25,
       "union_name":"Jhenaigati",
       "union_ban_name":"ঝিনাইগাতী"
    },
    {
       "_id":7262,
       "upazila_code":37,
       "union_code":43,
       "union_name":"Kangsha",
       "union_ban_name":"কংশা"
    },
    {
       "_id":7263,
       "upazila_code":37,
       "union_code":50,
       "union_name":"Malijhikanda",
       "union_ban_name":"মালিঝিকান্দা"
    },
    {
       "_id":7264,
       "upazila_code":37,
       "union_code":60,
       "union_name":"Nalkura",
       "union_ban_name":"নলকুড়া"
    },
    {
       "_id":7265,
       "upazila_code":67,
       "union_code":17,
       "union_name":"Baneshwardi",
       "union_ban_name":"বানেশ্বরদী"
    },
    {
       "_id":7266,
       "upazila_code":67,
       "union_code":19,
       "union_name":"Chandrakona",
       "union_ban_name":"চন্দ্রকোনা"
    },
    {
       "_id":7267,
       "upazila_code":67,
       "union_code":28,
       "union_name":"Char Ashtadhar",
       "union_ban_name":"চর অষ্টাধর"
    },
    {
       "_id":7268,
       "upazila_code":67,
       "union_code":38,
       "union_name":"Ganapaddi",
       "union_ban_name":"গণপদী"
    },
    {
       "_id":7269,
       "upazila_code":67,
       "union_code":47,
       "union_name":"Gourdwar",
       "union_ban_name":"গৌরদ্বার"
    },
    {
       "_id":7270,
       "upazila_code":67,
       "union_code":57,
       "union_name":"Nakla",
       "union_ban_name":"নকলা"
    },
    {
       "_id":7271,
       "upazila_code":67,
       "union_code":66,
       "union_name":"Pathakata",
       "union_ban_name":"পাঠকটা"
    },
    {
       "_id":7272,
       "upazila_code":67,
       "union_code":76,
       "union_name":"Talki",
       "union_ban_name":"তালকি"
    },
    {
       "_id":7273,
       "upazila_code":67,
       "union_code":85,
       "union_name":"Urpha",
       "union_ban_name":"উরফা"
    },
    {
       "_id":7275,
       "upazila_code":70,
       "union_code":34,
       "union_name":"Jogania",
       "union_ban_name":"জোগানিয়া"
    },
    {
       "_id":7276,
       "upazila_code":70,
       "union_code":38,
       "union_name":"Kakarkandi",
       "union_ban_name":"কাকারকান্দি"
    },
    {
       "_id":7277,
       "upazila_code":70,
       "union_code":41,
       "union_name":"Kalaspur",
       "union_ban_name":"কালাসপুর"
    },
    {
       "_id":7278,
       "upazila_code":70,
       "union_code":51,
       "union_name":"Nalitabari",
       "union_ban_name":"নালিতাবাড়ি"
    },
    {
       "_id":7279,
       "upazila_code":70,
       "union_code":55,
       "union_name":"Nayabil",
       "union_ban_name":"নয়াবিল"
    },
    {
       "_id":7280,
       "upazila_code":70,
       "union_code":69,
       "union_name":"Nunni",
       "union_ban_name":"নুনি"
    },
    {
       "_id":7281,
       "upazila_code":70,
       "union_code":72,
       "union_name":"Marichpura",
       "union_ban_name":"মেরিচপুরা"
    },
    {
       "_id":7282,
       "upazila_code":70,
       "union_code":75,
       "union_name":"Poragaon",
       "union_ban_name":"পোড়াগাঁও"
    },
    {
       "_id":7284,
       "upazila_code":70,
       "union_code":86,
       "union_name":"Ramchandrakura Mandalia",
       "union_ban_name":"রামচন্দ্রকুড়া মণ্ডলিয়া"
    },
    {
       "_id":7285,
       "upazila_code":70,
       "union_code":94,
       "union_name":"Rupnarayankura",
       "union_ban_name":"রূপনারায়ণকুড়া"
    },
    {
       "_id":7286,
       "upazila_code":88,
       "union_code":11,
       "union_name":"Bajitkhila",
       "union_ban_name":"বাজিতখিলা"
    },
    {
       "_id":7287,
       "upazila_code":88,
       "union_code":13,
       "union_name":"Balair Char",
       "union_ban_name":"বলির চর"
    },
    {
       "_id":7288,
       "upazila_code":88,
       "union_code":15,
       "union_name":"Betmari Ghughurakandi",
       "union_ban_name":"বেতমারী ঘুঘুরাকান্দি"
    },
    {
       "_id":7289,
       "upazila_code":88,
       "union_code":20,
       "union_name":"Bhatsala",
       "union_ban_name":"ভাতসালা"
    },
    {
       "_id":7290,
       "upazila_code":88,
       "union_code":27,
       "union_name":"Char Mucharia",
       "union_ban_name":"চর মুচারিয়া"
    },
    {
       "_id":7291,
       "upazila_code":88,
       "union_code":33,
       "union_name":"Char Pakshimari",
       "union_ban_name":"চর পাকশিমারী"
    },
    {
       "_id":7292,
       "upazila_code":88,
       "union_code":40,
       "union_name":"Char Sherpur",
       "union_ban_name":"চর শেরপুর"
    },
    {
       "_id":7294,
       "upazila_code":88,
       "union_code":54,
       "union_name":"Ghazir Khamar",
       "union_ban_name":"গাজির খামার"
    },
    {
       "_id":7295,
       "upazila_code":88,
       "union_code":61,
       "union_name":"Kamarer Char",
       "union_ban_name":"কামার চর"
    },
    {
       "_id":7300,
       "upazila_code":90,
       "union_code":15,
       "union_name":"Bhelua",
       "union_ban_name":"ভেলুয়া"
    },
    {
       "_id":7301,
       "upazila_code":90,
       "union_code":31,
       "union_name":"Garjaripa",
       "union_ban_name":"গজারিপা"
    },
    {
       "_id":7302,
       "upazila_code":90,
       "union_code":39,
       "union_name":"Gosaipur",
       "union_ban_name":"গোসাইপুর"
    },
    {
       "_id":7303,
       "upazila_code":90,
       "union_code":47,
       "union_name":"Kakilakura",
       "union_ban_name":"কাকিলাকুড়া"
    },
    {
       "_id":7304,
       "upazila_code":90,
       "union_code":55,
       "union_name":"Kharia Kazir Char",
       "union_ban_name":"খরিয়া কাজির চর"
    },
    {
       "_id":7305,
       "upazila_code":90,
       "union_code":63,
       "union_name":"Kurikahania",
       "union_ban_name":"কুরিকাহনিয়া"
    },
    {
       "_id":7306,
       "upazila_code":90,
       "union_code":71,
       "union_name":"Rani Shimul",
       "union_ban_name":"রানি শিমুল"
    },
    {
       "_id":7307,
       "upazila_code":90,
       "union_code":79,
       "union_name":"Singa Baruna",
       "union_ban_name":"সিংগা বরুণা"
    },
    {
       "_id":7308,
       "upazila_code":90,
       "union_code":86,
       "union_name":"Sreebardi",
       "union_ban_name":"শ্রীবরদী"
    },
    {
       "_id":7309,
       "upazila_code":90,
       "union_code":94,
       "union_name":"Tantihati",
       "union_ban_name":"তাঁতিহাটি"
    },
    {
       "_id":7310,
       "upazila_code":11,
       "union_code":13,
       "union_name":"Bara Dhul",
       "union_ban_name":"বড় ধুল"
    },
    {
       "_id":7311,
       "upazila_code":11,
       "union_code":27,
       "union_name":"Belkuchi",
       "union_ban_name":"বেলকুচি"
    },
    {
       "_id":7312,
       "upazila_code":11,
       "union_code":40,
       "union_name":"Bhangabari",
       "union_ban_name":"ভাঙ্গাবাড়ি"
    },
    {
       "_id":7314,
       "upazila_code":11,
       "union_code":67,
       "union_name":"Dhukaria Bera",
       "union_ban_name":"ধুকরিয়া বেড়া"
    },
    {
       "_id":7316,
       "upazila_code":27,
       "union_code":21,
       "union_name":"Bagutia",
       "union_ban_name":"বাগুটিয়া"
    },
    {
       "_id":7317,
       "upazila_code":27,
       "union_code":23,
       "union_name":"Gharjan",
       "union_ban_name":"ঘার্জন"
    },
    {
       "_id":7318,
       "upazila_code":27,
       "union_code":27,
       "union_name":"Khas Kaulia",
       "union_ban_name":"খাস কাউলিয়া"
    },
    {
       "_id":7319,
       "upazila_code":27,
       "union_code":31,
       "union_name":"Khas Pukuria",
       "union_ban_name":"খাস পুকুরিয়া"
    },
    {
       "_id":7320,
       "upazila_code":27,
       "union_code":37,
       "union_name":"Omarpur",
       "union_ban_name":"ওমরপুর"
    },
    {
       "_id":7321,
       "upazila_code":27,
       "union_code":47,
       "union_name":"Sadia Chandpur",
       "union_ban_name":"সাদিয়া চাঁদপুর"
    },
    {
       "_id":7322,
       "upazila_code":27,
       "union_code":71,
       "union_name":"Sthal",
       "union_ban_name":"স্থল"
    },
    {
       "_id":7323,
       "upazila_code":44,
       "union_code":19,
       "union_name":"Bhadraghat",
       "union_ban_name":"ভদ্রঘাট"
    },
    {
       "_id":7324,
       "upazila_code":44,
       "union_code":38,
       "union_name":"Jamtail",
       "union_ban_name":"জামটাইল"
    },
    {
       "_id":7325,
       "upazila_code":44,
       "union_code":57,
       "union_name":"Jhawail",
       "union_ban_name":"জাওয়াইল"
    },
    {
       "_id":7326,
       "upazila_code":44,
       "union_code":76,
       "union_name":"Roy Daulatpur",
       "union_ban_name":"রায় দৌলতপুর"
    },
    {
       "_id":7327,
       "upazila_code":50,
       "union_code":15,
       "union_name":"Char Girish",
       "union_ban_name":"চর গিরিশ"
    },
    {
       "_id":7328,
       "upazila_code":50,
       "union_code":17,
       "union_name":"Chalitadanga",
       "union_ban_name":"চলতাডাঙা"
    },
    {
       "_id":7329,
       "upazila_code":50,
       "union_code":25,
       "union_name":"Gandail",
       "union_ban_name":"গ্যান্ডাইল"
    },
    {
       "_id":7331,
       "upazila_code":50,
       "union_code":43,
       "union_name":"Khas Rajbari",
       "union_ban_name":"খাস রাজবাড়ী"
    },
    {
       "_id":7332,
       "upazila_code":50,
       "union_code":51,
       "union_name":"Maijbari",
       "union_ban_name":"মাইজবাড়ি"
    },
    {
       "_id":7333,
       "upazila_code":50,
       "union_code":56,
       "union_name":"Mansur Nagar",
       "union_ban_name":"মনসুর নগর"
    },
    {
       "_id":7334,
       "upazila_code":50,
       "union_code":60,
       "union_name":"Natuar Para",
       "union_ban_name":"নাটুয়ার পাড়া"
    },
    {
       "_id":7335,
       "upazila_code":50,
       "union_code":69,
       "union_name":"Nishchintapur",
       "union_ban_name":"নিশ্চিন্তপুর"
    },
    {
       "_id":7337,
       "upazila_code":50,
       "union_code":86,
       "union_name":"Subhagachha",
       "union_ban_name":"সুভাগাছা"
    },
    {
       "_id":7338,
       "upazila_code":50,
       "union_code":94,
       "union_name":"Tekani",
       "union_ban_name":"টেকানি"
    },
    {
       "_id":7339,
       "upazila_code":61,
       "union_code":13,
       "union_name":"Brahmagachha",
       "union_ban_name":"ব্রহ্মগাছা"
    },
    {
       "_id":7340,
       "upazila_code":61,
       "union_code":19,
       "union_name":"Chandaikona",
       "union_ban_name":"চান্দাইকোনা"
    },
    {
       "_id":7341,
       "upazila_code":61,
       "union_code":28,
       "union_name":"Dhamainagar",
       "union_ban_name":"ধামাইনগর"
    },
    {
       "_id":7342,
       "upazila_code":61,
       "union_code":38,
       "union_name":"Dhangara",
       "union_ban_name":"ধংগরা"
    },
    {
       "_id":7343,
       "upazila_code":61,
       "union_code":47,
       "union_name":"Dhubil",
       "union_ban_name":"ধুবিল"
    },
    {
       "_id":7344,
       "upazila_code":61,
       "union_code":57,
       "union_name":"Ghurka",
       "union_ban_name":"ঘুরকা"
    },
    {
       "_id":7345,
       "upazila_code":61,
       "union_code":66,
       "union_name":"Nalka",
       "union_ban_name":"নলকা"
    },
    {
       "_id":7346,
       "upazila_code":61,
       "union_code":76,
       "union_name":"Pangashi",
       "union_ban_name":"পাঙ্গাশী"
    },
    {
       "_id":7347,
       "upazila_code":61,
       "union_code":85,
       "union_name":"Sonakhara",
       "union_ban_name":"সোনাখড়া"
    },
    {
       "_id":7348,
       "upazila_code":67,
       "union_code":12,
       "union_name":"Beltail",
       "union_ban_name":"বেল্টেল"
    },
    {
       "_id":7350,
       "upazila_code":67,
       "union_code":21,
       "union_name":"Garadaha",
       "union_ban_name":"গাড়াদহ"
    },
    {
       "_id":7353,
       "upazila_code":67,
       "union_code":43,
       "union_name":"Kayempur",
       "union_ban_name":"কাইমপুর"
    },
    {
       "_id":7354,
       "upazila_code":67,
       "union_code":51,
       "union_name":"Khukni",
       "union_ban_name":"খুকনি"
    },
    {
       "_id":7355,
       "upazila_code":67,
       "union_code":58,
       "union_name":"Narina",
       "union_ban_name":"নারিনা"
    },
    {
       "_id":7356,
       "upazila_code":67,
       "union_code":65,
       "union_name":"Porjana",
       "union_ban_name":"পোরজানা"
    },
    {
       "_id":7357,
       "upazila_code":67,
       "union_code":73,
       "union_name":"Potajia",
       "union_ban_name":"পোটাজিয়া"
    },
    {
       "_id":7358,
       "upazila_code":67,
       "union_code":80,
       "union_name":"Rupabati",
       "union_ban_name":"রূপবতী"
    },
    {
       "_id":7359,
       "upazila_code":67,
       "union_code":87,
       "union_name":"Habibullanagar",
       "union_ban_name":"হাবিবুল্লানগর"
    },
    {
       "_id":7360,
       "upazila_code":67,
       "union_code":94,
       "union_name":"Sonatani",
       "union_ban_name":"সোনাতানি"
    },
    {
       "_id":7361,
       "upazila_code":78,
       "union_code":16,
       "union_name":"Baghbati",
       "union_ban_name":"বাগবাতি"
    },
    {
       "_id":7362,
       "upazila_code":78,
       "union_code":17,
       "union_name":"Bahuli",
       "union_ban_name":"বাহুলি"
    },
    {
       "_id":7363,
       "upazila_code":78,
       "union_code":25,
       "union_name":"Kalia Haripur",
       "union_ban_name":"কালিয়া হরিপুর"
    },
    {
       "_id":7364,
       "upazila_code":78,
       "union_code":34,
       "union_name":"Kaoakola",
       "union_ban_name":"কাওকোলা"
    },
    {
       "_id":7365,
       "upazila_code":78,
       "union_code":43,
       "union_name":"Khoksabari",
       "union_ban_name":"খোকশাবাড়ি"
    },
    {
       "_id":7366,
       "upazila_code":78,
       "union_code":51,
       "union_name":"Mechhra",
       "union_ban_name":"মেছড়া"
    },
    {
       "_id":7367,
       "upazila_code":78,
       "union_code":60,
       "union_name":"Ratankandi",
       "union_ban_name":"রতনকান্দি"
    },
    {
       "_id":7368,
       "upazila_code":78,
       "union_code":69,
       "union_name":"Saidabad",
       "union_ban_name":"সায়দাবাদ"
    },
    {
       "_id":7369,
       "upazila_code":78,
       "union_code":77,
       "union_name":"Shialkul",
       "union_ban_name":"শিয়ালকুল"
    },
    {
       "_id":7370,
       "upazila_code":78,
       "union_code":94,
       "union_name":"Chhangachha",
       "union_ban_name":"ছ্যাংগাছা"
    },
    {
       "_id":7371,
       "upazila_code":89,
       "union_code":10,
       "union_name":"Baruhas",
       "union_ban_name":"বারুহাস"
    },
    {
       "_id":7372,
       "upazila_code":89,
       "union_code":21,
       "union_name":"Deshigram",
       "union_ban_name":"দেশিগ্রাম"
    },
    {
       "_id":7373,
       "upazila_code":89,
       "union_code":31,
       "union_name":"Madhainagar",
       "union_ban_name":"মাধাইনগর"
    },
    {
       "_id":7374,
       "upazila_code":89,
       "union_code":42,
       "union_name":"Magura Binod",
       "union_ban_name":"মাগুরা বিনোদ"
    },
    {
       "_id":7376,
       "upazila_code":89,
       "union_code":63,
       "union_name":"Saguna",
       "union_ban_name":"সাগুনা"
    },
    {
       "_id":7377,
       "upazila_code":89,
       "union_code":73,
       "union_name":"Talam",
       "union_ban_name":"টালাম"
    },
    {
       "_id":7378,
       "upazila_code":89,
       "union_code":84,
       "union_name":"Tarash",
       "union_ban_name":"তাড়াশ"
    },
    {
       "_id":7379,
       "upazila_code":94,
       "union_code":12,
       "union_name":"Bangala",
       "union_ban_name":"বাঙলা"
    },
    {
       "_id":7380,
       "upazila_code":94,
       "union_code":14,
       "union_name":"Barahar",
       "union_ban_name":"বড়হার"
    },
    {
       "_id":7381,
       "upazila_code":94,
       "union_code":21,
       "union_name":"Bara Pangashi",
       "union_ban_name":"বড় পাঙ্গাশি"
    },
    {
       "_id":7382,
       "upazila_code":94,
       "union_code":29,
       "union_name":"Durganagar",
       "union_ban_name":"দুর্গানগর"
    },
    {
       "_id":7383,
       "upazila_code":94,
       "union_code":36,
       "union_name":"Hatikumrul",
       "union_ban_name":"হাতিকুমরুল"
    },
    {
       "_id":7385,
       "upazila_code":94,
       "union_code":51,
       "union_name":"Pancha Krushi",
       "union_ban_name":"পঞ্চ কৃষি"
    },
    {
       "_id":7386,
       "upazila_code":94,
       "union_code":58,
       "union_name":"Purnimaganti",
       "union_ban_name":"পূর্ণিমাগন্তী"
    },
    {
       "_id":7388,
       "upazila_code":94,
       "union_code":73,
       "union_name":"Salanga",
       "union_ban_name":"সালঙ্গা"
    },
    {
       "_id":7389,
       "upazila_code":94,
       "union_code":80,
       "union_name":"Salap",
       "union_ban_name":"সালাপ"
    },
    {
       "_id":7390,
       "upazila_code":94,
       "union_code":87,
       "union_name":"Udhunia",
       "union_ban_name":"উধুনিয়া"
    },
    {
       "_id":7391,
       "upazila_code":94,
       "union_code":94,
       "union_name":"Ullah Para",
       "union_ban_name":"উল্লাহ পাড়া"
    },
    {
       "_id":7392,
       "upazila_code":18,
       "union_code":17,
       "union_name":"Dakshin Badaghat",
       "union_ban_name":"দক্ষিণ বাদাঘাট"
    },
    {
       "_id":7393,
       "upazila_code":18,
       "union_code":20,
       "union_name":"Dhonpur",
       "union_ban_name":"ধোনপুর"
    },
    {
       "_id":7395,
       "upazila_code":18,
       "union_code":77,
       "union_name":"Palash",
       "union_ban_name":"পলাশ"
    },
    {
       "_id":7396,
       "upazila_code":18,
       "union_code":80,
       "union_name":"Sholukabad",
       "union_ban_name":"শোলুকাবাদ"
    },
    {
       "_id":7397,
       "upazila_code":23,
       "union_code":11,
       "union_name":"Bhatgaon",
       "union_ban_name":"ভাতগাঁও"
    },
    {
       "_id":7398,
       "upazila_code":23,
       "union_code":13,
       "union_name":"Chhatak",
       "union_ban_name":"ছাতক"
    },
    {
       "_id":7399,
       "upazila_code":23,
       "union_code":14,
       "union_name":"Charmohalla",
       "union_ban_name":"চরমোহল্লা"
    },
    {
       "_id":7400,
       "upazila_code":23,
       "union_code":38,
       "union_name":"Dular Bazar",
       "union_ban_name":"দুলার বাজার"
    },
    {
       "_id":7401,
       "upazila_code":23,
       "union_code":42,
       "union_name":"Dakshin Khurma",
       "union_ban_name":"দক্ষিণ খুরমা"
    },
    {
       "_id":7402,
       "upazila_code":23,
       "union_code":47,
       "union_name":"Dakshin Islampur",
       "union_ban_name":"দক্ষিণ ইসলামপুর"
    },
    {
       "_id":7403,
       "upazila_code":23,
       "union_code":52,
       "union_name":"Jawar Bazar",
       "union_ban_name":"জাওর বাজার"
    },
    {
       "_id":7404,
       "upazila_code":23,
       "union_code":57,
       "union_name":"Kalaruka",
       "union_ban_name":"কালারুকা"
    },
    {
       "_id":7405,
       "upazila_code":23,
       "union_code":76,
       "union_name":"Noarai",
       "union_ban_name":"নোয়ারাই"
    },
    {
       "_id":7406,
       "upazila_code":23,
       "union_code":85,
       "union_name":"Saidergaon",
       "union_ban_name":"সাইদারগাঁও"
    },
    {
       "_id":7407,
       "upazila_code":23,
       "union_code":90,
       "union_name":"Saila Afzalabad",
       "union_ban_name":"সাইলা আফজালাবাদ"
    },
    {
       "_id":7408,
       "upazila_code":23,
       "union_code":92,
       "union_name":"Sing Chapair",
       "union_ban_name":"সিং চাপাইর"
    },
    {
       "_id":7409,
       "upazila_code":23,
       "union_code":94,
       "union_name":"Uttar Surma",
       "union_ban_name":"উত্তর সুরমা"
    },
    {
       "_id":7410,
       "upazila_code":27,
       "union_code":11,
       "union_name":"Durgapasha",
       "union_ban_name":"দুর্গাপশা"
    },
    {
       "_id":7411,
       "upazila_code":27,
       "union_code":33,
       "union_name":"Joykalas",
       "union_ban_name":"জয়কালাস"
    },
    {
       "_id":7412,
       "upazila_code":27,
       "union_code":61,
       "union_name":"Patharia",
       "union_ban_name":"পাথারিয়া"
    },
    {
       "_id":7413,
       "upazila_code":27,
       "union_code":78,
       "union_name":"Shimulbak",
       "union_ban_name":"শিমুলবাক"
    },
    {
       "_id":7414,
       "upazila_code":27,
       "union_code":89,
       "union_name":"Paschim Birgaon",
       "union_ban_name":"পশ্চিম বীরগাঁও"
    },
    {
       "_id":7415,
       "upazila_code":27,
       "union_code":90,
       "union_name":"Paschim Pagla",
       "union_ban_name":"পশ্চিম পাগলা"
    },
    {
       "_id":7416,
       "upazila_code":27,
       "union_code":92,
       "union_name":"Purba Birgoan",
       "union_ban_name":"পূর্ব বীরগাঁও"
    },
    {
       "_id":7417,
       "upazila_code":27,
       "union_code":94,
       "union_name":"Purba Pagla",
       "union_ban_name":"পূর্ব পাগলা"
    },
    {
       "_id":7418,
       "upazila_code":29,
       "union_code":17,
       "union_name":"Bhati Para",
       "union_ban_name":"ভাটি পাড়া"
    },
    {
       "_id":7419,
       "upazila_code":29,
       "union_code":19,
       "union_name":"Charnar Char",
       "union_ban_name":"চার্নার চর"
    },
    {
       "_id":7420,
       "upazila_code":29,
       "union_code":28,
       "union_name":"Derai Sarmangal",
       "union_ban_name":"দেরাই সরমঙ্গল"
    },
    {
       "_id":7421,
       "upazila_code":29,
       "union_code":38,
       "union_name":"Jagaddal",
       "union_ban_name":"জগদ্দল"
    },
    {
       "_id":7423,
       "upazila_code":29,
       "union_code":57,
       "union_name":"Kulanj",
       "union_ban_name":"কুলঞ্জ"
    },
    {
       "_id":7424,
       "upazila_code":29,
       "union_code":66,
       "union_name":"Rafinagar",
       "union_ban_name":"রাফিনগর"
    },
    {
       "_id":7426,
       "upazila_code":29,
       "union_code":85,
       "union_name":"Taral",
       "union_ban_name":"তারাল"
    },
    {
       "_id":7427,
       "upazila_code":32,
       "union_code":21,
       "union_name":"Dakshin Sukhairrajapur",
       "union_ban_name":"দক্ষিণ সুখাইররাজপুর"
    },
    {
       "_id":7428,
       "upazila_code":32,
       "union_code":23,
       "union_name":"Chamardani",
       "union_ban_name":"চামারদানি"
    },
    {
       "_id":7429,
       "upazila_code":32,
       "union_code":35,
       "union_name":"Dakshin Bongshikunda",
       "union_ban_name":"দক্ষিণ বংশিকুন্ডা"
    },
    {
       "_id":7430,
       "upazila_code":32,
       "union_code":38,
       "union_name":"Dharmapasha",
       "union_ban_name":"ধর্মপাশা"
    },
    {
       "_id":7431,
       "upazila_code":32,
       "union_code":47,
       "union_name":"Joysree",
       "union_ban_name":"জয়শ্রী"
    },
    {
       "_id":7432,
       "upazila_code":32,
       "union_code":57,
       "union_name":"Madhyanagar",
       "union_ban_name":"মধ্যনগর"
    },
    {
       "_id":7433,
       "upazila_code":32,
       "union_code":66,
       "union_name":"Paikurati",
       "union_ban_name":"পাইকুরতী"
    },
    {
       "_id":7434,
       "upazila_code":32,
       "union_code":76,
       "union_name":"Selborash",
       "union_ban_name":"সেলবোরাস"
    },
    {
       "_id":7435,
       "upazila_code":32,
       "union_code":85,
       "union_name":"Uttar Sukhair Rajapur",
       "union_ban_name":"উত্তর সুখাইর রাজাপুর"
    },
    {
       "_id":7436,
       "upazila_code":32,
       "union_code":90,
       "union_name":"Uttar Bangshikunda",
       "union_ban_name":"উত্তর বংশিকুন্ড"
    },
    {
       "_id":7437,
       "upazila_code":33,
       "union_code":21,
       "union_name":"Bougla Bazar",
       "union_ban_name":"বগলা বাজার"
    },
    {
       "_id":7438,
       "upazila_code":33,
       "union_code":27,
       "union_name":"Dakshin Dowarabazar",
       "union_ban_name":"দক্ষিণ দোয়ারাবাজার"
    },
    {
       "_id":7439,
       "upazila_code":33,
       "union_code":32,
       "union_name":"Duhalia",
       "union_ban_name":"দুহালিয়া"
    },
    {
       "_id":7441,
       "upazila_code":33,
       "union_code":67,
       "union_name":"Mannargaon",
       "union_ban_name":"মান্নারগাঁও"
    },
    {
       "_id":7442,
       "upazila_code":33,
       "union_code":78,
       "union_name":"Pandergaon",
       "union_ban_name":"পান্ডারগাঁও"
    },
    {
       "_id":7443,
       "upazila_code":33,
       "union_code":81,
       "union_name":"Surma",
       "union_ban_name":"সুরমা"
    },
    {
       "_id":7445,
       "upazila_code":33,
       "union_code":92,
       "union_name":"Narsing Pur",
       "union_ban_name":"নার্সিং পুর"
    },
    {
       "_id":7446,
       "upazila_code":47,
       "union_code":17,
       "union_name":"Asharkandi",
       "union_ban_name":"আশারকান্দি"
    },
    {
       "_id":7447,
       "upazila_code":47,
       "union_code":19,
       "union_name":"Haldipur",
       "union_ban_name":"হলদিপুর"
    },
    {
       "_id":7448,
       "upazila_code":47,
       "union_code":38,
       "union_name":"Kalkalia",
       "union_ban_name":"কালকালিয়া"
    },
    {
       "_id":7450,
       "upazila_code":47,
       "union_code":57,
       "union_name":"Pailgaon",
       "union_ban_name":"পাইলগাঁও"
    },
    {
       "_id":7451,
       "upazila_code":47,
       "union_code":66,
       "union_name":"Patali",
       "union_ban_name":"পাটালি"
    },
    {
       "_id":7453,
       "upazila_code":47,
       "union_code":85,
       "union_name":"Syed Pur",
       "union_ban_name":"সৈয়দ পুর"
    },
    {
       "_id":7454,
       "upazila_code":50,
       "union_code":13,
       "union_name":"Beheli",
       "union_ban_name":"বেহেলি"
    },
    {
       "_id":7455,
       "upazila_code":50,
       "union_code":27,
       "union_name":"Vimkhali",
       "union_ban_name":"ভীমখালী"
    },
    {
       "_id":7456,
       "upazila_code":50,
       "union_code":54,
       "union_name":"Fenarbak",
       "union_ban_name":"ফেনারবাক"
    },
    {
       "_id":7457,
       "upazila_code":50,
       "union_code":67,
       "union_name":"Jamalganj",
       "union_ban_name":"জামালগঞ্জ"
    },
    {
       "_id":7458,
       "upazila_code":50,
       "union_code":81,
       "union_name":"Sachna Bazar",
       "union_ban_name":"সচনা বাজার"
    },
    {
       "_id":7461,
       "upazila_code":86,
       "union_code":71,
       "union_name":"Habibpur",
       "union_ban_name":"হাবিবপুর"
    },
    {
       "_id":7462,
       "upazila_code":86,
       "union_code":95,
       "union_name":"Sulla",
       "union_ban_name":"সুল্লা"
    },
    {
       "_id":7463,
       "upazila_code":89,
       "union_code":10,
       "union_name":"Aftabnagar",
       "union_ban_name":"আফতাবনগর"
    },
    {
       "_id":7464,
       "upazila_code":89,
       "union_code":27,
       "union_name":"Gourararang",
       "union_ban_name":"গৌরারং"
    },
    {
       "_id":7465,
       "upazila_code":89,
       "union_code":33,
       "union_name":"Jahangirnagar",
       "union_ban_name":"জাহাঙ্গীরনগর"
    },
    {
       "_id":7466,
       "upazila_code":89,
       "union_code":36,
       "union_name":"Katair",
       "union_ban_name":"কাটায়ার"
    },
    {
       "_id":7467,
       "upazila_code":89,
       "union_code":39,
       "union_name":"Lakshmansree",
       "union_ban_name":"লক্ষ্মণশ্রী"
    },
    {
       "_id":7469,
       "upazila_code":89,
       "union_code":55,
       "union_name":"Mollah Para",
       "union_ban_name":"মোল্লা পাড়া"
    },
    {
       "_id":7470,
       "upazila_code":89,
       "union_code":72,
       "union_name":"Rangar Char",
       "union_ban_name":"রঙ্গর চর"
    },
    {
       "_id":7474,
       "upazila_code":92,
       "union_code":43,
       "union_name":"Dakshin Baradal",
       "union_ban_name":"দক্ষিণ বড়দল"
    },
    {
       "_id":7475,
       "upazila_code":92,
       "union_code":64,
       "union_name":"Tahirpur",
       "union_ban_name":"তাহিরপুর"
    },
    {
       "_id":7476,
       "upazila_code":92,
       "union_code":71,
       "union_name":"Uttar Badaghat",
       "union_ban_name":"উত্তর বাদাঘাট"
    },
    {
       "_id":7478,
       "upazila_code":92,
       "union_code":92,
       "union_name":"Uttar Sreepur",
       "union_ban_name":"উত্তর শ্রীপুর"
    },
    {
       "_id":7479,
       "upazila_code":8,
       "union_code":11,
       "union_name":"Balaganj",
       "union_ban_name":"বালাগঞ্জ"
    },
    {
       "_id":7480,
       "upazila_code":8,
       "union_code":13,
       "union_name":"Boaljur Bazar",
       "union_ban_name":"বোয়ালজুর বাজার"
    },
    {
       "_id":7481,
       "upazila_code":8,
       "union_code":20,
       "union_name":"Burunga",
       "union_ban_name":"বুরুঙ্গা"
    },
    {
       "_id":7482,
       "upazila_code":8,
       "union_code":27,
       "union_name":"Dayamir",
       "union_ban_name":"দয়ামির"
    },
    {
       "_id":7483,
       "upazila_code":8,
       "union_code":33,
       "union_name":"Dewan Bazar",
       "union_ban_name":"দেওয়ান বাজার"
    },
    {
       "_id":7484,
       "upazila_code":8,
       "union_code":40,
       "union_name":"Goula Bazar",
       "union_ban_name":"গৌলা বাজার"
    },
    {
       "_id":7487,
       "upazila_code":8,
       "union_code":60,
       "union_name":"Paschim Gauripur",
       "union_ban_name":"পশ্চিম গৌরীপুর"
    },
    {
       "_id":7488,
       "upazila_code":8,
       "union_code":64,
       "union_name":"Paschim Pailanpur",
       "union_ban_name":"পশ্চিম পাইলনপুর"
    },
    {
       "_id":7489,
       "upazila_code":8,
       "union_code":67,
       "union_name":"Purba Pailanpur",
       "union_ban_name":"পূর্ব পাইলানপুর"
    },
    {
       "_id":7490,
       "upazila_code":8,
       "union_code":70,
       "union_name":"Purba Gauripur",
       "union_ban_name":"পূর্ব গৌরীপুর"
    },
    {
       "_id":7494,
       "upazila_code":17,
       "union_code":14,
       "union_name":"Mollahpur",
       "union_ban_name":"মোল্লাপুর"
    },
    {
       "_id":7495,
       "upazila_code":17,
       "union_code":25,
       "union_name":"Charkhai",
       "union_ban_name":"চরখাই"
    },
    {
       "_id":7496,
       "upazila_code":17,
       "union_code":34,
       "union_name":"Dobhag",
       "union_ban_name":"দোভগ"
    },
    {
       "_id":7497,
       "upazila_code":17,
       "union_code":43,
       "union_name":"Kurar Bazar",
       "union_ban_name":"কুরার বাজার"
    },
    {
       "_id":7498,
       "upazila_code":17,
       "union_code":51,
       "union_name":"Lauta",
       "union_ban_name":"লাউটা"
    },
    {
       "_id":7499,
       "upazila_code":17,
       "union_code":60,
       "union_name":"Mathiura",
       "union_ban_name":"মথিউরা"
    },
    {
       "_id":7500,
       "upazila_code":17,
       "union_code":77,
       "union_name":"Muria",
       "union_ban_name":"মুরিয়া"
    },
    {
       "_id":7501,
       "upazila_code":17,
       "union_code":86,
       "union_name":"Sheola",
       "union_ban_name":"শেওলা"
    },
    {
       "_id":7502,
       "upazila_code":17,
       "union_code":94,
       "union_name":"Tilpara",
       "union_ban_name":"তিলপাড়া"
    },
    {
       "_id":7503,
       "upazila_code":20,
       "union_code":10,
       "union_name":"Alankari",
       "union_ban_name":"অলঙ্কারি"
    },
    {
       "_id":7504,
       "upazila_code":20,
       "union_code":21,
       "union_name":"Bishwanath",
       "union_ban_name":"বিশ্বনাথ"
    },
    {
       "_id":7506,
       "upazila_code":20,
       "union_code":42,
       "union_name":"Deokalas",
       "union_ban_name":"দেওকালাস"
    },
    {
       "_id":7507,
       "upazila_code":20,
       "union_code":52,
       "union_name":"Dasghar",
       "union_ban_name":"দাশঘর"
    },
    {
       "_id":7508,
       "upazila_code":20,
       "union_code":63,
       "union_name":"Khazanchigaon",
       "union_ban_name":"খাজানচিগাঁও"
    },
    {
       "_id":7509,
       "upazila_code":20,
       "union_code":73,
       "union_name":"Lama Kazi",
       "union_ban_name":"লামা কাজী"
    },
    {
       "_id":7510,
       "upazila_code":20,
       "union_code":84,
       "union_name":"Rampasha",
       "union_ban_name":"রামপাশা"
    },
    {
       "_id":7511,
       "upazila_code":27,
       "union_code":11,
       "union_name":"Islampur Paschim",
       "union_ban_name":"ইসলামপুর পাছিম"
    },
    {
       "_id":7512,
       "upazila_code":27,
       "union_code":13,
       "union_name":"Islampur Purba",
       "union_ban_name":"ইসলামপুর পূর্ব"
    },
    {
       "_id":7513,
       "upazila_code":27,
       "union_code":17,
       "union_name":"Ishakalas",
       "union_ban_name":"ইশাকালস"
    },
    {
       "_id":7514,
       "upazila_code":27,
       "union_code":47,
       "union_name":"Ranikhai Dakshin",
       "union_ban_name":"রানিখাই দক্ষিণ"
    },
    {
       "_id":7515,
       "upazila_code":27,
       "union_code":57,
       "union_name":"Ranikhai Uttar",
       "union_ban_name":"রাণীখাই উত্তর"
    },
    {
       "_id":7516,
       "upazila_code":27,
       "union_code":71,
       "union_name":"Telikhal",
       "union_ban_name":"তেলিখাল"
    },
    {
       "_id":7518,
       "upazila_code":31,
       "union_code":36,
       "union_name":"Baraikandi",
       "union_ban_name":"বড়াইকান্দি"
    },
    {
       "_id":7520,
       "upazila_code":31,
       "union_code":45,
       "union_name":"Kuchai",
       "union_ban_name":"কুচাই"
    },
    {
       "_id":7521,
       "upazila_code":31,
       "union_code":50,
       "union_name":"Lala Bazar",
       "union_ban_name":"লালা বাজার"
    },
    {
       "_id":7522,
       "upazila_code":31,
       "union_code":60,
       "union_name":"Mogla Bazar",
       "union_ban_name":"মোগলা বাজার"
    },
    {
       "_id":7523,
       "upazila_code":31,
       "union_code":65,
       "union_name":"Mollargaon",
       "union_ban_name":"মোল্লারগাঁও"
    },
    {
       "_id":7524,
       "upazila_code":31,
       "union_code":75,
       "union_name":"Silam",
       "union_ban_name":"সিলাম"
    },
    {
       "_id":7525,
       "upazila_code":31,
       "union_code":85,
       "union_name":"Tetli",
       "union_ban_name":"তেতলি"
    },
    {
       "_id":7526,
       "upazila_code":35,
       "union_code":23,
       "union_name":"Fenchuganj",
       "union_ban_name":"ফেঞ্চুগঞ্জ"
    },
    {
       "_id":7527,
       "upazila_code":35,
       "union_code":47,
       "union_name":"Gilachhara",
       "union_ban_name":"গিলাছড়া"
    },
    {
       "_id":7528,
       "upazila_code":35,
       "union_code":71,
       "union_name":"Maijgaon",
       "union_ban_name":"মাইজগাঁও"
    },
    {
       "_id":7529,
       "upazila_code":38,
       "union_code":13,
       "union_name":"Amura",
       "union_ban_name":"আমুরা"
    },
    {
       "_id":7530,
       "upazila_code":38,
       "union_code":15,
       "union_name":"Bagha",
       "union_ban_name":"বাঘা"
    },
    {
       "_id":7532,
       "upazila_code":38,
       "union_code":20,
       "union_name":"Budbari Bazar",
       "union_ban_name":"বুদবাড়ী বাজার"
    },
    {
       "_id":7533,
       "upazila_code":38,
       "union_code":25,
       "union_name":"Dhaka Dakshin",
       "union_ban_name":"Dhakaাকা দক্ষিণ"
    },
    {
       "_id":7534,
       "upazila_code":38,
       "union_code":36,
       "union_name":"Shorifgonj",
       "union_ban_name":"শরিফগঞ্জ"
    },
    {
       "_id":7537,
       "upazila_code":38,
       "union_code":60,
       "union_name":"Lakshmi Pasha",
       "union_ban_name":"লক্ষ্মী পাশা"
    },
    {
       "_id":7538,
       "upazila_code":38,
       "union_code":69,
       "union_name":"Lakshanaband",
       "union_ban_name":"লক্ষণবন্ধ"
    },
    {
       "_id":7539,
       "upazila_code":38,
       "union_code":77,
       "union_name":"Uttar Bade Pasha",
       "union_ban_name":"উত্তর বদে পাশা"
    },
    {
       "_id":7540,
       "upazila_code":41,
       "union_code":10,
       "union_name":"Alirgaon",
       "union_ban_name":"আলিরগাঁও"
    },
    {
       "_id":7542,
       "upazila_code":41,
       "union_code":31,
       "union_name":"Purba Jaflong",
       "union_ban_name":"পূর্ব জাফলং"
    },
    {
       "_id":7543,
       "upazila_code":41,
       "union_code":42,
       "union_name":"Paschim Jaflong",
       "union_ban_name":"পাসচিম জাফলং"
    },
    {
       "_id":7545,
       "upazila_code":41,
       "union_code":63,
       "union_name":"Nandirgaon",
       "union_ban_name":"নন্দিরগাঁও"
    },
    {
       "_id":7546,
       "upazila_code":41,
       "union_code":73,
       "union_name":"Rustampur",
       "union_ban_name":"রুস্তমপুর"
    },
    {
       "_id":7547,
       "upazila_code":41,
       "union_code":84,
       "union_name":"Towakul",
       "union_ban_name":"তোয়াকুল"
    },
    {
       "_id":7548,
       "upazila_code":53,
       "union_code":27,
       "union_name":"Charikata",
       "union_ban_name":"চরিকাটা"
    },
    {
       "_id":7549,
       "upazila_code":53,
       "union_code":29,
       "union_name":"Chiknagul",
       "union_ban_name":"চিকনাগল"
    },
    {
       "_id":7552,
       "upazila_code":53,
       "union_code":52,
       "union_name":"Jaintapur",
       "union_ban_name":"জৈন্তাপুর"
    },
    {
       "_id":7553,
       "upazila_code":53,
       "union_code":63,
       "union_name":"Nijpat",
       "union_ban_name":"নিজপাট"
    },
    {
       "_id":7554,
       "upazila_code":59,
       "union_code":13,
       "union_name":"Bara Chatul",
       "union_ban_name":"বড় চাটুল"
    },
    {
       "_id":7555,
       "upazila_code":59,
       "union_code":19,
       "union_name":"Dakshin Banigram",
       "union_ban_name":"দক্ষিণ বনিগ্রাম"
    },
    {
       "_id":7556,
       "upazila_code":59,
       "union_code":38,
       "union_name":"Jhingrabari",
       "union_ban_name":"ঝিঙ্গাবাড়ী"
    },
    {
       "_id":7557,
       "upazila_code":59,
       "union_code":47,
       "union_name":"Kanaighat",
       "union_ban_name":"কানাইঘাট"
    },
    {
       "_id":7558,
       "upazila_code":59,
       "union_code":66,
       "union_name":"Paschim Lakshmip Rasad",
       "union_ban_name":"পাসছিম লক্ষ্মীপ রসাদ"
    },
    {
       "_id":7559,
       "upazila_code":59,
       "union_code":70,
       "union_name":"Satbak (Paschim Dighirpar)",
       "union_ban_name":"সাতবাক (পাসচিম দিঘিরপাড়)"
    },
    {
       "_id":7560,
       "upazila_code":59,
       "union_code":72,
       "union_name":"Purba Lakshmi Prasad",
       "union_ban_name":"পূর্ব লক্ষ্মী প্রসাদ"
    },
    {
       "_id":7561,
       "upazila_code":59,
       "union_code":76,
       "union_name":"Purba Dighirpar",
       "union_ban_name":"পূর্ব দিঘিরপাড়"
    },
    {
       "_id":7562,
       "upazila_code":59,
       "union_code":85,
       "union_name":"Rajaganj",
       "union_ban_name":"রাজগঞ্জ"
    },
    {
       "_id":7563,
       "upazila_code":62,
       "union_code":32,
       "union_name":"Hatkhola",
       "union_ban_name":"হাটখোলা"
    },
    {
       "_id":7565,
       "upazila_code":62,
       "union_code":38,
       "union_name":"Kandigaon",
       "union_ban_name":"কান্দিগাঁও"
    },
    {
       "_id":7566,
       "upazila_code":62,
       "union_code":40,
       "union_name":"Khadimnagar",
       "union_ban_name":"খাদিমনগর"
    },
    {
       "_id":7567,
       "upazila_code":62,
       "union_code":42,
       "union_name":"Khadim Para",
       "union_ban_name":"খাদিম পাড়া"
    },
    {
       "_id":7568,
       "upazila_code":62,
       "union_code":55,
       "union_name":"Mogalgaon",
       "union_ban_name":"মোগলগাঁও"
    },
    {
       "_id":7569,
       "upazila_code":62,
       "union_code":90,
       "union_name":"Tuker Bazar",
       "union_ban_name":"টুকার বাজার"
    },
    {
       "_id":7570,
       "upazila_code":62,
       "union_code":95,
       "union_name":"Tultikar",
       "union_ban_name":"টলটিকার"
    },
    {
       "_id":7571,
       "upazila_code":94,
       "union_code":15,
       "union_name":"Barahal",
       "union_ban_name":"বড়হাল"
    },
    {
       "_id":7572,
       "upazila_code":94,
       "union_code":19,
       "union_name":"Bara Thakuri",
       "union_ban_name":"বড় ঠাকুরি"
    },
    {
       "_id":7573,
       "upazila_code":94,
       "union_code":28,
       "union_name":"Birasree",
       "union_ban_name":"বিড়শ্রী"
    },
    {
       "_id":7574,
       "upazila_code":94,
       "union_code":38,
       "union_name":"Kashkanakpur",
       "union_ban_name":"কাশকনাকপুর"
    },
    {
       "_id":7575,
       "upazila_code":94,
       "union_code":47,
       "union_name":"Kajalshar",
       "union_ban_name":"কাজলশার"
    },
    {
       "_id":7576,
       "upazila_code":94,
       "union_code":57,
       "union_name":"Kholachhara",
       "union_ban_name":"খোলাছড়া"
    },
    {
       "_id":7579,
       "upazila_code":94,
       "union_code":85,
       "union_name":"Zakiganj",
       "union_ban_name":"জকিগঞ্জ"
    },
    {
       "_id":7581,
       "upazila_code":9,
       "union_code":35,
       "union_name":"Fulki",
       "union_ban_name":"ফুলকি"
    },
    {
       "_id":7582,
       "upazila_code":9,
       "union_code":47,
       "union_name":"Habla",
       "union_ban_name":"হাবলা"
    },
    {
       "_id":7584,
       "upazila_code":9,
       "union_code":71,
       "union_name":"Kashil",
       "union_ban_name":"কাশিল"
    },
    {
       "_id":7585,
       "upazila_code":9,
       "union_code":83,
       "union_name":"Kaoaljani",
       "union_ban_name":"কওলজানি"
    },
    {
       "_id":7586,
       "upazila_code":19,
       "union_code":13,
       "union_name":"Arjuna",
       "union_ban_name":"অর্জুন"
    },
    {
       "_id":7587,
       "upazila_code":19,
       "union_code":27,
       "union_name":"Aloa",
       "union_ban_name":"আলোয়া"
    },
    {
       "_id":7588,
       "upazila_code":19,
       "union_code":40,
       "union_name":"Falda",
       "union_ban_name":"ফালদা"
    },
    {
       "_id":7589,
       "upazila_code":19,
       "union_code":54,
       "union_name":"Gabsara",
       "union_ban_name":"গাবসর"
    },
    {
       "_id":7590,
       "upazila_code":19,
       "union_code":67,
       "union_name":"Gobindasi",
       "union_ban_name":"গোবিন্দাসী"
    },
    {
       "_id":7591,
       "upazila_code":19,
       "union_code":81,
       "union_name":"Nikrail",
       "union_ban_name":"নিকরাইল"
    },
    {
       "_id":7592,
       "upazila_code":23,
       "union_code":11,
       "union_name":"Atia",
       "union_ban_name":"এতিয়া"
    },
    {
       "_id":7593,
       "upazila_code":23,
       "union_code":23,
       "union_name":"Delduar",
       "union_ban_name":"দেলদুয়ার"
    },
    {
       "_id":7594,
       "upazila_code":23,
       "union_code":35,
       "union_name":"Deoli",
       "union_ban_name":"দেওলি"
    },
    {
       "_id":7595,
       "upazila_code":23,
       "union_code":41,
       "union_name":"Dubail",
       "union_ban_name":"দুবাইল"
    },
    {
       "_id":7596,
       "upazila_code":23,
       "union_code":47,
       "union_name":"Elasin",
       "union_ban_name":"এলাসিন"
    },
    {
       "_id":7597,
       "upazila_code":23,
       "union_code":59,
       "union_name":"Fazilhati",
       "union_ban_name":"ফাজিলহাটি"
    },
    {
       "_id":7598,
       "upazila_code":23,
       "union_code":65,
       "union_name":"Lauhati",
       "union_ban_name":"লাউহাটি"
    },
    {
       "_id":7599,
       "upazila_code":23,
       "union_code":71,
       "union_name":"Pathrail",
       "union_ban_name":"পাথরাইল"
    },
    {
       "_id":7600,
       "upazila_code":25,
       "union_code":21,
       "union_name":"Balibhadra",
       "union_ban_name":"বালিভদ্র"
    },
    {
       "_id":7603,
       "upazila_code":25,
       "union_code":47,
       "union_name":"Dhanbari",
       "union_ban_name":"ধনবাড়ী"
    },
    {
       "_id":7605,
       "upazila_code":25,
       "union_code":80,
       "union_name":"Musuddi",
       "union_ban_name":"মুসুদ্দি"
    },
    {
       "_id":7606,
       "upazila_code":25,
       "union_code":85,
       "union_name":"Paiska",
       "union_ban_name":"পাইস্কা"
    },
    {
       "_id":7607,
       "upazila_code":28,
       "union_code":15,
       "union_name":"Anehola",
       "union_ban_name":"অ্যানহোলা"
    },
    {
       "_id":7608,
       "upazila_code":28,
       "union_code":17,
       "union_name":"Deulabari",
       "union_ban_name":"দেউলাবাড়ি"
    },
    {
       "_id":7610,
       "upazila_code":28,
       "union_code":34,
       "union_name":"Dhala Para",
       "union_ban_name":"ধলা পাড়া"
    },
    {
       "_id":7611,
       "upazila_code":28,
       "union_code":43,
       "union_name":"Digalkandi",
       "union_ban_name":"দিগলকান্দি"
    },
    {
       "_id":7612,
       "upazila_code":28,
       "union_code":51,
       "union_name":"Digar",
       "union_ban_name":"দিগার"
    },
    {
       "_id":7613,
       "upazila_code":28,
       "union_code":60,
       "union_name":"Ghatail",
       "union_ban_name":"ঘাটাইল"
    },
    {
       "_id":7614,
       "upazila_code":28,
       "union_code":69,
       "union_name":"Jamuria",
       "union_ban_name":"জামুরিয়া"
    },
    {
       "_id":7615,
       "upazila_code":28,
       "union_code":77,
       "union_name":"Lakher Para",
       "union_ban_name":"লখের পাড়া"
    },
    {
       "_id":7617,
       "upazila_code":28,
       "union_code":94,
       "union_name":"Sandhanpur",
       "union_ban_name":"সন্ধনপুর"
    },
    {
       "_id":7618,
       "upazila_code":38,
       "union_code":17,
       "union_name":"Alamnagar",
       "union_ban_name":"আলমনগর"
    },
    {
       "_id":7619,
       "upazila_code":38,
       "union_code":21,
       "union_name":"Dhopakandi",
       "union_ban_name":"ধোপাকান্দি"
    },
    {
       "_id":7620,
       "upazila_code":38,
       "union_code":58,
       "union_name":"Hadira",
       "union_ban_name":"হাদিরা"
    },
    {
       "_id":7621,
       "upazila_code":38,
       "union_code":65,
       "union_name":"Hemnagar",
       "union_ban_name":"হেমনগর"
    },
    {
       "_id":7624,
       "upazila_code":38,
       "union_code":94,
       "union_name":"Nagda Simla",
       "union_ban_name":"নাগদা সিমলা"
    },
    {
       "_id":7626,
       "upazila_code":47,
       "union_code":14,
       "union_name":"Bangra",
       "union_ban_name":"বঙ্গরা"
    },
    {
       "_id":7627,
       "upazila_code":47,
       "union_code":21,
       "union_name":"Bir Basunda",
       "union_ban_name":"বীর বসুন্দা"
    },
    {
       "_id":7628,
       "upazila_code":47,
       "union_code":25,
       "union_name":"Dashkia",
       "union_ban_name":"দশকিয়া"
    },
    {
       "_id":7630,
       "upazila_code":47,
       "union_code":36,
       "union_name":"Elenga",
       "union_ban_name":"এলেঙ্গা"
    },
    {
       "_id":7631,
       "upazila_code":47,
       "union_code":43,
       "union_name":"Gohaliabari",
       "union_ban_name":"গোহালিবাড়ি"
    },
    {
       "_id":7632,
       "upazila_code":47,
       "union_code":58,
       "union_name":"Kok Dahara",
       "union_ban_name":"কোক দাহার"
    },
    {
       "_id":7633,
       "upazila_code":47,
       "union_code":65,
       "union_name":"Nagbari",
       "union_ban_name":"নাগবাড়ি"
    },
    {
       "_id":7635,
       "upazila_code":47,
       "union_code":80,
       "union_name":"Paikara",
       "union_ban_name":"পাইকার"
    },
    {
       "_id":7636,
       "upazila_code":47,
       "union_code":83,
       "union_name":"Parki",
       "union_ban_name":"পারকি"
    },
    {
       "_id":7637,
       "upazila_code":47,
       "union_code":87,
       "union_name":"Salla",
       "union_ban_name":"সাল্লা"
    },
    {
       "_id":7638,
       "upazila_code":47,
       "union_code":94,
       "union_name":"Sahadebpur",
       "union_ban_name":"সহদেবপুর"
    },
    {
       "_id":7640,
       "upazila_code":57,
       "union_code":24,
       "union_name":"Arankhola",
       "union_ban_name":"আরানখোলা"
    },
    {
       "_id":7641,
       "upazila_code":57,
       "union_code":28,
       "union_name":"Ausnara",
       "union_ban_name":"আউশনারা"
    },
    {
       "_id":7643,
       "upazila_code":57,
       "union_code":78,
       "union_name":"Mirzabari",
       "union_ban_name":"মির্জাবাড়ি"
    },
    {
       "_id":7644,
       "upazila_code":57,
       "union_code":92,
       "union_name":"Solakuri",
       "union_ban_name":"সোলাকুরি"
    },
    {
       "_id":7645,
       "upazila_code":66,
       "union_code":13,
       "union_name":"Ajgana",
       "union_ban_name":"আজগানা"
    },
    {
       "_id":7646,
       "upazila_code":66,
       "union_code":15,
       "union_name":"Anaitara",
       "union_ban_name":"আনাইতারা"
    },
    {
       "_id":7647,
       "upazila_code":66,
       "union_code":23,
       "union_name":"Bahuria",
       "union_ban_name":"বহুরিয়া"
    },
    {
       "_id":7648,
       "upazila_code":66,
       "union_code":31,
       "union_name":"Banail",
       "union_ban_name":"বনাইল"
    },
    {
       "_id":7649,
       "upazila_code":66,
       "union_code":34,
       "union_name":"Banshtail",
       "union_ban_name":"বাঁশটাইল"
    },
    {
       "_id":7650,
       "upazila_code":66,
       "union_code":36,
       "union_name":"Bhaora",
       "union_ban_name":"ভাওড়া"
    },
    {
       "_id":7653,
       "upazila_code":66,
       "union_code":55,
       "union_name":"Gorai",
       "union_ban_name":"গড়াই"
    },
    {
       "_id":7654,
       "upazila_code":66,
       "union_code":63,
       "union_name":"Jamurki",
       "union_ban_name":"জামুরকি"
    },
    {
       "_id":7656,
       "upazila_code":66,
       "union_code":71,
       "union_name":"Mahera",
       "union_ban_name":"মাহেরা"
    },
    {
       "_id":7657,
       "upazila_code":66,
       "union_code":87,
       "union_name":"Tarafpur",
       "union_ban_name":"তারাফপুর"
    },
    {
       "_id":7658,
       "upazila_code":66,
       "union_code":94,
       "union_name":"Uarsi",
       "union_ban_name":"উরসী"
    },
    {
       "_id":7659,
       "upazila_code":76,
       "union_code":10,
       "union_name":"Bekra",
       "union_ban_name":"বেকড়া"
    },
    {
       "_id":7660,
       "upazila_code":76,
       "union_code":12,
       "union_name":"Bhadra",
       "union_ban_name":"ভাদ্র"
    },
    {
       "_id":7661,
       "upazila_code":76,
       "union_code":14,
       "union_name":"Bhara",
       "union_ban_name":"ভারা"
    },
    {
       "_id":7662,
       "upazila_code":76,
       "union_code":21,
       "union_name":"Duptiair",
       "union_ban_name":"দপ্তিয়র"
    },
    {
       "_id":7663,
       "upazila_code":76,
       "union_code":36,
       "union_name":"Dhubaria",
       "union_ban_name":"ধুবারিয়া"
    },
    {
       "_id":7664,
       "upazila_code":76,
       "union_code":43,
       "union_name":"Gayhata",
       "union_ban_name":"গাইহাটা"
    },
    {
       "_id":7665,
       "upazila_code":76,
       "union_code":58,
       "union_name":"Mamudnagar",
       "union_ban_name":"মামুদনগর"
    },
    {
       "_id":7666,
       "upazila_code":76,
       "union_code":65,
       "union_name":"Mokhna",
       "union_ban_name":"মোখনা"
    },
    {
       "_id":7667,
       "upazila_code":76,
       "union_code":73,
       "union_name":"Nagarpur",
       "union_ban_name":"নগরপুর"
    },
    {
       "_id":7668,
       "upazila_code":76,
       "union_code":80,
       "union_name":"Pakutia",
       "union_ban_name":"পাকুটিয়া"
    },
    {
       "_id":7669,
       "upazila_code":76,
       "union_code":87,
       "union_name":"Sahabatpur",
       "union_ban_name":"সাহাবতপুর"
    },
    {
       "_id":7671,
       "upazila_code":85,
       "union_code":13,
       "union_name":"Baheratail",
       "union_ban_name":"বহেরাতাইল"
    },
    {
       "_id":7673,
       "upazila_code":85,
       "union_code":40,
       "union_name":"Hatibandha",
       "union_ban_name":"হাতীবান্ধা"
    },
    {
       "_id":7675,
       "upazila_code":85,
       "union_code":67,
       "union_name":"Kakrajan",
       "union_ban_name":"কাকরজন"
    },
    {
       "_id":7677,
       "upazila_code":95,
       "union_code":37,
       "union_name":"Baghil",
       "union_ban_name":"বাঘিল"
    },
    {
       "_id":7678,
       "upazila_code":95,
       "union_code":39,
       "union_name":"Danya",
       "union_ban_name":"দানিয়া"
    },
    {
       "_id":7680,
       "upazila_code":95,
       "union_code":47,
       "union_name":"Gharinda",
       "union_ban_name":"ঘরিন্দা"
    },
    {
       "_id":7681,
       "upazila_code":95,
       "union_code":53,
       "union_name":"Hugra",
       "union_ban_name":"হুগ্রা"
    },
    {
       "_id":7682,
       "upazila_code":95,
       "union_code":59,
       "union_name":"Karatia",
       "union_ban_name":"করটিয়া"
    },
    {
       "_id":7683,
       "upazila_code":95,
       "union_code":65,
       "union_name":"Katuli",
       "union_ban_name":"কাতুলি"
    },
    {
       "_id":7684,
       "upazila_code":95,
       "union_code":71,
       "union_name":"Kakua",
       "union_ban_name":"কাকুয়া"
    },
    {
       "_id":7685,
       "upazila_code":95,
       "union_code":77,
       "union_name":"Magra",
       "union_ban_name":"মাগরা"
    },
    {
       "_id":7686,
       "upazila_code":95,
       "union_code":80,
       "union_name":"Mahamudnagar",
       "union_ban_name":"মহামুদনগর"
    },
    {
       "_id":7688,
       "upazila_code":95,
       "union_code":90,
       "union_name":"Porabari",
       "union_ban_name":"পোড়াবাড়ি"
    },
    {
       "_id":7689,
       "upazila_code":8,
       "union_code":10,
       "union_name":"Amjankhore",
       "union_ban_name":"আমজনখোর"
    },
    {
       "_id":7690,
       "upazila_code":8,
       "union_code":21,
       "union_name":"Barabari",
       "union_ban_name":"বড়বাড়ি"
    },
    {
       "_id":7691,
       "upazila_code":8,
       "union_code":31,
       "union_name":"Bara Palashbari",
       "union_ban_name":"বড় পলাশবাড়ী"
    },
    {
       "_id":7692,
       "upazila_code":8,
       "union_code":42,
       "union_name":"Bhanor",
       "union_ban_name":"ভানোর"
    },
    {
       "_id":7693,
       "upazila_code":8,
       "union_code":52,
       "union_name":"Charol",
       "union_ban_name":"চারোল"
    },
    {
       "_id":7694,
       "upazila_code":8,
       "union_code":63,
       "union_name":"Dhantala",
       "union_ban_name":"ধনতলা"
    },
    {
       "_id":7695,
       "upazila_code":8,
       "union_code":73,
       "union_name":"Duosuo",
       "union_ban_name":"ডুওসুও"
    },
    {
       "_id":7696,
       "upazila_code":8,
       "union_code":84,
       "union_name":"Paria",
       "union_ban_name":"পারিয়া"
    },
    {
       "_id":7697,
       "upazila_code":51,
       "union_code":13,
       "union_name":"Amgaon",
       "union_ban_name":"আমগাঁও"
    },
    {
       "_id":7698,
       "upazila_code":51,
       "union_code":27,
       "union_name":"Bakua",
       "union_ban_name":"বাকুয়া"
    },
    {
       "_id":7699,
       "upazila_code":51,
       "union_code":40,
       "union_name":"Bhaturia",
       "union_ban_name":"ভাতুরিয়া"
    },
    {
       "_id":7700,
       "upazila_code":51,
       "union_code":54,
       "union_name":"Dangi Para",
       "union_ban_name":"ডাঙ্গি পাড়া"
    },
    {
       "_id":7701,
       "upazila_code":51,
       "union_code":67,
       "union_name":"Gedura",
       "union_ban_name":"গেদুরা"
    },
    {
       "_id":7703,
       "upazila_code":82,
       "union_code":23,
       "union_name":"Bairchuna",
       "union_ban_name":"বৈয়ারচুনা"
    },
    {
       "_id":7704,
       "upazila_code":82,
       "union_code":25,
       "union_name":"Bhomradaha",
       "union_ban_name":"ভোমরাদহ"
    },
    {
       "_id":7707,
       "upazila_code":82,
       "union_code":51,
       "union_name":"Jabarhat",
       "union_ban_name":"জবারহাট"
    },
    {
       "_id":7708,
       "upazila_code":82,
       "union_code":60,
       "union_name":"Khangaon",
       "union_ban_name":"খানগাঁও"
    },
    {
       "_id":7709,
       "upazila_code":82,
       "union_code":69,
       "union_name":"Kusha Raniganj",
       "union_ban_name":"কুশা রানীগঞ্জ"
    },
    {
       "_id":7712,
       "upazila_code":82,
       "union_code":94,
       "union_name":"Sengaon",
       "union_ban_name":"সেনগাঁও"
    },
    {
       "_id":7713,
       "upazila_code":86,
       "union_code":15,
       "union_name":"Bachor",
       "union_ban_name":"বাচোর"
    },
    {
       "_id":7714,
       "upazila_code":86,
       "union_code":31,
       "union_name":"Dharmagarh",
       "union_ban_name":"ধর্মগড়"
    },
    {
       "_id":7715,
       "upazila_code":86,
       "union_code":47,
       "union_name":"Hossain Gaon",
       "union_ban_name":"হোসেন গাওন"
    },
    {
       "_id":7717,
       "upazila_code":86,
       "union_code":63,
       "union_name":"Lehemba",
       "union_ban_name":"লেহেম্বা"
    },
    {
       "_id":7718,
       "upazila_code":86,
       "union_code":71,
       "union_name":"Nonduar",
       "union_ban_name":"ননদুয়ার"
    },
    {
       "_id":7719,
       "upazila_code":86,
       "union_code":79,
       "union_name":"Nekmarad",
       "union_ban_name":"নেকমারাদ"
    },
    {
       "_id":7720,
       "upazila_code":86,
       "union_code":87,
       "union_name":"Rator",
       "union_ban_name":"রেটার"
    },
    {
       "_id":7721,
       "upazila_code":94,
       "union_code":13,
       "union_name":"Akhanagar",
       "union_ban_name":"আখানগর"
    },
    {
       "_id":7722,
       "upazila_code":94,
       "union_code":14,
       "union_name":"Akcha",
       "union_ban_name":"আচ্ছা"
    },
    {
       "_id":7724,
       "upazila_code":94,
       "union_code":17,
       "union_name":"Begunbari",
       "union_ban_name":"বেগুনবাড়ি"
    },
    {
       "_id":7727,
       "upazila_code":94,
       "union_code":31,
       "union_name":"Chilarang",
       "union_ban_name":"চিলারং"
    },
    {
       "_id":7728,
       "upazila_code":94,
       "union_code":36,
       "union_name":"Debipur",
       "union_ban_name":"দেবিপুর"
    },
    {
       "_id":7729,
       "upazila_code":94,
       "union_code":42,
       "union_name":"Gareya",
       "union_ban_name":"গারেয়া"
    },
    {
       "_id":7733,
       "upazila_code":94,
       "union_code":63,
       "union_name":"Nargun",
       "union_ban_name":"নারগুন"
    },
    {
       "_id":7734,
       "upazila_code":94,
       "union_code":68,
       "union_name":"Rahimanpur",
       "union_ban_name":"রহিমপুর"
    },
    {
       "_id":7736,
       "upazila_code":94,
       "union_code":79,
       "union_name":"Rajagaon",
       "union_ban_name":"রাজাগাঁও"
    },
    {
       "_id":7737,
       "upazila_code":94,
       "union_code":84,
       "union_name":"Ruhea",
       "union_ban_name":"রুহিয়া"
    },
    {
       "_id":7738,
       "upazila_code":94,
       "union_code":89,
       "union_name":"Salandar",
       "union_ban_name":"সালানদার"
    },
    {
       "_id":7739,
       "upazila_code":94,
       "union_code":94,
       "union_name":"Sukhanpukhari",
       "union_ban_name":"সুখনপুখারী"
    }
 ]