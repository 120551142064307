export const subDistricts = [
    {
       "_id":1,
       "upazila_code":8,
       "upazila_name":"Bagerhat Sadar",
       "upazila_ban":"বাগেরহাট সদর",
       "union_code":1,
       "district_code":1
    },
    {
       "_id":10,
       "upazila_code":58,
       "upazila_name":"Mongla",
       "upazila_ban":"মংলা",
       "union_code":2,
       "district_code":1
    },
    {
       "_id":18,
       "upazila_code":60,
       "upazila_name":"Morrelganj",
       "upazila_ban":"মোড়েলগঞ্জ",
       "union_code":1,
       "district_code":1
    },
    {
       "_id":27,
       "upazila_code":14,
       "upazila_name":"Bandarban Sadar",
       "upazila_ban":"বান্দরবান সদর",
       "union_code":1,
       "district_code":3
    },
    {
       "_id":36,
       "upazila_code":51,
       "upazila_name":"Lama",
       "upazila_ban":"লামা",
       "union_code":1,
       "district_code":3
    },
    {
       "_id":45,
       "upazila_code":9,
       "upazila_name":"Amtali",
       "upazila_ban":"আমতলী",
       "union_code":1,
       "district_code":4
    },
    {
       "_id":54,
       "upazila_code":28,
       "upazila_name":"Barguna Sadar",
       "upazila_ban":"বরগুনা সদর",
       "union_code":1,
       "district_code":4
    },
    {
       "_id":63,
       "upazila_code":47,
       "upazila_name":"Betagi",
       "upazila_ban":"বেতাগী",
       "union_code":1,
       "district_code":4
    },
    {
       "_id":72,
       "upazila_code":85,
       "upazila_name":"Patharghata",
       "upazila_ban":"পাথরঘাটা",
       "union_code":1,
       "district_code":4
    },
    {
       "_id":81,
       "upazila_code":7,
       "upazila_name":"Bakerganj",
       "upazila_ban":"বাকেরগঞ্জ",
       "union_code":1,
       "district_code":6
    },
    {
       "_id":90,
       "upazila_code":10,
       "upazila_name":"Banari Para",
       "upazila_ban":"বানারী পাড়া",
       "union_code":1,
       "district_code":6
    },
    {
       "_id":99,
       "upazila_code":32,
       "upazila_name":"Gaurnadi",
       "upazila_ban":"গৌরনদী",
       "union_code":1,
       "district_code":6
    },
    {
       "_id":108,
       "upazila_code":51,
       "upazila_name":"Barisal Sadar",
       "upazila_ban":"বরিশাল সদর",
       "union_code":1,
       "district_code":6
    },
    {
       "_id":138,
       "upazila_code":62,
       "upazila_name":"Mehendiganj",
       "upazila_ban":"মেহেন্দিগঞ্জ",
       "union_code":1,
       "district_code":6
    },
    {
       "_id":147,
       "upazila_code":69,
       "upazila_name":"Muladi",
       "upazila_ban":"মুলাদী",
       "union_code":1,
       "district_code":6
    },
    {
       "_id":156,
       "upazila_code":18,
       "upazila_name":"Bhola Sadar",
       "upazila_ban":"ভোলা সদর",
       "union_code":1,
       "district_code":9
    },
    {
       "_id":165,
       "upazila_code":21,
       "upazila_name":"Burhanuddin",
       "upazila_ban":"বুরহানউদ্দিন",
       "union_code":1,
       "district_code":9
    },
    {
       "_id":174,
       "upazila_code":25,
       "upazila_name":"Char Fasson",
       "upazila_ban":"চর ফ্যাসন",
       "union_code":1,
       "district_code":9
    },
    {
       "_id":183,
       "upazila_code":29,
       "upazila_name":"Daulat Khan",
       "upazila_ban":"দৌলত খান",
       "union_code":1,
       "district_code":9
    },
    {
       "_id":192,
       "upazila_code":54,
       "upazila_name":"Lalmohan",
       "upazila_ban":"লালমোহন",
       "union_code":1,
       "district_code":9
    },
    {
       "_id":201,
       "upazila_code":6,
       "upazila_name":"Adamdighi",
       "upazila_ban":"আদমদীঘি",
       "union_code":1,
       "district_code":10
    },
    {
       "_id":210,
       "upazila_code":20,
       "upazila_name":"Bogra Sadar",
       "upazila_ban":"বগুড়া সদর",
       "union_code":1,
       "district_code":10
    },
    {
       "_id":230,
       "upazila_code":27,
       "upazila_name":"Dhunat",
       "upazila_ban":"ধুনট",
       "union_code":1,
       "district_code":10
    },
    {
       "_id":239,
       "upazila_code":33,
       "upazila_name":"Dhupchanchia",
       "upazila_ban":"দুপচাঁচিয়া",
       "union_code":1,
       "district_code":10
    },
    {
       "_id":248,
       "upazila_code":40,
       "upazila_name":"Gabtali",
       "upazila_ban":"গাবতলী",
       "union_code":1,
       "district_code":10
    },
    {
       "_id":257,
       "upazila_code":54,
       "upazila_name":"Kahaloo",
       "upazila_ban":"কাহালু",
       "union_code":1,
       "district_code":10
    },
    {
       "_id":266,
       "upazila_code":67,
       "upazila_name":"Nandigram",
       "upazila_ban":"নন্দীগ্রাম",
       "union_code":1,
       "district_code":10
    },
    {
       "_id":275,
       "upazila_code":81,
       "upazila_name":"Sariakandi",
       "upazila_ban":"সারিয়াকান্দি",
       "union_code":1,
       "district_code":10
    },
    {
       "_id":284,
       "upazila_code":85,
       "upazila_name":"Shajahanpur",
       "upazila_ban":"শাহজাহানপুর",
       "union_code":13,
       "district_code":10
    },
    {
       "_id":287,
       "upazila_code":88,
       "upazila_name":"Sherpur",
       "upazila_ban":"শেরপুর",
       "union_code":1,
       "district_code":10
    },
    {
       "_id":296,
       "upazila_code":94,
       "upazila_name":"Shibganj",
       "upazila_ban":"শিবগঞ্জ",
       "union_code":1,
       "district_code":10
    },
    {
       "_id":305,
       "upazila_code":95,
       "upazila_name":"Sonatola",
       "upazila_ban":"সোনাতোলা",
       "union_code":1,
       "district_code":10
    },
    {
       "_id":314,
       "upazila_code":2,
       "upazila_name":"Akhaura",
       "upazila_ban":"আখাউড়া",
       "union_code":1,
       "district_code":12
    },
    {
       "_id":323,
       "upazila_code":13,
       "upazila_name":"Brahmanbaria Sadar",
       "upazila_ban":"ব্রাহ্মণবাড়িয়া সদর",
       "union_code":1,
       "district_code":12
    },
    {
       "_id":335,
       "upazila_code":63,
       "upazila_name":"Kasba",
       "upazila_ban":"কাসবা",
       "union_code":1,
       "district_code":12
    },
    {
       "_id":344,
       "upazila_code":85,
       "upazila_name":"Nabinagar",
       "upazila_ban":"নবীনগর",
       "union_code":1,
       "district_code":12
    },
    {
       "_id":353,
       "upazila_code":22,
       "upazila_name":"Chandpur Sadar",
       "upazila_ban":"চাঁদপুর সদর",
       "union_code":1,
       "district_code":13
    },
    {
       "_id":368,
       "upazila_code":45,
       "upazila_name":"Faridganj",
       "upazila_ban":"ফরিদগঞ্জ",
       "union_code":1,
       "district_code":13
    },
    {
       "_id":377,
       "upazila_code":49,
       "upazila_name":"Hajiganj",
       "upazila_ban":"হাজীগঞ্জ",
       "union_code":1,
       "district_code":13
    },
    {
       "_id":389,
       "upazila_code":58,
       "upazila_name":"Kachua",
       "upazila_ban":"কাচুয়া",
       "union_code":1,
       "district_code":13
    },
    {
       "_id":398,
       "upazila_code":76,
       "upazila_name":"Matlab",
       "upazila_ban":"মতলব",
       "union_code":1,
       "district_code":13
    },
    {
       "_id":407,
       "upazila_code":79,
       "upazila_name":"Matlab Uttar",
       "upazila_ban":"মতলব উত্তর",
       "union_code":1,
       "district_code":13
    },
    {
       "_id":416,
       "upazila_code":95,
       "upazila_name":"Shahrasti",
       "upazila_ban":"শাহরাস্তি",
       "union_code":1,
       "district_code":13
    },
    {
       "_id":425,
       "upazila_code":37,
       "upazila_name":"Gomastapur",
       "upazila_ban":"গোমস্তাপুর",
       "union_code":1,
       "district_code":70
    },
    {
       "_id":434,
       "upazila_code":56,
       "upazila_name":"Nachole",
       "upazila_ban":"নাচোল",
       "union_code":1,
       "district_code":70
    },
    {
       "_id":443,
       "upazila_code":66,
       "upazila_name":"Chapai Nawabganj Sadar",
       "upazila_ban":"চাঁপাই নবাবগঞ্জ সদর",
       "union_code":1,
       "district_code":70
    },
    {
       "_id":467,
       "upazila_code":6,
       "upazila_name":"Bayejid Bostami Thana",
       "upazila_ban":"বায়েজিদ বোস্তামি থানা",
       "union_code":1,
       "district_code":15
    },
    {
       "_id":470,
       "upazila_code":8,
       "upazila_name":"Banshkhali",
       "upazila_ban":"বাঁশখালী",
       "union_code":1,
       "district_code":15
    },
    {
       "_id":479,
       "upazila_code":10,
       "upazila_name":"Bakalia Thana",
       "upazila_ban":"বাকালিয়া থানা",
       "union_code":17,
       "district_code":15
    },
    {
       "_id":483,
       "upazila_code":18,
       "upazila_name":"Chandanaish",
       "upazila_ban":"চান্দনাইশ",
       "union_code":1,
       "district_code":15
    },
    {
       "_id":492,
       "upazila_code":19,
       "upazila_name":"Chandgaon Thana",
       "upazila_ban":"চাঁদগাঁও থানা",
       "union_code":4,
       "district_code":15
    },
    {
       "_id":495,
       "upazila_code":20,
       "upazila_name":"Chittagong Port Thana",
       "upazila_ban":"চট্টগ্রাম বন্দর থানা",
       "union_code":37,
       "district_code":15
    },
    {
       "_id":498,
       "upazila_code":28,
       "upazila_name":"Double Mooring Thana",
       "upazila_ban":"ডাবল মুরিং থানা",
       "union_code":23,
       "district_code":15
    },
    {
       "_id":505,
       "upazila_code":35,
       "upazila_name":"Halishahar Thana",
       "upazila_ban":"হালিশহর থানা",
       "union_code":11,
       "district_code":15
    },
    {
       "_id":509,
       "upazila_code":41,
       "upazila_name":"Kotwali Thana",
       "upazila_ban":"কোতোয়ালি থানা",
       "union_code":15,
       "district_code":15
    },
    {
       "_id":519,
       "upazila_code":43,
       "upazila_name":"Khulshi Thana",
       "upazila_ban":"খুলশী থানা",
       "union_code":8,
       "district_code":15
    },
    {
       "_id":523,
       "upazila_code":53,
       "upazila_name":"Mirsharai",
       "upazila_ban":"মীরসরাই",
       "union_code":1,
       "district_code":15
    },
    {
       "_id":541,
       "upazila_code":55,
       "upazila_name":"Pahartali Thana",
       "upazila_ban":"পাহাড়তলী থানা",
       "union_code":10,
       "district_code":15
    },
    {
       "_id":544,
       "upazila_code":57,
       "upazila_name":"Panchlaish Thana",
       "upazila_ban":"পাঁচলাইশ থানা",
       "union_code":7,
       "district_code":15
    },
    {
       "_id":547,
       "upazila_code":61,
       "upazila_name":"Patiya",
       "upazila_ban":"পটিয়া",
       "union_code":1,
       "district_code":15
    },
    {
       "_id":556,
       "upazila_code":65,
       "upazila_name":"Patenga Thana",
       "upazila_ban":"পতেঙ্গা থানা",
       "union_code":40,
       "district_code":15
    },
    {
       "_id":558,
       "upazila_code":70,
       "upazila_name":"Rangunia",
       "upazila_ban":"রাঙ্গুনিয়া",
       "union_code":1,
       "district_code":15
    },
    {
       "_id":567,
       "upazila_code":74,
       "upazila_name":"Raozan",
       "upazila_ban":"রাউজান",
       "union_code":1,
       "district_code":15
    },
    {
       "_id":576,
       "upazila_code":78,
       "upazila_name":"Sandwip",
       "upazila_ban":"সন্দ্বীপ",
       "union_code":1,
       "district_code":15
    },
    {
       "_id":585,
       "upazila_code":82,
       "upazila_name":"Satkania",
       "upazila_ban":"সাতকানিয়া",
       "union_code":1,
       "district_code":15
    },
    {
       "_id":594,
       "upazila_code":86,
       "upazila_name":"Sitakunda",
       "upazila_ban":"সীতাকুণ্ড",
       "union_code":1,
       "district_code":15
    },
    {
       "_id":603,
       "upazila_code":7,
       "upazila_name":"Alamdanga",
       "upazila_ban":"আলমডাঙ্গা",
       "union_code":1,
       "district_code":18
    },
    {
       "_id":612,
       "upazila_code":23,
       "upazila_name":"Chuadanga Sadar",
       "upazila_ban":"চুয়াডাঙ্গা সদর",
       "union_code":1,
       "district_code":18
    },
    {
       "_id":621,
       "upazila_code":31,
       "upazila_name":"Damurhuda",
       "upazila_ban":"দামুড়হুদা",
       "union_code":1,
       "district_code":18
    },
    {
       "_id":630,
       "upazila_code":55,
       "upazila_name":"Jiban Nagar",
       "upazila_ban":"জীবন নগর",
       "union_code":1,
       "district_code":18
    },
    {
       "_id":639,
       "upazila_code":9,
       "upazila_name":"Barura",
       "upazila_ban":"বাড়ুরা",
       "union_code":1,
       "district_code":19
    },
    {
       "_id":648,
       "upazila_code":27,
       "upazila_name":"Chandina",
       "upazila_ban":"চান্দিনা",
       "union_code":1,
       "district_code":19
    },
    {
       "_id":657,
       "upazila_code":31,
       "upazila_name":"Chauddagram",
       "upazila_ban":"চৌদ্দগ্রাম",
       "union_code":1,
       "district_code":19
    },
    {
       "_id":666,
       "upazila_code":33,
       "upazila_name":"Comilla Sadar Dakshin",
       "upazila_ban":"কুমিল্লা সদর দক্ষিণ",
       "union_code":1,
       "district_code":19
    },
    {
       "_id":675,
       "upazila_code":36,
       "upazila_name":"Daudkandi",
       "upazila_ban":"দাউদকান্দি",
       "union_code":1,
       "district_code":19
    },
    {
       "_id":684,
       "upazila_code":40,
       "upazila_name":"Debidwar",
       "upazila_ban":"দেবিদ্বার",
       "union_code":1,
       "district_code":19
    },
    {
       "_id":693,
       "upazila_code":54,
       "upazila_name":"Homna",
       "upazila_ban":"হোমনা",
       "union_code":1,
       "district_code":19
    },
    {
       "_id":702,
       "upazila_code":67,
       "upazila_name":"Comilla Adarsha Sadar",
       "upazila_ban":"কুমিল্লা আদর্শ সদর",
       "union_code":1,
       "district_code":19
    },
    {
       "_id":720,
       "upazila_code":72,
       "upazila_name":"Laksam",
       "upazila_ban":"লাকসাম",
       "union_code":1,
       "district_code":19
    },
    {
       "_id":729,
       "upazila_code":87,
       "upazila_name":"Nangalkot",
       "upazila_ban":"নাঙ্গলকোট",
       "union_code":1,
       "district_code":19
    },
    {
       "_id":738,
       "upazila_code":16,
       "upazila_name":"Chakoria",
       "upazila_ban":"চকরিয়া",
       "union_code":1,
       "district_code":22
    },
    {
       "_id":747,
       "upazila_code":24,
       "upazila_name":"Cox's Bazar Sadar",
       "upazila_ban":"কক্সবাজার সদর",
       "union_code":1,
       "district_code":22
    },
    {
       "_id":759,
       "upazila_code":49,
       "upazila_name":"Maheshkhali",
       "upazila_ban":"মহেশখালী",
       "union_code":1,
       "district_code":22
    },
    {
       "_id":768,
       "upazila_code":90,
       "upazila_name":"Teknaf",
       "upazila_ban":"টেকনাফ",
       "union_code":1,
       "district_code":22
    },
    {
       "_id":777,
       "upazila_code":2,
       "upazila_name":"Adabor Thana",
       "upazila_ban":"আদাবোর থানা",
       "union_code":43,
       "district_code":26
    },
    {
       "_id":779,
       "upazila_code":4,
       "upazila_name":"Badda Thana",
       "upazila_ban":"বাড্ডা থানা",
       "union_code":17,
       "district_code":26
    },
    {
       "_id":781,
       "upazila_code":5,
       "upazila_name":"Bangshal Thana",
       "upazila_ban":"বংশাল থানা",
       "union_code":63,
       "district_code":26
    },
    {
       "_id":788,
       "upazila_code":6,
       "upazila_name":"Biman Bandar Thana",
       "upazila_ban":"বিমান বন্দর থানা",
       "union_code":1,
       "district_code":26
    },
    {
       "_id":790,
       "upazila_code":8,
       "upazila_name":"Cantonment Thana",
       "upazila_ban":"ক্যান্টনমেন্ট থানা",
       "union_code":15,
       "district_code":26
    },
    {
       "_id":792,
       "upazila_code":9,
       "upazila_name":"Chak Bazar Thana",
       "upazila_ban":"চক বাজার থানা",
       "union_code":63,
       "district_code":26
    },
    {
       "_id":797,
       "upazila_code":11,
       "upazila_name":"Darus Salam Thana",
       "upazila_ban":"দারুস সালাম থানা",
       "union_code":9,
       "district_code":26
    },
    {
       "_id":799,
       "upazila_code":14,
       "upazila_name":"Dhamrai",
       "upazila_ban":"ধামরাই",
       "union_code":1,
       "district_code":26
    },
    {
       "_id":808,
       "upazila_code":16,
       "upazila_name":"Dhanmondi Thana",
       "upazila_ban":"ধানমন্ডি থানা",
       "union_code":47,
       "district_code":26
    },
    {
       "_id":811,
       "upazila_code":18,
       "upazila_name":"Dohar",
       "upazila_ban":"দোহার",
       "union_code":1,
       "district_code":26
    },
    {
       "_id":820,
       "upazila_code":24,
       "upazila_name":"Gendaria Thana",
       "upazila_ban":"গেন্ডারিয়া থানা",
       "union_code":76,
       "district_code":26
    },
    {
       "_id":824,
       "upazila_code":26,
       "upazila_name":"Gulshan Thana",
       "upazila_ban":"গেন্ডারিয়া থানা",
       "union_code":18,
       "district_code":26
    },
    {
       "_id":827,
       "upazila_code":28,
       "upazila_name":"Hazaribagh Thana",
       "upazila_ban":"হাজারীবাগ থানা",
       "union_code":46,
       "district_code":26
    },
    {
       "_id":830,
       "upazila_code":29,
       "upazila_name":"Jatrabari Thana",
       "upazila_ban":"যাত্রাবাড়ী থানা",
       "union_code":76,
       "district_code":26
    },
    {
       "_id":834,
       "upazila_code":30,
       "upazila_name":"Kafrul Thana",
       "upazila_ban":"কাফরুল থানা",
       "union_code":4,
       "district_code":26
    },
    {
       "_id":838,
       "upazila_code":32,
       "upazila_name":"Kadamtali Thana",
       "upazila_ban":"কদমতলী থানা",
       "union_code":88,
       "district_code":26
    },
    {
       "_id":840,
       "upazila_code":33,
       "upazila_name":"Kalabagan Thana",
       "upazila_ban":"কলাবাগান থানা",
       "union_code":50,
       "district_code":26
    },
    {
       "_id":842,
       "upazila_code":36,
       "upazila_name":"Khilgaon Thana",
       "upazila_ban":"খিলগাঁও থানা",
       "union_code":24,
       "district_code":26
    },
    {
       "_id":845,
       "upazila_code":37,
       "upazila_name":"Khilkhet Thana",
       "upazila_ban":"খিলক্ষেত থানা",
       "union_code":17,
       "district_code":26
    },
    {
       "_id":850,
       "upazila_code":42,
       "upazila_name":"Lalbagh Thana",
       "upazila_ban":"লালবাগ থানা",
       "union_code":56,
       "district_code":26
    },
    {
       "_id":857,
       "upazila_code":48,
       "upazila_name":"Mirpur Thana",
       "upazila_ban":"মিরপুর থানা",
       "union_code":7,
       "district_code":26
    },
    {
       "_id":862,
       "upazila_code":50,
       "upazila_name":"Mohammadpur Thana",
       "upazila_ban":"মোহাম্মদপুর থানা",
       "union_code":42,
       "district_code":26
    },
    {
       "_id":868,
       "upazila_code":54,
       "upazila_name":"Motijheel Thana",
       "upazila_ban":"মতিঝিল থানা",
       "union_code":31,
       "district_code":26
    },
    {
       "_id":873,
       "upazila_code":63,
       "upazila_name":"New Market Thana",
       "upazila_ban":"নিউমার্কেট থানা",
       "union_code":52,
       "district_code":26
    },
    {
       "_id":874,
       "upazila_code":64,
       "upazila_name":"Pallabi Thana",
       "upazila_ban":"পল্লবী থানা",
       "union_code":2,
       "district_code":26
    },
    {
       "_id":880,
       "upazila_code":65,
       "upazila_name":"Paltan Thana",
       "upazila_ban":"পল্টন থানা",
       "union_code":36,
       "district_code":26
    },
    {
       "_id":881,
       "upazila_code":66,
       "upazila_name":"Ramna Thana",
       "upazila_ban":"রমনা থানা",
       "union_code":53,
       "district_code":26
    },
    {
       "_id":884,
       "upazila_code":67,
       "upazila_name":"Rampura Thana",
       "upazila_ban":"রামপুরা থানা",
       "union_code":22,
       "district_code":26
    },
    {
       "_id":886,
       "upazila_code":68,
       "upazila_name":"Sabujbagh Thana",
       "upazila_ban":"সবুজবাগ থানা",
       "union_code":27,
       "district_code":26
    },
    {
       "_id":890,
       "upazila_code":72,
       "upazila_name":"Savar",
       "upazila_ban":"সাভার",
       "union_code":1,
       "district_code":26
    },
    {
       "_id":899,
       "upazila_code":74,
       "upazila_name":"Shah Ali Thana",
       "upazila_ban":"শাহ আলী থানা",
       "union_code":7,
       "district_code":26
    },
    {
       "_id":901,
       "upazila_code":75,
       "upazila_name":"Shahbagh Thana",
       "upazila_ban":"শাহবাগ থানা",
       "union_code":56,
       "district_code":26
    },
    {
       "_id":903,
       "upazila_code":76,
       "upazila_name":"Shyampur Thana",
       "upazila_ban":"শ্যামপুর থানা",
       "union_code":83,
       "district_code":26
    },
    {
       "_id":906,
       "upazila_code":80,
       "upazila_name":"Sher-E-Bangla Nagar Thana",
       "upazila_ban":"শের-ই-বাংলা নগর থানা",
       "union_code":40,
       "district_code":26
    },
    {
       "_id":908,
       "upazila_code":88,
       "upazila_name":"Sutrapur Thana",
       "upazila_ban":"সূত্রাপুর থানা",
       "union_code":74,
       "district_code":26
    },
    {
       "_id":914,
       "upazila_code":90,
       "upazila_name":"Tejgaon Thana",
       "upazila_ban":"তেজগাঁও থানা",
       "union_code":38,
       "district_code":26
    },
    {
       "_id":917,
       "upazila_code":92,
       "upazila_name":"Tejgaon Ind. Area Thana",
       "upazila_ban":"তেজগাঁও ইন্ড.এরিয়া থানা",
       "union_code":20,
       "district_code":26
    },
    {
       "_id":920,
       "upazila_code":95,
       "upazila_name":"Uttara Thana",
       "upazila_ban":"উত্তরা থানা",
       "union_code":1,
       "district_code":26
    },
    {
       "_id":921,
       "upazila_code":10,
       "upazila_name":"Birampur",
       "upazila_ban":"বিরামপুর",
       "union_code":1,
       "district_code":27
    },
    {
       "_id":930,
       "upazila_code":12,
       "upazila_name":"Birganj",
       "upazila_ban":"বীরগঞ্জ",
       "union_code":1,
       "district_code":27
    },
    {
       "_id":939,
       "upazila_code":21,
       "upazila_name":"Bochaganj",
       "upazila_ban":"বোচাগঞ্জ",
       "union_code":1,
       "district_code":27
    },
    {
       "_id":948,
       "upazila_code":38,
       "upazila_name":"Fulbari",
       "upazila_ban":"ফুলবাড়ি",
       "union_code":1,
       "district_code":27
    },
    {
       "_id":957,
       "upazila_code":43,
       "upazila_name":"Ghoraghat",
       "upazila_ban":"ঘোড়াঘাট",
       "union_code":1,
       "district_code":27
    },
    {
       "_id":966,
       "upazila_code":47,
       "upazila_name":"Hakimpur",
       "upazila_ban":"হাকিমপুর",
       "union_code":1,
       "district_code":27
    },
    {
       "_id":975,
       "upazila_code":64,
       "upazila_name":"Dinajpur Sadar",
       "upazila_ban":"দিনাজপুর সদর",
       "union_code":1,
       "district_code":27
    },
    {
       "_id":987,
       "upazila_code":77,
       "upazila_name":"Parbatipur",
       "upazila_ban":"পার্বতীপুর",
       "union_code":1,
       "district_code":27
    },
    {
       "_id":996,
       "upazila_code":10,
       "upazila_name":"Bhanga",
       "upazila_ban":"ভাঙ্গা",
       "union_code":1,
       "district_code":29
    },
    {
       "_id":1005,
       "upazila_code":18,
       "upazila_name":"Boalmari",
       "upazila_ban":"বোয়ালমারী",
       "union_code":1,
       "district_code":29
    },
    {
       "_id":1014,
       "upazila_code":47,
       "upazila_name":"Faridpur Sadar",
       "upazila_ban":"ফরিদপুর সদর",
       "union_code":1,
       "district_code":29
    },
    {
       "_id":1023,
       "upazila_code":62,
       "upazila_name":"Nagarkanda",
       "upazila_ban":"নাগরকান্দা",
       "union_code":1,
       "district_code":29
    },
    {
       "_id":1032,
       "upazila_code":14,
       "upazila_name":"Chhagalnaiya",
       "upazila_ban":"ছাগলনাইয়া",
       "union_code":1,
       "district_code":30
    },
    {
       "_id":1041,
       "upazila_code":25,
       "upazila_name":"Daganbhuiyan",
       "upazila_ban":"দাগনভূঁইয়া",
       "union_code":1,
       "district_code":30
    },
    {
       "_id":1050,
       "upazila_code":29,
       "upazila_name":"Feni Sadar",
       "upazila_ban":"ফেনী সদর",
       "union_code":1,
       "district_code":30
    },
    {
       "_id":1068,
       "upazila_code":51,
       "upazila_name":"Parshuram",
       "upazila_ban":"পরশুরাম",
       "union_code":1,
       "district_code":30
    },
    {
       "_id":1077,
       "upazila_code":94,
       "upazila_name":"Sonagazi",
       "upazila_ban":"সোনাগাজী",
       "union_code":1,
       "district_code":30
    },
    {
       "_id":1086,
       "upazila_code":24,
       "upazila_name":"Gaibandha Sadar",
       "upazila_ban":"গাইবান্ধা সদর",
       "union_code":1,
       "district_code":32
    },
    {
       "_id":1095,
       "upazila_code":30,
       "upazila_name":"Gobindaganj",
       "upazila_ban":"গোবিন্দগঞ্জ",
       "union_code":1,
       "district_code":32
    },
    {
       "_id":1104,
       "upazila_code":91,
       "upazila_name":"Sundarganj",
       "upazila_ban":"সুন্দরগঞ্জ",
       "union_code":1,
       "district_code":32
    },
    {
       "_id":1113,
       "upazila_code":30,
       "upazila_name":"Gazipur Sadar",
       "upazila_ban":"গাজীপুর সদর",
       "union_code":1,
       "district_code":33
    },
    {
       "_id":1134,
       "upazila_code":32,
       "upazila_name":"Kaliakair",
       "upazila_ban":"কালিয়াকৈর",
       "union_code":1,
       "district_code":33
    },
    {
       "_id":1143,
       "upazila_code":34,
       "upazila_name":"Kaliganj",
       "upazila_ban":"কালিগঞ্জ",
       "union_code":1,
       "district_code":33
    },
    {
       "_id":1152,
       "upazila_code":86,
       "upazila_name":"Sreepur",
       "upazila_ban":"শ্রীপুর",
       "union_code":1,
       "district_code":33
    },
    {
       "_id":1161,
       "upazila_code":32,
       "upazila_name":"Gopalganj Sadar",
       "upazila_ban":"গোপালগঞ্জ সদর",
       "union_code":1,
       "district_code":35
    },
    {
       "_id":1170,
       "upazila_code":51,
       "upazila_name":"Kotalipara",
       "upazila_ban":"কোটালীপাড়া",
       "union_code":1,
       "district_code":35
    },
    {
       "_id":1179,
       "upazila_code":58,
       "upazila_name":"Muksudpur",
       "upazila_ban":"মুকসুদপুর",
       "union_code":1,
       "district_code":35
    },
    {
       "_id":1188,
       "upazila_code":91,
       "upazila_name":"Tungipara",
       "upazila_ban":"টুঙ্গিপাড়া",
       "union_code":1,
       "district_code":35
    },
    {
       "_id":1197,
       "upazila_code":2,
       "upazila_name":"Ajmiriganj",
       "upazila_ban":"আজমিরীগঞ্জ",
       "union_code":1,
       "district_code":36
    },
    {
       "_id":1206,
       "upazila_code":26,
       "upazila_name":"Chunarughat",
       "upazila_ban":"চুনারুঘাট",
       "union_code":1,
       "district_code":36
    },
    {
       "_id":1215,
       "upazila_code":44,
       "upazila_name":"Habiganj Sadar",
       "upazila_ban":"হবিগঞ্জ সদর",
       "union_code":1,
       "district_code":36
    },
    {
       "_id":1233,
       "upazila_code":71,
       "upazila_name":"Madhabpur",
       "upazila_ban":"মাধবপুর",
       "union_code":1,
       "district_code":36
    },
    {
       "_id":1242,
       "upazila_code":77,
       "upazila_name":"Nabiganj",
       "upazila_ban":"নবীগঞ্জ",
       "union_code":1,
       "district_code":36
    },
    {
       "_id":1251,
       "upazila_code":15,
       "upazila_name":"Dewanganj",
       "upazila_ban":"দেওয়ানগঞ্জ",
       "union_code":1,
       "district_code":39
    },
    {
       "_id":1260,
       "upazila_code":29,
       "upazila_name":"Islampur",
       "upazila_ban":"ইসলামপুর",
       "union_code":1,
       "district_code":39
    },
    {
       "_id":1269,
       "upazila_code":36,
       "upazila_name":"Jamalpur Sadar",
       "upazila_ban":"জামালপুর সদর",
       "union_code":1,
       "district_code":39
    },
    {
       "_id":1281,
       "upazila_code":58,
       "upazila_name":"Madarganj",
       "upazila_ban":"মাদারগঞ্জ",
       "union_code":1,
       "district_code":39
    },
    {
       "_id":1290,
       "upazila_code":61,
       "upazila_name":"Melandaha",
       "upazila_ban":"মেলান্দাহা",
       "union_code":1,
       "district_code":39
    },
    {
       "_id":1299,
       "upazila_code":85,
       "upazila_name":"Sarishabari",
       "upazila_ban":"সরিষাবাড়ি",
       "union_code":1,
       "district_code":39
    },
    {
       "_id":1308,
       "upazila_code":4,
       "upazila_name":"Abhaynagar",
       "upazila_ban":"অভয়নগর",
       "union_code":1,
       "district_code":41
    },
    {
       "_id":1317,
       "upazila_code":9,
       "upazila_name":"Bagherpara",
       "upazila_ban":"বাঘারপাড়া",
       "union_code":1,
       "district_code":41
    },
    {
       "_id":1326,
       "upazila_code":11,
       "upazila_name":"Chaugachha",
       "upazila_ban":"চৌগাছা",
       "union_code":1,
       "district_code":41
    },
    {
       "_id":1335,
       "upazila_code":23,
       "upazila_name":"Jhikargachha",
       "upazila_ban":"ঝিকরগাছা",
       "union_code":1,
       "district_code":41
    },
    {
       "_id":1344,
       "upazila_code":38,
       "upazila_name":"Keshabpur",
       "upazila_ban":"কেশবপুর",
       "union_code":1,
       "district_code":41
    },
    {
       "_id":1353,
       "upazila_code":47,
       "upazila_name":"Jessore Sadar",
       "upazila_ban":"যশোর সদর",
       "union_code":1,
       "district_code":41
    },
    {
       "_id":1362,
       "upazila_code":61,
       "upazila_name":"Manirampur",
       "upazila_ban":"মণিরামপুর",
       "union_code":1,
       "district_code":41
    },
    {
       "_id":1371,
       "upazila_code":90,
       "upazila_name":"Sharsha",
       "upazila_ban":"শার্শা",
       "union_code":1,
       "district_code":41
    },
    {
       "_id":1380,
       "upazila_code":40,
       "upazila_name":"Jhalokati Sadar",
       "upazila_ban":"ঝালকাঠি সদর",
       "union_code":1,
       "district_code":42
    },
    {
       "_id":1389,
       "upazila_code":73,
       "upazila_name":"Nalchity",
       "upazila_ban":"নলছিটি",
       "union_code":1,
       "district_code":42
    },
    {
       "_id":1398,
       "upazila_code":14,
       "upazila_name":"Harinakundu",
       "upazila_ban":"হরিণাকুন্ডু",
       "union_code":1,
       "district_code":44
    },
    {
       "_id":1407,
       "upazila_code":19,
       "upazila_name":"Jhenaidah Sadar",
       "upazila_ban":"ঝিনাইদহ সদর",
       "union_code":1,
       "district_code":44
    },
    {
       "_id":1425,
       "upazila_code":42,
       "upazila_name":"Kotchandpur",
       "upazila_ban":"কোটচাঁদপুর",
       "union_code":1,
       "district_code":44
    },
    {
       "_id":1434,
       "upazila_code":71,
       "upazila_name":"Maheshpur",
       "upazila_ban":"মহেশপুর",
       "union_code":1,
       "district_code":44
    },
    {
       "_id":1443,
       "upazila_code":80,
       "upazila_name":"Shailkupa",
       "upazila_ban":"শৈলকূপা",
       "union_code":1,
       "district_code":44
    },
    {
       "_id":1452,
       "upazila_code":13,
       "upazila_name":"Akkelpur",
       "upazila_ban":"আক্কেলপুর",
       "union_code":1,
       "district_code":38
    },
    {
       "_id":1461,
       "upazila_code":47,
       "upazila_name":"Joypurhat Sadar",
       "upazila_ban":"জয়পুরহাট সদর",
       "union_code":1,
       "district_code":38
    },
    {
       "_id":1470,
       "upazila_code":58,
       "upazila_name":"Kalai",
       "upazila_ban":"কালাই",
       "union_code":1,
       "district_code":38
    },
    {
       "_id":1479,
       "upazila_code":74,
       "upazila_name":"Panchbibi",
       "upazila_ban":"পাঁচবিবি",
       "union_code":1,
       "district_code":38
    },
    {
       "_id":1488,
       "upazila_code":49,
       "upazila_name":"Khagrachhari Sadar",
       "upazila_ban":"খাগড়াছড়ি সদর",
       "union_code":1,
       "district_code":46
    },
    {
       "_id":1497,
       "upazila_code":70,
       "upazila_name":"Matiranga",
       "upazila_ban":"মাটিরাঙ্গা",
       "union_code":1,
       "district_code":46
    },
    {
       "_id":1506,
       "upazila_code":80,
       "upazila_name":"Ramgarh",
       "upazila_ban":"রামগড়",
       "union_code":1,
       "district_code":46
    },
    {
       "_id":1515,
       "upazila_code":17,
       "upazila_name":"Dacope",
       "upazila_ban":"দাকোপ",
       "union_code":1,
       "district_code":47
    },
    {
       "_id":1524,
       "upazila_code":21,
       "upazila_name":"Daulatpur Thana",
       "upazila_ban":"দৌলতপুর থানা",
       "union_code":1,
       "district_code":47
    },
    {
       "_id":1530,
       "upazila_code":45,
       "upazila_name":"Khalishpur Thana",
       "upazila_ban":"খালিশপুর থানা",
       "union_code":7,
       "district_code":47
    },
    {
       "_id":1539,
       "upazila_code":48,
       "upazila_name":"Khan Jahan Ali Thana",
       "upazila_ban":"খান জাহান আলী থানা",
       "union_code":2,
       "district_code":47
    },
    {
       "_id":1540,
       "upazila_code":51,
       "upazila_name":"Khulna Sadar Thana",
       "upazila_ban":"খুলনা সদর থানা",
       "union_code":21,
       "district_code":47
    },
    {
       "_id":1549,
       "upazila_code":64,
       "upazila_name":"Paikgachha",
       "upazila_ban":"পাইকগাছা",
       "union_code":1,
       "district_code":47
    },
    {
       "_id":1558,
       "upazila_code":85,
       "upazila_name":"Sonadanga Thana",
       "upazila_ban":"সোনাডাঙ্গা থানা",
       "union_code":16,
       "district_code":47
    },
    {
       "_id":1565,
       "upazila_code":6,
       "upazila_name":"Bajitpur",
       "upazila_ban":"বাজিতপুর",
       "union_code":1,
       "district_code":48
    },
    {
       "_id":1574,
       "upazila_code":11,
       "upazila_name":"Bhairab",
       "upazila_ban":"ভৈরব",
       "union_code":1,
       "district_code":48
    },
    {
       "_id":1586,
       "upazila_code":27,
       "upazila_name":"Hossainpur",
       "upazila_ban":"হোসেনপুর",
       "union_code":1,
       "district_code":48
    },
    {
       "_id":1595,
       "upazila_code":42,
       "upazila_name":"Karimganj",
       "upazila_ban":"করিমগঞ্জ",
       "union_code":1,
       "district_code":48
    },
    {
       "_id":1604,
       "upazila_code":45,
       "upazila_name":"Katiadi",
       "upazila_ban":"কটিয়াদি",
       "union_code":1,
       "district_code":48
    },
    {
       "_id":1613,
       "upazila_code":49,
       "upazila_name":"Kishoreganj Sadar",
       "upazila_ban":"কিশোরগঞ্জ সদর",
       "union_code":1,
       "district_code":48
    },
    {
       "_id":1622,
       "upazila_code":54,
       "upazila_name":"Kuliar Char",
       "upazila_ban":"কুলিয়ার চর",
       "union_code":1,
       "district_code":48
    },
    {
       "_id":1631,
       "upazila_code":79,
       "upazila_name":"Pakundia",
       "upazila_ban":"পাকুন্দিয়া",
       "union_code":1,
       "district_code":48
    },
    {
       "_id":1640,
       "upazila_code":52,
       "upazila_name":"Kurigram Sadar",
       "upazila_ban":"কুড়িগ্রাম সদর",
       "union_code":1,
       "district_code":49
    },
    {
       "_id":1649,
       "upazila_code":61,
       "upazila_name":"Nageshwari",
       "upazila_ban":"নাগেশ্বরী",
       "union_code":1,
       "district_code":49
    },
    {
       "_id":1658,
       "upazila_code":94,
       "upazila_name":"Ulipur",
       "upazila_ban":"উলিপুর",
       "union_code":1,
       "district_code":49
    },
    {
       "_id":1667,
       "upazila_code":15,
       "upazila_name":"Bheramara",
       "upazila_ban":"ভেড়ামারা",
       "union_code":1,
       "district_code":50
    },
    {
       "_id":1676,
       "upazila_code":63,
       "upazila_name":"Khoksa",
       "upazila_ban":"খোকসা",
       "union_code":1,
       "district_code":50
    },
    {
       "_id":1685,
       "upazila_code":71,
       "upazila_name":"Kumarkhali",
       "upazila_ban":"কুমারখালী",
       "union_code":1,
       "district_code":50
    },
    {
       "_id":1694,
       "upazila_code":79,
       "upazila_name":"Kushtia Sadar",
       "upazila_ban":"কুষ্টিয়া সদর",
       "union_code":1,
       "district_code":50
    },
    {
       "_id":1706,
       "upazila_code":94,
       "upazila_name":"Mirpur",
       "upazila_ban":"মিরপুর",
       "union_code":1,
       "district_code":50
    },
    {
       "_id":1715,
       "upazila_code":43,
       "upazila_name":"Lakshmipur Sadar",
       "upazila_ban":"লক্ষ্মীপুর সদর",
       "union_code":1,
       "district_code":51
    },
    {
       "_id":1727,
       "upazila_code":58,
       "upazila_name":"Roypur",
       "upazila_ban":"রায়পুর",
       "union_code":1,
       "district_code":51
    },
    {
       "_id":1736,
       "upazila_code":65,
       "upazila_name":"Ramganj",
       "upazila_ban":"রামগঞ্জ",
       "union_code":1,
       "district_code":51
    },
    {
       "_id":1745,
       "upazila_code":73,
       "upazila_name":"Ramgati",
       "upazila_ban":"রামগতি",
       "union_code":1,
       "district_code":51
    },
    {
       "_id":1754,
       "upazila_code":55,
       "upazila_name":"Lalmonirhat Sadar",
       "upazila_ban":"লালমনিরহাট সদর",
       "union_code":1,
       "district_code":52
    },
    {
       "_id":1763,
       "upazila_code":70,
       "upazila_name":"Patgram",
       "upazila_ban":"পাটগ্রাম",
       "union_code":1,
       "district_code":52
    },
    {
       "_id":1772,
       "upazila_code":40,
       "upazila_name":"Kalkini",
       "upazila_ban":"কালকিনি",
       "union_code":1,
       "district_code":54
    },
    {
       "_id":1781,
       "upazila_code":54,
       "upazila_name":"Madaripur Sadar",
       "upazila_ban":"মাদারীপুর সদর",
       "union_code":1,
       "district_code":54
    },
    {
       "_id":1790,
       "upazila_code":87,
       "upazila_name":"Shib Char",
       "upazila_ban":"শিব চর",
       "union_code":1,
       "district_code":54
    },
    {
       "_id":1799,
       "upazila_code":57,
       "upazila_name":"Magura Sadar",
       "upazila_ban":"মাগুরা সদর",
       "union_code":1,
       "district_code":55
    },
    {
       "_id":1808,
       "upazila_code":46,
       "upazila_name":"Manikganj Sadar",
       "upazila_ban":"মানিকগঞ্জ সদর",
       "union_code":1,
       "district_code":56
    },
    {
       "_id":1817,
       "upazila_code":82,
       "upazila_name":"Singair",
       "upazila_ban":"সিঙ্গাইর",
       "union_code":1,
       "district_code":56
    },
    {
       "_id":1826,
       "upazila_code":14,
       "upazila_name":"Barlekha",
       "upazila_ban":"বারলেখা",
       "union_code":1,
       "district_code":58
    },
    {
       "_id":1835,
       "upazila_code":56,
       "upazila_name":"Kamalganj",
       "upazila_ban":"কমলগঞ্জ",
       "union_code":1,
       "district_code":58
    },
    {
       "_id":1844,
       "upazila_code":65,
       "upazila_name":"Kulaura",
       "upazila_ban":"কুলাউড়া",
       "union_code":1,
       "district_code":58
    },
    {
       "_id":1853,
       "upazila_code":74,
       "upazila_name":"Maulvibazar Sadar",
       "upazila_ban":"মৌলভীবাজার সদর",
       "union_code":1,
       "district_code":58
    },
    {
       "_id":1862,
       "upazila_code":83,
       "upazila_name":"Sreemangal",
       "upazila_ban":"শ্রীমঙ্গল",
       "union_code":1,
       "district_code":58
    },
    {
       "_id":1871,
       "upazila_code":47,
       "upazila_name":"Gangni",
       "upazila_ban":"গাংনী",
       "union_code":1,
       "district_code":57
    },
    {
       "_id":1880,
       "upazila_code":87,
       "upazila_name":"Meherpur Sadar",
       "upazila_ban":"মেহেরপুর সদর",
       "union_code":1,
       "district_code":57
    },
    {
       "_id":1889,
       "upazila_code":56,
       "upazila_name":"Munshiganj Sadar",
       "upazila_ban":"মুন্সিগঞ্জ সদর",
       "union_code":1,
       "district_code":59
    },
    {
       "_id":1907,
       "upazila_code":13,
       "upazila_name":"Bhaluka",
       "upazila_ban":"ভালুকা",
       "union_code":1,
       "district_code":61
    },
    {
       "_id":1916,
       "upazila_code":20,
       "upazila_name":"Fulbaria",
       "upazila_ban":"ফুলবাড়িয়া",
       "union_code":1,
       "district_code":61
    },
    {
       "_id":1925,
       "upazila_code":22,
       "upazila_name":"Gaffargaon",
       "upazila_ban":"গফরগাঁও",
       "union_code":1,
       "district_code":61
    },
    {
       "_id":1934,
       "upazila_code":23,
       "upazila_name":"Gauripur",
       "upazila_ban":"গৌরীপুর",
       "union_code":1,
       "district_code":61
    },
    {
       "_id":1943,
       "upazila_code":31,
       "upazila_name":"Ishwarganj",
       "upazila_ban":"ইশ্বরগঞ্জ",
       "union_code":1,
       "district_code":61
    },
    {
       "_id":1952,
       "upazila_code":52,
       "upazila_name":"Mymensingh Sadar",
       "upazila_ban":"ময়মনসিংহ সদর",
       "union_code":1,
       "district_code":61
    },
    {
       "_id":1973,
       "upazila_code":65,
       "upazila_name":"Muktagachha",
       "upazila_ban":"মুক্তগাছা",
       "union_code":1,
       "district_code":61
    },
    {
       "_id":1982,
       "upazila_code":72,
       "upazila_name":"Nandail",
       "upazila_ban":"নান্দাইল",
       "union_code":1,
       "district_code":61
    },
    {
       "_id":1991,
       "upazila_code":81,
       "upazila_name":"Phulpur",
       "upazila_ban":"ফুলপুর",
       "union_code":1,
       "district_code":61
    },
    {
       "_id":2000,
       "upazila_code":94,
       "upazila_name":"Trishal",
       "upazila_ban":"ত্রিশাল",
       "union_code":1,
       "district_code":61
    },
    {
       "_id":2009,
       "upazila_code":28,
       "upazila_name":"Dhamoirhat",
       "upazila_ban":"ধামইরহাট",
       "union_code":1,
       "district_code":64
    },
    {
       "_id":2018,
       "upazila_code":60,
       "upazila_name":"Naogaon Sadar",
       "upazila_ban":"নওগাঁ সদর",
       "union_code":1,
       "district_code":64
    },
    {
       "_id":2027,
       "upazila_code":75,
       "upazila_name":"Patnitala",
       "upazila_ban":"পত্নীতলা",
       "union_code":1,
       "district_code":64
    },
    {
       "_id":2036,
       "upazila_code":28,
       "upazila_name":"Kalia",
       "upazila_ban":"কালিয়া",
       "union_code":1,
       "district_code":65
    },
    {
       "_id":2045,
       "upazila_code":52,
       "upazila_name":"Lohagara",
       "upazila_ban":"লোহাগাড়া",
       "union_code":1,
       "district_code":65
    },
    {
       "_id":2054,
       "upazila_code":76,
       "upazila_name":"Narail Sadar",
       "upazila_ban":"নড়াইল সদর",
       "union_code":1,
       "district_code":65
    },
    {
       "_id":2063,
       "upazila_code":4,
       "upazila_name":"Sonargaon",
       "upazila_ban":"সোনারগাঁও",
       "union_code":1,
       "district_code":67
    },
    {
       "_id":2072,
       "upazila_code":6,
       "upazila_name":"Bandar",
       "upazila_ban":"বন্দর",
       "union_code":1,
       "district_code":67
    },
    {
       "_id":2081,
       "upazila_code":58,
       "upazila_name":"Narayanganj Sadar",
       "upazila_ban":"নারায়ণগঞ্জ সদর",
       "union_code":1,
       "district_code":67
    },
    {
       "_id":2099,
       "upazila_code":68,
       "upazila_name":"Rupganj",
       "upazila_ban":"রূপগঞ্জ",
       "union_code":1,
       "district_code":67
    },
    {
       "_id":2117,
       "upazila_code":52,
       "upazila_name":"Manohardi",
       "upazila_ban":"মনোহরদী",
       "union_code":1,
       "district_code":68
    },
    {
       "_id":2126,
       "upazila_code":60,
       "upazila_name":"Narsingdi Sadar",
       "upazila_ban":"নরসিংদী সদর",
       "union_code":1,
       "district_code":68
    },
    {
       "_id":2144,
       "upazila_code":63,
       "upazila_name":"Palash",
       "upazila_ban":"পলাশ",
       "union_code":1,
       "district_code":68
    },
    {
       "_id":2153,
       "upazila_code":64,
       "upazila_name":"Roypura",
       "upazila_ban":"রায়পুরা",
       "union_code":1,
       "district_code":68
    },
    {
       "_id":2162,
       "upazila_code":76,
       "upazila_name":"Shibpur",
       "upazila_ban":"শিবপুর",
       "union_code":1,
       "district_code":68
    },
    {
       "_id":2171,
       "upazila_code":9,
       "upazila_name":"Bagatipara",
       "upazila_ban":"বাগাতিপাড়া",
       "union_code":1,
       "district_code":69
    },
    {
       "_id":2180,
       "upazila_code":15,
       "upazila_name":"Baraigram",
       "upazila_ban":"বড়াইগ্রাম",
       "union_code":1,
       "district_code":69
    },
    {
       "_id":2198,
       "upazila_code":41,
       "upazila_name":"Gurudaspur",
       "upazila_ban":"গুরুদাসপুর",
       "union_code":1,
       "district_code":69
    },
    {
       "_id":2207,
       "upazila_code":44,
       "upazila_name":"Lalpur",
       "upazila_ban":"লালপুর",
       "union_code":1,
       "district_code":69
    },
    {
       "_id":2216,
       "upazila_code":63,
       "upazila_name":"Natore Sadar",
       "upazila_ban":"নাটোর সদর",
       "union_code":1,
       "district_code":69
    },
    {
       "_id":2234,
       "upazila_code":91,
       "upazila_name":"Singra",
       "upazila_ban":"সিংড়া",
       "union_code":1,
       "district_code":69
    },
    {
       "_id":2246,
       "upazila_code":18,
       "upazila_name":"Durgapur",
       "upazila_ban":"দুর্গাপুর",
       "union_code":1,
       "district_code":72
    },
    {
       "_id":2255,
       "upazila_code":47,
       "upazila_name":"Kendua",
       "upazila_ban":"কেন্দুয়া",
       "union_code":1,
       "district_code":72
    },
    {
       "_id":2264,
       "upazila_code":56,
       "upazila_name":"Madan",
       "upazila_ban":"মদন",
       "union_code":1,
       "district_code":72
    },
    {
       "_id":2273,
       "upazila_code":63,
       "upazila_name":"Mohanganj",
       "upazila_ban":"মোহনগঞ্জ",
       "union_code":1,
       "district_code":72
    },
    {
       "_id":2282,
       "upazila_code":74,
       "upazila_name":"Netrokona Sadar",
       "upazila_ban":"নেত্রকোনা সদর",
       "union_code":1,
       "district_code":72
    },
    {
       "_id":2291,
       "upazila_code":15,
       "upazila_name":"Domar",
       "upazila_ban":"ডোমার",
       "union_code":1,
       "district_code":73
    },
    {
       "_id":2300,
       "upazila_code":36,
       "upazila_name":"Jaldhaka",
       "upazila_ban":"জলঢাকা",
       "union_code":1,
       "district_code":73
    },
    {
       "_id":2309,
       "upazila_code":64,
       "upazila_name":"Nilphamari Sadar",
       "upazila_ban":"নীলফামারী সদর",
       "union_code":1,
       "district_code":73
    },
    {
       "_id":2318,
       "upazila_code":85,
       "upazila_name":"Saidpur",
       "upazila_ban":"সৈয়দপুর",
       "union_code":1,
       "district_code":73
    },
    {
       "_id":2333,
       "upazila_code":7,
       "upazila_name":"Begumganj",
       "upazila_ban":"বেগমগঞ্জ",
       "union_code":1,
       "district_code":75
    },
    {
       "_id":2342,
       "upazila_code":10,
       "upazila_name":"Chatkhil",
       "upazila_ban":"চাটখিল",
       "union_code":1,
       "district_code":75
    },
    {
       "_id":2351,
       "upazila_code":21,
       "upazila_name":"Companiganj",
       "upazila_ban":"কোম্পানীগঞ্জ",
       "union_code":1,
       "district_code":75
    },
    {
       "_id":2360,
       "upazila_code":36,
       "upazila_name":"Hatiya",
       "upazila_ban":"হাতিয়া",
       "union_code":1,
       "district_code":75
    },
    {
       "_id":2369,
       "upazila_code":47,
       "upazila_name":"Kabirhat",
       "upazila_ban":"কবিরহাট",
       "union_code":1,
       "district_code":75
    },
    {
       "_id":2378,
       "upazila_code":80,
       "upazila_name":"Senbagh",
       "upazila_ban":"সেনবাগ",
       "union_code":1,
       "district_code":75
    },
    {
       "_id":2387,
       "upazila_code":83,
       "upazila_name":"Sonaimuri",
       "upazila_ban":"সোনাইমুড়ি",
       "union_code":1,
       "district_code":75
    },
    {
       "_id":2396,
       "upazila_code":87,
       "upazila_name":"Noakhali Sadar",
       "upazila_ban":"নোয়াখালী সদর",
       "union_code":1,
       "district_code":75
    },
    {
       "_id":2405,
       "upazila_code":5,
       "upazila_name":"Atgharia",
       "upazila_ban":"আটঘরিয়া",
       "union_code":1,
       "district_code":76
    },
    {
       "_id":2414,
       "upazila_code":16,
       "upazila_name":"Bera",
       "upazila_ban":"বেরা",
       "union_code":1,
       "district_code":76
    },
    {
       "_id":2423,
       "upazila_code":19,
       "upazila_name":"Bhangura",
       "upazila_ban":"ভাঙ্গুরা",
       "union_code":1,
       "district_code":76
    },
    {
       "_id":2432,
       "upazila_code":22,
       "upazila_name":"Chatmohar",
       "upazila_ban":"চাটমোহর",
       "union_code":1,
       "district_code":76
    },
    {
       "_id":2441,
       "upazila_code":33,
       "upazila_name":"Faridpur",
       "upazila_ban":"ফরিদপুর",
       "union_code":1,
       "district_code":76
    },
    {
       "_id":2450,
       "upazila_code":39,
       "upazila_name":"Ishwardi",
       "upazila_ban":"ইশ্বরদী",
       "union_code":1,
       "district_code":76
    },
    {
       "_id":2459,
       "upazila_code":55,
       "upazila_name":"Pabna Sadar",
       "upazila_ban":"পাবনা সদর",
       "union_code":1,
       "district_code":76
    },
    {
       "_id":2474,
       "upazila_code":72,
       "upazila_name":"Santhia",
       "upazila_ban":"সাঁথিয়া",
       "union_code":1,
       "district_code":76
    },
    {
       "_id":2483,
       "upazila_code":83,
       "upazila_name":"Sujanagar",
       "upazila_ban":"সুজানগর",
       "union_code":1,
       "district_code":76
    },
    {
       "_id":2492,
       "upazila_code":25,
       "upazila_name":"Boda",
       "upazila_ban":"বোদা",
       "union_code":1,
       "district_code":77
    },
    {
       "_id":2501,
       "upazila_code":73,
       "upazila_name":"Panchagarh Sadar",
       "upazila_ban":"পঞ্চগড় সদর",
       "union_code":1,
       "district_code":77
    },
    {
       "_id":2510,
       "upazila_code":38,
       "upazila_name":"Bauphal",
       "upazila_ban":"বাউফল",
       "union_code":1,
       "district_code":78
    },
    {
       "_id":2519,
       "upazila_code":57,
       "upazila_name":"Galachipa",
       "upazila_ban":"গলাচিপা",
       "union_code":1,
       "district_code":78
    },
    {
       "_id":2528,
       "upazila_code":66,
       "upazila_name":"Kala Para",
       "upazila_ban":"কালা পাড়া",
       "union_code":1,
       "district_code":78
    },
    {
       "_id":2546,
       "upazila_code":95,
       "upazila_name":"Patuakhali Sadar",
       "upazila_ban":"পটুয়াখালী সদর",
       "union_code":1,
       "district_code":78
    },
    {
       "_id":2555,
       "upazila_code":58,
       "upazila_name":"Mathbaria",
       "upazila_ban":"মঠবাড়িয়া",
       "union_code":1,
       "district_code":79
    },
    {
       "_id":2564,
       "upazila_code":80,
       "upazila_name":"Pirojpur Sadar",
       "upazila_ban":"পিরোজপুর সদর",
       "union_code":1,
       "district_code":79
    },
    {
       "_id":2573,
       "upazila_code":87,
       "upazila_name":"Nesarabad",
       "upazila_ban":"নেছারাবাদ",
       "union_code":1,
       "district_code":79
    },
    {
       "_id":2582,
       "upazila_code":29,
       "upazila_name":"Goalanda",
       "upazila_ban":"গোয়ালন্দ",
       "union_code":1,
       "district_code":82
    },
    {
       "_id":2591,
       "upazila_code":73,
       "upazila_name":"Pangsha",
       "upazila_ban":"পাংশা",
       "union_code":1,
       "district_code":82
    },
    {
       "_id":2600,
       "upazila_code":76,
       "upazila_name":"Rajbari Sadar",
       "upazila_ban":"রাজবাড়ী সদর",
       "union_code":1,
       "district_code":82
    },
    {
       "_id":2609,
       "upazila_code":10,
       "upazila_name":"Bagha",
       "upazila_ban":"বাঘা",
       "union_code":1,
       "district_code":81
    },
    {
       "_id":2627,
       "upazila_code":12,
       "upazila_name":"Baghmara",
       "upazila_ban":"বাঘমারা",
       "union_code":1,
       "district_code":81
    },
    {
       "_id":2645,
       "upazila_code":22,
       "upazila_name":"Boalia Thana",
       "upazila_ban":"বোয়ালিয়া থানা",
       "union_code":9,
       "district_code":81
    },
    {
       "_id":2663,
       "upazila_code":25,
       "upazila_name":"Charghat",
       "upazila_ban":"চারঘাট",
       "union_code":1,
       "district_code":81
    },
    {
       "_id":2681,
       "upazila_code":34,
       "upazila_name":"Godagari",
       "upazila_ban":"গোদাগাড়ী",
       "union_code":1,
       "district_code":81
    },
    {
       "_id":2699,
       "upazila_code":40,
       "upazila_name":"Matihar Thana",
       "upazila_ban":"মাটিহার থানা",
       "union_code":28,
       "district_code":81
    },
    {
       "_id":2702,
       "upazila_code":53,
       "upazila_name":"Mohanpur",
       "upazila_ban":"মোহনপুর",
       "union_code":1,
       "district_code":81
    },
    {
       "_id":2711,
       "upazila_code":72,
       "upazila_name":"Paba",
       "upazila_ban":"পাবা",
       "union_code":1,
       "district_code":81
    },
    {
       "_id":2729,
       "upazila_code":82,
       "upazila_name":"Puthia",
       "upazila_ban":"পুঠিয়া",
       "union_code":1,
       "district_code":81
    },
    {
       "_id":2738,
       "upazila_code":85,
       "upazila_name":"Rajpara Thana",
       "upazila_ban":"রাজপাড়া থানা",
       "union_code":1,
       "district_code":81
    },
    {
       "_id":2748,
       "upazila_code":90,
       "upazila_name":"Shah Makhdum Thana",
       "upazila_ban":"শাহ মখদুম থানা",
       "union_code":17,
       "district_code":81
    },
    {
       "_id":2750,
       "upazila_code":94,
       "upazila_name":"Tanore",
       "upazila_ban":"ট্যানোর",
       "union_code":1,
       "district_code":81
    },
    {
       "_id":2768,
       "upazila_code":7,
       "upazila_name":"Baghaichhari",
       "upazila_ban":"বাঘাইছড়ি",
       "union_code":1,
       "district_code":84
    },
    {
       "_id":2777,
       "upazila_code":87,
       "upazila_name":"Rangamati Sadar",
       "upazila_ban":"রাঙামাটি সদর",
       "union_code":1,
       "district_code":84
    },
    {
       "_id":2786,
       "upazila_code":3,
       "upazila_name":"Badarganj",
       "upazila_ban":"বদরগঞ্জ",
       "union_code":1,
       "district_code":85
    },
    {
       "_id":2795,
       "upazila_code":42,
       "upazila_name":"Kaunia",
       "upazila_ban":"কাউনিয়া",
       "union_code":1,
       "district_code":85
    },
    {
       "_id":2804,
       "upazila_code":49,
       "upazila_name":"Rangpur Sadar",
       "upazila_ban":"রংপুর সদর",
       "union_code":1,
       "district_code":85
    },
    {
       "_id":2819,
       "upazila_code":43,
       "upazila_name":"Kalaroa",
       "upazila_ban":"কলারোয়া",
       "union_code":1,
       "district_code":87
    },
    {
       "_id":2828,
       "upazila_code":82,
       "upazila_name":"Satkhira Sadar",
       "upazila_ban":"সাতক্ষীরা সদর",
       "union_code":1,
       "district_code":87
    },
    {
       "_id":2837,
       "upazila_code":14,
       "upazila_name":"Bhedarganj",
       "upazila_ban":"ভেদারগঞ্জ",
       "union_code":1,
       "district_code":86
    },
    {
       "_id":2846,
       "upazila_code":25,
       "upazila_name":"Damudya",
       "upazila_ban":"ডামুড্যা",
       "union_code":1,
       "district_code":86
    },
    {
       "_id":2855,
       "upazila_code":65,
       "upazila_name":"Naria",
       "upazila_ban":"নাড়িয়া",
       "union_code":1,
       "district_code":86
    },
    {
       "_id":2864,
       "upazila_code":69,
       "upazila_name":"Shariatpur Sadar",
       "upazila_ban":"শরীয়তপুর সদর",
       "union_code":1,
       "district_code":86
    },
    {
       "_id":2873,
       "upazila_code":94,
       "upazila_name":"Zanjira",
       "upazila_ban":"জাঞ্জিরা",
       "union_code":1,
       "district_code":86
    },
    {
       "_id":2882,
       "upazila_code":67,
       "upazila_name":"Nakla",
       "upazila_ban":"নকলা",
       "union_code":1,
       "district_code":89
    },
    {
       "_id":2891,
       "upazila_code":70,
       "upazila_name":"Nalitabari",
       "upazila_ban":"নালিতাবাড়ি",
       "union_code":1,
       "district_code":89
    },
    {
       "_id":2900,
       "upazila_code":88,
       "upazila_name":"Sherpur Sadar",
       "upazila_ban":"শেরপুর সদর",
       "union_code":1,
       "district_code":89
    },
    {
       "_id":2909,
       "upazila_code":90,
       "upazila_name":"Sreebardi",
       "upazila_ban":"শ্রীবরদী",
       "union_code":1,
       "district_code":89
    },
    {
       "_id":2918,
       "upazila_code":11,
       "upazila_name":"Belkuchi",
       "upazila_ban":"বেলকুচি",
       "union_code":1,
       "district_code":88
    },
    {
       "_id":2927,
       "upazila_code":50,
       "upazila_name":"Kazipur",
       "upazila_ban":"কাজীপুর",
       "union_code":1,
       "district_code":88
    },
    {
       "_id":2936,
       "upazila_code":61,
       "upazila_name":"Royganj",
       "upazila_ban":"রায়গঞ্জ",
       "union_code":1,
       "district_code":88
    },
    {
       "_id":2945,
       "upazila_code":67,
       "upazila_name":"Shahjadpur",
       "upazila_ban":"শাহজাদপুর",
       "union_code":1,
       "district_code":88
    },
    {
       "_id":2954,
       "upazila_code":78,
       "upazila_name":"Sirajganj Sadar",
       "upazila_ban":"সিরাজগঞ্জ সদর",
       "union_code":1,
       "district_code":88
    },
    {
       "_id":2969,
       "upazila_code":94,
       "upazila_name":"Ullah Para",
       "upazila_ban":"উল্লাহ পাড়া",
       "union_code":1,
       "district_code":88
    },
    {
       "_id":2978,
       "upazila_code":23,
       "upazila_name":"Chhatak",
       "upazila_ban":"ছাতক",
       "union_code":1,
       "district_code":90
    },
    {
       "_id":2987,
       "upazila_code":29,
       "upazila_name":"Derai",
       "upazila_ban":"দিরাই",
       "union_code":1,
       "district_code":90
    },
    {
       "_id":2996,
       "upazila_code":47,
       "upazila_name":"Jagannathpur",
       "upazila_ban":"জগন্নাথপুর",
       "union_code":1,
       "district_code":90
    },
    {
       "_id":3005,
       "upazila_code":89,
       "upazila_name":"Sunamganj Sadar",
       "upazila_ban":"সুনামগঞ্জ সদর",
       "union_code":1,
       "district_code":90
    },
    {
       "_id":3014,
       "upazila_code":17,
       "upazila_name":"Beani Bazar",
       "upazila_ban":"বিয়ানী বাজার",
       "union_code":1,
       "district_code":91
    },
    {
       "_id":3023,
       "upazila_code":38,
       "upazila_name":"Golapganj",
       "upazila_ban":"গোলাপগঞ্জ",
       "union_code":1,
       "district_code":91
    },
    {
       "_id":3032,
       "upazila_code":59,
       "upazila_name":"Kanaighat",
       "upazila_ban":"কানাইঘাট",
       "union_code":1,
       "district_code":91
    },
    {
       "_id":3041,
       "upazila_code":62,
       "upazila_name":"Sylhet Sadar",
       "upazila_ban":"সিলেট সদর",
       "union_code":1,
       "district_code":91
    },
    {
       "_id":3067,
       "upazila_code":94,
       "upazila_name":"Zakiganj",
       "upazila_ban":"জকিগঞ্জ",
       "union_code":1,
       "district_code":91
    },
    {
       "_id":3076,
       "upazila_code":19,
       "upazila_name":"Bhuapur",
       "upazila_ban":"ভূয়াপুর",
       "union_code":1,
       "district_code":93
    },
    {
       "_id":3085,
       "upazila_code":25,
       "upazila_name":"Dhanbari",
       "upazila_ban":"ধনবাড়ী",
       "union_code":1,
       "district_code":93
    },
    {
       "_id":3094,
       "upazila_code":28,
       "upazila_name":"Ghatail",
       "upazila_ban":"ঘাটাইল",
       "union_code":1,
       "district_code":93
    },
    {
       "_id":3103,
       "upazila_code":38,
       "upazila_name":"Gopalpur",
       "upazila_ban":"গোপালপুর",
       "union_code":1,
       "district_code":93
    },
    {
       "_id":3112,
       "upazila_code":47,
       "upazila_name":"Kalihati",
       "upazila_ban":"কালিহাতী",
       "union_code":1,
       "district_code":93
    },
    {
       "_id":3121,
       "upazila_code":57,
       "upazila_name":"Madhupur",
       "upazila_ban":"মধুপুর",
       "union_code":1,
       "district_code":93
    },
    {
       "_id":3130,
       "upazila_code":66,
       "upazila_name":"Mirzapur",
       "upazila_ban":"মির্জাপুর",
       "union_code":1,
       "district_code":93
    },
    {
       "_id":3139,
       "upazila_code":85,
       "upazila_name":"Sakhipur",
       "upazila_ban":"সখিপুর",
       "union_code":1,
       "district_code":93
    },
    {
       "_id":3148,
       "upazila_code":95,
       "upazila_name":"Tangail Sadar",
       "upazila_ban":"টাঙ্গাইল সদর",
       "union_code":1,
       "district_code":93
    },
    {
       "_id":3166,
       "upazila_code":82,
       "upazila_name":"Pirganj",
       "upazila_ban":"পীরগঞ্জ",
       "union_code":1,
       "district_code":94
    },
    {
       "_id":3175,
       "upazila_code":86,
       "upazila_name":"Ranisankail",
       "upazila_ban":"রাণীশংকৈল",
       "union_code":1,
       "district_code":94
    },
    {
       "_id":3184,
       "upazila_code":94,
       "upazila_name":"Thakurgaon Sadar",
       "upazila_ban":"ঠাকুরগাঁও সদর",
       "union_code":1,
       "district_code":94
    },
    {
       "_id":3206,
       "upazila_code":14,
       "upazila_name":"Chitalmari",
       "upazila_ban":"চিতলমারী",
       "union_code":15,
       "district_code":1
    },
    {
       "_id":3213,
       "upazila_code":34,
       "upazila_name":"Fakirhat",
       "upazila_ban":"ফকিরহাট",
       "union_code":10,
       "district_code":1
    },
    {
       "_id":3228,
       "upazila_code":56,
       "upazila_name":"Mollahat",
       "upazila_ban":"মোল্লাহাট",
       "union_code":17,
       "district_code":1
    },
    {
       "_id":3257,
       "upazila_code":73,
       "upazila_name":"Rampal",
       "upazila_ban":"রামপাল",
       "union_code":10,
       "district_code":1
    },
    {
       "_id":3267,
       "upazila_code":77,
       "upazila_name":"Sarankhola",
       "upazila_ban":"সরণখোলা",
       "union_code":19,
       "district_code":1
    },
    {
       "_id":3271,
       "upazila_code":4,
       "upazila_name":"Alikadam",
       "upazila_ban":"আলীকদম",
       "union_code":31,
       "district_code":3
    },
    {
       "_id":3285,
       "upazila_code":73,
       "upazila_name":"Naikhongchhari",
       "upazila_ban":"নাইখংছড়ি",
       "union_code":19,
       "district_code":3
    },
    {
       "_id":3289,
       "upazila_code":89,
       "upazila_name":"Rowangchhari",
       "upazila_ban":"রোয়াংছড়ি",
       "union_code":19,
       "district_code":3
    },
    {
       "_id":3293,
       "upazila_code":91,
       "upazila_name":"Ruma",
       "upazila_ban":"রুমা",
       "union_code":19,
       "district_code":3
    },
    {
       "_id":3297,
       "upazila_code":95,
       "upazila_name":"Thanchi",
       "upazila_ban":"থানচি",
       "union_code":19,
       "district_code":3
    },
    {
       "_id":3315,
       "upazila_code":19,
       "upazila_name":"Bamna",
       "upazila_ban":"বামনা",
       "union_code":23,
       "district_code":4
    },
    {
       "_id":3343,
       "upazila_code":2,
       "upazila_name":"Agailjhara",
       "upazila_ban":"আগাইলঝাড়া",
       "union_code":13,
       "district_code":6
    },
    {
       "_id":3348,
       "upazila_code":3,
       "upazila_name":"Babuganj",
       "upazila_ban":"বাবুগঞ্জ",
       "union_code":13,
       "district_code":6
    },
    {
       "_id":3383,
       "upazila_code":36,
       "upazila_name":"Hizla",
       "upazila_ban":"হিজলা",
       "union_code":13,
       "district_code":6
    },
    {
       "_id":3419,
       "upazila_code":94,
       "upazila_name":"Wazirpur",
       "upazila_ban":"উজিরপুর",
       "union_code":10,
       "district_code":6
    },
    {
       "_id":3487,
       "upazila_code":65,
       "upazila_name":"Manpura",
       "upazila_ban":"মনপুরা",
       "union_code":21,
       "district_code":9
    },
    {
       "_id":3491,
       "upazila_code":91,
       "upazila_name":"Tazumuddin",
       "upazila_ban":"তজুমদ্দিন",
       "union_code":19,
       "district_code":9
    },
    {
       "_id":3609,
       "upazila_code":4,
       "upazila_name":"Banchharampur",
       "upazila_ban":"বাঞ্ছারামপুর",
       "union_code":14,
       "district_code":12
    },
    {
       "_id":3622,
       "upazila_code":7,
       "upazila_name":"Bijoynagar",
       "upazila_ban":"বিজয়নগর",
       "union_code":28,
       "district_code":12
    },
    {
       "_id":3643,
       "upazila_code":33,
       "upazila_name":"Ashuganj",
       "upazila_ban":"আশুগঞ্জ",
       "union_code":12,
       "district_code":12
    },
    {
       "_id":3682,
       "upazila_code":90,
       "upazila_name":"Nasirnagar",
       "upazila_ban":"নাসিরনগর",
       "union_code":14,
       "district_code":12
    },
    {
       "_id":3695,
       "upazila_code":94,
       "upazila_name":"Sarail",
       "upazila_ban":"সরাইল",
       "union_code":13,
       "district_code":12
    },
    {
       "_id":3733,
       "upazila_code":47,
       "upazila_name":"Haim Char",
       "upazila_ban":"হাইম চর",
       "union_code":11,
       "district_code":13
    },
    {
       "_id":3791,
       "upazila_code":18,
       "upazila_name":"Bholahat",
       "upazila_ban":"ভোলাহাট",
       "union_code":18,
       "district_code":70
    },
    {
       "_id":3836,
       "upazila_code":4,
       "upazila_name":"Anowara",
       "upazila_ban":"আনোয়ারা",
       "union_code":15,
       "district_code":15
    },
    {
       "_id":3861,
       "upazila_code":12,
       "upazila_name":"Boalkhali",
       "upazila_ban":"বোয়ালখালী",
       "union_code":17,
       "district_code":15
    },
    {
       "_id":3880,
       "upazila_code":33,
       "upazila_name":"Fatikchhari",
       "upazila_ban":"ফটিকছড়ি",
       "union_code":11,
       "district_code":15
    },
    {
       "_id":3901,
       "upazila_code":37,
       "upazila_name":"Hathazari",
       "upazila_ban":"হাটহাজারী",
       "union_code":10,
       "district_code":15
    },
    {
       "_id":4080,
       "upazila_code":15,
       "upazila_name":"Brahmanpara",
       "upazila_ban":"ব্রাহ্মণপাড়া",
       "union_code":18,
       "district_code":19
    },
    {
       "_id":4088,
       "upazila_code":18,
       "upazila_name":"Burichang",
       "upazila_ban":"বুড়িচং",
       "union_code":11,
       "district_code":19
    },
    {
       "_id":4185,
       "upazila_code":74,
       "upazila_name":"Manoharganj",
       "upazila_ban":"মনোহরগঞ্জ",
       "union_code":13,
       "district_code":19
    },
    {
       "_id":4196,
       "upazila_code":75,
       "upazila_name":"Meghna",
       "upazila_ban":"মেঘনা",
       "union_code":12,
       "district_code":19
    },
    {
       "_id":4203,
       "upazila_code":81,
       "upazila_name":"Muradnagar",
       "upazila_ban":"মুরাদনগর",
       "union_code":10,
       "district_code":19
    },
    {
       "_id":4237,
       "upazila_code":94,
       "upazila_name":"Titas",
       "upazila_ban":"তিতাস",
       "union_code":10,
       "district_code":19
    },
    {
       "_id":4274,
       "upazila_code":45,
       "upazila_name":"Kutubdia",
       "upazila_ban":"কুতুবদিয়া",
       "union_code":13,
       "district_code":22
    },
    {
       "_id":4288,
       "upazila_code":56,
       "upazila_name":"Pekua",
       "upazila_ban":"পেকুয়া",
       "union_code":11,
       "district_code":22
    },
    {
       "_id":4295,
       "upazila_code":66,
       "upazila_name":"Ramu",
       "upazila_ban":"রামু",
       "union_code":13,
       "district_code":22
    },
    {
       "_id":4312,
       "upazila_code":94,
       "upazila_name":"Ukhia",
       "upazila_ban":"উখিয়া",
       "union_code":15,
       "district_code":22
    },
    {
       "_id":4322,
       "upazila_code":10,
       "upazila_name":"Dakshinkhan Thana",
       "upazila_ban":"দক্ষিণখান থানা",
       "union_code":38,
       "district_code":26
    },
    {
       "_id":4323,
       "upazila_code":12,
       "upazila_name":"Demra Thana",
       "upazila_ban":"ডেমরা থানা",
       "union_code":65,
       "district_code":26
    },
    {
       "_id":4355,
       "upazila_code":34,
       "upazila_name":"Kamrangir Char Thana",
       "upazila_ban":"কামরঙ্গীর চর থানা",
       "union_code":51,
       "district_code":26
    },
    {
       "_id":4360,
       "upazila_code":38,
       "upazila_name":"Keraniganj",
       "upazila_ban":"কেরানীগঞ্জ",
       "union_code":11,
       "district_code":26
    },
    {
       "_id":4372,
       "upazila_code":62,
       "upazila_name":"Nawabganj",
       "upazila_ban":"নবাবগঞ্জ",
       "union_code":11,
       "district_code":26
    },
    {
       "_id":4400,
       "upazila_code":93,
       "upazila_name":"Turag Thana",
       "upazila_ban":"তুরাগ থানা",
       "union_code":51,
       "district_code":26
    },
    {
       "_id":4401,
       "upazila_code":96,
       "upazila_name":"Uttar Khan Thana",
       "upazila_ban":"উত্তর খান থানা",
       "union_code":76,
       "district_code":26
    },
    {
       "_id":4420,
       "upazila_code":17,
       "upazila_name":"Biral",
       "upazila_ban":"বিরল",
       "union_code":13,
       "district_code":27
    },
    {
       "_id":4436,
       "upazila_code":30,
       "upazila_name":"Chirirbandar",
       "upazila_ban":"চিরিরবন্দর",
       "union_code":13,
       "district_code":27
    },
    {
       "_id":4462,
       "upazila_code":56,
       "upazila_name":"Kaharole",
       "upazila_ban":"কাহারোল",
       "union_code":15,
       "district_code":27
    },
    {
       "_id":4468,
       "upazila_code":60,
       "upazila_name":"Khansama",
       "upazila_ban":"খানসামা",
       "union_code":15,
       "district_code":27
    },
    {
       "_id":4503,
       "upazila_code":3,
       "upazila_name":"Alfadanga",
       "upazila_ban":"আলফাডাঙ্গা",
       "union_code":10,
       "district_code":29
    },
    {
       "_id":4532,
       "upazila_code":21,
       "upazila_name":"Char Bhadrasan",
       "upazila_ban":"চর ভদ্রাসন",
       "union_code":19,
       "district_code":29
    },
    {
       "_id":4547,
       "upazila_code":56,
       "upazila_name":"Madhukhali",
       "upazila_ban":"মধুখালী",
       "union_code":10,
       "district_code":29
    },
    {
       "_id":4565,
       "upazila_code":84,
       "upazila_name":"Sadarpur",
       "upazila_ban":"সদরপুর",
       "union_code":15,
       "district_code":29
    },
    {
       "_id":4574,
       "upazila_code":90,
       "upazila_name":"Saltha",
       "upazila_ban":"সালথা",
       "union_code":10,
       "district_code":29
    },
    {
       "_id":4607,
       "upazila_code":41,
       "upazila_name":"Fulgazi",
       "upazila_ban":"ফুলগাজী",
       "union_code":13,
       "district_code":30
    },
    {
       "_id":4625,
       "upazila_code":21,
       "upazila_name":"Fulchhari",
       "upazila_ban":"ফুলছড়ি",
       "union_code":11,
       "district_code":32
    },
    {
       "_id":4662,
       "upazila_code":67,
       "upazila_name":"Palashbari",
       "upazila_ban":"পলাশবাড়ী",
       "union_code":13,
       "district_code":32
    },
    {
       "_id":4671,
       "upazila_code":82,
       "upazila_name":"Sadullapur",
       "upazila_ban":"সাদুল্লাপুর",
       "union_code":13,
       "district_code":32
    },
    {
       "_id":4682,
       "upazila_code":88,
       "upazila_name":"Saghata",
       "upazila_ban":"সাঘাটা",
       "union_code":13,
       "district_code":32
    },
    {
       "_id":4731,
       "upazila_code":36,
       "upazila_name":"Kapasia",
       "upazila_ban":"কাপাসিয়া",
       "union_code":13,
       "district_code":33
    },
    {
       "_id":4771,
       "upazila_code":43,
       "upazila_name":"Kashiani",
       "upazila_ban":"কাশিয়ানী",
       "union_code":11,
       "district_code":35
    },
    {
       "_id":4823,
       "upazila_code":5,
       "upazila_name":"Bahubal",
       "upazila_ban":"বাহুবল",
       "union_code":11,
       "district_code":36
    },
    {
       "_id":4830,
       "upazila_code":11,
       "upazila_name":"Baniachong",
       "upazila_ban":"বানিয়াচং",
       "union_code":11,
       "district_code":36
    },
    {
       "_id":4864,
       "upazila_code":68,
       "upazila_name":"Lakhai",
       "upazila_ban":"লাখাই",
       "union_code":13,
       "district_code":36
    },
    {
       "_id":4894,
       "upazila_code":7,
       "upazila_name":"Bakshiganj",
       "upazila_ban":"বকশীগঞ্জ",
       "union_code":11,
       "district_code":39
    },
    {
       "_id":5062,
       "upazila_code":43,
       "upazila_name":"Kanthalia",
       "upazila_ban":"কাঁথালিয়া",
       "union_code":15,
       "district_code":42
    },
    {
       "_id":5078,
       "upazila_code":84,
       "upazila_name":"Rajapur",
       "upazila_ban":"রাজাপুর",
       "union_code":13,
       "district_code":42
    },
    {
       "_id":5170,
       "upazila_code":61,
       "upazila_name":"Khetlal",
       "upazila_ban":"ক্ষেতলাল",
       "union_code":15,
       "district_code":38
    },
    {
       "_id":5183,
       "upazila_code":43,
       "upazila_name":"Dighinala",
       "upazila_ban":"দিঘিনালা",
       "union_code":15,
       "district_code":46
    },
    {
       "_id":5193,
       "upazila_code":61,
       "upazila_name":"Lakshmichhari",
       "upazila_ban":"লক্ষ্মীছড়ি",
       "union_code":23,
       "district_code":46
    },
    {
       "_id":5196,
       "upazila_code":65,
       "upazila_name":"Mahalchhari",
       "upazila_ban":"মহালছড়ি",
       "union_code":15,
       "district_code":46
    },
    {
       "_id":5201,
       "upazila_code":67,
       "upazila_name":"Manikchhari",
       "upazila_ban":"মহালছড়ি",
       "union_code":19,
       "district_code":46
    },
    {
       "_id":5213,
       "upazila_code":77,
       "upazila_name":"Panchhari",
       "upazila_ban":"পানছড়ি",
       "union_code":19,
       "district_code":46
    },
    {
       "_id":5221,
       "upazila_code":12,
       "upazila_name":"Batiaghata",
       "upazila_ban":"বটিয়াঘাটা",
       "union_code":11,
       "district_code":47
    },
    {
       "_id":5239,
       "upazila_code":30,
       "upazila_name":"Dumuria",
       "upazila_ban":"ডুমুরিয়া",
       "union_code":11,
       "district_code":47
    },
    {
       "_id":5253,
       "upazila_code":40,
       "upazila_name":"Dighalia",
       "upazila_ban":"দিঘালিয়া",
       "union_code":17,
       "district_code":47
    },
    {
       "_id":5259,
       "upazila_code":53,
       "upazila_name":"Koyra",
       "upazila_ban":"কয়রা",
       "union_code":10,
       "district_code":47
    },
    {
       "_id":5276,
       "upazila_code":69,
       "upazila_name":"Phultala",
       "upazila_ban":"ফুলতলা",
       "union_code":38,
       "district_code":47
    },
    {
       "_id":5279,
       "upazila_code":75,
       "upazila_name":"Rupsa",
       "upazila_ban":"রুপসা",
       "union_code":13,
       "district_code":47
    },
    {
       "_id":5284,
       "upazila_code":94,
       "upazila_name":"Terokhada",
       "upazila_ban":"তেরখাদা",
       "union_code":13,
       "district_code":47
    },
    {
       "_id":5290,
       "upazila_code":2,
       "upazila_name":"Austagram",
       "upazila_ban":"অস্টগ্রাম",
       "union_code":11,
       "district_code":48
    },
    {
       "_id":5322,
       "upazila_code":33,
       "upazila_name":"Itna",
       "upazila_ban":"ইটনা",
       "union_code":17,
       "district_code":48
    },
    {
       "_id":5368,
       "upazila_code":59,
       "upazila_name":"Mithamain",
       "upazila_ban":"মিঠামইন",
       "union_code":11,
       "district_code":48
    },
    {
       "_id":5375,
       "upazila_code":76,
       "upazila_name":"Nikli",
       "upazila_ban":"নিকলি",
       "union_code":23,
       "district_code":48
    },
    {
       "_id":5391,
       "upazila_code":92,
       "upazila_name":"Tarail",
       "upazila_ban":"তারাইল",
       "union_code":13,
       "district_code":48
    },
    {
       "_id":5398,
       "upazila_code":6,
       "upazila_name":"Bhurungamari",
       "upazila_ban":"ভুরুঙ্গামারী",
       "union_code":13,
       "district_code":49
    },
    {
       "_id":5407,
       "upazila_code":8,
       "upazila_name":"Char Rajibpur",
       "upazila_ban":"চর রাজিবপুর",
       "union_code":19,
       "district_code":49
    },
    {
       "_id":5410,
       "upazila_code":9,
       "upazila_name":"Chilmari",
       "upazila_ban":"চিলমারী",
       "union_code":11,
       "district_code":49
    },
    {
       "_id":5416,
       "upazila_code":18,
       "upazila_name":"Phulbari Sadar",
       "upazila_ban":"ফুলবাড়ী সদর",
       "union_code":13,
       "district_code":49
    },
    {
       "_id":5444,
       "upazila_code":77,
       "upazila_name":"Rajarhat",
       "upazila_ban":"রাজারহাট",
       "union_code":10,
       "district_code":49
    },
    {
       "_id":5451,
       "upazila_code":79,
       "upazila_name":"Raumari",
       "upazila_ban":"রৌমারী",
       "union_code":11,
       "district_code":49
    },
    {
       "_id":5475,
       "upazila_code":39,
       "upazila_name":"Daulatpur",
       "upazila_ban":"দৌলতপুর",
       "union_code":11,
       "district_code":50
    },
    {
       "_id":5535,
       "upazila_code":33,
       "upazila_name":"Kamalnagar",
       "upazila_ban":"কমলনগর",
       "union_code":47,
       "district_code":51
    },
    {
       "_id":5593,
       "upazila_code":2,
       "upazila_name":"Aditmari",
       "upazila_ban":"আদিতমারী",
       "union_code":10,
       "district_code":52
    },
    {
       "_id":5601,
       "upazila_code":33,
       "upazila_name":"Hatibandha",
       "upazila_ban":"হাতীবান্ধা",
       "union_code":15,
       "district_code":52
    },
    {
       "_id":5613,
       "upazila_code":39,
       "upazila_name":"Kaligang",
       "upazila_ban":"কালিগঞ্জ",
       "union_code":17,
       "district_code":52
    },
    {
       "_id":5667,
       "upazila_code":80,
       "upazila_name":"Rajoir",
       "upazila_ban":"রাজৈর",
       "union_code":13,
       "district_code":54
    },
    {
       "_id":5710,
       "upazila_code":66,
       "upazila_name":"Mohammadpur",
       "upazila_ban":"মোহাম্মদপুর",
       "union_code":10,
       "district_code":55
    },
    {
       "_id":5718,
       "upazila_code":85,
       "upazila_name":"Shalikha",
       "upazila_ban":"শালিখা",
       "union_code":11,
       "district_code":55
    },
    {
       "_id":5741,
       "upazila_code":22,
       "upazila_name":"Ghior",
       "upazila_ban":"ঘিওর",
       "union_code":11,
       "district_code":56
    },
    {
       "_id":5748,
       "upazila_code":28,
       "upazila_name":"Harirampur",
       "upazila_ban":"হরিরামপুর",
       "union_code":11,
       "district_code":56
    },
    {
       "_id":5771,
       "upazila_code":70,
       "upazila_name":"Saturia",
       "upazila_ban":"সাটুরিয়া",
       "union_code":19,
       "district_code":56
    },
    {
       "_id":5780,
       "upazila_code":78,
       "upazila_name":"Shibalaya",
       "upazila_ban":"শিবালয়",
       "union_code":11,
       "district_code":56
    },
    {
       "_id":5808,
       "upazila_code":35,
       "upazila_name":"Juri",
       "upazila_ban":"জুড়ি",
       "union_code":23,
       "district_code":58
    },
    {
       "_id":5848,
       "upazila_code":80,
       "upazila_name":"Rajnagar",
       "upazila_ban":"রাজনগর",
       "union_code":10,
       "district_code":58
    },
    {
       "_id":5874,
       "upazila_code":60,
       "upazila_name":"Mujib Nagar",
       "upazila_ban":"মুজিব নগর",
       "union_code":28,
       "district_code":57
    },
    {
       "_id":5883,
       "upazila_code":24,
       "upazila_name":"Gazaria",
       "upazila_ban":"গজারিয়া",
       "union_code":10,
       "district_code":59
    },
    {
       "_id":5891,
       "upazila_code":44,
       "upazila_name":"Lohajang",
       "upazila_ban":"লৌহজং",
       "union_code":13,
       "district_code":59
    },
    {
       "_id":5910,
       "upazila_code":74,
       "upazila_name":"Serajdikhan",
       "upazila_ban":"সিরাজদিখান",
       "union_code":11,
       "district_code":59
    },
    {
       "_id":5924,
       "upazila_code":84,
       "upazila_name":"Sreenagar",
       "upazila_ban":"শ্রীনগর",
       "union_code":11,
       "district_code":59
    },
    {
       "_id":5938,
       "upazila_code":94,
       "upazila_name":"Tongibari",
       "upazila_ban":"টঙ্গিবাড়ি",
       "union_code":11,
       "district_code":59
    },
    {
       "_id":5961,
       "upazila_code":16,
       "upazila_name":"Dhobaura",
       "upazila_ban":"ধোবাউড়া",
       "union_code":10,
       "district_code":61
    },
    {
       "_id":6006,
       "upazila_code":24,
       "upazila_name":"Haluaghat",
       "upazila_ban":"হালুয়াঘাট",
       "union_code":17,
       "district_code":61
    },
    {
       "_id":6096,
       "upazila_code":3,
       "upazila_name":"Atrai",
       "upazila_ban":"আত্রাই",
       "union_code":10,
       "district_code":64
    },
    {
       "_id":6104,
       "upazila_code":6,
       "upazila_name":"Badalgachhi",
       "upazila_ban":"বাদলগাছি",
       "union_code":10,
       "district_code":64
    },
    {
       "_id":6120,
       "upazila_code":47,
       "upazila_name":"Manda",
       "upazila_ban":"মান্ডা",
       "union_code":12,
       "district_code":64
    },
    {
       "_id":6134,
       "upazila_code":50,
       "upazila_name":"Mahadebpur",
       "upazila_ban":"মহাদেবপুর",
       "union_code":15,
       "district_code":64
    },
    {
       "_id":6156,
       "upazila_code":69,
       "upazila_name":"Niamatpur",
       "upazila_ban":"নিয়ামতপুর",
       "union_code":10,
       "district_code":64
    },
    {
       "_id":6175,
       "upazila_code":79,
       "upazila_name":"Porsha",
       "upazila_ban":"পোরশা",
       "union_code":15,
       "district_code":64
    },
    {
       "_id":6181,
       "upazila_code":85,
       "upazila_name":"Raninagar",
       "upazila_ban":"রাণীনগর",
       "union_code":10,
       "district_code":64
    },
    {
       "_id":6189,
       "upazila_code":86,
       "upazila_name":"Sapahar",
       "upazila_ban":"সাপাহার",
       "union_code":17,
       "district_code":64
    },
    {
       "_id":6233,
       "upazila_code":2,
       "upazila_name":"Araihazar",
       "upazila_ban":"আড়াইহাজার",
       "union_code":23,
       "district_code":67
    },
    {
       "_id":6274,
       "upazila_code":7,
       "upazila_name":"Belabo",
       "upazila_ban":"বেলাবো",
       "union_code":11,
       "district_code":68
    },
    {
       "_id":6396,
       "upazila_code":4,
       "upazila_name":"Atpara",
       "upazila_ban":"আটপাড়া",
       "union_code":11,
       "district_code":72
    },
    {
       "_id":6403,
       "upazila_code":9,
       "upazila_name":"Barhatta",
       "upazila_ban":"বারহাট্টা",
       "union_code":11,
       "district_code":72
    },
    {
       "_id":6417,
       "upazila_code":38,
       "upazila_name":"Khaliajuri",
       "upazila_ban":"খালিয়াজুরী",
       "union_code":13,
       "district_code":72
    },
    {
       "_id":6423,
       "upazila_code":40,
       "upazila_name":"Kalmakanda",
       "upazila_ban":"কলমাকান্দা",
       "union_code":11,
       "district_code":72
    },
    {
       "_id":6471,
       "upazila_code":83,
       "upazila_name":"Purbadhala",
       "upazila_ban":"পূর্বধলা",
       "union_code":13,
       "district_code":72
    },
    {
       "_id":6482,
       "upazila_code":12,
       "upazila_name":"Dimla",
       "upazila_ban":"ডিমলা",
       "union_code":13,
       "district_code":73
    },
    {
       "_id":6513,
       "upazila_code":45,
       "upazila_name":"Kishoreganj",
       "upazila_ban":"কিশোরগঞ্জ",
       "union_code":17,
       "district_code":73
    },
    {
       "_id":6611,
       "upazila_code":85,
       "upazila_name":"Subarnachar",
       "upazila_ban":"সুবর্ণচর",
       "union_code":29,
       "district_code":75
    },
    {
       "_id":6705,
       "upazila_code":4,
       "upazila_name":"Atwari",
       "upazila_ban":"আটওয়ারি",
       "union_code":13,
       "district_code":77
    },
    {
       "_id":6721,
       "upazila_code":34,
       "upazila_name":"Debiganj",
       "upazila_ban":"দেবিগঞ্জ",
       "union_code":19,
       "district_code":77
    },
    {
       "_id":6741,
       "upazila_code":90,
       "upazila_name":"Tentulia",
       "upazila_ban":"তেঁতুলিয়া",
       "union_code":13,
       "district_code":77
    },
    {
       "_id":6762,
       "upazila_code":52,
       "upazila_name":"Dashmina",
       "upazila_ban":"দশমিনা",
       "union_code":10,
       "district_code":78
    },
    {
       "_id":6768,
       "upazila_code":55,
       "upazila_name":"Dumki",
       "upazila_ban":"ডুমকি",
       "union_code":19,
       "district_code":78
    },
    {
       "_id":6801,
       "upazila_code":76,
       "upazila_name":"Mirzaganj",
       "upazila_ban":"মির্জাগঞ্জ",
       "union_code":13,
       "district_code":78
    },
    {
       "_id":6819,
       "upazila_code":14,
       "upazila_name":"Bhandaria",
       "upazila_ban":"ভান্ডারিয়া",
       "union_code":11,
       "district_code":79
    },
    {
       "_id":6826,
       "upazila_code":47,
       "upazila_name":"Kawkhali",
       "upazila_ban":"কাওখালী",
       "union_code":15,
       "district_code":79
    },
    {
       "_id":6842,
       "upazila_code":76,
       "upazila_name":"Nazirpur",
       "upazila_ban":"নাজিরপুর",
       "union_code":10,
       "district_code":79
    },
    {
       "_id":6867,
       "upazila_code":90,
       "upazila_name":"Zianagar",
       "upazila_ban":"জিয়ানগর",
       "union_code":15,
       "district_code":79
    },
    {
       "_id":6870,
       "upazila_code":7,
       "upazila_name":"Baliakandi",
       "upazila_ban":"বালিয়াকান্দি",
       "union_code":17,
       "district_code":82
    },
    {
       "_id":6881,
       "upazila_code":47,
       "upazila_name":"Kalukhali",
       "upazila_ban":"কালুখালী",
       "union_code":18,
       "district_code":82
    },
    {
       "_id":6991,
       "upazila_code":21,
       "upazila_name":"Barkal",
       "upazila_ban":"বরকল",
       "union_code":15,
       "district_code":84
    },
    {
       "_id":7000,
       "upazila_code":29,
       "upazila_name":"Belai Chhari",
       "upazila_ban":"বেলাই ছরি",
       "union_code":23,
       "district_code":84
    },
    {
       "_id":7003,
       "upazila_code":36,
       "upazila_name":"Kaptai",
       "upazila_ban":"কাপ্তাই",
       "union_code":19,
       "district_code":84
    },
    {
       "_id":7008,
       "upazila_code":47,
       "upazila_name":"Jurai Chhari",
       "upazila_ban":"জুরাই ছড়ি",
       "union_code":19,
       "district_code":84
    },
    {
       "_id":7012,
       "upazila_code":58,
       "upazila_name":"Langadu",
       "upazila_ban":"লাঙ্গাদু",
       "union_code":13,
       "district_code":84
    },
    {
       "_id":7019,
       "upazila_code":75,
       "upazila_name":"Naniarchar",
       "upazila_ban":"নানিয়ারচর",
       "union_code":19,
       "district_code":84
    },
    {
       "_id":7023,
       "upazila_code":78,
       "upazila_name":"Rajasthali",
       "upazila_ban":"রাজস্থালি",
       "union_code":23,
       "district_code":84
    },
    {
       "_id":7042,
       "upazila_code":27,
       "upazila_name":"Gangachara",
       "upazila_ban":"গঙ্গাচড়া",
       "union_code":16,
       "district_code":85
    },
    {
       "_id":7069,
       "upazila_code":58,
       "upazila_name":"Mithapukur",
       "upazila_ban":"মিঠাপুকুর",
       "union_code":17,
       "district_code":85
    },
    {
       "_id":7086,
       "upazila_code":73,
       "upazila_name":"Pirgachha",
       "upazila_ban":"পীরগাছা",
       "union_code":17,
       "district_code":85
    },
    {
       "_id":7110,
       "upazila_code":92,
       "upazila_name":"Taraganj",
       "upazila_ban":"তারাগঞ্জ",
       "union_code":25,
       "district_code":85
    },
    {
       "_id":7115,
       "upazila_code":4,
       "upazila_name":"Assasuni",
       "upazila_ban":"আসাসুনি",
       "union_code":15,
       "district_code":87
    },
    {
       "_id":7126,
       "upazila_code":25,
       "upazila_name":"Debhata",
       "upazila_ban":"দেবহাটা",
       "union_code":15,
       "district_code":87
    },
    {
       "_id":7169,
       "upazila_code":86,
       "upazila_name":"Shyamnagar",
       "upazila_ban":"শ্যামনগর",
       "union_code":11,
       "district_code":87
    },
    {
       "_id":7181,
       "upazila_code":90,
       "upazila_name":"Tala",
       "upazila_ban":"তালা",
       "union_code":11,
       "district_code":87
    },
    {
       "_id":7213,
       "upazila_code":36,
       "upazila_name":"Gosairhat",
       "upazila_ban":"গোসাইরহাট",
       "union_code":11,
       "district_code":86
    },
    {
       "_id":7258,
       "upazila_code":37,
       "upazila_name":"Jhenaigati",
       "upazila_ban":"ঝিনাইগাতী",
       "union_code":12,
       "district_code":89
    },
    {
       "_id":7316,
       "upazila_code":27,
       "upazila_name":"Chauhali",
       "upazila_ban":"চৌহালি",
       "union_code":21,
       "district_code":88
    },
    {
       "_id":7323,
       "upazila_code":44,
       "upazila_name":"Kamarkhanda",
       "upazila_ban":"কামারখন্দ",
       "union_code":19,
       "district_code":88
    },
    {
       "_id":7371,
       "upazila_code":89,
       "upazila_name":"Tarash",
       "upazila_ban":"তাড়াশ",
       "union_code":10,
       "district_code":88
    },
    {
       "_id":7392,
       "upazila_code":18,
       "upazila_name":"Bishwambarpur",
       "upazila_ban":"বিশ্বম্ভরপুর",
       "union_code":17,
       "district_code":90
    },
    {
       "_id":7410,
       "upazila_code":27,
       "upazila_name":"Dakshin Sunamganj",
       "upazila_ban":"দক্ষিণ সুনামগঞ্জ",
       "union_code":11,
       "district_code":90
    },
    {
       "_id":7427,
       "upazila_code":32,
       "upazila_name":"Dharampasha",
       "upazila_ban":"ধরমপাশা",
       "union_code":21,
       "district_code":90
    },
    {
       "_id":7437,
       "upazila_code":33,
       "upazila_name":"Dowarabazar",
       "upazila_ban":"দোয়ারাবাজার",
       "union_code":21,
       "district_code":90
    },
    {
       "_id":7455,
       "upazila_code":50,
       "upazila_name":"Jamalganj",
       "upazila_ban":"জামালগঞ্জ",
       "union_code":27,
       "district_code":90
    },
    {
       "_id":7459,
       "upazila_code":86,
       "upazila_name":"Sulla",
       "upazila_ban":"সুল্লা",
       "union_code":23,
       "district_code":90
    },
    {
       "_id":7472,
       "upazila_code":92,
       "upazila_name":"Tahirpur",
       "upazila_ban":"তাহিরপুর",
       "union_code":10,
       "district_code":90
    },
    {
       "_id":7479,
       "upazila_code":8,
       "upazila_name":"Balaganj",
       "upazila_ban":"বালাগঞ্জ",
       "union_code":11,
       "district_code":91
    },
    {
       "_id":7503,
       "upazila_code":20,
       "upazila_name":"Bishwanath",
       "upazila_ban":"বিশ্বনাথ",
       "union_code":10,
       "district_code":91
    },
    {
       "_id":7517,
       "upazila_code":31,
       "upazila_name":"Dakshin Surma",
       "upazila_ban":"দক্ষিণ সুরমা",
       "union_code":30,
       "district_code":91
    },
    {
       "_id":7526,
       "upazila_code":35,
       "upazila_name":"Fenchuganj",
       "upazila_ban":"ফেঞ্চুগঞ্জ",
       "union_code":23,
       "district_code":91
    },
    {
       "_id":7540,
       "upazila_code":41,
       "upazila_name":"Gowainghat",
       "upazila_ban":"গোয়াইনঘাট",
       "union_code":10,
       "district_code":91
    },
    {
       "_id":7548,
       "upazila_code":53,
       "upazila_name":"Jaintiapur",
       "upazila_ban":"জৈন্তিয়াপুর",
       "union_code":27,
       "district_code":91
    },
    {
       "_id":7580,
       "upazila_code":9,
       "upazila_name":"Basail",
       "upazila_ban":"বাসাইল",
       "union_code":11,
       "district_code":93
    },
    {
       "_id":7592,
       "upazila_code":23,
       "upazila_name":"Delduar",
       "upazila_ban":"দেলদুয়ার",
       "union_code":11,
       "district_code":93
    },
    {
       "_id":7659,
       "upazila_code":76,
       "upazila_name":"Nagarpur",
       "upazila_ban":"নগরপুর",
       "union_code":10,
       "district_code":93
    },
    {
       "_id":7689,
       "upazila_code":8,
       "upazila_name":"Baliadangi",
       "upazila_ban":"বালিয়াডাঙ্গী",
       "union_code":10,
       "district_code":94
    },
    {
       "_id":7697,
       "upazila_code":51,
       "upazila_name":"Haripur",
       "upazila_ban":"হরিপুর",
       "union_code":13,
       "district_code":94
    }
 ]