import React  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import {formState} from 'src/redux/actions';
import styles from "./Form.module.css"
import FirstPage from './Page1/FirstPage';
import SecondPage from './Page2/SecondPage';
import ThirdPage from './Page3/ThirdPage'


const useStyles = makeStyles((theme) => ({
  root: {
    marginTop:'2%',
    width: '100%',
    backgroundColor:'',
    alignItems: 'center',
  },
  instructions: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(1),
  },
}));


const getSteps = (step) =>{
  return ['Primary Information', 'Official Information' , 'Particulars'];
}

const getStepContent = (stepIndex) => {
  switch (stepIndex) {
    case 0:
        return <FirstPage/>;
    case 1:
        return <SecondPage />;
    case 2:
        return <ThirdPage />;
    default:
      return 'Unknown stepIndex';
  }
}

const Form = () => {

  const classes = useStyles();
  const activeStep = useSelector(state=> state.customerState.formState)
  const dispatch = useDispatch()
  const steps = getSteps();

  const handleReset = () => {
    dispatch(formState(0))
  };

  return (
    <div className = {classes.root}>
    <div className = {`sticky-stepper ${styles.fixedElement }`}>
    <div className = {styles.stepper_title}>Customer Enlistment Form</div>
      <Stepper  activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>
              <div className={styles.step_label}>{label}</div>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      </div>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>All steps completed</Typography>
            <Button onClick={handleReset}>Reset</Button>
          </div>
        ) : (
          <div>
            <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
          </div>
        )}
      </div>
    </div>
  );
}

export default Form;