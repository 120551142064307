export const districts = [
    {
       "_id":1,
       "district_ban_name":"বাগেরহাট",
       "district_name":"Bagerhat",
       "division_code":40,
       "district_code":1
    },
    {
       "_id":27,
       "district_ban_name":"বন্দরবান",
       "district_name":"Bandarban",
       "division_code":20,
       "district_code":3
    },
    {
       "_id":45,
       "district_ban_name":"বরগুনা",
       "district_name":"Barguna",
       "division_code":10,
       "district_code":4
    },
    {
       "_id":81,
       "district_ban_name":"বরিশাল",
       "district_name":"Barisal",
       "division_code":10,
       "district_code":6
    },
    {
       "_id":156,
       "district_ban_name":"ভোলা",
       "district_name":"Bhola",
       "division_code":10,
       "district_code":9
    },
    {
       "_id":201,
       "district_ban_name":"বগুড়া",
       "district_name":"Bogra",
       "division_code":50,
       "district_code":10
    },
    {
       "_id":314,
       "district_ban_name":"ব্রাহ্মণবাড়িয়া",
       "district_name":"Brahmanbaria",
       "division_code":20,
       "district_code":12
    },
    {
       "_id":353,
       "district_ban_name":"চাঁদপুর",
       "district_name":"Chandpur",
       "division_code":20,
       "district_code":13
    },
    {
       "_id":425,
       "district_ban_name":"চাঁপাই নবাবগঞ্জ",
       "district_name":"Chapai Nawabganj",
       "division_code":50,
       "district_code":70
    },
    {
       "_id":426,
       "district_ban_name":"চাপাই নবাবগঞ্জ",
       "district_name":"Chapai Nawabganj",
       "division_code":50,
       "district_code":70
    },
    {
       "_id":467,
       "district_ban_name":"চট্টগ্রাম",
       "district_name":"Chittagong",
       "division_code":20,
       "district_code":15
    },
    {
       "_id":603,
       "district_ban_name":"চুয়াডাঙ্গা",
       "district_name":"Chuadanga",
       "division_code":40,
       "district_code":18
    },
    {
       "_id":639,
       "district_ban_name":"কুমিল্লা",
       "district_name":"Comilla",
       "division_code":20,
       "district_code":19
    },
    {
       "_id":738,
       "district_ban_name":"কক্স'স বাজার",
       "district_name":"Cox's Bazar",
       "division_code":20,
       "district_code":22
    },
    {
       "_id":777,
       "district_ban_name":"ঢাকা",
       "district_name":"Dhaka",
       "division_code":30,
       "district_code":26
    },
    {
       "_id":921,
       "district_ban_name":"দিনাজপুর",
       "district_name":"Dinajpur",
       "division_code":55,
       "district_code":27
    },
    {
       "_id":996,
       "district_ban_name":"ফরিদপুর",
       "district_name":"Faridpur",
       "division_code":30,
       "district_code":29
    },
    {
       "_id":1032,
       "district_ban_name":"ফেনী",
       "district_name":"Feni",
       "division_code":20,
       "district_code":30
    },
    {
       "_id":1086,
       "district_ban_name":"গাইবান্ধা",
       "district_name":"Gaibandha",
       "division_code":55,
       "district_code":32
    },
    {
       "_id":1113,
       "district_ban_name":"গাজীপুর",
       "district_name":"Gazipur",
       "division_code":30,
       "district_code":33
    },
    {
       "_id":1161,
       "district_ban_name":"গোপালগঞ্জ",
       "district_name":"Gopalganj",
       "division_code":30,
       "district_code":35
    },
    {
       "_id":1197,
       "district_ban_name":"হবিগঞ্জ",
       "district_name":"Habiganj",
       "division_code":60,
       "district_code":36
    },
    {
       "_id":1251,
       "district_ban_name":"জামালপুর",
       "district_name":"Jamalpur",
       "division_code":30,
       "district_code":39
    },
    {
       "_id":1308,
       "district_ban_name":"যশোর",
       "district_name":"Jessore",
       "division_code":40,
       "district_code":41
    },
    {
       "_id":1380,
       "district_ban_name":"ঝালকাঠি",
       "district_name":"Jhalokati",
       "division_code":10,
       "district_code":42
    },
    {
       "_id":1398,
       "district_ban_name":"ঝিনাইদহ",
       "district_name":"Jhenaidah",
       "division_code":40,
       "district_code":44
    },
    {
       "_id":1452,
       "district_ban_name":"জয়পুরহাট",
       "district_name":"Joypurhat",
       "division_code":50,
       "district_code":38
    },
    {
       "_id":1488,
       "district_ban_name":"খাগড়াছড়ি",
       "district_name":"Khagrachhari",
       "division_code":20,
       "district_code":46
    },
    {
       "_id":1515,
       "district_ban_name":"খুলনা",
       "district_name":"Khulna",
       "division_code":40,
       "district_code":47
    },
    {
       "_id":1565,
       "district_ban_name":"কিশোরগঞ্জ",
       "district_name":"Kishoreganj",
       "division_code":30,
       "district_code":48
    },
    {
       "_id":1640,
       "district_ban_name":"কুড়িগ্রাম",
       "district_name":"Kurigram",
       "division_code":55,
       "district_code":49
    },
    {
       "_id":1667,
       "district_ban_name":"কুষ্টিয়া",
       "district_name":"Kushtia",
       "division_code":40,
       "district_code":50
    },
    {
       "_id":1715,
       "district_ban_name":"লক্ষ্মীপুর",
       "district_name":"Lakshmipur",
       "division_code":20,
       "district_code":51
    },
    {
       "_id":1754,
       "district_ban_name":"লালমনিরহাট",
       "district_name":"Lalmonirhat",
       "division_code":55,
       "district_code":52
    },
    {
       "_id":1772,
       "district_ban_name":"মাদারীপুর",
       "district_name":"Madaripur",
       "division_code":30,
       "district_code":54
    },
    {
       "_id":1799,
       "district_ban_name":"মাগুরা",
       "district_name":"Magura",
       "division_code":40,
       "district_code":55
    },
    {
       "_id":1808,
       "district_ban_name":"মানিকগঞ্জ",
       "district_name":"Manikganj",
       "division_code":30,
       "district_code":56
    },
    {
       "_id":1826,
       "district_ban_name":"মৌলভী বাজার",
       "district_name":"Maulvibazar",
       "division_code":60,
       "district_code":58
    },
    {
       "_id":1871,
       "district_ban_name":"মেহেরপুর",
       "district_name":"Meherpur",
       "division_code":40,
       "district_code":57
    },
    {
       "_id":1889,
       "district_ban_name":"মুন্সিগঞ্জ",
       "district_name":"Munshiganj",
       "division_code":30,
       "district_code":59
    },
    {
       "_id":1907,
       "district_ban_name":"ময়মনসিংহ",
       "district_name":"Mymensingh",
       "division_code":30,
       "district_code":61
    },
    {
       "_id":2009,
       "district_ban_name":"নওগাঁ",
       "district_name":"Naogaon",
       "division_code":50,
       "district_code":64
    },
    {
       "_id":2036,
       "district_ban_name":"নড়াইল",
       "district_name":"Narail",
       "division_code":40,
       "district_code":65
    },
    {
       "_id":2063,
       "district_ban_name":"নারায়ণগঞ্জ",
       "district_name":"Narayanganj",
       "division_code":30,
       "district_code":67
    },
    {
       "_id":2117,
       "district_ban_name":"নরসিংদী",
       "district_name":"Narsingdi",
       "division_code":30,
       "district_code":68
    },
    {
       "_id":2171,
       "district_ban_name":"নাটোর",
       "district_name":"Natore",
       "division_code":50,
       "district_code":69
    },
    {
       "_id":2246,
       "district_ban_name":"নেত্রকোনা",
       "district_name":"Netrakona",
       "division_code":30,
       "district_code":72
    },
    {
       "_id":2291,
       "district_ban_name":"নীলফামারী",
       "district_name":"Nilphamari",
       "division_code":55,
       "district_code":73
    },
    {
       "_id":2333,
       "district_ban_name":"নোয়াখালী",
       "district_name":"Noakhali",
       "division_code":20,
       "district_code":75
    },
    {
       "_id":2405,
       "district_ban_name":"পাবনা",
       "district_name":"Pabna",
       "division_code":50,
       "district_code":76
    },
    {
       "_id":2492,
       "district_ban_name":"পঞ্চগড়",
       "district_name":"Panchagarh",
       "division_code":55,
       "district_code":77
    },
    {
       "_id":2510,
       "district_ban_name":"পটুয়াখালী",
       "district_name":"Patuakhali",
       "division_code":10,
       "district_code":78
    },
    {
       "_id":2555,
       "district_ban_name":"পিরোজপুর",
       "district_name":"Pirojpur",
       "division_code":10,
       "district_code":79
    },
    {
       "_id":2582,
       "district_ban_name":"রাজবাড়ী",
       "district_name":"Rajbari",
       "division_code":30,
       "district_code":82
    },
    {
       "_id":2609,
       "district_ban_name":"রাজশাহী",
       "district_name":"Rajshahi",
       "division_code":50,
       "district_code":81
    },
    {
       "_id":2768,
       "district_ban_name":"রাঙামাটি",
       "district_name":"Rangamati",
       "division_code":20,
       "district_code":84
    },
    {
       "_id":2786,
       "district_ban_name":"রংপুর",
       "district_name":"Rangpur",
       "division_code":55,
       "district_code":85
    },
    {
       "_id":2819,
       "district_ban_name":"সাতক্ষীরা",
       "district_name":"Satkhira",
       "division_code":40,
       "district_code":87
    },
    {
       "_id":2837,
       "district_ban_name":"শরীয়তপুর",
       "district_name":"Shariatpur",
       "division_code":30,
       "district_code":86
    },
    {
       "_id":2882,
       "district_ban_name":"শেরপুর",
       "district_name":"Sherpur",
       "division_code":30,
       "district_code":89
    },
    {
       "_id":2918,
       "district_ban_name":"সিরাজগঞ্জ",
       "district_name":"Sirajganj",
       "division_code":50,
       "district_code":88
    },
    {
       "_id":2978,
       "district_ban_name":"সুনামগঞ্জ",
       "district_name":"Sunamganj",
       "division_code":60,
       "district_code":90
    },
    {
       "_id":3014,
       "district_ban_name":"সিলেট",
       "district_name":"Sylhet",
       "division_code":60,
       "district_code":91
    },
    {
       "_id":3076,
       "district_ban_name":"টাঙ্গাইল",
       "district_name":"Tangail",
       "division_code":30,
       "district_code":93
    },
    {
       "_id":3166,
       "district_ban_name":"ঠাকুরগাঁও",
       "district_name":"Thakurgaon",
       "division_code":55,
       "district_code":94
    },
    {
       "_id":3196,
       "district_ban_name":"বাঘেরহাট",
       "district_name":"Bagerhat",
       "division_code":40,
       "district_code":1
    },
    {
       "_id":3604,
       "district_ban_name":"ব্রাহ্মণবাড়িয়া",
       "district_name":"Brahmanbaria",
       "division_code":20,
       "district_code":12
    },
    {
       "_id":4246,
       "district_ban_name":"কক্সবাজার",
       "district_name":"Cox's Bazar",
       "division_code":20,
       "district_code":22
    },
    {
       "_id":5798,
       "district_ban_name":"মৌলভীবাজার",
       "district_name":"Maulvibazar",
       "division_code":60,
       "district_code":58
    },
    {
       "_id":6195,
       "district_ban_name":"নড়াইল",
       "district_name":"Narail",
       "division_code":40,
       "district_code":65
    }
 ]