import React, {useState, useEffect} from 'react'
import styles from "./Register.module.css"
import Input from "src/components/InputWithIcon/Input"
import { Form, Button} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { RegisterSchema } from './RegisterSchema';
import { FaUser, FaMailBulk, FaKey, FaPhone, FaFacebook, FaTwitter, FaGoogle, FaLinkedin } from 'react-icons/fa';
import { formState } from 'src/redux/actions';
import { apiProvider } from 'src/ApiProvider/ApiProvider';
import { registerStatus } from 'src/redux/actions';
import store from 'src/redux/store';

const Register = () => {

    const history = useHistory()
    const dispatch = useDispatch()

    const [regStatus, setRegStatus] = useState(false)

    //useEffects
    useEffect(()=>{
        dispatch(formState(0))
    },[])

    //states
    const [checked, setChecked] = useState(false)

    const submit = async (event) => {
        await apiProvider.register(event)
        const registered = store.getState().applicationState.alreadyRegistered
        if(!registered){
            setRegStatus(false)
            history.push("/user/register/state")
        }
        else {
            setRegStatus(true)
            dispatch(registerStatus(false))
        }
    }

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver : yupResolver(RegisterSchema),
      });

    return (
        <div className = {`container-fluid`}>
            <div className = {styles.register_title}>
                Register
            </div>

            <Form className = "form container" onSubmit={handleSubmit(submit)}>

                <div className="row d-flex justify-content-center">
                    <div className="col-md-5">

                        <div className="row mb-3">
                            <Input 
                                register = {register}
                                errors = {errors}
                                fieldName = "fullName"
                                icon = {<FaUser/>}
                                placeholder = {"Full Name"}
                                type = {"text"}
                            />
                        </div>

                        <div className="row mb-3">
                            <Input 
                                register = {register}
                                errors = {errors}
                                fieldName = "email"
                                icon = {<FaMailBulk/>}
                                placeholder = {"Email Id"}
                                type = {"email"}
                            />
                        </div>

                        <div className="row mb-3">
                            <Input
                                register={register}
                                errors={errors}
                                fieldName="phoneNumber"
                                icon={<FaPhone />}
                                placeholder={"Phone No."}
                                type={"text"}
                            />
                        </div>

                        <div className="row mb-3">
                            <Input 
                                register = {register}
                                errors = {errors}
                                fieldName = "password"
                                icon = {<FaKey/>}
                                placeholder = {"Password"}
                                type = {"password"}
                            />
                        </div>                
                        
                        <div className="row">
                            <Input 
                                register = {register}
                                errors = {errors}
                                fieldName = "confirmPassword"
                                icon = {<FaKey/>}
                                placeholder = {"Confirm Password"}
                                type = {"password"}
                            />
                        </div>  

                        <div className="row mt-3">

                            <div className={`form-check ${styles.terms_row}`}>
                                <input type="checkbox" className={`form-check-input ${styles.checkbox}`} onClick = {()=>setChecked(!checked)} id="ch1"/>
                                <label className={`form-check-label ${styles.term_check}`} for="ch1"> I Accept the <strong>Terms & Conditions</strong> </label>
                            </div>
                        
                        </div>
                        
                        <div className="row mt-4">
                            {regStatus?
                                    <div className={styles.error_msg}>User Already Registered</div> : null
                            }
                        </div>

                        <div className="row mt-3 mb-5">

                            <Button type="submit" disabled={!checked} className={styles.signup_btn}>Sign Up</Button>

                        </div>

                    </div>
                </div>
                
                </Form>

                <div className="mb-5">
                <span className = {styles.already_signed}>
                   Already have an account?
                </span>

                <a className = {styles.register_link} href="/login">
                    Sign In
                </a>
                </div>

                {/*

                <div className={`row d-flex justify-content-center mt-5 mb-5`}>
                    <hr className={`col-2 mt-2 ${styles.hr_line}`}></hr>
                    <h6 className="col-1 ">or</h6>
                    <hr className={`col-2 mt-2 ${styles.hr_line}`}></hr>
                </div>


                <span className = {styles.already_signed}>
                    Continue With Social Media
                </span>

                <div className={`row d-flex justify-content-center mt-5 mb-5`}>
                    <div className = "col-sm-1 p-1" ><FaFacebook color={"#4267B2"} size={40}/></div>
                    <div className = "col-sm-1 p-1" ><FaTwitter color={"#55ACEE"} size={40}/></div>
                    <div className = "col-sm-1 p-1" ><FaGoogle color={"#F24E1E"} size={40}/></div>
                    <div className = "col-sm-1 p-1" ><FaLinkedin color={"#0077B5"} size={40}/></div>
                </div>
                */}

        </div>
    )
}

export default Register
