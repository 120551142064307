import React, {useState, useEffect} from 'react';
import Summary from "src/components/Order/Summary"
import {useParams} from "react-router"
import styles from "./OrderSummary.module.css"
import { apiProvider } from 'src/ApiProvider/ApiProvider'

const OrderSummary = () => {

    const {id} = useParams()

    const [orderData , setOrderData] = useState([])

    const getTotalPrice = (price, count) => {
        return parseInt(price) * parseInt(count)
    } 
    
    useEffect(()=>{
        const getData = async () => {
            const result = await apiProvider.getOrderItems(id);
            const allOrderData = result? result.map(item=>{
                return (
                   {
                    item_name : item.name,
                    description  : item.details,
                    quantity : item.quantity,
                    total : getTotalPrice(item.quantity, item.price),
                    currency : result[0]?.currency,
                    item_image : item.image
                    }
                )
            }) : []
            setOrderData(allOrderData)
        }
        getData()
    },[])

    return (
        <div className="container">
            <div className={`row mt-4 ${styles.order_title_row}`}>
                    <div className={`h4 col-6 d-flex justify-content-start align-items-center ${styles.order_summary_title}`}>
                        Order Summary
                    </div>
            </div>
            <Summary
                options = {orderData}
            />
        </div>
    );
};

export default OrderSummary;