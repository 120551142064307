import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import styles from "./DashboardCustomers.module.css"
import CustomButton from "src/components/Button/CustomButton"

import OrderList from "src/components/CustomTable/List"
import { apiProvider } from 'src/ApiProvider/ApiProvider'

const DashboardCustomers = () => {

    const history = useHistory()
    const [searchString, setSearchString] = useState('')

    const [customers , setCustomers] = useState([])
    useEffect(() =>{
        const getData = async () =>{
            const result = await apiProvider.getAllCustomers()
            setCustomers(result)
        }
        getData();
    },[])

    const fieldItems = ["organizationName", "primaryContactPerson", "email", "phoneNo", "status"]

    return (
        <div className={`container ${styles.container}`}>

        <div className="row pb-4">
            <div className="col-4 d-flex justify-content-start align-items-center">
                <div className = {`h4 ${styles.order_title}`}>Customers</div>
            </div>
            <div className="col-8 d-flex mt-3 justify-content-end">
                <CustomButton
                    label = "View Details"
                    onClick={() => history.push("/cdprc/customers")}
                    type = "button"
                />
            </div>
        </div>

        <OrderList
            List = {customers && customers.slice(0,5)}
            Fields = {fieldItems}
            scopedValue = "status"
            onRowClick = {(id)=> {history.push(`/cdprc/customer/details/${id}`)}}
        />
            
        </div>
    );
};

export default DashboardCustomers;