import React from 'react';
import styles from "./Contact.module.css"
import { Form, Button} from 'react-bootstrap';
import Input from "src/components/Input/Input"
import { yupResolver } from '@hookform/resolvers/yup'; 
import { useForm } from 'react-hook-form';
import CommentInput from 'src/components/Input/CommentInput'
import { ContactSchema } from "./ContactSchema"
import Label from 'src/components/Label/Label';
import CustomButton from 'src/components/Button/CustomButton';
import {  FaPhone, FaMapMarkedAlt, FaMailBulk } from "react-icons/fa"
import Map from './Map';
import { apiProvider } from 'src/ApiProvider/ApiProvider';

const Contact = () => {
    
    //Methods
    const handleNext = async (event) => {
        const result = await apiProvider.messageCdprc(event)
    };

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver : yupResolver(ContactSchema),
    });

    return (
        <div className="flex-row align-items-center">
        <div className="container">
          <div className={`row ${styles.centered}`}>
            <div className={`col-md-7 ${styles.centeredContent}`}>
            
                <Form className = "form container" onSubmit={handleSubmit(handleNext)}>

                    <div className={`h4 col-6 d-flex justify-content-start align-items-center ${styles.contact_title}`}>
                        Contact Us
                    </div>

                    <div className = "row">

                        <Input
                            register={register}
                            errors = {errors}
                            label = "First Name"
                            fieldName="ContactPersonFirstName"
                            required = {true}
                            label_md = "2"
                            md = "4"
                            size = "sm"
                        /> 

                        <Input
                            register={register}
                            errors = {errors}
                            label = "Last Name"
                            required = {true}
                            fieldName="ContactPersonLastName"
                            label_md = "2"
                            md = "4"
                            size = "sm"
                        /> 

                    </div>

                    <div className = "row">

                        <Input
                            register={register}
                            errors = {errors}
                            label = "Email"
                            required = {true}
                            fieldName="ContactPersonEmailId"
                            label_md = "2"
                            md = "10"
                            size = "sm"
                        /> 
            
                    </div>
                    <div className = "row">
                        <Label
                            label = "Message"
                        />
                        <CommentInput
                            register={register}
                            errors = {errors}
                            fieldName="ContactPersonMessage"
                            required = {true}
                            md = "10"
                            size = "sm"
                            as = "textarea"
                            placeholder = "Type your comment here..."
                        />
                    </div>
                    
                    <div className = "row">
                    <CustomButton
                        label = "Submit"
                        type = "submit"
                    />
                    </div>

                </Form>
            </div>
            <div className={`col-md-5 ${styles.centeredContent}`}>

               
                <div className={`h4 col-6 d-flex justify-content-start align-items-center ${styles.contact_title}`}>
                        Contact Info
                </div>

                <div className={`card ${styles.contact_card}`}>
                <div className={`row ${styles.text_row}`}>
                    <div className="col-1"><FaPhone/></div>
                    <div className="col-11">+88 01784791538</div>
                </div>
                <div className={`row ${styles.text_row}`}>
                    <div className="col-1"><FaMailBulk/></div>
                    <div className="col-11">mail@gmail.com</div>
                </div>
                <div className={`row ${styles.text_row}`}>
                    <div className="col-1"><FaMapMarkedAlt/></div>
                    <div className="col-11">House 468(1st Floor), Road 06, Avenue 06, Mirpur DOHS, Dhaka - 1230, Bangladesh</div>
                </div>
                <div className={`row ${styles.text_row}`}>
                    <div className="col-12">
                        <Map/>
                    </div>
                </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default Contact;