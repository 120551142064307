import * as yup from 'yup';

//Regex
const string_regex = /^[A-Za-z][A-Za-z\s.]+$/;
const contact_number_regex = /(^(\+8801|8801|01|008801))[3-9]{1}(\d){8}$/;
const password_regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/

//limits
const string_max = 200;

//Error Messages
const invalid = "Input Format Invalid";
const required = "Input Required"
const max_limit = "Maximum Limit Exceeded"
const enter_pass =  "Please Enter your password"
const password_error =  "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
const password_must_match = "Passwords must match"

const emailErrorMsg = "Enter in this format : name@example.com"
const phoneNumError = "Enter a valid contact number";

export const RegisterSchema = yup.object().shape({
     
    fullName : yup.string().max(string_max, max_limit).required(required).matches(string_regex,invalid),
    
    email :  yup.string().max(string_max, max_limit).required(required).email(emailErrorMsg),

    phoneNumber : yup.string().required(required).matches(contact_number_regex, phoneNumError),

    password: yup
                .string()
                .required(enter_pass)
                .matches( password_regex, password_error),

    confirmPassword: yup.string()
                        .oneOf([yup.ref('password'), null], password_must_match)
    })