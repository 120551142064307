import { useState, useEffect } from 'react';
import { useDispatch} from 'react-redux';
import CustomButton from "src/components/Button/CustomButton"
import { useSelector } from 'react-redux';

import styles from "./Templates.module.css"

import CardItem from "./CardItem";
import { orderState, templateInformationInsert } from "src/redux/actions";
import { apiProvider } from 'src/ApiProvider/ApiProvider';
import { useHistory } from 'react-router';

const Templates = () => {

    const dispatch = useDispatch()
    const history = useHistory()

    const [selected , setSelected] = useState(false)

    const [data , setData] = useState([]) // Expecting [{id : "" , frontImage : "", backImage : "", title : "", price : "", currency:""}]
    
    const idTemplate = useSelector(state=> state.orderState.idCardTemplate)
    
    useEffect(() =>{
        if(idTemplate) setSelected(true)
        const getData = async () =>{
            const result = await apiProvider.getIdCardTemplatesData()
            setData(result)
        }
        getData(); 
    },[])  

    const setSelectedIdCardTemplate = (cardData) =>{
        dispatch(templateInformationInsert(cardData))
        setSelected(true)
    }

    return (
        <div className="container-fluid">
            <div className={`row justify-content-center ${styles.main_row}`}>
            <div className="col-lg-12 d-flex justify-content-center">
                <div className="col-lg-12">
                    <div className="container">
                        <div className={`card-deck row justify-content-center ${styles.middle_card_row}`}>
                            {
                                data && data.map(item => {
                                    return (
                                        <div className="col-xl-5 d-flex justify-content-center">
                                        <CardItem 
                                            templateData = {item}
                                            setSelected = {cardData => setSelectedIdCardTemplate(cardData)}
                                            disableAllSelect = {selected}
                                            currentItem = {data && idTemplate? idTemplate : null}
                                        />
                                    </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            </div>
            
            
            <div className="row mt-3 mb-5 justify-content-center" >
                
                <div className="col-sm-2">
                    
                    <CustomButton 
                        label = "Back" 
                        onClick = {()=>{ 
                            dispatch(orderState(0))
                            history.push('/user/dashboard')
                        }}
                        type = "button" 
                    />

                    </div>

                <div className="col-sm-2">

                    <CustomButton 
                        label = "Next" 
                        onClick = {()=> dispatch(orderState(1))}
                        type = "button" 
                        disabled = {!selected}
                    />

                </div>

            </div>

        </div>
    );
};

export default Templates;