const applicationState = {
    logState : false,
    token : null,
    userName : null,
    email : null,
    phone : null,
    permission : null,
    type : null,
    alreadyRegistered : false
  }
  export const applicationStateReducer=(state = applicationState, action) => {
      switch (action.type){
        case 'LOGGED_IN':
            return {...state,
                 logState: true,
                 token : action.payload.token? action.payload.token : null , 
                 userName : action.payload.userName? action.payload.userName : null,
                 email : action.payload.email? action.payload.email : null,
                 phone : action.payload.phone? action.payload.phone : null,
                 permission : action.payload.permission? action.payload.permission : null,
                 type : action.payload.type? action.payload.type : null
                }

        case 'LOGGED_OUT':
            return {...state, logState: false, token : null, userName : null, type : null}


        case 'REGISTER_STATUS':
          return {...state, alreadyRegistered : action.payload}

        default:
            return {...state}
    }
  }
  