// Under Dev

import axios from 'axios';

const successHandler = (response) =>  {
    if(response) return response.data
}

const errorHandler = (error) => {
    return error.response
}

//Http Methods
const get = (url) => axios.get(url).then(successHandler).catch(errorHandler)

const post = (url, data) => axios.post(url, data).then(successHandler).catch(errorHandler)

const put = (url, data) => axios.put(url, data).then(successHandler).catch(errorHandler)

const deleteItem = (url, data) => axios.delete(url, data).then(successHandler).catch(errorHandler)




const getIdCardTemplatesData = () => get(`/products/templates`)
const getAccessoriesTemplatesData = () => get(`/products/accessories`)
const searchUserOrders = (searchText) => get(`/search/users/orders/${searchText}`)
const searchAttachments = (searchText) => get(`/search/attachments/${searchText}`)
const searchOrders = (searchText) => get(`/search/orders/${searchText}`)
const searchCustomers = (searchText) => get(`/search/customers/${searchText}`)
const messageCdprc = ( data ) => post(`/cdprc/messages`, data) 


//New Functions
const register = ( data ) => post(`/users/register`, data)
const login = ( data ) => post(`/users/login`, data)
const postCustomer = ( data ) => put(`/customers`, data) 
const getCustomerInfoByToken = () => get(`/customers`)
const postOrder =  ( data ) => post(`/customers/orders`, data)
const getExcelTemplateList = () => get(`/products`)
const uploadAttachment = (attachments) => post(`/customers/files`, attachments)
const getUploadedData = (id) => get(`/customers/orders/${id}/files`) 
const findOrderData = (orderId, dataId) => get(`/customers/orders/${orderId}/data/${dataId}`)
const deleteOrderData = (orderId, dataId) => deleteItem(`/customers/orders/${orderId}/data/${dataId}`)
const updateOrderData = (orderId, dataId, data) => put(`/customers/orders/${orderId}/data/${dataId}`, data)
const confirmOrder =  (id) => get(`/customers/orders/${id}/confirm`)
const getCustomersOrders = () => get(`/customers/orders`)
const getOrderItems = (id) => get(`/customers/orders/${id}/items`) 

const getAllCustomers = () => get(`/cdprc/customers`)
const getAllOrders = () => get(`/cdprc/orders`)
const getCustomerData = (id) => get(`/cdprc/customers/${id}`) 
const getOrderItemsCdprc = (id) => get(`/cdprc/orders/${id}`)
const updateOrderStatus = (id, status) => put(`/cdprc/orders/${id}/update/${status}`)

const cdprcCustomerApproval = (id, data) => post(`/cdprc/customers/${id}/approve` , data)
const cdprcCustomerVerification = (id, data) => post(`/cdprc/customers/${id}/verify` , data)
const cdprcOfficialLoggedInUser = () => get(`https://graph.microsoft.com/v1.0/me`)


export const apiProvider = {
  login,  
  register,
  postCustomer,
  postOrder,
  getIdCardTemplatesData, 
  getAccessoriesTemplatesData,
  getExcelTemplateList,
  uploadAttachment,
  getUploadedData,
  updateOrderStatus,
  findOrderData,
  updateOrderData,
  deleteOrderData,
  getAllCustomers,
  getCustomerData,
  getOrderItemsCdprc,
  getAllOrders,
  getCustomersOrders,
  getOrderItems,
  cdprcCustomerApproval,
  cdprcCustomerVerification,
  searchUserOrders,
  searchAttachments,
  searchOrders,
  searchCustomers,
  confirmOrder,
  messageCdprc,
  getCustomerInfoByToken,
  cdprcOfficialLoggedInUser
};

