import React from 'react';
import Label from 'src/components/Label/Label';
import styles from "./SisterConcern.module.css"

const SisterConcernCheck = (props) => {

    //Props values
    const register = props.register
    const errors = props.errors
    const checkFieldName = props.checkFieldName
    const inputFieldName = props.inputFieldName
    const required = props.required
    const requiredText = props.requiredText
    const label = props.label
    const label_md = props.label_md

    const formData = props.formData

    const check = (text) => formData[checkFieldName] && formData[checkFieldName] === text

    return (
        <div className="row">
        <Label label = {label} label_md = {label_md} required = {required} requiredText={requiredText}/>


        <div className="col-xl-10">
            <div className="row">
                <div className="col-xl-3">
                    <label className={`from-group  ${styles.checkbox_row}`}>
                        <input disabled = {true} checked={check("yes")} className={`${styles.checkbox}`} {...register(checkFieldName)} value = "yes" type = "radio"/>
                        <span className={styles.checkbox_text}><input className={styles.checkbox_input}  defaultValue={formData[inputFieldName]} {...register(inputFieldName)} placeholder={"Yes (if yes then specify)"} type="text"></input></span>
                    </label>
                    <div style={{ height: "25px", marginTop: "5px", fontSize:"12px", fontFamily:"poppins", textAlign: "left"}}>
                        {errors[inputFieldName]? <p className = "text-danger">{errors[inputFieldName].message}</p> : null}
                    </div>
                </div>
            
                <div className="col-xl-2 mb-5">
                    <label className={`from-group  ${styles.checkbox_row}`}>
                        <input disabled = {true} checked={check("no")} className={`${styles.checkbox}`} {...register(checkFieldName)} value = "no" type = "radio"/>
                        <span className={styles.checkbox_text}>No</span>
                    </label>
                </div>
            </div>
        </div>
        <div style={{ height: "25px", marginTop: "-20px", fontSize:"12px", fontFamily:"poppins", textAlign: "left"}}>
            {errors[checkFieldName]? <p className = "text-danger">{errors[checkFieldName].message}</p> : null}
        </div>
        </div>
    );
};

export default SisterConcernCheck;