import React, {useEffect} from 'react';
import styles from './UserState.module.css'
import user_state_icon from "src/images/user_state_icon.png"
import CustomButton from 'src/components/Button/CustomButton'
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { loggedOut } from 'src/redux/actions';

const UserState = () => {

    const history = useHistory()

    return (
        <div className="container vh-100">

            <div className="row">
                <img className={styles.user_state_icon} src = {user_state_icon} alt = "..."></img>
            </div>

            <div className={`row ${styles.user_status_text}`}>
                <span> 
                    Your form has been submitted. 
                    It is in a state of review.
                    After reviewing all the information
                    you will be able to place your order 
                    and we will notify you via email. 
                </span>
            </div>

            <div className="row mt-3 mb-5 justify-content-center" >
            
                <div className="col-sm-4">
                    <CustomButton 
                        label = "Go To Dashboard" 
                        type = "button" 
                        onClick={() => history.push("/user/dashboard") }
                    />
                </div>

            </div>

        </div>
    );
};

export default UserState;