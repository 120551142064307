import React  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import styles from "./Order.module.css"

import Templates from "./Templates/Templates"
import Accessories from "./Accessories/Accessories"
import OrderSummary from "./OrderSummary/OrderSummary"
import Checkout from "./Checkout/Checkout"
import OrderCompleted from "./OrderCompleted/OrderCompleted"
import OrderFailed from './OrderFailed/OrderFailed';


const useStyles = makeStyles((theme) => ({
  root: {
    marginTop:'2%',
    width: '100%',
    backgroundColor:'',
    alignItems: 'center',
  },
  instructions: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(1),
  },
  failure : {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(1),
    backgroundColor : "red"
  }
}));


const getSteps = (step) =>{
  return ['Choose Templates', 'Choose Accessories', 'Order Summary', "Checkout"];
}

const getStepContent = (stepIndex) => {
  switch (stepIndex) {
    case 0:
      return <Templates/>;
    case 1:
      return <Accessories/>;
    case 2:
        return <OrderSummary/>;
    case 3:
        return <Checkout/>;
    default:
      return 'Unknown stepIndex';
  }
}

const Order = () => {
  
  const order_id = useSelector(state=> state.orderState.order_id)
  const classes = useStyles();
  const activeStep = useSelector(state=> state.orderState.order_form_state)
  const dispatch = useDispatch()
  const steps = getSteps();

  const handleReset = () => {
    //dispatch(formReset())
  };

  return (
    <div className = {classes.root}>
    <div className = {`sticky-stepper ${styles.fixedElement }`}>
      <Stepper  activeStep={activeStep} alternativeLabel>
        {steps.map((label,index) => (
          <Step key={label}>
            <StepLabel 
              icon={(index+1)}
              error={!order_id && activeStep === steps.length && index === steps.length-1? true : false}
            >
                <div className={styles.step_label}>{label}</div>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      </div>
      <div>
        {activeStep === steps.length ? (
          <div>
              <Typography className={classes.instructions}>
                {
                order_id?
                  <OrderCompleted
                      order_id = {order_id? order_id : null}
                  />:
                  <OrderFailed/>
                }
              </Typography>
          </div>
        ) : (
          <div>
            <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
          </div>
        )}
      </div>
    </div>
  );
}

export default Order;