import React, {useState,useEffect} from 'react';
import styles from "./CardItem.module.css"
import NumberInput from 'src/components/NumberInput/NumberInput';
import CustomButton from 'src/components/Button/CustomButton';

const CardItem = (props) => {
    
    const templateSelect = props.setSelected
    const disabled = props.disableAllSelect
    const currentItem = props.currentItem

    const [currentCount, setCurrentCount] = useState(0)

    
    useEffect(() => {
        if(currentItem && currentItem.id == templateData.id) {
            setCurrentCount(currentItem.quantity)
        }
    }, [])

    const templateData = props.templateData
    
    const [count, setCount] = useState(0)

    return (
        <div className = {`card ${styles.card_item}`}>
            <div className = "container p-0">
                <div className = {`row d-flex justify-content-center ${styles.card_name_title}`}>
                    <span>{templateData.title}</span>
                </div>
                <div className = "row">
                    <div className = "col-xl-6">
                    <img src= {templateData.front} className ={styles.card_img_front}  alt="..."/>
                    </div>
                    <div className = "col-xl-6">
                    <img src= {templateData.back} className ={styles.card_img_back} alt="..."/>
                    </div>
                </div>
                <div className = "row mt-5">
                <div class="container mt-5">
                    <div class="row">
                        <div className="col-4 d-flex align-items-center">
                        <span className = {styles.price_row}> 
                            <span className={styles.price_text}>Price</span>
                            <span>{`- ${templateData.price}`} {templateData.currency}</span>
                        </span>
                        </div>
                        <div className="col d-flex align-items-center">
                            <NumberInput 
                                getCount = {value=> setCount(value)}
                                existingNumber = {currentCount}
                            />
                        </div>
                        <div className="col d-flex align-items-center">
                        <CustomButton 
                            label = "Select" 
                            onClick =  {()=> {templateSelect({...templateData, quantity : count})}}
                            type = "button" 
                            disabled = {
                                count<1? 
                                currentCount<1? true : false
                                : false}
                        />
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CardItem;