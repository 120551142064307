import React, { useEffect, useState } from 'react';
import styles from "./EnlistmentForm.module.css"
import { apiProvider } from 'src/ApiProvider/ApiProvider';
import ApprovedBy from './ApprovedBy/ApprovedBy';
import VerifiedBy from './VerifiedBy/VerifiedBy';
import Page401 from 'src/pages/Page401/Page401'
import store from 'src/redux/store';
import Success from 'src/components/SuccessPage/Success';
import { useSelector } from 'react-redux';
import { permissions, status } from 'src/Utils/utils';

const EnlistmentForm = (props) => {

    const customerData = props.customerData

    const [customerState, setCustomerState] = useState()
    const [loggedInUser, setLoggedInUser] = useState({
        name : useSelector(state=> state.applicationState.userName),
        email : useSelector(state=> state.applicationState.email),
        phone : useSelector(state=> state.applicationState.phone),
        permission : useSelector(state=> state.applicationState.permission)
      })

    useEffect(() => {
        setCustomerState(customerData.status)
    }, [])
    
    return (
        <div className="container-fuild p-0">
                
                {
                    customerState === status.PENDING?
                        loggedInUser?.permission === permissions.VERIFY?
                            <VerifiedBy
                                customerInfo = {customerData}  
                                loggedInUserInfo = {loggedInUser}  
                            />
                        : <Page401/> 
                    : null
                }
                
                {
                     customerState === status.VERIFIED?
                        loggedInUser?.permission === permissions.APPROVE?
                            <ApprovedBy
                                customerInfo = {customerData}  
                                loggedInUserInfo = {loggedInUser}  
                            />
                        : <Page401/> 
                    : null
                }

                {
                    customerState === status.APPROVED?
                        <Success message = "This Customer is Verified & Approved"/>
                    : null
                }

        </div>
    );
};

export default EnlistmentForm;