import React, {useState, useEffect} from 'react';
import styles from "./CompanyInfo.module.css"

const CompanyInfo = (props) => {

    const name = props.name
    const phone = props.phone
    const tin = props.tin

    return (
        <>
        <div className={`col ${styles.row_text}`}>
            <span className={styles.key_text}>
                Organization Name :
            </span>
            {name}
        </div>

        <div className={`col ${styles.row_text}`}>
            <span className={styles.key_text}>
                Phone Number :
            </span>
            {phone}
        </div>

        <div className={`col ${styles.row_text}`}>
            <span className={styles.key_text}>
                TIN No :
            </span>
            {tin}
        </div>
        </>
    );
};

export default CompanyInfo;