import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch} from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import Input from 'src/components/Input/Input';
import Title from 'src/components/Title/Title';
import UploadBox from 'src/components/UploadBox/UploadBox';
import DatePick from 'src/components/DatePicker/DatePick';
import CustomButton from 'src/components/Button/CustomButton';
import { useHistory, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import styles from "./EditItem.module.css"
import { apiProvider } from 'src/ApiProvider/ApiProvider';
import { getExtension, getFormData } from 'src/Utils/utils';
import { EditItemSchema } from './EditItem.Schema';

const EditItem = () => {

    //File Accept Strings
    const onlyImage = "image/jpg, image/jpeg, image/png"

    const dispatch = useDispatch();
    const history = useHistory();

    const [employeeData , setEmployeeData] = useState({})

    const orderId = useSelector(state=> state.orderState.order_id)
    
    const {id} = useParams();

    useEffect(() =>{
        const getData = async () =>{
            const result = await apiProvider.findOrderData( orderId , id )
            if(result) setEmployeeData(result)
        }
        getData()
    },[])


    //Methods
    const submit = async (event) => {
        //Do Submission
        const newData =  Object.fromEntries(Object.entries(event).filter(([_,value]) => value))
        const updated = {
            ...newData,
            image : event["image"][0],
            joiningDate : event["joiningDate"]? new Date(event["joiningDate"]).toISOString() : employeeData.joiningDate,
            expireDate : event["expireDate"]? new Date(event["expireDate"]).toISOString() : employeeData.expireDate,
        }
        const allFormData = getFormData(updated)
        await apiProvider.updateOrderData(orderId, id, allFormData)
        history.goBack()
    };

    const { register, handleSubmit, control, formState: { errors } } = useForm({
      //resolver : yupResolver(EditItemSchema),
    });

    return (
        <div className={`container ${styles.edit_container}`}>
                                
            {Object.keys(employeeData).length !== 0? 
            <>
            <Form className = "form container" onSubmit={handleSubmit(submit)}>

                <div className = "row">

                    <Title
                        label = "Contents of ID Card"
                    />

                </div>

                <div className = "row">

                    <Input
                        register={register}
                        errors = {errors}
                        label = "Name"
                        formData = {employeeData}
                        fieldName="name"
                        required = {true}
                        label_md = "2"
                        md = "10"
                        size = "sm"
                    /> 
    
                </div>
    
                <div className = "row">
    
                    <Input
                        register={register}
                        errors = {errors}
                        label = "Designation"
                        formData = {employeeData}
                        fieldName="designation"
                        required = {true}
                        label_md = "2"
                        md = "10"
                        size = "sm"
                    /> 
    
                </div>


                    
                <div className = "row">
    
                    <Input
                        register={register}
                        errors = {errors}
                        label = "Employee Id"
                        fieldName="employeeId"
                        formData = {employeeData}
                        required = {true}
                        label_md = "2"
                        md = "4"
                        size = "sm"
                    /> 

                    <Input
                        register={register}
                        errors = {errors}
                        label = "E-Mail"
                        formData = {employeeData}
                        fieldName="email"
                        required = {true}
                        label_md = "2"
                        md = "4"
                        size = "sm"
                    /> 
    
                </div>
    
                <div className = "row">
    
                    <Input
                        register={register}
                        errors = {errors}
                        label = "Phone"
                        formData = {employeeData}
                        fieldName="phone"
                        required = {true}
                        label_md = "2"
                        md = "10"
                        size = "sm"
                    /> 

                    
                    <Input
                        register={register}
                        errors = {errors}
                        formData = {employeeData}
                        label = "Blood Group"
                        fieldName="bloodGroup"
                        required = {true}
                        label_md = "2"
                        md = "4"
                        size = "sm"
                    /> 
    
                </div>

                <div className = "row">

                        <DatePick
                            register = {register}
                            control = {control}
                            errors = {errors}
                            label = "Joining Date"
                            label_md = "2"
                            formData = {employeeData}
                            fieldName = "joiningDate"
                            required = {true}
                            md = "4"
                            setMaxToday = {true}
                        />

                        <DatePick
                            control = {control}
                            errors = {errors}
                            label = "Expire Date"
                            label_md = "2"
                            formData = {employeeData}
                            fieldName = "expireDate"
                            required = {true}
                            md = "4"
                        />
    
                    
                
                </div>
    
          

                <div className = "row">
                    <UploadBox 
                        register = {register}
                        errors = {errors}
                        label = "Photograph"
                        fieldName = "image"
                        accept = {onlyImage}
                        col_width = "6"
                        label_width = "4"
                        box_width = "4"
                        filePath = {employeeData.image}
                        fileType = {getExtension(employeeData.image)}
                    />

                </div>

                <div className="container"> 

                    <div className="row mt-5 mb-3 justify-content-center" >
                    
                        <div className="col-md-2">
                            
                            <CustomButton 
                                label = "Back"
                                onClick={() => history.goBack()}
                                type = "button" 
                            />

                        </div>

                        <div className="col-md-2">

                            <CustomButton 
                                label = "Update" 
                                type = "submit" 
                            />

                        </div>

                    </div>

                </div>

            </Form> 
        </> : null}
    </div>
    );
};

export default EditItem;