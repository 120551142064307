import React from 'react';
import styles from "./List.module.css"
import { FaCircle } from 'react-icons/fa'
import { convertIsoToDate, getStatusColor, isIsoDate, camelCaseToNormalCase } from 'src/Utils/utils';

const List = (props) => {

    //Props
    const List = props.List //Received array of orders
    const fields = props.Fields //Received array of field names to be displayed
    const scopedValue = props.scopedValue //Received
    const onRowClick = props.onRowClick

    return (<>
        <div className={`table-responsive-lg border rounded mt-4 mb-3 min-vh-50 p-0 m-0 ${styles.table_responsive}`}>
            <table className={`table`}>

                <thead className={styles.table_head}>
                    <tr className={styles.thead_tr}>
                    {fields && fields.map(fieldItem=>{
                        return <th scope="col">{camelCaseToNormalCase(fieldItem)}</th>
                    })}
                    </tr>
                </thead>

                <tbody className={styles.table_body}>
                    {List && List.map(ListItem=>{
                        return (
                            <tr className = {`${styles.table_row}`} onClick = {()=>onRowClick(ListItem["id"])}>
                                {fields && fields.map(fieldItem =>{
                                    return <td> 
                                    {fieldItem === scopedValue? 
                                        <div className={`${styles.status_row}`}>
                                            <span className={`${styles.circle}`}>
                                                <FaCircle color={getStatusColor(ListItem[fieldItem])}/>
                                            </span>
                                             <span className = {`${styles.status}`}>{camelCaseToNormalCase(ListItem[fieldItem])}</span>
                                        </div>
                                        :
                                        isIsoDate(ListItem[fieldItem])? convertIsoToDate(ListItem[fieldItem]) : ListItem[fieldItem]
                                    }
                                    </td>
                                })}
                            </tr>
                        )
                    })

                    }
                </tbody>
            </table>
        </div>
        </>
    );
};

export default List;