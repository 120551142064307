const orderState = {
    order_form_state : 0, //can be used to keep all states
    idCardTemplate : null,
    accessories : null,
    checkout_information : null,
    order_id : null,
  }
  
  export const orderStateReducer=(state = orderState, action) => {
      switch (action.type){

        case 'TEMPLATE_INFORMATION_INSERT':
            return {...state, idCardTemplate : action.payload }

        case 'ORDER_ID':
            return {...state, order_id: action.payload}

        case 'ACCESSORIES_INFORMATION_INSERT':
            return {...state, accessories: action.payload}
        
        case 'CHECKOUT_INFORMATION_INSERT':
            return {...state, checkout_information: action.payload}

        case 'ORDER_STATE':
            return {...state, order_form_state: action.payload}

        case 'CLEAR_ORDER_DATA':
            return {...state, idCardTemplate: null, accessories: null, checkout_information: null, order_id : null}
        
        default:
            return {...state}
    }
  }
  