import React , {useState,useEffect} from 'react';
import ListItem from "./ListItem"
import employee_image from "src/images/employee_image.png"
import CustomButton from 'src/components/Button/CustomButton';
import confirm_icon from "src/images/confirm_icon.png"
import styles from "./List.module.css"
import { FaSearch } from 'react-icons/fa';
import { apiProvider } from 'src/ApiProvider/ApiProvider';
import ConfirmModal from 'src/components/Modal/ConfirmModal';
import { useHistory, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { clearOrderData } from 'src/redux/actions';
import { searchFunction } from 'src/Utils/utils';

//This data will be coming from the uploaded excel attachment.

const List = () => {
    
    const [data , setData] = useState([])
    const [searchString , setSearchString] = useState();
    const [employeeList , setEmployeeList] = useState([]);
    const [selected, setSelected] = useState (false)
    const [modalShow, setModalShow] = useState(false);

    const history = useHistory()
    const dispatch = useDispatch()

    const {id} = useParams();

    useEffect(() =>{
        const getData = async () =>{
            const result = await apiProvider.getUploadedData(id)
            setData(result)
            setEmployeeList(result)
        }
        getData()
    },[])

    //Setting the search results
    useEffect(() =>{
        if(searchString && data.length>0){
            const searchAbleEmployeeData = data.map(item=>{ return {...item , phone : `${item.phone}`}})
            const result = searchFunction(searchAbleEmployeeData, searchString)
            setEmployeeList(result)
        } 
        else setEmployeeList(data)
    },[searchString])
    
    useEffect(() => {
        const confirmOrder =  async () =>{
            await apiProvider.confirmOrder(id)
            dispatch(clearOrderData())
            history.push('/user/dashboard')
        }
        if(selected) confirmOrder()
    }, [selected])


    //Deleting a item
    const deleteItem = async (itemId) =>{
        await apiProvider.deleteOrderData(id, itemId)
        const updatedData = data.filter(item=> item.id !==itemId)
        setData(updatedData)
        const updatedEmployeeList = employeeList.filter(item=> item.id !==itemId)
        setEmployeeList(updatedEmployeeList)
    }

    return (
        <div className="container min-vh-100">

            {employeeList?.length>0?
            <div className={`row ${styles.confirm_row}`}>
                <div className="col-lg-1 d-flex justify-content-start align-items-center">
                    <img className={styles.confirm_icon} src={confirm_icon} alt = "..."></img>
                </div>
                <div className="col-lg-8">
                <div className={`row ${styles.confirm_data_text}`}>Confirm Data</div>
                <div className={`row ${styles.confirm_data_msg}`}>After Checking / Uploading All Data Click Confirm Button to notify the official Team.</div></div>
                <div className="col-lg-3 d-flex mt-2 justify-content-end">
                    <CustomButton
                        label = "Confirm"
                        onClick={() => setModalShow(true)}
                        type = "button"
                    />
                </div>
            </div>
            : null    
            }
            
            <div className={`row ${styles.search_row}`}>
                <div className={`input-group ${styles.icon_input}`}>
                    <div className={`input-group-prepend`}>
                        <div className={`input-group-text ${styles.icon}`}><span className={styles.icon_file}><FaSearch/></span></div>
                    </div>
                    <input type="text"  className={`form-control ${styles.input}`} placeholder="Search" onChange={(e) => setSearchString(e.target.value)} />
                </div>
            </div>

            <div className={`row justify-content-center`}>

                {employeeList && employeeList.map(item=>{
                    return <div className="col-xl-4 d-flex justify-content-center">
                                <ListItem 
                                    {...item}
                                    deleteItem={(id)=>deleteItem(id)}
                                />
                            </div>
                })}

            </div>

            <ConfirmModal
                show={modalShow}
                selected = {(value)=> setSelected(value)}
                onHide={() => setModalShow(false)}
            />

        </div>
    );
};

export default List;