import React from 'react';
import { useHistory } from 'react-router-dom';
import styles from './OrderFailed.module.css'
import error_icon from "src/images/error_icon.png"
import CustomButton from "src/components/Button/CustomButton"
import { useDispatch } from 'react-redux';
import { orderState } from 'src/redux/actions';


const OrderFailed= (props) => {

    const order_id = props.order_id

    const history = useHistory()
    const dispatch = useDispatch()

    return (
        <div className="container">
            <div className="row">
                <img className={styles.fail_icon} src = {error_icon} alt = "..."></img>
            </div>
            <div className={`row ${styles.congratulations_text}`}>
                <span>Sorry !</span>
            </div>

            <div className={`row ${styles.order_placed_text}`}>
                <span>We could not process your payment. Please Go Back and Try Again.</span>
            </div>

            <div className="row mt-5 mb-5 justify-content-center" >
            
                <div className="col-sm-4">

                    <CustomButton 
                        label = "Go Back" 
                        type = "button" 
                        onClick = {()=>dispatch(orderState(2))}
                    />

                </div>

            </div>
        </div>
    );
};

export default OrderFailed;