import React, {useState, useEffect} from 'react';
import { Button } from "react-bootstrap"
import { useDispatch, useSelector } from 'react-redux';
import { accessoriesInformationInsert, orderState } from "src/redux/actions";
import styles from "./Accessories.module.css"
import CustomButton from "src/components/Button/CustomButton"

import CardItem from "src/pages/Order/Accessories/CardItem";
import { apiProvider } from 'src/ApiProvider/ApiProvider';

const Accessories = () => {

    const [data , setData] = useState([]) // expecting [{id : "" , image : "", title : "", price : "", currency:"", details : ""}]
    
    const accessories = useSelector(state=> state.orderState.accessories)

    useEffect(() =>{
        const getData = async() =>{
            const result = await apiProvider.getAccessoriesTemplatesData()
            setData(result)
        }
        getData();
    },[])

    const dispatch = useDispatch()


    const setSelectedAccessory = (accessoryData) =>{
        if(accessories){
            const selectedAccessories = accessories.filter(item => item.id !== accessoryData.id)
            dispatch(accessoriesInformationInsert( [...selectedAccessories, accessoryData] ))
        }
        else dispatch(accessoriesInformationInsert( [accessoryData] ))
    }

    //Next
    const nextHandler = () => dispatch(orderState(2))

    return (
        <div className="container-fluid">
            <div className={`row justify-content-center ${styles.main_row}`}>
            <div className="col-lg-12 d-flex justify-content-center">
                <div className="col-lg-12">
                    <div className="container">
                        <div className={`card-deck row justify-content-center ${styles.middle_card_row}`}>
                            {
                                data && data.map(item =>{
                                    return (<div className="col-xl-5 d-flex justify-content-center">
                                                <CardItem 
                                                    accessoryData = {item}
                                                    setSelected = {accessoryData =>setSelectedAccessory(accessoryData)}
                                                    existing = { data && accessories}
                                                />
                                            </div>)
                                })
                            }
                        </div>
                </div>
            </div>
        </div>


        <div className="row mt-3 mb-5 justify-content-center" >
                <div className="col-sm-2">
                    
                    <CustomButton 
                        label = "Back" 
                        onClick = {()=> dispatch(orderState(0))}
                        type = "button" 
                    />

                    </div>

                <div className="col-sm-2">

                    <CustomButton 
                        label = "Next" 
                        onClick={nextHandler}
                        type = "button" 
                    />

                </div>
            </div>
        

        </div>
        </div>
    );
};

export default Accessories;