import React from 'react'
import styles from "./Page404.module.css"
const Page404 = () => {
  return (
    <div className="flex-row align-items-center">
      <div className="container">
        <div className={`row ${styles.centered}`}>
          <div className={`col-md-6 ${styles.centeredText}`}>
            <div>
              <h1 className="float-left display-3 mr-4">404</h1>
              <h4 className="pt-3">Not Found</h4>
              <p className="text-muted float-left">The content you are looking for was not found.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Page404