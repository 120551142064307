import React, {useState, useEffect} from 'react';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import styles from "./Orders.module.css"
import OrderList from "src/components/CustomTable/List"
import { useHistory } from 'react-router';
import { apiProvider } from 'src/ApiProvider/ApiProvider';
import SearchBar from 'src/components/SearchBar/SearchBar';
import Pagination from 'src/components/Pagination/Pagination';
import { searchFunction } from 'src/Utils/utils';
import { getSum } from 'src/Utils/utils';
import { status } from 'src/Utils/utils';
const Orders = () => {

    const history = useHistory()

    const [allOrders, setAllOrders] = useState([])
    const [orders , setOrders] = useState([])
    const [searchText, setSearchText] = useState()
    const [key, setKey] = useState('allRecord');
    const [currentPage, setCurrentPage] = useState (1)

    const fieldItems = ["id", "totalPrice", "currency", "purchasedOn", "status"]

    useEffect(()=>{
        const getData = async () => {
            const result = await apiProvider.getAllOrders()
            const orderList = result.map(item=>{
                const total = getSum(item.accessories) + getSum(item.idCardTemplate)
                return {...item , totalPrice : total, currency : item.idCardTemplate[0].currency}
              })
            setOrders(orderList)
            setAllOrders(orderList)
        }
        getData()
    },[])

    useEffect(() =>{
        setCurrentPage(1)
    },[key])
    
    const searchClick = async () =>{
        if(searchText && allOrders.length>0){
            const searchableData = orders.map(item=>{ return { id : item.id , purchasedOn : item.purchasedOn, totalPrice : `${item.totalPrice}`, currency : item.currency, status : item.status } })
            const result = searchFunction(searchableData, searchText)
            if(result) setOrders(result)
        }
    }

    const refreshClick = () => {
        setSearchText('')
        setOrders(allOrders)
    }


    const getComponentData = (key) =>{
        switch (key) {
            case "allOrders" : return orders
            case "inProgress" : return orders.filter(item => item.status === status.INPROGRESS)
            case "delivered" : return orders.filter(item => item.status === status.DELIVERED)
            case "cancelled" : return orders.filter(item => item.status === status.CANCELLED)
            default : return orders
        }
    }

    return (
        <div className={`container ${styles.container}`}>
            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className={styles.tabs}
            >
            <Tab eventKey="allOrders" active title="All Orders"></Tab>
            <Tab eventKey="inProgress" title="In Progress"></Tab>
            <Tab eventKey="delivered" title="Delivered"></Tab>
            <Tab eventKey="cancelled" title="Cancelled"></Tab>
        </Tabs>

        <SearchBar
            onChangeValue = {(value) => setSearchText(value)}
            onSearchClick = {searchClick}
            onRefreshClick = {refreshClick}
        />

        <OrderList
            List = {getComponentData(key).slice((currentPage*10)-10,(currentPage*10))}
            Fields = {fieldItems}
            scopedValue = "status"
            onRowClick = {(id)=> {history.push(`/cdprc/order/details/${id}`)}}
        />
        <Pagination
            dataCount = {getComponentData(key).length}
            itemsPerPage = "10"
            clickedValue = {value => setCurrentPage(value) }
        />

        </div>
    );
};

export default Orders;