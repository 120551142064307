import * as yup from 'yup';

//Regex
const string_regex = /^[A-Za-z][A-Za-z\s.]+$/;
const contact_number_regex = /(^(\+8801|8801|01|008801))[3-9]{1}(\d){8}$/;
const nid_regex = /^(\d{10}|\d{13}|\d{17})$/;
const passport_regex = /^[A-Za-z0-9]+[0-9\s.]+$/;
const driving_license_regex = /^(\d{9})$/
const designation_regex = /^[A-Za-z][A-Za-z\s./-:,]+$/;

//limits
const string_max = 100;
const min_limit = 1

//Error Messages
const nidError = "Should Contain 10 or 13 or 17 digits"
const drivingLicenseError = "Should Contain 9 digits"
const passportError = "Enter in this format : BB7878927";
const emailErrorMsg = "Enter in this format : name@example.com"
const phoneNumError = "Enter a valid contact number";

const invalid = "Invalid Input Format";
const required = "Input Required";
const max_limit = "Maximum Limit Exceeded"
const min_limit_msg = `Minimum ${min_limit} Items required`
const fileError = "Upload File(max size 2MB)"

export const ThirdPageSchema = yup.object().shape({

    ownerFirstName: yup.string().max(string_max, max_limit).required(required).matches(string_regex, invalid),
    ownerLastName: yup.string().max(string_max, max_limit).required(required).matches(string_regex, invalid),


    ownerDesignation: yup.string().max(string_max, max_limit).required(required).typeError(required),

    ownerContactNumber: yup.string().required(required).matches(contact_number_regex, phoneNumError),

    ownerEmail: yup.string().max(string_max, max_limit).email(emailErrorMsg).required(required),

    ownerPhotoFile: yup.mixed().test("fileSize", fileError, (value) => {
        if (!value.length) return false
        return value[0].size <= 2000000
    }),

    ownerNidNumber: yup.string().test('ownerNidNumber', nidError, (value) => {
        if (!!value) {
            const schema = yup.string().required(required).matches(nid_regex, nidError);
            return schema.isValidSync(value);
        }
    }),
    ownerPassportNumber: yup.string().test('ownerPassportNumber', passportError, function (value) {
        if (!!value) {
            const schema = yup.string().required(required).matches(passport_regex, passportError);
            return schema.isValidSync(value);
        }
        return false;
    }),

    ownerDrivingLicenseNumber: yup.string().test('ownerDrivingLicenseNumber', drivingLicenseError, function (value) {
        if (!!value) {
            const schema = yup.string().required(required).matches(driving_license_regex, drivingLicenseError);
            return schema.isValidSync(value);
        }
        return false;
    }),

    ownerNidFile: yup.mixed().when("ownerNidNumber",
        {
            is: (val) => val === "",
            then: yup.mixed().test("fileSize", fileError, (value) => {
                if (!value.length) return false
                return value[0].size <= 2000000
            }),
        }),

    ownerPassportFile: yup.mixed().when("ownerPassportNumber",
        {
            is: (val) => val === "",
            then: yup.mixed().test("fileSize", fileError, (value) => {
                if (!value.length) return false
                return value[0].size <= 2000000
            }),
        }),

    ownerDrivingLicenseFile: yup.mixed().when("ownerDrivingLicenseNumber",
        {
            is: (val) => val === "",
            then: yup.mixed().test("fileSize", fileError, (value) => {
                if (!value.length) return false
                return value[0].size <= 2000000
            }),
        }),

    providerOwnerSame: yup.boolean(),

    infoProviderFirstName: yup.string().when("providerOwnerSame", {
        is: false, then: yup.string().max(string_max, max_limit).required(required).matches(string_regex, invalid)
    }),

    infoProviderLastName: yup.string().when("providerOwnerSame", {
        is: false, then: yup.string().max(string_max, max_limit).required(required).matches(string_regex, invalid)
    }),

    infoProviderDesignation: yup.string().when("providerOwnerSame", {
        is: false, then: yup.string().max(string_max, max_limit).required(required).matches(designation_regex, invalid)
    }),

    infoProviderDepartment: yup.string().when("providerOwnerSame", {
        is: false, then: yup.string().max(string_max, max_limit).required(required).matches(string_regex, invalid)
    }),

    infoProviderContactNumber: yup.string().when("providerOwnerSame", {
        is: false, then: yup.string().required(required).matches(contact_number_regex, phoneNumError)
    }),

    infoProviderEmail: yup.string().when("providerOwnerSame", {
        is: false, then: yup.string().max(string_max, max_limit).email(emailErrorMsg).required(required),
    }),

    infoProviderSignFile: yup.mixed().test("fileSize", fileError, (value) => {
        if (!value.length) return false
        return value[0].size <= 2000000
    }),
}
);