import React, {useEffect, useState} from 'react';
import styles from './DashboardLayout.module.css'
import {useDispatch, useSelector} from "react-redux";
import {Nav, Navbar} from "react-bootstrap";
import Dashboard from "./Dashboard"
import Customers from "src/pages/CDPRC/Customers/Customers"
import Orders from 'src/pages/CDPRC/Orders//Orders';
import dashboard_icon from "src/images/dashboard_icon.png"
import customers_icon from "src/images/customer_icon.png"
import order_icon from "src/images/order_icon.png"
import { formState } from 'src/redux/actions';
import { useIsAuthenticated } from '@azure/msal-react';

const DashboardLayout = () => {

    const [selectedMenu, setSelectedMenu] = useState('dashboard')

    const dispatch = useDispatch()

    useEffect(()=>{
        dispatch(formState(0))
    },[])

    //Store all sidebar menu options
    const menuOptions = ['Dashboard' , 'Customers' , 'Orders']
    //Co responding menu icons
    const menuIcons = [dashboard_icon, customers_icon, order_icon]
    
    const clickHandler = event => setSelectedMenu(event.target.value) 

    const isAuthenticated = useIsAuthenticated();

    console.log(isAuthenticated)


    const getComponent = (menu) =>{
        //Use Lower Case values of all menu options
        switch(menu){
            case 'dashboard': return <Dashboard/>
            case 'customers': return <Customers/>
            case 'orders' : return <Orders/>
            default: return <Dashboard/>
        }
    }

    return (
        <div>
            <div>
                <div className={`${styles.sidebar}`}>
                    {menuOptions && menuOptions.map(menu =>{
                        return (
                            <button onClick={clickHandler} className={styles.menu_button} value={menu.toLowerCase()}>
                                <img className={styles.menu_icon} src = {menuIcons[menuOptions.indexOf(menu)]} alt="Menu Icon"/>  
                                    {menu}
                            </button>
                        )
                    })}
                </div>

                <div className={`${styles.content}`}>
                    {getComponent(selectedMenu)}
                </div>

            </div>

        </div>
    );
};

export default DashboardLayout;