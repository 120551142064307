import * as yup from 'yup';

//Regex
const company_name_regex = /^[A-Za-z0-9][A-Za-z0-9\s.]+$/;
const string_regex = /^[A-Za-z][A-Za-z\s.]+$/;
const contact_number_regex = /(^(\+8801|8801|01|008801))[3-9]{1}(\d){8}$/;
const designation_regex = /^[A-Za-z][A-Za-z\s./-:,]+$/;



//limits
const string_max = 100;

//Error Messages
const invalid = "Input Format Invalid";
const required = "Input Required"
const max_limit = "Maximum Limit Exceeded"

export const FirstPageSchema = yup.object().shape({
     organizationName : yup.string().max(string_max, max_limit).required(required).matches(company_name_regex,invalid),
     division : yup.string().required(required),
     district : yup.string().required(required),
     upazila : yup.string().required(required),
     thana : yup.string().required(required),
     municipality : yup.string().required(required),
     union :yup.string().required(required),
     postOffice : yup.string().required(required),
     houseNumber : yup.string().max(string_max, max_limit).required(required),
     roadNumber : yup.string().max(string_max, max_limit),
     postCode : yup.string().max(string_max, max_limit),
     primaryPersonFirstName : yup.string().max(string_max, max_limit).required(required).matches(string_regex,invalid),
     primaryPersonLastName :yup.string().max(string_max, max_limit).required(required).matches(string_regex,invalid),
     primaryPersonDesignation :  yup.string().max(string_max, max_limit).required(required).matches(designation_regex,invalid),
     primaryPersonContactNumber : yup.string().required(required).matches(contact_number_regex, invalid),
     primaryPersonOfficialEmailId :  yup.string().max(string_max, max_limit).required(required).email(invalid),
});
