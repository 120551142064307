import { useState, useEffect } from "react";
import NumberInput from "src/components/NumberInput/NumberInput";
import CustomButton from "src/components/Button/CustomButton";
import styles from "./CardItem.module.css";

const CardItem = (props) => {


    const accessoryData = props.accessoryData
    const acccessorySelect = props.setSelected
    const existing = props.existing
    
    const [currentCount, setCurrentCount] = useState(0)

    useEffect(() => {
        if(existing) {
            const c = existing.find(i=> i.id === accessoryData.id)
            if(c) setCurrentCount(c.quantity)
        }
    }, [])

    const [count , setCount] = useState(0)

    return (
        <div className = {`card ${styles.card_item}`}>
            <div className={styles.card_item_title}>{accessoryData.title}</div>

                <img src= {accessoryData.image} alt="..." className = {styles.card_img}/>

                <div className="p-0">
                    <p className={styles.card_paragraph}>{accessoryData.details}</p>
                </div>

                <div className = "row mt-5">
                    <div class="container">
                        <div class="row">
                            <div className="col-4 d-flex align-items-center">

                            <span className = {styles.price_row}> 
                                <span className={styles.price_text}>Price</span>
                                <span>{`- ${accessoryData.price}`} {accessoryData.currency}</span>
                            </span>

                            </div>
                            <div className="col d-flex align-items-center">
                                <NumberInput
                                    getCount = {value=> setCount(value)}
                                    existingNumber = {currentCount}
                                />
                            </div>
                            <div className="col d-flex align-items-center">
                            <CustomButton
                                label = "Select"
                                onClick =  {()=> {acccessorySelect({...accessoryData, quantity : count})}}
                                type = "button"
                                disabled = {count<1}
                            />
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    );
};

export default CardItem;