import React from 'react';
import styles from "./TheFooter.module.css"
import cdprc_logo from 'src/images/cdprc_logo.png'

import { FaSignInAlt, FaUser, FaThLarge, FaPhone, FaMapMarkedAlt, FaMailBulk, FaGlobe } from "react-icons/fa"

const TheFooter = () => {
    return (
        <footer className={styles.footer_section} id = "contact_footer">
          <div className="container">
          <div className="row">

            <div className="col-md-3 mb-3">
                <h6 className={`row ${styles.footer_text_title}`}>Quick Links</h6>
              
                <div className={`row ${styles.footer_text_row}`}>
                    <div className="col-1"><FaSignInAlt/></div>
                    <a href="/login" className="col-11">Login</a>
                </div>
                <div className={`row ${styles.footer_text_row}`}>
                    <div className="col-1"><FaUser/></div>
                    <a href="/register" className="col-11">Register</a>
                </div>

            </div>

            <div className="col-md-4 mb-4">
              <h6 className={`row ${styles.footer_text_title}`}>Contact</h6>
              <div className={`row ${styles.footer_text_row}`}>
                  <div className="col-1"><FaPhone/></div>
                  <div className="col-11">+88 01784791538</div>
              </div>
              <div className={`row ${styles.footer_text_row}`}>
                  <div className="col-1"><FaMapMarkedAlt/></div>
                  <div className="col-11">House 468(1st Floor), Road 06, Avenue 06, Mirpur DOHS, Dhaka - 1230, Bangladesh</div>
              </div>
              <div className={`row ${styles.footer_text_row}`}>
                  <div className="col-1"><FaMailBulk/></div>
                  <div className="col-11">info@cdprc.com</div>
              </div>
              <div className={`row ${styles.footer_text_row}`}>
                  <div className="col-1"><FaGlobe/></div>
                  <a className="col-11" href = "http://www.cdprc.com" >http://www.cdprc.com</a>
              </div>
            </div>

            <div className="col-lg-5">
            <div className={`row content-center p-3 ${styles.footer_tech_support_section}`} >
                  <div className="col-9">
                    <h6 className={styles.developed_by}>Developed By</h6>
                      <div className={styles.cdprc_name}>
                        Circle Data Processing & Research Center
                      </div>
                  </div>
                  <img className={`col-3 ${styles.tech_support_img}`} src={cdprc_logo} alt="CDPRC Logo"></img>
              </div>
            </div>
          </div>
          </div>
          </footer>
    );
};

export default TheFooter;