import React from 'react';
import Label from "src/components/Label/Label"
import Select from 'react-select'
import { Controller } from 'react-hook-form';
import styles from "./IndustryTypes.module.css";
import { industryTypes } from "src/masterdata/industryTypes"

const IndustryTypes = (props) => {
    const register = props.register
    const errors = props.errors
    const control = props.control
    const label = props.label;
    const label_md = props.label_md
    const required = props.required;
    const fieldName = props.fieldName
    const requiredText = props.requiredText
    const formData = props.formData

    const selected = formData && formData[fieldName]? 
                        JSON.parse(formData[fieldName]).map(item =>{ return {label : item , value : item} })
                        : []

    return (<>
        <Label label = {label} label_md = {label_md} required = {required} requiredText={requiredText}/>
        <div className={`col-lg-10 ${styles.select_div}`}>
        <Controller
            name= {fieldName}
            control={control}
            defaultValue={selected}
            render={({ field }) => (
            <Select
                isDisabled={true}
                // defaultValue={options[0]}
                {...field}
                size="large"
                isMulti
                grouped
                closeMenuOnSelect={false}
                options={industryTypes}
                className="basic-multi-select"
                classNamePrefix="select"
            />
            )}/>
            <div style={{ height: "25px", marginTop: "5px", fontSize:"12px", fontFamily:"poppins", textAlign: "left"}}>
                {errors[fieldName] ? <p className= "text-danger">{errors[fieldName].message}</p> : null}
            </div>
        </div>
        </>
    );
};

export default IndustryTypes;