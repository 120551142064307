import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import { FaSearch } from 'react-icons/fa'
import { FiRefreshCcw } from 'react-icons/fi'
import { BsFilter } from 'react-icons/bs'
import OrderList from "src/components/CustomTable/List"
import CustomButton from 'src/components/Button/CustomButton'
import { useDispatch } from 'react-redux'
import styles from "./Dashboard.module.css"
import { clearOrderData, orderState } from 'src/redux/actions'
import Pagination from 'src/components/Pagination/Pagination';
import { apiProvider } from 'src/ApiProvider/ApiProvider'
import { getSum, searchFunction, status } from 'src/Utils/utils'
import { useSelector } from 'react-redux'

const Dashboard = () => {

    const history = useHistory()
    const dispatch = useDispatch()

    const token = useSelector(state=> state.applicationState.token)

    //Search String
    const [searchString, setSearchString] = useState('')
    const [currentPage, setCurrentPage] = useState (1)
    const [allData , setAllData]  = useState([])
    const [data, setData] = useState([])

    const [customerData , setCustomerData] = useState();


    useEffect(()=>{
        const getCustomerData = async () =>{
            const result = await apiProvider.getCustomerInfoByToken();
            setCustomerData(result)
        }
        getCustomerData();
    },[])

    useEffect(()=>{
        dispatch(clearOrderData())
        const getData = async () =>{
            const result = await apiProvider.getCustomersOrders()
            const orderList = result?.length>0? result.map(item=>{
                const total = getSum(item.accessories) + getSum(item.idCardTemplate)
                return {...item , totalPrice : `${total} ${item.idCardTemplate[0].currency}`}
              }) : []
            setAllData(orderList)
            setData(orderList)
        }
        getData()
    },[token])

    const searchOrder = async () =>{
        if(searchString!=='' && allData.length>0){
            const searchableData = data.map(item=>{ return { id : item.id , purchasedOn : item.purchasedOn, totalPrice : item.totalPrice, status : item.status } })
            const result = searchFunction(searchableData, searchString)
            if(result) setData(result)
        }
    }

    const refreshClick =  () =>{
        setSearchString("")
        setData(allData)
    }

    const fieldItems = ["id", "purchasedOn", "totalPrice", "status"]

    return (
        <div>
            <div className={`container ${styles.container}`}>
                <div className = {`h4 ${styles.order_title}`}>
                    Your Orders
                </div>

                <div className="row mt-4">
                    <div className="col-4 d-flex justify-content-start align-items-center">
                    <div className={`input-group ${styles.icon_input}`}>
                        <div className={`input-group-prepend`}>
                            <div className={`input-group-text ${styles.icon}`}><span className={styles.icon_file}><FaSearch/></span></div>
                        </div>
                        <input type="text"  className={`form-control ${styles.input}`} value={searchString} placeholder="Search" onChange={(e) => setSearchString(e.target.value)} />
                    </div>

                    </div>
                    <div className="col-3 d-flex justify-content-start align-items-center">
                        <button className={`${styles.search_btn}`}
                            onClick={searchOrder}
                        > Search</button>
                        <button className={`${styles.refresh_btn}`}
                            onClick={refreshClick}
                        ><FiRefreshCcw/></button>
                    </div>
                    <div className="col-lg-5 mt-2 d-flex justify-content-end">
                        <CustomButton
                            disabled = {customerData?.status !== status.APPROVED}
                            label = "+ Add Order"
                            onClick={() => {
                                dispatch(orderState(0));
                                history.push("/user/order")
                            }}
                            type = "button"
                        />
                    </div>
                </div>

                <OrderList
                    List = {data && data.slice((currentPage*10)-10,(currentPage*10))}
                    Fields = {fieldItems}
                    scopedValue = "status"
                    onRowClick = {(id)=> {
                        const order = data.find(item=>item.id == id);
                        if(order.status === status.PENDING && order.isConfirmed) history.push(`/order/${id}`)
                        else if(order.status === status.PENDING && !order.isConfirmed) history.push(`/orders/${id}/records`)
                    }}
                />

                {data?
                <Pagination
                    dataCount = {data.length}
                    itemsPerPage = "10"
                    clickedValue = {value => setCurrentPage(value) }
                />: null
                }

            </div>
        </div>
    );
};

export default Dashboard;