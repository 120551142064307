import React,{useEffect} from 'react';
import styles from "./Processing.module.css"
const Processing = () => {
    return (
        <div>
            <div className="container">
                <div className={`row ${styles.processing_text}`}>
                    <span>Please wait while we are processing your information.......</span>
                </div>
            </div>
        </div>
    );
};

export default Processing;