import React, {useState,useEffect} from 'react';
import Label from 'src/components/Label/Label';
import styles from "./PreviewBox.module.css"
import no_file from "src/images/no_file.png"
import excel_icon from "src/images/excel_icon.png"
import zip_icon from "src/images/zip_icon.png"

const PreviewBox = (props) => {

    const label = props.label
    const col_width = props.col_width
    const label_width = props.label_width
    const box_width = props.box_width
    const existingFilePath = props.filePath
    const existingFileType = props.fileType

    //State
    const [filePath , setFilePath] = useState()

    //Setting file if any file already passed through props for previews
    useEffect(()=>{
        if(existingFilePath) setFilePath(existingFilePath)
    },[existingFilePath])
    
    //Extensions
    const imageExtensions = ["png", "jpeg", "jpg"]

    const exceptionHandle = {
        "csv" : excel_icon,
        "xlsx": excel_icon,
        "zip": zip_icon
    } 

    const getImageSrc = (filePath) => {
        if(imageExtensions.find(item => item === existingFileType)) return (filePath);
        else return exceptionHandle[existingFileType]
    }

    return (
        <div className={`col-lg-${col_width}`}>
            <div className="row d-flex align-items-center">
                <Label label = {label} label_md = {label_width}/>
                    <div className={`col-lg-${box_width} ${styles.box_col}`}>
                        <div className="row">
                                    <div className={`row ${styles.preview_box}`}>
                                    {
                                    !filePath?
                                        <img className = {styles.no_file} src = {no_file} alt="No File" />
                                        :
                                        getImageSrc(filePath)?
                                            <>
                                            <img className = {styles.preview_area} src = {getImageSrc(filePath)} alt="..." />
                                            <a target="_blank" href={filePath} className={styles.link_button}>View</a>
                                            </>
                                            :
                                            <>
                                            <iframe title="Document" className = {styles.preview_area} src = {filePath} type = {existingFileType}/>
                                            <a target="_blank" href={filePath} className={styles.link_button}>View</a>
                                            </>
                                    }
                                    </div>
                        </div>

                    </div>
                </div>    
            </div>
    );
};

export default PreviewBox;
