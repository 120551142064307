import React, { useEffect, useState } from 'react';
import { Form, Button} from 'react-bootstrap';
import { useDispatch} from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import CommentInput from 'src/components/Input/CommentInput';
import Title from 'src/components/Title/Title';
import UploadBox from 'src/components/UploadBox/UploadBox';
import { ApprovedBySchema } from './ApprovedBySchema';
import CustomButton from 'src/components/Button/CustomButton';
import Label from 'src/components/Label/Label';
import Input from 'src/components/Input/Input';
import { formState } from 'src/redux/actions';
import styles from "./ApprovedBy.module.css"
import CompanyInfo from '../CompanyInfo';
import CDPRCOfficials from '../CDPRCOfficials';
import { apiProvider } from 'src/ApiProvider/ApiProvider';
import { getFormData } from 'src/Utils/utils'
import { useHistory } from 'react-router';

const ApprovedBy = (props) => {

    useEffect(() => {
        window.scrollTo(0,0);
    }, [])

    const customerInfo = props.customerInfo

    const loggedInUserInfo = props.loggedInUserInfo

    const [approvalClick , setApprovalClick] = useState()
    
    //File Accept Strings
    const onlyImage = "image/jpg, image/jpeg, image/png"

    const dispatch = useDispatch();
    const history = useHistory()

    //Methods
    const submit = async (event) => {
        const data = {
            ...event,
            approved: `${approvalClick}`,
            signFile: event["signFile"][0]
        }
        const collectedFormData = getFormData(data)
        const result = await apiProvider.cdprcCustomerApproval(customerInfo.id, collectedFormData)
        if(result) dispatch(formState(3))
        history.goBack()
    };

    const { register, handleSubmit, control, formState: { errors } } = useForm({
        resolver: yupResolver(ApprovedBySchema),
    });

    return (
        <div className="container-fuild p-0">

            <div className = {`row justify-content-center ${styles.title_text}`}>

                Customer Enlistment Form for CDPRC Officials Only

            </div>

            <Form className = "form container" onSubmit={handleSubmit(submit)}>

                <div className="row border p-3">
                    <CompanyInfo
                        name = {customerInfo && customerInfo.organizationName? customerInfo.organizationName : null }
                        phone = {customerInfo && customerInfo.primaryPersonContactNumber? customerInfo.primaryPersonContactNumber : null}
                        tin = {customerInfo && customerInfo.eTinNumber? customerInfo.eTinNumber : null}
                    />
                </div>

                
                <div className="row mt-5">
                    <div className = "col-lg-8">

                        <div>
                            <Label
                                label = "Comments & Recommendation by Sales & Executive"
                                required = {true}
                            />
                            <CommentInput
                                register={register}
                                errors = {errors}
                                fieldName="comments"
                                required = {true}
                                md = "12"
                                size = "sm"
                                as = "textarea"
                                placeholder = "Type your comment here..."
                            />
                    
                        </div>

                    </div>

                    <div className = "col-lg-4">
                        <CDPRCOfficials
                            name = {loggedInUserInfo.name}
                            email = {loggedInUserInfo.email}
                            phone = {loggedInUserInfo.phone}
                        />
                    </div>

                </div>
                    <div className = "row">

                        <Title
                            label = "Approved By"
                        />

                    </div>

                    <div className = "row">

                        <Input
                            register={register}
                            errors = {errors}
                            label = "First Name"
                            fieldName="firstName"
                            required = {true}
                            label_md = "2"
                            md = "4"
                            size = "sm"
                        /> 
            
                        <Input
                            register={register}
                            errors = {errors}
                            label = "Last Name"
                            fieldName="lastName"
                            required = {true}
                            label_md = "2"
                            md = "4"
                            size = "sm"
                        /> 

                    </div>


                    <div className = "row">

                        <Input
                            register={register}
                            errors = {errors}
                            label = "Designation"
                            fieldName="designation"
                            required = {true}
                            label_md = "2"
                            md = "10"
                            size = "sm"
                        /> 

                    </div>

                    <div className = "row">

                        <Input
                            register={register}
                            errors = {errors}
                            label = "Contact Number"
                            fieldName="contactNumber"
                            required = {true}
                            label_md = "2"
                            md = "10"
                            size = "sm"
                        /> 

                    </div>

                    <div className = "row">

                        <Input
                            register={register}
                            errors = {errors}
                            label = "E-Mail address"
                            fieldName="email"
                            required = {true}
                            label_md = "2"
                            md = "10"
                            size = "sm"
                        /> 

                    </div>

                    <div className = "row">


                        <UploadBox
                            register = {register}
                            errors = {errors}
                            label = "Signature"
                            required = {true}
                            fieldName = "signFile"
                            accept = {onlyImage}
                            col_width = {"6"}
                            label_width = {"5"}
                            box_width = {"6"}
                        />

                    </div>

                <div className="row mt-5 mb-5 justify-content-center" >

                    <div className="col-sm-2">

                        <CustomButton 
                            label = "Back" 
                            type = "button"
                            onClick = {()=> dispatch(formState(2))} 
                        />

                    </div>

                    <div className="col-sm-2">

                        <CustomButton 
                            label = "Reject" 
                            type = "submit" 
                            onClick={()=> setApprovalClick(false)}
                        />

                    </div>

                    <div className="col-sm-2">

                        <CustomButton 
                            label = "Approve" 
                            type = "submit" 
                            onClick={()=> setApprovalClick(true)}
                        />

                    </div>

                </div>
    
    </Form> 
        
    </div>
    );
};

export default ApprovedBy;