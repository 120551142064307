import React from 'react'
import styles from "./Page401.module.css"

const Page401 = () => {
  return (
    <div className="flex-row align-items-center">
      <div className="container">
        <div className={`row ${styles.centered}`}>
          <div className={`col-md-6 ${styles.centeredText}`}>
            <div>
              <h1 className="float-left display-3 mr-4">401</h1>
              <h4 className="pt-3">Unauthorized</h4>
              <p className="text-muted float-left">The page you are looking for was not found due to declined permission access.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Page401