export const divisions = [
    {
       "division_code":40,
       "division_name":"Khulna",
       "division_ban_name":"খুলনা"
    },
    {
       "division_code":20,
       "division_name":"Chittagong",
       "division_ban_name":"চট্টগ্রাম"
    },
    {
       "division_code":30,
       "division_name":"Dhaka",
       "division_ban_name":"ঢাকা"
    },
    {
       "division_code":50,
       "division_name":"Rajshahi",
       "division_ban_name":"রাজসাহী"
    },
    {
       "division_code":10,
       "division_name":"Barisal",
       "division_ban_name":"বরিশাল"
    },
    {
       "division_code":55,
       "division_name":"Rangpur",
       "division_ban_name":"রংপুর"
    },
    {
       "division_code":60,
       "division_name":"Sylhet",
       "division_ban_name":"সিলেট"
    }
 ]