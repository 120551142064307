export const formState = (data)=>{
    return {
        type: "FORM_STATE",
        payload : data
    }
}


export const clearData=()=>{
    return{
        type:'CLEAR_DATA'
    }
}

export const insertPage1Information=(data)=>{
    return{
        type: 'PAGE1_INFORMATION_INSERT',
        payload : data
    }
}

export const insertPage2Information=(data)=>{
    return{
        type: 'PAGE2_INFORMATION_INSERT',
        payload : data
    }
}

export const insertPage3Information=(data)=>{
    return{
        type: 'PAGE3_INFORMATION_INSERT',
        payload : data
    }
}



export const orderState=(data)=>{
    return{
        type: 'ORDER_STATE',
        payload : data
    }
}


export const templateInformationInsert = (data) =>{
    return {
        type : 'TEMPLATE_INFORMATION_INSERT',
        payload : data 
    }
}

export const accessoriesInformationInsert = (data) =>{
    return {
        type : 'ACCESSORIES_INFORMATION_INSERT',
        payload : data 
    }
}

export const checkoutInformationInsert = (data) =>{
    return {
        type : 'CHECKOUT_INFORMATION_INSERT',
        payload : data 
    }
}

export const orderCompleted=()=>{
    return{
        type:'ORDER_COMPLETED'
    }
}


export const orderId=(data)=>{
    return{
        type:'ORDER_ID',
        payload : data 
    }
}


export const orderRejected=()=>{
    return{
        type:'ORDER_REJECTED'
    }
}

export const loggedIn=(data)=>{
    return{
        type:'LOGGED_IN',
        payload : data
    }
}

export const loggedOut=()=>{
    return{
        type:'LOGGED_OUT'
    }
}

export const clearOrderData=()=>{
    return{
        type : 'CLEAR_ORDER_DATA'
    }
}

export const registerStatus = (data) =>{
    return {
        type : 'REGISTER_STATUS',
        payload : data
    }
}