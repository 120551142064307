import React from 'react'
import styles from "./Input.module.css"

const Input = (props) => {

    const register = props.register
    const errors = props.errors
    const fieldName = props.fieldName
    const icon = props.icon
    const type = props.type
    const placeholder = props.placeholder

    return (<>
        <div className={`input-group ${styles.icon_input}`}>
            <div className={`input-group-prepend`}>
                <div className={`input-group-text ${styles.icon}`}><span className={styles.icon_file}>{icon}</span></div>
            </div>
            <input type={type}  className={`form-control ${styles.input}`} placeholder={placeholder} {...register(fieldName)} />
        </div>
        <div style={{ height: "25px", marginTop: "5px", marginLeft: "42px", fontSize:"12px", fontFamily:"poppins", textAlign: "left"}}>
            {errors[fieldName]? <p className = "text-danger">{errors[fieldName].message}</p> : null}
        </div>
      </>
    )

}

export default Input;
