import React from 'react';
import { useHistory } from 'react-router-dom';
import styles from './UserReviewDone.module.css'
import confirm_icon from "src/images/confirm_icon.png"
import CustomButton from "src/components/Button/CustomButton"

const UserReviewDone = () => {

    const history = useHistory()

    return (
        <div className="container vh-100">
            <div className="row">
                <img className={styles.confirm_icon} src = {confirm_icon} alt = "..."></img>
            </div>
            <div className={`row ${styles.review_done_text}`}>
                <span>You can Place your Order Now !</span>
            </div>
            <div className="row">
                <CustomButton
                    label = "Next"
                    type = "button"
                    onClick={() => history.push("/user/order")}
                />
            </div>
        </div>
    );
};

export default UserReviewDone;